import React from "react";
import {deepEqual} from "../../../../../core/services/utils";
import {GeneralSettingsTypes} from "../../../../../core/constants/enums";
import {FormInput} from "../../../base/input";
import {NumberFormInput} from "../../../base/input/number";
import Switch from "../../../base/switch";
import {FormSelect} from "../../../base/select/select";
import {CircularProgress, Fade} from "@material-ui/core";
import {ReactComponent as Cross} from "../../../../../assets/images/cross.svg";
import {ReactComponent as Tick} from "../../../../../assets/images/tick.svg";

const GeneralSettingDynamicEntry = ({
                                        setting,
                                        onRevert,
                                        onSave,
                                        form,
                                        values,
                                        initialValues,
                                        name: nameProp = null
                                    }) => {
    const name = nameProp ?? `${setting.type}-${setting.id}`
    const changed = !deepEqual(initialValues[name], values[name])

    /**
     * Renders the input of the entry based on the type of the setting.
     * @return {JSX.Element}
     */
    const renderInput = () => {
        switch (setting.type) {
            case GeneralSettingsTypes.text:
                return (
                    <FormInput
                        name={name}
                        fullWidth
                        disabled={setting.loading}
                    />
                );
            case GeneralSettingsTypes.number:
                return (
                    <NumberFormInput
                        name={name}
                        disabled={setting.loading}
                        form={form}
                        value={values[name]}
                    />
                );
            case GeneralSettingsTypes.boolean:
                return (
                    <Switch
                        size={4.5}
                        disabled={setting.loading}
                        checked={values[name]}
                        onChange={(checked) => form.change(name, checked)}
                    />
                );
            case GeneralSettingsTypes.list:
                return (
                    <FormSelect
                        className={'w-75'}
                        name={name}
                        disabled={setting.loading}
                        data={setting?.defaultValues?.map((value) => ({
                            id: value.id,
                            value: value,
                        })) ?? []}
                    />
                );
            default:
                break;
        }
    }

    return (
        <>
            <div className={'entry'}>
                <div className={'d-flex flex-wrap mb-4 align-items-center'}>
                    <p className={'title'}>
                        {setting?.title ?? ''}
                    </p>
                    <Fade in={changed}>
                        <div className={'d-flex'}>
                            <button
                                className={'button text icon-button'}
                                disabled={!changed}
                                onClick={() => onRevert(setting, form)}>
                                {
                                    setting.loading
                                        ? <CircularProgress size={18} color={'secondary'}/>
                                        : <Cross/>

                                }
                            </button>
                            <button
                                className={'button text icon-button'}
                                disabled={!changed}
                                onClick={() => onSave(setting, values[name])}>
                                {
                                    setting.loading
                                        ? <CircularProgress size={18} color={'secondary'}/>
                                        : <Tick/>
                                }
                            </button>
                        </div>
                    </Fade>
                </div>
                <div className={'w-100 w-md-75 w-lg-50'}>
                    {renderInput()}
                </div>
            </div>
        </>
    );
}

export default GeneralSettingDynamicEntry;
