import React, {useContext, useLayoutEffect, useRef, useState} from "react";
import * as Yup from "yup";
import {InvoiceSearchQueryNames} from "../../../../../core/constants/query-names";
import {AnimationServiceContext} from "../../../../contexts/animation-service";
import {useTranslation} from "react-i18next";
import {makeRequired, makeValidate} from "mui-rff";
import {AnimationContextConstants} from "../../../../../core/constants/enums";
import {InvoicesTabValues} from "../../../../../core/constants/tab-values";
import Form from "../../../base/form";
import {SearchFormInput} from "../../../base/input/search";
import classnames from "classnames";
import {Col, Collapse} from "react-bootstrap";
import {FormKeyboardDatePicker} from "../../../base/date-picker";


/**
 * Creates the schema of the invoice form section.
 * @param {any} translation the translations for the search section
 * @param {any} validationTranslation the translations for the validation section
 * @return {import('yup').AnyObjectSchema}
 */
const makeSchema = (translation, validationTranslation) => {
    return Yup.object().shape({
        [InvoiceSearchQueryNames.keywords]: Yup.string().when(InvoiceSearchQueryNames.keywords, {
            is: val => !val?.length,
            then: Yup.string().nullable(),
            otherwise: Yup.string().nullable().required(validationTranslation?.required ?? '')
        }),
        [InvoiceSearchQueryNames.fromDate]: Yup.date().when(InvoiceSearchQueryNames.toDate, {
            is: val => !val,
            then: Yup.date().nullable().max(new Date(), translation?.validateMessages?.futureLookupError ?? ''),
            otherwise: Yup.date().nullable()
                .max(Yup.ref(InvoiceSearchQueryNames.toDate), validationTranslation?.dateSpecific?.maxLookupError ?? '')
                .max(new Date(), translation?.validateMessages?.futureLookupError ?? '')
        }),
        [InvoiceSearchQueryNames.toDate]: Yup.date().when(InvoiceSearchQueryNames.fromDate, {
            is: val => !val,
            then: Yup.date().nullable().max(new Date(), translation?.validateMessages?.futureLookupError ?? ''),
            otherwise: Yup.date().nullable()
                .min(Yup.ref(InvoiceSearchQueryNames.fromDate), validationTranslation?.dateSpecific?.minLookupError ?? '')
                .max(new Date(), translation?.validateMessages?.futureLookupError ?? '')
        }),
    }, [[InvoiceSearchQueryNames.keywords, InvoiceSearchQueryNames.keywords], [InvoiceSearchQueryNames.fromDate, InvoiceSearchQueryNames.toDate]]);
}

const InvoicesFormSection = ({filters, selectedTab, submit}) => {
    const animationService = useContext(AnimationServiceContext)
    const {t} = useTranslation()
    const translation = t('views.panel.invoices.form', {returnObjects: true})
    const validationTranslation = t('utils.formValidation', {returnObjects: true})
    const prevSelectedTab = useRef(null)
    const [showCollapsedForm, setShowCollapsedForm] = useState(false)
    const schema = makeSchema(translation, validationTranslation)
    const validate = makeValidate(schema)
    const required = makeRequired(schema)

    /**
     * As soon as the component layout is done:
     * Creates the animation for search button between tabs.
     */
    useLayoutEffect(() => {
        animationService.removeAnimation(AnimationContextConstants.searchInvoices.name)
        animationService.addAnimation(AnimationContextConstants.searchInvoices)
    }, [])

    /**
     * Listens to the changes in showCollapsedForm and with each change:
     * Determines how to animate the search button based on the selected tab.
     */
    useLayoutEffect(() => {
        const animation = animationService.getAnimation(AnimationContextConstants.searchInvoices.name)
        let animationFrame;
        if (showCollapsedForm) {
            animationFrame = window.requestAnimationFrame(() => animation.play())
        } else {
            if (prevSelectedTab.current === InvoicesTabValues.custom) {
                animationFrame = window.requestAnimationFrame(() => animation.reverse())
            } else if (prevSelectedTab.current == null) {
                animation.toggleVisibility(animation.baseId, true)
            }
        }
        return () => {
            if (!animationFrame) return
            cancelAnimationFrame(animationFrame)
            animationFrame = null
        }
    }, [showCollapsedForm])

    /**
     * Listens to the changes in selectedTab and with each change:
     * Changes the showCollapsedForm based on the provided selected value to trigger the button animations.
     * it also saves the last selected tab into a ref to check when to invoke the reserving of animation
     */
    useLayoutEffect(() => {
        switch (selectedTab) {
            case InvoicesTabValues.custom:
                setShowCollapsedForm(true)
                break;
            case InvoicesTabValues.all:
            case InvoicesTabValues.thisYear:
            case InvoicesTabValues.thisMonth:
                setShowCollapsedForm(false)
                break;
            default:
                break;
        }
        return () => {
            prevSelectedTab.current = selectedTab
        }
    }, [selectedTab])

    /**
     * Calls the search callback as soon as the form has been submitted.
     * @param {any}         values          the form values
     * @param {formApi}     form            the form api
     * @param {function}    submitErrors    a callback for submitting any form errors.
     */
    const onFormSearched = (values, form, submitErrors) => {
        if (submit) submit(values)
    }

    return (
        <>
            <Form
                onSubmit={onFormSearched}
                validate={validate}
                className={'d-flex flex-column'}
                initialValues={filters}
                render={({submitting, values}) => {
                    return (
                        <>
                            <SearchFormInput
                                placeholder={translation?.keywords?.placeholder ?? ''}
                                fullWidth
                                required={required[InvoiceSearchQueryNames.keywords]}
                                name={InvoiceSearchQueryNames.keywords}
                                buttonProps={{
                                    className: classnames('button primary invisible'),
                                    children: translation?.actions?.submit ?? '',
                                    type: "submit",
                                    id: AnimationContextConstants.searchInvoices.baseId,
                                    disabled: submitting
                                }}
                            />
                            <Collapse in={showCollapsedForm}>
                                <div>
                                    <div className={'collapsed-section'}>
                                        <Col xs={12} md={6} lg={4} xl={"auto"}
                                             className={'d-flex align-items-center ps-3 flex-xl-grow-1 pe-3'}>
                                            <p className={'title'}>
                                                {translation?.fromDate?.title ?? ''}
                                            </p>
                                            <FormKeyboardDatePicker
                                                maxDate={values[InvoiceSearchQueryNames.toDate]}
                                                placeholder={translation?.fromDate?.placeholder}
                                                name={InvoiceSearchQueryNames.fromDate}
                                                required={required[InvoiceSearchQueryNames.fromDate]}
                                            />
                                        </Col>
                                        <Col xs={12} md={6} lg={4} xl={"auto"} className={'d-flex align-items-center' +
                                            ' ps-3 flex-xl-grow-1'}>
                                            <p className={'title'}>
                                                {translation?.toDate?.title ?? ''}
                                            </p>
                                            <FormKeyboardDatePicker
                                                minDate={values[InvoiceSearchQueryNames.fromDate]}
                                                placeholder={translation?.toDate?.placeholder}
                                                name={InvoiceSearchQueryNames.toDate}
                                                required={required[InvoiceSearchQueryNames.toDate]}
                                            />
                                        </Col>
                                        <Col xs={12} lg={4} xl={3} className={'d-flex justify-content-end'}>
                                            <button
                                                disabled={submitting}
                                                id={AnimationContextConstants.searchInvoices.destinationId}
                                                type={'submit'}
                                                className={'button primary invisible mt-4 mt-lg-0'}
                                            >
                                                {translation?.actions?.submit ?? ''}
                                            </button>
                                        </Col>
                                    </div>
                                </div>
                            </Collapse>
                        </>
                    );
                }}
            />
        </>
    );

}


export default InvoicesFormSection;
