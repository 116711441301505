import React from 'react';
import {Col, Row} from "react-bootstrap";
import BarChartLegends from "./legends";
import ChartDataset from "./dataset";
import classnames from "classnames";


/**
 * @typedef {{color: string, id: number, title: string}} legendType
 * @typedef {{code: string, data: [{total: number, legendId: number}], id: number}} datasetType
 * @typedef {{yAxis: string, xAxis: string}} labelsType
 * @typedef {{legends: [legendType], dataset: [datasetType], labels: labelsType}} configJSONChartType
 */

const BarChart = ({data, title, loading}) => {
    const {legends, xAxisLabel, dataset} = (data ?? {});

    /**
     * Fetches the title which is the combination of all legends
     * @param {[legendType]} legends
     * @returns {string}
     */
    const getTitle = (legends) => {
        let title = '';
        for (let i = 0; i < legends.length; ++i) {
            if (i !== 0 && i !== legends.length)
                title += '/';
            title += legends[i].title;
        }
        return title;
    }

    /**
     * Finds the max value of the provided information in the JSON object
     * @param {[datasetType]} dataset
     * @returns {number}
     */
    const findMaxVal = (dataset) => {
        let max = 0;
        for (const barInfo of dataset) {
            for (const data of barInfo.data) {
                if (max <= data.total)
                    max = data.total
            }
        }
        return max;
    }

    /**
     * Retrieves the color for a single bar in the chart
     * @param {number} legendId
     * @returns {string}
     */
    const getSingleBarColor = (legendId) => {
        return legends.find(legend => legend.id === legendId).color;
    }

    return (
        <>
            {
                loading
                    ? (
                        <div className={'loading bar-chart'}>
                            <div className='title'>
                                <div/>
                            </div>
                            <Row>
                                <Col xs={12} md={3}>
                                    <div className={'legends'}>
                                        <div/>
                                    </div>
                                </Col>
                                <Col xs={12} md={9} className={'bar-chart-col ps-5 ps-md-3'}>
                                    <div className='chart'>
                                        <div className='d-flex flex-row flex-nowrap justify-content-start h-100'>
                                            {Array(4).fill(null).map((e, index) => (
                                                <div key={index} className={'dataset'}>
                                                    {Array(2).fill(null).map((e, _index) => (
                                                        <div key={_index}
                                                             className={classnames('bar-container')}>
                                                            <div className={classnames('bar', {[`bar-${_index}`]: true})}>
                                                                <div/>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>

                                            ))}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    )
                    : (
                        <div className='bar-chart-container'>
                            <div className='title'>
                                {title ?? getTitle(legends ?? [])}
                            </div>
                            <Row>
                                <Col xs={12} md={3}>
                                    <BarChartLegends legends={legends}/>
                                </Col>
                                <Col xs={12} md={9} className={'bar-chart-col'}>
                                    <div className='bar-chart'>
                                        <div className='d-flex flex-row flex-nowrap justify-content-start h-100'>
                                            {[null, ...(dataset ?? [])]?.map((data, index) => (
                                                    <ChartDataset
                                                        key={data?.id ?? 'x-axis'}
                                                        dataset={data}
                                                        getSingleBarColor={getSingleBarColor}
                                                        xAxisLabel={xAxisLabel ?? ''}
                                                        maxVal={findMaxVal(dataset ?? [])}
                                                        index={index}
                                                    />
                                                )
                                            )}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    )
            }
        </>
    )
}

export default BarChart;
