import React, {useEffect, useState} from "react";
import classnames from "classnames";
import {ReactComponent as Apps} from "../../../../../assets/images/panel/top-bar/apps.svg";
import {Popover} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import BizPrintImage from '../../../../../assets/logos/bizPrint.png';
import bizChronoImage from '../../../../../assets/logos/bizChrono.png';
import BizCmsImage from '../../../../../assets/logos/bizCms.png';
import {Launch} from "@material-ui/icons";
import CacheService from "../../../../../core/services/cache_service";


//TODO: the following are not done:
// 1. the api call for fetching the applications
// 2. the loading animation

const tempApps = [
    {
        name: 'BizCms',
        url: process.env.REACT_APP_CMS_URL,
        logoUrl: BizCmsImage,
        color: `#0041e1`,
        id: 1
    },
    {
        name: 'BizPrint',
        url: process.env.REACT_APP_PRINT_URL,
        logoUrl: BizPrintImage,
        color: `#feab01`,
        id: 2
    },
    {
        name: 'BizChrono',
        url: process.env.REACT_APP_CHRONO_URL,
        logoUrl: bizChronoImage,
        color: `#5560FF`,
        id: 3
    },
]

const PanelTopbarApplications = ({disabled}) => {
    const [popover, setPopover] = useState();
    const [apps, setApps] = useState([]);
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();

    const translations = t('containers.panel.topbar.apps', {returnObjects: true});


    /**
     * As soon as the component mounts:
     * Fetches the list of available application from the server and sets the state
     */
    useEffect(() => {
        getUserAvailableApplications();
    }, [])

    /**
     * Fetches the list of available applications of the user and sets the state
     */
    const getUserAvailableApplications = () => {
        setLoading(true);
        setApps(tempApps);
        setLoading(false);
    }

    /**
     * Navigates the user to biz core application
     */
    const navigateToCore = () => {
        if (window.bizpire?.navigateToCore) {
            window.bizpire.navigateToCore();
            return;
        }
        const baseUrl = CacheService.getCoreUrls().baseUrl;
        if (!baseUrl) return;
        window.location.replace(baseUrl);
    }

    return (
        <>
            <div
                className={classnames('topbar-icon',
                    {'disabled': disabled},
                    {'active': !!popover},
                )}
                onClick={(e) => setPopover(e.currentTarget)}>
                <Apps/>
            </div>
            <Popover
                id={!!popover ? 'apps' : undefined}
                classes={{paper: 'top-bar-popover apps'}}
                open={!!popover}
                onClose={() => setPopover(null)}
                anchorReference={'anchorEl'}
                anchorEl={popover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div className={'content'}>
                    <div className={'d-flex justify-content-between w-100 mx-3'}>
                        <div className={'title'}>
                            {translations.popover.title}
                        </div>
                        <button
                            className={'button primary text core'}
                            onClick={navigateToCore}
                        >
                            BizCore
                            <Launch/>
                        </button>
                    </div>
                    <div className={'d-flex flex-wrap mt-3'}>
                        {
                            apps?.map(app => (
                                <a
                                    key={app.id}
                                    href={app.url}
                                    className={'app'}
                                    style={{'--color': app.color}}
                                >
                                    <img src={app.logoUrl}
                                         alt={app.name + ' logo'}
                                    />
                                    {app.name}
                                </a>
                            ))
                        }
                    </div>
                </div>
            </Popover>
        </>
    )
}

export default PanelTopbarApplications;
