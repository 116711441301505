import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import useRouter from "../../../../../../hooks/use-router";
import {TableCellAlignments, TableCellTypes} from "../../../../../../../core/constants/enums";
import NoEntries from "../../../../../../components/app-specific/no-entries";
import {ReactComponent as DeleteIcon} from "../../../../../../../assets/images/delete-garbage-icon.svg";
import SpireCountingSearchSection from "../../../../../../components/search-sections/countings/spire-counting/list";
import Table from "../../../../../../containers/table";
import EditSpireCountingDialog from "../../../../../../components/dialogs/counting/spire/edit-spire-counting";
import {routeFunctions} from "../../../../../../routes";
import CreateSpireCountingDialog from "../../../../../../components/dialogs/counting/spire/create-spire-counting";
import moment from "moment";
import {useDispatch} from "react-redux";
import {dialogActions} from "../../../../../../../redux/entities/dialog-slice";
import useIsMounted from "../../../../../../hooks/use-is-mounted";
import useSearchData from "../../../../../../hooks/use-search-data";
import QueryManagementUtils from "../../../../../../../core/services/query-management-utils";
import {SpireCountingListQueryParams} from "../../../../../../../core/constants/query-params";
import {CompanyContext} from "../../../../../../contexts/company";

// Keys for the users table
const tableCellKeys = {
    title: 'title',
    warehouse: 'warehouse',
    submittedBy: 'submittedBy',
    submitDate: 'submitDate',
    expiryDate: "expiryDate",
    edit: "edit",
    view: "view",
    remove: "remove"
}

const SpireCountingListView = () => {
    const {query, location, history, stringifyUrl, params} = useRouter();
    const [loading, setLoading] = useState(true);
    const [spireCountings, setSpireCountings] = useState([]);
    const [isEditingCountingReq, setIsEditingCountingReq] = useState(false);
    const [isAddingCountingReq, setIsAddingCountingReq] = useState(false);
    const [warehouses, setWarehouses] = useState([]);
    const [selectedCountingReq, setSelectedCountingReq] = useState(null);
    const isMounted = useIsMounted();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const translation = t("views.panel.company.counting.spire.list", {returnObjects: true});

    const [company] = useContext(CompanyContext);
    const selectedCompany = useRef(company);

    /**
     * With each change in the selected company:
     */
    useEffect(() => {
        if (selectedCompany.current?.id === company?.id)
            return;
        selectedCompany.current = company;
        //   TODO: implement the action related to this
    }, [company?.id])

    /**
     * Gets the warehouses with initial render
     * // Todo: could be moved to the dialog
     */
    useEffect(() => {
        setWarehouses([
            {
                id: 1,
                title: '00'
            },
            {
                id: 2,
                title: 'warehouse1'
            },
        ]);
    }, [])

    /**
     * Gets the list of spire counting from the server and updates the pagination info length
     * @return {Promise<void>}
     */
    const search = async () => {
        await delay(2000);
        const forApi = prepareRequestDS();
        const response = {
            resultFlag: true,
            data: {
                items: [
                    {
                        id: 1,
                        title: "Counting Test",
                        warehouse: "BC Test",
                        submitter: "Tester",
                        submitDate: '2022-12-02',
                        expiryDate: '2022-12-02',

                    },
                    {
                        id: 2,
                        title: "Counting Test2",
                        warehouse: "Warehouse test",
                        submitter: "Tester",
                        submitDate: '2022-12-02',
                        expiryDate: '2022-12-02',

                    },
                    {
                        id: 3,
                        title: "Counting",
                        warehouse: "BC Test",
                        submitter: "John Doe",
                        submitDate: '2022-12-02',
                        expiryDate: '2022-12-02',

                    },
                    {
                        id: 4,
                        title: "Counting 5",
                        warehouse: "BC Test",
                        submitter: "Dio",
                        submitDate: '2022-12-02',
                        expiryDate: '2022-12-02',

                    },
                    {
                        id: 5,
                        title: "Counting 3",
                        warehouse: "Biz",
                        submitter: "Robot",
                        submitDate: '2022-12-02',
                        expiryDate: '2022-12-02',

                    },
                    {
                        id: 6,
                        title: "Counting",
                        warehouse: "BC",
                        submitter: "Agent",
                        submitDate: '2022-12-02',
                        expiryDate: '2022-12-02',

                    },
                ]
            }
        }
        if (!isMounted())
            return;
        if (response?.resultFlag)
            setSpireCountings(response?.data?.items);
        setLoading(false);
    }

    const {filters, orderBy} = useSearchData(
        SpireCountingListQueryParams,
        search,
        {
            useFilters: true,
            initialFilters: {},
            filtersQueryParamMap: {
                keyword: SpireCountingListQueryParams.keywords,
            },

            usePaginationInfo: false,

            useOrderBy: true,
            initialOrderBy: undefined
        },
    )

    /**
     * Prepares the api request body for getting the list of spire countings
     * @return {{orderBy: Object, filters: Object}}
     */
    const prepareRequestDS = () => {
        return {
            filters: filters,
            orderBy: orderBy
        }
    }

    // TODO: Remove when called the api
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    /**
     * Clears the form values
     */
    const clearFilters = () => {
        //TODO: implement clearing filters
        history.push(stringifyUrl({
            url: location.pathname,
            query: {}
        }));
    }

    /**
     * Shows confirmation dialog regarding removing a counting request
     * - Configs the confirmation dialog with help of redux
     * @param spireCounting
     */
    const onRemoveIconPressed = (spireCounting) => {
        dispatch(dialogActions.configConfirmationDialog({
            open: true,
            onClose: () => dispatch(dialogActions.confirmationDialogToggle(false)),
            onConfirm: () => _onRemovingConfirmed(spireCounting),
        }))
    }

    /**
     * Removes the counting request from the list:
     * - Closes the dialog by dispatching the action regarding this
     * - Calls the api regarding this action
     * @param {*} spireCounting
     */
    const _onRemovingConfirmed = (spireCounting) => {
        dispatch(dialogActions.confirmationDialogToggle(false));
        setSpireCountings(prevState => [...prevState.filter(e => e.id !== spireCounting.id)]);


    }

    /**
     * Handle viewing the spire counting:
     * - Navigates user to spire counting single view
     * - Passes the company and spireCounting id into the url params
     * @param {*} spireCounting
     */
    const onViewSpireCounting = (spireCounting) => {
        history.push(routeFunctions.panel.company.counting.singleSpire(params.company, spireCounting.id));
    }

    /**
     * Handles creating a new spire counting request:
     * - Opens a dialog or navigates user to a page?
     * @param {boolean} shouldOpen
     */
    const onCreateNewSpireCounting = (shouldOpen) => {
        setIsAddingCountingReq(shouldOpen);
    }

    /**
     * Creates a new counting request:
     * - Closes the dialog
     * - Calls the api regarding this action and if it was successful, adds counting request to the list of counting req
     * @param {Object} formValues
     *
     */
    const createCountingRequest = (formValues) => {
        //TODO: Call the api for adding counting request
        setSpireCountings(prevState => [
            {
                id: 100,
                title: formValues.title,
                warehouse: formValues.location.title,
                submitter: "Operator Testing",
                submitDate: moment.now(),
                expiryDate: formValues.expiryDate,

            }, ...prevState]);
        setIsAddingCountingReq(false);
    }

    /**
     * Handles editing a counting request:
     *
     * - Sets the [spireCounting] values in the state
     * - Opens dialog for editing the counting request
     *
     * @param {Object} spireCounting
     * @param {boolean} shouldShowDialog
     */
    const onEditCountingRequest = (spireCounting, shouldShowDialog) => {
        setSelectedCountingReq(spireCounting);
        setIsEditingCountingReq(shouldShowDialog);
    }

    /**
     * Edits counting request:
     * - Calls the api regarding this action and if the api was successful
     * - Closes the dialog
     * - Updates [spireCountings] in the state
     *
     * @param {{title: string, expiryDate: string | Moment}} formValues form field values
     * @param {Object} spireCounting spire counting object being passed form the dialog
     * @param {{title:string, expiryDate: string}} formNames values used for the names of the forms
     */
    const editCountingRequest = (formValues, spireCounting, formNames) => {
        setSpireCountings(prevState => prevState.map(e => {
                if (e.id === spireCounting.id) {
                    return {
                        ...e,
                        [formNames.expiryDate]: formValues?.expiryDate,
                        [formNames.title]: formValues?.title
                    }
                }
                return e;
            })
        );
        setIsEditingCountingReq(false);
    }

    /**
     * Memo version of table columns
     * @type {object[]}
     */
    const tableColumns = useMemo(() => [
        {
            title: translation?.tableHeaders?.title ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: tableCellKeys.title,
            sortable: true
        },
        {
            title: translation?.tableHeaders?.warehouse ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: tableCellKeys.warehouse,
            sortable: true,
        },
        {
            title: translation?.tableHeaders?.submittedBy ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: tableCellKeys.submittedBy,
            sortable: true,
            size: 1.2
        },
        {
            title: translation?.tableHeaders?.submitDate ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.date,
            name: tableCellKeys.submitDate,
            sortable: true,
        },
        {
            title: translation?.tableHeaders?.expiryDate ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.date,
            name: tableCellKeys.expiryDate,
            sortable: true,
        },
        {
            title: '',
            alignment: TableCellAlignments.center,
            type: TableCellTypes.element,
            name: tableCellKeys.edit,
            size: 0
        },
        {
            title: '',
            alignment: TableCellAlignments.center,
            type: TableCellTypes.element,
            name: tableCellKeys.view,
            size: 0
        },
        {
            title: '',
            alignment: TableCellAlignments.center,
            type: TableCellTypes.element,
            name: tableCellKeys.remove,
            last: true,
            size: 0
        }
    ], [translation]);

    /**
     * Memo version of table rows
     * @type {object[]}
     */
    const tableRows = useMemo(() => {
        return spireCountings?.map((spireCounting) => ({
            key: spireCounting.id,
            [tableCellKeys.title]: spireCounting?.title,
            [tableCellKeys.warehouse]: spireCounting?.warehouse,
            [tableCellKeys.submittedBy]: spireCounting?.submitter,
            [tableCellKeys.submitDate]: spireCounting?.submitDate,
            [tableCellKeys.expiryDate]: spireCounting?.expiryDate,
            [tableCellKeys.edit]: () => (
                <button
                    className={'button primary  px-4'}
                    onClick={() => onEditCountingRequest(spireCounting, true)}
                >
                    {translation?.tableActions?.edit ?? ''}
                </button>
            ),
            [tableCellKeys.view]: () => (
                <button
                    className={'button primary outlined px-4'}
                    onClick={() => onViewSpireCounting(spireCounting)}
                >
                    {translation?.tableActions?.view ?? ''}
                </button>
            ),
            [tableCellKeys.remove]: () => (
                <div onClick={(e) => onRemoveIconPressed(spireCounting)}>
                    <DeleteIcon/>
                </div>
            ),
        }))
    }, [spireCountings])

    return (
        <>
            <div className={"spire-counting-list"}>
                <div className={'panel-card p-3 pt-4'}>
                    <div className={'px-4 d-flex align-items-center justify-content-between'}>
                        <p className={'title'}>
                            {translation?.title ?? ''}
                        </p>
                        <button
                            className={'button primary outlined px-4'}
                            onClick={() => onCreateNewSpireCounting(true)}
                        >
                            {translation?.newReq ?? ''}
                        </button>
                    </div>
                    <SpireCountingSearchSection filters={filters}/>
                    <div className={'pb-5'}>
                        {
                            !!spireCountings?.length < 1 && !loading
                                ? <NoEntries
                                    text={
                                        Object.entries(filters ?? {})?.length > 0
                                            ? translation?.empty?.withFilters
                                            : translation?.empty?.text
                                    }
                                    includeButton={Object.entries(filters ?? {})?.length > 0}
                                    buttonProps={{
                                        onClick: clearFilters,
                                        className: 'button primary px-3'
                                    }}/>
                                : <Table
                                    loading={{state: loading, count: 5}}
                                    className={'my-3'}
                                    orderBy={orderBy}
                                    color={'primary'}
                                    onSort={(orderBy) => QueryManagementUtils.setOrderBy(
                                        orderBy,
                                        query,
                                        history,
                                        location,
                                    )}
                                    cells={tableColumns}
                                    data={tableRows}
                                />
                        }
                    </div>

                </div>
            </div>
            <EditSpireCountingDialog
                open={isEditingCountingReq}
                setOpen={setIsEditingCountingReq}
                initData={selectedCountingReq}
                onSubmit={editCountingRequest}
            />
            <CreateSpireCountingDialog
                open={isAddingCountingReq}
                setOpen={setIsAddingCountingReq}
                warehouses={warehouses}
                createCountingReq={createCountingRequest}
            />
        </>
    );
}

export default SpireCountingListView;
