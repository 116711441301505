import {useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {DefaultInitialPaginationInfo, DefaultPaginationInfo, HandlePaginationType} from "../../../core/constants/enums";
import useRouter from "../use-router";
import queryString, {stringifyUrl} from "query-string";
import axios from "axios";
import Utils, {deepCopy, deepEqual} from "../../../core/services/utils";
import {CommonQueryParams, PaginationQueryParams, SortByQueryParams} from "../../../core/constants/query-params";


/**
 * Uses the given config to prepare the search data needed for the search views.
 *
 * This hook does 2 major things:
 * 1. syncs the values of the pagination info + tab of the query with the state (query -> state) and vise versa (state
 * -> query)
 * 2. stores the most updated version of all data necessary for the search to happen.
 *
 * @param {any}          queryParams                         the query params object that this hook uses to look up the
 *                                                              query properties with while syncing.
 * @param {Function}    search                              the search function used to preform the search
 *
 * @param {boolean}     useFilters                          whether to use the filters in the search data
 * @param {any}         initialFilters                      the initial filters data to be set in the state
 * @param {any}         filtersQueryParamMap                the mapping of the filter properties with their query param
 *                                                              properties
 *
 * @param {boolean}     usePaginationInfo                   whether to use the pagination in the search data
 * @param {any}         initialPaginationInfo               the initial pagination info set in the state
 * @param {any}         defaultPaginationInfo               the default pagination info, uses [DefaultPaginationInfo] by default
 * @param {any}         defaultInitialPaginationInfo        the default initial pagination info, uses [DefaultInitialPaginationInfo] by default
 *
 * @param {string}      paginationHandlingType              type of handling the pagination for search
 * @param {function}    onScrollBasedPaginationInfoReset    callback function for clearing the data list used in the corresponding view
 *
 * @param {boolean}     useOrderBy                          whether to use the order by in the search data
 * @param {Record<string, string>}  orderByType             the type of the orderBy object (determines which
 *                                                              properties to use for constructing the orderBy object)
 * @param {any}         initialOrderBy                      initial order by set in the state.
 * @param {any}         defaultInitialOrderBy               default initial order-by to be used when
 *                                                              [resetOrderByOnTabChanged] and tab is changed.
 * @param {boolean}     useTab                              whether to use tabs in the search data
 * @param {any}         defaultTab                          the default tab to be placed as the tab of the query in
 *                                                              the view
 * @param {any}         resetPaginationOnTabChange    the pagination config used while changing the tab
 * @param {any}         resetOrderByOnTabChanged      the order by config used while changing the tab
 * @param {any}         cancelTokenConditions               the conditions that the search callbacks' cancel token source may be cancelled.
 * @returns {{tab: unknown, orderBy: *, paginationInfo: *, filters: {}, loadMore: *, updatePaginationInfo: *}}
 */
// TODO: also take common query params in the args and make QueryManagementUtils methods to also use them.
const useSearchData = (
    queryParams = {},
    search,
    {
        useFilters = false,
        initialFilters = {},
        filtersQueryParamMap = {},

        usePaginationInfo = false,
        initialPaginationInfo,
        defaultPaginationInfo = DefaultPaginationInfo,
        defaultInitialPaginationInfo = DefaultInitialPaginationInfo,

        paginationHandlingType = HandlePaginationType.tablePagination,
        onScrollBasedPaginationInfoReset,

        useOrderBy = false,
        orderByType = {
            property: 'propertyName',
            descending: 'isDescending',
        },
        initialOrderBy = undefined,
        defaultInitialOrderBy = undefined,

        useTab = false,
        defaultTab,
        resetPaginationOnTabChange = paginationHandlingType !== HandlePaginationType.scrollPagination,
        resetOrderByOnTabChanged = paginationHandlingType !== HandlePaginationType.scrollPagination,

        cancelTokenConditions = {
            paginationInfo: false,
            orderBy: false,
            filters: false,
            tab: false,
        },
    }
) => {
    if (!defaultPaginationInfo) defaultPaginationInfo = DefaultPaginationInfo;
    if (!defaultInitialPaginationInfo) defaultInitialPaginationInfo = DefaultInitialPaginationInfo;
    if (!paginationHandlingType) paginationHandlingType = HandlePaginationType.tablePagination;
    if (!orderByType) orderByType = {
        property: 'propertyName',
        descending: 'isDescending',
    }


    const {query, history, location} = useRouter();
    const [filters, setFilters] = useState(undefined);
    const [paginationInfo, setPaginationInfo] = useState(undefined);
    const [orderBy, setOrderBy] = useState(undefined);
    const [tab, setTab] = useState(undefined);
    const mutex = useRef({
        initialSyncer: false,
        paginationResetter: false,
        stateSyncer: false,
        querySyncer: false,
        search: false,
    });

    const newFilters = useRef(undefined);
    const newPaginationInfo = useRef(undefined);
    const newOrderBy = useRef(undefined);
    const newTab = useRef(undefined);

    const preventListenerFromExecuting = useRef({
        paginationResetter: true,
        stateSyncer: true,
        querySyncer: true,
        search: true,
    });

    // ##################  Initial Render (Syncing query with initial props and set state)  ##################

    useLayoutEffect(() => {
        if (mutex.current.initialSyncer)
            return;
        mutex.current.initialSyncer = true;

        const query = {
            ...queryString.parse(window.location.search, {arrayFormat: 'bracket'}),
        };

        let newQuery = deepCopy(query);

        // merge Query filters and initial filters to set in both the state and query
        if (useFilters) {
            const syncedFilters = {
                ...Utils.excludeNullOrUndefined(initialFilters ?? {}, false),
                ...(hasFiltersChanged(false, true) ?? {}),
            };
            setFilters(syncedFilters);
            newQuery = {
                ...newQuery,
                ...Utils.excludeNullOrUndefined(Object.fromEntries(
                    Object.entries(filtersQueryParamMap ?? {})
                        .map(([key, value]) => [
                            value,
                            syncedFilters[key]
                        ])
                ), false)
            }
        }

        // merge Query pagination and initial pagination to set in both the state and query
        if (usePaginationInfo) {
            let syncedPaginationInfo;
            if (paginationHandlingType !== HandlePaginationType.scrollPagination) {
                syncedPaginationInfo = ({
                    ...Utils.excludeNullOrUndefined(initialPaginationInfo ?? {}),
                    // default comes after initial pagination info since we want to force a searchable currentPage not a
                    // reset flag.
                    ...Utils.excludeNullOrUndefined(defaultPaginationInfo),
                    ...Utils.excludeNullOrUndefined(hasPaginationChanged(false, true) ?? {}),
                });
            } else {
                syncedPaginationInfo = ({
                    ...Utils.excludeNullOrUndefined(initialPaginationInfo ?? {}),
                    ...Utils.excludeNullOrUndefined(defaultPaginationInfo),
                })
            }

            if (syncedPaginationInfo.currentPage === defaultInitialPaginationInfo.currentPage) {
                syncedPaginationInfo.currentPage = defaultPaginationInfo?.currentPage;
            }

            setPaginationInfo(syncedPaginationInfo);
            if (paginationHandlingType !== HandlePaginationType.scrollPagination) {
                newQuery = {
                    ...newQuery,
                    [PaginationQueryParams.pageSize]: syncedPaginationInfo.pageSize,
                    [PaginationQueryParams.currentPage]: syncedPaginationInfo.currentPage,
                    [PaginationQueryParams.length]: syncedPaginationInfo.length,
                }
            } else {
                newQuery = {
                    ...newQuery,
                    [PaginationQueryParams.pageSize]: undefined,
                    [PaginationQueryParams.currentPage]: undefined,
                    [PaginationQueryParams.length]: undefined,
                }
            }
        }

        // merge Query orderBy and initial orderBy to set in both the state and query
        if (useOrderBy) {
            let syncedOrderBy = {
                ...Utils.excludeNullOrUndefined(initialOrderBy ?? {}),
                ...(hasOrderByChanged(false, true) ?? {}),
            };
            if (!Object.keys(syncedOrderBy).length)
                syncedOrderBy = undefined;
            setOrderBy(syncedOrderBy);
            newQuery = {
                ...newQuery,
                [SortByQueryParams.property]: syncedOrderBy?.[orderByType.property],
                [SortByQueryParams.descending]: syncedOrderBy?.[orderByType.descending],
            }
        }

        // merge Query tab and initial tab to set in both the state and query
        if (useTab) {
            const syncedTab = hasTabChanged(false, true) ?? defaultTab;
            setTab(syncedTab);
            if (syncedTab !== query[CommonQueryParams.tab]) {
                newQuery = {
                    ...newQuery,
                    [CommonQueryParams.tab]: syncedTab,
                }
                if (resetPaginationOnTabChange && paginationHandlingType !== HandlePaginationType.scrollPagination) {
                    newQuery = {
                        ...newQuery,
                        [PaginationQueryParams.length]: defaultInitialPaginationInfo?.length,
                        [PaginationQueryParams.currentPage]: defaultInitialPaginationInfo?.currentPage,
                        [PaginationQueryParams.pageSize]: defaultInitialPaginationInfo?.pageSize,
                    }
                } else {
                    newQuery = {
                        ...newQuery,
                        [PaginationQueryParams.length]: undefined,
                        [PaginationQueryParams.currentPage]: undefined,
                        [PaginationQueryParams.pageSize]: undefined,
                    }
                }
                if (resetOrderByOnTabChanged) {
                    newQuery = {
                        ...newQuery,
                        [SortByQueryParams.property]: defaultInitialOrderBy?.[orderByType.property],
                        [SortByQueryParams.descending]: defaultInitialOrderBy?.[orderByType.descending],
                    }
                }
            }
            newQuery = {
                ...newQuery,
                [CommonQueryParams.tab]: syncedTab,
            }
        }

        if (deepEqual(query, newQuery))
            return (mutex.current.initialSyncer = false) || void 0;

        // only change the query when it is actually changed.

        // since the state itself is fully set here, there is no need for the query to be synced or listened to
        // execute
        preventListenerFromExecuting.current.stateSyncer = true;
        preventListenerFromExecuting.current.querySyncer = true;
        history.replace(stringifyUrl({
            url: location.pathname,
            fragmentIdentifier: location.hash,
            query: newQuery
        }), {arrayFormat: 'bracket'});

        mutex.current.initialSyncer = false;
    }, [])

    // ##################  Sync State with values of Query  ##################

    /**
     * With each change in the query of the url parameters:
     *
     * - determines if any of the state values are not in sync with the query, and if so syncs the state with the query
     * - if there are any changes detected,
     *      and the pagination info is not directly changed, then resets the pagination info
     *      and the pagination info is not directly changed, then resets the pagination info
     */
    useEffect(() => {
        if (preventListenerFromExecuting.current.stateSyncer) {
            return (preventListenerFromExecuting.current.stateSyncer = false) || void 0;
        }

        if (mutex.current.stateSyncer)
            return;
        mutex.current.stateSyncer = true;

        let resetPaginationInfo = false;

        if (useFilters)
            resetPaginationInfo = resetPaginationInfo || hasFiltersChanged();

        if (useOrderBy)
            resetPaginationInfo = resetPaginationInfo || hasOrderByChanged();

        if (useTab)
            resetPaginationInfo = resetPaginationInfo || hasTabChanged();

        if (usePaginationInfo && paginationHandlingType !== HandlePaginationType.scrollPagination)
            resetPaginationInfo = !hasPaginationChanged() && resetPaginationInfo;

        if (usePaginationInfo && resetPaginationInfo) {
            // scrolled based pagination
            if (paginationHandlingType === HandlePaginationType.scrollPagination) {
                if (onScrollBasedPaginationInfoReset)
                    onScrollBasedPaginationInfoReset();
                return setPaginationInfo(initialPaginationInfo);
            }
            setPaginationInfo(prevState => ({
                ...(prevState ?? {}),
                currentPage: initialPaginationInfo?.currentPage ?? defaultInitialPaginationInfo?.currentPage,
            }))
            // table based pagination
        }
        mutex.current.stateSyncer = false;
    }, [query])


    // ##################  Sync Query with values of State (Pagination + Tab)  ##################

    /**
     * With each change in the current page of the paginationInfo:
     * - if the pagination's current page indicates a reset flag, then resets the current apge.
     */
    useLayoutEffect(() => {
        if (!usePaginationInfo)
            return;
        if (preventListenerFromExecuting.current.paginationResetter)
            return (preventListenerFromExecuting.current.paginationResetter = false) || void 0;

        if (paginationInfo?.currentPage === defaultInitialPaginationInfo.currentPage)
            setPaginationInfo(prevState => ({
                ...(prevState ?? {}),
                currentPage: defaultPaginationInfo?.currentPage,
            }))
    }, [paginationInfo?.currentPage])

    /**
     * With each change in the pagination state's value and tab's state value:
     * - syncs the query with the values of the pagination and tab state values.
     */
    useLayoutEffect(() => {
        if (preventListenerFromExecuting.current.querySyncer) {
            return (preventListenerFromExecuting.current.querySyncer = false) || void 0;
        }

        if (paginationInfo?.currentPage === defaultInitialPaginationInfo.currentPage)
            return;

        if (mutex.current.querySyncer)
            return;
        mutex.current.querySyncer = true;

        const query = {
            ...queryString.parse(window.location.search, {arrayFormat: 'bracket'}),
        };
        let newQuery = deepCopy(query);

        if (usePaginationInfo) {
            const paginationInQuery = {
                pageSize: query[PaginationQueryParams.pageSize],
                currentPage: query[PaginationQueryParams.currentPage],
                length: query[PaginationQueryParams.length],
            }
            if (!deepEqual(paginationInQuery, paginationInfo)) {
                if (paginationHandlingType !== HandlePaginationType.scrollPagination) {
                    newQuery = {
                        ...(newQuery ?? {}),
                        [PaginationQueryParams.length]: paginationInfo?.length ?? defaultPaginationInfo.length,
                        [PaginationQueryParams.currentPage]: paginationInfo?.currentPage ?? defaultPaginationInfo.currentPage,
                        [PaginationQueryParams.pageSize]: paginationInfo?.pageSize ?? defaultPaginationInfo.pageSize,
                    }
                } else {
                    newQuery = {
                        ...newQuery,
                        [PaginationQueryParams.pageSize]: undefined,
                        [PaginationQueryParams.currentPage]: undefined,
                        [PaginationQueryParams.length]: undefined,
                    }
                }
            }
        }

        let newTab = tab ?? defaultTab;
        if (useTab) {
            if (query[CommonQueryParams.tab] !== newTab) {
                newQuery = {
                    ...newQuery,
                    [CommonQueryParams.tab]: newTab,
                }
            }
        }

        if (deepEqual(newQuery, query))
            return;

        history.replace(stringifyUrl({
            url: location.pathname,
            fragmentIdentifier: location.hash,
            query: newQuery
        }), {arrayFormat: 'bracket'});

        mutex.current.querySyncer = false;
    }, [
        paginationInfo?.currentPage,
        paginationInfo?.pageSize,
        paginationInfo?.length,
        tab,
    ])


    // ##################  State Mutators  ##################

    /**
     * Determines whether the filters of the query has changed.
     *
     * * if [setState] then sets the state if the value has changed.
     * * if [returnResult] then returns the new state instead of the changed flag.
     * @param {boolean} setState
     * @param {boolean} returnResult
     * @returns {boolean | any}
     */
    const hasFiltersChanged = (setState = true, returnResult = false) => {
        const newFilters =
            Utils.excludeNullOrUndefined(Object.fromEntries(
                Object.entries(filtersQueryParamMap ?? {})
                    .map(([key, value]) => [
                        key,
                        query[value]
                    ])
            ), false)
        const changed = !deepEqual(filters, newFilters);

        if (changed && setState)
            setFilters(newFilters);

        if (returnResult)
            return newFilters;
        return changed;
    }

    /**
     * Determines whether the pagination of the query has changed.
     *
     * * if [setState] then sets the state if the value has changed.
     * * if [returnResult] then returns the new state instead of the changed flag.
     * @param {boolean} setState
     * @param {boolean} returnResult
     * @returns {boolean | any}
     */
    const hasPaginationChanged = (setState = true, returnResult = false) => {
        const newPageSize = query[PaginationQueryParams.pageSize] ?? null;
        const newCurrentPage = query[PaginationQueryParams.currentPage] ?? null;
        const newTotal = query[PaginationQueryParams.length] ?? null;

        let newPaginationInfo = {
            pageSize: newPageSize
                ? parseInt(newPageSize)
                : defaultPaginationInfo.pageSize,
            currentPage: newCurrentPage
                ? parseInt(newCurrentPage)
                : defaultPaginationInfo.currentPage,
            length: newTotal
                ? parseInt(newTotal)
                : defaultPaginationInfo.length,
        };
        if (newPaginationInfo?.currentPage === defaultInitialPaginationInfo?.currentPage) {
            newPaginationInfo.currentPage = defaultPaginationInfo?.currentPage;
        }

        let changed = !deepEqual(newPaginationInfo, paginationInfo);

        if (changed && setState) {
            setPaginationInfo(newPaginationInfo);
        }

        if (returnResult)
            return newPaginationInfo;

        // the change itself may only consider currentPage and pageSize
        return !deepEqual(
            {c: paginationInfo?.currentPage, s: paginationInfo?.pageSize},
            {c: newPaginationInfo?.currentPage, s: newPaginationInfo?.pageSize}
        );
    }

    /**
     * Determines whether the order by of the query has changed.
     *
     * * if [setState] then sets the state if the value has changed.
     * * if [returnResult] then returns the new state instead of the changed flag.
     * @param {boolean} setState
     * @param {boolean} returnResult
     * @returns {boolean | any}
     */
    const hasOrderByChanged = (setState = true, returnResult = false) => {
        let newOrderBy;
        const newOrderByName = query[SortByQueryParams.property] ?? null;
        const isDescending = query[SortByQueryParams.descending] ?? null;
        if (newOrderByName !== null && isDescending !== null) {
            newOrderBy = {
                [orderByType.property]: newOrderByName,
                [orderByType.descending]: isDescending === 'true',
            };
        }

        const changed = !deepEqual(newOrderBy, orderBy);

        if (changed && setState) {
            setOrderBy(newOrderBy);
        }

        if (returnResult)
            return newOrderBy;
        return changed;
    }

    /**
     * Determines whether the tab of the query has changed.
     *
     * * if [setState] then sets the state if the value has changed.
     * * if [returnResult] then returns the new state instead of the changed flag.
     * @param {boolean} setState
     * @param {boolean} returnResult
     * @returns {boolean | any}
     */
    const hasTabChanged = (setState = true, returnResult = false) => {
        const newTab = query[CommonQueryParams.tab];
        const changed = !deepEqual(tab, newTab);

        if (changed && setState)
            setTab(newTab);

        if (returnResult)
            return newTab;
        return changed;
    }


    // ##################       Search     ##################

    /**
     * With each change in the pagination current page and pageSize, tab, filters, and orderBy:
     * - Sets the values of the refs to the new value
     */
    useLayoutEffect(() => {
        newPaginationInfo.current = {
            c: paginationInfo?.currentPage,
            s: paginationInfo?.pageSize
        };
        newTab.current = tab;
        newFilters.current = filters;
        newOrderBy.current = orderBy;
    }, [paginationInfo?.currentPage, paginationInfo?.pageSize, tab, filters, orderBy])

    /**
     * With each change in state properties of this hook:
     * - if tab does not exist and [useTab] flag is set, does nothing since the tab should be set first (done by the
     *      syncTab function of this hook)
     * - if pagination info has a value specific for resting, and [usePaginationInfo] flag is set, does nothing
     *      since the pagination info should first be set to a proper state (done by the syncPagination function of this
     *      hook)
     *  - otherwise invokes the search function.
     */
    useEffect(() => {
        if (preventListenerFromExecuting.current.search) {
            return (preventListenerFromExecuting.current.search = false) || void 0;
        }

        if (usePaginationInfo && paginationInfo?.currentPage === defaultInitialPaginationInfo.currentPage) {
            return;
        }

        if (useFilters && filtersNotInSyncWithQuery())
            return;
        if (usePaginationInfo && paginationNotInSyncWithQuery()
            && paginationHandlingType !== HandlePaginationType.scrollPagination)
            return;
        if (useOrderBy && orderByNotInSyncWithQuery())
            return;
        if (useTab && tabNotInSyncWithQuery())
            return;

        if (!Object.values(cancelTokenConditions ?? {}).some(e => !!e)) {
            search();
            return;
        }
        const source = axios.CancelToken.source();
        search(source);
        return () => shouldCancelSearch(source)

    }, [paginationInfo?.currentPage, paginationInfo?.pageSize, tab, filters, orderBy])

    /**
     * Determines if the filters' property is in sync with the query or not.
     * @return {boolean}
     */
    const filtersNotInSyncWithQuery = () => {
        return (!deepEqual(filters, hasFiltersChanged(false, true)))
    }

    /**
     * Determines if the pagination property is in sync with the query or not.
     * @return {boolean}
     */
    const paginationNotInSyncWithQuery = () => {
        const change = hasPaginationChanged(false, true) ?? undefined;
        if (change)
            return (!deepEqual(
                {c: paginationInfo?.currentPage, s: paginationInfo?.pageSize},
                {c: change?.currentPage, s: change?.pageSize}
            ))
        return false;
    }

    /**
     * Determines if the order by property is in sync with the query or not.
     * @return {boolean}
     */
    const orderByNotInSyncWithQuery = () => {
        const change = hasOrderByChanged(false, true) ?? undefined;
        if (change)
            return (!deepEqual(orderBy, change))
        return false;
    }

    /**
     * Determines if the tab property is in sync with the query or not.
     * @return {boolean}
     */
    const tabNotInSyncWithQuery = () => {
        const change = hasTabChanged(false, true) ?? undefined;
        if (change)
            return (!deepEqual(tab, change))
        return false;
    }

    /**
     * Determines if the cancel token should be invoked for the api based on changes in the state data
     * @param source
     */
    const shouldCancelSearch = (source) => {
        let shouldCancel = false;

        const _paginationInfo = {
            c: paginationInfo?.currentPage,
            s: paginationInfo?.pageSize
        }
        if (cancelTokenConditions.paginationInfo && !deepEqual(_paginationInfo, newPaginationInfo.current))
            shouldCancel = true;

        if (cancelTokenConditions.filters && !deepEqual(filters, newFilters.current))
            shouldCancel = true;

        if (cancelTokenConditions.orderBy && !deepEqual(orderBy, newOrderBy.current))
            shouldCancel = true;

        if (cancelTokenConditions.tab && !deepEqual(tab, newTab.current))
            shouldCancel = true;

        if (shouldCancel)
            source.cancel();
    }

    // ##################       Return Value     ##################


    /**
     * Handles loading more data as the loaded data has reached the threshold for loading more data
     */
    const loadMore = () => {
        setPaginationInfo(prevState => ({...prevState, currentPage: prevState.currentPage + 1}))
    }

    /**
     * Updates the pagination info without changing the query
     * @param {object} newPagination
     */
    const updatePaginationInfo = (newPagination) => {
        setPaginationInfo(newPagination);
    }

    /**
     * The memo version of all properties that this hook has control over.
     */
    return useMemo(() => ({
        filters,
        paginationInfo,
        orderBy,
        tab,
        loadMore,
        updatePaginationInfo,
    }), [
        filters,
        paginationInfo,
        orderBy,
        tab,
    ])

}

export default useSearchData;
