import {createSlice} from "@reduxjs/toolkit";

// Initial state of the dialog slice being passed to the redux slice
const initialState = {
    confirmation: {
        open: false,
        closeButtonLabel: 'CANCEL',
        confirmButtonLabel: 'CONFIRM',
        onClose: null,
        onConfirm: null,
        title: 'Confirmation',
        description: 'Do you want to continue? by confirming this action you will not have access to these information in the future.',
        props:null,
    },
    barcode:{
        open:false,
        closeButtonLabel: "CLOSE",
        onClose: null,
        title: 'Scan By App',
        barcodeCode: null,
        props:null,
    }
}

/**
 * Redux slice for handling general dialogs in the application
 */
const dialogSlice = createSlice({
        name: "dialog",
        initialState: initialState,
        reducers: {
            configConfirmationDialog(state, action) {
                state.confirmation = {
                    ...initialState.confirmation,
                    ...action.payload,
                }
            },
            confirmationDialogToggle(state,action) {
                state.confirmation.open = action.payload;
            },
            configBarcodeDialog(state,action) {
                state.barcode = {
                    ...initialState.barcode,
                    ...action.payload,
                }
            },
            barcodeDialogToggle(state,action) {
                state.barcode.open = action.payload;
            },
        }
    }
);


export const dialogActions = dialogSlice.actions;

export default dialogSlice;
