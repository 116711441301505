import React, {useContext, useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import FormSection from "./form";
// import MapBox from "../../../../../../components/app-specific/mapbox";
import {RegistrationContext} from "../../../../../../contexts/registration";
import {registrationStepperStepIds} from "../../../../../../../core/constants/ids";
import MapBox from "../../../../../../components/app-specific/mapbox";


const RegistrationViewBusinessInfoSection = () => {
    const [registrationState] = useContext(RegistrationContext);
    const [provinces, setProvinces] = useState([]);
    const [countries, setCountries] = useState([]);
    const [companyTypes, setCompanyTypes] = useState([]);
    const [tempAddress, setTempAddress] = useState({});

    useEffect(() => {
        //TODO: make the useEffect dependant to the language
        getCompanyTypes();
        getCountriesAndProvinces();
    }, []);

    /**
     * Gets the list of company types from the server and populates the state list
     */
    const getCompanyTypes = () => {
        //TODO: call api for fetching the list of company types
        setCompanyTypes([{
            id: 1,
            name: "Type 1"
        },
            {
                id: 2,
                name: "Type 2"
            }]);
    }

    /**
     * Gets the list of countries and provinces from the server and populates the state list
     */
    const getCountriesAndProvinces = () => {
        //TODO: call api for fetching the list of company types
        setCountries([
            {
                id: 1,
                name: "Canada",
                code: 'CA',
            },
            {
                id: 2,
                name: "USA",
                code: 'US',
            }]);
        setProvinces([
            {
                id: 1,
                name: 'British Columbia',
                countryId: 1,
            },
            {
                id: 2,
                name: 'Ontario',
                countryId: 1,
            },
            {
                id: 3,
                name: 'California',
                countryId: 2,
            },
            {
                id: 4,
                name: 'Texas',
                countryId: 2,
            },
        ])
    }


    /**
     * Sets the address of the business by the address extracted by Mapbox.
     *
     * Finds and sets the country and province of the Mapbox with data from server if they are found
     * @param {MapboxAddress} address
     */
    const onMapboxAddressSelect = (address) => {
        address = findInternalProvinceAndCountryBasedOnMapboxAddress(address);
        setTempAddress(address);
    }

    /**
     * Finds the province and country of the mapbox address and replaces them with their object from server.
     *
     * If they can not be found, removes the properties
     * @param {{country: string, address: string, province: string, postalCode: string}} address
     * @return {*}
     */
    const findInternalProvinceAndCountryBasedOnMapboxAddress = (address) => {
        if (!address.province?.length) {
            delete address.province;
        }
        const foundProvince = provinces.find(e => e.name.toLowerCase().trim() === address.province?.toLowerCase()?.trim());
        // province found, set both province and country
        if (foundProvince) {
            address['province'] = foundProvince
            address.country = countries.find(e => e.id === address.province.countryId);
            return address;
        }
        // remove province and check to find the country
        delete address.province;
        const foundCountry = countries.find(e => e.name.toLowerCase().trim() === address.country.toLowerCase().trim());
        // country found, set it
        if (foundCountry) {
            address.country = foundCountry;
            return address;
        }
        // remove country and return
        delete address.country;
        return address;
    }

    /**
     * Constructs the starting address of mapbox based on the saved address in businessInfo state
     * @return {null}
     */
    const getStartingMapboxAddress = () => {
        const businessInfoAddress = registrationState[registrationStepperStepIds.businessInfo]?.address;
        if (!businessInfoAddress) {
            return null;
        }
        const address = [
            businessInfoAddress.address,
            businessInfoAddress.postalCode,
            //TODO: maybe change name after api is ready
            businessInfoAddress.province?.name,
        ]
        return {
            country: businessInfoAddress.country?.code,
            address: address.filter(e => e?.length)?.join(' ')?.trim() ?? '',
        };
    }

    return (
        <>
            <Row className={'business-info'}>
                {/*MapBox*/}
                <Col xs={12} md={6} className={'order-1 order-md-2 mb-0 mb-sm-3'}>
                    <MapBox
                        startFromSepcificAddress
                        getStartingAddress={getStartingMapboxAddress}
                        onAddressSelect={onMapboxAddressSelect}
                    />
                </Col>
                {/*Business Info Form*/}
                <Col xs={12} md={6} className={'order-2 order-md-1 flex-row-center mb-5'}>
                    <FormSection
                        provinces={provinces}
                        countries={countries}
                        companyTypes={companyTypes}
                        tempAddress={tempAddress}
                    />
                </Col>
            </Row>

        </>
    )
}


export default RegistrationViewBusinessInfoSection;
