import {Rect, Transformer} from "react-konva";
import Konva from "konva";
import {Box} from "konva/lib/shapes/Transformer";
import React, {forwardRef, useCallback, useLayoutEffect, useMemo, useRef, useState} from "react";
import {KonvaNodeEvents} from "react-konva/ReactKonvaCore";
import BoundText from "../bound-text";


export type IBoundRectProps = {
    transformerProps?: Omit<Konva.TransformerConfig & KonvaNodeEvents, 'ref' | 'onDragMove' | 'boundBoxFunc'>,
    rectProps?: Konva.RectConfig & KonvaNodeEvents,
    interactive: boolean,
    parent: Konva.Node,
    padding?: number,
    boundThreshold?: number,
    minSize?: {
        width?: number,
        height?: number,
    },
    remove: () => void,
    rotate: () => void,
    allowRemoval?: boolean,
    allowRotation?: boolean,
}

const BoundRect = forwardRef<Konva.Rect, IBoundRectProps>((
    {
        transformerProps,
        rectProps,
        parent,
        padding = 0,
        interactive,
        boundThreshold = 0.0001,
        minSize,
        remove,
        rotate,
        allowRemoval = false,
        allowRotation = false
    },
    ref) => {
    const [rect, setRect] = useState<Konva.Rect>();
    const [dragging, setDragging] = useState(false);
    const transformer = useRef<Konva.Transformer | null>();
    const rotationSnaps = useMemo(() => [0, 90, 180, 270], []);
    const lastCursorValue = useRef<string>();
    const shadowRectRef = useRef<Konva.Node | null>(null);

    /**
     * With each change in the [name] of the [transformerProps] and [rect]'s state value:
     * - adds the transformer's name to the [rect]'s name list, and remove it when a new name is to be added.
     */
    useLayoutEffect(() => {
        if (!rect || !transformerProps?.name)
            return;
        rect.addName(transformerProps.name);
        return () => {
            rect.removeName(transformerProps.name);
        };
    }, [transformerProps?.name, rect]);

    /**
     * Fetches one of the edge points of a box provided its pivotal points and angle.
     *
     * @param pivotX
     * @param pivotY
     * @param diffX
     * @param diffY
     * @param angle
     */
    const getCorner = useCallback((pivotX: number, pivotY: number, diffX: number, diffY: number, angle: number) => {
        const distance = Math.sqrt(diffX * diffX + diffY * diffY);
        /// find angle from pivot to corner
        angle += Math.atan2(diffY, diffX);
        /// get new x and y and round it off to integer
        const x = pivotX + distance * Math.cos(angle);
        const y = pivotY + distance * Math.sin(angle);
        return {x: x, y: y};
    }, [])

    /**
     * Fetches the client rect measurements of a rotated box.
     * @param rotatedBox
     */
    const getClientRect = useCallback((rotatedBox: Box) => {
        const {x, y, width, height, rotation} = rotatedBox;

        const p1 = getCorner(x, y, 0, 0, rotation);
        const p2 = getCorner(x, y, width, 0, rotation);
        const p3 = getCorner(x, y, width, height, rotation);
        const p4 = getCorner(x, y, 0, height, rotation);

        const minX = Math.min(p1.x, p2.x, p3.x, p4.x);
        const minY = Math.min(p1.y, p2.y, p3.y, p4.y);
        const maxX = Math.max(p1.x, p2.x, p3.x, p4.x);
        const maxY = Math.max(p1.y, p2.y, p3.y, p4.y);

        const stage = parent.getClassName() === 'Stage' ? parent : parent.getStage();
        const strokeWidth = (rect?.strokeWidth?.() ?? 0) * (stage?.scaleX() ?? 0);
        return {
            x: minX + (strokeWidth / 2),
            y: minY + (strokeWidth / 2),
            width: maxX - minX,
            height: maxY - minY,
        };
    }, [getCorner, parent, rect])

    /**
     * Fetches the client rect of an arbitrary box that wraps a series of boxes in the canvas.
     * @param boxes
     */
    const getTotalBox = useCallback((boxes: Array<ReturnType<typeof getClientRect>>) => {
        let minX = Infinity;
        let minY = Infinity;
        let maxX = -Infinity;
        let maxY = -Infinity;
        boxes.forEach((box) => {
            minX = Math.min(minX, box.x);
            minY = Math.min(minY, box.y);
            maxX = Math.max(maxX, box.x + box.width);
            maxY = Math.max(maxY, box.y + box.height);
        });
        return {
            x: minX,
            y: minY,
            width: maxX - minX,
            height: maxY - minY,
        };
    }, [])

    /**
     * Fetches the size of the parent having accounted for the scale of the stage.
     */
    const getParentSize = useCallback(() => {
        const size = parent.size();
        const stage = parent.getClassName() === 'Stage' ? parent : parent.getStage();
        if (rect) {
            const strokeWidth = rect.strokeWidth();
            size.width += strokeWidth;
            size.height = size.height + strokeWidth;
        }
        if (stage) {
            size.width *= stage.scaleX();
            size.height *= stage.scaleY();
        }
        return size;
    }, [parent, rect])

    /**
     * Contains the rectangle within the bounds of its parent as the rectangle is being moved around.
     */
    const onTransformerDragMove = useCallback(() => {
        if (!transformer.current)
            return;
        const boxes = transformer.current.nodes().map((node) => node.getClientRect());
        const box = getTotalBox(boxes);
        const parentSize = getParentSize();
        const parentAbsPosition = parent.getAbsolutePosition();

        for (const shape of transformer.current.nodes()) {
            const absPos = shape.getAbsolutePosition();

            // we total box goes outside of viewport, we need to move absolute position of shape
            const newAbsPos = {...absPos};
            if ((box.x - parentAbsPosition.x) < padding) {
                newAbsPos.x = parentAbsPosition.x;
            }
            if ((box.y - parentAbsPosition.y) < padding) {
                newAbsPos.y = parentAbsPosition.y;
            }

            if ((box.x - parentAbsPosition.x) + box.width > (parentSize.width - padding)) {
                newAbsPos.x = parentAbsPosition.x + parentSize.width - box.width;
            }
            if ((box.y - parentAbsPosition.y) + box.height > (parentSize.height - padding)) {
                newAbsPos.y = parentAbsPosition.y + parentSize.height - box.height;
            }
            shape.setAbsolutePosition(newAbsPos);
        }
    }, [getParentSize, getTotalBox, padding, parent])

    /**
     * Contains the rectangle within the bounds of its parent as the rectangle is being resized or rotated.
     *
     * @param oldBox
     * @param newBox
     */
    const transformerBoundBoxFunc = useCallback((oldBox: Box, newBox: Box) => {
        const box = getClientRect(newBox);
        const parentSize = getParentSize();
        const parentAbsPosition = parent.getAbsolutePosition();
        const scale = ((parent.getClassName() === 'Stage' ? parent : parent.getStage())?.scaleX() ?? 0);
        const _boundThreshold = boundThreshold * (10 ** scale)

        const isOut =
            ((box.x - parentAbsPosition.x) - padding < 0 && Math.abs((box.x - parentAbsPosition.x) - padding) > _boundThreshold) ||
            ((box.y - parentAbsPosition.y) - padding < 0 && Math.abs((box.y - parentAbsPosition.y) - padding) > _boundThreshold) ||
            ((box.x - parentAbsPosition.x) + box.width - ((parentSize.width) - padding) > 0 && Math.abs((box.x - parentAbsPosition.x) + box.width - ((parentSize.width) - padding)) > _boundThreshold) ||
            ((box.y - parentAbsPosition.y) + box.height - ((parentSize.height) - padding) > 0 && Math.abs((box.y - parentAbsPosition.y) + box.height - ((parentSize.height) - padding)) > _boundThreshold);

        let lessThanMinimSize = false;
        if (minSize) {
            if (!!minSize.width && (minSize.width * scale) > (box.width)) {
                lessThanMinimSize = true;
            } else if (!!minSize.height && (minSize.height * scale) > (box.height)) {
                lessThanMinimSize = true;
            }
        }
        // if new bounding box is out of visible viewport or min-size is not respected, skip transforming
        // this logic can be improved by still allow some transforming if we have small available space
        if (isOut || lessThanMinimSize) {
            oldBox.x = Math.min(
                Math.max(oldBox.x, parentAbsPosition.x - scale * ((rect?.strokeWidth() ?? 0) / 2)),
                parentAbsPosition.x + parentSize.width - scale * ((rect?.strokeWidth() ?? 0) / 2)
            );
            oldBox.y = Math.min(
                Math.max(oldBox.y, parentAbsPosition.y - scale * ((rect?.strokeWidth() ?? 0) / 2)),
                parentAbsPosition.y + parentSize.height - scale * ((rect?.strokeWidth() ?? 0) / 2)
            );
            return oldBox;
        }
        return newBox;
    }, [boundThreshold, getClientRect, getParentSize, minSize, padding, parent, rect])

    /**
     * Assigns the provided [_ref] instance to both the [rect]'s ref object and the forwarded ref of the component.
     * @param _ref
     */
    const assignRectRef = useCallback((_ref: Konva.Rect | null) => {
        setRect(_ref as Konva.Rect);
        if (typeof ref === 'function') {
            ref(_ref);
        } else if (ref !== null && typeof ref !== 'undefined') {
            ref.current = _ref;
        }
    }, [ref])

    /**
     * Sets the dragging state value of this component to [True].
     */
    const onRectDragMove = useCallback<(e: Konva.KonvaEventObject<DragEvent>) => void>((e) => {
        setDragging(true);
        if (rectProps?.onDragMove) {
            rectProps.onDragMove(e);
        }
    }, [rectProps])

    /**
     * Sets the dragging state value of this component to [False].
     */
    const onRectDragEnd = useCallback<(e: Konva.KonvaEventObject<DragEvent>) => void>((e) => {
        setDragging(false);
        if (rectProps?.onDragEnd) {
            rectProps.onDragEnd(e);
        }
    }, [rectProps])

    /**
     * Resets the scale of the rect when it is being transformed.
     *
     * transformer is changing scale of the node and NOT its width or height but in the store we have only width and height to match
     * the data better we will reset scale on transform.
     * @param e
     */
    const onRectTransformed = useCallback<(e: Konva.KonvaEventObject<Event>) => void>((e) => {
        const node = rect as Konva.Rect;
        const scaleX = node.scaleX();
        const scaleY = node.scaleY();

        // we will reset it back
        node.scaleX(1);
        node.scaleY(1);
        node.width(node.width() * scaleX);
        node.height(node.height() * scaleY);

        if (rectProps?.onTransform) {
            rectProps.onTransform(e);
        }
        if (shadowRectRef.current) {
            shadowRectRef.current.setAttrs({
                width: node.width(),
                height: node.height(),
                x: node.x(),
                y: node.y(),
            });
        }
    }, [rect, rectProps]);

    /**
     * Changes the cursor of the stage as soon as the pointer enters the rect.
     */
    const onRectMouseEnter = useCallback((e) => {
        if (rectProps?.onMouseEnter) {
            rectProps.onMouseEnter(e);
        }
        if (!rectProps?.cursor)
            return;
        const stage = rect?.getStage();
        if (!stage)
            return;
        lastCursorValue.current = stage.container().style.cursor;
        stage.container().style.cursor = rectProps.cursor;
    }, [rect, rectProps]);

    /**
     * Resets the cursor of the stage as soon as the pointer leaves the rect.
     */
    const onRectMouseLeave = useCallback((e) => {
        if (rectProps?.onMouseLeave) {
            rectProps.onMouseLeave(e);
        }
        if (!rectProps?.cursor)
            return;
        const stage = rect?.getStage();
        if (!stage)
            return;
        stage.container().style.cursor = lastCursorValue.current ?? 'default';
    }, [rect, rectProps]);

    const buttons = useMemo(() => {
        const res = [];
        if (allowRotation) {
            res.push({
                name: 'rotate',
                path: '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><title>box-configurator-rotate</title><circle cx="8" cy="8" r="8" style="fill:#fff"/><path d="M0.9,0.5c0.1,0,0.3,0.1,0.3,0.3L1.1,2.9c1-1.4,2.6-2.4,4.5-2.4c2.9,0,5.3,2.4,5.3,5.3c0,2.9-2.4,5.3-5.3,5.3c-1.4,0-2.6-0.5-3.6-1.4c-0.1-0.1-0.1-0.3,0-0.4L2.3,9c0.1-0.1,0.3-0.1,0.4,0c0.7,0.7,1.7,1.1,2.8,1.1c2.3,0,4.2-1.9,4.2-4.2S7.8,1.7,5.5,1.7c-1.7,0-3.2,1-3.8,2.5l2.7-0.1c0.1,0,0.3,0.1,0.3,0.3v0.6c0,0.1-0.1,0.3-0.3,0.3H0.3C0.1,5.2,0,5.1,0,4.9V0.8c0-0.1,0.1-0.3,0.3-0.3H0.9z"/></svg>',
                shapeName: 'top-right',
                transform: {
                    x: 20,
                    y: 12,
                },
            });
        }
        if (allowRemoval) {
            res.push({
                name: 'delete',
                path: '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><title>box-configurator-delete</title><circle cx="8" cy="8" r="8" style="fill:#fff"/><path d="M10.24,1.08v.66a.39.39,0,0,1-.36.36H1.12a.39.39,0,0,1-.36-.36V1.08A.39.39,0,0,1,1.12.72H3.64L3.82.3A.52.52,0,0,1,4.24,0h2.4a.61.61,0,0,1,.48.3L7.3.72H9.82C10.06.78,10.24.9,10.24,1.08ZM1.42,2.82h8.1V9.91a1.05,1.05,0,0,1-1,1H2.44a1.05,1.05,0,0,1-1-1ZM3.1,9.19a.39.39,0,0,0,.36.36.39.39,0,0,0,.36-.36V4.44a.39.39,0,0,0-.36-.36.39.39,0,0,0-.36.36Zm2,0a.36.36,0,0,0,.72,0V4.44a.36.36,0,1,0-.72,0Zm2,0a.36.36,0,0,0,.72,0V4.44a.36.36,0,0,0-.72,0Z"/></svg>',
                shapeName: 'top-right',
                transform: {
                    x: 20,
                    y: allowRotation ? 42 : 12,
                },
            });
        }
        return res;
    }, [allowRemoval, allowRotation]);

    /**
     * Syncs the position of the icons when there is a change in the [rect]'s position or size.
     */
    const syncIconsPositions = useCallback(() => {
        if (shadowRectRef.current && transformer.current) {
            shadowRectRef.current.setAttrs({
                width: transformer.current.width(),
                height: transformer.current.height(),
                x: transformer.current.x(),
                y: transformer.current.y(),
            });
        }
        if (!transformer.current)
            return;
        transformer.current.update();
        for (const button of buttons) {
            const shape = transformer.current.findOne('.' + button.shapeName);
            const group = transformer.current.findOne('.' + button.name + '-icon');
            group.position({
                x: shape.position().x + button.transform.x,
                y: shape.position().y + button.transform.y,
            });
        }
    }, [buttons])

    /**
     * When the icons of the rect should be visible:
     * - creates the icons and attaches the event listeners that would sync buttons position with [rect]'s changes.
     */
    useLayoutEffect(() => {
        if (!(!dragging && (transformerProps?.visible ?? true)) || !rect || !transformer.current)
            return;
        const iconGroups: Array<Konva.Node> = [];
        const stage = rect.getStage();
        for (const button of buttons) {
            const shape = transformer.current.findOne('.' + button.shapeName);
            const group = new Konva.Group({
                name: button.name + '-icon',
            });
            const bgCircle = new Konva.Circle({
                width: 24,
                height: 24,
                fill: '#009FB8',
            });
            const icon = new Konva.Path({
                fill: "white",
                data: button.path,
                width: 20,
                height: 20,
                x: -5.25,
                y: -5.25,
            });
            group.add(bgCircle);
            group.add(icon);
            group.position({
                x: shape.position().x + button.transform.x,
                y: shape.position().y + button.transform.y,
            });
            iconGroups.push(group);
            transformer.current.add(group);
            group.moveToTop();
            group.on("mouseenter", () => {
                if (!stage) return;
                stage.container().style.cursor = 'pointer';
            })
            group.on('mouseleave', () => {
                if (!stage) return;
                stage.container().style.cursor = 'default';
            })
            switch (button.name) {
                case 'delete':
                    group.on('click', remove)
                    break;
                case 'rotate':
                    group.on('click', rotate)
                    break;
            }
        }
        const sync = () => syncIconsPositions();
        rect.on('transform', sync);
        rect.on('attrsChange', sync);
        rect.on('widthChange', sync);
        rect.on('heightChange', sync);
        stage?.on('scaleXChange', sync);
        return () => {
            rect.off('transform', sync);
            rect.off('attrsChange', sync);
            rect.off('widthChange', sync);
            rect.off('heightChange', sync);
            stage?.off('scaleXChange', sync);
            for (let icon of iconGroups) {
                icon.remove();
            }
        }
    }, [dragging, transformerProps?.visible, rect, remove, syncIconsPositions, buttons]);

    const propsWithoutShadow = useMemo<Konva.RectConfig>(() => ({
        ...(rectProps ?? {}),
        shadowColor: undefined,
        shadowOffsetX: undefined,
        shadowOffsetY: undefined,
        shadowBlur: undefined,
        shadowEnabled: undefined,
    }), [rectProps])

    return (
        <>
            {
                rect && interactive &&
                <BoundText
                    rect={rect}
                    defaultFontSize={12}
                    fill={'black'}
                    fontStyle={'700'}
                    text={rectProps?.title}
                />
            }
            <Rect
                {...(!dragging ? {...(rectProps ?? {})} : propsWithoutShadow)}
                perfectDrawEnabled={false}
                listening={interactive}
                ref={assignRectRef}
                onDragMove={onRectDragMove}
                onDragEnd={onRectDragEnd}
                onTransform={onRectTransformed}
                onMouseEnter={onRectMouseEnter}
                onMouseLeave={onRectMouseLeave}
            />
            {
                rect &&
                <>
                    <Transformer
                        rotationSnaps={rotationSnaps}
                        {...(transformerProps ?? {})}
                        visible={!dragging && (transformerProps?.visible ?? true)}
                        ref={ref => transformer.current = ref}
                        node={rect}
                        flipEnabled={false}
                        rotateEnabled={false}
                        rotateAnchorOffset={20}
                        onDragMove={onTransformerDragMove}
                        boundBoxFunc={transformerBoundBoxFunc}
                        {...((dragging || (!(transformerProps?.visible ?? true))) && {
                            enabledAnchors: [],
                            rotateEnabled: false,
                            resizeEnabled: false,
                            anchorSize: 0,
                            rotateAnchorOffset: 0,
                        })}
                    />
                </>
            }
        </>
    );
})

export default BoundRect;
