import React, {useEffect, useRef} from "react";
import useRouter from "../../../../hooks/use-router";
import Aos from "aos";
import {Col, Container, Row} from "react-bootstrap";
import {routeFunctions} from "../../../../routes";
import {useTranslation} from "react-i18next";
import VideoPlayer from "../../../../components/app-specific/video-player";
import classnames from "classnames";
import {landingViewId} from "../../../../../core/constants/ids";
import LandingViewHeroSection from "./sections/hero";
import LandingViewFeaturesSection from "./sections/features";
import LandingViewStepsSection from "./sections/steps";
import LandingViewPricePlansSection from "./sections/price-plans";
import LandingViewStatsSection from "./sections/stats";
import {TermsAndConditionsLocationHash} from "../../../../../core/constants/enums";

// the options used for the scrolling animation of AOS
export const landingViewScrollAnimationOptions = {
    duration: 500,
    easing: "ease-in-out",
    delay: 0,
    offset: 250,
    fadeUpAnimation: "fade-up",
    fadeRightAnimation: "fade-right",
    fadeLeftAnimation: "fade-left",
    zoomOutAnimation: "zoom-out",
    zoomInAnimation: "zoom-in",
    slideLeftAnimation: "slide-left",
    slideRightAnimation: "slide-right",
    featuresBaseDelay: 100,
};

const LandingView = ({sections}) => {
    const {history, params, location, stringifyUrl} = useRouter();
    const {t} = useTranslation();
    const scrolling = useRef(false);

    /**
     * As soon as the component mounts:
     * Attaches a scroll listener to the window
     * As soon as the component un-mounts:
     * Removes the attached scroll listener from the window.
     */
    useEffect(() => {
        window.addEventListener('scroll', onWindowScroll);
        return () => window.removeEventListener('scroll', onWindowScroll)
    }, []);

    /**
     * As soon as the component mounts:
     * initializes the _animations for the cards of this view.
     */
    useEffect(() => {
        Aos.init({
            duration: landingViewScrollAnimationOptions.duration,
            offset: landingViewScrollAnimationOptions.offset,
            easing: landingViewScrollAnimationOptions.easing,
            delay: landingViewScrollAnimationOptions.delay,
        });
        return () => Aos.refreshHard();
    }, []);

    /**
     * Listen to changes in the fragmentIdentifier of the url and with each change:
     *
     * * if the hash is for privacy-policy, does not scroll the page.
     * * scrolls to the section related to the fragmentIdentifier.
     */
    useEffect(() => {
        if (location?.hash?.substring(1) === TermsAndConditionsLocationHash) {
            return;
        }
        scrollToSection(location?.hash?.substring(1));
    }, [location?.hash])

    /**
     * Adds or removes the fragment identifier of the url based on the scroll position of the window.
     * @param {UIEvent<Document>} e
     */
    const onWindowScroll = (e) => {
        if (scrolling.current) return;
        const bottomScrollPosition = document.getElementById(landingViewId).getBoundingClientRect().height;
        const scrollPosition = e.currentTarget.scrollY;
        if (scrollPosition < 100 && location.hash !== null) {
            history.replace(stringifyUrl({
                url: routeFunctions.public.landing(params.language),
                fragmentIdentifier: null,
            }));
            return;
        }
        if (scrollPosition > bottomScrollPosition - 500 && location.hash !== 'end') {
            history.replace(stringifyUrl({
                url: routeFunctions.public.landing(params.language),
                fragmentIdentifier: 'end',
            }));
        }
    }


    /**
     * Scrolls the window to the specified section if it exists. If not, then scrolls to the top of the page
     *
     * Sets a global scrolling for
     * @param {string | null} sectionHash
     */
    const scrollToSection = (sectionHash) => {
        scrolling.current = true;
        if (sectionHash) {
            const elm = document.getElementById(sectionHash);
            elm?.scrollIntoView({behavior: 'smooth'});
        } else {
            // Scroll to top
            window.scrollTo({top: 0, left: 0, behavior: "smooth"});
        }
        setTimeout(() => {
            scrolling.current = false;
        }, 500);
    }

    return (
        <>
            <div className={'landing-view'} id={landingViewId}>
                <div className='first-floating-background-container'/>
                {/*Hero section */}
                <LandingViewHeroSection sections={sections} scrollToSection={scrollToSection}/>
                {/*Features Section*/}
                <LandingViewFeaturesSection sections={sections}/>
                {/*Steps Section*/}
                <LandingViewStepsSection sections={sections}/>
                {/*Video Section*/}
                <div className={'third-floating-background-container'}/>
                <Container data-aos={landingViewScrollAnimationOptions.zoomInAnimation}
                           className={'video-player-container'}>
                    <Row>
                        <Col xs={12}>
                            <VideoPlayer
                                id={'landing-page-video-player'}
                                videoId={'LedHhaS6WmA'}
                            />
                        </Col>
                    </Row>
                </Container>
                {/*Packages Section*/}
                <LandingViewPricePlansSection sections={sections}/>
                {/*Slogan */}
                <Container className={'slogan'}>
                    <Row data-aos={landingViewScrollAnimationOptions.fadeUpAnimation}>
                        <Col xs={12}>
                            <h4 className={'title'}>
                                {t('views.public.landing.slogan.text', {returnObjects: true})?.map((e, index) => (
                                    index === 1
                                        ? <span key={index} className={classnames('mx-1 primary-color')}>
                                        {e}
                                    </span>
                                        : e
                                ))}
                            </h4>
                        </Col>
                    </Row>
                </Container>
                {/*Stats */}
                <LandingViewStatsSection/>
            </div>
        </>
    )
}


export default LandingView;
