import React from "react";
import TabsLayout from "../../app-specific/tabs-layout";
import useRouter from "../../../hooks/use-router";
import {useTranslation} from "react-i18next";
import {InvoiceSearchQueryNames} from "../../../../core/constants/query-names";
import moment from "moment";
import {generatePaginationAndOrderByQueryResetter} from "../../../../core/services/searching-utils";
import {InvoicesTabValues} from "../../../../core/constants/tab-values";
import InvoicesFormSection from "./form";

const InvoicesSearchSection = ({filters, children}) => {
    const {query, history, location, stringifyUrl} = useRouter();
    const {t} = useTranslation()
    const translations = t("views.panel.invoices", {returnObjects: true});
    const tabs = [
        {
            label: translations?.tabs?.month,
            value: InvoicesTabValues.thisMonth
        },
        {
            label: translations?.tabs?.year,
            value: InvoicesTabValues.thisYear
        },
        {
            label: translations?.tabs?.all,
            value: InvoicesTabValues.all
        },
        {
            label: translations?.tabs?.custom,
            value: InvoicesTabValues.custom
        },
    ]

    /**
     * Determines the selected dateFilter and the start and end date of the search section based on the selected tab.
     * updates the url of the view as well.
     * @param tabValue
     */
    const onTabChanged = (tabValue) => {
        const dateFilter = tabValue
        let startDate, endDate;
        switch (tabValue) {
            case InvoicesTabValues.thisMonth:
                startDate = moment().subtract(1, 'month').toDate().toISOString();
                endDate = moment().toDate().toISOString();
                break;
            case InvoicesTabValues.thisYear:
                startDate = moment().subtract(1, 'year').toDate().toISOString();
                endDate = moment().toDate().toISOString();
                break;
            case InvoicesTabValues.all:
            case InvoicesTabValues.custom:
            default:
                startDate = undefined;
                endDate = undefined;
                break;
        }
        const result = {
            [InvoiceSearchQueryNames.dateFilter]: dateFilter,
            [InvoiceSearchQueryNames.fromDate]: startDate,
            [InvoiceSearchQueryNames.toDate]: endDate,
        }
        mergeQueriesAndApply(result)
    }

    /**
     * Merges the newQuery with the existing query of the view.
     * used to apply the changes in the form section while preserving the existing queries
     * @param {any} newQuery the query object to merge with the previous one
     */
    const mergeQueriesAndApply = (newQuery) => {
        history.push(stringifyUrl({
            url: location.pathname,
            query: {
                ...query,
                ...newQuery,
                // since we are changing the search form, the pagination and order by must be removed to produce the
                // results from the beginning.
                ...generatePaginationAndOrderByQueryResetter(),
            }
        }))
    }

    return (
        <>
            <TabsLayout
                tabs={tabs}
                onChange={onTabChanged}
                initialValue={filters
                    ? filters[InvoiceSearchQueryNames.dateFilter]
                    : undefined}>
                {(selectedValue) => (
                    <>
                        <InvoicesFormSection
                            filters={filters}
                            selectedTab={selectedValue}
                            submit={mergeQueriesAndApply}
                        />
                        {children}
                    </>
                )}
            </TabsLayout>
        </>
    )
}

export default InvoicesSearchSection;
