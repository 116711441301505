import React, {useEffect, useLayoutEffect, useState} from "react";
import PublicViewLayoutHeader from "./header";
import {useTranslation} from "react-i18next";
import {routeFunctions} from "../../routes";
import useRouter from "../../hooks/use-router";
import PublicViewLayoutFooter from "./footer";
import TermsAndConditionsDialog from "../../components/dialogs/terms-and-conditions";
import {TermsAndConditionsLocationHash} from "../../../core/constants/enums";

const PublicViewLayout = ({children}) => {
    const {history, stringifyUrl, params, location} = useRouter();
    const [termsAndConditions, setTermsAndConditions] = useState({open: false, callback: undefined, preview: false});
    const {t} = useTranslation();
    // the application sections
    const sections = {
        features: {
            hash: 'features',
            text: t('containers.publicViewLayout.sections.features')
        },
        steps: {
            hash: 'steps',
            text: t('containers.publicViewLayout.sections.steps')
        },
        pricing: {
            hash: 'pricing',
            text: t('containers.publicViewLayout.sections.pricing')
        },
    };

    /**
     * As soon as the component mounts:
     * Adds the terms and conditions dialog toggle functionality for the usage in public views.
     */
    useLayoutEffect(() => {
        window.bizpire = {
            ...window.bizpire,
            termsAndConditionsDialog: termsAndConditionsDialog
        };
        return () => {
            delete window.bizpire?.termsAndConditionsDialog;
        }
    }, [])

    /**
     * Listens to the changes in the fragmentIdentifier of the url and with each change:
     * if the identifier is for termsAndConditions page, then opens its dialog in preview mode.
     */
    useEffect(() => {
        if (location?.hash?.substring(1) !== TermsAndConditionsLocationHash) return;
        termsAndConditionsDialog(true, true);
    }, [location?.hash])


    /**
     * Toggles the privacy policy dialog of the application.
     * @param {boolean}             open        whether to open or close the modal
     * @param {boolean}             preview     whether to preview the dialog or to accept it
     * @param {function(): boolean} callback    a function that determines whether the user has accepted the modal or not.
     */
    const termsAndConditionsDialog = (open, preview, callback = undefined) => {
        if (open) {
            setTermsAndConditions({open, callback, preview})
        } else {
            setTermsAndConditions({open, callback: undefined, preview: false})
        }
    }


    /**
     * Redirects the user to the target section and updates the fragmentIdentifier of the url to the given sectionHash
     *
     * @param {string | null} sectionHash the section to navigate to
     */
    const onSectionClick = (sectionHash = null) => {
        if (sectionHash === null) {
            history.replace(stringifyUrl({
                url: routeFunctions.public.landing(params.language),
                fragmentIdentifier: null,
            }));
            return;
        }
        history.replace(stringifyUrl({
            url: routeFunctions.public.landing(params.language),
            fragmentIdentifier: sectionHash,
        }));
    };

    return (
        <>
            <div className={'public-layout'}>
                <PublicViewLayoutHeader
                    sections={sections}
                    onSectionClick={onSectionClick}
                />
                <div className={'min-vh-100'}>
                    {children(sections)}
                </div>
                <PublicViewLayoutFooter
                    sections={sections}
                    onSectionClick={onSectionClick}
                />
            </div>
            <TermsAndConditionsDialog
                open={termsAndConditions.open}
                callback={termsAndConditions.callback}
                preview={termsAndConditions.preview}
                setOpen={termsAndConditionsDialog}
            />
        </>
    )
}

export default PublicViewLayout;
