import {makeStyles} from "@material-ui/core/styles";
import {disabledTextColor} from "../../../../assets/mui/colors";

export const useSelectStyles = makeStyles(() => ({
    root: {
        padding: props => props.projectsSelect ? '15px 16px 16px 16px !important' : '6px 25px 6px 0px !important',
    },
    icon: {
        color: props => !props.data?.length ? disabledTextColor : props.iconColor,
        cursor: props => (!props.data || !props.data.length) ? 'not-allowed' : 'pointer',
        transition: 'all 0.2s ease-in-out',
    },
    innerInput: {
        fontSize: props => props.fontSize,
        fontWeight: '500',
    },
    menuRoot: {},
}));
