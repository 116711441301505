import React from 'react';
import DashboardViewCompanyStatsSection from "./sections/company-stats";
import DashboardViewUserLogsSection from "./sections/user-logs";
import DashboardViewWarehousesInfo from "./sections/warehouses-info";
import DashboardViewWarehouseSyncSection from "./sections/warehouse-sync";


const DashboardView = () => {

    return (
        <div className={'dashboard'}>
            <div className='panel-card'>
                <section>
                    <DashboardViewWarehousesInfo/>
                </section>
                <section>
                    <DashboardViewCompanyStatsSection/>
                </section>
            </div>
            <div className='mt-3'>
                <section className='panel-card'>
                    <DashboardViewWarehouseSyncSection/>
                </section>
            </div>
            <div className='mt-3'>
                <section className='panel-card'>
                    <DashboardViewUserLogsSection/>
                </section>
            </div>

        </div>

    )
}

export default DashboardView;
