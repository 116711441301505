import React, {useEffect, useState} from "react";
import {TableCellAlignments, TableCellTypes} from "../../../../../../../../core/constants/enums";
import {useTranslation} from "react-i18next";
import Table from "../../../../../../../containers/table";
import moment from "moment";
import NoEntries from "../../../../../../../components/app-specific/no-entries";

//Keys for the upper table
const upperTableCellKeys = {
    title: 'title',
    submittedBy: 'submittedBy',
    submitDate: 'submitDate',
    expiryDate: 'expiryDate',
    totalCount: 'totalCount',
    scannedCount: 'scannedCount'
}

//Keys for the participants table
const participantTableKeys = {
    name: 'name',
    joinDate: 'joinDate',
    lastSave: 'lastSave',
}

const SpireCountingParticipants = ({data, loading}) => {
    const [participants, setParticipants] = useState([]);
    const {t} = useTranslation();
    const translation = t('views.panel.company.counting.spire.single', {returnObjects: true});
    const errorMessage = t('views.panel.errorMessages', {returnObjects: true})


    // list of information table cell structures
    const upperTableCells = [
        {
            title: translation?.upperTableTitles?.title ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: upperTableCellKeys.title,
        },
        {
            title: translation?.upperTableTitles?.submittedBy ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: upperTableCellKeys.submittedBy,
        },
        {
            title: translation?.upperTableTitles?.submitDate ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.date,
            name: upperTableCellKeys.submitDate,
        },
        {
            title: translation?.upperTableTitles?.expiryDate ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.date,
            name: upperTableCellKeys.expiryDate,
        },
        {
            title: translation?.upperTableTitles?.totalCount ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.string,
            name: upperTableCellKeys.totalCount,
        },
        {
            title: translation?.upperTableTitles?.scannedCount ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.string,
            name: upperTableCellKeys.scannedCount,
        },
    ];

    // list of recent log's table cell structures
    const participantsTableCells = [
        {
            title: translation?.participantTable?.name ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: participantTableKeys.name,
        },
        {
            title: translation?.participantTable?.joinDate ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.date,
            name: participantTableKeys.joinDate,
            size: 2
        },
        {
            title: translation?.participantTable?.lastSave ?? '',
            alignment: TableCellAlignments.center,
            type: TableCellTypes.string,
            name: participantTableKeys.lastSave,
            size: 4
        }
    ]

    /**
     * With each change in the date in the props:
     * - sets the participants in the state
     */
    useEffect(() => {
        setParticipants(data?.participants)
    }, [data]);

    /**
     * Creates the data entries for upper information table
     * @return {TableCellData[]}
     */
    const createUpperInformativeTableDate = () => {
        return [
            {
                key: data?.id,
                [upperTableCellKeys.title]: data?.title,
                [upperTableCellKeys.submittedBy]: data?.submittedBy,
                [upperTableCellKeys.submitDate]: data?.submitDate,
                [upperTableCellKeys.expiryDate]: data?.expiryDate,
                [upperTableCellKeys.totalCount]: data?.totalCount,
                [upperTableCellKeys.scannedCount]: data?.scannedCount,
            }
        ];
    }

    /**
     * Creates the data entries for the recent logs table
     * @return {TableCellData[]}
     */
    const createParticipantsTableData = () => {
        return participants?.map((participant) => (
            {
                key: participant?.id,
                [participantTableKeys.name]: participant?.name,
                [participantTableKeys.joinDate]: participant?.joinDate,
                [participantTableKeys.lastSave]: moment(participant?.lastSave).fromNow(),
            }));
    }


    return (
        <>
            <div className={'spire-counting-single-information'}>
                <Table
                    loading={{state: loading, count: 1}}
                    className={'my-3'}
                    color={'primary'}
                    cells={upperTableCells}
                    data={createUpperInformativeTableDate()}
                />
                <p className={'title mt-5'}>
                    {translation?.participants ?? ''}
                </p>
                {
                    participants?.length < 1 && !loading
                        ? <NoEntries
                            text={errorMessage}
                            includeButton={false}
                        />
                        : <Table
                            loading={{state: loading, count: 3}}
                            className={'my-3'}
                            color={'primary'}
                            cells={participantsTableCells}
                            data={createParticipantsTableData()}
                        />

                }
            </div>
        </>
    );
}

export default SpireCountingParticipants;
