import React, {useMemo} from "react";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import useRouter from "../../../../../hooks/use-router";
import {makeRequired, makeValidate} from "mui-rff";
import {SearchFormInput} from "../../../../base/input/search";
import classnames from "classnames";
import Form from "../../../../base/form";
import {SpireItemSearchOptions} from "../../../../../../core/constants/enums";
import {Row} from "react-bootstrap";
import {PositionCountingItemsQueryParams} from "../../../../../../core/constants/query-params";


// Form keys for search field
const formKeys = {
    keyword: 'keyword',
}

/**
 * Creates the schema of the spire counting single view products form section
 * @param validationTranslation
 * @return {*}
 */
const makeSchema = (validationTranslation) => {
    return Yup.object().shape({
        [formKeys.keyword]: Yup.string().when(formKeys.keyword, {
            is: val => !val?.length,
            then: Yup.string().nullable(),
            otherwise: Yup.string().nullable().required(validationTranslation?.required ?? '')
        }),
    }, [[formKeys.keyword, formKeys.keyword]]);
}

const SingleSpireCountingSearchSection = ({filters}) => {
    const {t} = useTranslation();
    const {query, location, history, stringifyUrl} = useRouter();

    //TODO: change translation
    const translation = t("views.panel.company.usersManagement", {returnObjects: true});
    const validationTranslation = t('utils.formValidation', {returnObjects: true});
    const schema = makeSchema(validationTranslation)
    const validate = makeValidate(schema);
    const required = makeRequired(schema);

    /**
     * Memo version of selected search options
     * @type {string}
     */
    const selectedSearchOption = useMemo(() => {
        if (!!filters && !!filters[PositionCountingItemsQueryParams.filter])
            return filters[PositionCountingItemsQueryParams.filter];
        return SpireItemSearchOptions.all;
    }, [filters])

    /**
     * Handles click on search button and modifies the query with the changes in the form values
     * @param values
     */
    const onFormSearched = (values) => {
        // if user searches with empty keyword, removes keyword from query params
        if (!Object.keys(values).length) {
            history.push(stringifyUrl({
                url: location.pathname,
                query: {
                    ...query,
                    [formKeys.keyword]: undefined,
                }
            }))
            return;
        }
        history.push(stringifyUrl({
            url: location.pathname,
            query: {
                ...query,
                ...values,
            }
        }))
    }

    /**
     * Handles selection of option for loading spire counting products:
     * - Sets the [selectedSearchOption] in the state
     * - updates the query param for this action
     *
     * @param {string} spireItemSearchOption the selected option for
     */
    const onOptionClicked = (spireItemSearchOption) => {
        history.replace(stringifyUrl({
            url: location.pathname,
            query: {
                ...query,
                [PositionCountingItemsQueryParams.filter]: spireItemSearchOption,
            }
        }))
    }


    return (
        <>
            <Form
                onSubmit={onFormSearched}
                validate={validate}
                className={'d-flex flex-column spire-counting-search-section'}
                initialValues={filters}
                render={({submitting}) => {
                    return (
                        <>
                            <SearchFormInput
                                placeholder={translation?.form?.keywords?.placeHolder ?? ''}
                                fullWidth
                                required={required[formKeys.keyword]}
                                name={formKeys.keyword}
                                buttonProps={{
                                    className: classnames('button primary'),
                                    children: translation?.actions?.submit ?? '',
                                    type: "submit",
                                    disabled: submitting
                                }}
                            />
                        </>
                    );
                }}
            />
            <Row className={'my-3'}>
                <div
                    className={classnames('search-option-selection', {'selected': selectedSearchOption === SpireItemSearchOptions.all})}
                    onClick={(e) => onOptionClicked(SpireItemSearchOptions.all)}
                >
                    {'All'}
                </div>
                <div
                    className={classnames('search-option-selection', {'selected': selectedSearchOption === SpireItemSearchOptions.correctParts})}
                    onClick={(e) => onOptionClicked(SpireItemSearchOptions.correctParts)}
                >
                    {'Correct Parts'}
                </div>
                <div
                    className={classnames('search-option-selection', {'selected': selectedSearchOption === SpireItemSearchOptions.incorrectParts})}
                    onClick={(e) => onOptionClicked(SpireItemSearchOptions.incorrectParts)}
                >
                    {'Incorrect Parts'}
                </div>
            </Row>
        </>
    );
}

export default SingleSpireCountingSearchSection;
