import React, {useEffect, useState} from "react";
import {TableCellAlignments, TableCellTypes} from "../../../../../../../../core/constants/enums";
import {useTranslation} from "react-i18next";
import Table from "../../../../../../../containers/table";
import {dialogActions} from "../../../../../../../../redux/entities/dialog-slice";
import {useDispatch} from "react-redux";
import {ReactComponent as BarcodeIcon} from "../../../../../../../../assets/images/barcode-icon.svg";
import {routeFunctions} from "../../../../../../../routes";
import useRouter from "../../../../../../../hooks/use-router";
import NoEntries from "../../../../../../../components/app-specific/no-entries";

//Keys for the upper table
const upperTableCellKeys = {
    title: 'title',
    submittedBy: 'submittedBy',
    submitDate: 'submitDate',
    expiryDate: 'expiryDate',
    totalCount: 'totalCount',
    scannedCount: 'scannedCount'
}

//Keys for the recent logs table
const recentLogsTableKeys = {
    partNumber: 'partNumber',
    serialNumber: 'serialNumber',
    count: 'count',
    dateTime: 'dateTime',
    operator: 'operator'
}

const SpireCountingInformation = ({data, loading}) => {
    const {params, history} = useRouter()
    const {t} = useTranslation();
    const [recentLogs, setRecentLogs] = useState([]);
    const translation = t('views.panel.company.counting.spire.single', {returnObjects: true});
    const errorMessage = t('views.panel.errorMessages', {returnObjects: true})

    const dispatch = useDispatch();

    // list of information table cell structures
    const upperTableCells = [
        {
            title: translation?.upperTableTitles?.title ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: upperTableCellKeys.title,
        },
        {
            title: translation?.upperTableTitles?.submittedBy ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: upperTableCellKeys.submittedBy,
        },
        {
            title: translation?.upperTableTitles?.submitDate ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.date,
            name: upperTableCellKeys.submitDate,
        },
        {
            title: translation?.upperTableTitles?.expiryDate ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.date,
            name: upperTableCellKeys.expiryDate,
        },
        {
            title: translation?.upperTableTitles?.totalCount ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.string,
            name: upperTableCellKeys.totalCount,
        },
        {
            title: translation?.upperTableTitles?.scannedCount ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.string,
            name: upperTableCellKeys.scannedCount,
        },
    ];

    // list of recent log's table cell structures
    const recentLogsTableCells = [
        {
            title: translation?.recentLogsTable?.partNumber ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: recentLogsTableKeys.partNumber,
        },
        {
            title: translation?.recentLogsTable?.serialNumber ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: recentLogsTableKeys.serialNumber,
            size: 1.5,
        },
        {
            title: translation?.recentLogsTable?.count ?? '',
            alignment: TableCellAlignments.center,
            type: TableCellTypes.string,
            name: recentLogsTableKeys.count,
        },
        {
            title: translation?.recentLogsTable?.dateTime ?? '',
            alignment: TableCellAlignments.center,
            type: TableCellTypes.dateTime,
            name: recentLogsTableKeys.dateTime,
            size: 2.5
        },
        {
            title: translation?.recentLogsTable?.operator ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: recentLogsTableKeys.operator,

        },
    ]

    /**
     * With each change in the date in the props:
     * - sets the recent logs in the state
     */
    useEffect(() => {
        setRecentLogs(data?.recentLogs)
    }, [data]);

    /**
     * Handles click on the barcode icon button:
     * - Opens dialog for showing the barcode
     * @param {string} partNumber for generating barcode from
     */
    const onBarcodeClicked = (partNumber) => {
        dispatch(dialogActions.configBarcodeDialog({
            open: true,
            onClose: () => dispatch(dialogActions.barcodeDialogToggle(false)),
            barcodeCode: partNumber,
        }));
    }

    /**
     * Handles click on the part number text button:
     * - Navigates user to the part single view
     * @param {number} partId
     */
    const onPartNumberClicked = (partId) => {
        history.push(routeFunctions.panel.company.items.single.product(params.company, partId))
    }

    /**
     * Handles click on the serial number text button:
     * - Navigates user to the part single view
     * @param {number} serialId
     */
    const onSerialNumberClicked = (serialId) => {
        history.push(routeFunctions.panel.company.items.single.instance(params.company, serialId))
    }

    /**
     * Creates the data entries for upper information table
     * @return {TableData[]}
     */
    const createUpperInformativeTableDate = () => {
        return [
            {
                key: data?.id,
                [upperTableCellKeys.title]: data?.title,
                [upperTableCellKeys.submittedBy]: data?.submittedBy,
                [upperTableCellKeys.submitDate]: data?.submitDate,
                [upperTableCellKeys.expiryDate]: data?.expiryDate,
                [upperTableCellKeys.totalCount]: data?.totalCount,
                [upperTableCellKeys.scannedCount]: data?.scannedCount,
            }
        ];
    }

    /**
     * Creates the data entries for the recent logs table
     * @return {TableData[]}
     */
    const createRecentLogsTableData = () => {
        return recentLogs?.map((log) => ({
            key: log?.id,
            [recentLogsTableKeys.partNumber]: () => (
                <div className={'d-flex flex-row align-items-center'}>
                    {
                        log?.partNo?.length > 0 &&
                        <div
                            onClick={() => onBarcodeClicked(log?.partNo)}
                            className={'barcode-icon-button'}
                        >
                            <BarcodeIcon/>
                        </div>
                    }
                    <p className={'text-button'} onClick={() => onPartNumberClicked(log?.id)}>
                        {log?.partNo}
                    </p>
                </div>
            ),
            [recentLogsTableKeys.serialNumber]: () => (
                <div className={'d-flex flex-row align-items-center'}>
                    {
                        log?.serialNo?.length > 0 &&
                        <div
                            onClick={() => onBarcodeClicked(log?.serialNo)}
                            className={'barcode-icon-button'}
                        >
                            <BarcodeIcon/>
                        </div>
                    }
                    <p className={'text-button'} onClick={() => onSerialNumberClicked(log?.id)}>
                        {log?.serialNo}
                    </p>
                </div>
            ),
            [recentLogsTableKeys.count]: log?.count,
            [recentLogsTableKeys.dateTime]: log?.dateTime,
            [recentLogsTableKeys.operator]: log?.operator,
        }));
    }


    return (
        <>
            <div className={'spire-counting-single-information'}>
                <Table
                    loading={{state: loading, count: 1}}
                    className={'my-3'}
                    color={'primary'}
                    cells={upperTableCells}
                    data={createUpperInformativeTableDate()}
                />
                <p className={'title mt-5'}>
                    {translation?.recentLogs ?? ''}
                </p>
                {
                    recentLogs?.length >= 1 && !loading
                        ? <Table
                            loading={{state: loading, count: 3}}
                            className={'my-3'}
                            color={'primary'}
                            cells={recentLogsTableCells}
                            data={createRecentLogsTableData()}
                        />
                        : <NoEntries
                            text={errorMessage}
                            includeButton={false}
                        />
                }
            </div>
        </>
    );
}

export default SpireCountingInformation;
