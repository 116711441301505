import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import useRouter from "../../../../../../hooks/use-router";
import {DefaultInitialPaginationInfo, TableCellAlignments, TableCellTypes} from "../../../../../../../core/constants/enums";
import {PositionCountingListQueryNames} from "../../../../../../../core/constants/query-names";
import button from "bootstrap/js/src/button";
import NoEntries from "../../../../../../components/app-specific/no-entries";
import Table from "../../../../../../containers/table";
import PositionCountingSearchSection from "../../../../../../components/search-sections/countings/position-counting";
import {routeFunctions} from "../../../../../../routes";
import useIsMounted from "../../../../../../hooks/use-is-mounted";
import {CompanyContext} from "../../../../../../contexts/company";
import QueryManagementUtils from "../../../../../../../core/services/query-management-utils";
import useSearchData from "../../../../../../hooks/use-search-data";

const initialPaginationInfo = {
    ...DefaultInitialPaginationInfo,
    pageSize: 10,
}

const tableCellKeys = {
    title: 'title',
    submitDateTime: 'submitDateTime',
    lastModifiedDateTime: 'lastModifiedDateTime',
    warehouse: 'warehouse',
    selectedLocation: 'selectedLocation',
    submittedBy: 'submittedBy',
    view: 'view'
}

const warehouses = [
    {
        id: 1,
        title: "warehouse1"
    },
    {
        id: 2,
        title: "warehouse2"
    },
    {
        id: 3,
        title: "warehouse3"
    },
]

const PositionCountingListView = () => {
    const {query, location, history, stringifyUrl, params} = useRouter();
    const [positionCountings, setPositionCountings] = useState([]);
    const [loading, setLoading] = useState(true)
    const {t} = useTranslation();
    const isMounted = useIsMounted();

    const translation = t("views.panel.company.counting.position.list", {returnObjects: true});

    const [company] = useContext(CompanyContext);
    const selectedCompany = useRef(company);

    /**
     * With each change in the selected company:
     */
    useEffect(() => {
        if (selectedCompany.current?.id === company?.id)
            return;
        selectedCompany.current = company;
        //   TODO: implement the action related to this
    }, [company?.id])

    /**
     * Searches for position countings:
     * - Calls the api regarding this action
     */
    const search = async () => {
        const forApi = prepareRequestDS();
        const response = {
            resultFlag: true,
            data: {
                items: [
                    {
                        id: 1,
                        title: 'Testing Counting',
                        submitDateTime: new Date(),
                        lastModifiedDate: new Date(),
                        warehouse: {
                            id: 1,
                            title: "Warehouse Test"
                        },
                        selectedLocation: {
                            id: 1,
                            code: 'A01111',
                        },
                        submittedBy: 'Tester',
                    },
                    {
                        id: 2,
                        title: 'Testing Counting',
                        submitDateTime: new Date(),
                        lastModifiedDate: new Date(),
                        warehouse: {
                            id: 1,
                            title: "Warehouse Test"
                        },
                        selectedLocation: {
                            id: 2,
                            code: 'A01112',
                        },
                        submittedBy: 'Tester',
                    },
                    {
                        id: 3,
                        title: 'Testing Counting',
                        submitDateTime: new Date(),
                        lastModifiedDate: new Date(),
                        warehouse: {
                            id: 1,
                            title: "Warehouse Test"
                        },
                        selectedLocation: {
                            id: 3,
                            code: 'A01113',
                        },
                        submittedBy: 'Tester',
                    },
                ],
                paginationInfo: {
                    length: 3,
                }
            }
        }
        if (!isMounted())
            return;
        if (response?.resultFlag) {
            setPositionCountings(response?.data?.items);
            const newPaginationInfo = {
                ...paginationInfo,
                length: response?.data.paginationInfo.length ?? 0
            }
            QueryManagementUtils.setPaginationInfo(newPaginationInfo, query, history, location);
        }
        setLoading(false);
    }

    const {filters, paginationInfo, orderBy} = useSearchData(
        PositionCountingListQueryNames,
        search,
        {
            useFilters: true,
            initialFilters: {},
            filtersQueryParamMap: {
                keyword: PositionCountingListQueryNames.keyword,
                warehouse: PositionCountingListQueryNames.warehouse
            },

            usePaginationInfo: true,
            initialPaginationInfo: initialPaginationInfo,

            useOrderBy: true,
            initialOrderBy: undefined,
        }
    )

    /**
     * Prepares the api call request body
     */
    const prepareRequestDS = () => {
        return {
            paginationInfo: paginationInfo,
            filters: filters,
            orderBy: orderBy
        }
    }

    /**
     * Clears the filters of this view to invoke a search without any filers.
     */
    const clearFilters = () => {
        history.push(stringifyUrl({
            url: location.pathname,
            query: {}
        }));
    }

    /**
     * Handles clicking on the warehouse name in the table of data
     * @param item
     */
    const onWarehouseClicked = (item) => {

    }

    /**
     * Handles clicking on the selected location code
     * @param item
     */
    const onSelectedLocationClicked = (item) => {

    }

    /**
     * Handles clicking on the view button of the table row item
     * @param item
     */
    const onPositionCountingViewClicked = (item) => {
        history.push(routeFunctions.panel.company.counting.singlePosition(params.company, item.id));
    }

    /**
     * Memo version of table columns
     * @type {object[]}
     */
    const tableColumns = useMemo(() => [
        {
            title: translation?.tableHeaders?.title ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: tableCellKeys.title,
            sortable: true,
        },
        {
            title: translation?.tableHeaders?.submitDateTime ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.dateTime,
            name: tableCellKeys.submitDateTime,
            sortable: true,
            size: 1.5,
        },
        {
            title: translation?.tableHeaders?.lastModifiedDateTime ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.dateTime,
            name: tableCellKeys.lastModifiedDateTime,
            sortable: true,
            size: 2,
        },
        {
            title: translation?.tableHeaders?.warehouse ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: tableCellKeys.warehouse,
            sortable: true,
        },
        {
            title: translation?.tableHeaders?.selectedLocation ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: tableCellKeys.selectedLocation,
            sortable: true,
            size: 1.5
        },
        {
            title: translation?.tableHeaders?.submittedBy ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: tableCellKeys.submittedBy,
            sortable: true,
            size: 1.1
        },
        {
            alignment: TableCellAlignments.right,
            type: TableCellTypes.element,
            name: tableCellKeys.view,
            last: true
        },
    ], []);

    /**
     * Memo version of table rows
     * @type {object[]}
     */
    const tableRows = useMemo(() => {
        return positionCountings?.map(positionCounting => (
            {
                key: positionCounting.id,
                [tableCellKeys.title]: positionCounting?.title,
                [tableCellKeys.submitDateTime]: positionCounting?.submitDateTime,
                [tableCellKeys.lastModifiedDateTime]: positionCounting?.lastModifiedDate,
                [tableCellKeys.warehouse]: () => (
                    <p
                        onClick={() => onWarehouseClicked(positionCounting)}
                        className={'text-button'}
                    >
                        {positionCounting?.warehouse?.title ?? ''}
                    </p>
                ),
                [tableCellKeys.selectedLocation]: () => (
                    <p
                        onClick={() => onSelectedLocationClicked(positionCounting)}
                        className={'text-button'}
                    >
                        {positionCounting?.selectedLocation?.code ?? ''}
                    </p>
                ),
                [tableCellKeys.submittedBy]: positionCounting?.submittedBy,
                [tableCellKeys.view]: () => (
                    <button
                        className={'button primary outlined px-4'}
                        onClick={() => onPositionCountingViewClicked(positionCounting)}
                    >
                        {translation?.tableAction?.view}
                    </button>
                ),
            }
        ));
    }, [positionCountings])

    return (
        <>
            <div className={'position-countings'}>
                <div className={'panel-card p-3 pt-5'}>
                    <PositionCountingSearchSection
                        filters={filters}
                        warehouses={warehouses}
                    />
                    <div>
                        {
                            !!positionCountings?.length < 1 && !loading
                                ? <NoEntries
                                    text={
                                        Object.entries(filters ?? {})?.length > 0
                                            ? translation?.empty?.withFilters
                                            : translation?.empty?.text
                                    }
                                    includeButton={Object.entries(filters ?? {})?.length > 0}
                                    buttonProps={{
                                        onClick: clearFilters,
                                        className: 'button primary px-3'
                                    }}/>
                                : <Table
                                    loading={{state: loading, count: 8}}
                                    className={'mt-3 mb-5'}
                                    color={'primary'}
                                    cells={tableColumns}
                                    data={tableRows}
                                    paginationInfo={paginationInfo}
                                    orderBy={orderBy}
                                    onSort={(orderBy) => QueryManagementUtils.setOrderBy(
                                        orderBy,
                                        query,
                                        history,
                                        location,
                                    )}
                                    onPageSizeChange={(p, i) => QueryManagementUtils.setPaginationInfo(
                                        {...p, pageSize: i},
                                        query,
                                        history,
                                        location
                                    )}
                                    onCurrentPageChange={(p, newP) => QueryManagementUtils.setPaginationInfo(
                                        {...p, currentPage: newP},
                                        query,
                                        history,
                                        location,
                                    )}
                                />
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default PositionCountingListView;
