import React, {useEffect} from "react";
import useRouter from "../../../../hooks/use-router";
import {matchPath, Route, Switch} from "react-router";
import {routeFunctions, routes} from "../../../../routes";
import UsersListView from "./list";

const UsersView = () => {
    const {location, history, params} = useRouter();

    /**
     * With each change in the path name of the url:
     * - Checks if the route is the same as panel.company.users.base, then changes it to the users list view
     */
    useEffect(()=>{
        if(!!matchPath(location.pathname,{path:routes.panel.company.users.base,exact:true})){
            history.replace(routeFunctions.panel.company.users.list(params.company));
        }
    },[location.pathname])

    return (
        <>
            <Switch>
                <Route path={routes.panel.company.users.list} exact>
                    <UsersListView/>
                </Route>
            </Switch>
        </>
    );
}

export default UsersView;
