import React, {useContext, useEffect, useState} from "react";
import {CompanyContext} from "../../../../../../contexts/company";
import {useTranslation} from "react-i18next";
import Api from "../../../../../../../core/services/api_service";
import useIsMounted from "../../../../../../hooks/use-is-mounted";
import {toast} from "react-toastify";
import moment from "moment";
import {exportCSVFile} from "../../../../../../../core/services/utils";
import {TableCellAlignments, TableCellTypes} from "../../../../../../../core/constants/enums";
import Table from "../../../../../../containers/table";
import ButtonDropdown from "../../../../../../components/app-specific/button-dropdown";
import NoEntries from "../../../../../../components/app-specific/no-entries";

// the loading names used in each of the warehouse syncs
const injectedPropertyNames = {
    partsReportLoading: "partsReportLoading",
    serialsReportLoading: "serialsReportLoading",
    recreating: "recreating",
    applying: "applying",
}

// the keys used for the table
const tableCellKeys = {
    title: 'title',
    submitDate: 'submitDate',
    operator: 'type',
    apply: 'apply',
    more: 'more',
};

const moreOptionKeys = {
    parts: 'parts',
    serials: 'serials',
    recreate: 'recreate',
}

const DashboardViewWarehouseSyncSection = () => {
    const [company] = useContext(CompanyContext);
    const [warehouses, setWarehouses] = useState([]);
    const [loading, setLoading] = useState(true)
    const {t} = useTranslation();
    const isMounted = useIsMounted()
    const translations = t('views.panel.company.dashboard.warehouseSync', {returnObjects: true})
    const injectedProps = Object.values(injectedPropertyNames).reduce((o, key) => ({...o, [key]: false}), {})
    const tableCells = [
        {
            title: translations?.tableHeaders?.title ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: tableCellKeys.title,
        },
        {
            title: translations?.tableHeaders?.submitDate ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.dateTime,
            name: tableCellKeys.submitDate,
        },
        {
            title: translations?.tableHeaders?.operator ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.string,
            name: tableCellKeys.operator,
            size: 0.5,
        },
        {
            alignment: TableCellAlignments.center,
            type: TableCellTypes.element,
            name: tableCellKeys.apply,
            size: 0.5,
        },
        {
            alignment: TableCellAlignments.center,
            type: TableCellTypes.element,
            name: tableCellKeys.more,
        }
    ]

    /**
     * Listens to the changes of the selected company of the application and which each change:
     * Fetches the warehouse syncs of that company
     */
    useEffect(() => {
        getWarehouseSyncs();
    }, [company?.id]);

    /**
     * Fetches the warehouse sync reports of the selected company from the server.
     */
    const getWarehouseSyncs = () => {
        setLoading(true)
        Api.getWarehouseSynchronizeList().then((response) => {
            if (!isMounted()) return
            if (response?.resultFlag) {
                setWarehouses(response?.data?.map(e => ({
                    ...e,
                    ...injectedProps,
                })))
            }
            setLoading(false)
        })
    }


    /**
     * Applies for a specific warehouse sync by calling the server.
     * @param {{warehouseTitle: string, warehouseID: number, id: number}} warehouseSync
     */
    const applyWarehouseSync = (warehouseSync) => {
        toggleValueForWarehouseSyncProp(warehouseSync, injectedPropertyNames.applying, true);
        Api.applyForWarehouseSynchronize(warehouseSync.warehouseID, warehouseSync.id).then((response) => {
            if (!isMounted()) return
            if (response?.resultFlag) {
                setWarehouses(response?.data?.map(e => e.id === warehouseSync.id ? {...e, isApplied: true} : e))
            }
        })
    }

    /**
     * Fetches the part numbers report for the selected warehouse sync.
     * @param {{warehouseTitle: string, warehouseID: number, id: number}} warehouseSync
     */
    const getPartsReportForWarehouseSync = (warehouseSync) => {
        toggleValueForWarehouseSyncProp(warehouseSync, injectedPropertyNames.partsReportLoading, true);
        Api.getWarehouseSyncPartsReport(warehouseSync.warehouseID, warehouseSync.id).then((response) => {
            if (!isMounted()) return
            if (response?.resultFlag) {
                if (!response?.data?.length) {
                    toast.warning(translations?.reports?.partsReport?.emptyWarning ?? '', {type: "warning"})
                    toggleValueForWarehouseSyncProp(warehouseSync, injectedPropertyNames.partsReportLoading, false);
                    return;
                }
                const report = response?.data?.map(e => ({
                    [translations.reports?.partsReport?.partNumber]: e.partNumber,
                    [translations.reports?.partsReport?.correctAvailability]: e.correctAvailability,
                    [translations.reports?.partsReport?.currentAvailability]: e.currentAvailability,
                }))
                const title = t('views.panel.company.dashboard.warehouseSync.reports.partsReport.title',
                    {
                        warehouseTitle: warehouseSync?.warehouseTitle ?? '',
                        Date: moment().format('DD-MM-YYYY HH:mm:ss')
                    })
                exportCSVFile(Object.keys(report[0]), report, title)
            }
            toggleValueForWarehouseSyncProp(warehouseSync, injectedPropertyNames.partsReportLoading, false);
        })
    }

    /**
     * Fetches the serial numbers report for the selected warehouse sync.
     * @param {{warehouseTitle: string, warehouseID: number, id: number}} warehouseSync
     */
    const getSerialsReportForWarehouseSync = (warehouseSync) => {
        toggleValueForWarehouseSyncProp(warehouseSync, injectedPropertyNames.serialsReportLoading, true);
        Api.getWarehouseSyncSerialsReport(warehouseSync.warehouseID, warehouseSync.id).then((response) => {
            if (!isMounted()) return
            if (response?.resultFlag) {
                if (!response?.data?.length) {
                    toast.warning(translations?.reports?.serialsReport?.emptyWarning ?? '', {type: "warning"})
                    toggleValueForWarehouseSyncProp(warehouseSync, injectedPropertyNames.serialsReportLoading, false);
                    return;
                }
                const report = response?.data?.map(e => ({
                    [translations.reports?.serialsReport?.partNumber]: e.partNumber,
                    [translations.reports?.serialsReport?.serialNumber]: e.serialNumber,
                }))
                const title = t('views.panel.company.dashboard.warehouseSync.reports.serialsReport.title',
                    {
                        warehouseTitle: warehouseSync?.warehouseTitle ?? '',
                        Date: moment().format('DD-MM-YYYY HH:mm:ss')
                    })
                exportCSVFile(Object.keys(report[0]), report, title)
            }
            toggleValueForWarehouseSyncProp(warehouseSync, injectedPropertyNames.serialsReportLoading, false);
        })
    }

    /**
     * Creates another warehouse sync for the given sync and replaces it with the currently given data.
     * @param {{warehouseTitle: string, warehouseID: number, id: number}} warehouseSync
     */
    const recreateWarehouseSync = (warehouseSync) => {
        toggleValueForWarehouseSyncProp(warehouseSync, injectedPropertyNames.recreating, true);
        Api.createManualWarehouseSynchronize(warehouseSync.id).then((response) => {
            if (!isMounted()) return
            if (response?.resultFlag) {
                setWarehouses(response?.data?.map(e => e.id === warehouseSync.id ? ({
                    ...response.data,
                    ...injectedProps
                }) : e))
            }
            toggleValueForWarehouseSyncProp(warehouseSync, injectedPropertyNames.recreating, false);
        })
    }

    /**
     * Toggles the prop's loading state for a single warehouse sync.
     *
     * @param {any} warehouseSync
     * @param {string} prop the property to toggle the loading state of
     * @param {boolean} value the value of the toggle
     */
    const toggleValueForWarehouseSyncProp = (warehouseSync, prop, value) => {
        setWarehouses(prevState => prevState?.map(e =>
            e.id === warehouseSync.id ? ({
                ...e,
                [prop]: value
            }) : e))
    }

    /**
     * Invokes the appropriate behaviour depending on the selected option.
     * @param {{id: int}} option the selected option
     * @param {any} warehouseSync the warehouse sync that option was selected for.
     * @param {function(boolean): void} setPopover callback to close the popover
     */
    const onMoreOptionsSelected = (option, warehouseSync, setPopover) => {
        switch (option.id) {
            case moreOptionKeys.parts:
                getPartsReportForWarehouseSync(warehouseSync)
                break;
            case moreOptionKeys.serials:
                getSerialsReportForWarehouseSync(warehouseSync)
                break;
            case moreOptionKeys.recreate:
            default:
                if (setPopover) setPopover(false)
                recreateWarehouseSync(warehouseSync)
        }
    }

    /**
     * Create the options for the button dropdown of the table rows.
     * @param {any} warehouseSync
     * @return {[{id: string, title: (*|string)}, {id: string, title: (*|string)}, {id: string, title: (*|string)}]}
     */
    const createMoreOptions = (warehouseSync) => [
        {
            title: warehouseSync[injectedPropertyNames.partsReportLoading]
                ? translations?.tableActions?.partsReport?.loading ?? ''
                : translations?.tableActions?.partsReport?.text ?? '',
            id: moreOptionKeys.parts,
            disabled: warehouseSync[injectedPropertyNames.partsReportLoading]
                || warehouseSync[injectedPropertyNames.recreating]
        },
        {
            title: warehouseSync[injectedPropertyNames.serialsReportLoading]
                ? translations?.tableActions?.serialsReport?.loading ?? ''
                : translations?.tableActions?.serialsReport?.text ?? '',
            id: moreOptionKeys.serials,
            disabled: warehouseSync[injectedPropertyNames.serialsReportLoading]
                || warehouseSync[injectedPropertyNames.recreating]
        },
        {
            title: warehouseSync[injectedPropertyNames.recreating]
                ? translations?.tableActions?.recreateReport?.loading ?? ''
                : translations?.tableActions?.recreateReport?.text ?? '',
            id: moreOptionKeys.recreate,
            disabled: warehouseSync[injectedPropertyNames.recreating]
        },
    ]


    /**
     * Creates the data entries for warehouse synchronous table
     * @return {TableCellData[]}
     */
    const createTableData = () => {
        return warehouses
            ?.map((warehouseSync) => ({
                key: warehouseSync.id,
                [tableCellKeys.title]: warehouseSync?.warehouseTitle,
                [tableCellKeys.submitDate]: warehouseSync?.submitDateTime,
                [tableCellKeys.operator]: warehouseSync?.operatorName,
                [tableCellKeys.apply]: () => (
                    <button
                        disabled={warehouseSync.isApplied
                        || warehouseSync[injectedPropertyNames.applying]
                        || warehouseSync[injectedPropertyNames.recreating]}
                        className='button primary px-4'
                        onClick={() => applyWarehouseSync(warehouseSync)}>
                        {warehouseSync.isApplied
                            ? translations?.tableActions?.apply?.applied ?? ''
                            : warehouseSync[injectedPropertyNames.applying]
                                ? translations?.tableActions?.apply?.loading ?? ''
                                : translations?.tableActions?.apply?.text ?? ''
                        }
                    </button>
                ),
                [tableCellKeys.more]: () => (
                    <ButtonDropdown
                        variant={'outlined'}
                        title={translations?.tableActions?.more ?? ''}
                        options={createMoreOptions(warehouseSync)}
                        onOptionSelected={(option, setPopover) => onMoreOptionsSelected(option, warehouseSync, setPopover)}
                        buttonProps={{
                            className: 'primary more',
                        }}
                    />
                ),
            }))
    }

    return (
        <>
            <div className='warehouse-sync'>
                <p className='title'>
                    {translations.title}
                </p>
                <div>
                    {
                        warehouses?.length <= 1 && !loading
                            ? (
                                <NoEntries text={translations?.empty ?? ''}/>
                            )
                            : (
                                <Table
                                    loading={{state: loading, count: 4}}
                                    className={'my-3'}
                                    color={'primary'}
                                    cells={tableCells}
                                    data={createTableData()}
                                />
                            )
                    }
                </div>
            </div>
        </>
    )
}

export default DashboardViewWarehouseSyncSection;
