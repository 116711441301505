import React, {useLayoutEffect, useState} from "react";
import BreadCrumb from "./bread-crumb";

/**
 * @typedef {{
 *      key: any,
 *      child: JSX.Element | HTMLElement,
 *      className: string,
 *      indented: null | boolean,
 *      onClick: function(): void
 *  }} BreadCrumb
 */

/**
 * @param {BreadCrumb[]} data
 */
const BreadCrumbs = ({data}) => {
    const [breadCrumbs, setBreadCrumbs] = useState([]);

    /**
     * Listens for the changes in data and with each change:
     * sets the data of state to be later used for creation of each bread crumb.
     *
     * the created inner list will have a default value of true for indented if not provided by data.
     */
    useLayoutEffect(() => {
        setBreadCrumbs(data?.map((breadCrumb, index) => ({
            ...breadCrumb,
            indented: breadCrumb.indented === undefined
                ? index !== data?.length - 1
                : breadCrumb.indented,
        })) ?? [])
    }, [data])

    return (
        <>
            <div className={'bread-crumbs'}>
                {
                    breadCrumbs?.map((breadCrumb, index) => (
                        <BreadCrumb
                            key={breadCrumb.key}
                            order={breadCrumbs?.length - index}
                            {...breadCrumb}
                        />
                    ))
                }
            </div>
        </>
    )
}

export default BreadCrumbs;
