import React, {useContext} from "react";
import classnames from "classnames";
import {ReactComponent as Icon} from "../../../../../assets/images/panel/top-bar/inventory-schema.svg";
import {matchPath} from "react-router";
import routes from "../../../../routes";
import useRouter from "../../../../hooks/use-router";
import {CompanyContext} from "../../../../contexts/company";

const PanelTopbarInventorySchema = ({disabled}) => {
    const [company] = useContext(CompanyContext);
    const {location, history} = useRouter();
    const isInInventorySchemaView = !!matchPath(location.pathname, {
        path: [routes.panel.company.inventorySchema.view],
        exact: true,
    });

    /**
     * navigates the user to the inventory-schemas' view
     */
    const navigateToInventorySchema = () => {
        if (disabled || !company)
            return;
        history.push(routes.panel.company.inventorySchema.view);
    }

    return (
        <>
            <div
                className={classnames('topbar-icon',
                    {'disabled': disabled || !company},
                    {'active': isInInventorySchemaView}
                )}
                onClick={navigateToInventorySchema}
            >
                <Icon/>
            </div>
        </>
    )
}

export default PanelTopbarInventorySchema;
