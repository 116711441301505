import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import useRouter from "../../../../../hooks/use-router";
import {DefaultInitialPaginationInfo, DefaultPaginationInfo, TableCellAlignments, TableCellTypes} from "../../../../../../core/constants/enums";
import {
    generatePaginationAndOrderByQueryResetter,
    hasOrderByOfQueryChanged,
    hasPaginationInfoChanged,
    onTableCurrentPageChange,
    onTablePageSizeChange,
    onTableSort
} from "../../../../../../core/services/searching-utils";
import {SingleOrderQueryNames} from "../../../../../../core/constants/query-names";
import {deepEqual} from "../../../../../../core/services/utils";
import {ReactComponent as BarcodeIcon} from "../../../../../../assets/images/barcode-icon.svg";
import {ReactComponent as IsSerialized} from "../../../../../../assets/images/order-serializable/serialized.svg";
import {ReactComponent as NotSerialized} from "../../../../../../assets/images/order-serializable/not-serialized.svg";
import {routeFunctions} from "../../../../../routes";
import NoEntries from "../../../../../components/app-specific/no-entries";
import Table from "../../../../../containers/table";
import SalesOrderPartsSearchSection from "../../../../../components/search-sections/sales-order";
import {Col, Collapse, Row} from "react-bootstrap";
import Barcode from 'react-barcode';
import {lighterPrimaryColor, primaryTextColor} from "../../../../../../assets/mui/colors";
import {ReactComponent as BackIconButton} from "../../../../../../assets/images/back-icon.svg";
import classnames from "classnames";
import {ReactComponent as ChevronIcon} from "../../../../../../assets/images/chevron-icon.svg";
import TableSpacer from "../../../../../containers/table/spacer";
import {dialogActions} from "../../../../../../redux/entities/dialog-slice";
import {useDispatch} from "react-redux";
import useIsMounted from "../../../../../hooks/use-is-mounted";


const infoTableCellKeys = {
    customerName: "customerName",
    submittedDate: "submittedDate",
    requiredDate: "requiredDate",
    totalProducts: "totalProducts",
    totalItems: "totalItems"
}

const mainTableCellsKeys = {
    expand: "expand",
    partNumber: "partNumber",
    orderCount: "orderCount",
    committedCount: "committedCount",
    notScannedCount: "notScannedCount",
    isSerialized: "isSerialized",
    weight: "weight",
    height: "height",
    width: "width",
    depth: "depth",
    description: "description"
}

const innerTableCellKeys = {
    serialNumber: "serialNumber",
    row: "row",
    section: "section",
    aisle: "aisle",
    warehouse: "warehouse",
    scannedOn: "scannedOn",
    operator: "operator"
}

const SalesOrderSingleView = () => {
    const {t} = useTranslation();
    const {query, location, history, params, stringifyUrl} = useRouter();
    const [salesOrderFullInfo, setSalesOrderFullInfo] = useState({});
    const [parts, setParts] = useState([]);
    const [paginationInfo, setPaginationInfo] = useState(DefaultPaginationInfo);
    const [orderBy, setOrderBy] = useState(null);
    const [fullInfoLoading, setFullInfoLoading] = useState(true);
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState({});
    const [expandedSectionsIds, setExpandedSectionIds] = useState([]);
    const dispatch = useDispatch();
    const isMounted = useIsMounted();

    const translation = t("views.panel.company.salesOrders.single", {returnObjects: true});
    const errorMessage = t('views.panel.errorMessages', {returnObjects: true})


    const infoTableCells = [
        {
            title: translation?.infoTableHeader?.customerName ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: infoTableCellKeys.customerName,
            size: 0,
        },
        {
            title: translation?.infoTableHeader?.submittedDate ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.date,
            name: infoTableCellKeys.submittedDate,
            size: 0
        },
        {
            title: translation?.infoTableHeader?.requiredDate ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.date,
            name: infoTableCellKeys.requiredDate,
            size: 0
        },
        {
            title: translation?.infoTableHeader?.totalProducts ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.string,
            name: infoTableCellKeys.totalProducts,
            size: 0
        },
        {
            title: translation?.infoTableHeader?.totalItems ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.string,
            name: infoTableCellKeys.totalItems,
            size: 0
        },
    ];

    const mainTableCells = [
        {
            alignment: TableCellAlignments.center,
            type: TableCellTypes.element,
            name: mainTableCellsKeys.expand,
            size: 0,
        },
        {
            title: translation?.partsTableHeaders?.partNumber ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: mainTableCellsKeys.partNumber,
            sortable: true,
        },
        {
            title: translation?.partsTableHeaders?.orderCount ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.element,
            name: mainTableCellsKeys.orderCount,
            sortable: true,
        },
        {
            title: translation?.partsTableHeaders?.committedCount ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.element,
            name: mainTableCellsKeys.committedCount,
            sortable: true,
            size: 1.3
        },
        {
            title: translation?.partsTableHeaders?.notScannedCount ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.element,
            name: mainTableCellsKeys.notScannedCount,
            sortable: true,
            size: 1.4
        },
        {
            title: translation?.partsTableHeaders?.isSerialized ?? '',
            alignment: TableCellAlignments.center,
            type: TableCellTypes.element,
            name: mainTableCellsKeys.isSerialized,
            sortable: true,
        },
        {
            title: translation?.partsTableHeaders?.weight ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.string,
            name: mainTableCellsKeys.weight,
            sortable: true,
        },
        {
            title: translation?.partsTableHeaders?.height ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.string,
            name: mainTableCellsKeys.height,
            sortable: true,
        },
        {
            title: translation?.partsTableHeaders?.width ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.string,
            name: mainTableCellsKeys.width,
            sortable: true,
        },
        {
            title: translation?.partsTableHeaders?.depth ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.string,
            name: mainTableCellsKeys.depth,
            sortable: true,
        },
        {
            title: translation?.partsTableHeaders?.description ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: mainTableCellsKeys.description,
            sortable: true,
            size: 3
        },
    ];

    const innerTableCells = [
        {
            title: translation?.partsInnerTableHeader?.serialNumber ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: innerTableCellKeys.serialNumber,
        },
        {
            title: translation?.partsInnerTableHeader?.row ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: innerTableCellKeys.row,
        },
        {
            title: translation?.partsInnerTableHeader?.section ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: innerTableCellKeys.section,
        },
        {
            title: translation?.partsInnerTableHeader?.aisle ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: innerTableCellKeys.aisle,
        },
        {
            title: translation?.partsInnerTableHeader?.warehouse ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: innerTableCellKeys.warehouse,
        },
        {
            title: translation?.partsInnerTableHeader?.scannedOn ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.dateTime,
            name: innerTableCellKeys.scannedOn,
        },
        {
            title: translation?.partsInnerTableHeader?.operator ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: innerTableCellKeys.operator,
        },
    ];

    /**
     * With each change in the url params:
     *
     * - Calls the api for getting the full information of the sales order
     */
    useEffect(() => {
        setSalesOrderFullInfo({
            id: 1,
            orderNumber: "Q123123-0",
            customerName: "Richardson",
            submittedDate: new Date(),
            requiredDate: new Date(),
            totalProducts: 10,
            totalItems: 50,
        });
        setFullInfoLoading(false);
    }, [params]);

    /**
     * With each change in the query params of the url:
     *
     * - Checks if the filters, pagination info, or order-by have been changed, if so, calls the api for getting the parts in the sales order with new filters
     */
    useEffect(() => {
        hasPaginationInfoChanged(query, paginationInfo, setPaginationInfo);
        const newFilter = hasFiltersChanged();
        const newOrderBy = hasOrderByOfQueryChanged(query, orderBy, setOrderBy);
        if (newFilter || newOrderBy)
            setPaginationInfo(DefaultInitialPaginationInfo)
    }, [query])

    useEffect(() => {
        if (paginationInfo.currentPage === DefaultInitialPaginationInfo.currentPage)
            return setPaginationInfo(DefaultPaginationInfo);
        searchParts();
    }, [paginationInfo.currentPage, paginationInfo.pageSize])

    /**
     * Checks if the search params has been changes and if so, sets the [filter] in the state to the new value
     * @return {{}}
     */
    const hasFiltersChanged = () => {
        let newFilters = {};
        const newKeyword = query[SingleOrderQueryNames.keyword];
        if (newKeyword?.length) {
            newFilters[SingleOrderQueryNames.keyword] = newKeyword;
        }
        if (!deepEqual(newFilters, filters)) {
            setFilters(newFilters);
            return newFilters;
        }
    }

    /**
     * Searches for parts within a sales order:
     * - Calls the api regarding this action
     */
    const searchParts = async () => {
        const forApi = {
            paginationInfo: paginationInfo,
            filters: filters,
            orderBy: orderBy
        }
        const response = await searchPartsApiCall(forApi);
        if (!isMounted())
            return;
        if (response?.resultFlag) {
            setParts(response?.data?.items);
            setPaginationInfo(prevState => ({...prevState, length: response?.data?.paginationInfo?.length}));
        }
        setLoading(false);
    }

    /**
     * calls the api for getting the list of parts based on the given request information
     * @param {(Object | null)} forApi
     * @return {Promise<{data: {paginationInfo: {length: number}, items: [{depth: number, width: number, isSerialized: boolean, weight: number, description: string, partNumber: string, id: number, height: number, submitDateTime: Date},{depth: number, width: number, isSerialized: boolean, weight: number, description: string, partNumber: string, id: number, height: number, submitDateTime: Date},{depth: number, width: number, isSerialized: boolean, weight: number, description: string, partNumber: string, id: number, height: number, submitDateTime: Date},{depth: number, width: number, isSerialized: boolean, weight: number, description: string, partNumber: string, id: number, height: number, submitDateTime: Date}]}, resultFlag: boolean}>}
     */
    const searchPartsApiCall = async (forApi = null) => {
        return {
            resultFlag: true,
            data: {
                items: [
                    {
                        id: 1,
                        partNumber: "RADFR101A",
                        orderCount: 10,
                        committedCount: 14,
                        notScannedCount: 10,
                        isSerialized: true,
                        expanded: false,
                        weight: 12,
                        height: 12,
                        width: 12,
                        depth: 12,
                        description: "This is a sample description for checking how the description is being handled",
                        scannedSerials: [
                            {
                                id: 20,
                                serialNumber: '123-123-123',
                                row: 'R1',
                                section: 'S1',
                                aisle: 'A1',
                                warehouse: 'Warehouse1',
                                scannedOn: new Date(),
                                operator: "Testing User"
                            },
                            {
                                id: 21,
                                serialNumber: '123-123-123',
                                row: 'R1',
                                section: 'S1',
                                aisle: 'A1',
                                warehouse: 'Warehouse1',
                                scannedOn: new Date(),
                                operator: "Testing User"
                            },
                            {
                                id: 22,
                                serialNumber: '123-123-123',
                                row: 'R1',
                                section: 'S1',
                                aisle: 'A1',
                                warehouse: 'Warehouse1',
                                scannedOn: new Date(),
                                operator: "Testing User"
                            },
                            {
                                id: 23,
                                serialNumber: '123-123-123',
                                row: 'R1',
                                section: 'S1',
                                aisle: 'A1',
                                warehouse: 'Warehouse1',
                                scannedOn: new Date(),
                                operator: "Testing User"
                            },
                            {
                                id: 24,
                                serialNumber: '123-123-123',
                                row: 'R1',
                                section: 'S1',
                                aisle: 'A1',
                                warehouse: 'Warehouse1',
                                scannedOn: new Date(),
                                operator: "Testing User"
                            }
                        ],
                        suggestedSerials: [
                            {
                                id: 28,
                                serialNumber: '123-123-123',
                                row: 'R1',
                                section: 'S1',
                                aisle: 'A1',
                                warehouse: 'Warehouse1',
                            },
                            {
                                id: 29,
                                serialNumber: '123-123-123',
                                row: 'R1',
                                section: 'S1',
                                aisle: 'A1',
                                warehouse: 'Warehouse1',
                            }
                        ],
                    },
                    {
                        id: 2,
                        partNumber: "RADFR101A",
                        orderCount: 10,
                        committedCount: 14,
                        notScannedCount: 10,
                        isSerialized: false,
                        expanded: false,
                        weight: 12,
                        height: 12,
                        width: 12,
                        depth: 12,
                        description: "This is a sample description for checking how the description is being handled",
                    },
                    {
                        id: 3,
                        partNumber: "RADFR101A",
                        orderCount: 10,
                        committedCount: 14,
                        notScannedCount: 10,
                        isSerialized: true,
                        expanded: false,
                        weight: 12,
                        height: 12,
                        width: 12,
                        depth: 12,
                        description: "This is a sample description for checking how the description is being handled",
                        scannedSerials: [
                            {
                                id: 41,
                                serialNumber: '123-123-123',
                                row: 'R1',
                                section: 'S1',
                                aisle: 'A1',
                                warehouse: 'Warehouse1',
                                scannedOn: new Date(),
                                operator: "Testing User"
                            },
                            {
                                id: 42,
                                serialNumber: '123-123-123',
                                row: 'R1',
                                section: 'S1',
                                aisle: 'A1',
                                warehouse: 'Warehouse1',
                                scannedOn: new Date(),
                                operator: "Testing User"
                            },
                            {
                                id: 43,
                                serialNumber: '123-123-123',
                                row: 'R1',
                                section: 'S1',
                                aisle: 'A1',
                                warehouse: 'Warehouse1',
                                scannedOn: new Date(),
                                operator: "Testing User"
                            },
                            {
                                id: 44,
                                serialNumber: '123-123-123',
                                row: 'R1',
                                section: 'S1',
                                aisle: 'A1',
                                warehouse: 'Warehouse1',
                                scannedOn: new Date(),
                                operator: "Testing User"
                            },
                            {
                                id: 45,
                                serialNumber: '123-123-123',
                                row: 'R1',
                                section: 'S1',
                                aisle: 'A1',
                                warehouse: 'Warehouse1',
                                scannedOn: new Date(),
                                operator: "Testing User"
                            }
                        ],
                        suggestedSerials: [
                            {
                                id: 48,
                                serialNumber: '123-123-123',
                                row: 'R1',
                                section: 'S1',
                                aisle: 'A1',
                                warehouse: 'Warehouse1',
                            },
                            {
                                id: 49,
                                serialNumber: '123-123-123',
                                row: 'R1',
                                section: 'S1',
                                aisle: 'A1',
                                warehouse: 'Warehouse1',
                            }
                        ],
                    },
                    {
                        id: 4,
                        partNumber: "RADFR101A",
                        orderCount: 10,
                        committedCount: 14,
                        notScannedCount: 10,
                        isSerialized: false,
                        expanded: false,
                        weight: 12,
                        height: 12,
                        width: 12,
                        depth: 12,
                        description: "This is a sample description for checking how the description is being handled",
                    },
                ],
                paginationInfo: {
                    length: 4,
                }
            }
        }
    }

    /**
     * Handles click on the barcode icon button:
     * - Opens dialog for showing the barcode
     * @param {string} code for generating barcode from
     */
    const onBarcodeClicked = (code) => {
        dispatch(dialogActions.configBarcodeDialog({
            open: true,
            onClose: () => dispatch(dialogActions.barcodeDialogToggle(false)),
            barcodeCode: code,
        }));
    }

    /**
     * Navigates user to the part single view:
     * @param {number} partId
     */
    const onPartNumberClicked = (partId) => {
        history.push(routeFunctions.panel.company.items.single.product(params.company, partId));
    }

    /**
     * Clears filters from url query params for triggering search without filters
     */
    const clearFilters = () => {
        history.push(stringifyUrl({
            url: location.pathname,
            query: {
                ...generatePaginationAndOrderByQueryResetter()
            }
        }));
    }

    /**
     * Navigates user back to sales order list view
     */
    const onNavigateBackClicked = () => {
        history.replace(routeFunctions.panel.company.salesOrders.list(params.company));
    }

    /**
     * Handles expanding the table row for showing inner table
     * @param part
     */
    const onExpandClicked = (part) => {
        if (!part.expanded) {
            setExpandedSectionIds(prevState => [...prevState, part.id]);
        }
        setParts(
            prevState => prevState.map(item => {
                if (item.id === part?.id) {
                    return {
                        ...item,
                        expanded: !part.expanded
                    }
                }
                return item;
            })
        );
    }

    /**
     * Handles click on serial number text button:
     * - Navigates user to instance single view
     * @param {number} serialId
     */
    const onSerialNumberClicked = (serialId) => {
        history.push(routeFunctions.panel.company.items.single.instance(params.company, serialId));
    }

    const onWarehouseClicked = (warehouseId) => {
    }

    const onAisleClicked = (aisleId) => {
    }

    const onSectionClicked = (sectionId) => {
    }

    const onRowClicked = (rowId) => {
    }

    /**
     * Creates the data entries for the information of sales order table
     * @return {TableData[]}
     */
    const createInformationTableData = () => {
        return [{
            key: salesOrderFullInfo.id,
            [infoTableCellKeys.customerName]: salesOrderFullInfo.customerName,
            [infoTableCellKeys.submittedDate]: salesOrderFullInfo.submittedDate,
            [infoTableCellKeys.requiredDate]: salesOrderFullInfo.requiredDate,
            [infoTableCellKeys.totalProducts]: salesOrderFullInfo.totalProducts,
            [infoTableCellKeys.totalItems]: salesOrderFullInfo.totalItems,
        }];
    }

    /**
     * Creates the data entries for the instances of part in inner table
     * @param items
     * @return {TableData[]}
     */
    const createInnerTableData = (items) => {
        //TODO: Change the onClick functions for locations when DS is complete
        return items.map(serial => (
            {
                key: serial?.id,
                [innerTableCellKeys.serialNumber]: () => (
                    <div className={'d-flex flex-row align-items-center'}>
                        {
                            serial?.serialNumber?.length > 0 &&
                            <div
                                onClick={() => onBarcodeClicked(serial?.serialNumber)}
                                className={'barcode-icon-button'}
                            >
                                <BarcodeIcon/>
                            </div>
                        }
                        <p
                            className={'text-button'}
                            onClick={() => onSerialNumberClicked(serial.id)}
                        >
                            {serial?.serialNumber}
                        </p>
                    </div>
                ),
                [innerTableCellKeys.row]: () => (
                    <p
                        className={'text-button'}
                        onClick={() => onRowClicked(serial.id)}
                    >
                        {serial?.row}
                    </p>
                ),
                [innerTableCellKeys.section]: () => (
                    <p
                        className={'text-button'}
                        onClick={() => onSectionClicked(serial.id)}
                    >
                        {serial?.section}
                    </p>
                ),
                [innerTableCellKeys.aisle]: () => (
                    <p
                        className={'text-button'}
                        onClick={() => onAisleClicked(serial.id)}
                    >
                        {serial?.aisle}
                    </p>
                ),
                [innerTableCellKeys.warehouse]: () => (
                    <p
                        className={'text-button'}
                        onClick={() => onWarehouseClicked(serial.id)}
                    >
                        {serial?.warehouse}
                    </p>
                ),
                [innerTableCellKeys.scannedOn]: serial.scannedOn,
                [innerTableCellKeys.operator]: serial.operator,
            }
        ));
    }

    /**
     *
     * @param {number} id the id of the part in the tabl row
     */
    const hideExpandedSection = (id) => {
        setExpandedSectionIds(prevState => prevState.filter(e => e !== id));
    }

    /**
     * Creates the data entries for the information of sales order table
     * @return {TableData[]}
     */
    const createTableData = () => {
        return parts?.map(part => (
            {
                key: part?.id,
                [mainTableCellsKeys.expand]: () => (
                    <div>
                        {part.isSerialized && <div
                            className={classnames('expand-chevron', {'expanded': part?.expanded})}
                            onClick={() => onExpandClicked(part)}
                        >
                            <ChevronIcon/>
                        </div>}
                    </div>
                ),
                [mainTableCellsKeys.partNumber]: () => (
                    <div className={'d-flex flex-row align-items-center'}>
                        {
                            part?.partNumber?.length > 0 &&
                            <div
                                onClick={() => onBarcodeClicked(part?.partNumber)}
                                className={'barcode-icon-button'}
                            >
                                <BarcodeIcon/>
                            </div>
                        }
                        <p
                            className={'text-button'}
                            onClick={() => onPartNumberClicked(part.id)}
                        >
                            {part.partNumber}
                        </p>
                    </div>
                ),
                [mainTableCellsKeys.orderCount]: () => (
                    <div className={'order-count'}>
                        <p>
                            {part.orderCount}
                        </p>
                    </div>
                ),
                [mainTableCellsKeys.committedCount]: () => (
                    <div className={'committed-count'}>
                        <p>
                            {part.committedCount}
                        </p>
                    </div>
                ),
                [mainTableCellsKeys.notScannedCount]: () => (
                    <div className={'not-scanned-count'}>
                        <p>
                            {part.notScannedCount}
                        </p>
                    </div>
                ),
                [mainTableCellsKeys.isSerialized]: () => (
                    <div>
                        {part.isSerialized && <IsSerialized/>}
                        {!part.isSerialized && <NotSerialized/>}
                    </div>
                ),
                [mainTableCellsKeys.weight]: part.weight ?? '',
                [mainTableCellsKeys.height]: part.height ?? '',
                [mainTableCellsKeys.width]: part.width ?? '',
                [mainTableCellsKeys.depth]: part.depth ?? '',
                [mainTableCellsKeys.description]: part.description ?? '',
                expandedSection: !part.isSerialized
                    ? () => {
                    }
                    : () => (
                        //Make sure collapse is imported from react bootstrap
                        <>
                            <tr className={classnames({'visually-hidden': !expandedSectionsIds.includes(part?.id)})}>
                                <td colSpan={(expandedSectionsIds.length > 0) ? 12 : 0}>
                                    <Collapse in={part?.expanded} onExited={() => hideExpandedSection(part.id)}>
                                        <div className={'inner-item-container scanned-instances'}>
                                            {
                                                part?.scannedSerials?.length > 0
                                                    ? <Table
                                                        className={'my-3 w-auto'}
                                                        color={'secondary'}
                                                        cells={innerTableCells}
                                                        data={createInnerTableData(part?.scannedSerials)}
                                                    />
                                                    : <NoEntries
                                                        text={errorMessage}
                                                        includeButton={false}
                                                    />
                                            }
                                            {
                                                part?.suggestedSerials?.length > 0 &&
                                                <>
                                                    <p className={'title'}>
                                                        {translation?.suggestion}
                                                    </p>
                                                    <Table
                                                        className={'my-3 w-auto second-table'}
                                                        color={'secondary'}
                                                        cells={innerTableCells}
                                                        data={createInnerTableData(part?.suggestedSerials)}
                                                    />
                                                </>
                                            }
                                        </div>
                                    </Collapse>
                                </td>
                            </tr>
                            {
                                part?.expanded &&
                                <TableSpacer length={mainTableCells.length}/>
                            }
                        </>
                    ),
            }
        ));

    }


    return (
        <>
            <div className={'single-sales-order'}>
                <div className={'panel-card p-3'}>
                    <div className={'d-flex align-items-start justify-content-start'}>
                        <div className={'back-icon-button'} onClick={onNavigateBackClicked}>
                            <BackIconButton/>
                        </div>
                        <div>
                            <p className={'title'}>
                                {salesOrderFullInfo.orderNumber}
                            </p>
                            <p className={'sub-title'}>
                                {translation?.salesOrderInformation ?? ''}
                            </p>
                        </div>
                    </div>
                    <div className={'sales-order-info'}>
                        <Row>
                            <Col xs={12} xl={9}>
                                <div>
                                    <Table
                                        loading={{state: fullInfoLoading, count: 2}}
                                        className={'my-3'}
                                        color={'primary'}
                                        cells={infoTableCells}
                                        data={createInformationTableData()}
                                    />
                                </div>
                            </Col>
                            <Col xs={12} xl={3}>
                                <div className={'barcode-section d-flex m-auto'}>
                                    {/*TODO: change the value based on api*/}
                                    <Barcode
                                        value={"RADFR101A"}
                                        text={"Scan by phone app"}
                                        font={"Montserrat"}
                                        fontSize={15}
                                        lineColor={primaryTextColor}
                                        width={2}
                                        height={150}
                                        background={lighterPrimaryColor}

                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <p className={'parts-title'}>
                            {translation?.parts}
                        </p>
                    </div>
                    <SalesOrderPartsSearchSection filters={filters}/>
                    <div>
                        {
                            parts?.length < 1 && !loading
                                ? (
                                    <NoEntries
                                        text={
                                            Object.entries(filters ?? {})?.length > 0
                                                ? translation?.empty?.withFilter
                                                : translation?.empty?.text
                                        }
                                        includeButton={Object.entries(filters ?? {})?.length > 0}
                                        buttonProps={{
                                            onClick: clearFilters,
                                            className: 'button primary px-3'
                                        }}/>
                                )
                                : (
                                    <Table
                                        loading={{state: loading, count: 8}}
                                        className={'my-3'}
                                        color={'primary'}
                                        cells={mainTableCells}
                                        data={createTableData()}
                                        paginationInfo={paginationInfo}
                                        orderBy={orderBy}
                                        onSort={(o) => onTableSort(o, query, history, location)}
                                        onPageSizeChange={(p, i) => onTablePageSizeChange(p, i, query, history, location)}
                                        onCurrentPageChange={(p, i) => onTableCurrentPageChange(p, i, query, history, location)}
                                    />
                                )
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default SalesOrderSingleView;
