import {ReactComponent as HeaderArrow} from "../../../../assets/images/table-header-arrow.svg";
import classnames from "classnames";
import {TableCellAlignments} from "../../../../core/constants/enums";
import React from "react";
import TableCell from "../cell";
import TableSpacer from "../spacer";

/**
 *
 * @param {TableCellData[]}             cells
 * @param {TableOrderBy}            orderBy
 * @param {function(TableOrderBy)}  onSortProp
 */
const TableHeader = ({cells, orderBy, onSort: onSortProp}) => {

    /**
     * Calls the onSort callback with the appropriate arguments for sorting a cell in the header.
     * @param {TableCellData} cell
     * @param {boolean} isDescending
     */
    const onSort = (cell, isDescending) => {
        if (!onSortProp) return;
        onSortProp({propertyName: cell.name, isDescending});
    }

    /**
     * Renders the cell element of a table header.
     *
     * Determines if the cell should contain the sort arrow and its state as well.
     *
     * @param cell
     * @return {JSX.Element|*|(function(function(boolean)): *)}
     */
    const renderCell = (cell) => {
        let cellElement = cell.title;
        if (!cell.sortable) {
            return cellElement;
        }
        const sortedBasedOnCell = orderBy?.propertyName === cell.name;
        const isSortDescending = sortedBasedOnCell ? orderBy?.isDescending : true;
        const cellArrow = (
            <HeaderArrow
                onClick={() => onSort(cell, sortedBasedOnCell ? !isSortDescending : true)}
                className={classnames('header-arrow-container',
                    {
                        'active': sortedBasedOnCell,
                        'up': !isSortDescending
                    }
                )}
            />
        )
        if (!React.isValidElement(cellElement)) {
            cellElement = (
                <p className={classnames({'active': sortedBasedOnCell})}>
                    {cellElement}
                </p>
            )
        }
        switch (cell.alignment) {
            case TableCellAlignments.left:
            case TableCellAlignments.center:
            default:
                return (
                    <>
                        {cellElement}
                        {cellArrow}
                    </>
                )
            case TableCellAlignments.right:
                return (
                    <>
                        {cellArrow}
                        {cellElement}
                    </>
                )
        }
    }

    return (
        <>
            <thead className={'table-header'}>
            <tr>
                {
                    cells
                        ?.filter(cell => !cell.last)
                        ?.map((cell) => (
                            <TableCell
                                key={cell.name}
                                className={classnames('header-cell')}
                                renderCell={renderCell}
                                cell={cell}
                            />
                        ))
                }
                <th className={'last-cell'}/>
            </tr>
            <TableSpacer length={cells.length}/>

            </thead>
        </>
    );
}

export default TableHeader;
