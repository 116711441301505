import {useEffect, useState} from "react";
import PaymentMethodsDefaultCard from "./sections/default-card";
import PaymentMethodsOtherCards from "./sections/other-cards";
import {getCardMonth, getCardNumber} from "../../../../core/services/utils";
import {CreditCardTypes} from "../../../../core/constants/enums";


const PaymentMethodsView = () => {
    const [loading, setLoading] = useState(true);
    const [cards, setCards] = useState([]);
    const [defaultCard, setDefaultCard] = useState({});

    /**
     * As soon as the component mounts:
     * Fetches the list of available payment methods from the server.
     */
    useEffect(() => {
        getPaymentMethodsList();
    }, []);

    /**
     * Fetches the payment methods of the user fro the server and sets the state accordingly
     */
    const getPaymentMethodsList = () => {
        setLoading(true);
        // TODO: Call the API to get the cards list
        const res = {
            resultFlag: true,
            data: [
                {
                    id: 11,
                    isDefault: true,
                    lastFourDigits: "5675",
                    month: "01",
                    type: "V",
                },
                {
                    id: 12,
                    isDefault: false,
                    lastFourDigits: "5675",
                    month: "01",
                    type: "M",
                },
                {
                    id: 13,
                    isDefault: false,
                    lastFourDigits: "4242",
                    month: "01",
                    type: "AX",
                },
                {
                    id: 14,
                    isDefault: false,
                    lastFourDigits: "4242",
                    month: "01",
                    type: "BB",
                },
            ],
        };
        const defaultCard = res?.data?.find((e) => e.isDefault);
        if (defaultCard) {
            setDefaultCard(createCardData(defaultCard));
        }
        setCards(res?.data?.map(createCardData));
        setTimeout(() => setLoading(false), 1000)
        // setLoading(false);
    };

    /**
     * Creates the card data form the server data.
     * @param {any} card
     * @return {*&{cvc: string, number: string, expiry: string, loading: boolean, issuer: string}}
     */
    const createCardData = (card) => {
        return {
            ...card,
            cvc: '***',
            number: getCardNumber(card.lastFourDigits, card.type),
            expiry: getCardMonth(card.month),
            issuer: CreditCardTypes[card?.type] ?? 'unknown',
            loading: false,
        }
    }

    /**
     * Sets the selected card as the current default card of the user.
     *
     * calls the api for setting the default card as well.
     * @param {any}                         card
     * @param {function(boolean): void}     setLoading
     */
    const setCardAsDefault = (card, setLoading) => {
        // TODO: call the API to set the card to default
        if (setLoading) setLoading(true);
        setDefaultCard(card)
        setCards(prevState => prevState?.map(e => e.id === card.id
            ? {...e, isDefault: true}
            : e?.isDefault
                ? {...e, isDefault: false}
                : e
        ))
        if (setLoading) setLoading(false);
    }

    /**
     * Removes the selected credit card from the list of available credit cards of the user
     * @param {any} card
     */
    const removeCard = (card) => {
        setCards(prevState => prevState?.map(e => e.id === card.id ? {...e, loading: true} : e))
        // TODO: call the API to remove the card
        setCards(prevState => prevState?.filter((e) => e.id !== card?.id));
    }

    /**
     * Adds the newly added card to the list of cards
     * @param {any} newCard the newly added card
     */
    const addNewCard = (newCard) => {
        setCards(prevState => [...prevState, createCardData(newCard)])
    }


    return (
        <div className='panel-card payment-methods'>
            {!!defaultCard &&
                <div>
                    <PaymentMethodsDefaultCard loading={loading} card={defaultCard}/>
                </div>
            }
            <div className='mt-5'>
                <PaymentMethodsOtherCards
                    cardsList={cards}
                    loading={loading}
                    setDefault={setCardAsDefault}
                    removeCard={removeCard}
                    addCard={addNewCard}
                />
            </div>
        </div>
    );
}

export default PaymentMethodsView;
