import React, {useEffect} from "react";
import {matchPath, Route, Switch} from "react-router";
import routes, {routeFunctions, routesLists} from "../../../routes";
import useRouter from "../../../hooks/use-router";
import LocationViews from "./location";
import DashboardView from "./dashboard";
import CountingViews from "./counting";
import UsersView from "./users";
import ItemsView from "./products";
import SalesOrderView from "./sales-order";
import PurchaseOrderView from "./purchase-order";
import EditInventorySchemaView from "./inventory-schema/edit";
import ViewInventorySchemaView from "./inventory-schema/view";

const CompanyViews = () => {
    const {location, history, params} = useRouter();

    /**
     * Listens to the changes in pathname of the url route and with each change:
     * if the route is the same as the panel.company.base, changes it to the dashboard route.
     */
    useEffect(() => {
        if (!!matchPath(location.pathname, {path: routes.panel.company.base, exact: true})) {
            history.replace(routeFunctions.panel.company.dashboard(params.company));
        }
    }, [location.pathname])

    return (
        <>
            <Switch>
                <Route path={[
                    routes.panel.company.dashboard,
                    routes.panel.company.base,
                ]} exact>
                    <DashboardView/>
                </Route>
                <Route path={[
                    routes.panel.company.location.base,
                    routes.panel.company.location.warehouses,
                    routes.panel.company.location.aisles,
                    routes.panel.company.location.sections,
                    routes.panel.company.location.rows,
                    routes.panel.company.location.rowItems,
                ]} exact>
                    <LocationViews/>
                </Route>
                <Route path={[
                    routes.panel.company.users.base,
                    routes.panel.company.users.list,
                    routes.panel.company.users.groups,
                ]} exact>
                    <UsersView/>
                </Route>
                <Route path={routesLists.counting} exact>
                    <CountingViews/>
                </Route>
                <Route path={routesLists.items} exact>
                    <ItemsView/>
                </Route>
                <Route path={routesLists.salesOrders} exact>
                    <SalesOrderView/>
                </Route>
                <Route path={routesLists.purchaseOrders} exact>
                    <PurchaseOrderView/>
                </Route>
                <Route path={routes.panel.company.inventorySchema.edit} exact>
                    <EditInventorySchemaView/>
                </Route>
                <Route path={routes.panel.company.inventorySchema.view} exact>
                    <ViewInventorySchemaView/>
                </Route>
            </Switch>
        </>
    )
}

export default CompanyViews;
