import React from "react";
import classnames from "classnames";

const BreadCrumb = ({onClick, child, className, indented, order}) => {

    return (
        <div
            style={{'--order': order}}
            onClick={onClick}
            className={classnames('bread-crumb', className, {
                "indented": indented,
                'clickable': onClick !== undefined
            })}>
            {React.isValidElement(child)
                ? child
                : (
                    <p>
                        {child}
                    </p>
                )
            }
        </div>
    );
}

export default BreadCrumb;
