import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import Konva from "konva";
import {IInitialSnapGuide, ISnapGuide} from "../../../types";
import Utils from "../../../../core/services/utils";
import {Layer, Line} from "react-konva";

export type ISnappyLayerProps = React.PropsWithChildren<Omit<Konva.LayerConfig, 'ref' | 'onDragEnd' | 'onDragMove'> & {
    stage: Konva.Stage,
    stageSize: { width: number, height: number }
    draggableSelector: string,
    padding?: number,
}>;


const SnappyLayer = ({stage, draggableSelector, children, stageSize, padding = 0, ...props}: ISnappyLayerProps) => {
    const layerRef = useRef<Konva.Layer>();
    const [guides, setGuides] = useState<Array<ISnapGuide>>([]);
    const GUIDELINE_OFFSET = useMemo(() => 5, []);

    /**
     * Determines the position by which we can use as the snap anchors of our rectangles based on the stage.
     * @param skipShape
     */
    const getLineGuideStops = useCallback((skipShape: Konva.Node) => {
        // check to see if the shape is nested, and if so, gets no guides.
        let specificAncestor = skipShape;
        let foundAncestor = false;
        while (specificAncestor.parent) {
            if (specificAncestor.parent.hasName(draggableSelector)) {
                specificAncestor = specificAncestor.parent;
                foundAncestor = true;
                break;
            }
            specificAncestor = specificAncestor.parent;
        }

        if (foundAncestor)
            return {vertical: [], horizontal: []};

        // we can snap to stage borders and the center of the stage
        const vertical: Array<number | number[]> = [padding, stage.width() / 2, stage.width() - padding];
        const horizontal: Array<number | number[]> = [padding, stage.height() / 2, stage.height() - padding];
        // we snap over edges and center of each object on the canvas
        for (const guideItem of stage.find((e: Konva.Node) => e.hasName(draggableSelector) && e.getClassName() === 'Rect')) {
            if (guideItem === skipShape)
                continue;

            const box = guideItem.getClientRect({skipShadow: true});
            // and we can snap to all edges of shapes
            vertical.push([box.x, box.x + box.width, box.x + box.width / 2]);
            horizontal.push([box.y, box.y + box.height, box.y + box.height / 2]);
        }
        return {
            vertical: vertical.flat(),
            horizontal: horizontal.flat(),
        };
    }, [draggableSelector, padding, stage])

    /**
     * Determines the points of the draggable rectangles that will trigger the snapping.
     *
     * - we will enable all the edges and the center of the node for snapping.
     * @param node
     */
    const getObjectSnappingEdges = useCallback((node: Konva.Node) => {
        const box = node.getClientRect({skipShadow: true});
        const absPos = node.absolutePosition();

        return {
            vertical: [
                {guide: Math.round(box.x), offset: Math.round(absPos.x - box.x), snap: 'start',},
                {guide: Math.round(box.x + box.width / 2), offset: Math.round(absPos.x - box.x - box.width / 2), snap: 'center',},
                {guide: Math.round(box.x + box.width), offset: Math.round(absPos.x - box.x - box.width), snap: 'end',},
            ],
            horizontal: [
                {guide: Math.round(box.y), offset: Math.round(absPos.y - box.y), snap: 'start',},
                {guide: Math.round(box.y + box.height / 2), offset: Math.round(absPos.y - box.y - box.height / 2), snap: 'center',},
                {guide: Math.round(box.y + box.height), offset: Math.round(absPos.y - box.y - box.height), snap: 'end',},
            ],
        };
    }, [])

    /**
     * Finds all snapping possibilities based on the provided line guide stops and draggable rectangle snapping edges.
     *
     * @param lineGuideStops
     * @param itemBounds
     */
    const getGuides = useCallback((lineGuideStops: ReturnType<typeof getLineGuideStops>, itemBounds: ReturnType<typeof getObjectSnappingEdges>) => {
        const resultV: Array<IInitialSnapGuide> = [];
        const resultH: Array<IInitialSnapGuide> = [];

        for (const lineGuide of lineGuideStops.vertical) {
            for (const itemBound of itemBounds.vertical) {
                const diff = Math.abs(lineGuide - itemBound.guide);
                // if the distance between guild line and object snap point is close we can consider this for snapping
                if (diff < GUIDELINE_OFFSET) {
                    resultV.push({
                        lineGuide: lineGuide,
                        diff: diff,
                        snap: itemBound.snap,
                        offset: itemBound.offset,
                    });
                }
            }
        }

        for (const lineGuide of lineGuideStops.horizontal) {
            for (const itemBound of itemBounds.horizontal) {
                const diff = Math.abs(lineGuide - itemBound.guide);
                // if the distance between guild line and object snap point is close we can consider this for snapping
                if (diff < GUIDELINE_OFFSET) {
                    resultH.push({
                        lineGuide: lineGuide,
                        diff: diff,
                        snap: itemBound.snap,
                        offset: itemBound.offset,
                    });
                }
            }
        }

        const guides: Array<ISnapGuide> = [];

        // find the closest snap
        const minV = resultV.sort((a, b) => a.diff - b.diff)[0];
        const minH = resultH.sort((a, b) => a.diff - b.diff)[0];
        if (minV) {
            guides.push({
                lineGuide: minV.lineGuide,
                offset: minV.offset,
                orientation: 'V',
                snap: minV.snap,
                key: Utils.createUUId(),
            });
        }
        if (minH) {
            guides.push({
                lineGuide: minH.lineGuide,
                offset: minH.offset,
                orientation: 'H',
                snap: minH.snap,
                key: Utils.createUUId(),
            });
        }
        return guides;
    }, [GUIDELINE_OFFSET])

    /**
     * Forces the position of the provided node based on the snap-guides that this layer is showing.
     */
    const forceNodePosition = useCallback((guides: Array<ISnapGuide>, node: Konva.Node) => {
        const absPos = node.absolutePosition();
        // now force object position
        for (const lg of guides) {
            switch (lg.snap) {
                case 'start': {
                    switch (lg.orientation) {
                        case 'V': {
                            absPos.x = lg.lineGuide + lg.offset;
                            break;
                        }
                        case 'H': {
                            absPos.y = lg.lineGuide + lg.offset;
                            break;
                        }
                    }
                    break;
                }
                case 'center': {
                    switch (lg.orientation) {
                        case 'V': {
                            absPos.x = lg.lineGuide + lg.offset;
                            break;
                        }
                        case 'H': {
                            absPos.y = lg.lineGuide + lg.offset;
                            break;
                        }
                    }
                    break;
                }
                case 'end': {
                    switch (lg.orientation) {
                        case 'V': {
                            absPos.x = lg.lineGuide + lg.offset;
                            break;
                        }
                        case 'H': {
                            absPos.y = lg.lineGuide + lg.offset;
                            break;
                        }
                    }
                    break;
                }
            }
        }
        node.absolutePosition(absPos);
    }, [])

    /**
     * clears the guidelines of this layer.
     */
    const removeGuides = useCallback(() => setGuides([]), []);

    /**
     * Creates the snap-guides of the layer and forces the node to position itself with the help of these guides.
     */
    const createSnapGuides = useCallback<(evt: Konva.KonvaEventObject<Event>, forcePosition?: boolean) => void>((e, forcePosition) => {
        if (e.target.getClassName() !== 'Rect')
            return;
        // find possible snapping lines
        const lineGuideStops = getLineGuideStops(e.target);
        // find snapping points of current object
        const itemBounds = getObjectSnappingEdges(e.target);
        // find where can we snap current object
        const guides = getGuides(lineGuideStops, itemBounds);
        setGuides(guides);
        // do nothing if no snapping
        if (!guides.length || !forcePosition)
            return;
        forceNodePosition(guides, e.target);
    }, [forceNodePosition, getGuides, getLineGuideStops, getObjectSnappingEdges]);

    /**
     * Creates the snap-guides of the layer and forces the node to position itself with the help of these guides.
     */
    const createSnapGuidesAndForceNodePosition = useCallback<(evt: Konva.KonvaEventObject<Event>) => void>((e) => {
        createSnapGuides(e, true);
    }, [createSnapGuides])

    /**
     * With each change in the children of this component:
     * - reattaches the transform listeners on each immediate stage item
     */
    useEffect(() => {
        if (!layerRef.current)
            return;
        const immediateRectChildren = stage.find((e: Konva.Node) => e.hasName(draggableSelector) && e.getClassName() === 'Rect');
        for (const guideItem of immediateRectChildren) {
            guideItem.on('transform', createSnapGuides)
            guideItem.on('transformend', removeGuides)
        }
        return () => {
            for (const guideItem of immediateRectChildren) {
                guideItem.off('transform', createSnapGuides)
                guideItem.off('transformend', removeGuides)
            }
        }
    }, [createSnapGuides, children, draggableSelector, stage, removeGuides]);

    const guideNodes = useMemo(() =>
        guides.map((lg) => {
            switch (lg.orientation) {
                default:
                    return null;
                case "H": {
                    return (
                        <Line
                            ref={ref => ref?.absolutePosition?.({x: 0, y: lg.lineGuide})}
                            key={lg.key}
                            points={[-stageSize.width, 0, stageSize.width, 0]}
                            stroke={'rgb(0, 161, 255)'}
                            strokeWidth={1}
                            dash={[4, 6]}
                        />
                    );
                }
                case "V":
                    return (
                        <Line
                            ref={ref => ref?.absolutePosition?.({y: 0, x: lg.lineGuide})}
                            key={lg.key}
                            points={[0, -stageSize.height, 0, stageSize.height]}
                            stroke={'rgb(0, 161, 255)'}
                            strokeWidth={1}
                            dash={[4, 6]}
                        />
                    );
            }
        }), [guides, stageSize.height, stageSize.width])

    return (
        <Layer
            {...props}
            ref={ref => layerRef.current = ref as Konva.Layer}
            onDragMove={createSnapGuidesAndForceNodePosition}
            onDragEnd={removeGuides}
        >
            {guideNodes}
            {children}
        </Layer>
    );
}

export default SnappyLayer;
