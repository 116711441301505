import {useTranslation} from "react-i18next";
import Cards from 'react-credit-cards';

const PaymentMethodsDefaultCard = ({card, loading}) => {
    const {t} = useTranslation();
    const translations = t('views.panel.paymentMethods.defaultCard', {returnObjects: true});

    return (
        <>
            {
                loading
                    ? (
                        <div className={'loading default-card'}>
                            <h2 className='title'>
                                {translations?.title ?? ''}
                            </h2>
                            <div/>
                        </div>
                    )
                    : (
                        <div className={'default-card'}>
                            <h2 className='title'>
                                {translations?.title ?? ''}
                            </h2>
                            <div className='py-4'>
                                <Cards
                                    preview
                                    number={"**** **** **** ****"}
                                    expiry={"**/**"}
                                    cvc={"***"}
                                    {...card}
                                    name={translations?.cardName ?? ""}
                                    focused={'name'}
                                    locale={{
                                        valid: translations?.validThru ?? ''
                                    }}
                                />
                            </div>
                        </div>
                    )
            }
        </>
    )
}

export default PaymentMethodsDefaultCard;
