import React from "react";
import useStyles from "./_styles";
import {primaryColor, secondaryColor} from "../../../../assets/mui/colors";
import {lighten, Tab as MuiTab, Tabs as MuiTabs} from '@material-ui/core'

export const Tabs = ({indicatorColor = secondaryColor, indicatorHeight = 3, ...props}) => {
    const classes = useStyles({
        indicatorColor: indicatorColor,
        indicatorHeight: indicatorHeight
    });

    return (
        <MuiTabs
            classes={{root: classes.tabs, indicator: classes.indicator}}
            {...props}
        />
    );
}

export const Tab = ({
                        hoverColor = primaryColor,
                        selectedColor = primaryColor,
                        selectedFontSize = 13,
                        fontSize = 13,
                        focusColor = primaryColor,
                        minWidth = 72,
                        color = lighten(primaryColor, 0.2),
                        ...props
                    }) => {
    const classes = useStyles({
        hoverColor,
        selectedFontSize,
        selectedColor,
        focusColor,
        fontSize,
        minWidth,
        color
    });

    return (
        <MuiTab
            classes={{selected: classes.selected, root: classes.tab}}
            disableRipple
            {...props}
        />
    );

}
