import React, {useContext} from "react";
import {PanelContext} from "../../../../contexts/panel";
import classnames from "classnames";
import {CSSTransition, SwitchTransition} from "react-transition-group";
import {ReactComponent as Logo} from "../../../../../assets/logos/logo.svg";
import {ReactComponent as LogoDescription} from "../../../../../assets/logos/logo-descriotion.svg";

const SidebarFooter = () => {
    const [{collapsed}] = useContext(PanelContext);

    return (
        <div className={classnames('sidebar-footer', {collapsed})}>
            <SwitchTransition mode={'out-in'}>
                <CSSTransition
                    key={!collapsed}
                    classNames="fade"
                    addEndListener={(node, done) => {
                        node.addEventListener("transitionend", done, false);
                    }}
                >
                    {
                        collapsed
                            ? <Logo className={'sidebar-logo'}/>
                            : <LogoDescription className={'sidebar-logo'}/>
                    }

                </CSSTransition>
            </SwitchTransition>
        </div>
    );
}

export default SidebarFooter;
