import React from "react";
import {ReactComponent as Minus} from "../../../../assets/images/minus.svg";
import {ReactComponent as Plus} from "../../../../assets/images/plus.svg";
import {getOsSpecs} from "../../../../core/services/utils";
import classnames from "classnames";


export const NumberInput = ({disabled, value, modifier = 1, onChange, ...props}) => {
    const {isMac} = getOsSpecs();

    /**
     * Changes the width of the input as soon as the user types into the input.
     * @param {KeyboardEvent} e
     */
    const changeWidthOfInput = (e,) => {
        e.target.parentNode.dataset.value = e.target.value;
    }

    /**
     * Changes the value of the input as soon as one of the helper buttons are clicked.
     *
     * based on the held keyboard key, if any, the added value will get a multiplier.
     * @param {MouseEvent} e
     * @param {1 | -1} sign determines the sign of the modifier value
     */
    const changeValue = (e, sign) => {
        let multiplier = 1;
        if (e.altKey) {
            multiplier = 0.1;
        }
        if (e.shiftKey) {
            multiplier = 10;
        }
        if ((isMac && e.metaKey) || (!isMac && e.ctrlKey)) {
            multiplier = 100;
        }
        if (onChange) {
            onChange(value + (modifier * sign * multiplier))
        }
    }

    /**
     * Sets the new value by changing the value in the form.
     * @param {Event} e
     */
    const onValueChanged = (e) => {
        const value = Number(e.target.value)
        if (onChange) {
            onChange(value)
        }
    }

    return (
        <>
            <div className={'numbers-container'}>
                <button
                    disabled={disabled}
                    className={'button text'}
                    onClick={(e) => changeValue(e, -1)}
                >
                    <Minus/>
                </button>
                <label className={'input-sizer'}>
                    <input
                        {...props}
                        value={value ?? ''}
                        onChange={onValueChanged}
                        disabled={disabled}
                        onInput={changeWidthOfInput}
                        type={'number'}
                    />
                </label>
                <button
                    disabled={disabled}
                    className={'button text'}
                    onClick={(e) => changeValue(e, 1)}
                >
                    <Plus/>
                </button>
            </div>
        </>
    )
}

export const NumberFormInput = ({
                                    disabled,
                                    modifier = 1,
                                    labelClassName,
                                    name,
                                    form,
                                    value,
                                    ...props
                                }) => {
    const {isMac} = getOsSpecs();

    /**
     * Changes the width of the input as soon as the user types into the input.
     * @param {KeyboardEvent} e
     */
    const changeWidthOfInput = (e,) => {
        e.target.parentNode.dataset.value = e.target.value;
    }

    /**
     * Changes the value of the input as soon as one of the helper buttons are clicked.
     *
     * based on the held keyboard key, if any, the added value will get a multiplier.
     * @param {MouseEvent} e
     * @param {1 | -1} sign determines the sign of the modifier value
     */
    const changeValue = (e, sign) => {
        let multiplier = 1;
        if (e.altKey) {
            multiplier = 0.1;
        }
        if (e.shiftKey) {
            multiplier = 10;
        }
        if ((isMac && e.metaKey) || (!isMac && e.ctrlKey)) {
            multiplier = 100;
        }
        if (form) {
            const values = form.getState()?.values ?? {};
            const value = Number(values[name])
            form.change(name, value + (modifier * sign * multiplier))
        }
    }

    /**
     * Sets the new value by changing the value in the form.
     * @param {Event} e
     */
    const onValueChanged = (e) => {
        const value = Number(e.target.value)
        form.change(name, value)
    }

    return (
        <>
            <div className={'numbers-container'}>
                <button
                    disabled={disabled}
                    className={'button text'}
                    onClick={(e) => changeValue(e, -1)}
                >
                    <Minus/>
                </button>
                <label className={classnames('input-sizer', labelClassName)}>
                    <input
                        {...props}
                        name={name}
                        value={value ?? ''}
                        onChange={onValueChanged}
                        disabled={disabled}
                        onInput={changeWidthOfInput}
                        type={'number'}
                    />
                </label>
                <button
                    disabled={disabled}
                    className={'button text'}
                    onClick={(e) => changeValue(e, 1)}
                >
                    <Plus/>
                </button>
            </div>
        </>
    );
}
