import {createTheme} from "@material-ui/core";
import {
    disabledTextColor,
    green,
    hintTextColor, lighterPrimaryColor,
    lighterSecondaryColor, lightPrimaryColor,
    lightSecondaryColor,
    primaryColor,
    primaryColorAlternate,
    primaryTextColor,
    red,
    secondaryColor,
    secondaryTextColor,
} from "./colors";


const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200
        },
    },
    palette: {
        primary: {
            main: primaryColor,
        },
        secondary: {
            main: secondaryColor,
        },
        success: {
            main: green,
        },
        error: {
            main: red,
        },
        text: {
            primary: primaryTextColor,
            secondary: secondaryTextColor,
            disabled: disabledTextColor,
            hint: hintTextColor,
        }
    },
    typography: {
        fontFamily: 'Montserrat',
        fontSize: 13,
    },
    shape: {
        borderRadius: 7,
    },
    overrides: {
        MuiInputBase: {
            root: {
                fontSize: '0.8rem',
                '&.Mui-disabled': {
                    cursor: 'not-allowed',
                },
            },
        },
        MuiInput: {
            underline: {
                // '&:hover':{
                //     color: primaryColorAlternate,
                // },
                '&:before': {
                    borderBottomColor: `${primaryColorAlternate} !important`,
                },
                '&:after': {
                    borderBottomColor: `${primaryColorAlternate} !important`,
                },
                '&.Mui-error': {
                    '&:after':{
                        borderBottomColor: `${red} !important`,
                    }
                }

            }
        },
        MuiOutlinedInput: {
            notchedOutline: {
                borderColor: primaryColorAlternate,
                '&:hover': {
                    borderColor: primaryColor,
                }
            },
            multiline: {
                paddingLeft: '10px',
                paddingTop: '10px'
            }
        },
        MuiFormControl: {
            root: {
                '&.Mui-disabled': {
                    cursor: 'not-allowed',
                },
            },
        },
        MuiSelect: {
            root: {
                padding: '16px 16px'
            },
            select: {
                '&.Mui-disabled': {
                    cursor: 'not-allowed',
                    color: disabledTextColor,
                },
                '&:focus': {
                    backgroundColor: 'transparent',
                },
            },
            outlined: {
                paddingRight: '15px',
                paddingLeft: 15,
            }
        },
        MuiButton: {
            root: {
                padding: '0.5rem',
                border: 'none',
                outline: 'none',
            },
        },
        MuiPopover: {
            paper: {
                elevation: 4,
            },
            root: {
                elevation: 4,
            }
        },
        MuiIconButton: {
            root: {
                padding: 5,
            },

        },
        MuiMenu: {
            paper: {
                marginTop: 5,
                borderRadius: 3,
            }
        },
        MuiMenuItem: {
            root: {
                transition: 'all 0.2s ease-in-out',
                color: "black",
                margin: '5px 5px',
                borderRadius: 3,
                paddingBottom: 11,
                paddingTop: 11,
                backgroundColor: 'white',
                '&:hover': {
                    backgroundColor: lighterSecondaryColor,
                },
                "&$selected": {
                    transition: 'all 0.2s ease-in-out',
                    color: secondaryColor,
                    backgroundColor: lighterSecondaryColor,
                    '&:hover': {
                        backgroundColor: lighterSecondaryColor,
                    },
                }
            },
        },
        MuiFormHelperText: {
            root: {
                '&$error': {
                    fontWeight: 500,
                    fontSize: '12px !important',
                    margin: '8px 0',
                }
            }
        },
        MuiTooltip: {
            tooltip: {
                borderRadius: 5,
                padding: 10,
                fontWeight: 600,
                fontSize: 12,
            }
        },
        MuiAutocomplete: {
            option: {
                margin: '10px',
                borderRadius: 7,
                '&$Mui-select': {
                    backgroundColor: lightSecondaryColor,
                },
                '&:hover': {
                    backgroundColor: lightSecondaryColor,
                },
            }
        },
    },

});


export default theme;
