import {createContext} from "react";
import AnimationService from "../../../core/services/animation-service";

/**
 * The initial Values used for the animation service context.
 * @type {AnimationService}
 */
export const AnimationServiceContextInitialValues = new AnimationService();

/**
 * Context used to store a reference of the animation service.
 */
export const AnimationServiceContext = createContext(AnimationServiceContextInitialValues);
