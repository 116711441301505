//    ***** CSS IMPORTS *******
import './assets/css/index.scss';
import './assets/css/responsive.scss';
import "react-toastify/dist/ReactToastify.css";
import 'aos/dist/aos.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-credit-cards/lib/styles.scss';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
//    ***** Other IMPORTS *******
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import reportWebVitals from './reportWebVitals';
import setUpJQueryExtensions from "./core/services/jquery_extensions";
import setUpExtensions from "./core/services/extensions";
import './i18n.config';

ReactDOM.render(
    <App/>,
    document.getElementById('root')
);
setUpJQueryExtensions();
setUpExtensions();
reportWebVitals();

