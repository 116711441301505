import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import LocalStorageService from './local_storage_service'
import Texts from "../constants/texts/api-response-messages";
import ApiRequestDs from "../models/requests/api_request.ds";
import CrudDs from "../models/response/crud.ds";
import {History, Location} from 'history';
import CacheService from "./cache_service";
import {ApiHeaderNames} from "../constants/enums";
import {CrudResponse} from "../models/response/crud-response";
import routes from "../../ui/routes";
import {matchPath} from "react-router";
import ToastService from "./toast-service";
import {screenshotUserBrowserWindow} from "./utils";
// @ts-ignore
import {CurlHelper} from 'axios-curlirize/src/lib/CurlHelper.js';
import {ApiErrorCodes} from "../constants/api-response-codes";


/**
 * Makes an api request using the provided request data and returns a proper result based on the result of the api
 * response.
 * @param apiRequestDs  the request of the api
 */
const apiCallback = async <T>(apiRequestDs: ApiRequestDs): CrudResponse<T> => {
    const {
        url = '',
        method = 'GET',
        body = null,
        history = null,
        location = null,
        headers = {},
        showErrorToast = true,
        showSuccessToast = false,
        redirectToError = false,
        onDownloadProgress = undefined,
        onUploadProgress = undefined,
        loginRequired = true,
        externalResponse = false,
    } = apiRequestDs;

    // Checks to see if the user is logged in internally, and if not, logs them out
    const isLoggedIn = CacheService.isLoggedIn();
    if (!isLoggedIn && loginRequired) {
        unAuthorizedResponse(location, history);
        return;
    }
    const allHeaders = await _getHeaders(headers);

    const request = {
        url,
        method,
        onDownloadProgress,
        onUploadProgress,
        data: body,
        headers: allHeaders,
    }
    try {
        const response: AxiosResponse<CrudDs<T>> = await axios.request<any, AxiosResponse<CrudDs<T>>>(request);
        return checkResData<T>(request, response, history, location, showSuccessToast, showErrorToast, redirectToError, externalResponse);
    } catch (e) {
        // if the api call crashes itself
        const error = Error(`Api Error Internally : ${e}`);
        console.error(error)
        return checkResData<T>(request, undefined, history, location, showSuccessToast, showErrorToast, redirectToError, externalResponse);
    }
};

/**
 * Gets the headers required for the api call
 * @param headers the extra headers
 */
const _getHeaders = async (headers: any) => {
    return {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
        [ApiHeaderNames.authorization]: LocalStorageService.get(LocalStorageService.keys.token),
        [ApiHeaderNames.companyId]: CacheService.getCompanyId(),
        ...headers,
    };
}

/**
 * Checks the response data form the api. if the status if not 200 or the errorCode of the response object is not
 * 200, then logs the error, sends data to sentry and returns the erroneous response.
 *
 * @param request the axios request config
 * @param response              the axios response
 * @param history               the react-router-dom history object
 * @param location              the react-router-dom location object
 * @param showSuccess           whether show success toast
 * @param showError             whether show error toast
 * @param redirectToError       whether redirect to the an error page upon failed state
 * @param externalResponse      whether the response of the api is not of type CrudResponse
 */
const checkResData = async <T>(request: AxiosRequestConfig,
                               response: AxiosResponse<CrudDs<T>> | undefined,
                               history?: History | null,
                               location?: Location | null,
                               showSuccess: boolean = false,
                               showError: boolean = false,
                               redirectToError: boolean = false,
                               externalResponse: boolean = false,
): Promise<CrudDs<T | null> | undefined> => {
    const showToasts = await createBoundedToasts(request, response, location, showError);

    // Catch axios api call error
    if (!response) {
        if (showError) {
            showToasts.error({
                title: Texts.serverErrorTitle,
                message: Texts.serverErrorMessage,
            });
        }
        if (redirectToError && history && location) {
            navigateToServerErrorPage(history, location);
        }
        return undefined;
    }
    // successful result
    if (response.status.toString().startsWith('2') && !response.data.errorCode) {
        if (showSuccess) {
            // const successMessage = `${response.data?.headerTitle ?? Texts.serverSuccessTitle} : ${response.data?.message ?? Texts.serverSuccessMessage}`;
            // toast.success(successMessage, {type: 'success'});
            showToasts.success({
                title: response.data?.headerTitle ?? Texts.serverSuccessTitle,
                message: response.data?.message ?? Texts.serverSuccessMessage,
            })
        }
        if (externalResponse) {
            return createExternalCrudDs(response);
        }
        return response.data;// successful result
    }
    // error cases
    else {
        switch (response.status) {
            // unAuthorized request
            case 401:
                unAuthorizedResponse(location, history);
                break;
            // access denied
            case 403:
                if (redirectToError && history && location) {
                    navigateToAccessDeniedPage(history, location)
                }
                break;
            case 200:
                // just return the response since it is of an external type
                if (externalResponse) {
                    return createExternalCrudDs(response);
                }
                switch (response.data.errorCode) {
                    // unAuthorized request internally
                    case ApiErrorCodes.unAuthorized:
                        unAuthorizedResponse(location, history);
                        break;
                    // access denied internally
                    case 403:
                        if (redirectToError && history && location) {
                            navigateToAccessDeniedPage(history, location)
                        }
                        break;
                    default:
                        const error = Error(`Api Result Failed with ${(response?.status ?? response?.data?.errorCode ?? -100)} and response of ${response?.data}`);
                        console.error(error);
                        if (redirectToError && history && location) {
                            navigateToServerErrorPage(history, location);
                        }
                        break;
                }
                break;
            default:
                break;
        }
        if (showError) {
            // const errorMessage = `${response.data?.headerTitle ?? Texts.serverErrorTitle} : ${response.data?.message ?? Texts.serverErrorMessage}`;
            // toast.error(errorMessage, {type: 'error'});
            showToasts.error({
                title: response.data?.headerTitle ?? Texts.serverErrorTitle,
                message: response.data?.message ?? Texts.serverErrorMessage,
                hideTicketSubmission: (response?.status === 401 || response?.data?.errorCode === 401),
            });
        }
        return {
            resultFlag: response.data?.resultFlag ?? false,
            data: response.data?.data ?? null,
            message: response.data?.message ?? Texts.serverErrorMessage,
            headerTitle: response.data?.headerTitle ?? Texts.serverErrorTitle,
            errorCode: response.data.errorCode ?? response.status,
            status: response.status,
        };
    }
}

/**
 * Creates an external CrudDs for the api responses that are not of type CrudDs.
 * @param  {AxiosResponse} response
 */
const createExternalCrudDs = (response: AxiosResponse): CrudDs<any> => {
    return {
        resultFlag: true,
        data: response.data,
        message: null,
        headerTitle: null,
        errorCode: null,
        status: response.status,
    }
}

/**
 * Navigates the user to the serverErrorForSection page if the user is not already in that page.
 * @param history
 * @param location
 */
const navigateToServerErrorPage = (history: History, location: Location) => {
    if (location.pathname.includes(routes.error.serverErrorForSection)) {
        return;
    }
    if (matchPath(location.pathname, {path: routes.error.serverError, exact: true})) {
        return;
    }
    if (location.pathname === '/') {
        history.push(routes.error.serverErrorForSection)
        return;
    }
    history.push(`${location.pathname}${routes.error.serverErrorForSection}`)
}

/**
 * Navigates the user to the accessDeniedForSection page if the user is not already in that page.
 * @param history
 * @param location
 */
const navigateToAccessDeniedPage = (history: History, location: Location) => {
    if (location.pathname.includes(routes.error.accessDeniedForSection)) {
        return;
    }
    if (matchPath(location.pathname, {path: routes.error.accessDenied, exact: true})) {
        return;
    }
    if (location.pathname === '/') {
        history.push(routes.error.accessDeniedForSection)
        return;
    }
    history.replace(`${location.pathname}${routes.error.accessDeniedForSection}`)
}

/**
 * Removes the login information from the localstorage so that the user can be navigated to the login view
 * @param location
 * @param history
 */
const unAuthorizedResponse = (location?: Location | null, history?: History | null) => {
    CacheService.removeUserInformation();
}

/**
 * Determines whether the given response is erroneous.
 * @param response
 */
const responseErroneous = (response: AxiosResponse | undefined) => {
    return !response ||
        response.status.toString().startsWith('4') ||
        response.status.toString().startsWith('5') ||
        !response.data?.resultFlag
}

/**
 * Generates toasts that are bounded to the given arguments.
 * @param request
 * @param response
 * @param location
 * @param showError
 */
const createBoundedToasts = async (
    request: AxiosRequestConfig,
    response: AxiosResponse<CrudDs<any>> | undefined,
    location?: Location | null,
    showError?: boolean,
) => {
    let capturedScreen: string | null;
    if (showError && responseErroneous(response)) {
        capturedScreen = await screenshotUserBrowserWindow();
    }
    return ({
        success: (props: any) => ToastService.success({
            ...(props ?? {}),
            props: {
                ...(props?.props ?? {}),
                data: {
                    ...(props?.props?.data ?? {}),
                    curl: generateCurl(request, response),
                    location: location,
                    apiResponse: response?.data,
                    apiRequest: request,
                },
            },
        }),
        error: (props: any) => ToastService.error({
            hideTicketSubmission: false,
            ...(props ?? {}),
            props: {
                ...(props?.props ?? {}),
                data: {
                    ...(props?.props?.data ?? {}),
                    curl: generateCurl(request, response),
                    location: location,
                    capturedScreen: capturedScreen,
                    apiResponse: response?.data,
                    apiRequest: request,
                },
            },
        }),
    })
}

/**
 * Generates a C-URL from the response or request of the Axios.
 *
 * @param request
 * @param response
 */
const generateCurl = (request: AxiosRequestConfig, response: AxiosResponse | undefined): string | undefined => {
    if (!response) {
        return new CurlHelper(request).generateCommand();
    }
    const config: AxiosRequestConfig & {
        curlCommand: string,
    } = response.config as any;
    return config.curlCommand;
}

export default apiCallback;
