import EnvService from "../../../services/env-service";

/**
 * This interface houses all the available endpoints of this application specific for the 'biz-print' server.
 */
class InventorySchemaVisualizerApiEndpoints {

    /**
     * The base url of the api endpoints of this application.
     * @private
     */
    private static baseUrl: string = EnvService.ApiUrl;

    /**
     * The controllers of the api endpoints.
     * @private
     */
    private static controllers = {
        row: this.baseUrl.concat('Row'),
        warehouse: this.baseUrl.concat('Warehouse'),
        location: this.baseUrl.concat("Location"),
        inventorySchema: this.baseUrl.concat("InventorySchema"),
    }

    /**
     * The endpoints specific for the warehouse controller of the server api.
     */
    static warehouse = {
        getAll: this.controllers.warehouse.concat('/list'),
        update: this.controllers.warehouse,
        getAllEntries: (warehouseId: number) => this.controllers.warehouse.concat('/GetElements').concat(`/${warehouseId}`),
    }

    /**
     * The endpoints specific for the row controller of the server api.
     */
    static row = {
        getRowItems: (rowId: number) => this.controllers.row.concat('/LoadInfo').concat(`/${rowId}`),
    }

    /**
     * The endpoints specific for the location controller of the server api.
     */
    static location = {
        baseUrl: this.controllers.location,
        changeSerializedItemsLocation: this.controllers.location.concat('/ChangeMultiPosition'),
        changeUnSerializedItemsLocation: this.controllers.location.concat('/ChangeUnserializedLocation'),
    }

    /**
     * The endpoints specific for the inventory-schema controller of the server api.
     */
    static inventorySchema = {
        getSavedState: (warehouseId: number) => this.controllers.inventorySchema.concat('/getSavedState').concat(`/${warehouseId}`),
        saveState: this.controllers.inventorySchema.concat('/saveState'),
    }
}


export default InventorySchemaVisualizerApiEndpoints;
