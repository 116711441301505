import React from "react";

const NoEntries = ({
                       text = 'No search results found!',
                       includeButton = false,
                       buttonText = 'clear filters',
                       buttonProps = {},
                   }) => {
    return (
        <>
            <div className={'no-entries'}>
                <p>
                    {text ?? ''}
                </p>
                {includeButton &&
                <button {...buttonProps}>
                    {buttonText ?? ""}
                </button>
                }
            </div>

        </>
    );
}

export default NoEntries;
