import {RegistrationStepperSteps} from "../../../core/constants/enums";
import {numComparator} from "../../../core/services/utils";

/**
 * The Action types for the registration reducer
 */
export const RegistrationReducerActions = {
    nextStep: 'NEXT_STEP',
    prevStep: 'PREV_STEP',
    jumpToStep: 'JUMP_TO_STEP',
    submitStep: 'SUBMIT_STEP',
};


/**
 * The reducer for registration process.
 * Manages the logic for the Registration process
 * @param {RegistrationState} state
 * @param {{type: string, payload: *}} action
 */
const RegistrationReducer = (state, action) => {
    const indexes = Object.values(RegistrationStepperSteps).sort(numComparator);
    const lastIndex = indexes[indexes.length - 1];
    const firstIndex = indexes[0];
    switch (action.type) {
        case RegistrationReducerActions.nextStep:
            let currentStep = state.activeStep;
            if (currentStep === lastIndex) {
                console.error('You should not exceed the last step of the process');
                return;
            }
            return {...state, activeStep: Math.min(currentStep + 1, lastIndex)};

        case RegistrationReducerActions.prevStep:
            let _currentStep = state.activeStep;
            if (_currentStep === firstIndex) {
                console.error('You should not go before the first step of the process');
                return;
            }
            return {...state, activeStep: Math.max(_currentStep - 1, firstIndex)};

        case RegistrationReducerActions.jumpToStep:
            const stepToJump = action.payload;
            if (stepToJump > lastIndex || stepToJump < firstIndex) {
                console.error('You should not go before the first step or after the last step of the process');
                return;
            }
            return {...state, activeStep: Math.min(lastIndex, Math.max(stepToJump, firstIndex))};

        case RegistrationReducerActions.submitStep:
            const {submitted, valid, stepName, values} = action.payload;
            return {...state, [stepName]: {...state[stepName], ...values, submitted, valid}};

        default:
            return state;
    }
}

export default RegistrationReducer;
