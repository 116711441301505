import React, {useEffect, useState} from "react";
import * as Yup from 'yup';
import ValidateMessages from "../../../../core/constants/texts/vallidate-messages";
import {useTranslation} from "react-i18next";
import {makeRequired, makeValidate} from "mui-rff";
import Dialog from "../../base/dialog";
import {DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import Form from "../../base/form";
import {FormInput} from "../../base/input";

// Form name key
const formName = {
    note: 'note'
}

// Schema for declaring the validation logic of the form
const schema = Yup.object().shape({
    [formName.note]: Yup.string().trim().nullable().required(ValidateMessages.required)
});

const AddOrEditNoteDialog = ({setOpen, open, initData, onSubmit}) => {
    const [initialValue, setInitialValue] = useState(
        {
            titleCode: initData?.partNumber ?? initData.serialNumber ?? '',
            note: initData?.note ?? ''
        }
    );
    const {t} = useTranslation();
    const validate = makeValidate(schema);
    const required = makeRequired(schema);
    const translation = t('dialogs.counting.shared.addOrEditNoteDialog', {
            returnObjects: true,
            code: initialValue?.titleCode
        });

    /**
     * With each change in the [initDate] in the props, renders the state data
     */
    useEffect(() => {
        setInitialValue(
            {
                titleCode: initData?.partNumber ?? initData.serialNumber ?? '',
                note: initData?.note ?? ''
            }
        );
    }, [initData]);

    /**
     * Renders the label for submit action button of the dialog based on the props and submitting
     * @param {boolean} submitting
     * @return {string}
     */
    const renderButtonLabel = (submitting) => {
        if (submitting) {
            if (initData?.note) return translation?.dialogActions?.editing;
            return translation?.dialogActions?.creating;
        }
        if (initData?.note) return translation?.dialogActions?.edit;
        return translation?.dialogActions?.create;
    }

    return (
        <>
            <Dialog
                max={'md'}
                fullWidth
                setOpen={setOpen}
                open={open}
            >
                <DialogTitle className={'title'}>
                    <div>
                        <h2>{translation.title}</h2>
                    </div>
                </DialogTitle>
                <Form
                    onSubmit={onSubmit}
                    validate={validate}
                    initialValues={initialValue}
                    render={({submitting, values, form}) => {
                        return (
                            <>
                                <DialogContent>
                                    <FormInput
                                        placeholder={translation?.placeHolder}
                                        multiline
                                        rows={5}
                                        variant={'outlined'}
                                        name={formName.note}
                                        required={required[formName.note]}
                                        disabled={submitting}
                                        value={values[formName.note]}
                                        onChange={(e) => form.change(e.target.name, e.target.value)}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <button
                                        className='button primary outlined px-4'
                                        type='button'
                                        onClick={() => setOpen(false)}
                                        disabled={submitting}
                                    >
                                        {translation?.dialogActions?.cancel ?? ''}
                                    </button>
                                    <button
                                        className='button primary px-5'
                                        disabled={submitting || !open || values[formName.note] === initialValue?.note || values[formName.note]?.trim()?.length === 0}
                                        type='submit'>
                                        {renderButtonLabel(submitting)}
                                    </button>
                                </DialogActions>
                            </>
                        );
                    }}
                />
            </Dialog>
        </>
    );
}

export default AddOrEditNoteDialog;
