/**
 * Different values of api call methods.
 */
export enum ApiMethods {
    get = 'GET',
    post = 'POST',
    put = 'PUT',
    delete = 'DELETE',
    patch = 'PATCH',
}

/**
 * Modes of the visualizer for inventory-schema feature.
 */
export enum InventorySchemaVisualizerModes {
    view = 'view',
    edit = 'edit',
}

/**
 * Depth levels of the visualizer for inventory-schema feature.
 */
export enum InventorySchemaVisualizerDepths {
    aisles = 'Aisles',
    sections = 'Sections',
    rows = 'Rows',
}
