import {useTranslation} from "react-i18next";
import React, {useRef, useState} from "react";
import classnames from "classnames";
import {ReactComponent as Arrow} from "../../../../assets/images/dropdown-icon.svg";
import Pagination from "../../../components/base/pagination";
import {Popover} from "@material-ui/core";

/**
 *
 * @param {PaginationInfo}                  paginationInfo
 * @param {function(PaginationInfo, int)}   onPageSizeChange
 * @param {function(PaginationInfo, int)}   onCurrentPageChange
 * @param {number[]}                        pageSizes
 * @return {JSX.Element}
 * @constructor
 */
const TablePagination = ({
                             paginationInfo,
                             onPageSizeChange,
                             onCurrentPageChange: onCurrentPageChangeProp,
                             pageSizes
                         }) => {
    const {t} = useTranslation();
    /**@type {React.MutableRefObject<HTMLElement>}*/
    const layoutRef = useRef();
    const [popover, setPopover] = useState();

    /**
     * Calls the onPageSizeChange callback with the current paginationInfo and the selected size as its arguments.
     * @param {int} size
     */
    const onSizeClicked = (size) => {
        setPopover(null);
        if (size === paginationInfo.pageSize) return;
        if (!onPageSizeChange) return;
        onPageSizeChange(paginationInfo, size);
    }

    /**
     * Calls the onCurrentPageChangeProp callback with the current paginationInfo and the selected page as its
     * arguments.
     * @param {int} newPage
     */
    const onCurrentPageChange = (newPage) => {
        if (!onCurrentPageChangeProp) return;
        onCurrentPageChangeProp(paginationInfo, newPage);
    }

    /**
     * Creates the string representation of the page count with translation.
     * @return {string}
     */
    const renderPageCount = () => {
        const totalCount = paginationInfo.length ?? 0;
        const start = (paginationInfo.currentPage - 1) * paginationInfo.pageSize + 1;
        const end = Math.min(paginationInfo.currentPage * paginationInfo.pageSize, totalCount);
        return t('components.table.pagination.pageCount', {start: start, end: end, total: totalCount});
    }

    return (
        <>
            <div className={'pagination-layout'} ref={layoutRef}>
                <div className={'pagination-inner'}>
                    <div className={classnames('rows-per-page', {'active': !!popover})}>
                        <p>
                            {t('components.table.pagination.rowsPerPage')}
                        </p>
                        <div className={'dropdown-container'}
                             onClick={(e) => setPopover(e.currentTarget)}>
                            <p>
                                {paginationInfo.pageSize}
                            </p>
                            <Arrow/>
                        </div>
                    </div>
                    <div>
                        <p className={'page-count'}>
                            {renderPageCount()}
                        </p>
                    </div>
                    {
                        Math.floor(paginationInfo.length / paginationInfo.pageSize) > 1 &&
                        <div className={'pagination-container'}>
                            <Pagination
                                paginationInfo={paginationInfo}
                                onPageChange={onCurrentPageChange}
                            />
                        </div>
                    }
                </div>
            </div>
            <Popover
                id={!!popover ? 'page-sizes' : undefined}
                classes={{paper: 'page-sizes-popover'}}
                open={!!popover}
                onClose={() => setPopover(null)}
                anchorReference={'anchorEl'}
                anchorEl={popover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className={'content'}>
                    <div className={'d-flex flex-wrap mt-2'}>
                        {
                            pageSizes?.map(size => (
                                <div
                                    key={size}
                                    className={classnames('page-size',
                                        {'active': size === paginationInfo.pageSize})}
                                    onClick={() => onSizeClicked(size)}>
                                    {size ?? '--'}
                                </div>
                            ))
                        }
                    </div>
                </div>
            </Popover>
        </>
    );
}

export default TablePagination;
