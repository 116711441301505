import React, {useLayoutEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import classNames from "classnames";


const HowToSetupCard = ({step, textFirst = true,}) => {
    const [[blackTitle, blueTitle], setTitle] = useState(['', '']);

    /**
     * Listens for the changes in the title of the step and with each change:
     * Splits the title into black and blue sections
     */
    useLayoutEffect(() => {
        splitTitle(step.title)
    }, [step.title])

    /**
     * Splits the title to black and blue sections
     * The last word of the title is blue, the rest is black
     */
    const splitTitle = () => {
        const words = step.title?.split(' ') ?? [];
        if (!words?.length) {
            return;
        }
        if (words.length === 1) {
            setTitle(['', words[0]]);
        }
        setTitle([
            words.filter((_, index) => index !== words.length - 1).join(' '),
            words[words.length - 1],
        ]);
    }


    return (
        <>
            <Row data-aos={step.animationType} className={'landing-view-step'}>
                <Col xs={12} md={6}
                     className={classNames('mt-5 mt-md-3 order-1', {
                         'order-md-0': textFirst,
                         'order-md-1': !textFirst
                     })}>
                    <div
                        className='d-flex justify-content-center align-items-center align-items-md-start flex-column text-break'>
                        <h4 className='title text-center text-md-start'>
                            {blackTitle}
                            <span className='primary-color mx-1'>
                                {blueTitle}
                            </span>
                        </h4>
                        <p className='mt-1 body text-center text-md-start'>
                            {
                                step.body?.filter(e => e?.length)?.map((text, index) => (
                                    <span key={index}>
                                        {text}
                                        <br/>
                                    </span>
                                ))
                            }
                        </p>
                    </div>
                </Col>
                <Col xs={12} md={6}
                     className={classNames('mt-3 order-0', {'order-md-1': textFirst, 'order-md-0': !textFirst})}>
                    {step.icon}
                </Col>
            </Row>

        </>
    )
}

export default HowToSetupCard;
