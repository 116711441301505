import React, {useState} from "react";
import TableCell from "../cell";
import classnames from "classnames";
import {Collapse} from "react-bootstrap";
import TableSpacer from "../spacer";
import {TableCellTypes} from "../../../../core/constants/enums";

/**
 * @param {TableCellData[]}                     cells
 * @param {TableData}                           data
 * @param {boolean}                             last
 */
const TableRow = ({cells, data, last}) => {
    const [childOpen, setChildOpen] = useState(false);

    /**
     * Renders a single cell of a table row.
     * @param {TableCellData} cell
     * @return {JSX.Element}
     */
    const renderCell = (cell) => {
        let cellElement = <></>;
        if (!cell || !data) {
            return cellElement;
        }
        const cellData = data[cell.name];
        if (cellData == null) {
            return cellElement;
        }
        if (typeof cellData === 'function') {
            const result = cellData(childOpen, setChildOpen, cell.format);
            return result ?? cellElement;
        }
        cellElement = cellData;
        if (cell.format) {
            cellElement = cell.format(cellData);
        }
        if ([
            TableCellTypes.date,
            TableCellTypes.string,
            TableCellTypes.money,
            TableCellTypes.dateTime]
            .includes(cell.type)) {
            return (
                <p>
                    {cellElement}
                </p>
            );
        }
        return (
            <>
                {cellElement}
            </>
        );
    }

    return (
        <>
            <tr className={classnames({
                'special-row': !!data?.isSpecialRow
            })}>
                {
                    cells
                        ?.filter(cell => !cell.last)
                        ?.map((cell, index) => (
                            <TableCell
                                key={cell.name}
                                className={classnames('row-cell')}
                                renderCell={renderCell}
                                cell={cell}
                                elementType={index === 0 ? 'th' : 'td'}
                            />
                        ))
                }
                <th className={'last-cell'}>
                    <div className={'inner-last-cell'}>
                        {
                            renderCell(cells?.find(last => last.last))
                        }
                    </div>
                </th>
            </tr>
            {
                !last &&
                <TableSpacer length={cells.length}/>
            }
            {/*Added on March 29th, 2022 regarding the expandability of table rows*/}
            {data?.expandedSection && data?.expandedSection()}
            {
                data?.child &&
                <>
                    <tr>
                        <td>
                            <Collapse in={childOpen}>
                                <div className={'table-child'}>
                                    {typeof data?.child === 'function' ? data.child(childOpen, setChildOpen) : data.child}
                                </div>
                            </Collapse>
                        </td>
                    </tr>
                    {
                        childOpen &&
                        <TableSpacer length={cells.length}/>
                    }
                </>
            }
        </>
    );
}

export default TableRow;
