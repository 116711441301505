import React from "react";
import ReactPaginate from "react-paginate";
import {ReactComponent as BackIcon} from "../../../../assets/images/pagination-back-arrow.svg";
import {ReactComponent as NextIcon} from "../../../../assets/images/pagination-next-arrow.svg";
import {ReactComponent as BreakIcon} from "../../../../assets/images/pagination-break.svg";

const Pagination = ({paginationInfo, onPageChange: onPageChangeProp}) => {

    /**
     * Calls the onPageChange Callback function with the newly selected page
     * If the selected is null does nothing (the first render under componentDidMount)
     * @param e
     */
    const onPageChange = (e) => {
        if (e.selected === null) return;
        if ((e.selected + 1) === paginationInfo.currentPage) return;
        if (onPageChangeProp) onPageChangeProp(e.selected);
    }

    return (
        <ReactPaginate
            forcePage={paginationInfo.currentPage - 1}
            pageCount={Math.ceil(paginationInfo.length / paginationInfo.pageSize)}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            onPageChange={onPageChange}
            previousLabel={<BackIcon/>}
            nextLabel={<NextIcon/>}
            breakLabel={<BreakIcon/>}
            containerClassName='pagination'
            breakClassName='break-point'
            pageClassName='page-item'
            pageLinkClassName='page-link'
            previousClassName='prev'
            previousLinkClassName='page-link'
            nextClassName='next'
            nextLinkClassName='page-link'
            activeClassName='active'
        />
    );
}

export default Pagination;
