import {matchPath, Route, Switch} from "react-router";
import routes, {routesLists} from "../../routes";
import NotFound from "./not_found";
import AccessDenied from "./access_denied";
import ServerError from "./server_error";
import React, {useLayoutEffect, useState} from "react";
import {Container, Row} from "react-bootstrap";
import useRouter from "../../hooks/use-router";


const ErrorViews = () => {
    const {location} = useRouter();
    const [isRoot, setIsRoot] = useState(false);

    /**
     * Listens for the changes in the pathname of the url and with each change:
     *
     * * Determines whether the current pathname is a root error and if so, places the pages inside an appropriate
     * root div
     */
    useLayoutEffect(() => {
        const rootErrorPaths = [
            routes.error.serverError,
            routes.error.accessDenied,
            routes.error.notFound,
        ]
        const isInRootErrorRoutes = matchPath(location.pathname, {path: rootErrorPaths, exact: true});
        if (isInRootErrorRoutes && !isRoot) {
            setIsRoot(true);
        }
        if (!isInRootErrorRoutes && isRoot) {
            setIsRoot(false);
        }
    }, [location.pathname])


    const errorPages = (
        <>
            <Container className={'error-page'}>
                <Row>
                    <Switch>
                        <Route path={[routes.error.notFound, routes.error.base]} exact>
                            <NotFound/>
                        </Route>
                        <Route
                            path={[routes.error.accessDenied, routes.error.accessDeniedForSection, ...routesLists.allWithAccessDeniedSection]}
                            exact>
                            <AccessDenied/>
                        </Route>
                        <Route
                            path={[routes.error.serverError, routes.error.serverErrorForSection, ...routesLists.allWithServerErrorSection]}
                            exact>
                            <ServerError/>
                        </Route>
                    </Switch>
                </Row>
            </Container>
        </>
    );

    return (
        <>
            {
                isRoot
                    ? (
                        <div className={'error-page-layout'}>
                            {errorPages}
                        </div>
                    )
                    : errorPages
            }
        </>
    );
}

export default ErrorViews;
