/**
 * The element that exposes the state of youtube player ready to the application
 */
export const youtubePlayerReadyId = 'youtube-player-ready';

/**
 * The Root of the landing view
 */
export const landingViewId = 'landing-view';

/**
 * The Topbar element of the application panel
 */
export const topbarId = 'topbar';

/**
 * The Stepper used in the registration view
 */
export const registrationStepperId = 'registration-stepper';

/**
 * The Step ids used inside the registration view stepper
 */
export const registrationStepperStepIds = {
    userInfo: 'user-information' as const,
    businessInfo: 'business-information' as const,
    planSelection: 'plan-selection' as const,
    paymentInfo: 'payment-information' as const,
};


/**
 * The Ids of the sidebar menu and menu-products in the panel
 */
export const sidebarIds = {
    header: {
        editProfile: 'editProfile' as const,
        logout: 'logout' as const,
    },
    body: {
        dashboard: 'dashboard' as const,
        userManagement: {
            id: 'userManagement' as const,
            list: 'userManagementList' as const,
            groups: 'userManagementGroups' as const,
        },
        counting: {
            id: 'counting' as const,
            spire: 'spire' as const,
            position: 'positionCounting' as const,
        },
        products: {
            id: 'products' as const,
            list: 'productsList' as const,
            instances: 'productsInstances' as const,
        },
        location: 'location' as const,
        salesOrder: 'salesOrder' as const,
        purchaseOrder: 'purchaseOrder' as const,
        payments: {
            id: "payments" as const,
            subscription: 'subscription' as const,
            methods: 'methods' as const,
            invoices: 'invoices' as const,
        }
    },
}
