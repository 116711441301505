import React from "react";
import {Checkboxes} from "mui-rff";
import classnames from "classnames";
import {Checkbox as MuiCheckbox} from '@material-ui/core';


export const CheckBox = ({
                             label,
                             checked = false,
                             disabled = false,
                             className = "",
                             ...props
                         }) => {

    return (
        <>
            <MuiCheckbox
                className={classnames(`checkbox`, className)}
                label={label}
                checked={checked}
                disabled={disabled}

                {...props}
            />
        </>
    )
}


export const CheckBoxForm = ({required, label, data, name, className, ...props}) => {

    return (
        <>
            <Checkboxes
                className={classnames(`checkbox`, className)}
                required={required}
                label={label}
                data={data}
                name={name}
                {...props}
            />
        </>
    )
}



