import React, {useMemo, useState} from "react";
import button from "bootstrap/js/src/button";
import SubmitTicketDialog from "../../dialogs/submit-ticket-dialog";

/**
 *
 * @param {string} title
 * @param {string} message
 * @param {import('react-toastify').ToastContentProps} toastProps
 * @param {boolean} hideTicketSubmission
 * @param {any[]} buttons
 * @return {JSX.Element}
 * @constructor
 */
const ToastContent = ({title, message, toastProps, hideTicketSubmission, buttons}) => {
    const [openTicketDialog, setOpenTicketDialog] = useState(false);
    const ticketData = useMemo(() => ({
        curl: toastProps.data?.curl,
        capturedScreen: toastProps.data?.capturedScreen,
        url: window.location.origin + (toastProps.data?.location?.pathname ?? ''),
        apiResponse: toastProps.data?.apiResponse,
        apiRequest: toastProps.data?.apiRequest,
    }), [toastProps])

    /**
     * Closes the current toast that this component belongs to as soon as the ticket submission dialog is closed.
     */
    const onDialogClosed = () => {
        setOpenTicketDialog(false);
        toastProps.closeToast();
    }

    return (
        <>
            <div className={'toast-content'}>
                {
                    title &&
                    <p className={'toast-title'}>
                        {title ?? ''}
                    </p>
                }
                {
                    message &&
                    <p className={'toast-message'}>
                        {message ?? ''}
                    </p>
                }
                <div className={'toast-actions'}>
                    {
                        buttons?.map((e, index, array) => (e))
                    }
                    {
                        !hideTicketSubmission &&
                        <button
                            className='button secondary outlined px-2'
                            type='button'
                            onClick={() => setOpenTicketDialog(true)}
                        >
                            Submit Ticket
                        </button>
                    }
                </div>
            </div>
            <SubmitTicketDialog
                open={openTicketDialog}
                close={onDialogClosed}
                data={ticketData}
            />
        </>
    )
}

export default ToastContent;
