import React, {useEffect} from "react";
import useRouter from "../../../../hooks/use-router";
import {matchPath, Route, Switch} from "react-router";
import routes, {routeFunctions} from "../../../../routes";
import ItemsListView from "./list";
import ProductView from "./single/product";
import ProductInstanceView from "./single/instance";

const ItemsView = () => {
    const {location, history, params} = useRouter();

    /**
     * Listens to the changes in pathname of the url route and with each change:
     * if the route is the same as the panel.company.products.base, changes it to the products list route.
     */
    useEffect(() => {
        if (!!matchPath(location.pathname, {path: routes.panel.company.items.base, exact: true})) {
            history.replace(routeFunctions.panel.company.items.list.products(params.company));
        }
    }, [location.pathname])

    return (
        <>
            <Switch>
                <Route path={[
                    routes.panel.company.items.base,
                    routes.panel.company.items.list.instances,
                    routes.panel.company.items.list.products,
                ]} exact>
                    <ItemsListView/>
                </Route>
                <Route path={[routes.panel.company.items.single.product]} exact>
                    <ProductView/>
                </Route>
                <Route path={[routes.panel.company.items.single.instance]} exact>
                    <ProductInstanceView/>
                </Route>
            </Switch>
        </>
    )
}

export default ItemsView;
