import React from "react";
import Dialog from "../../base/dialog";
import {DialogActions, DialogContentText, DialogTitle} from "@material-ui/core";
import {Trans, useTranslation} from "react-i18next";
import moment from "moment";

const CancelCurrentPlanConfirmationDialog = ({open, setOpen, plan, onConfirm}) => {
    const {t} = useTranslation();
    const translations = t('dialogs.cancelCurrentPlanConfirmationDialog', {returnObjects: true,});

    return (
        <>
            <Dialog
                open={open}
                setOpen={setOpen}
                maxWidth={'sm'}
                className={'cancel-plan-confirmation-dialog'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle className={'title'} id="scroll-dialog-title">
                    {translations?.title ?? ''}
                </DialogTitle>
                <DialogContentText className={'py-2 px-4 pb-4'}>
                    <Trans
                        t={t}
                        i18nKey="dialogs.cancelCurrentPlanConfirmationDialog.content"
                        values={{
                            date: plan?.nextBill
                                ? moment(plan.nextBill).format('MMM DD YYYY')
                                : ''
                        }}
                        components={{bold: <span className={'date'}/>}}
                    />
                </DialogContentText>
                <DialogActions>
                    <button
                        className={'button outlined primary px-5 py-2'}
                        type={'button'}
                        onClick={setOpen}
                    >
                        {translations?.actions?.cancel ?? ''}
                    </button>
                    <button
                        className={'button primary px-5 py-2'}
                        type={'button'}
                        onClick={onConfirm}
                    >
                        {translations?.actions?.accept ?? ''}
                    </button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CancelCurrentPlanConfirmationDialog;
