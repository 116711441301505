import React from "react";
import routes, {routesLists} from "../../routes";
import {Route, Switch} from "react-router";
import {MuiThemeProvider} from "@material-ui/core";
import theme from "../../../assets/mui/panel-theme";
import Panel from "../../containers/panel";
import Auth from "./auth";
import Loader from "../../components/app-specific/loader";
import useRouter from "../../hooks/use-router";
import CompanyViews from "./company";
import SubscriptionView from "./subscription";
import PaymentMethodsView from "./payment-methods";
import EditProfileView from "./edit-profile";
import InvoicesView from "./invoices";
import SettingsView from "./settings";

const PanelViews = () => {
    //TODO: change the routes that force the render of panel routes instead of loading component
    const {match: forceShowPanelViews} = useRouter({
        path: [routes.panel.auth.login],
        exact: true
    })

    return (
        <MuiThemeProvider theme={theme}>
            <Panel>
                {(loading) => (
                    !loading || !!forceShowPanelViews ?
                        <Switch>
                            <Route path={[routes.panel.base]} exact>
                                <></>
                            </Route>
                            <Route path={[routes.panel.auth.login]} exact>
                                <Auth/>
                            </Route>
                            <Route path={[routes.panel.setup]} exact>
                                {/*TODO: add setup path*/}
                                <></>
                            </Route>
                            <Route path={[routes.panel.versions]} exact>
                                {/*TODO: add versions path*/}
                                <></>
                            </Route>
                            <Route path={[routes.panel.subscription]}>
                                <SubscriptionView/>
                            </Route>
                            <Route path={[routes.panel.invoices]}>
                                <InvoicesView/>
                            </Route>
                            <Route path={[routes.panel.editProfile]}>
                                <EditProfileView/>
                            </Route>
                            <Route path={[routes.panel.paymentMethods]}>
                                <PaymentMethodsView/>
                            </Route>
                            <Route path={[routes.panel.settings]}>
                                <SettingsView/>
                            </Route>
                            <Route path={routesLists.company} exact>
                                <CompanyViews/>
                            </Route>

                        </Switch>
                        : <Loader withOverlay/>
                )}
            </Panel>
        </MuiThemeProvider>
    );
}

export default PanelViews;
