/**
 * The Action types for the registration reducer
 */
export const CompanyReducerActions = {
    select: 'SELECT',
};


/**
 * The reducer for selected company of the user.
 * @param {CompanyContextState} state
 * @param {{type: string, payload: *}} action
 */
const CompanyReducer = (state, action) => {
    switch (action.type) {
        case CompanyReducerActions.select:
            return action.payload;

        default:
            return state;
    }
}

export default CompanyReducer;
