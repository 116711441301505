import React from "react";
import TableRow from "../row";
import {CSSTransition, TransitionGroup} from 'react-transition-group';

/**
 *
 * @param {TableCellData[]}                 cells
 * @param {TableData[]}                     data
 * @param {{count: number, state: boolean}}   loading
 * @param {{timeout: any, classNames: string}}  animationProps
 * @param {{timeout: any, classNames: string}}  loadingAnimationProps
 */
const TableBody = ({cells, data, loading, animationProps, loadingAnimationProps}) => {
    return (
        <>
            <TransitionGroup component={'tbody'} className={'table-body'}>
                {
                    loading?.state
                        ? Array(loading?.count).fill(null).map((e, index) => (
                            <CSSTransition
                                key={`--${index}`}
                                {...loadingAnimationProps}
                            >
                                <tr key={`--${index}`}>
                                    {
                                        Array(!!cells?.find(e => e.last) ? cells.length : cells.length + 1)
                                            .fill(null)
                                            .map((e, index) => (
                                                <td key={index}>
                                                    <div className={'table-row loading'}>
                                                        <div/>
                                                    </div>
                                                </td>
                                            ))
                                    }
                                </tr>
                            </CSSTransition>
                        ))
                        : data?.map((singleData, index) => (
                            <CSSTransition
                                key={singleData.key}
                                {...animationProps}
                            >
                                <TableRow
                                    cells={cells}
                                    data={singleData}
                                    last={index + 1 === data?.length}
                                    animationProps={animationProps}
                                />
                            </CSSTransition>
                        ))
                }
            </TransitionGroup>
        </>
    )
}

export default TableBody;
