import {Col, Row} from "react-bootstrap";
import UserInfo from "./sections/user-info";
import UserPicture from "./sections/user-picture";
import {useEffect, useState} from "react";
import {setUserInfoRedux} from "../../../../redux/entities/user-info/actions";
import {useDispatch} from "react-redux";


const EditProfileView = () => {
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({});
    const dispatch = useDispatch();

    /**
     * As soon as the component mounts:
     * fetches the user information from the server.
     */
    useEffect(() => {
        getUserInfo();
    }, []);

    /**
     * Fetches the user information from the server and sets the inner state.
     */
    const getUserInfo = () => {
        setLoading(true);
        //TODO: call the API to retrieve the user information
        const resData = {
            id: 1,
            firstName: 'John',
            lastName: 'Doe',
            phone: '4445556666',
            email: 'jdoe@gmail.com',
            profileImageUrl: "https://google.com/notFound.jpeg",
        }
        setUserProfileInfo(resData)
        setUserProfileImage(resData.profileImageUrl)
        setLoading(false);
    }

    /**
     * Sets the given profile information in both the redux and inner state
     * @param {any} data the user information
     */
    const setUserProfileInfo = (data) => {
        const info = {
            firstName: data.firstName,
            lastName: data.lastName,
            phone: data.phone,
            email: data.email,
        };
        setUser(info);
        dispatch(setUserInfoRedux(info))
    }

    /**
     * Sets the given profile image in both the redux and inner state
     * @param {string} profileImageUrl the url of the profile image
     */
    const setUserProfileImage = (profileImageUrl) => {
        const info = {
            profileImageUrl: profileImageUrl,
        };
        setUser(prevState => ({...prevState, ...info}));
        dispatch(setUserInfoRedux(info))
    }

    return (
        <div className='edit-profile-container'>
            <Row>
                <Col xs={12} lg={6} className='order-2 order-lg-1'>
                    <UserInfo loading={loading} user={user} setUser={setUserProfileInfo}/>
                </Col>
                <Col xs={12} lg={6} className='order-1 order-lg-2 ps-0'>
                    <UserPicture loading={loading} data={user?.profileImageUrl} setData={setUserProfileImage}/>
                </Col>
            </Row>
        </div>
    )
}

export default EditProfileView;
