import React from 'react';
import {MenuItem, Select as MuiSelect} from "@material-ui/core";
import FieldSelect from "./_base";
import classNames from "classnames";
import {useSelectStyles} from "./_styles";
import {primaryColor} from "../../../../assets/mui/colors";


/**
 * The Menu props for blurring the dropdown after its been opened and to bring the menu to the front to prevent
 * its edges being clipped.
 */
const defaultMenuProps = (classes) => ({
    TransitionProps: {
        onEnter: () => {
            setTimeout(() => {
                if (document.activeElement) {
                    document.activeElement.blur();
                }
            }, 500);
        },
    },
    PaperProps: {
        elevation: 4,
        style: {
            transform: 'translate3d(0, 0, 0)'
        }
    },
    MenuListProps: {
        classes: {
            root: classes.menuRoot
        },
    },
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
    getContentAnchorEl: null
})

/**
 * The Default Renderer for the values of the dropdown
 * @param value {any}
 * @param renderValue {function}
 * @param header {any}
 * @param multiple {boolean}
 * @return {JSX.Element}
 */
const defaultRenderValue = (value, header, renderValue, multiple) => {
    // no value selected => the header
    if (!value) {
        return (
            <p>
                {header}
            </p>
        )
    }
    // the external render value exists
    if (renderValue) {
        return renderValue(value);
    }
    // multiple values can be rendered
    if (multiple) {
        return (
            <p className={'text-wrap'}>
                {value.map(e => (
                    <span key={e.label ?? e.name ?? e.title}
                          className='mr-3'>
                            {e.label ?? e.name ?? e.title}
                        </span>
                ))}
            </p>
        )
    }
    // default
    return (
        <p>
            {value.label ?? value.name ?? value.title ?? value.description ?? `${value}`}
        </p>
    )
}

export const Select = ({
                           placeholder = 'select',
                           ariaLabel = 'select',
                           value = '',
                           fullWidth = false,
                           fontSize = 14,
                           autoWidth = false,
                           projects = false,
                           multiple = false,
                           displayEmpty = true,
                           onChange,
                           label,
                           name = label,
                           header = null,
                           type,
                           data,
                           renderValue,
                           disabled = false,
                           className = '',
                           iconColor = primaryColor,
                           ...props
                       }) => {
    const classes = useSelectStyles({data, fontSize, iconColor});


    return (
        <MuiSelect
            displayEmpty={displayEmpty}
            disabled={disabled || !data?.length}
            fullWidth={fullWidth}
            autoWidth={autoWidth}
            type={type}
            variant={'standard'}
            name={name}
            value={value}
            onChange={onChange}
            multiple={multiple}

            classes={{
                icon: classes.icon,
                iconOpen: classes.iconOpen,
                root: classes.root,
            }}
            className={classNames('select')}
            placeholder={placeholder}
            inputProps={{'aria-label': ariaLabel, className: classes.innerInput}}
            renderValue={(value) => defaultRenderValue(value, header, renderValue, multiple)}
            MenuProps={defaultMenuProps(classes)}
            {...props}
        >
            {
                data &&
                [
                    (header?.length
                            ? <MenuItem
                                unselectable={'on'}
                                key={'first-item'}
                                disabled
                            >
                                {header}
                            </MenuItem>
                            : null
                    ),
                    ...data?.map(item => (
                        <MenuItem
                            key={item.id}
                            value={item.value}
                            disabled={item.disabled}
                        >
                            {defaultRenderValue(item.value, header, renderValue, multiple)}
                        </MenuItem>
                    ))].filter(e => e)
            }
        </MuiSelect>
    );
}


export const FormSelect = ({
                               label,
                               placeholder = 'select',
                               ariaLabel = 'select',
                               required = false,
                               fullWidth = true,
                               autoWidth = false,
                               multiple = false,
                               displayEmpty = true,
                               fontSize = 13,
                               name,
                               data,
                               renderValue,
                               header,
                               disabled = false,
                               onChange,
                               className = '',
                               iconColor = primaryColor,
                               insidePanel = true,
                               ...props
                           }) => {
    const classes = useSelectStyles({data, fontSize, iconColor});

    return (
        <FieldSelect
            displayEmpty={displayEmpty}
            disabled={!data?.length || disabled}
            fullWidth={fullWidth}
            autoWidth={autoWidth}
            required={!!required}
            name={name}
            variant={'standard'}
            label={label}
            multiple={multiple}
            placeholder={placeholder}
            onChange={onChange}
            classes={{
                icon: classes.icon,
                root: classes.root,
            }}
            className={classNames('select', className, {"app-input": insidePanel})}
            inputProps={{'aria-label': ariaLabel, className: classes.innerInput}}
            renderValue={(value) => defaultRenderValue(value, header, renderValue, multiple)}
            MenuProps={defaultMenuProps(classes)}
            {...props}
        >
            {
                data &&
                [
                    (header?.length
                            ? <MenuItem
                                key={'first-item'}
                                value={undefined}
                                disabled
                                selected={false}
                            >
                                {header}
                            </MenuItem>
                            : null
                    ),
                    ...data?.map(item => (
                        <MenuItem
                            key={item.id}
                            value={item.value}
                            disabled={item.disabled}
                        >
                            {defaultRenderValue(item.value, header, renderValue, multiple)}
                        </MenuItem>
                    ))].filter(e => e)
            }
        </FieldSelect>
    );
}
