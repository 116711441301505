import {Tooltip} from "@material-ui/core";
import copy from "clipboard-copy";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    tooltip: {
        borderRadius: 0,
        fontWeight: 700,
        zIndex: 1600,
    }
}))

const CopyToClipboard = ({tooltipProps, children}) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const classes = useStyles();

    const onCopy = (content) => {
        copy(content);
        setShowTooltip(true);
    }

    return (
        <Tooltip
            className={classes.tooltip}
            open={showTooltip}
            title={"Copied to clipboard!"}
            leaveDelay={1000}
            classes={{
                popper: classes.tooltip,
            }}
            onClose={() => setShowTooltip(false)}
            {...tooltipProps || {}}
        >
            {children({copy: onCopy})}
        </Tooltip>
    );
}

export default CopyToClipboard;
