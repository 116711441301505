import {ReactComponent as BizPireLogo} from '../../../../assets/logos/logo-descriotion.svg';
import {ReactComponent as Instagram} from '../../../../assets/images/views/public-views/footer/instagram.svg';
import {ReactComponent as Twitter} from '../../../../assets/images/views/public-views/footer/twitter.svg';
import {ReactComponent as LinkedIn} from '../../../../assets/images/views/public-views/footer/linkedin.svg';
import moment from "moment";
import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {openInNewTab} from "../../../../core/services/utils";
import {useTranslation} from "react-i18next";

const PublicViewLayoutFooter = ({sections, onSectionClick}) => {
    const {t} = useTranslation();

    /**
     * Opens a new tab with Bizkey Tech's website
     */
    const navigateToBizkeyTech = () => {
        openInNewTab('https://bizkeytech.com');
    }

    return (
        <div className={'footer'}>
            <Container>
                <Row className='justify-content-between'>
                    <Col xs={12} md={6}
                         className='mt-4 pt-2 d-flex flex-column align-items-center align-items-md-start'>
                        <BizPireLogo className={'logo'} onClick={() => onSectionClick(null)}/>
                        <div className={'nav-container'}>
                            {
                                Object.values(sections).map((section) => (
                                    <div
                                        key={section.hash}
                                        className={'nav-item'}
                                        onClick={() => onSectionClick(section.hash)}
                                    >
                                        {section.text}
                                    </div>
                                ))
                            }
                        </div>
                    </Col>
                    <Col xs={12} md={6}
                         className='mt-4 pt-2 d-flex flex-column align-items-center align-items-md-end align-items-md-start'>
                        <div className={'d-flex align-items-end justify-content-center justify-content-md-end' +
                            ' flex-wrap'}
                        >
                            <div className={'me-2'}>
                                <p className='title'>
                                    {t('containers.publicViewLayout.footer.subscribeText')}
                                </p>
                                <input placeholder={'Enter Your Email'}/>
                            </div>
                            <button className={'button secondary subscribe'}>
                                {t('containers.publicViewLayout.footer.subscribeButton')}
                            </button>
                        </div>
                        <div className={'social-container justify-content-center justify-content-md-end'}>
                            <button className={'button text'}>
                                <Instagram className={'social'}/>
                            </button>
                            <button className={'button text'}>
                                <Twitter className={'social'}/>
                            </button>
                            <button className={'button text'}>
                                <LinkedIn className={'social'}/>
                            </button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <p className='disclaimer'>
                        &copy;
                        {t('containers.publicViewLayout.footer.copyRight', {
                            date: moment().format('YYYY'),
                            company: 'BizKey Tech Inc'
                        })}
                        <span className={'bizkey-tech'} onClick={navigateToBizkeyTech}>
                            Bizkey Tech
                        </span>
                    </p>
                </Row>
            </Container>
        </div>
    )
};

export default PublicViewLayoutFooter;
