import React from "react";
import classnames from "classnames";
import {TableCellAlignments} from "../../../../core/constants/enums";


/**
 * @param {'th' | 'td'}             elementType
 * @param {TableCellData}           cell
 * @param {string}                  className
 * @param {function(TableCellData)} renderCell
 * @param {any}                     style
 */
const TableCell = ({elementType = 'th', cell, className, style, renderCell}) => {

    return (
        <>
            {
                React.createElement(
                    elementType,
                    {
                        key: cell.name,
                        className: classnames('cell', className),
                        align: cell.alignment,
                        style: {
                            '--size': cell.size,
                            ...(style ?? {}),
                        }
                    },
                    <div className={classnames('inner-cell', cell.type,
                        {
                            'justify-content-start': cell.alignment === TableCellAlignments.left,
                            'justify-content-center': cell.alignment === TableCellAlignments.center,
                            'justify-content-end': cell.alignment === TableCellAlignments.right,
                        },
                    )}>
                        {renderCell(cell) ?? <></>}
                    </div>
                )
            }
        </>
    )
}

export default TableCell;
