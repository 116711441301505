import React, {useMemo} from "react";
import useRouter from "../../../../../hooks/use-router";
import {ItemsListTabValues} from "../../../../../../core/constants/tab-values";
import {useTranslation} from "react-i18next";
import {routeFunctions, routes} from "../../../../../routes";
import TabsLayout from "../../../../../components/app-specific/tabs-layout";
import {matchPath} from "react-router";
import ItemsTabView from "./items";
import PartsTabView from "./parts";

const ItemsListView = () => {
    const {location, history, params} = useRouter();
    const {t} = useTranslation();
    const translations = t("views.panel.company.products.list", {returnObjects: true})
    const tabs = [
        {
            label: translations?.tabs?.products,
            value: ItemsListTabValues.products
        },
        {
            label: translations?.tabs?.instances,
            value: ItemsListTabValues.instances
        },
    ];

    /**
     * Checks if the initial value of the tab view should be items or parts list view
     * @type {string}
     */
    const initialValue = useMemo(() => {
        return (
            !!matchPath(location.pathname, {
                path: routes.panel.company.items.list.products,
                exact: true
            })
                ? ItemsListTabValues.products
                : ItemsListTabValues.instances
        )
    }, [location.pathname]);


    /**
     * Changes the path of the url with changes in selected tab
     * @param tabValue
     */
    const onTabChanged = (tabValue) => {
        if (tabValue === ItemsListTabValues.products) {
            history.push(routeFunctions.panel.company.items.list.products(params.company));
            return;
        }
        history.push(routeFunctions.panel.company.items.list.instances(params.company));
    }


    return (
        <>
            <div className={'panel-card p-0 pt-4 products'}>
                <TabsLayout
                    tabs={tabs}
                    swipeable
                    onChange={onTabChanged}
                    initialValue={initialValue}
                >
                    <PartsTabView/>
                    <ItemsTabView/>
                </TabsLayout>

            </div>
        </>
    )
}

export default ItemsListView;
