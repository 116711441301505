import React from "react";
import useRouter from "../../../hooks/use-router";
import {useTranslation} from "react-i18next";
import {ReactComponent as NotFoundIcon} from "../../../../assets/images/views/error/not-found.svg";
import {Col, Container, Row} from "react-bootstrap";


const NotFound = () => {
    const {history} = useRouter();
    const {t} = useTranslation();

    return (
        <>
            <div className={'svg-container'}>
                <NotFoundIcon/>
            </div>
            <Container>
                <Row>
                    <Col xs={12} className={'flex-row-center'}>
                        <p className={'message'}>
                            {t('views.errors.notFound.message')}
                        </p>
                    </Col>
                    <Col xs={12} className={'flex-row-center mt-3'}>
                        <button
                            className={'button primary px-5'}
                            onClick={history.goBack}>
                            {t('views.errors.notFound.actionButton')}
                        </button>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default NotFound;
