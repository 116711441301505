import moment from "moment";
import {jsonToCSV} from 'react-papaparse'
import {CreditCardTypes, MonerisCardTypes, OperatingSystems, ReadingFileAs} from "../constants/enums";
import html2canvas from "html2canvas";


/**
 * Determines if two objects are equal
 * @param object1 {any}
 * @param object2 {any}
 * @return {boolean}
 */
export const deepEqual = (object1: any, object2: any): boolean => {
    // check if the first one is an array
    if (Array.isArray(object1)) {
        if (!Array.isArray(object2) || object1.length !== object2.length) return false;
        for (let i = 0; i < object1.length; i++) {
            if (!deepEqual(object1[i], object2[i])) return false;
        }
        return true;
    }
    // check if the first one is an object
    if (typeof object1 === 'object' && object1 !== null && object2 !== null) {
        if (!(typeof object2 === 'object')) return false;
        const keys = Object.keys(object1);
        if (keys.length !== Object.keys(object2).length) return false;
        for (const key in object1) {
            if (!deepEqual(object1[key], object2[key])) return false;
        }
        return true;
    }
    // not array and not object, therefore must be primitive
    return object1 === object2;
}

/**
 *  Deep copy an acyclic *basic* Javascript object.  This only handles basic
 * scalars (strings, numbers, booleans) and arbitrarily deep arrays and objects
 * containing these.  This does *not* handle instances of other classes.
 * @param obj {any}
 */
export const deepCopy = (obj: any): any => {
    let ret, key;
    let marker = '__deepCopy';

    if (obj && obj[marker])
        throw (new Error('attempted deep copy of cyclic object'));

    if (obj && obj.constructor == Object) {
        ret = {};
        obj[marker] = true;

        for (key in obj) {
            if (key == marker)
                continue;

            // @ts-ignore
            ret[key] = deepCopy(obj[key]);
        }

        delete (obj[marker]);
        return (ret);
    }

    if (obj && obj.constructor == Array) {
        ret = [];
        // @ts-ignore
        obj[marker] = true;

        for (key = 0; key < obj.length; key++)
            ret.push(deepCopy(obj[key]));

        // @ts-ignore
        delete (obj[marker]);
        return (ret);
    }
    // It must be a primitive type -- just return it.
    return (obj);
}

/**
 * Transforms a string that is parsable to a number into a formatted money string.
 * @param amount {string | number}
 * @param decimalCount {number}
 * @param decimal {string} the identifier used for decimal separation
 * @param thousands {string} the identifier used for thousands separation
 */
export const formatMoney = (amount: any, decimalCount = 2, decimal = ".", thousands = ",") => {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";
        amount = Math.abs(Number(amount) || 0).toFixed(decimalCount);
        let i: any = parseInt(amount).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return "$" + negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
};

/**
 * Flattens an object. if the parent key exists, then prepends the parent key with the key as it constructs the obejct
 * @param object {any}
 * @param parentKey {string | null}
 */
const _flatten = (object: any, parentKey: string | null = null): any => {
    return [].concat(...Object.keys(object)
        .map((key) => typeof object[key] === 'object'
            ? _flatten(object[key], parentKey ? `${parentKey}-${key}` : key)
            : ((parentKey) ? {[`${parentKey}-${key}`]: object[key]} : {[key]: object[key]})
        )
    );
}

/**
 * Creates a new flattened object off of the given object
 * @param object {any}
 */
export const flattenObject = (object: any) => {
    return Object.assign({}, ..._flatten(object))
}

/**
 * Given an object, will flatten it and return all of its values as a single.
 *
 * if append, then for each of the values of the object, appends it to their values as a string
 * @param object {any}
 * @param append {string | null}
 */
export const flattenObjectAndReturnAsAList = (object: any, append: string | null = null): string[] | any[] => {
    const all = flattenObject(object);
    const res = [];
    for (const [key, value] of Object.entries(all)) {
        if (key) res.push(value);
    }
    if (append && append.length) return res.map(e => `${e}${append}`);
    return res;
};

/**
 * Gets the offset of an element based on the parent id
 * @param element {HTMLElement | Element | null}
 * @param parentId {string}
 */
export const getOffsetTop = (element: HTMLElement | Element | null, parentId: string) => {
    let offsetTop = 0;
    while (element && element.id !== parentId) {
        offsetTop += (element as HTMLElement).offsetTop;
        element = (element as HTMLElement).offsetParent;
    }
    return offsetTop;
}

/**
 * Gets the element position with resposne to the window
 * @param element {HTMLElement}
 */
export const getElementPositionWithRespectToWindow = function (element: HTMLElement) {
    let target = element,
        target_width = target.offsetWidth,
        target_height = target.offsetHeight,
        globalLeft = 0,
        globalTop = 0,
        rect = {};

    /**
     * if the element has a parent, then add the parents; offset left and top the global one, otherwise return the
     * rect object of the element position
     * @param _parent {HTMLElement}
     */
    const moonwalk = function (_parent: any) {
        if (!!_parent) {
            globalLeft += _parent.offsetLeft;
            globalTop += _parent.offsetTop;
            moonwalk(_parent.offsetParent);
        } else {
            return rect = {
                top: target.offsetTop + globalTop,
                left: target.offsetLeft + globalLeft,
                bottom: (target.offsetTop + globalTop) + target_height,
                right: (target.offsetLeft + globalLeft) + target_width
            };
        }
    };
    moonwalk(target.offsetParent);
    return rect;
}

/**
 * Determines if a given target is within the vicinity of a central point based on the specified percentage
 * @param target {number}
 * @param center {number}
 * @param percent {number}
 * @param threshHold {number}
 */
export const withinVicinity = (target: number, center: number, percent: number = 0, threshHold: number = 0): boolean => {
    const difference = Math.abs(center - target);
    return (Math.abs(center) + ((percent / 100) * Math.abs(center)) - threshHold) >= difference;
}

/**
 * Exports a file from the browser to the user OS system.
 * @param {Blob}    blob
 * @param {string}  exportedFileName
 * @private
 */
const _exportFile = (blob: Blob, exportedFileName: string,) => {
    const link = document.createElement("a");
    if (link.download !== undefined) {
        link.href = URL.createObjectURL(blob);
        link.download = exportedFileName;
        link.style.visibility = 'hidden';
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

/**
 * Exports a file into csv by the given headers, products and the file title
 * @param headers {any}
 * @param items {any}
 * @param fileTitle {string}
 */
export const exportCSVFile = (headers: any, items: any, fileTitle: string): void => {
    const csv = jsonToCSV(items, {});
    const exportedFileName = fileTitle + '.csv' || 'export.csv';
    const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
    _exportFile(blob, exportedFileName);
}

/**
 * Exports a file into json given products and the file title
 * @param items {any}
 * @param fileTitle {string}
 */
export const exportJsonFile = (items: any, fileTitle: string): void => {
    const exportedFileName = fileTitle + '.json' || 'template.json';
    const blob = new Blob([items], {type: 'data:application/json;charset=utf-8;'});
    _exportFile(blob, exportedFileName);
}

/**
 * Reads the given file based on the provided method of reading.
 * @param {File | Blob} file
 * @param {string} as
 */
export const readFile = (file: File | Blob, as: string): PromiseLike<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        switch (as) {
            case ReadingFileAs.text:
                reader.readAsText(file);
                break;
            case ReadingFileAs.dataUrl:
                reader.readAsDataURL(file);
                break;
            case ReadingFileAs.arrayBuffer:
                reader.readAsArrayBuffer(file);
                break;
            case ReadingFileAs.binaryString:
                reader.readAsBinaryString(file);
                break;
        }
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

/**
 * fills the card number empty spaces with *s
 * @param lastFour
 * @param cardType
 */
export const getCardNumber = (lastFour: string, cardType: string) => {
    switch (cardType) {
        case MonerisCardTypes.masterCard:
        case CreditCardTypes.M:
        case MonerisCardTypes.jcb:
        case CreditCardTypes.C1:
        case MonerisCardTypes.debit:
        case CreditCardTypes.D:
        case MonerisCardTypes.visa:
        case CreditCardTypes.V:
        case MonerisCardTypes.novusDiscover:
        case CreditCardTypes.NO:
        case MonerisCardTypes.sears:
        case CreditCardTypes.SE:
        default:
            return `**** **** **** ${lastFour}`;
        case MonerisCardTypes.americanExpress:
        case CreditCardTypes.AX:
            return `**** ****** *${lastFour}`;
    }
}

/**
 * fills the card month empty spaces with *s
 * @param month
 */
export const getCardMonth = (month: string) => {
    if (!month?.length) return "** / **";
    if (month?.length === 1) {
        return `0${month} / **`;
    }
    return `${month} / **`;
}

/**
 * Sets Attributes for a given element.
 * @param element
 * @param attributes
 */
export const setAttributes = (element: any, attributes: any) => {
    for (const [key, value] of Object.entries(attributes)) {
        if ((key === 'styles' || key === 'style') && typeof value === 'object') {
            for (const [styleKey, styleProp] of Object.entries(value as any)) {
                element.style[styleKey] = styleProp;
            }
        } else if (value === 'html') {
            element.innerHTML = value;
        } else {
            element.setAttribute(key, value);
        }
    }
}

type ObjectKey = string | number | symbol;

/**
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param items An array of type TItem.
 * @param keyGetter A Function that takes the the Array type TItem as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of TItem.
 *
 * @returns Map of the array grouped by the grouping function.
 */
export const groupBy = <K extends ObjectKey, TItem extends Record<K, ObjectKey>>(
    items: TItem[],
    keyGetter: (input: TItem) => K
): Record<ObjectKey, TItem[]> => {
    return items.reduce((result, item) => ({
        ...result,
        [keyGetter(item)]: [
            ...(result[keyGetter(item)] || []),
            item
        ],
    }), {} as Record<ObjectKey, TItem[]>);
}

/**
 * Calculates the area of a circle given its radius.
 * @param r {number}
 */
export const areaOfCircle = (r: number) => r * r * 3.1415;

/**
 * Creates a Unique Identifier in form of a string
 */
export const createUUId = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const randomNumber = Math.random() * 16 | 0;
        const v = c == 'x' ? randomNumber : (randomNumber & 0x3 | 0x8);
        return v.toString(16);
    });
}

/**
 *  Returns the color as an array of [r, g, b, a] -- all range from 0 - 255
 * color must be a valid canvas fillStyle. This will cover most anything
 * you'd want to use.
 * Examples:
 * _colorToRGBA('red')  # [255, 0, 0, 255]
 * _colorToRGBA('#f00') # [255, 0, 0, 255]
 * @param {string} color
 */
const _colorToRGBA = (color: string) => {
    let cvs, ctx: any;
    cvs = document.createElement('canvas');
    cvs.height = 1;
    cvs.width = 1;
    ctx = cvs.getContext('2d');
    ctx.fillStyle = color;
    ctx.fillRect(0, 0, 1, 1);
    return ctx.getImageData(0, 0, 1, 1).data;
}

/**
 * Turns a number (0-255) into a 2-character hex number (00-ff)
 * @param {number} num
 */
const _byteToHex = (num: number) => {
    return ('0' + num.toString(16)).slice(-2);
}

/**
 * Convert any CSS color to a hex representation
 *  Examples:
 * colorToHex('red')            # '#ff0000'
 * colorToHex('rgb(255, 0, 0)') # '#ff0000'
 * @param {string} color
 */
export const colorToHex = (color: string) => {

    let rgba: any, hex: any;
    rgba = _colorToRGBA(color);
    hex = [0, 1, 2].map(
        function (idx) {
            return _byteToHex(rgba[idx]);
        }
    ).join('');
    return "#" + hex;
}

/**
 * Returns the given value with the maximum amount of decimals provided.
 * @param value
 * @param maxDecimals
 * @param returnType
 */
export const numberWithMaximumDecimals = (value: string | number, maxDecimals: number = 2, returnType: "string" | "number" = 'string'): number | string => {
    // @ts-ignore
    const modulus = value % 1;
    if (modulus == 0) {
        return value;
    }
    const modulusString = value.toString();

    const numberOfDecimals = modulusString.split('.')?.length > 1
        ? modulusString.split('.')[1].length
        : 0;
    const valueWithCorrectDecimals = typeof value === 'string'
        ? parseFloat(value).toFixed(Math.min(numberOfDecimals, maxDecimals))
        : value.toFixed(Math.min(numberOfDecimals, maxDecimals));

    switch (returnType) {
        case 'string':
            return valueWithCorrectDecimals;
        case "number":
        default:
            return parseFloat(valueWithCorrectDecimals);
    }
}

/**
 * Given a string, forces a number value from it. If NAN, returns 0.
 * @param {string| number} val
 * @return {number}
 */
export const forceNumber = (val: number | string): number => {
    if (typeof val === 'number') return val;
    const _value = parseFloat(val ?? '0');
    return isNaN(_value) ? 0 : _value;
}

/**
 * Converts the given css values to pixel values.
 * @param {{name: string, value: any}[]} cssValues
 * @return {{name: string, value: number}[]}
 */
export const calculateInPx = (cssValues: { name: string, value: any }[]) => {
    const result: { name: string, value: any }[] = [];
    if (!cssValues?.length) return result;
    const testElement = document.createElement('testPageSize');
    cssValues?.forEach((cssValue: { name: string, value: any }) => {
        // @ts-ignore
        testElement.style[cssValue.name] = cssValue.value;
    })
    document.documentElement.appendChild(testElement);
    cssValues.forEach((cssValue: { name: string, value: any }, index: number) => {
        result[index] = {
            name: cssValue.name,
            // @ts-ignore
            value: window.Extensions.toPx(testElement, testElement.style[cssValue.name], cssValue.name)
        }
    })
    document.documentElement.removeChild(testElement);
    return result;
}

/**
 * Opens a new tab in the users' browser with the given url.
 * @param url
 */
export const openInNewTab = (url: string) => {
    const newTab = document.createElement('a');
    newTab.href = url;
    newTab.target = '_blank';
    newTab.rel = 'noopenner';
    document.body.appendChild(newTab);
    newTab.click();
    document.body.removeChild(newTab);
}

const constructAbbreviatedNumber = (number: number, divisor: number, fractionalDigits = 1): string => {
    const whole = number / divisor;
    const remainder = number % divisor;
    if (remainder > (divisor / 10)) {
        return whole.toFixed(fractionalDigits);
    }
    return Math.round(whole).toString();

}

/**
 * Shortens a given number by giving it postfixes to it.
 * @param {number | string} number
 * @param {number | string} min
 * @return {string}
 */
export const abbreviateNumber = (number: number, min = 999): string => {
    min = Number(min);
    if (!number) {
        return '0';
    }
    if (min && number <= min) {
        return number.toString();
    }
    const map = {
        1000000000: 'B',
        1000000: 'M',
        1000: 'K',
    };
    const mapList = Object.entries(map);
    number = Number(number);
    for (let i = 0; i < mapList.length; i++) {
        const [_divisorString, replacedSymbol] = mapList[i];
        const divisor = Number(_divisorString);
        if (number > divisor) {
            return constructAbbreviatedNumber(number, divisor) + replacedSymbol;
        }
    }
    return number.toString();
}

/**
 * Creates the price of the plans card in the application.
 *
 * Splits the price into two elements in the array (the whole number and the decimal part)
 * @param {string | number} price
 * @return {number[]}
 */
export const createPriceForPlansCard = (price: string | number) => {
    if (!price) {
        return [];
    }
    const double = parseFloat(`${price}`) ?? 0.0;
    const priceArray = double.toString().split('.');
    if (priceArray?.length) {
        return [insertThousandSeparators(parseInt(priceArray[0] ?? 0)), enforceDoubleRemainder(parseInt(priceArray[1]) ?? 0)];
    } else {
        return ['0', '00']
    }
}

/**
 * Enforces the reminder to have at least 2 characters
 * @param {number} remainder
 * @return {string}
 */
const enforceDoubleRemainder = (remainder: number) => {
    if (remainder > 9) {
        return remainder.toString();
    }
    return `0${remainder}`
}

/**
 * Inserts thousand separators to the provided number.
 * @param {number} number
 * @param {string} thousands
 * @return {string}
 */
const insertThousandSeparators = (number: number, thousands: string = ',') => {
    const array = number.toString().split('');
    array.reverse();
    const copy = deepCopy(array);
    for (let i = 3; i < copy.length; i++) {
        if (i % 3 === 0 && i + 1 <= copy.length) {
            const addition = (i / 3) - 1;
            array.splice(addition + i, 0, thousands);
        }
    }
    array.reverse();
    return array.join('');
}

/**
 * Fetches the OS of the system with 90% accuracy.
 *
 * @author Vladyslav Turak
 * @see https://stackoverflow.com/questions/38241480/detect-macos-ios-windows-android-and-linux-os-with-js
 */
const _getOS = () => {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = OperatingSystems.mac;
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = OperatingSystems.ios;
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = OperatingSystems.windows;
    } else if (/Android/.test(userAgent)) {
        os = OperatingSystems.android;
    } else if (/Linux/.test(platform)) {
        os = OperatingSystems.linux;
    }
    return os;
}

/**
 * Fetches the information about the os of the user.
 */
export const getOsSpecs = () => {
    const os = _getOS();
    const isMac = os === OperatingSystems.mac;
    const isIOS = os === OperatingSystems.ios;
    const isWindows = os === OperatingSystems.windows;
    const isAndroid = os === OperatingSystems.android;
    const isLinux = os === OperatingSystems.linux;

    return {
        os,
        isMac,
        isIOS,
        isWindows,
        isAndroid,
        isLinux,
    };
}

/**
 * Un masks the phone number from the form input that is in standard US format (***)***-**** to pure string
 * @param  phoneNumber {string}
 */
export const unMaskPhoneNumber = (phoneNumber: string) => {
    return phoneNumber.substring(1, 4).concat(phoneNumber.substring(5, 8)).concat(phoneNumber.substring(9, 13))
}

/**
 * Takes a screenshot of the user' browser window.
 *
 * first uses the mediaDevices of the navigator to take the screenshot, if erroneous, creates the screenshot
 * using the canvas approach.
 * @param {boolean} allowExplicitScreenshot
 * @return {Promise<string>}
 */
export const screenshotUserBrowserWindow = async (allowExplicitScreenshot = false) => {
    try {
        if (!allowExplicitScreenshot) {
            // throw so that the screenshot is handled with a canvas
            throw "";
        }
        // media display with user permission screenshot.
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        const video = document.createElement("video");
        if (!context) {
            // throw so that the screenshot is handled with a canvas
            throw "";
        }

        // @ts-ignore
        const captureStream = await navigator.mediaDevices?.getDisplayMedia({video: false});
        video.srcObject = captureStream;
        context.drawImage(video, 0, 0, window.innerWidth, window.innerHeight);
        captureStream.getTracks().forEach((track: MediaStreamTrack) => track.stop());
        return extractCanvasContentBytes(canvas);
    } catch (err) {
        // canvas screenshot
        const canvas = await html2canvas(document.body);
        return extractCanvasContentBytes(canvas);
    }
}

/**
 * Creates the content bytes of the image obtained from the given canvas.
 * @param {HTMLCanvasElement} canvas
 * @return {Promise<string|null>}
 */
export const extractCanvasContentBytes = async (canvas: HTMLCanvasElement) => {
    const toBlob = (): Promise<Blob | null> => new Promise(r => canvas.toBlob(r));
    const blob = await toBlob();
    if (!blob) {
        return null;
    }
    const binaryString = await readFile(blob, ReadingFileAs.binaryString)
    if (!binaryString) {
        return null;
    }
    // @ts-ignore
    return btoa(binaryString);
}

//              ########################### COMPARATORS ###################################

/**
 * Compares two numbers
 * @param a {number}
 * @param b {number}
 */
export const numComparator = (a: number, b: number): number => {
    if (a === b) return 0;
    if (a < b) return -1;
    return 1
}

/**
 * Compares two dates by converting them to moment objects and then comparing them
 * @param a {Date}
 * @param b {Date}
 */
export const dateComparator = (a: Date, b: Date): number => {
    const _momentComparator = (a: moment.Moment, b: moment.Moment) => {
        if (a.isSame(b, 'ms')) return 0;
        if (a.isAfter(b, 'ms')) return 1;
        return -1;
    }
    return _momentComparator(moment(a), moment(b));
}

/**
 * Compares two strings.
 * @param a {string}
 * @param b {string}
 */
export const stringComparator = (a: string, b: string): number => {
    return a?.localeCompare(b);
}

/**
 * Compares two Booleans
 * @param a {boolean}
 * @param b {boolean}
 */
export const booleanComparator = (a: boolean, b: boolean): number => {
    if (a === b) return 0;
    if (a < b) return -1;
    return 1;
}

class Utils {

    /**
     * Given an object, will flatten it and return all of its values as a single.
     *
     * if append, then for each of the values of the object, appends it to their values as a string
     * @param object {any}
     * @param append {string | null}
     */
    static flattenObjectAndReturnAsAList(object: any, append: string | null = null): string[] | any[] {
        const all = this.flattenObject(object);
        const res = [];
        for (const [key, value] of Object.entries(all)) {
            if (key) res.push(value);
        }
        if (append && append.length) return res.map(e => `${e}${append}`);
        return res;
    };

    /**
     * Fetches the color name associated with the icon of the vendors' warranty rate.
     * @param {number} warrantyRate
     * @return {string}
     */
    static getWarrantyRateIconColor(warrantyRate: number = 0): string {
        if (warrantyRate < 26)
            return 'good';
        if (warrantyRate < 51)
            return 'normal'
        if (warrantyRate < 76)
            return 'not-good'
        return 'not-acceptable';
    }

    /**
     * Awaits for the specified time in milliseconds.
     * @param {number} milliseconds
     * @return {Promise<void>}
     */
    static async wait(milliseconds: number): Promise<void> {
        await new Promise(r => setTimeout(r, milliseconds));
    }

    /**
     * Fetches the aspect ratio between the given width and height.
     * @param {number} width
     * @param {number} height
     * @return {[number, number]}
     */
    static getAspectRatio(width: number, height: number): [number, number] {
        const gcd = (a: number, b: number): number => a ? gcd(b % a, a) : b;
        const widthToHeightAspectRaw = Number((width / height).toFixed(2));
        const raisedWidth = Math.round(widthToHeightAspectRaw * 100);
        const raisedHeight = 100;
        const _gcd = gcd(raisedWidth, raisedHeight);
        return [raisedWidth / _gcd, raisedHeight / _gcd];
    }

    /**
     * Excludes null or undefined from the values of the object.
     * @param {Object} obj
     * @param {boolean} excludeNull
     * @param {boolean} excludeUndefined
     * @return {Object}
     */
    static excludeNullOrUndefined<T extends Object = Object>(
        obj: T,
        excludeNull: boolean = true,
        excludeUndefined: boolean = true)
        : { [p: string]: any } {
        return Object.fromEntries(Object.entries(obj).filter(([_, val]) =>
            ((excludeNull && val !== null) || !excludeNull) &&
            ((excludeUndefined && typeof val !== 'undefined') || !excludeUndefined)
        ))
    }

    /**
     * Flattens an object. if the parent key exists, then prepends the parent key with the key as it constructs the obejct
     * @param object {any}
     * @param parentKey {string | null}
     * @private
     */
    private static flatten(object: any, parentKey: string | null = null): any {
        return [].concat(
            ...Object.keys(object).map((key) => typeof object[key] === 'object'
                ? this.flatten(object[key], parentKey ? `${parentKey}-${key}` : key)
                : ((parentKey) ? {[`${parentKey}-${key}`]: object[key]} : {[key]: object[key]})
            )
        );
    }

    /**
     * Creates a new flattened object off of the given object
     * @param object {any}
     */
    private static flattenObject(object: any) {
        return Object.assign({}, ...this.flatten(object))
    }
}

export default Utils;
