export const mapboxApiUrl = process.env.REACT_APP_MAPBOX_API_URL;

// ########################################     Auth    ########################################

const _placesBaseUrl = mapboxApiUrl + 'mapbox.places/';
/**
 * Auth Apis
 */
export const mapboxPlacesApis = {
    baseUrl: _placesBaseUrl,
    getMarkerAddress: (lng: number, lat: number, accessToken: string) => _placesBaseUrl + `${lng},${lat}.json?access_token=${accessToken}`,
    getAddressCoordinates: (address: string, accessToken: string, country?: string) => _placesBaseUrl + `${encodeURIComponent(address)}.json?access_token=${accessToken}${country?.length ? `&country=${country}` : ''}`
};
