import React, {useContext, useState} from "react";
import {Col, Row} from "react-bootstrap";
import ReactCreditCards from 'react-credit-cards';
import {RegistrationContext} from "../../../../../../contexts/registration";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import ValidateMessages from "../../../../../../../core/constants/texts/vallidate-messages";
import {makeRequired, makeValidate} from "mui-rff";
import {registrationStepperStepIds} from "../../../../../../../core/constants/ids";
import {RegistrationReducerActions} from "../../../../../../reducers/registration";
import Form from "../../../../../../components/base/form";
import classnames from "classnames";
import {FormInput} from "../../../../../../components/base/input";
import moment from "moment";
import {FormKeyboardDatePicker} from "../../../../../../components/base/date-picker";

const formNames = {
    cvc: 'cvc',
    expiry: 'expiry',
    holderName: 'name',
    cardNumber: 'number',
};

/**
 * Creates the schema for the registration payment info form
 * @param {function(string): any} t the translator
 * @return {*}
 */
const createSchema = (t) => {
    return Yup.object().shape({
        [formNames.cvc]: Yup.string().nullable().required(ValidateMessages.required),
        [formNames.holderName]: Yup.string().nullable().required(ValidateMessages.required),
        [formNames.expiry]: Yup.date().nullable()
            .required(ValidateMessages.required)
            .typeError(ValidateMessages.incorrectType(t('utils.formValidation.types.date'))),
        [formNames.cardNumber]: Yup.string().nullable().required(ValidateMessages.required),
    });
}

const RegistrationViewPaymentInfoSection = () => {
    const [registrationState, registrationReducer] = useContext(RegistrationContext);
    const [registering, setRegistering] = useState(false);
    const [focused, _setFocused] = useState();
    const {t} = useTranslation();
    const schema = createSchema(t);
    const validate = makeValidate(schema);
    const required = makeRequired(schema);
    const translations = t('views.public.registration.paymentInfo.form', {returnObjects: true});

    /**
     * Submits the values of this form.
     *
     * calls the api of registration if there is no error in the form.
     * @param {any} values
     * @param {any} form
     * @param {function(errors:any): void} errorCallback
     */
    const onSubmit = async (values, form, errorCallback) => {
        const registrationErrors = await registerUser(values);
        const hasError = !!registrationErrors?.length;
        if (hasError) {
            //TODO: set the errors for each field
        }

    }

    /**
     * Registers a new user and returns any errors associated with the process.
     *
     * @param {any} paymentInfo the paymentInfo values for registration
     */
    const registerUser = (paymentInfo) => {
        setRegistering(true);
        const forApi = {
            userInfo: {
                ...registrationState[registrationStepperStepIds.userInfo]
            },
            businessInfo: {
                ...registrationState[registrationStepperStepIds.businessInfo]
            },
            selectedPlan: {
                ...registrationState[registrationStepperStepIds.planSelection]
            },
            paymentInfo: {
                ...paymentInfo,
            }
        };
        const response = {resultFlag: true,};
        //TODO: call api for registering the user.
        registrationReducer({
            type: RegistrationReducerActions.submitStep,
            payload: {
                stepName: registrationStepperStepIds.paymentInfo,
                paymentInfo,
                submitted: true,
                valid: response.resultFlag,
            }
        });
        if (response.resultFlag) {
            // use timeout since the useEffect for blockNavigation must be called first
            setTimeout(() => {
                //TODO: navigate to the panel

            }, 200);
            return;
        }
        // the list of errors associated with the errors
        return response.errors;
    }

    /**
     * Sets the focused ref value to the given event's target name
     * @param {MouseEvent} e
     */
    const setFocused = (e) => {
        _setFocused(e.target.name);
    }


    return (
        <>
            <Form
                onSubmit={onSubmit}
                className={'row payment-info'}
                validate={validate}
                initialValues={registrationState[registrationStepperStepIds.userInfo]}
                render={({values}) => {
                    const colClassName = 'px-4 mb-4 mt-2';
                    return (
                        <>
                            {/*Card*/}
                            <Col xs={12} md={5} className={'order-1 order-md-2 mb-0 flex-row-center mb-sm-5'}>
                                <ReactCreditCards
                                    cvc={values[formNames.cvc] ?? '***'}
                                    expiry={
                                        values[formNames.expiry] && values[formNames.expiry] !== 'Invalid Date'
                                            ? moment(values[formNames.expiry]).format('MM/yy')
                                            : '**/**'
                                    }
                                    name={values[formNames.holderName] ?? t('views.public.registration.paymentInfo.creditCard.namePlaceHolder')}
                                    number={values[formNames.cardNumber] ?? ''}
                                    focused={focused}
                                />
                            </Col>
                            <Col xs={12} md={7} className={'order-2 order-md-1 flex-row-center mb-5'}>
                                <div className={'registration-form-card'}>
                                    <Col xs={12} className={'mb-4 flex-row-center'}>
                                        <p className={'title'}>
                                            {t('views.public.registration.steps.paymentInfo')}
                                        </p>
                                    </Col>
                                    <Row>
                                        <Col xs={12} className={classnames(colClassName)}>
                                            <FormInput
                                                placeholder={translations.cardNumber.placeholder}
                                                label={translations.cardNumber.title}
                                                name={formNames.cardNumber}
                                                required={required[formNames.cardNumber]}
                                                onFocus={setFocused}
                                                inputProps={{maxLength: 19}}
                                            />
                                        </Col>
                                        <Col xs={12} className={classnames(colClassName)}>
                                            <FormInput
                                                placeholder={translations.holderName.placeholder}
                                                label={translations.holderName.title}
                                                name={formNames.holderName}
                                                required={required[formNames.holderName]}
                                                onFocus={setFocused}
                                            />
                                        </Col>
                                        <Col xs={12} sm={6} md={12} lg={6} className={classnames(colClassName)}>
                                            <FormKeyboardDatePicker
                                                insidePanel={false}
                                                views={['month', 'year']}
                                                format={'MM/yy'}
                                                label={translations.expiry.title}
                                                name={formNames.expiry}
                                                placeholder={translations.expiry.placeholder}
                                                required={required[formNames.expiry]}
                                                onFocus={setFocused}
                                                className={'expiry-date'}
                                            />
                                        </Col>
                                        <Col xs={12} sm={6} md={12} lg={6} className={classnames(colClassName)}>
                                            <FormInput
                                                placeholder={translations.cvc.placeholder}
                                                label={translations.cvc.title}
                                                name={formNames.cvc}
                                                required={required[formNames.cvc]}
                                                onFocus={setFocused}
                                                inputProps={{maxLength: 4}}
                                            />
                                        </Col>
                                        <Col xs={12} className={'flex-row-center mt-4 mb-2'}>
                                            <button
                                                className={'button secondary px-8 py-2'}
                                                disabled={registering}
                                                type={'submit'}>
                                                {
                                                    registering
                                                        ? translations.button.registering
                                                        : translations.button.text
                                                }
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>

                        </>
                    );
                }}
            />
        </>
    );
}
export default RegistrationViewPaymentInfoSection;
