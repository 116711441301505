import React from 'react';

const BarChartLegends = ({legends}) => {


    return (
        <div className='legends-container text-start'>
            <ul className='legends'>
                {legends?.map(legend => (
                    <li
                        key={legend.id}
                        style={{'--bullet_color': `${legend.color}`}}>
                        <div className='d-flex justify-content-start align-items-center'>
                            <div className='bullet'/>
                            <div className='ps-2'>
                                {legend.title}
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default BarChartLegends;
