import React, {useEffect, useRef, useState} from "react";
import {readFile} from "../../../../core/services/utils";
import useIsMounted from "../../../hooks/use-is-mounted";
import {InputFileAcceptMimes, InputFileAcceptTypes, ReadingFileAs} from "../../../../core/constants/enums";
import accepts from "attr-accept";
import UploadButton from "../button/upload-button";
import classnames from "classnames";


const DropZone = ({
                      error = false,
                      disabled,
                      accept = Object.values(InputFileAcceptTypes),
                      id,
                      setFiles,
                      multiple = false,
                      children,
                      setParsedImages,
                      loading,
                      setLoading,
                      className,
                      identifier = '',
                      openOnClick = false,
                      uploading,
                      dropZoneClassName,
                      labelClassName,
                      noParse = false,
                      dragging = false,
                      endDragging,
                      notAcceptedDurationInMs = 1000,
                  }) => {
    const [highlight, setHighlight] = useState(false);
    const [notAccepted, setNotAccepted] = useState(false);
    const inputRef = useRef();
    const isMounted = useIsMounted();

    /**
     * Listens for the changes in notAccepted and with each change:
     * if the component was in the not-accepted format, then clears the format after a second.
     */
    useEffect(() => {
        let timer;
        if (!notAccepted) {
            if (timer) return () => clearTimeout(timer);
            return;
        }
        timer = setTimeout(() => {
            if (!isMounted()) return;
            setNotAccepted(false)
        }, notAcceptedDurationInMs)
        return () => clearTimeout(timer)
    }, [notAccepted])

    /**
     * Opens the file dialog from the system.
     */
    const openFileDialog = () => {
        if (disabled || loading) return;
        inputRef?.current?.click()
    }

    /**
     * Highlights the dropzone if dragged over it.
     * @param e {DragEvent}
     */
    const onDragEnter = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (disabled || loading) return;
        if (!highlight) setHighlight(true);
    }

    /**
     * Un-Highlights the dropzone if leaves the draggable area it.
     * @param e {DragEvent}
     */
    const onDragLeave = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (highlight) setHighlight(false);
    }

    /**
     * Gets the dropped files and then un-highlights the drop-zone and calls onFilesSelected with the returned array
     * from fileListToArray function.
     * @param e {DragEvent}
     */
    const onDrop = (e) => {
        e.preventDefault();
        if (disabled || loading || !e.dataTransfer?.files?.length) return;
        const files = fileListToArray(e.dataTransfer.files);
        setNotAccepted(!files?.length);
        if (endDragging) endDragging();
        onDragLeave(e);
        onFilesSelected(files).then();
    }


    /**
     * For every selected file, if the file has the accepted types, returns the files array.
     * if no multiple, then only returns the first selected file.
     *
     * @param files {FileList}
     * @return {FileList}
     */
    const fileListToArray = (files) => {
        const dataTransfer = new DataTransfer();
        for (let index = 0; index < files.length; index++) {
            if (!accepts(files.item(index), accept)) continue;
            if (multiple) {
                dataTransfer.items.add(files.item(index));
            } else {
                dataTransfer.items.add(files.item(index));
                break;
            }
        }
        return dataTransfer.files;
    }

    /**
     * For each of the files, converts them to their base 64 string and then sends the converted array of
     * potentially filled as the argument of setFiles callback.
     * @param files {FileList}
     */
    const onFilesSelected = async (files) => {
        if (disabled || loading || files?.length <= 0) return;
        if (setLoading) setLoading(true);
        const {parsedImages, parsedFiles} = await readFiles(files);
        if (setLoading) setLoading(false);
        if (setParsedImages && parsedImages?.length) setParsedImages(parsedImages);
        if (!setFiles) return;
        if (noParse) {
            setFiles(files);
        } else {
            setFiles(parsedFiles?.filter(e => e.length));
        }
    }

    /**
     * Reads the given list of files and parses them into parsed data url files or texts if their type is not of
     * type image.
     * @param {FileList} files
     * @return {Promise<{parsedImages: *[], parsedFiles: *[]}|{}>}
     */
    const readFiles = async (files) => {
        const parsedImages = [];
        const parsedFiles = [];
        for (let index = 0; index < files.length; index++) {
            if (!accepts(files.item(index), InputFileAcceptMimes.images)) {
                const text = await readFile(files.item(index), ReadingFileAs.text);
                parsedFiles.push(text);
            } else {
                const dataUrl = await readFile(files.item(index), ReadingFileAs.dataUrl);
                parsedImages.push(dataUrl);
                parsedFiles.push(dataUrl.split(',')[1]);
            }
            if (!multiple) break;
        }
        if (!isMounted()) return {};
        return {parsedImages, parsedFiles};
    }


    return (
        <div
            className={classnames('drop-zone droppable', {
                'high-light': highlight,
                'error': error,
                'dragging': dragging,
                'cursor-progress-hover': uploading,
                'cursor-not-allowed-hover': disabled || loading,
                'cursor-pointer-hover': !(disabled || loading),
                'not-accepted': notAccepted,
                [dropZoneClassName]: dropZoneClassName?.length,
                [identifier]: identifier?.length,
            })}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDragOver={e => e.preventDefault()}
            onDrop={onDrop}
            onClick={() => openOnClick && openFileDialog()}
        >
            {
                openOnClick
                    ? (
                        <UploadButton
                            labelClassName={labelClassName}
                            disabled={disabled || loading}
                            accept={accept}
                            ref={inputRef}
                            multiple={multiple}
                            id={`upload-button-${id}`}
                            onFileSelect={onFilesSelected}
                            className={className}
                        >
                            {
                                typeof children === 'function'
                                    ? children(openFileDialog, highlight, notAccepted)
                                    : children
                            }
                        </UploadButton>
                    )
                    : typeof children === 'function'
                        ? children(openFileDialog, highlight, notAccepted)
                        : children
            }

        </div>
    )
}


export default DropZone;
