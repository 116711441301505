import React, {useLayoutEffect, useRef, useState} from "react";
import classnames from "classnames";
import {numComparator} from "../../../../core/services/utils";
import {Fade} from "@material-ui/core";


// The amount of line to be reduced
const StepperLineReducer = 2;

/**
 * @param {{icon: JSX.Element, label: any, labelPrefix: any,index: number}[]} steps
 * @param {number} activeStepIndex
 * @param {string} className
 * @param {string} id
 */
const Stepper = ({steps, activeStepIndex, className, id}) => {

    return (
        <>
            <div className={classnames('stepper-container', className)} id={id}>
                <div className={'stepper'}>
                    {
                        steps?.sort((a, b) => numComparator(a.index, b.index))?.map((step, index) => {
                            switch (index) {
                                case 0:
                                    return (
                                        <StepperStep
                                            key={index}
                                            step={step}
                                            hasLine={false}
                                            isActive={activeStepIndex >= step.index}
                                            clickable={activeStepIndex > step.index}
                                        />
                                    );
                                default:
                                    return (
                                        <StepperStep
                                            key={index}
                                            step={step}
                                            hasLine={true}
                                            isActive={activeStepIndex >= step.index}
                                            clickable={activeStepIndex > step.index}
                                        />
                                    );
                            }
                        })
                    }
                </div>

            </div>
        </>
    )
}

const StepperStep = ({step, isActive, clickable, hasLine = false}) => {
    const [labelWidth, setLabelWidth] = useState(0);
    const [labelMargin, setLabelMargin] = useState(0);
    /**@type {React.MutableRefObject<HTMLElement>}*/
    const lineRef = useRef();
    /**@type {React.MutableRefObject<HTMLElement>}*/
    const iconRef = useRef();

    /**
     * As soon as the values of lineRef and iconRef are available:
     * Calculates the width and the margin of the stepLabel and sets their states
     */
    useLayoutEffect(() => {
        if (!lineRef.current || !iconRef.current) return;
        const lineWidth = lineRef.current.getBoundingClientRect()?.width / (hasLine ? 2 : 1);
        const iconWidth = iconRef.current.getBoundingClientRect()?.width;
        setLabelMargin(lineWidth);
        setLabelWidth(iconWidth + (StepperLineReducer * lineWidth) + 2);
    }, [lineRef.current, iconRef.current])

    return (
        <>
            <div
                className={classnames('step-container', {'active': isActive, 'clickable': clickable})}
                id={step.id}
                onClick={() => clickable && step.onClick(step)}
            >
                <div ref={lineRef} className={classnames('stepper-line', {'no-line': !hasLine})}/>
                <div className={'step'}>
                    <div ref={iconRef}>
                        {
                            React.cloneElement(
                                step.icon,
                                {className: classnames('step-icon')}
                            )
                        }
                    </div>
                    <div className={classnames('step-label')} style={{width: labelWidth, marginLeft: -labelMargin}}>
                        <p>
                            <Fade in={!!step.labelPrefix}>
                                <span>
                                  {step.labelPrefix}
                                </span>
                            </Fade>

                            {step.label}
                        </p>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Stepper;
