import React, {useLayoutEffect, useRef, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {ReactComponent as MainImage} from "../../../../../../../assets/images/views/public-views/landing/hero-img.svg";
import {Link} from "react-router-dom";
import {ReactComponent as AppleLogo} from "../../../../../../../assets/images/views/public-views/landing/apple-logo.svg";
import {ReactComponent as AndroidLogo} from "../../../../../../../assets/images/views/public-views/landing/android-logo.svg";
import {ReactComponent as ScrollDownBox} from "../../../../../../../assets/images/views/public-views/landing/scroll-down-box.svg";
import {ReactComponent as ScrollDownBoxDot} from "../../../../../../../assets/images/views/public-views/landing/scroll-drop-down-dot.svg";
import {landingViewScrollAnimationOptions} from "../../index";
import {useTranslation} from "react-i18next";
import {routeFunctions} from "../../../../../../routes";
import useRouter from "../../../../../../hooks/use-router";

const LandingViewHeroSection = ({scrollToSection, sections}) => {
    const {history, params} = useRouter();
    const {t} = useTranslation();
    const [mainImageHeight, setMainImageHeight] = useState(0);
    /**@type {React.MutableRefObject<HTMLElement>}*/
    const mainImageRef = useRef();

    /**
     * Listens to the changes in the mainImage svg and with each change:
     * Sets the height of the containing element so that it is in sync with the svg.
     */
    useLayoutEffect(() => {
        if (!mainImageRef?.current) return;
        const newHeight = mainImageRef.current.getBoundingClientRect().height;
        if (newHeight !== mainImageHeight) {
            setMainImageHeight(newHeight);
        }
    }, [mainImageRef.current])

    /**
     * Navigates the user to the registration view
     */
    const navigateToRegistration = () => {
        history.push(routeFunctions.public.register(params.language));
    }

    /**
     * Navigates the user to the BizCore for authentication
     */
    const navigateToLogin = () => {
        if (window.bizpire?.navigateToCoreLogin) {
            window.bizpire?.navigateToCoreLogin();
        }
    }

    return (
        <>
            <Container data-aos={landingViewScrollAnimationOptions.fadeUpAnimation}>
                <Row>
                    <Col xs={12} md={5} className='hero-text mt-5 mt-md-10'>
                        <div className='w-100'>
                            <h2 className='title text-center text-md-start'>
                                {t('views.public.landing.hero.title.black.line1')}
                                <br/>
                                {t('views.public.landing.hero.title.black.line2')}
                                <br/>
                                <span className='primary-color mx-2'>
                                    {t('views.public.landing.hero.title.blue')}
                                    </span>
                            </h2>
                            <div
                                className='d-flex justify-content-center justify-content-md-start align-content-center flex-wrap mt-3'>
                                <button className={'button secondary register'} onClick={navigateToRegistration}>
                                    {t('views.public.landing.hero.actions.register')}
                                </button>
                                <div className={'mx-2'}/>
                                <button className={'button secondary login'} onClick={navigateToLogin}>
                                    {t('views.public.landing.hero.actions.login')}
                                </button>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={7} className='hero-img mt-5 mt-md-6' style={{minHeight: mainImageHeight ?? 0}}>
                        <div className={'main-image-container'}>
                            <MainImage className={'main-image'} ref={mainImageRef}/>
                        </div>
                        <div className='app-container w-100 px-3 px-lg-5 mx-0 mx-md-2 mx-lg-4'>
                            <p>
                                {t('views.public.landing.hero.apps')}
                            </p>
                            <div className='d-flex justify-content-start mt-3'>
                                    <span className='me-3 app'>
                                        <Link to='/'>
                                            <AppleLogo/>
                                        </Link>
                                    </span>
                                <span className='ms-3 app'>
                                        <Link to='/'>
                                            <AndroidLogo/>
                                        </Link>
                                    </span>
                            </div>
                        </div>
                    </Col>
                </Row>
                {/*Scroll Down SVG*/}
                <div className='text-center py-3'>
                    <div className='scroll-down-box'
                         onClick={() => scrollToSection(sections.features.hash)}
                    >
                        <ScrollDownBox className={'position-absolute'}/>
                        <ScrollDownBoxDot className={'position-absolute scroll-down-dot'}/>
                        <div className='position-absolute text-center mt-5 w-100'>
                            <p className='ps-3'>
                                {t('views.public.landing.hero.scrollDown')}
                            </p>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default LandingViewHeroSection;
