import React, {useEffect} from "react";
import routes from "../../../routes";
import useRouter from "../../../hooks/use-router";
import {matchPath} from "react-router";
import {useTranslation} from "react-i18next";
import {ReactComponent as AccessDeniedIcon} from "../../../../assets/images/views/error/access-denied.svg";
import {Col, Container, Row} from "react-bootstrap";


const AccessDenied = () => {
    const {history, location} = useRouter();
    const {t} = useTranslation();

    /**
     * checks the pathname of the url based on accessDeniedForSection route,
     * no prefix => change the url to access denied.
     */
    useEffect(() => {
        if (!!matchPath(location.pathname, {path: routes.error.accessDeniedForSection, exact: true})) {
            history.replace(routes.error.accessDenied);
        }
    }, [location.pathname])


    return (
        <>
            <div className={'svg-container'}>
                <AccessDeniedIcon/>
            </div>
            <Container>
                <Row>
                    <Col xs={12} className={'flex-row-center'}>
                        <p className={'message'}>
                            {t('views.errors.accessDenied.message')}
                        </p>
                    </Col>
                    <Col xs={12} className={'flex-row-center mt-3'}>
                        <button
                            className={'button primary px-5'}
                            onClick={history.goBack}>
                            {t('views.errors.accessDenied.actionButton')}
                        </button>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default AccessDenied;
