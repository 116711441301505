import React from 'react';
import classnames from "classnames";


const BarChartDataset = ({dataset, getSingleBarColor, xAxisLabel, maxVal, index}) => {

    /**
     * Calculates the height of the bar in the chart
     * @param {number} val
     * @returns {number}
     */
    const calculateHeight = (val) => {
        return ((val / maxVal).toFixed(2)) * 100;
    }

    return (
        <div className='dataset-wrapper'>
            <div className={classnames('dataset', {
                'px-5': ![0, 1].includes(index),
                'pe-5 ps-2': index === 1,
                'x-axis': index === 0
            })}>
                {dataset?.data?.map((data) => (
                    <div
                        className='bar-container'
                        key={data.legendId}>
                        <div className='font-weight-bold text-center'>
                            {data.total}
                        </div>
                        <div
                            className='bar'
                            style={{
                                '--bar_height': `${calculateHeight(data.total)}%`,
                                '--bar_color': `${getSingleBarColor(data.legendId)}`
                            }}
                        />
                    </div>
                ))}
            </div>
            {
                index === 0
                    ? (
                        <div className='x-axis-label'>
                            <p className='w-100'>
                                {xAxisLabel}
                            </p>
                        </div>
                    )
                    : (
                        <div className='py-2 text-center'>
                            <hr className='w-100'/>
                            <span className='bar-label'>{dataset?.code ?? ''}</span>
                        </div>
                    )
            }
        </div>
    )
};

export default BarChartDataset;
