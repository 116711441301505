import React, {useMemo, useRef, useState} from "react";
import OwlCarousel from "react-owl-carousel";
import LazyImage from "../../base/lazy-image";
import {ReactComponent as CrossRounded} from "../../../../assets/images/cross-rounded.svg";
import {ReactComponent as LeftChevron} from "../../../../assets/images/image-slide-chevrons/left-chevron.svg";
import {ReactComponent as RightChevron} from "../../../../assets/images/image-slide-chevrons/right-chevron.svg";

const ImageSlider = ({images, onImageRemoved, onImageClicked}) => {
    /**@type {React.MutableRefObject<import('react-owl-carousel').default>}*/
    const carouselRef = useRef();
    const [hasNext, setHasNext] = useState(false);
    const [hasPrev, setHasPrev] = useState(false);

    /**
     * Changes the carousel item based on the offset:
     *
     *  If the offset is greater than zero shifts it the right
     *  Otherwise, shifts it to the left
     *
     * @param {number} offset
     */
    const changeSlide = (offset) => {
        if (offset > 0) {
            carouselRef.current.next(250);
            return;
        }
        carouselRef.current.prev(250);
    }

    /**
     * Changes the values of [hasNext] and [hasPrev] depending on the current state of the owl
     * carousel.
     * @param {Event} e
     */
    const onOwlCarouselChanged = (e) => {
        if (e?.item?.index === null) return;

        const pageInfo = e?.page ?? {};
        const itemsInfo = e?.item ?? {};
        const hasNext = (itemsInfo.index + pageInfo.size) < itemsInfo.count;
        const hasPrev = itemsInfo.index > 0;
        setHasNext(hasNext);
        setHasPrev(hasPrev)
    }

    const owlCarousel = useMemo(() => {
        return (
            <OwlCarousel
                autoplay={false}
                loop={false}
                items={3}
                center={false}
                onChanged={onOwlCarouselChanged}
                ref={carouselRef}
            >
                {images?.map((image, index) => (
                        <div
                            key={image?.id}
                            className={'image-class'}>
                            <div onClick={() => onImageClicked(index)}>
                                <LazyImage src={image.src}/>
                            </div>
                            <CrossRounded className={'cross-icon-rounded'} onClick={() => onImageRemoved(index)}/>
                        </div>
                    )
                )}
            </OwlCarousel>
        );
    }, [images]);

    return (
        <div className={'d-flex flex-row justify-content-center align-items-center image-gallery-bottom-section w-100'}>
            {hasPrev && <LeftChevron onClick={() => changeSlide(-1)}/>}
            <div className={'bottom-image-slide'}>
                {owlCarousel}
            </div>
            {hasNext && <RightChevron onClick={() => changeSlide(1)}/>}
        </div>
    );
}

export default ImageSlider;
