/**
 * The invoices view's tab values used to determine the date filtering in the form.
 */
export const InvoicesTabValues = {
    thisMonth: 'this-month',
    thisYear: 'this-year',
    all: 'all',
    custom: 'custom',
}


/**
 * The Settings view's tab values used to determine view of the settings the user wishes to preview or edit.
 */
export const SettingsTabValues = {
    general: 'general',
    spire: 'spire',
    company: 'company',
}

/**
 * The products list tab values used to determine the type of item searching.
 */
export const ItemsListTabValues = {
    products: 'products',
    instances: 'instances',
}

/**
 * The spire counting information tab values
 */
export const SpireCountingTabValues = {
    information: 'information',
    items: 'items',
    participant: 'participant',
    reports: 'reports'
}

/**
 * The position counting information tab values
 */
export const PositionCountingTabValues = {
    information: 'information',
    items: 'items',
    reports: 'reports'
}


