import React, {useLayoutEffect, useState} from "react";
import {abbreviateNumber} from "../../../../core/services/utils";
import classnames from "classnames";

const DoughnutChart = ({data, width = 300, loading}) => {
    const [percentage, setPercentage] = useState(0);

    /**
     * Listens to the changes in data and with each change, sets the current active percentage of the chart
     */
    useLayoutEffect(() => {
        setPercentage(calculateActivePercentage());
    }, [data])

    /**
     * Calculates the active percentage of the chart based on the provided data.
     * @return {number}
     */
    const calculateActivePercentage = () => {
        return parseFloat((data.open.total / (data.open.total + data.close.total)).toFixed(2)) * 100;
    };

    return (
        <>
            {
                loading ? (
                        <div className={classnames('doughnut-chart-layout loading')}>
                            <p className={'title'}>
                                <div/>
                            </p>
                            <div className='doughnut-chart-container'
                                 style={{
                                     '--width': `${width}px`,
                                     '--height': `${width / 2}px`,
                                 }}>
                                <div className='doughnut-chart'
                                     style={{
                                         '--percentage': `${percentage}`,
                                         '--width_chart': `${width * 9 / 10}px`,
                                     }}>
                                    <div/>
                                </div>
                            </div>
                        </div>
                    )
                    : (
                        <div className={classnames('doughnut-chart-layout')}>
                            <p className={'title'}>
                                {data.title ?? ''}
                            </p>
                            <div className='doughnut-chart-container'
                                 style={{
                                     '--width': `${width}px`,
                                     '--height': `${width / 2}px`,
                                 }}>
                                <div className='doughnut-chart'
                                     style={{
                                         '--percentage': `${percentage}`,
                                         '--width_chart': `${width * 9 / 10}px`,
                                     }}>
                                    <div className='active'>
                                        <div className='chart-data d-flex justify-content-center align-items-start'>
                            <span className='open'>
                                {abbreviateNumber(data.open.total ?? 0)}
                                <br/>
                                <span className='text'>
                                    {data.open.title ?? ''}
                                </span>
                            </span>
                                            <span className='font-weight-bold'>
                            &nbsp;/&nbsp;
                        </span>
                                            <span className='close'>
                            {abbreviateNumber(data.close.total ?? 0)}
                                                <br/>
                            <span className='text'>
                                {data.close.title ?? ''}
                            </span>
                        </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )

            }
        </>

    )
}

export default DoughnutChart;
