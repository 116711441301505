import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import useRouter from "../../../../../hooks/use-router";
import {useTranslation} from "react-i18next";
import {DefaultInitialPaginationInfo, TableCellAlignments, TableCellTypes} from "../../../../../../core/constants/enums";
import {ReactComponent as BarcodeIcon} from "../../../../../../assets/images/barcode-icon.svg";
import NoEntries from "../../../../../components/app-specific/no-entries";
import Table from "../../../../../containers/table";
import OrderListSearchSection from "../../../../../components/search-sections/orders";
import {routeFunctions} from "../../../../../routes";
import {dialogActions} from "../../../../../../redux/entities/dialog-slice";
import {useDispatch} from "react-redux";
import useIsMounted from "../../../../../hooks/use-is-mounted";
import {CompanyContext} from "../../../../../contexts/company";
import useSearchData from "../../../../../hooks/use-search-data";
import {SalesOrderListQueryParams} from "../../../../../../core/constants/query-params";
import QueryManagementUtils from "../../../../../../core/services/query-management-utils";


const initialPaginationInfo = {
    ...DefaultInitialPaginationInfo,
    pageSize: 10,
}

const searchStatus = [
    {
        id: 1,
        title: 'Active'
    },
    {
        id: 2,
        title: 'DeActive'
    }
]

// Keys for the users table
const tableCellKeys = {
    code: 'code',
    customerName: 'customerName',
    submittedDate: 'submittedDate',
    requiredDate: 'requiredDate',
    totalProducts: 'totalProducts',
    totalItems: 'totalItems',
    status: 'status'
}

const SalesOrderListView = () => {
    const {query, location, history, stringifyUrl, params} = useRouter();
    const [company] = useContext(CompanyContext);
    const [loading, setLoading] = useState(true);
    const [salesOrders, setSalesOrders] = useState([]);
    const [statuses, setStatuses] = useState(searchStatus);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const isMounted = useIsMounted();
    const selectedCompany = useRef(company);

    const translation = t("views.panel.company.salesOrders.list", {returnObjects: true});


    /**
     * With each change in the selected company:
     */
    useEffect(() => {
        if (selectedCompany.current?.id === company?.id)
            return;
        selectedCompany.current = company;
        //   TODO: implement the action related to this
    }, [company?.id])

    /**
     * Gets the list of sales orders from the server:
     * - Calls the api regarding this action
     */
    const search = async () => {
        await delay(2000);
        const forApi = prepareApiRequest();
        console.log(forApi);
        const response = {
            resultFlag: true,
            data: {
                items: [
                    {
                        id: 1,
                        code: "Q000123-0",
                        customerName: "Testing",
                        submittedDate: new Date(),
                        requiredDate: new Date(),
                        totalProducts: 10,
                        totalItems: 50,
                        status: {
                            id: 1,
                            title: "Open"
                        }
                    },
                    {
                        id: 2,
                        code: "Q000123-0",
                        customerName: "Testing",
                        submittedDate: new Date(),
                        requiredDate: new Date(),
                        totalProducts: 10,
                        totalItems: 50,
                        status: {
                            id: 2,
                            title: "Close"
                        }
                    },
                    {
                        id: 3,
                        code: "Q000123-0",
                        customerName: "Testing",
                        submittedDate: new Date(),
                        requiredDate: new Date(),
                        totalProducts: 10,
                        totalItems: 50,
                        status: {
                            id: 1,
                            title: "Open"
                        }
                    },
                    {
                        id: 4,
                        code: "Q000123-0",
                        customerName: "Testing",
                        submittedDate: new Date(),
                        requiredDate: new Date(),
                        totalProducts: 10,
                        totalItems: 50,
                        status: {
                            id: 2,
                            title: "Close"
                        }
                    },
                    {
                        id: 5,
                        code: "Q000123-0",
                        customerName: "Testing",
                        submittedDate: new Date(),
                        requiredDate: new Date(),
                        totalProducts: 10,
                        totalItems: 50,
                        status: {
                            id: 1,
                            title: "Open"
                        }
                    },
                ],
                paginationInfo: {
                    length: 5,
                }
            }
        }
        if (!isMounted())
            return;
        if (response?.resultFlag) {
            setSalesOrders(response?.data?.items);
            const newPaginationInfo = {
                ...paginationInfo,
                length: response?.data.paginationInfo.length ?? 0
            }
            QueryManagementUtils.setPaginationInfo(newPaginationInfo, query, history, location);
        }
        setLoading(false);
    }

    const {filters, orderBy, paginationInfo} = useSearchData(
        SalesOrderListQueryParams,
        search,
        {
            useFilters: true,
            initialFilters: {},
            filtersQueryParamMap: {
                keyword: SalesOrderListQueryParams.keyword,
                status: SalesOrderListQueryParams.status,
                fromDate: SalesOrderListQueryParams.fromDate,
                toDate: SalesOrderListQueryParams.toDate
            },
            usePaginationInfo: true,
            initialPaginationInfo: initialPaginationInfo,

            useOrderBy: true,
            initialOrderBy: undefined,
        }
    );

    /**
     * Prepares the search api request DS
     * @return {{orderBy: unknown, paginationInfo: {length: number, pageSize: number, currentPage: number}, filters: {}}}
     */
    const prepareApiRequest = () => {
        return {
            paginationInfo: paginationInfo,
            filters: filters,
            orderBy: orderBy,
        }
    }

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    /**
     * Clears the form values
     */
    const clearFilters = () => {
        history.push(stringifyUrl({
            url: location.pathname,
            query: {}
        }));
    }

    /**
     * Handles click on the barcode icon button:
     * - Opens dialog for showing the barcode
     * @param {string} code for generating barcode from
     */
    const onBarcodeClicked = (code) => {
        dispatch(dialogActions.configBarcodeDialog({
            open: true,
            onClose: () => dispatch(dialogActions.barcodeDialogToggle(false)),
            barcodeCode: code,
        }));
    }

    /**
     * Handles click on the sales order's order number:
     * - Navigates user to sales order single view
     * @param salesOrder
     */
    const onSalesOrderSelected = (salesOrder) => {
        history.push(routeFunctions.panel.company.salesOrders.single(params.company, salesOrder.id));
    }

    /**
     * Memo version of table columns
     * @type {object[]}
     */
    const tableColumns = useMemo(() => [
        {
            title: translation.tableHeaders.code ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: tableCellKeys.code,
            sortable: true
        },
        {
            title: translation.tableHeaders.customerName ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: tableCellKeys.customerName,
            size: 1.2,
            sortable: true
        },
        {
            title: translation.tableHeaders.submittedDate ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.date,
            name: tableCellKeys.submittedDate,
            size: 1.2,
            sortable: true
        },
        {
            title: translation.tableHeaders.requiredDate ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.date,
            name: tableCellKeys.requiredDate,
            size: 1.2,
            sortable: true
        },
        {
            title: translation.tableHeaders.totalProducts ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.string,
            name: tableCellKeys.totalProducts,
            size: 1.2,
            sortable: true
        },
        {
            title: translation.tableHeaders.totalItems ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.string,
            name: tableCellKeys.totalItems,
            sortable: true
        },
        {
            title: translation.tableHeaders.status ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: tableCellKeys.status,
            sortable: true
        },
    ], [])

    /**
     * Memo version of table rows records
     * @type {object[]}
     */
    const tableRows = useMemo(() => {
        return salesOrders?.map(salesOrder => ({
            key: salesOrder?.id,
            [tableCellKeys.code]: () => (
                <div className={'d-flex flex-row align-items-center'}>
                    {
                        salesOrder?.code?.length > 0 &&
                        <div
                            onClick={() => onBarcodeClicked(salesOrder?.code)}
                            className={'barcode-icon-button'}
                        >
                            <BarcodeIcon/>
                        </div>
                    }
                    <p
                        className={'text-button'}
                        onClick={() => onSalesOrderSelected(salesOrder)}
                    >
                        {salesOrder.code}
                    </p>
                </div>
            ),
            [tableCellKeys.customerName]: salesOrder?.customerName,
            [tableCellKeys.submittedDate]: salesOrder?.submittedDate,
            [tableCellKeys.requiredDate]: salesOrder?.requiredDate,
            [tableCellKeys.totalProducts]: salesOrder?.totalProducts,
            [tableCellKeys.totalItems]: salesOrder?.totalItems,
            [tableCellKeys.status]: salesOrder?.status?.title,
        }))
    }, [salesOrders])

    return (
        <>
            <div className={"sales-order-list"}>
                <div className={'panel-card p-0 pt-4'}>
                    <div className={'px-4 d-flex align-items-center justify-content-start'}>
                        <p className={'title'}>
                            {translation?.salesOrders ?? ''}
                        </p>
                    </div>
                    <OrderListSearchSection filters={filters} statuses={statuses}/>
                    <div className={'pb-5 px-4'}>
                        {
                            !!salesOrders?.length < 1 && !loading
                                ? <NoEntries
                                    text={
                                        Object.entries(filters ?? {})?.length > 0
                                            ? translation?.empty?.withFilter
                                            : translation?.empty?.text
                                    }
                                    includeButton={Object.entries(filters ?? {})?.length > 0}
                                    buttonProps={{
                                        onClick: clearFilters,
                                        className: 'button primary px-3'
                                    }}/>
                                : <Table
                                    loading={{state: loading, count: 5}}
                                    className={'my-3'}
                                    orderBy={orderBy}
                                    color={'primary'}
                                    onSort={(orderBy) => QueryManagementUtils.setOrderBy(
                                        orderBy,
                                        query,
                                        history,
                                        location,
                                    )}
                                    cells={tableColumns}
                                    data={tableRows}
                                    paginationInfo={paginationInfo}
                                    onPageSizeChange={(p, i) => QueryManagementUtils.setPaginationInfo(
                                        {...p, pageSize: i},
                                        query,
                                        history,
                                        location
                                    )}
                                    onCurrentPageChange={(p, newP) => QueryManagementUtils.setPaginationInfo(
                                        {...p, currentPage: newP},
                                        query,
                                        history,
                                        location,
                                    )}
                                />
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default SalesOrderListView;
