import {flattenObjectAndReturnAsAList} from "../../core/services/utils";
import {generatePath} from "react-router";

/**
 * All the route names of the application
 */
export const routes = {
    landing: '/',
    public: {
        landing: '/landing/:language?',
        register: '/register/:language?',
    },
    panel: {
        base: '/panel',
        setup: '/panel/setup',
        versions: '/panel/version-history',
        editProfile: '/panel/edit-profile',
        auth: {
            login: '/panel/login/:token?/:time?',
            loginWithoutParams: '/panel/login',
            logout: '/panel/logout',
        },
        settings: '/panel/settings',
        company: {
            base: '/panel/:company',
            dashboard: '/panel/:company/dashboard',
            users: {
                base: '/panel/:company/users',
                list: '/panel/:company/users/list',
                groups: '/panel/:company/users/groups',
            },
            inventorySchema: {
                edit: '/panel/:company/inventory-schema/edit/:id',
                view: '/panel/:company/inventory-schema/view-warehouses',
            },
            counting: {
                base: '/panel/:company/counting',
                list: {
                    spireCountings: '/panel/:company/counting/spire/list',
                    positionCountings: '/panel/:company/counting/position/list',
                },
                single: {
                    spireCounting: '/panel/:company/counting/spire/:spireCountingId',
                    positionCounting: '/panel/:company/counting/position/:positionCountingId',
                }
            },
            salesOrders: {
                base: '/panel/:company/sales-order',
                list: {
                    salesOrders: '/panel/:company/sales-order/list',
                },
                single: {
                    salesOrder: '/panel/:company/sales-order/:salesOrderId',
                }
            },
            purchaseOrders: {
                base: '/panel/:company/purchase-order',
                list: {
                    purchaseOrder: '/panel/:company/purchase-order/list',
                },
                single: {
                    purchaseOrder: '/panel/:company/purchase-order/:purchaseOrderId',
                }
            },
            items: {
                base: '/panel/:company/products',
                list: {
                    products: '/panel/:company/products/parts/list',
                    instances: '/panel/:company/products/items/list',
                },
                single: {
                    product: '/panel/:company/products/parts/:id',
                    instance: '/panel/:company/products/items/:id',
                }
            },
            location: {
                base: '/panel/:company/location',
                warehouses: '/panel/:company/location/warehouses',
                aisles: '/panel/:company/location/:warehouse/aisles',
                sections: '/panel/:company/location/:warehouse/:aisle/sections',
                rows: '/panel/:company/location/:warehouse/:aisle/:section/rows',
                rowItems: '/panel/:company/location/:warehouse/:aisle/:section/:row/products',
            },
        },
        subscription: '/panel/subscription',
        paymentMethods: '/panel/payment-methods',
        invoices: '/panel/invoices',
    },
    error: {
        base: '/error',
        notFound: '/error/notFound',
        accessDenied: '/error/accessDenied',
        serverError: '/error/serverError',
        serverErrorForSection: '/error/serverErrorForSection',
        accessDeniedForSection: '/error/accessDeniedForSection',
    },
}

/**
 * All the routes with variable parts in form of functions
 */
export const routeFunctions = {
    public: {
        /**
         * @param  {string} language
         * @return {string}
         */
        landing: (language = undefined) => generatePath(routes.public.landing, {language: language}),
        /**
         * @param  {string} language
         * @return {string}
         */
        register: (language = undefined) => generatePath(routes.public.register, {language: language}),
    },
    panel: {
        auth: {
            /**
             * @param {string} token
             * @param {string} time
             * @return {string}
             */
            login: (token = undefined, time = undefined) => generatePath(routes.panel.auth.login, {
                token: token,
                time: time
            }),
        },
        company: {
            /**
             * @param  {string} company
             * @return {string}
             */
            dashboard: (company) => generatePath(routes.panel.company.dashboard, {company: company}),
            users: {
                /**
                 * @param  {string} company
                 * @return {string}
                 */
                base: (company) => generatePath(routes.panel.company.users.base, {company: company}),
                /**
                 * @param  {string} company
                 * @return {string}
                 */
                list: (company) => generatePath(routes.panel.company.users.list, {company: company}),
                /**
                 * @param  {string} company
                 * @return {string}
                 */
                groups: (company) => generatePath(routes.panel.company.users.groups, {company: company}),
            },
            inventorySchema: {
                /**
                 * @param  {string} company
                 * @param  {number} warehouseId
                 * @return {string}
                 */
                edit: (company, warehouseId) => generatePath(routes.panel.company.inventorySchema.edit, {
                    company: company,
                    id: warehouseId
                }),
                /**
                 * @param  {string} company
                 * @return {string}
                 */
                view: (company) => generatePath(routes.panel.company.inventorySchema.view, {company: company}),
            },
            counting: {
                /**
                 * @param  {string} company
                 * @return {string}
                 */
                base: (company) => generatePath(routes.panel.company.counting.base, {company: company}),
                /**
                 * @param  {string} company
                 * @return {string}
                 */
                spire: (company) => generatePath(routes.panel.company.counting.list.spireCountings, {company: company}),
                /**
                 * @param  {string} company
                 * @return {string}
                 */
                position: (company) => generatePath(routes.panel.company.counting.list.positionCountings, {company: company}),

                /**
                 * @param {string} company
                 * @param {number} spireCountingReqId
                 */
                singleSpire: (company, spireCountingReqId) => generatePath(routes.panel.company.counting.single.spireCounting, {
                    company: company,
                    spireCountingId: spireCountingReqId
                }),

                /**
                 * @param {string} company
                 * @param {number} positionCountingId
                 */
                singlePosition: (company, positionCountingId) => generatePath(routes.panel.company.counting.single.positionCounting, {
                    company: company,
                    positionCountingId: positionCountingId
                })
            },
            items: {
                /**
                 * @param  {string} company
                 * @return {string}
                 */
                base: (company) => generatePath(routes.panel.company.items.base, {company: company}),
                list: {
                    /**
                     * @param  {string} company
                     * @return {string}
                     */
                    products: (company) => generatePath(routes.panel.company.items.list.products, {
                        company: company
                    }),
                    /**
                     * @param  {string} company
                     * @return {string}
                     */
                    instances: (company) => generatePath(routes.panel.company.items.list.instances, {
                        company: company,
                    }),
                },
                single: {
                    /**
                     * @param  {string} company
                     * @param  {number} id
                     * @return {string}
                     */
                    product: (company, id) => generatePath(routes.panel.company.items.single.product, {
                        company: company,
                        id: id
                    }),
                    /**
                     * @param  {string} company
                     * @param  {number} id
                     * @return {string}
                     */
                    instance: (company, id) => generatePath(routes.panel.company.items.single.instance, {
                        company: company,
                        id: id,
                    }),
                }
            },
            salesOrders: {
                /**
                 * @param  {string} company
                 * @return {string}
                 */
                base: (company) => generatePath(routes.panel.company.salesOrders.base, {company: company}),
                /**
                 * @param  {string} company
                 * @return {string}
                 */
                list: (company) => generatePath(routes.panel.company.salesOrders.list.salesOrders, {company: company}),
                /**
                 * @param  {string} company
                 * @param  {number} id
                 * @return {string}
                 */
                single: (company, id) => generatePath(routes.panel.company.salesOrders.single.salesOrder, {
                    company: company,
                    salesOrderId: id
                }),
            },
            purchaseOrders: {
                /**
                 * @param  {string} company
                 * @return {string}
                 */
                base: (company) => generatePath(routes.panel.company.purchaseOrders.base, {company: company}),
                /**
                 * @param  {string} company
                 * @return {string}
                 */
                list: (company) => generatePath(routes.panel.company.purchaseOrders.list.purchaseOrder, {company: company}),
                /**
                 * @param  {string} company
                 * @param  {number} id
                 * @return {string}
                 */
                single: (company, id) => generatePath(routes.panel.company.purchaseOrders.single.purchaseOrder, {
                    company: company,
                    purchaseOrderId: id
                }),
            },
            location: {
                /**
                 * @param  {string} company
                 * @return {string}
                 */
                base: (company) => generatePath(routes.panel.company.location.base, {company: company}),
                /**
                 * @param  {string} company
                 * @return {string}
                 */
                warehouses: (company) => generatePath(routes.panel.company.location.warehouses, {company: company}),
                /**
                 * @param  {string} company
                 * @param  {string} warehouse
                 * @return {string}
                 */
                aisles: (company, warehouse) => generatePath(routes.panel.company.location.aisles, {
                    company: company,
                    warehouse: warehouse
                }),
                /**
                 * @param  {string} company
                 * @param  {string} warehouse
                 * @param  {string} aisle
                 * @return {string}
                 */
                sections: (company, warehouse, aisle) => generatePath(routes.panel.company.location.sections, {
                    company: company,
                    warehouse: warehouse,
                    aisle: aisle
                }),
                /**
                 * @param  {string} company
                 * @param  {string} warehouse
                 * @param  {string} aisle
                 * @param  {string} section
                 * @return {string}
                 */
                rows: (company, warehouse, aisle, section) => generatePath(routes.panel.company.location.rows, {
                    company: company,
                    warehouse: warehouse,
                    aisle: aisle,
                    section: section
                }),
                /**
                 * @param  {string} company
                 * @param  {string} warehouse
                 * @param  {string} aisle
                 * @param  {string} section
                 * @param  {string} row
                 * @return {string}
                 */
                rowItems: (company, warehouse, aisle, section, row) => generatePath(routes.panel.company.location.rowItems, {
                    company: company,
                    warehouse: warehouse,
                    aisle: aisle,
                    section: section,
                    row: row
                }),
            },
        }
    },
}

/**
 * The Accumulated list of all routes for difference route entries
 */
export const routesLists = {
    //                                  ************* ALL ROUTES **************

    all: flattenObjectAndReturnAsAList(routes),
    allWithServerErrorSection: flattenObjectAndReturnAsAList(routes, routes.error.serverErrorForSection),
    allWithAccessDeniedSection: flattenObjectAndReturnAsAList(routes, routes.error.accessDeniedForSection),

    //                                  ************* PUBLIC ROUTES **************

    public: flattenObjectAndReturnAsAList(routes.public),

    //                                  ************* PANEL ROUTES **************

    panel: flattenObjectAndReturnAsAList(routes.panel),

    //                                  ************* COMPANY ROUTES **************

    company: flattenObjectAndReturnAsAList(routes.panel.company),

    counting: flattenObjectAndReturnAsAList(routes.panel.company.counting),

    items: flattenObjectAndReturnAsAList(routes.panel.company.items),

    salesOrders: flattenObjectAndReturnAsAList(routes.panel.company.salesOrders),

    purchaseOrders: flattenObjectAndReturnAsAList(routes.panel.company.purchaseOrders),

    //                                  ************* ERROR ROUTES **************
    error: flattenObjectAndReturnAsAList(routes.error),
}

export default routes;
