import React, {useContext, useEffect, useState} from "react";
import classnames from "classnames";
import {ReactComponent as Bell} from "../../../../../assets/images/panel/top-bar/bell.svg";
import {ReactComponent as NoNotifications} from "../../../../../assets/images/panel/top-bar/no-notifications.svg";
import {Popover} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {CompanyContext} from "../../../../contexts/company";

//TODO: the following are not done:
// 1. the api call for fetching the notifications
// 2. the signalR hook if we are to use that
// 3. the ui of notification rows
// 4. the logic of storing the notifications to view instead of all notifications (based on the selected state)
// 5. the logic for notification onClick and navigation through the application
// 6. the loading animation

// the state by which the notifications of the users are rendered
const notificationsViewStates = {
    all: 'all-notifications',
    unread: 'unread-notifications',
};

const PanelTopbarNotifications = ({disabled}) => {
    const [company] = useContext(CompanyContext);
    const [popover, setPopover] = useState();
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(false);
    const [viewState, setViewState] = useState(notificationsViewStates.all);
    const {t} = useTranslation();

    const hasNotifications = !!notifications?.length;
    const translations = t('containers.panel.topbar.notifications', {returnObjects: true});


    /**
     * As soon as the component mounts:
     * fetches the list of notifications from the server.
     */
    useEffect(() => {
        getNotifications();
    }, [])

    /**
     * Listens to the changes in popover and with each change:
     * if the popover is opened, then fetches the list of notifications from the server.
     */
    useEffect(() => {
        if (!popover) {
            setViewState(notificationsViewStates.all);
            return;
        }
        getNotifications();
    }, [popover])

    /**
     * Fetches the list of available notifications of the user.
     */
    const getNotifications = () => {
        setLoading(true);
        setNotifications([]);
        setLoading(false);
    }

    /**
     * Changes the view state of user's notifications
     */
    const toggleNotificationViewState = () => {
        const newState = viewState === notificationsViewStates.all
            ? notificationsViewStates.unread
            : notificationsViewStates.all;
        setViewState(newState);
    }

    return (
        <>
            <div
                className={classnames('topbar-icon',
                    {'disabled': disabled},
                    {'active': !!popover},
                    {'notifications-ball': hasNotifications},
                )}
                onClick={(e) => setPopover(e.currentTarget)}>
                <Bell/>
            </div>
            <Popover
                id={!!popover ? 'notifications' : undefined}
                classes={{
                    paper: 'top-bar-popover notifications'
                }}
                open={!!popover}
                onClose={() => setPopover(null)}
                anchorReference={'anchorEl'}
                anchorEl={popover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div className={'content'}>
                    <div className={'title'}>
                        {translations.popover.title}
                        <button className={'button text primary'}
                                onClick={toggleNotificationViewState}>
                            {
                                viewState === notificationsViewStates.all
                                    ? translations.popover.actions.viewUnread
                                    : translations.popover.actions.viewAll
                            }
                        </button>
                    </div>
                    {
                        //TODO: filter using the read property of notifications
                        notifications?.filter(e => e)?.length
                            ? (
                                <>
                                </>
                            )
                            : (
                                <div className={'empty-notifications'}>
                                    <NoNotifications/>
                                    {
                                        viewState === notificationsViewStates.all
                                            ? (
                                                <>
                                                    <p className={'title'}>
                                                        {translations.popover.empty.all.title}
                                                    </p>
                                                    <p className={'body'}>
                                                        {translations.popover.empty.all.message}
                                                    </p>
                                                </>
                                            )
                                            : (
                                                <>
                                                    <p className={'title'}>
                                                        {translations.popover.empty.unread.title}
                                                    </p>
                                                    <p className={'body'}>
                                                        {translations.popover.empty.unread.message?.map((e, index) =>
                                                            e.type === 'button'
                                                                ? <button key={index}
                                                                          className={'button primary text px-1'}
                                                                          onClick={toggleNotificationViewState}
                                                                >
                                                                    {translations.popover.actions.viewAll}
                                                                </button>
                                                                : <span key={index}>{e.text}</span>
                                                        )}
                                                    </p>
                                                </>
                                            )
                                    }

                                </div>
                            )
                    }
                </div>
            </Popover>
        </>
    )
}

export default PanelTopbarNotifications;
