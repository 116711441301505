import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {DefaultInitialPaginationInfo, DefaultPaginationInfo, TableCellAlignments, TableCellTypes} from "../../../../../../../core/constants/enums";
import {
    hasOrderByOfQueryChanged,
    hasPaginationInfoChanged,
    onTableCurrentPageChange,
    onTablePageSizeChange,
    onTableSort
} from "../../../../../../../core/services/searching-utils";
import useRouter from "../../../../../../hooks/use-router";
import {ReactComponent as ConvertToExcel} from "../../../../../../../assets/images/convert-to-excel.svg";
import Table from "../../../../../../containers/table";
import {Col} from "react-bootstrap";
import Barcode from "react-barcode";
import {lighterPrimaryColor, primaryTextColor} from "../../../../../../../assets/mui/colors";
import BreadCrumbs from "../../../../../../components/app-specific/bread-crumbs";
import classnames from "classnames";
import {routeFunctions} from "../../../../../../routes";
import ImageSection from "../../../../../../components/app-specific/product-image-section";
import useWindowViewportWidth from "../../../../../../hooks/use-window";
import useIsMounted from "../../../../../../hooks/use-is-mounted";
import moment from "moment";
import {exportCSVFile} from "../../../../../../../core/services/utils";
import NoEntries from "../../../../../../components/app-specific/no-entries";

// Object indicating the table cell keys
const tableCellKeys = {
    warehouse: 'warehouse',
    aisle: 'aisle',
    section: 'section',
    row: 'row',
    enterDate: 'enterDate',
    exitDate: 'exitDate'
}

const applicableSizesForSpacerHeightIncrease = ['xs', 'sm', 'md', 'lg'];


const ProductInstanceView = () => {
    const {t} = useTranslation();
    const {query, location, history, params} = useRouter()
    const [partFullInfo, setPartFullInfo] = useState({});
    const [parts, setParts] = useState([]);
    const [paginationInfo, setPaginationInfo] = useState(DefaultPaginationInfo);
    const [orderBy, setOrderBy] = useState(null);
    const [loading, setLoading] = useState(true);
    /**@type {React.MutableRefObject<HTMLDivElement>}*/
    const imageSectionRef = useRef(null);
    const [imageSectionDimensions, setImageSectionDimensions] = useState({height: 0, width: 0});
    const viewportWidth = useWindowViewportWidth();
    const isMounted = useIsMounted();
    const [isGeneratinExcel, setIsGeneratingReport] = useState(false);

    const translation = t("views.panel.company.products.single.instance", {returnObjects: true});
    const errorMessage = t('views.panel.errorMessages', {returnObjects: true})


    const informationSectionStyle = useMemo(() => ({
        ...(
            applicableSizesForSpacerHeightIncrease.includes(viewportWidth)
                ? {}
                : {height: (imageSectionDimensions?.height) ?? 0}
        )
    }), [viewportWidth, imageSectionDimensions]);

    const spacerStyles = useMemo(() => ({
        width: imageSectionDimensions?.width ?? 0,
        // ...(
        //     applicableSizesForSpacerHeightIncrease.includes(viewportWidth)
        //         ? {
        //             height: imageSectionDimensions?.height ?? 0
        //         }
        //         : {}
        // )
    }), [viewportWidth, imageSectionDimensions])

    const informationSectionProps = useMemo(() => ({
        ...(
            applicableSizesForSpacerHeightIncrease.includes(viewportWidth)
                ? {xs: 12}
                : {}
        )
    }), [viewportWidth]);


    // Array for declaring the structure of table cells
    const tableCells = [
        {
            title: translation?.tableHeaders.warehouse,
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: tableCellKeys.warehouse,
            sortable: true
        },
        {
            title: translation?.tableHeaders.aisle,
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: tableCellKeys.aisle,
            sortable: true
        },
        {
            title: translation?.tableHeaders.section,
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: tableCellKeys.section,
            sortable: true
        },
        {
            title: translation?.tableHeaders.row,
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: tableCellKeys.row,
            sortable: true
        },
        {
            title: translation?.tableHeaders.enterDate,
            alignment: TableCellAlignments.left,
            type: TableCellTypes.dateTime,
            name: tableCellKeys.enterDate,
            sortable: true,
            size: 1.5
        },
        {
            title: translation?.tableHeaders.exitDate,
            alignment: TableCellAlignments.left,
            type: TableCellTypes.dateTime,
            name: tableCellKeys.exitDate,
            sortable: true
        }

    ];

    useLayoutEffect(() => {
        if (!imageSectionRef.current) return;
        const observer = new ResizeObserver(onImageSectionResized);
        observer.observe(imageSectionRef.current);
        onImageSectionResized([imageSectionRef.current]);
        return () => {
            observer.disconnect();
        }
    }, [imageSectionRef])

    /**
     *
     * @param {( HTMLDivElement | ResizeObserverEntry)[] } entries
     */
    const onImageSectionResized = (entries) => {
        if (!entries.length)
            return;
        const element = entries[0];
        const clientRect = element.contentRect ?? element.getBoundingClientRect();
        setImageSectionDimensions({
            width: clientRect.width,
            height: clientRect.height,
        });
    }

    /**
     * Listens to the changes in the url query parameters and with each change:
     * -If the paginationInfo of query is not the same as the state, updates the state
     * -If the filters of the query is not the same as the state, updates the state
     * -If the order-by of the query is not the same as the state, updates the state
     *
     * if there are any changes from the above scenarios, searches the invoices with the new state values
     */
    useEffect(() => {
        hasPaginationInfoChanged(query, paginationInfo, setPaginationInfo);
        const newOrderBy = hasOrderByOfQueryChanged(query, orderBy, setOrderBy);
        getPartFullInformation();
        if (newOrderBy)
            setPaginationInfo(DefaultInitialPaginationInfo)
    }, [query]);

    useEffect(() => {
        if (paginationInfo.currentPage === DefaultInitialPaginationInfo.currentPage)
            return setPaginationInfo(DefaultPaginationInfo);
        searchInstanceHistory();
    }, [paginationInfo.currentPage, paginationInfo.pageSize])

    /**
     * Gets the full information of the part:
     * - Calls the api regarding this action
     */
    const getPartFullInformation = () => {
        setPartFullInfo({
            id: 1,
            partNumber: 'RADFR153A',
            serialNumber: "123123-12312-1231-23",
            upc: '123123123',
            height: 20,
            weight: 60,
            width: 60,
            depth: 25,
            totalAvailability: 2012,
            spireAvailability: 200,
            warehouse: "W1",
            aisle: "A1",
            section: "S1",
            row: "R1",
            isAvailable: true,
            images: [
                {
                    id: 1,
                    src: 'https://admin.chtp.com/files/4c146b533ab249c39528d71daf7ea7ba.jpg'
                },
                {
                    id: 2,
                    src: 'https://admin.chtp.com/files/9a2422ac784b48f2af1064f8897e87df.jpg'
                },
                {
                    id: 3,
                    src: 'https://admin.chtp.com/files/b92922e008a64a578dfff8995f57ea31.jpg'
                },
                {
                    id: 4,
                    src: 'https://admin.chtp.com/files/4c146b533ab249c39528d71daf7ea7ba.jpg'
                }
            ],
        });
    }

    /**
     * Searches for part instances:
     * - Calls teh api regarding this action with the given pagination info, orderBy, and search filters
     */
    const searchInstanceHistory = async () => {
        const forApi = {
            paginationInfo: paginationInfo,
            orderBy: orderBy
        }
        const response = await searchInstanceHistoryApiCall(forApi);
        if (!isMounted())
            return;
        if (response?.resultFlag) {
            setParts(response.items.sort((a, b) => {
                return a.isCurrentLocation ? -1 : b.isCurrentLocation ? 1 : 0;
            }));
            setPaginationInfo(prevState => ({...prevState, length: response.paginationInfo.length}));
        }
        setLoading(false);
    }

    const searchInstanceHistoryApiCall = async (forApi = null) => {
        return {
            resultFlag: true,
            items: [
                {
                    id: 1,
                    partNumber: 'RADFR153A',
                    serializable: true,
                    serialNumber: '123-123-123',
                    warehouse: {
                        id: 1,
                        title: "00"
                    },
                    aisle: {
                        id: 1,
                        title: 'A1'
                    },
                    section: {
                        id: 1,
                        title: 'S1'
                    },
                    row: {
                        id: 1,
                        title: 'R1'
                    },
                    enterDate: new Date(),
                    exitDate: new Date(),
                    count: 1,
                    isCurrentLocation: true
                },
                {
                    id: 2,
                    partNumber: 'CACDO102',
                    serializable: false,
                    serialNumber: null,
                    warehouse: {
                        id: 1,
                        title: "00"
                    },
                    aisle: {
                        id: 1,
                        title: 'A1'
                    },
                    section: {
                        id: 1,
                        title: 'S1'
                    },
                    row: {
                        id: 1,
                        title: 'R1'
                    },
                    enterDate: new Date(),
                    exitDate: new Date(),
                    count: 20,
                    isCurrentLocation: false
                },
                {
                    id: 3,
                    partNumber: 'RADFR153A',
                    serializable: true,
                    serialNumber: '123-123-123',
                    warehouse: {
                        id: 1,
                        title: "00"
                    },
                    aisle: {
                        id: 1,
                        title: 'A1'
                    },
                    section: {
                        id: 1,
                        title: 'S1'
                    },
                    row: {
                        id: 1,
                        title: 'R1'
                    },
                    enterDate: new Date(),
                    exitDate: new Date(),
                    count: 1,
                    isCurrentLocation: false
                },
                {
                    id: 4,
                    partNumber: 'CONVO108',
                    serializable: false,
                    serialNumber: null,
                    warehouse: {
                        id: 1,
                        title: "00"
                    },
                    aisle: {
                        id: 1,
                        title: 'A1'
                    },
                    section: {
                        id: 1,
                        title: 'S1'
                    },
                    row: {
                        id: 1,
                        title: 'R1'
                    },
                    enterDate: new Date(),
                    exitDate: new Date(),
                    count: 10,
                    isCurrentLocation: false
                },
                {
                    id: 5,
                    partNumber: 'RADFR153A',
                    serializable: true,
                    serialNumber: '123-123-123',
                    warehouse: {
                        id: 1,
                        title: "99"
                    },
                    aisle: {
                        id: 1,
                        title: 'A1'
                    },
                    section: {
                        id: 1,
                        title: 'S1'
                    },
                    row: {
                        id: 1,
                        title: 'R1'
                    },
                    enterDate: new Date(),
                    exitDate: new Date(),
                    count: 1,
                    isCurrentLocation: false
                },
            ],
            paginationInfo: {
                length: 5
            }
        }
    }

    const onWarehouseClicked = (warehouseId) => {
    }

    const onAisleClicked = (aisleId) => {
    }

    const onSectionClicked = (sectionId) => {
    }

    const onRowClicked = (rowId) => {
    }

    /**
     * Handles click on the part number:
     * Navigates user to the part single view
     */
    const onPartNumberClicked = () => {
        history.push(routeFunctions.panel.company.items.single.product(params.company, partFullInfo.id))
    }

    /**
     * Handles adding a new image to the list of images of the part:
     */
    const onAddImageClicked = () => {
    }

    /**
     * Handles click on the cross icon button on images in the bottom slide bar:
     * - Opens confirmation dialog for confirming the remove action
     * - After confirmation, calls the api regarding this action
     * @param index
     */
    const onRemoveImage = (index) => {
    }

    /**
     * Generates excel report for the part instances
     *
     */
    const generateExcelReport = async () => {
        setIsGeneratingReport(true);
        const response = await searchInstanceHistoryApiCall();
        if (!isMounted())
            return;
        setIsGeneratingReport(false);
        if (response?.resultFlag) {
            const title = translation?.information?.excelTitle;
            const content = response.data.items?.map(e => ({
                [translation?.tableHeaders.warehouse]: `${e.warehouse.title}`,
                [translation?.tableHeaders.aisle]: `${e.aisle.title}`,
                [translation?.tableHeaders.section]: `${e.section.title}`,
                [translation?.tableHeaders.row]: `${e.row.title}`,
                [translation?.tableHeaders.enterDate]: `${moment(e?.enterDate).format('DD-MM-YYYY HH:mm:ss')}`,
                [translation?.tableHeaders.exitDate]: `${moment(e?.exitDate).format('DD-MM-YYYY HH:mm:ss')}`,

            }))
            const headers = Object.keys(content[0]);
            exportCSVFile(headers, content, title);
        }
    }

    /**
     * Creates the data entries for the part instances table
     * @return {TableData[]}
     */
    const createTableData = () => {
        return parts?.map(part => (
            {
                key: part?.id,
                [tableCellKeys.warehouse]: () => (
                    <div onClick={() => onWarehouseClicked(part.warehouse.id)}>
                        <p className={'text-button'}>{part?.warehouse?.title}</p>
                    </div>
                ),
                [tableCellKeys.aisle]: () => (
                    <div onClick={() => onAisleClicked(part.aisle.id)}>
                        <p className={'text-button'}>{part?.aisle?.title}</p>
                    </div>
                ),
                [tableCellKeys.section]: () => (
                    <div onClick={() => onSectionClicked(part.section.id)}>
                        <p className={'text-button'}>{part?.section?.title}</p>
                    </div>
                ),
                [tableCellKeys.row]: () => (
                    <div onClick={() => onRowClicked(part.row.id)}>
                        <p className={'text-button'}>{part?.row?.title}</p>
                    </div>
                ),
                [tableCellKeys.enterDate]: part?.enterDate,
                [tableCellKeys.exitDate]: part?.exitDate,
                isSpecialRow: part.isCurrentLocation,
            }
        ));

    }


    return (
        <>
            <div className={'panel-card  single-instance-view'}>
                <div className={'information-section'} style={informationSectionStyle}>
                    <ImageSection
                        loading={loading}
                        ref={imageSectionRef}
                        onRemoveImage={onRemoveImage}
                        partImages={partFullInfo.images}
                        onAddImageClicked={onAddImageClicked}
                        translation={translation}
                    />
                    <div className={'d-flex flex-wrap information-section-details'}>
                        <div className={'information-section-spacer'}
                             style={spacerStyles}/>
                        <Col {...informationSectionProps} className={'flex-xl-grow-1 px-5'}>
                            <div className={'d-flex flex-row  align-items-start justify-content-between pt-4 pt-xl-0'}>
                                <div className={'d-flex flex-column align-items-start'}>
                                    <p className={'part-number'}>{partFullInfo?.serialNumber}</p>
                                    <div className={'d-flex flex-row  align-items-end mb-4'}>
                                        <p className={'info-title'}>{`${translation.information?.upc ?? ''}:`}</p>
                                        <p className={'info-value'}>{partFullInfo?.upc}</p>
                                    </div>
                                </div>
                                <button
                                    className={'button primary px-4 part-number-button'}
                                    onClick={onPartNumberClicked}
                                >{partFullInfo?.partNumber}</button>
                            </div>
                            <div className={'d-flex flex-row flex-wrap align-items-start justify-content-between'}>
                                <div className={classnames('order-2 order-xl-1')}>
                                    <div className={'d-flex flex-row  align-items-center mb-2'}>
                                        <p className={'info-title'}>{`${translation.information?.height ?? ''}:`}</p>
                                        <p className={'info-value'}>{`${partFullInfo.height ?? 0} m`}</p>
                                    </div>
                                    <div className={'d-flex flex-row  align-items-center mb-2'}>
                                        <p className={'info-title'}>{`${translation.information?.weight ?? ''}:`}</p>
                                        <p className={'info-value'}>{`${partFullInfo.weight ?? 0} m`}</p>
                                    </div>
                                    <div className={'d-flex flex-row  align-items-center mb-2'}>
                                        <p className={'info-title'}>{`${translation.information?.width ?? ''}:`}</p>
                                        <p className={'info-value'}>{`${partFullInfo.width ?? 0} m`}</p>
                                    </div>
                                    <div className={'d-flex flex-row  align-items-center mb-2'}>
                                        <p className={'info-title'}>{`${translation.information?.depth ?? ''}:`}</p>
                                        <p className={'info-value'}>{`${partFullInfo.depth ?? 0} m`}</p>
                                    </div>
                                </div>
                                <div className={'order-3 order-xl-2 d-flex flex-column barcode-info'}>
                                    <Barcode
                                        value={partFullInfo?.partNumber ?? ''}
                                        displayValue={false}
                                        font={"Montserrat"}
                                        fontSize={15}
                                        background={lighterPrimaryColor}
                                        lineColor={primaryTextColor}
                                        width={2}
                                        height={150}
                                    />
                                </div>
                                <div className={classnames(
                                    'order-1 order-xl-3 location-availability', {
                                        ' w-100': applicableSizesForSpacerHeightIncrease.includes(viewportWidth)
                                    })}>
                                    <p
                                        className={classnames('instance-availability-title', {'available': partFullInfo?.isAvailable})}
                                    >
                                        {partFullInfo.isAvailable ? translation.currentLocation : translation?.notAvailable}
                                    </p>
                                    <BreadCrumbs data={
                                        [
                                            {
                                                key: partFullInfo.warehouse,
                                                child: <p key={partFullInfo.warehouse} className={'text-button'}>{partFullInfo.warehouse}</p>,
                                                indented: true,
                                                onClick: () => console.log(partFullInfo.warehouse)

                                            },
                                            {
                                                key: partFullInfo.aisle,
                                                child: <p key={partFullInfo.aisle} className={'text-button'}>{partFullInfo.aisle}</p>,
                                                indented: true,
                                                onClick: () => console.log(partFullInfo.aisle)
                                            },
                                            {
                                                key: partFullInfo.section,
                                                child: <p key={partFullInfo.section} className={'text-button'}>{partFullInfo.section}</p>,
                                                indented: true,
                                                onClick: () => console.log(partFullInfo.section)
                                            },
                                            {
                                                key: partFullInfo.row,
                                                child: <p key={partFullInfo.row} className={'text-button'}>{partFullInfo.row}</p>,
                                                indented: true,
                                                onClick: () => console.log(partFullInfo.row)
                                            },
                                        ]
                                    }/>
                                </div>
                            </div>
                        </Col>
                    </div>
                </div>
                {
                    (parts?.length < 1 && !loading)
                        ? <div className={'pb-5'}>
                            <NoEntries
                                text={errorMessage}
                                includeButton={false}
                            />
                        </div>
                        : <>
                            <div className={'d-flex align-items-center justify-content-between mb-4 px-3'}>
                                <div/>
                                <div className={'d-flex align-items-center justify-content-center'}>
                                    <button
                                        onClick={generateExcelReport}
                                        className={'button primary outlined report'}>
                                        <ConvertToExcel className={'me-1'}/>
                                        <p>
                                            {isGeneratinExcel ? (translation.generatingReport ?? '') : (translation?.information?.convertToExcel ?? '')}
                                        </p>
                                    </button>
                                </div>
                            </div>
                            <div>
                                <Table
                                    loading={{state: loading, count: 8}}
                                    className={'my-3 px-3 instance-location-table'}
                                    color={'primary'}
                                    cells={tableCells}
                                    data={createTableData()}
                                    paginationInfo={paginationInfo}
                                    orderBy={orderBy}
                                    onSort={(o) => onTableSort(o, query, history, location)}
                                    onPageSizeChange={(p, i) => onTablePageSizeChange(p, i, query, history, location)}
                                    onCurrentPageChange={(p, i) => onTableCurrentPageChange(p, i, query, history, location)}
                                />
                            </div>
                        </>
                }
            </div>
        </>
    )
}

export default ProductInstanceView;


