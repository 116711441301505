import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import useRouter from "../../../../../hooks/use-router";
import Api from "../../../../../../core/services/api_service";
import {CompanyContext} from "../../../../../contexts/company";
import useIsMounted from "../../../../../hooks/use-is-mounted";
import InventorySchemaVisualizer from "../../../../../../packages/inventory-schema-visualizer/ui";
import {InventorySchemaVisualizerModes} from "../../../../../../packages/inventory-schema-visualizer/core/models/constants/enums";

const EditInventorySchemaView = () => {
    const {params: {id}} = useRouter();
    const [company] = useContext(CompanyContext);
    const [warehouses, setWarehouses] = useState([]);
    const [loading, setLoading] = useState(true);
    const isMounted = useIsMounted();

    /**@type {LocationWarehouseDs}*/
    const selectedWarehouse = useMemo(() => warehouses.find(e => e.id === Number(id)), [id, warehouses]);

    const {t} = useTranslation();
    const translations = t("views.panel.company.inventorySchema.edit", {
        returnObjects: true,
        code: selectedWarehouse?.code ?? '',
        title: selectedWarehouse?.title ?? '',
    });

    /**
     * Fetches the available warehouses of the selected company of the operator from the server.
     * @type {(function(): Promise<void>)|*}
     */
    const getCompanyWarehouses = useCallback(async () => {
        setLoading(true);
        const response = await Api.getWarehouses();
        if (!isMounted())
            return;
        if (response?.resultFlag)
            setWarehouses(response.data ?? []);
        setLoading(false);
    }, [isMounted])

    /**
     * With each change in the selected company of the panel:
     * - fetches the available warehouses of the selected company of the operator from the server.
     */
    useEffect(() => {
        getCompanyWarehouses().then();
    }, [getCompanyWarehouses, company?.id])

    return (
        <div className={'panel-card edit-inventory-schema-view'}>
            <div className='d-flex justify-content-between align-items-center'>
                <div className={'d-flex align-items-center'}>
                    <p className='title'>
                        {translations?.title ?? ''}
                    </p>
                </div>
            </div>
            <div className={''}>
                {
                    selectedWarehouse && !loading &&
                    <InventorySchemaVisualizer
                        mode={InventorySchemaVisualizerModes.edit}
                        warehouse={selectedWarehouse}
                        onRowClicked={() => void 0}
                    />
                }
            </div>
        </div>
    )
}

export default EditInventorySchemaView;
