import React from "react";
import {DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import Dialog from "../../../base/dialog";
import {useTranslation} from "react-i18next";

const ReviewAndAdjustDialog = ({open, setOpen, onMove, currentLocationCode, moveToLocationCode}) => {
    const {t} = useTranslation();
    const translation = t("dialogs.counting.position.reviewAndAdjustDialog", {returnObjects: true});

    return (
        <>
            <Dialog
                max={'md'}
                fullWidth
                open={open}
                setOpen={setOpen}
            >
                <DialogTitle className={'title'} id={'scroll-dialog-title'}>
                    <div>
                        <h2>
                            {translation?.title ?? ''}
                        </h2>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div>
                        <p className={'mb-3'}>
                            {translation?.currentLocation ?? ''}: <b>{currentLocationCode ?? ''}</b>
                        </p>
                        <p>
                            {translation?.moveTo ?? ''}: <b>{moveToLocationCode ?? ''}</b>
                        </p>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button
                        className='button primary outlined px-4'
                        type='button'
                        onClick={() => setOpen(false)}
                    >
                        {translation?.cancel ?? ''}
                    </button>
                    <button
                        className='button primary px-5'
                        type='submit'
                        onClick={onMove}
                    >
                        {translation?.confirmButton ?? ''}
                    </button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ReviewAndAdjustDialog;
