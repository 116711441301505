import React, {useContext} from "react";
import {RegistrationContext} from "../../../../../../contexts/registration";
import {RegistrationReducerActions} from "../../../../../../reducers/registration";
import {registrationStepperStepIds} from "../../../../../../../core/constants/ids";
import ApplicationPricePlans from "../../../../../../containers/application-price-plans";


const RegistrationViewPlanSelectionSection = () => {
    const [, registrationReducer] = useContext(RegistrationContext);

    /**
     * Navigates to the next step of the registration process after setting the state
     * @param {any} plan
     */
    const onSelectPlan = (plan) => {
        registrationReducer({
            type: RegistrationReducerActions.submitStep,
            payload: {
                stepName: registrationStepperStepIds.planSelection,
                values: plan,
                submitted: true,
                valid: true,
            }
        })
        registrationReducer({type: RegistrationReducerActions.nextStep});
    }

    return (
        <>
            <ApplicationPricePlans
                onPlanSelected={onSelectPlan}
                buttonText={'views.public.registration.plans.button'}
            />
            <div className={'my-4'}/>
        </>
    )
}


export default RegistrationViewPlanSelectionSection;
