import {
    ICanvasDataEntryDepth,
    IInternalCanvasDataEntry,
    IInventorySchemaVisualizerVisualConfig,
    IOnRowClickedFunc,
    IRemoveRectFunc,
    IRotateRectFunc
} from "../../../../types";
import Konva from "konva";
import {InventorySchemaVisualizerModes} from "../../../../../core/models/constants/enums";
import React, {useCallback} from "react";
import BoundRect from "../../bound-rect";
import {Group} from "react-konva";
import useWarehouseRect from "../../../../hooks/use-warehouse-rect";
import SectionRect from "../section";

export type IAisleRectProps = {
    aisle: IInternalCanvasDataEntry<ICanvasDataEntryDepth.aisle>
    stage: Konva.Stage,
    selectedRectData?: IInventorySchemaVisualizerVisualConfig['selectedEntityData'],
    setSelectedRectData: (data: IInventorySchemaVisualizerVisualConfig['selectedEntityData'] | undefined) => void,
    mode: InventorySchemaVisualizerModes,
    name: string,
    remove: IRemoveRectFunc,
    rotate: IRotateRectFunc,
    onRowClicked: IOnRowClickedFunc,

};
export const AisleRect = ({
                              stage,
                              selectedRectData,
                              setSelectedRectData,
                              name,
                              mode,
                              aisle,
                              remove,
                              rotate,
                              onRowClicked,
                          }: IAisleRectProps) => {
    const {
        rect,
        setRect,
        groupRef,
        draggable,
        selected,
        selectEntity,
        opacity,
        removeEntity,
        removable,
        rotatable,
        rotateEntity
    } = useWarehouseRect({
        mode: mode,
        selectedRectData: selectedRectData,
        setSelectedRectData: setSelectedRectData,
        allowInteractions: aisle.allowInteractions,
        data: aisle.data,
        remove: remove,
        rotate: rotate,
    });

    /**
     * Changes the properties of the Group entity and Text entity of the canvas with each change in the Rect's entity.
     */
    const onRectTransformed = useCallback<(evt: Konva.KonvaEventObject<Event>) => void>((e) => {
        if (!groupRef.current)
            return;
        const rect = e.target;
        const group = groupRef.current;

        const newAttrs = {
            x: rect.x(),
            y: rect.y(),
            rotation: rect.rotation(),
            width: rect.width(),
            height: rect.height(),
        }
        if (group) {
            const prevGroupAttrs = {
                x: group.x(),
                y: group.y(),
                rotation: group.rotation(),
                width: group.width(),
                height: group.height(),
            };
            group.setAttrs(newAttrs);
            for (const sectionRect of group.getChildren((node) => node.getClassName() === 'Rect')) {
                const aisleRotated = aisle.rotated;
                if (!aisleRotated) {
                    sectionRect.setAttrs({
                        height: newAttrs.height,
                        width: newAttrs.width * (sectionRect.width() / prevGroupAttrs.width),
                        y: 0,
                        x: newAttrs.width * (sectionRect.x() / prevGroupAttrs.width),
                    });
                } else {
                    sectionRect.setAttrs({
                        height: newAttrs.height * (sectionRect.height() / prevGroupAttrs.height),
                        width: newAttrs.width,
                        y: newAttrs.height * (sectionRect.y() / prevGroupAttrs.height),
                        x: 0,
                    });
                }
                // force transform the section rects since their transformation will not be triggered (their listening is set to false),
                sectionRect.getAttr('forceOnTransform')?.({target: sectionRect});
            }
        }
    }, [aisle.rotated, groupRef]);

    /**
     * Changes the position of the Group entity and Text entity of the canvas with each change in the Rect entity's position.
     */
    const onRectDragged = useCallback<(evt: Konva.KonvaEventObject<DragEvent>) => void>((e) => {
        const rect = e.target;
        const group = groupRef.current;
        if (group) {
            group.setAttrs({
                x: rect.x(),
                y: rect.y(),
            });
        }
    }, [groupRef]);

    return (
        <>
            <BoundRect
                interactive={aisle.allowInteractions}
                allowRemoval={removable}
                allowRotation={rotatable}
                ref={(ref) => setRect(ref as Konva.Rect)}
                padding={5}
                parent={stage}
                remove={removeEntity}
                rotate={rotateEntity}
                rectProps={{
                    x: aisle.x,
                    y: aisle.y,
                    width: aisle.width,
                    height: aisle.height,
                    fill: aisle.fill,
                    title: aisle.title,
                    data: aisle.data,
                    draggable: draggable,
                    onDblClick: selectEntity,
                    onDblTap: selectEntity,
                    name: name,
                    stroke: aisle.stroke,
                    strokeWidth: aisle.strokeWidth,
                    opacity: opacity,
                    cursor: aisle.cursor,
                    onTransform: onRectTransformed,
                    onDragMove: onRectDragged,
                    rotated: aisle.rotated,
                    entity: aisle.entity,
                    shadowColor: aisle?.shadowColor,
                    shadowOffsetX: aisle?.shadowOffsetX,
                    shadowOffsetY: aisle?.shadowOffsetY,
                    shadowBlur: aisle?.shadowBlur,
                    shadowEnabled: aisle?.shadowEnabled,
                }}
                transformerProps={{
                    keepRatio: false,
                    borderStroke: aisle.stroke,
                    borderStrokeWidth: aisle.strokeWidth,
                    anchorCornerRadius: 2,
                    visible: selected,
                }}
                minSize={{width: 20, height: 20}}
            />
            <Group
                ref={ref => groupRef.current = ref}
                x={aisle.x}
                y={aisle.y}
                width={aisle.width}
                height={aisle.height}
                draggable={false}
            >
                {
                    aisle.children.map(section => (
                        <SectionRect
                            key={section.key}
                            parent={rect as Konva.Rect}
                            section={section}
                            mode={mode}
                            remove={remove}
                            rotate={rotate}
                            selectedRectData={selectedRectData}
                            setSelectedRectData={setSelectedRectData}
                            onRowClicked={onRowClicked}
                        />
                    ))
                }
            </Group>
        </>
    );
}

export default AisleRect;
