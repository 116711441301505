import React from "react";
import PublicViewLayout from "../../../containers/public-view-layout";
import {Route, Switch} from "react-router";
import routes from "../../../routes";
import LandingView from "./landing";
import RegistrationView from "./registration";

const WithPublicViewLayoutViews = () => {

    return (
        <>
            <PublicViewLayout>
                {(sections) => (
                    <Switch>
                        <Route path={[
                            routes.landing,
                            routes.public.landing
                        ]} exact>
                            <LandingView sections={sections}/>
                        </Route>
                        <Route path={routes.public.register} exact>
                            <RegistrationView/>
                        </Route>
                    </Switch>
                )}
            </PublicViewLayout>
        </>
    )
}

export default WithPublicViewLayoutViews;
