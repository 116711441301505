import React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import routes from "./ui/routes";
import {Slide, toast, ToastContainer} from "react-toastify";
import {MuiThemeProvider} from "@material-ui/core";
import theme from "./assets/mui/panel-theme";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Views from "./ui/views";
import {Provider as ReduxProvider} from 'react-redux';
import store from "./redux/store";


const App = () => {
    return (
        <MuiThemeProvider theme={theme}>
            <ReduxProvider store={store}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Router>
                        <Route path={routes.landing}>
                            <Views/>
                        </Route>
                        <AppToastContainer/>
                    </Router>
                </MuiPickersUtilsProvider>
            </ReduxProvider>
        </MuiThemeProvider>
    );
}

const AppToastContainer = () => {

    return (
        <>
            <ToastContainer
                position={toast.POSITION.BOTTOM_RIGHT}
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={true}
                closeOnClick
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
                transition={Slide}
            />
        </>
    );
}

export default App;
