import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker as FieldKeyboardDatePicker} from "mui-rff";
import {ReactComponent as DatePickerIcon} from "../../../../assets/images/calendar-icon.svg";
import classnames from "classnames";

export const FormKeyboardDatePicker = ({
                                           insidePanel = true,
                                           format = 'dd-MM-yyyy',
                                           variant = 'inline',
                                           disableToolbar = true,
                                           keyboardIcon,
                                           className = '',
                                           ...props
                                       }) => {

    return (
        <>
            <FieldKeyboardDatePicker
                className={classnames(className, {'app-input': insidePanel,})}
                format={format}
                variant={variant}
                disableToolbar
                dateFunsUtils={DateFnsUtils}
                keyboardIcon={keyboardIcon ?? <DatePickerIcon className={'picker-icon'}/>}
                {...props}
            />
        </>
    )
}
