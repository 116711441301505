import {History, Location} from "history";
import {stringifyUrl} from "query-string";
import {debounce} from "lodash";
import {DefaultInitialPaginationInfo, DefaultPaginationInfo} from "../constants/enums";
import {CommonQueryParams, PaginationQueryParams, SortByQueryParams} from "../constants/query-params";

/**
 * Provides utility functionality for managing the searching of the
 */
class QueryManagementUtils {

    /**
     * Changes the order by of the query params.
     *
     * * resets the current page back to one. if resetCurrentPage flag is set.
     * @param {TableOrderBy}    newOrderBy
     * @param {any}             query
     * @param {any}             defaultPaginationInfo
     * @param {boolean}         resetCurrentPage
     * @param {History}         history
     * @param {Location}        location
     */
    static setOrderBy = debounce(QueryManagementUtils._setOrderBy, 300);

    /**
     * Changes the order by of the query params.
     *
     * * resets the current page back to one. if resetCurrentPage flag is set.
     * @param {TableOrderBy}    newOrderBy
     * @param {any}             query
     * @param {any}             defaultPaginationInfo
     * @param {boolean}         resetCurrentPage
     * @param {History}         history
     * @param {Location}        location
     */
    private static _setOrderBy(
        newOrderBy: any,
        query: any,
        history: History,
        location: Location,
        resetCurrentPage = true,
        defaultPaginationInfo = DefaultPaginationInfo,
    ) {
        history?.push(stringifyUrl({
            url: location?.pathname,
            fragmentIdentifier: location.hash,
            query: {
                ...(query ?? {}),
                [SortByQueryParams.property]: newOrderBy.propertyName,
                [SortByQueryParams.descending]: newOrderBy.isDescending,
                ...(resetCurrentPage ? {[PaginationQueryParams.currentPage]: defaultPaginationInfo.currentPage} : {})
            }
        }, {arrayFormat: 'bracket'}));
    }


    /**
     * Changes the pagination info of the query based on th given data.
     *
     * @param {PaginationInfo}  paginationInfo
     * @param {any}             query
     * @param {any}             defaultPaginationInfo
     * @param {History}         history
     * @param {Location}        location
     */
    static setPaginationInfo = debounce(QueryManagementUtils._setPaginationInfo, 300);

    /**
     * Changes the pagination info of the query based on th given data.
     *
     * @param {PaginationInfo}  paginationInfo
     * @param {any}             query
     * @param {any}             defaultPaginationInfo
     * @param {History}         history
     * @param {Location}        location
     */
    private static _setPaginationInfo(
        paginationInfo: any,
        query: any,
        history: History,
        location: Location,
        defaultPaginationInfo = DefaultPaginationInfo,
    ) {
        history.push(stringifyUrl({
            url: location.pathname,
            fragmentIdentifier: location.hash,
            query: {
                ...(query ?? {}),
                [PaginationQueryParams.length]: paginationInfo?.length ?? defaultPaginationInfo.length,
                [PaginationQueryParams.currentPage]: paginationInfo?.currentPage ?? defaultPaginationInfo.currentPage,
                [PaginationQueryParams.pageSize]: paginationInfo?.pageSize ?? defaultPaginationInfo.pageSize,
            }
        }, {arrayFormat: 'bracket'}));
    }

    /**
     * Sets the tab of the query params with the given data.
     * @param {any}             tab
     * @param {any}             query
     * @param {History}         history
     * @param {Location}        location
     * @param {any}             paginationConfig
     * @param {any}             orderByConfig
     */
    static setTab = debounce(QueryManagementUtils._setTab, 300);

    /**
     * Sets the tab of the query params with the given data.
     * @param {any}             tab
     * @param {any}             query
     * @param {History}         history
     * @param {Location}        location
     * @param {any}             paginationConfig
     * @param {any}             orderByConfig
     */
    private static _setTab(
        tab: any,
        query: any,
        history: History,
        location: Location,
        paginationConfig?: any,
        orderByConfig?: any,
    ) {
        if (!paginationConfig) {
            paginationConfig = {
                reset: true,
                replacement: DefaultInitialPaginationInfo,
            }
        }
        if (!orderByConfig) {
            orderByConfig = {
                reset: true,
                replacement: undefined,
            }
        }
        history.replace(stringifyUrl({
            url: location.pathname,
            fragmentIdentifier: location.hash,
            query: {
                ...query,
                [CommonQueryParams.tab]: tab,
                ...(
                    paginationConfig?.reset
                        ? {
                            [PaginationQueryParams.length]: paginationConfig.replacement?.length,
                            [PaginationQueryParams.currentPage]: paginationConfig.replacement?.currentPage,
                            [PaginationQueryParams.pageSize]: paginationConfig.replacement?.pageSize,
                        }
                        : {}
                ),
                ...(
                    orderByConfig?.reset
                        ? {
                            [SortByQueryParams.property]: orderByConfig?.replacement?.propertyName,
                            [SortByQueryParams.descending]: orderByConfig?.replacement?.isDescending,
                        }
                        : {}
                ),
            }
        }, {arrayFormat: 'bracket'}))
    }

}

export default QueryManagementUtils;
