import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Dialog from "../../../base/dialog";
import {DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {default as SwipeableViews} from "react-swipeable-views";
import {ReactComponent as RightArrow} from "../../../../../assets/images/right-arrow.svg";
import Form from "../../../base/form";
import * as Yup from 'yup';
import ValidateMessages from "../../../../../core/constants/texts/vallidate-messages";
import {makeRequired, makeValidate} from "mui-rff";
import {FormInput} from "../../../base/input";
import {ReactComponent as LocationIcon} from "../../../../../assets/images/dialogs/request-position-recount/location.svg";
import {ReactComponent as TitleIcon} from "../../../../../assets/images/dialogs/request-position-recount/title.svg";
import classnames from "classnames";

// Form name key
const formName = {
    title: 'title'
}

// Schema for declaring the validation logic of the form
const schema = Yup.object().shape({
    [formName.title]: Yup.string().trim().nullable().required(ValidateMessages.required)
});

const RecountRequestDialog = ({setOpen, open, locations, onSubmit}) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const {t} = useTranslation();
    const validate = makeValidate(schema);
    const required = makeRequired(schema);
    const translation = t('dialogs.counting.position.requestRecount', {returnObjects: true});

    useEffect(() => {
        setSelectedIndex(0);
    }, [open])

    const changePage = () => {
        setSelectedIndex(prevState => prevState === 0 ? 1 : 0);
    }

    const onLocationSelected = (location) => {
        setSelectedLocation(location);
        changePage();
    }

    const onViewSwiped = (e) => {
        console.log(e);
    }

    const onFormSubmitted = (formValue) => {
        onSubmit(formValue, selectedLocation);
    }

    /**
     * Handles navigating user to the previous slide if in title selection of dialog
     */
    const navigateToPreviousSlide = () => {
        setSelectedIndex(0);
    }

    return (
        <>
            <Dialog
                max={'md'}
                fullWidth
                setOpen={setOpen}
                open={open}
            >
                <DialogTitle className={'title'}>
                    <div>
                        <h2>{translation.title}</h2>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className={'recount-page-title-indicator'}>
                        <div className={'d-flex flex-column justify-content-center align-items-center'}>
                            <div
                                className={classnames('icon-indicator', {'selected': selectedIndex === 0})}
                                style={{cursor : 'pointer'}}
                                onClick={navigateToPreviousSlide}
                            >
                                <div>
                                    <LocationIcon/>
                                </div>
                            </div>
                            <p>
                                {translation?.selectLocation}
                            </p>
                        </div>
                        <div className={'base-line-indicator'}/>
                        <div className={'d-flex flex-column justify-content-center align-items-center'}>
                            <div
                                className={classnames('icon-indicator', {'selected': selectedIndex === 1})}
                                style={{cursor :  selectedIndex === 1 ? 'pointer' : 'not-allowed'}}
                            >
                                <div>
                                    <TitleIcon/>
                                </div>
                            </div>
                            <p>
                                {translation?.selectTitle}
                            </p>
                        </div>
                    </div>
                    <SwipeableViews
                        index={selectedIndex}
                        onChangeIndex={onViewSwiped}
                        animateTransitions={true}
                        disableLazyLoading={false}
                    >
                        <div key={1} className={'recount-location-selection'}>
                            {locations.map(location =>
                                <div
                                    key={location.id}
                                    className={'recount-location-card'}
                                    onClick={(e) => onLocationSelected(location)}
                                >
                                    <p>{location.title}</p>
                                    <RightArrow/>
                                </div>
                            )}
                        </div>
                        <div key={2}>
                            <Form
                                onSubmit={onFormSubmitted}
                                validate={validate}
                                render={({submitting}) => {
                                    return (
                                        <div className={'title-section'}>
                                            <FormInput
                                                className={'title-input'}
                                                placeholder={translation?.placeHolder}
                                                name={formName.title}
                                                required={required[formName.title]}
                                                disabled={submitting}
                                            />
                                            <DialogActions>
                                                <button
                                                    className='button primary px-5'
                                                    disabled={submitting || !open}
                                                    type='submit'>
                                                    {submitting && translation?.submitting}
                                                    {!submitting && translation?.submit}
                                                </button>
                                            </DialogActions>
                                        </div>
                                    )
                                }}
                            />
                        </div>
                    </SwipeableViews>
                </DialogContent>

            </Dialog>
        </>
    );
}

export default RecountRequestDialog;
