import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import useRouter from "../../../../../../hooks/use-router";
import {useTranslation} from "react-i18next";
import {DefaultInitialPaginationInfo, TableCellAlignments, TableCellTypes} from "../../../../../../../core/constants/enums";
import {generatePaginationAndOrderByQueryResetter} from "../../../../../../../core/services/searching-utils";
import NoEntries from "../../../../../../components/app-specific/no-entries";
import Table from "../../../../../../containers/table";
import InstancesSearchSection from "../../../../../../components/search-sections/items/list/instances";
import {ReactComponent as ConvertToExcel} from "../../../../../../../assets/images/convert-to-excel.svg";
import {exportCSVFile} from "../../../../../../../core/services/utils";
import {routeFunctions, routes} from "../../../../../../routes";
import {ReactComponent as BarcodeIcon} from "../../../../../../../assets/images/barcode-icon.svg";
import {useDispatch} from "react-redux";
import {dialogActions} from "../../../../../../../redux/entities/dialog-slice";
import useIsMounted from "../../../../../../hooks/use-is-mounted";
import moment from "moment";
import useSearchData from "../../../../../../hooks/use-search-data";
import {PartInstanceListQueryNames} from "../../../../../../../core/constants/query-names";
import QueryManagementUtils from "../../../../../../../core/services/query-management-utils";
import {CompanyContext} from "../../../../../../contexts/company";
import {matchPath} from "react-router";


const initialPaginationInfo = {
    ...DefaultInitialPaginationInfo,
    pageSize: 10,
}

// Object indicating the table cell keys
const tableCellKeys = {
    partNumber: 'partNumber',
    serialNumber: 'serialNumber',
    warehouse: 'warehouse',
    aisle: 'aisle',
    section: 'section',
    row: 'row',
    enterDate: 'enterDate',
    exitDate: 'exitDate',
    count: 'count'
}

// Samples statuses
const statuses = [{
    id: 1, title: "Active"
}, {
    id: 2, title: "Testing"
}]

// Sample warehouses
const warehouses = [{
    id: 1, title: "00"
}, {
    id: 2, title: "Test"
}]

// Sample Aisles
const aisles = [{
    id: 1, title: 'A1', warehouse: {
        id: 1
    },
}, {
    id: 2, title: 'A2', warehouse: {
        id: 1
    },
}, {
    id: 3, title: 'A3', warehouse: {
        id: 2
    },
}, {
    id: 4, title: 'A4', warehouse: {
        id: 2
    },
},]

// Sample sections
const sections = [
    {
        id: 1,
        title: 'asasdasdasdasdasdasdasdasdasdasdasdasdasdS1',
        aisle: {
            id: 1
        }
    },
    {
        id: 2,
        title: 'asasdasdasdasdasdasdasdasdasdasdasdasdasdS1',
        aisle: {
            id: 1
        }
    },
    {
        id: 3,
        title: 'S3',
        aisle: {
            id: 3
        }
    },
    {
        id: 4,
        title: 'asasdasdasdasdasdasdasdasdasdasdasdasdasdS1',
        aisle: {
            id: 3
        }
    },
    {
        id: 5,
        title: 'S4',
        aisle: {
            id: 3
        }
    },
    {
        id: 6,
        title: 'S4',
        aisle: {
            id: 3
        }
    },
    {
        id: 7,
        title: 'S4',
        aisle: {
            id: 3
        }
    },
    {
        id: 8,
        title: 'S4',
        aisle: {
            id: 3
        }
    },
]

// Sample rows
const rows = [{
    id: 1, title: 'R1', section: {
        id: 1
    },
}, {
    id: 2, title: 'R2', section: {
        id: 1
    },
}, {
    id: 3, title: 'R3', section: {
        id: 2
    },
}, {
    id: 4, title: 'R4', section: {
        id: 2
    },
},]


const ItemsTabView = () => {
    const {query, location, history, stringifyUrl, params} = useRouter();
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const isMounted = useIsMounted();
    const [isGeneratingExcelReport, setIsGeneratingExcelReport] = useState(false);

    const translation = t("views.panel.company.products.list.instances", {returnObjects: true});

    /**
     * With each change in the path name of the location, checks if query is empty so triggers searching again
     */
    useEffect(() => {
        if (!!matchPath(location.pathname, {
            path: routes.panel.company.items.list.instances,
            exact: true
        }) && !!Object.keys(query)?.length < 1)
            search().then();
    }, [location?.pathname])


    //Panel company
    const [company] = useContext(CompanyContext);
    const selectedCompany = useRef(company);

    /**
     * With each change in the selected company:
     */
    useEffect(() => {
        if (selectedCompany.current?.id === company?.id)
            return;
        selectedCompany.current = company;
        //   TODO: implement the action related to this
    }, [company?.id]);


    /**
     * Searches for part instances:
     * - Calls teh api regarding this action
     */
    const search = async () => {
        const formApi = prepareSearchRequest();
        const response = {
            resultFlag: true,
            data: {
                items: [
                    {
                        id: 1,
                        partNumber: 'RADFR153A',
                        serializable: true,
                        serialNumber: '123-123-123',
                        warehouse: {
                            id: 1,
                            title: "00",
                        }, aisle: {
                            id: 1, title: 'A1'
                        }, section: {
                            id: 1, title: 'S1'
                        }, row: {
                            id: 1, title: 'R1'
                        }, enterDate: new Date(), exitDate: new Date(), count: 1,
                    }, {
                        id: 2, partNumber: 'CACDO102', serializable: false, serialNumber: null, warehouse: {
                            id: 1, title: "00"
                        }, aisle: {
                            id: 1, title: 'A1'
                        }, section: {
                            id: 1, title: 'S1'
                        }, row: {
                            id: 1, title: 'R1'
                        }, enterDate: new Date(), exitDate: new Date(), count: 20,
                    }, {
                        id: 3, partNumber: 'RADFR153A', serializable: true, serialNumber: '123-123-123', warehouse: {
                            id: 1, title: "00"
                        }, aisle: {
                            id: 1, title: 'A1'
                        }, section: {
                            id: 1, title: 'S1'
                        }, row: {
                            id: 1, title: 'R1'
                        }, enterDate: new Date(), exitDate: new Date(), count: 1,
                    }, {
                        id: 4, partNumber: 'CONVO108', serializable: false, serialNumber: null, warehouse: {
                            id: 1, title: "00"
                        }, aisle: {
                            id: 1, title: 'A1'
                        }, section: {
                            id: 1, title: 'S1'
                        }, row: {
                            id: 1, title: 'R1'
                        }, enterDate: new Date(), exitDate: new Date(), count: 10,
                    }, {
                        id: 5, partNumber: 'RADFR153A', serializable: true, serialNumber: '123-123-123', warehouse: {
                            id: 1, title: "00"
                        }, aisle: {
                            id: 1, title: 'A1'
                        }, section: {
                            id: 1, title: 'S1'
                        }, row: {
                            id: 1, title: 'R1'
                        }, enterDate: new Date(), exitDate: new Date(), count: 1,
                    },],
                paginationInfo: {
                    length: 5,
                }
            }
        }
        if (!isMounted()) return;
        if (response?.resultFlag) {
            setItems(response?.data.items);
            const newPaginationInfo = {
                ...paginationInfo,
                length: response?.data.paginationInfo.length ?? 0
            }
            QueryManagementUtils.setPaginationInfo(newPaginationInfo, query, history, location);
        }
        setLoading(false);
    }

    const {filters, paginationInfo, orderBy} = useSearchData(
        PartInstanceListQueryNames,
        search,
        {
            useFilters: true,
            initialFilters: {},
            filtersQueryParamMap: {
                keyword: PartInstanceListQueryNames.keyword,
                rows: PartInstanceListQueryNames.row,
                aisles: PartInstanceListQueryNames.aisle,
                sections: PartInstanceListQueryNames.section,
                warehouses: PartInstanceListQueryNames.warehouse,
                statuses: PartInstanceListQueryNames.status,
            },

            usePaginationInfo: true,
            initialPaginationInfo: initialPaginationInfo,

            useOrderBy: true,
            initialOrderBy: undefined,

        }
    );

    /**
     * prepares the api call request body based on pagination, filter, and orderBy
     */
    const prepareSearchRequest = () => {
        return {
            paginationInfo: paginationInfo,
            filters: filters,
            orderBy: orderBy,
        }
    }

    /**
     * Clears the search filters of this view to invoke a search without any filters
     */
    const clearFilters = () => {
        history.push(stringifyUrl({
            url: location.pathname, query: {
                ...generatePaginationAndOrderByQueryResetter()
            }
        }));
    }

    /**
     * Navigates user to part view:
     * Adds the part id to the url path and navigates user to the part single view
     * @param {number} id
     */
    const onPartNumberClicked = (id) => {
        history.push(routeFunctions.panel.company.items.single.product(params.company, id));
    }

    /**
     * Navigates user to part instance view:
     * Adds the serial number id to the url path and navigates user to instance single view
     * @param {number} id
     */
    const onSerialNumberClicked = (id) => {
        history.push(routeFunctions.panel.company.items.single.instance(params.company, id))
    }

    /**
     * Navigates user to aisles screen of the selected warehouse
     * @param {number} warehouseId
     */
    const onWarehouseClicked = (warehouseId) => {
        history.push(routeFunctions.panel.company.location.aisles(params.company, warehouseId.toString()));
    }

    /**
     * Navigates user to aisle view:
     */
    const onAisleClicked = (warehouseId, aisleId) => {
        history.push(routeFunctions.panel.company.location.sections(params.company, warehouseId.toString(), aisleId.toString()));
    }

    /**
     * Navigates user to aisle view:
     */
    const onSectionClicked = (warehouseId, aisleId, sectionId) => {
        history.push(routeFunctions.panel.company.location.rows(params.company, warehouseId.toString(), aisleId.toString(), sectionId.toString()));
    }

    /**
     * Navigates user to aisle view:
     */
    const onRowClicked = (warehouseId, aisleId, sectionId, rowId) => {
        history.push(routeFunctions.panel.company.location.rowItems(params.company, warehouseId.toString(), aisleId.toString(), sectionId.toString(), rowId.toString()));
    }

    /**
     * Generates excel reports for the part instances:
     */
    const generateExcelReport = async () => {
        setIsGeneratingExcelReport(true);
        const response = await search();
        if (!isMounted()) return;
        setIsGeneratingExcelReport(false);
        if (response?.resultFlag) {
            const title = translation?.excelTitle;
            const content = response.data.items?.map(e => ({
                [translation?.tableHeaders.partNumber]: e?.partNumber,
                [translation?.tableHeaders.serialNumber]: `${e.serialNumber ?? 'N/A'}`,
                [translation?.tableHeaders.warehouse]: `${e.warehouse.title}`,
                [translation?.tableHeaders.aisle]: `${e.aisle.title}`,
                [translation?.tableHeaders.section]: `${e.section.title}`,
                [translation?.tableHeaders.row]: `${e.row.title}`,
                [translation?.tableHeaders.enterDate]: `${moment(e?.enterDate).format('DD-MM-YYYY HH:mm:ss')}`,
                [translation?.tableHeaders.exitDate]: `${moment(e?.exitDate).format('DD-MM-YYYY HH:mm:ss')}`,
                [translation?.tableHeaders.count]: `${e.count}`,
            }))
            const headers = Object.keys(content[0]);
            exportCSVFile(headers, content, title);
        }
    }

    /**
     * Handles click on the barcode icon button:
     * - Opens dialog for showing the barcode
     * @param {string} partNumber for generating barcode from
     */
    const onBarcodeClicked = (partNumber) => {
        dispatch(dialogActions.configBarcodeDialog({
            open: true, onClose: () => dispatch(dialogActions.barcodeDialogToggle(false)), barcodeCode: partNumber,
        }));
    }

    /**
     * Memo version of table columns
     * @type {object[]}
     */
    const tableColumns = useMemo(() => [
        {
            title: translation?.tableHeaders.partNumber,
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: tableCellKeys.partNumber,
            sortable: true
        },
        {
            title: translation?.tableHeaders.serialNumber,
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: tableCellKeys.serialNumber,
            sortable: true,
            size: 1.5
        },
        {
            title: translation?.tableHeaders.warehouse,
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: tableCellKeys.warehouse,
            sortable: true
        },
        {
            title: translation?.tableHeaders.aisle,
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: tableCellKeys.aisle, sortable: true
        },
        {

            title: translation?.tableHeaders.section,
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: tableCellKeys.section,
            sortable: true
        },
        {
            title: translation?.tableHeaders.row,
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: tableCellKeys.row,
            sortable: true
        },
        {
            title: translation?.tableHeaders.enterDate,
            alignment: TableCellAlignments.left,
            type: TableCellTypes.dateTime,
            name: tableCellKeys.enterDate,
            sortable: true
        },
        {
            title: translation?.tableHeaders.exitDate,
            alignment: TableCellAlignments.left,
            type: TableCellTypes.dateTime,
            name: tableCellKeys.exitDate,
            sortable: true
        },
        {
            title: translation?.tableHeaders.count,
            alignment: TableCellAlignments.right,
            type: TableCellTypes.string,
            name: tableCellKeys.count,
            sortable: true
        },

    ], []);

    /**
     * Memo version of table rows records
     * @type {object[]}
     */
    const tableRows = useMemo(() => {
        return items?.map(item => ({
            key: item?.id,
            [tableCellKeys.partNumber]: () => (
                <div className={'d-flex flex-row align-items-center'}>
                    {
                        item?.partNumber?.length > 0 &&
                        <div
                            onClick={() => onBarcodeClicked(item?.partNumber)}
                            className={'barcode-icon-button'}
                        >
                            <BarcodeIcon/>
                        </div>
                    }
                    <div onClick={() => onPartNumberClicked(item?.id,)}>
                        <p className={'text-button'}>{item?.partNumber}</p>
                    </div>
                </div>
            ),
            [tableCellKeys.serialNumber]: () => (
                <div>
                    {
                        item?.serializable &&
                        <div className={'d-flex flex-row align-items-center'}>
                            {
                                item?.serialNumber?.length > 0 &&
                                <div
                                    onClick={() => onBarcodeClicked(item?.serialNumber)}
                                    className={'barcode-icon-button'}
                                >
                                    <BarcodeIcon/>
                                </div>
                            }
                            <div onClick={() => onSerialNumberClicked(item?.id)}>
                                <p className={'text-button'}>{item?.serialNumber}</p>
                            </div>
                        </div>
                    }
                    {
                        !item?.serializable &&
                        <p>{translation?.unSerialized}</p>
                    }
                </div>
            ),
            [tableCellKeys.warehouse]: () => (
                <div onClick={() => onWarehouseClicked(item.warehouse.id)}>
                    <p className={'text-button'}>{item?.warehouse?.title}</p>
                </div>
            ),
            [tableCellKeys.aisle]: () => (
                <div onClick={() => onAisleClicked(item.warehouse.id, item.aisle.id)}>
                    <p className={'text-button'}>{item?.aisle?.title}</p>
                </div>
            ),
            [tableCellKeys.section]: () => (
                <div onClick={() => onSectionClicked(item.warehouse.id, item.aisle.id, item.section.id)}>
                    <p className={'text-button'}>{item?.section?.title}</p>
                </div>
            ),
            [tableCellKeys.row]: () => (
                <div onClick={() => onRowClicked(item.warehouse.id, item.aisle.id, item.section.id, item.row.id)}>
                    <p className={'text-button'}>{item?.row?.title}</p>
                </div>
            ),
            [tableCellKeys.enterDate]: item?.enterDate,
            [tableCellKeys.exitDate]: item?.exitDate,
            [tableCellKeys.count]: item.count,
        }));

    }, [items, translation])

    return (
        <>
            <div className={'part-instances'}>
                <div className={'panel-card p-3'}>
                    <div className={'d-flex align-items-center justify-content-between mb-4 '}>
                        <div/>
                        <div className={'d-flex align-items-center justify-content-center'}>
                            <p className={'me-3 total-instance-count'}>
                                {translation?.total?.concat(paginationInfo?.length)}
                            </p>
                            <button
                                onClick={generateExcelReport}
                                disabled={isGeneratingExcelReport}
                                className={'button primary outlined report'}
                            >
                                <ConvertToExcel className={'me-1'}/>
                                <p>
                                    {!isGeneratingExcelReport ? (translation?.convertToExcel ?? '') : (translation?.generatingReport ?? '')}
                                </p>
                            </button>
                        </div>
                    </div>
                    <InstancesSearchSection
                        filters={filters}
                        warehouses={warehouses}
                        aisles={aisles}
                        rows={rows}
                        sections={sections}
                        statuses={statuses}
                    />
                    <div>
                        {
                            (items?.length < 1 && !loading)
                                ? <NoEntries
                                    text={Object.entries(filters ?? {})?.length > 0 ? translation?.empty?.withFilters : translation?.empty?.text}
                                    includeButton={Object.entries(filters ?? {})?.length > 0}
                                    buttonProps={{
                                        onClick: clearFilters, className: 'button primary px-3'
                                    }}/>
                                : <Table
                                    loading={{state: loading, count: 8}}
                                    className={'my-3'}
                                    color={'primary'}
                                    cells={tableColumns}
                                    data={tableRows}
                                    paginationInfo={paginationInfo}
                                    orderBy={orderBy}
                                    onSort={(orderBy) => QueryManagementUtils.setOrderBy(
                                        orderBy,
                                        query,
                                        history,
                                        location,
                                    )}
                                    onPageSizeChange={(p, i) => QueryManagementUtils.setPaginationInfo(
                                        {...p, pageSize: i},
                                        query,
                                        history,
                                        location
                                    )}
                                    onCurrentPageChange={(p, newP) => QueryManagementUtils.setPaginationInfo(
                                        {...p, currentPage: newP},
                                        query,
                                        history,
                                        location,
                                    )}
                                />
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default ItemsTabView;
