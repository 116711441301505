import React, {useLayoutEffect, useRef, useState} from "react";
import useWindowViewportWidth from "../../../hooks/use-window";
import classnames from "classnames";

/**
 * Determines the position fo features cards based on their index and the current viewportWidth
 * @param {number} index
 * @param {string} width
 * @return {{bottom: number, right: number}|{top: number, right: number}|{}|{top: number, left: number}}
 */
const getPositionOfCard = (index, width) => {
    switch (index) {
        case 0: // the first features card (top-left)
        default:
            return {
                bottom: 0,
                right: 15,
            };
        case 1: // the second features card (top-right)
            switch (width) {
                case 'sm':
                case 'xs':
                    return {
                        left: 0,
                        top: 30,
                    }
                default:
                    break;
            }
            return {};
        case 2: // the third features card (bottom-left)
            switch (width) {
                case 'sm':
                case 'xs':
                    return {
                        right: 0,
                        top: -40,
                    }
                case 'md':
                    return {
                        top: 35,
                        right: 5
                    };
                default:
                    break;
            }
            return {
                right: 90,
                top: 35,
            };
        case 3:// the fourth features card (bottom-right)
            switch (width) {
                case 'sm':
                case 'xs':
                    return {
                        left: 0,
                        top: -110,
                    }
                default:
                    break;
            }
            return {
                top: -15,
                left: 0
            };
    }
}

const FeatureCard = ({feature, delay, index}) => {
    const [height, setHeight] = useState();
    const windowViewportWidth = useWindowViewportWidth();
    /**@type {React.MutableRefObject<HTMLElement>}*/
    const ref = useRef();

    /**
     * Listens to the changes in the mainImage svg and the width of viewport and with each changeL:
     * Sets the height of the containing element so that it is in sync with the features card.
     */
    useLayoutEffect(() => {
        if (!ref?.current) return;
        const newHeight = ref.current.getBoundingClientRect().height;
        if (newHeight !== height) {
            setHeight(newHeight);
        }
    }, [ref.current, windowViewportWidth])

    return (
        <div
            style={{minHeight: (height ?? 0) + 100}}
            className={'position-relative'}
            data-aos={feature.animationType}
            data-aos-delay={delay}
        >
            <div
                style={{
                    ...getPositionOfCard(index, windowViewportWidth),
                }}
                ref={ref}
                className={classnames(`feature-card px-3 pt-2 pb-4 `)}>
                <section className='mt-2'>
                    {feature.icon}
                    <h4 className='d-inline-block px-2'>
                        {feature.title}
                    </h4>
                </section>
                <section className='mt-3'>
                    <p>
                        {
                            feature.body?.filter(e => e?.length)?.map((text, index) => (
                                <span key={index}>
                                        {text}
                                    <br/>
                                </span>
                            ))
                        }
                    </p>
                </section>
            </div>
        </div>
    )
}

export default FeatureCard;
