import React, {useContext, useEffect, useState} from 'react';
import {ReactComponent as BulletPointIcon} from '../../../../../../../assets/images/views/dashboard/bullet-point-icon.svg';
import {CompanyContext} from "../../../../../../contexts/company";
import {useTranslation} from "react-i18next";
import moment from "moment";

const UserLogs = () => {
    const [company] = useContext(CompanyContext) ?? {};
    const [logs, setLogs] = useState([]);
    const [loadingLogs, setLoadingLogs] = useState(true);
    const {i18n, t} = useTranslation();
    const translations = t('views.panel.company.dashboard.userLogs', {returnObjects: true})

    /**
     * Listens to the changes of the selected company and selected language and with each change:
     * Retrieves the user logs associated with the new company
     */
    useEffect(() => {
        if (!company.id) return;
        getUserLogs();
    }, [company.id, i18n.language]);

    /**
     * Fetches the recent logs belong to the selected company form the server
     */
    const getUserLogs = () => {
        setLoadingLogs(true);
        // TODO:  Call the API for getting the list of user logs
        const logs = [
            {
                id: 1,
                type: 'Purchase',
                number: '000456',
                operator: {
                    fName: 'Alireza',
                    lName: 'Kakan'
                },
                operationType: {
                    id: 1,
                    title: 'Scan'
                },
                submitted: '2020-09-21T16:00'
            },
            {
                id: 2,
                type: 'Counting',
                number: '000456',
                operator: {
                    fName: 'Alireza',
                    lName: 'Kakan'
                },
                operationType: {
                    id: 3,
                    title: 'Close'
                },
                submitted: '2021-09-21T16:00'
            }
        ];
        setLogs(generateLogMessage(logs));
        setLoadingLogs(false);
    }

    /**
     * Generates the title and the message for the provided logs
     */
    const generateLogMessage = (logs) => {
        // remove this when api is ready
        const userLogOperationTypes = {
            Scan: {id: 1, title: 'Scan', message: 'has scanned the'},
            Submit: {id: 2, title: 'Submit', message: 'has submitted the'},
            Close: {id: 3, title: 'Close', message: 'has closed the'}
        }
        //TODO: change when the api is ready
        return logs?.map((log) => {
            const operation = log.operationType;
            switch (operation.id) {
                case userLogOperationTypes.Scan.id:
                    return {
                        ...log,
                        title: `${log.type} - ${log.number} ${log.operator.fName} ${log.operator.lName}`,
                        message: `${userLogOperationTypes.Scan.message} ${log.type}`
                    };
                case userLogOperationTypes.Close.id:
                    return {
                        ...log,
                        title: `${log.type} - ${log.number} ${log.operator.fName} ${log.operator.lName}`,
                        message: `${userLogOperationTypes.Close.message} ${log.type}`
                    };
                case userLogOperationTypes.Submit.id:
                    return {
                        ...log,
                        title: `${log.type} - ${log.number} ${log.operator.fName} ${log.operator.lName}`,
                        message: `${userLogOperationTypes.Submit.message} ${log.type}`
                    };
                default:
                    return log;
            }
        })
    }

    return (
        <div className='logs'>
            <p className='title'>
                {translations.title}
            </p>
            {
                loadingLogs
                    ? (
                        <div className={'d-flex flex-wrap'}>
                            {
                                Array(10).fill(null).map((e, index) => (
                                    <div key={index} className={'loading user-log'}>
                                        <div/>
                                    </div>
                                ))
                            }
                        </div>
                    )
                    : (
                        <table className={'logs-table'}>
                            <tbody>
                            {
                                logs?.map(log => (
                                    <tr key={log.id} className={'t-row'}>
                                        <td valign={"top"} style={{width: 40}}>
                                            <i className='mx-2'>
                                                <BulletPointIcon/>
                                            </i>
                                        </td>
                                        <td>
                                            <span className='bold'>{log.title}</span>
                                            <span>{log.message}</span>
                                        </td>
                                        <td valign={"top"}>
                                            <span className='submit-time'>{moment(log.submitted).fromNow()}</span>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    )
            }
        </div>
    )
}

export default UserLogs;
