import {History, Location} from 'history';
import ApiRequestDs from "../models/requests/api_request.ds";
import apiCallback from "./api_callback";
import {ApiHeaderNames, ApiMethods} from "../constants/enums";
import {
    aisleApis,
    authApis,
    companyApis,
    rowApis,
    sectionApis,
    spireCountingApis,
    spireCountingReportApis,
    warehouseApis,
    warehouseSyncApis
} from "../constants/endpoints/api_endpoints";
import {CrudResponse} from "../models/response/crud-response";
import routes from "../../ui/routes";
import {mapboxPlacesApis} from "../constants/endpoints/maxbox_api_endpoints";


/**
 * Interface that handles all the api calls to the server.
 */
export default class Api {
    static history: History;
    static location: Location;

    // ########################################     Helper Methods    ########################################

    /**
     * Updates the history of Api
     * @param history
     */
    static setHistory = (history: History) => {
        if (!history) return;
        Api.history = history;
    }

    /**
     * Updates the location of Api
     * @param location
     */
    static setLocation = (location: Location) => {
        if (!location) return;
        Api.location = location;
    }

    /**
     * Prepares the APi Request by injecting the history and location into the api call back
     * @param {ApiRequestDs | any} request
     * @return {ApiRequestDs}
     */
    static prepareRequest = (request: ApiRequestDs): ApiRequestDs => {
        return {
            ...request,
            history: Api.history,
            location: Api.location,
        }
    }

    // ########################################     Auth APIS   ########################################

    /**
     * Get the configuration of the site from api including the api url, login url, logout url of core
     *
     * @return {CrudResponse}
     */
    static getApplicationConfigurations = async (): CrudResponse<any> => {
        // const result = await apiCallback(Api.prepareRequest({
        //     url: authApis.getConfiguration,
        //     method: ApiMethods.get,
        //     redirectToError: true,
        //     loginRequired: false,
        // }));
        // if (!result) {
        //     Api.history.push(routes.error.serverError);
        // } else return result;
        //TODO: remove in production
        return {
            resultFlag: true,
            message: null,
            headerTitle: null,
            errorCode: null,
            data: {
                baseUrl: "https://bizcore.bizkeytech.com",
                loginUrl: "https://bizcore.bizkeytech.com/login",
                logoutUrl: "https://bizcore.bizkeytech.com/logout",
            }
        };

    }

    /**
     * Logs the user in the system provided the token
     *
     * if the login api had an error, redirects the user to serverError page
     * if the logic api's response was not successful, redirects the user to accessDenied page
     *
     * @param token             the token generated by Core
     * @return {CrudResponse}
     */
    static login = async (token: string): CrudResponse<any> => {
        const result = await apiCallback(Api.prepareRequest({
            url: authApis.login,
            method: ApiMethods.post,
            body: {token},
            showSuccessToast: true,
            loginRequired: false,
        }));
        if (!result || !result.resultFlag) {
            console.error(Error(`Api Result Failed at login with ${result?.status ?? result?.errorCode}`))
            if (!result) Api.history.push(routes.error.serverError);
            else if (!result.resultFlag) Api.history.replace(routes.error.accessDenied);
        }
        return result;
    }


    // ########################################     Company APIS   ########################################

    /**
     * Fetches the list of available companies of the user.
     *
     * @return {CrudResponse}
     */
    static getCompanies = async (): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: companyApis.getList,
            method: ApiMethods.get,
            redirectToError: true,
        }))
    }

    /**
     * Sets a selected company as the default company of the user.
     *
     * @param companyId         the company to be set as default
     * @return {CrudResponse}
     */
    static setDefaultCompany = async (companyId: number): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: companyApis.setDefault(companyId),
            method: ApiMethods.put,
            showErrorToast: false,
        }))
    }

    // ########################################     Warehouse APIS   ########################################

    /**
     * Fetches the list of available warehouses of a selected company
     *
     * @return {CrudResponse}
     */
    static getWarehouses = async (): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: warehouseApis.getList,
            method: ApiMethods.get,
        }))
    }

    /**
     * Sets a selected warehouse as the default warehouse of the user.
     *
     * @param warehouseId       the warehouse to be set as default
     * @return {CrudResponse}
     */
    static setDefaultWarehouse = async (warehouseId: number): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: warehouseApis.setDefault(warehouseId),
            method: ApiMethods.put,
            showSuccessToast: true,
        }))
    }

    /**
     * Creates a new warehouse for the selected company of the user
     *
     * @param warehouse         the warehouse to be created
     * @return {CrudResponse}
     */
    static createWarehouse = async (warehouse: any): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: warehouseApis.create,
            method: ApiMethods.post,
            body: warehouse,
            showSuccessToast: true,
        }))
    }

    /**
     * Updates the information of an existing warehouse of the user.
     *
     * @param warehouse         the warehouse to be updated
     * @return {CrudResponse}
     */
    static updateWarehouse = async (warehouse: any): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: warehouseApis.update,
            method: ApiMethods.put,
            body: warehouse,
            showSuccessToast: true,
        }))
    }

    /**
     * Removes a selected warehouse of the user.
     *
     * @param warehouseId       the warehouse to be removed
     * @return {CrudResponse}
     */
    static removeWarehouse = async (warehouseId: number): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: warehouseApis.remove(warehouseId),
            method: ApiMethods.delete,
            showSuccessToast: true,
        }))
    }


    // ########################################     Aisle APIS   ########################################

    /**
     * Fetches the list of available aisles of a selected warehouse of the user
     *
     * @param warehouseId       the warehouse to fetch the aisles of
     * @return {CrudResponse}
     */
    static getAisles = async (warehouseId: number): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: aisleApis.getList,
            method: ApiMethods.get,
            headers: {
                [ApiHeaderNames.warehouseId]: warehouseId,
            },
        }))
    }

    /**
     * Creates a new aisle for the selected warehouse of the user
     *
     * @param aisle             the aisle to create
     * @param warehouseId       the warehouse to create the aisle into
     * @return {CrudResponse}
     */
    static createAisle = async (aisle: any, warehouseId: number): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: aisleApis.create,
            method: ApiMethods.post,
            body: aisle,
            showSuccessToast: true,
            headers: {
                [ApiHeaderNames.warehouseId]: warehouseId,
            },
        }))
    }

    /**
     * Updates the information of an existing aisle of the user.
     *
     * @param aisle             the aisle to be updated
     * @param warehouseId       the warehouse to update the aisle of
     * @return {CrudResponse}
     */
    static updateAisle = async (aisle: any, warehouseId: number): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: aisleApis.update,
            method: ApiMethods.put,
            body: aisle,
            showSuccessToast: true,
            headers: {
                [ApiHeaderNames.warehouseId]: warehouseId,
            },
        }))
    }

    /**
     * Removes a selected aisle of the user.
     *
     * @param aisleId           the aisle to be removed
     * @param warehouseId       the warehouse to remove the aisle of
     * @return {CrudResponse}
     */
    static removeAisle = async (aisleId: number, warehouseId: number): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: aisleApis.remove(aisleId),
            method: ApiMethods.delete,
            showSuccessToast: true,
            headers: {
                [ApiHeaderNames.warehouseId]: warehouseId,
            },
        }))
    }

    /**
     * Loads the information of a selected aisle of the user
     *
     * @param code              the aisle code to get the information of
     * @param warehouseId       the warehouse that the aisle belongs to
     * @return {CrudResponse}
     */
    static loadAisleInformation = async (code: string, warehouseId: number): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: aisleApis.loadInfo(code),
            method: ApiMethods.get,
            headers: {
                [ApiHeaderNames.warehouseId]: warehouseId,
            },
        }))
    }


    // ########################################     Section APIS   ########################################

    /**
     * Fetches the list of available sections of a selected aisle of the user
     *
     * @param aisleId           the aisle to fetch the sections of
     * @param warehouseId       the warehouse that the section belongs to
     * @return {CrudResponse}
     */
    static getSections = async (aisleId: number, warehouseId: number): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: sectionApis.getList(aisleId),
            method: ApiMethods.get,
            headers: {
                [ApiHeaderNames.warehouseId]: warehouseId,
            },
        }))
    }

    /**
     * Creates a new section for the selected aisle of the user
     *
     * @param section           the section to create
     * @param warehouseId       the warehouse to create the section into
     * @return {CrudResponse}
     */
    static createSection = async (section: any, warehouseId: number): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: sectionApis.create,
            method: ApiMethods.post,
            body: section,
            showSuccessToast: true,
            headers: {
                [ApiHeaderNames.warehouseId]: warehouseId,
            },
        }))
    }

    /**
     * Updates the information of an existing section of the user.
     *
     * @param section           the section to be updated
     * @param warehouseId       the warehouse to update the section of
     * @return {CrudResponse}
     */
    static updateSection = async (section: any, warehouseId: number): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: sectionApis.update,
            method: ApiMethods.put,
            body: section,
            showSuccessToast: true,
            headers: {
                [ApiHeaderNames.warehouseId]: warehouseId,
            },
        }))
    }

    /**
     * Removes a selected section of the user.
     *
     * @param sectionId         the section to be removed
     * @param warehouseId       the warehouse to remove the aisle of
     * @return {CrudResponse}
     */
    static removeSection = async (sectionId: number, warehouseId: number): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: sectionApis.remove(sectionId),
            method: ApiMethods.delete,
            showSuccessToast: true,
            headers: {
                [ApiHeaderNames.warehouseId]: warehouseId,
            },
        }))
    }

    /**
     * Loads the information of a selected section of the user
     *
     * @param code              the section code to get the information of
     * @param warehouseId       the warehouse that the section belongs to
     * @return {CrudResponse}
     */
    static loadSectionInformation = async (code: string, warehouseId: number): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: sectionApis.loadInfo(code),
            method: ApiMethods.get,
            headers: {
                [ApiHeaderNames.warehouseId]: warehouseId,
            },
        }))
    }


    // ########################################     Row APIS   ########################################

    /**
     * Fetches the list of available rows of a selected section of the user
     *
     * @param sectionId          the section to fetch the rows of
     * @param warehouseId        the warehouse that the row belongs to
     * @return {CrudResponse}
     */
    static getRows = async (sectionId: number, warehouseId: number): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: rowApis.getList(sectionId),
            method: ApiMethods.get,
            headers: {
                [ApiHeaderNames.warehouseId]: warehouseId,
            },
        }))
    }

    /**
     * Creates a new row for the selected section of the user
     *
     * @param row           the row to create
     * @param warehouseId       the warehouse to create the row into
     * @return {CrudResponse}
     */
    static createRow = async (row: any, warehouseId: number): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: rowApis.create,
            method: ApiMethods.post,
            body: row,
            showSuccessToast: true,
            headers: {
                [ApiHeaderNames.warehouseId]: warehouseId,
            },
        }))
    }

    /**
     * Updates the information of an existing row of the user.
     *
     * @param row               the row to be updated
     * @param warehouseId       the warehouse to update the row of
     * @return {CrudResponse}
     */
    static updateRow = async (row: any, warehouseId: number): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: rowApis.update,
            method: ApiMethods.put,
            body: row,
            showSuccessToast: true,
            headers: {
                [ApiHeaderNames.warehouseId]: warehouseId,
            },
        }))
    }

    /**
     * Removes a selected row of the user.
     *
     * @param rowId             the row to be removed
     * @param warehouseId       the warehouse to remove the row of
     * @return {CrudResponse}
     */
    static removeRow = async (rowId: number, warehouseId: number): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: rowApis.remove(rowId),
            method: ApiMethods.delete,
            showSuccessToast: true,
            headers: {
                [ApiHeaderNames.warehouseId]: warehouseId,
            },
        }))
    }

    /**
     * Loads the information of a selected row of the user
     *
     * @param rowId             the row id to get the information of
     * @param warehouseId       the warehouse that the row belongs to
     * @return {CrudResponse}
     */
    static loadRowInformation = async (rowId: number, warehouseId: number): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: rowApis.loadInfo(rowId),
            method: ApiMethods.get,
            headers: {
                [ApiHeaderNames.warehouseId]: warehouseId,
            },
        }))
    }

    // ########################################     WarehouseSynchronize  APIS  ########################################

    /**
     * Loads the list of available warehouse synchronize of the user based on its selected company
     *
     * @return {CrudResponse}
     */
    static getWarehouseSynchronizeList = async (): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: warehouseSyncApis.getList,
            method: ApiMethods.get,
        }))
    }

    /**
     * Creates a warehouse sync manual for a specific warehouse of the user.
     *
     * @param warehouseId       the warehouse to create its sync model for
     * @return {CrudResponse}
     */
    static createManualWarehouseSynchronize = async (warehouseId: number): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: warehouseSyncApis.createManual,
            method: ApiMethods.post,
            showSuccessToast: true,
            headers: {
                [ApiHeaderNames.warehouseId]: warehouseId,
            },
        }))
    }

    /**
     * Applies for a warehouse sync manual for a specific warehouse of the user.
     *
     * @param warehouseId       the warehouse to apply a sync model for
     * @param warehouseSyncId   the warehouse sync to apply for
     * @return {CrudResponse}
     */
    static applyForWarehouseSynchronize = async (warehouseId: number, warehouseSyncId: number): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: warehouseSyncApis.apply(warehouseSyncId),
            method: ApiMethods.post,
            showSuccessToast: true,
            headers: {
                [ApiHeaderNames.warehouseId]: warehouseId,
            },
        }))
    }

    /**
     * Fetches the part numbers of a specific warehouse sync of the user.
     *
     * @param warehouseId       the warehouse to fetch sync model's report for
     * @param warehouseSyncId   the warehouse sync to fetch the report for
     * @return {CrudResponse}
     */
    static getWarehouseSyncPartsReport = async (warehouseId: number, warehouseSyncId: number): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: warehouseSyncApis.getPartReport(warehouseSyncId),
            method: ApiMethods.get,
            headers: {
                [ApiHeaderNames.warehouseId]: warehouseId,
            },
        }))
    }


    /**
     * Fetches the serial numbers of a specific warehouse sync of the user.
     *
     * @param warehouseId       the warehouse to fetch sync model's report for
     * @param warehouseSyncId   the warehouse sync to fetch the report for
     * @return {CrudResponse}
     */
    static getWarehouseSyncSerialsReport = async (warehouseId: number, warehouseSyncId: number): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: warehouseSyncApis.getSerialReport(warehouseSyncId),
            method: ApiMethods.get,
            headers: {
                [ApiHeaderNames.warehouseId]: warehouseId,
            },
        }))
    }

    // ########################################     SpireCounting APIS     ########################################


    /**
     * Fetches the list of spire counting requests for the selected company of the user.
     *
     * @return {CrudResponse}
     */
    static getSpireCountingRequests = async (): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: spireCountingApis.getList,
            method: ApiMethods.get,
        }))
    }

    /**
     * Fetches the the information of a specific spire counting of the uer.
     *
     * @param requestId         the spire counting to fetch the information of
     * @return {CrudResponse}
     */
    static getSpireCountingInformation = async (requestId: number): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: spireCountingApis.getById(requestId),
            method: ApiMethods.get,
        }))
    }

    /**
     * Creates a new spire counting request for the selected warehouse of the user.
     *
     * @param spireCountingRequest  the spire counting to be created
     * @param warehouseId           the warehouse to create the spire counting for.
     * @return {CrudResponse}
     */
    static createSpireCountingRequest = async (spireCountingRequest: any, warehouseId: number): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: spireCountingApis.create,
            method: ApiMethods.post,
            body: spireCountingRequest,
            showSuccessToast: true,
            headers: {
                [ApiHeaderNames.warehouseId]: warehouseId,
            },
        }))
    }

    /**
     * Creates a recount spire counting request for a selected warehouse and its part numbers.
     *
     * @param spireCountingRecountRequest   the spire counting recount request to be created
     * @param warehouseId                   the warehouse to create the recount spire counting request for.
     * @return {CrudResponse}
     */
    static createRecountSpireCountingRequest = async (spireCountingRecountRequest: any, warehouseId: number): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: spireCountingApis.createRecount,
            method: ApiMethods.post,
            body: spireCountingRecountRequest,
            showSuccessToast: true,
            headers: {
                [ApiHeaderNames.warehouseId]: warehouseId,
            },
        }))
    }

    /**
     * Updates the information of an existing spire counting request of the user.
     *
     * @param spireCountingRequest          the spire counting request to be updated
     * @param warehouseId                   the warehouse to update the spire counting request for.
     * @return {CrudResponse}
     */
    static updateSpireCountingRequest = async (spireCountingRequest: any, warehouseId: number): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: spireCountingApis.update,
            method: ApiMethods.put,
            body: spireCountingRequest,
            showSuccessToast: true,
            headers: {
                [ApiHeaderNames.warehouseId]: warehouseId,
            },
        }))
    }

    /**
     * Removes an existing spire counting request of the user.
     *
     * @param spireCountingRequestId        the spire counting request to be removed
     * @return {CrudResponse}
     */
    static removeSpireCountingRequest = async (spireCountingRequestId: number): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: spireCountingApis.remove(spireCountingRequestId),
            method: ApiMethods.delete,
            showSuccessToast: true,
        }))
    }

    /**
     * Fetches the list of participants of a particular spire counting.
     *
     * @param spireCountingId        the spire counting to fetch its participants from
     * @param warehouseId            the warehouse to fetch the spire counting participants for.
     * @return {CrudResponse}
     */
    static getSpireCountingParticipants = async (spireCountingId: number, warehouseId: number): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: spireCountingApis.getParticipants(spireCountingId),
            method: ApiMethods.delete,
            headers: {
                [ApiHeaderNames.warehouseId]: warehouseId,
            },
        }))
    }

    /**
     * Fetches the list of Scanned products of a specific spire counting.
     *
     * @param filters               the spire counting to fetch its scanned products from
     * @return {CrudResponse}
     */
    static getSpireCountingScannedItems = async (filters: any): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: spireCountingApis.getScannedItems,
            method: ApiMethods.post,
            body: filters,
        }))
    }

    /**
     * Fetches the list of Scanned serials of a specific spire counting.
     *
     * @param filters               the spire counting to fetch its scanned products from
     * @return {CrudResponse}
     */
    static getSpireCountingScannedSerials = async (filters: any): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: spireCountingApis.getScannedSerials,
            method: ApiMethods.post,
            body: filters,
        }))
    }

    /**
     * Updates the note for a spire counting frozen serial number in a specific spire counting of a user.
     *
     * @param note              the note to be updated
     * @param warehouseId       the warehouse to update the spire counting serial note for.
     * @return {CrudResponse}
     */
    static updateSpireCountingFrozenSerialNote = async (note: any, warehouseId: number): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: spireCountingApis.updateFrozenNote,
            method: ApiMethods.post,
            body: note,
            showSuccessToast: true,
            headers: {
                [ApiHeaderNames.warehouseId]: warehouseId,
            },
        }))
    }

    /**
     * Updates the note for a spire counting scanned serial number in a specific spire counting of a user.
     *
     * @param note              the note to be updated
     * @param warehouseId       the warehouse to update the spire counting serial note for.
     * @return {CrudResponse}
     */
    static updateSpireCountingScannedSerialNote = async (note: any, warehouseId: number): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: spireCountingApis.updateScannedNote,
            method: ApiMethods.post,
            body: note,
            showSuccessToast: true,
            headers: {
                [ApiHeaderNames.warehouseId]: warehouseId,
            },
        }))
    }

    /**
     * Updates the note for a spire counting un-serialized part number in a specific spire counting of a user.
     *
     * @param note              the note to be updated
     * @param warehouseId       the warehouse to update the spire counting part note for.
     * @return {CrudResponse}
     */
    static updateSpireCountingUnSerializedItemNote = async (note: any, warehouseId: number): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: spireCountingApis.updateUnSerializedNote,
            method: ApiMethods.post,
            body: note,
            showSuccessToast: true,
            headers: {
                [ApiHeaderNames.warehouseId]: warehouseId,
            },
        }))
    }

    // ########################################    SpireCountingReport APIS     ########################################

    /**
     * Fetches the frozen products report of a specific spire counting report
     *
     * @param requestId         the spire counting request to get the report of
     * @param warehouseId       the warehouse that the spire counting request
     * @return {CrudResponse}
     */
    static getSpireCountingFrozenReport = async (requestId: number, warehouseId: number): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: spireCountingReportApis.getFrozenReport(requestId),
            method: ApiMethods.get,
            showSuccessToast: true,
            headers: {
                [ApiHeaderNames.warehouseId]: warehouseId,
            },
        }))
    }

    /**
     * Fetches the scanned products report of a specific spire counting report
     *
     * @param requestId         the spire counting request to get the report of
     * @param warehouseId       the warehouse that the spire counting request
     * @return {CrudResponse}
     */
    static getSpireCountingScannedReport = async (requestId: number, warehouseId: number): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: spireCountingReportApis.getScannedReport(requestId),
            method: ApiMethods.get,
            showSuccessToast: true,
            headers: {
                [ApiHeaderNames.warehouseId]: warehouseId,
            },
        }))
    }

    /**
     * Fetches the general report of a specific spire counting report
     *
     * @param filters           the filters used for the report generation
     * @param warehouseId       the warehouse that the spire counting request
     * @return {CrudResponse}
     */
    static getSpireCountingGeneralReport = async (filters: any, warehouseId: number): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: spireCountingReportApis.getGeneralReport,
            method: ApiMethods.post,
            body: filters,
            showSuccessToast: true,
            headers: {
                [ApiHeaderNames.warehouseId]: warehouseId,
            },
        }))
    }


    // ########################################     MAPBOX PLACES APIS    ########################################


    /**
     * Fetches the Address geolocation based on the provided lng & lat of a particular marker
     *
     * @param lng               the longitude of the marker
     * @param lat               the latitude of the marker
     * @param accessToken       the access token of mapboxGl
     * @return {CrudResponse}
     */
    static getMapboxMarkerAddress = async (lng: number, lat: number, accessToken: string): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: mapboxPlacesApis.getMarkerAddress(lng, lat, accessToken),
            method: ApiMethods.get,
            loginRequired: false,
            externalResponse: true,
        }))
    }

    /**
     * Fetches the general report of a specific spire counting report
     *
     * @param address               the address of the given location
     * @param accessToken           the access token of mapboxGl
     * @param country               the country in which the address should be looked up.
     * @return {CrudResponse}
     */
    static getMapboxCoordinates = async (address: string, accessToken: string, country?: string): CrudResponse<any> => {
        return await apiCallback(Api.prepareRequest({
            url: mapboxPlacesApis.getAddressCoordinates(address, accessToken, country),
            method: ApiMethods.get,
            loginRequired: false,
            externalResponse: true,
        }))
    }


    // ########################################     LOCAL FETCHER     ########################################

    /**
     * Fetches the local json files of the application that are located in the public folder.
     *
     * @param fileNames             the name of the files to be fetched
     * @param directory             the directory path to get the local json files from.
     * @return {Promise<{data: any, fileName: string}[]>}
     */
    static fetchLocalJsonFiles = async (fileNames: string[], directory: string = '/assets/content/')
        : Promise<{ data: any, fileName: string }[]> => {
        const localFiles: any = {};
        const promises: Promise<{ data: any, fileName: string }>[] = [];
        fileNames.forEach((fileName) => {
            promises.push(Api._localFileFetcher(`${fileName}.json`, directory));
        });
        return await Promise.all(promises).then((results) => {
            [...results].forEach((result) => {
                // make sure to use camelCase notation for the fileNames after the fetch in case they were snakeCase
                const fileName = result.fileName.replaceAll(new RegExp(/-[a-z]/g), (string) => `${string.charAt(1).toUpperCase()}`)
                localFiles[fileName] = result.data;
            })
            return localFiles;
        });
    }

    /**
     * Fetches a single file from the public folder of the application
     *
     * @param directory             the directory path to get the local json files from.
     * @param filename
     * @private
     */
    static _localFileFetcher = async (filename: string, directory: string)
        : Promise<{ data: any | null, fileName: string }> => {
        try {
            const fetchResult = await fetch(`${process.env.PUBLIC_URL}${directory}${filename}`);
            return {data: await fetchResult.json(), fileName: filename}
        } catch (e) {
            return {data: null, fileName: filename};
        }
    }
}
