import React, {useEffect} from "react";
import useRouter from "../../../../hooks/use-router";
import {matchPath, Route, Switch} from "react-router";
import routes, {routeFunctions} from "../../../../routes";
import SpireCountingListView from "./spire/list";
import SpireCountingSingleView from "./spire/single";
import PositionCountingListView from "./position/list";
import PositionCountingSingleView from "./position/single";

const CountingViews = () => {
    const {location, history, params} = useRouter();

    /**
     * Listens to the changes in pathname of the url route and with each change:
     * if the route is the same as the panel.company.counting.base, changes it to the spire counting view.
     */
    useEffect(() => {
        if (!!matchPath(location.pathname, {path: routes.panel.company.counting.base, exact: true})) {
            history.replace(routeFunctions.panel.company.counting.spire(params.company));
        }
    }, [location.pathname])

    return (
        <>
            <Switch>
                <Route path={routes.panel.company.counting.list.spireCountings}>
                    <SpireCountingListView/>
                </Route>
                <Route path={routes.panel.company.counting.single.spireCounting} exact>
                    <SpireCountingSingleView/>
                </Route>
                <Route path={routes.panel.company.counting.list.positionCountings} exact>
                    <PositionCountingListView/>
                </Route>
                <Route path={routes.panel.company.counting.single.positionCounting} exact>
                    <PositionCountingSingleView/>
                </Route>
            </Switch>
        </>
    )
}

export default CountingViews;
