import React, {useEffect} from "react";
import {matchPath, Route, Switch} from "react-router";
import routes, {routeFunctions} from "../../../../routes";
import SalesOrderListView from "./list";
import SalesOrderSingleView from "./single";
import useRouter from "../../../../hooks/use-router";

const SalesOrderView = () => {
    const {location, history,params} = useRouter();


    /**
     * With each change in the path name of the url:
     * -Checks if the route is the sames as routes.panel.company.salesOrders.base, then changes it to sales order list view
     */
    useEffect(()=>{
        if(!!matchPath(location.pathname, {path:routes.panel.company.salesOrders.base ,exact:true})){
            history.replace(routeFunctions.panel.company.salesOrders.list(params.company));
        }
    },[location.pathname]);

    return (
        <>
            <Switch>
                <Route path={routes.panel.company.salesOrders.list.salesOrders}>
                    <SalesOrderListView/>
                </Route>
                <Route path={routes.panel.company.salesOrders.single.salesOrder}>
                    <SalesOrderSingleView/>
                </Route>
            </Switch>
        </>
    );
}

export default SalesOrderView;
