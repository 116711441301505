import i18next from "i18next";


/**
 * The Validation Messages in this application
 */
export default class ValidateMessages {
    static incorrect: string = i18next?.t('utils.formValidation.incorrect') ?? "The field value is incorrect";
    static incorrectType = (type: string) => i18next?.t('utils.formValidation.incorrectType', {type}) ?? `The value must be a valid ${type}`;
    static required: string = i18next?.t('utils.formValidation.required') ?? 'This field is required';
    static minLength = (type: string, length: number) => i18next?.t('utils.formValidation.minLength', {
        type,
        length
    }) ?? `${type} must be at least ${length} characters`
}
