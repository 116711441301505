import React, {useEffect, useState} from 'react';
import {Prompt} from 'react-router-dom';
import useRouter from "../../../hooks/use-router";
import Dialog from "../dialog";
import {DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import {useTranslation} from "react-i18next";


const RouteLeavingGuard = ({
                               when = true,
                               navigate,
                               shouldBlockNavigation,
                               confirmationTitle,
                               confirmationMessage,
                           }) => {
    const {history} = useRouter();
    const {t} = useTranslation();
    const [openPrompt, setOpenPrompt] = useState(false);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);
    const [savedLocation, setSavedLocation] = useState();
    const title = confirmationTitle ?? t('dialogs.routeLeavingGuardDialog.title');
    const message = confirmationMessage ?? t('dialogs.routeLeavingGuardDialog.message');

    /**
     * Listens for the changes in savedLocation and confirmationNavigation and with each change:
     * if confirmedNavigation and savedLocation then navigates the user to the savedLocation.
     */
    useEffect(() => {
        if (!confirmedNavigation || !savedLocation) return;
        if (navigate) {
            navigate(savedLocation.pathname);
        } else {
            history.push(savedLocation.pathname);
        }
    }, [confirmedNavigation, savedLocation]);

    /**
     * Adds the confirmation trigger for default browser behaviour on reload or back-button click confirmation
     */
    useEffect(() => {
        if (!when) return clearWindowEventListeners;
        window.addEventListener('beforeunload', triggerConfirmationForBeforeunload);
        return clearWindowEventListeners;
    }, [when])

    /**
     * Clears the attached event listeners of the window.
     */
    const clearWindowEventListeners = () => {
        window.removeEventListener('beforeunload', triggerConfirmationForBeforeunload);
    }

    /**
     * Triggers browsers' confirmation popup in case of leaving the page or reloading the page.
     * @param e
     * @return {string}
     */
    const triggerConfirmationForBeforeunload = (e) => {
        e.preventDefault();
        e.returnValue = message;
        return message;
    }

    /**
     * Blocks the navigation of the route by checking whether the route is to be blocked or not. If blocked, shows
     * the confirmation prompt.
     * @param nextLocation {Location}
     * @return {boolean} whether to allow navigation
     */
    const blockNavigation = (nextLocation) => {
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            setOpenPrompt(true);
            setSavedLocation(nextLocation);
            return false;
        }
        return true;
    };

    /**
     * Closes the modal and sets the confirmedNavigation to trigger the location change
     */
    const onConfirmNavigation = () => {
        setOpenPrompt(false);
        setConfirmedNavigation(true);
    };

    /**
     * Closes the dialog
     */
    const closeDialog = () => {
        setOpenPrompt(false);
    }

    return (
        <>
            <Prompt when={when} message={blockNavigation}/>
            <Dialog
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                open={openPrompt}
                setOpen={setOpenPrompt}
                className={'route-leaving-guard'}
                maxWidth={'xs'}
                fullWidth
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle className={'title'} id="scroll-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="scroll-dialog-description"
                        className={'body'}
                        tabIndex={-1}>
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={'actions'}>
                    <button className={'button secondary outlined px-7 py-2'}
                            type={'button'}
                            onClick={closeDialog}>
                        {t('dialogs.routeLeavingGuardDialog.actions.cancel')}
                    </button>
                    <button className={'button secondary outlined px-7 py-2'}
                            type={'button'}
                            onClick={onConfirmNavigation}>
                        {t('dialogs.routeLeavingGuardDialog.actions.proceed')}
                    </button>
                </DialogActions>
            </Dialog>
        </>
    );
};


export default RouteLeavingGuard;
