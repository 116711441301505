import React, {useEffect, useState} from "react";
import useIsMounted from "../../../../hooks/use-is-mounted";
import {useTranslation} from "react-i18next";
import Form from "../../../../components/base/form";
import * as Yup from 'yup'
import {makeValidate} from "mui-rff";
import {ApplicationLanguages, ApplicationSettingKeys, GeneralSettingsTypes} from "../../../../../core/constants/enums";
import ValidateMessages from "../../../../../core/constants/texts/vallidate-messages";
import CacheService from "../../../../../core/services/cache_service";
import GeneralSettingDynamicEntry from "../../../../components/app-specific/general-setting-entry/dynamic";
import GeneralSettingLanguagesEntry from "../../../../components/app-specific/general-setting-entry/languages";
import classnames from "classnames";

const formNames = {
    list: {
        title: 'list-title',
        id: 'list-id',
    }
}

const GeneralSettingsView = () => {
    const [loading, setLoading] = useState(true);
    const [settings, setSettings] = useState([]);
    const [schema, setSchema] = useState(Yup.object().shape({}))
    const [initialValues, setInitialValues] = useState({})
    const validate = makeValidate(schema)
    const {t, i18n} = useTranslation()
    const isMounted = useIsMounted()
    const languageTranslations = t("languages", {returnObjects: true});
    const languages = Object.values(ApplicationLanguages).map(e => ({id: e, title: languageTranslations[e]})) ?? [];


    /**
     * Listens to the changes in the language of the application and with each change:
     * fetches the general settings from the server and then creates the form values based on them
     */
    useEffect(() => {
        getGeneralSettings().then((settings) => {
            if (!settings) return
            const {schema, initialValues} = createSchemaAndInitialValues(settings)
            setSchema(schema)
            setInitialValues(initialValues)
        })
    }, [i18n.language])

    /**
     * Fetches the general settings of the application from the server and populates the inner state list.
     * @return {Promise<any[]>}
     */
    const getGeneralSettings = async () => {
        // TODO: call api for fetching the general settings
        setLoading(true);
        const response = {
            resultFlag: true,
            data: [
                {
                    id: 1,
                    title: "This is supposed to be a title for the settings False",
                    type: GeneralSettingsTypes.boolean,
                    value: '1'
                },
                {
                    id: 2,
                    title: "This is supposed to be a title for the settings True",
                    type: GeneralSettingsTypes.boolean,
                    value: '0'
                },
                {
                    id: 3,
                    title: "This is supposed to be a title for the settings Text",
                    type: GeneralSettingsTypes.text,
                    value: 'this is a text placeholder'
                },
                {
                    id: 4,
                    title: "This is supposed to be a title for the settings number",
                    type: GeneralSettingsTypes.number,
                    value: '23.45'
                },
                {
                    id: 5,
                    title: "This is supposed to be a title for the settings number",
                    type: GeneralSettingsTypes.number,
                    value: '23.45'
                },
                {
                    id: 6,
                    title: "This is supposed to be a title for the settings number",
                    type: GeneralSettingsTypes.number,
                    value: '23.45'
                },
                {
                    id: 7,
                    title: "This is supposed to be a title for the settings list",
                    type: GeneralSettingsTypes.list,
                    value: 'This is the title of the list 1',
                    defaultValues: [
                        {
                            title: 'This is the title of the list 1',
                            id: 1,
                        },
                        {
                            title: 'This is the title of the list 2',
                            id: 2,
                        },
                        {
                            title: 'This is the title of the list 3',
                            id: 3,
                        }
                    ]
                },
            ],
        }
        setLoading(false);
        const result = response?.data?.map(e => ({...e, loading: false}))
        setSettings(result)
        return result
    }

    /**s
     * Creates the schema and initial values for the form based on the given settings.
     * @param {any[]} settings the list of fetched settings from the server.
     * @return {{schema: {}, initialValues: {}}}
     */
    const createSchemaAndInitialValues = (settings) => {
        const _static = createStaticSchemaAndInitialValues()
        const _dynamic = createDynamicSchemaAndInitialValues(settings)
        const schema = {
            ..._static.schema,
            ..._dynamic.schema
        }
        const initialValues = {
            ..._static.initialValues,
            ..._dynamic.initialValues
        }
        return {
            schema,
            initialValues
        }
    }

    /**
     * Creates the schema and initial values for static general setting entries.
     * @return {{schema: {}, initialValues: {}}}
     */
    const createStaticSchemaAndInitialValues = () => {
        const schema = {}
        const initialValues = {}
        const settings = CacheService.getSettings() ?? {}
        // language
        schema[ApplicationSettingKeys.language] = Yup.object().nullable().shape({
            [formNames.list.title]: Yup.string().nullable(),
            [formNames.list.id]: Yup.string().nullable(),
        })
        initialValues[ApplicationSettingKeys.language] = languages.find(e => e.id === settings[ApplicationSettingKeys.language])
            ?? {title: "", id: 'no-language-selected'};
        return {schema, initialValues}
    }

    /**
     * Creates the schema and initial values for the form based on the given settings.
     * @param {any[]} settings the list of fetched settings from the server.
     * @return {{schema: {}, initialValues: {}}}
     */
    const createDynamicSchemaAndInitialValues = (settings) => {
        const schema = {}
        const initialValues = {}
        for (const setting of settings) {
            let value;
            switch (setting.type) {
                case GeneralSettingsTypes.boolean:
                    schema[`${setting.type}-${setting.id}`] = Yup.boolean()
                        .nullable()
                        .default(false)
                    value = setting.value === '1'
                    break;
                case GeneralSettingsTypes.number:
                    schema[`${setting.type}-${setting.id}`] = Yup.number()
                        .nullable()
                        .typeError(ValidateMessages.incorrectType(t('utils.formValidation.types.number')))
                    value = Number(setting.value)
                    if (isNaN(value)) {
                        value = undefined
                    }
                    break;
                case GeneralSettingsTypes.list:
                    schema[`${setting.type}-${setting.id}`] = Yup.object().nullable().shape({
                        [formNames.list.title]: Yup.string().nullable(),
                        [formNames.list.id]: Yup.mixed().nullable(),
                    })
                    value = setting.defaultValues?.find(e => e.title === setting.value)
                    console.log(value)
                    break;
                case GeneralSettingsTypes.text:
                default:
                    schema[`${setting.type}-${setting.id}`] = Yup.string().nullable()
                    value = `${setting.value}`
                    break;
            }
            initialValues[`${setting.type}-${setting.id}`] = value
        }
        return {schema, initialValues}
    }

    /**
     * Saves the change in the setting value in the server.
     * @param {any} setting the setting to save the value of
     * @param {any} newValue the new value of the setting
     */
    const saveGeneralSettingChanges = (setting, newValue) => {
        setSettings(prevState => prevState?.map(e => e.id === setting.id ? {...e, loading: true} : e))
        const forApi = {...setting};
        switch (setting.type) {
            case GeneralSettingsTypes.boolean:
                forApi['value'] = newValue ? '1' : '0'
                break;
            case GeneralSettingsTypes.number:
                forApi['value'] = `${newValue}`
                break;
            case GeneralSettingsTypes.list:
                forApi['value'] = newValue.title
                break;
            case GeneralSettingsTypes.text:
            default:
                forApi['value'] = `${setting.value}`
                break;
        }
        // TODO: call api for updating general settings value
        // sets the initial value as well, since if in the future we want to revert it, we can take it from initial
        // values
        setInitialValues(prevState => ({...prevState, [`${setting.type}-${setting.id}`]: newValue}))
        setSettings(prevState => prevState?.map(e => e.id === setting.id ? {...e, loading: false} : e))
    }

    /**
     * Reverts any of the settings changes to their previous value
     * @param {any} setting the setting to revert its changes
     * @param {formApi} form the form api used for reverting the value of the setting
     */
    const revertGeneralSettingChanges = (setting, form) => {
        form.change(`${setting.type}-${setting.id}`, initialValues[`${setting.type}-${setting.id}`])
    }


    return (
        <>
            <div className={'general'}>
                {
                    loading
                        ? <div className={'d-flex flex-column'}>
                            {
                                Array(8).fill(null).map((e, index) => (
                                    <div key={index} className={'loading loading-entry'}>
                                        <div className={'title'}>
                                            <div/>
                                        </div>
                                        <div className={classnames('value', {
                                            'input': (index + 1) % 2 === 0,
                                            'switch': (index + 1) % 3 === 0,
                                        })}>
                                            <div/>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        : <Form
                            onSubmit={() => false}
                            className={'d-flex flex-column'}
                            validate={validate}
                            initialValues={initialValues}
                            render={({...formProps}) => (
                                <>
                                    <GeneralSettingLanguagesEntry {...formProps}/>
                                    {
                                        settings?.map(setting => (
                                            <GeneralSettingDynamicEntry
                                                key={setting.id}
                                                setting={setting}
                                                onSave={saveGeneralSettingChanges}
                                                onRevert={revertGeneralSettingChanges}
                                                {...formProps}
                                            />
                                        ))
                                    }
                                </>
                            )}
                        />
                }
            </div>
        </>
    )
}

export default GeneralSettingsView;
