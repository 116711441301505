import {DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import Dialog from "../../base/dialog";
import {useTranslation} from "react-i18next";
import Form from "../../base/form";
import {Col, Row} from "react-bootstrap";
import {FormInput, PhoneNumberInput} from "../../base/input";
import ValidateMessages from "../../../../core/constants/texts/vallidate-messages";
import * as Yup from 'yup'
import {makeRequired, makeValidate} from "mui-rff";


const formNames = {
    firstName: "firstName",
    lastName: "lastName",
    email: "email",
    phoneNumber: "phoneNumber",
}

const schema = Yup.object().shape({
    [formNames.firstName]: Yup.string().nullable().required(ValidateMessages.required),
    [formNames.lastName]: Yup.string().nullable().required(ValidateMessages.required),
    [formNames.email]: Yup.string().email().nullable().required(ValidateMessages.required),
    [formNames.phoneNumber]: Yup.string().min(13,ValidateMessages.minLength('Phone number',10)).nullable().required(ValidateMessages.required),
})

const CreateNewUserDialog = ({setOpen, open, createNewUser}) => {
    const {t} = useTranslation();

    const validate = makeValidate(schema)
    const required = makeRequired(schema)
    const translations = t('dialogs.createNewUserDialog', {returnObjects: true})


    return (
        <>
            <Dialog
                maxWidth={'md'}
                fullWidth
                setOpen={setOpen}
                open={open}
            >
                <DialogTitle className={'title'} id={'scroll-dialog-title'}>
                    <div>
                        <h2>{translations?.title ?? ''}</h2>
                    </div>
                </DialogTitle>
                <Form
                    onSubmit={(val) => createNewUser(val, formNames.phoneNumber)}
                    validate={validate}
                    render={({submitting,form,values}) => {
                        return (
                            <>
                                <DialogContent id="scroll-dialog-description" className={'mb-4 pt-0'}>
                                <Row>
                                    <Col xs={12} md={6} className='mt-3'>
                                        <FormInput
                                            name={formNames.firstName}
                                            required={required[formNames.firstName]}
                                            label={translations?.form?.firstName ?? ''}
                                            disabled={submitting}
                                        />
                                    </Col>
                                    <Col xs={12} md={6} className='mt-3'>
                                        <FormInput
                                            name={formNames.lastName}
                                            required={required[formNames.lastName]}
                                            label={translations?.form?.lastName ?? ''}
                                            disabled={submitting}
                                        />
                                    </Col>
                                    <Col xs={12} md={6} className='mt-3'>
                                        <FormInput
                                            name={formNames.email}
                                            required={required[formNames.email]}
                                            label={translations?.form?.email ?? ''}
                                            disabled={submitting}
                                        />
                                    </Col>
                                    <Col xs={12} md={6} className='mt-3'>
                                        <PhoneNumberInput
                                            value={values[formNames.phoneNumber]}
                                            onChange={(e)=>form.change(e.target.name, e.target.value)}
                                            name={formNames.phoneNumber}
                                            required={required[formNames.phoneNumber]}
                                            label={translations?.form?.phoneNumber ?? ''}
                                            disabled={submitting}
                                        />
                                    </Col>

                                </Row>
                                </DialogContent>
                                <DialogActions>
                                    <button
                                        className='button primary outlined px-4'
                                        type='button'
                                        onClick={() => setOpen(false)}
                                        disabled={submitting}
                                    >
                                        {translations?.actions?.cancel ?? ''}
                                    </button>
                                    <button
                                        className='button primary px-5'
                                        type='submit'>
                                        {!submitting && (translations?.actions?.submit ?? '')}
                                        {submitting && (translations?.actions?.submitting ?? '')}
                                    </button>
                                </DialogActions>
                            </>
                        )
                    }}
                />
            </Dialog>
        </>
    );
}

export default CreateNewUserDialog;
