import React, {useContext, useEffect, useRef} from "react";
import {createPopper} from "@popperjs/core";
import classNames from "classnames";
import {PanelContext} from "../../../../contexts/panel";

const SidebarPopper = ({children, collapsed = true, referenceElement}) => {
    /**@type {React.MutableRefObject<import('@popperjs/core/lib/types').Instance>}*/
    const popperInstance = useRef(null);
    const popperElRef = useRef(null);
    const popperElement = useRef(null);
    const [{profileExpanded}] = useContext(PanelContext);
    /**@type {React.MutableRefObject<function(): void>}*/
    const disconnecter = useRef(() => null);

    /**
     * As soon as the component un-mounts:
     * disconnects the resize observer of this popper
     */
    useEffect(() => {
        return () => {
            if (disconnecter.current
                && typeof disconnecter.current == 'function') {
                disconnecter.current()
            }
        }
    }, [])

    /**
     * Listens to the changes in collapsed state and with each change:
     *
     * * creates the popper instance if its does not exist, and attaches an observer to update the position of the
     *      popper in case of any ui changes.
     * * updates the ui of the popper at the end.
     */
    useEffect(() => {
        if (!collapsed) {
            return removePopperInstance
        }
        if (referenceElement.current && popperElement.current) {
            createPopperInstance();
        }
        if (popperElRef.current) {
            observePopperChanges();
        }
        setTimeout(updatePopperPosition, 300);
        return removePopperInstance;
    }, [collapsed, referenceElement, popperElement, profileExpanded]);

    /**
     * Creates a popper instance for the sidebar with the set options and stores it in the popperInstance ref.
     */
    const createPopperInstance = () => {
        popperInstance.current = createPopper(
            referenceElement.current,
            popperElement.current,
            {
                placement: 'right-start',
                strategy: 'fixed',
                modifiers: [
                    {
                        name: 'computeStyles',
                        options: {
                            adaptive: false,
                        },
                    },
                ],
            });
    }

    /**
     * Observes any changes in the element of popper and its reference and with each change assigns a new
     * resizeObserver that will update the popperInstance position with each change
     */
    const observePopperChanges = () => {
        const resizeObserver = new ResizeObserver(updatePopperPosition);
        resizeObserver.observe(popperElRef.current);
        resizeObserver.observe(referenceElement.current);
        disconnecter.current = () => resizeObserver.disconnect()
    }

    /**
     * Updates the position of the current popper element.
     * @param {ResizeObserverEntry[]} entries
     */
    const updatePopperPosition = (entries) => {
        if (entries) {
            [...(entries ?? [])].forEach(e => {
                // do anything with the observed elements of the popper
            })
        }
        if (popperInstance.current) {
            popperInstance.current.update().then();
        }
    }

    /**
     * Removes the popper instance and removes its associated ref.
     */
    const removePopperInstance = () => {
        if (!popperInstance.current) return;
        popperInstance.current.destroy();
        popperInstance.current = null;
    }

    return (
        <>
            <div
                ref={popperElement}
                className={classNames('sidebar-popper')}
            >
                <div className="popper-inner" ref={popperElRef}>
                    {children}
                </div>
            </div>
        </>
    );
}

export default SidebarPopper;
