import * as Yup from 'yup';
import {useTranslation} from "react-i18next";
import ValidateMessages from '../../../../../../core/constants/texts/vallidate-messages';
import {makeRequired, makeValidate} from "mui-rff";
import Form from "../../../../../components/base/form";
import {useState} from "react";
import {deepEqual} from "../../../../../../core/services/utils";
import {Col} from "react-bootstrap";
import {FormInput} from "../../../../../components/base/input";
import ChangePasswordDialog from "../../../../../components/dialogs/change-password";

const formNames = {
    firstName: 'firstName',
    lastName: 'lastName',
    phone: 'phone',
    email: 'email'
};

const schema = Yup.object().shape({
    [formNames.firstName]: Yup.string().nullable().required(ValidateMessages.required),
    [formNames.lastName]: Yup.string().nullable().required(ValidateMessages.required),
    [formNames.phone]: Yup.string().nullable().required(ValidateMessages.required),
    [formNames.email]: Yup.string().nullable().required(ValidateMessages.required),
});

const UserInfo = ({user, setUser, loading}) => {
    const [changePasswordDialog, setChangePasswordDialog] = useState({open: false});
    const validate = makeValidate(schema);
    const required = makeRequired(schema);
    const {t} = useTranslation();
    const translations = t("views.panel.editProfile.userInfo", {returnObjects: true});
    const colSpans = {
        xs: 12,
        sm: 9,
        lg: 7
    }

    /**
     * Updates the profile information of the user by calling the server api, and then updates the user information
     * in the state.
     * @param {any}         values the form values
     * @param {formApi}     form
     * @param {function} submitErrors
     * @return {Promise<void>}
     */
    const updateProfileInformation = async (values, form, submitErrors) => {
        //TODO: call the api to submit the updated information of the user
        setUser(values)
    };

    /**
     * Toggles the change password dialog for the user profile.
     * @param {boolean} open whether to open or close the dialog
     */
    const toggleChangePasswordDialog = (open) => {
        setChangePasswordDialog({open});
    }

    return (
        <div className='user-info panel-card'>
            <div className='d-flex justify-content-between flex-grow-1 align-items-center'>
                <div>
                    <h2 className='title'>
                        {translations?.title ?? ''}
                    </h2>
                </div>
                <div>
                    <button className='button outlined primary py-2'
                            disabled={loading}
                            onClick={() => toggleChangePasswordDialog(true)}>
                        {translations?.actions?.changePassword ?? ''}
                    </button>
                </div>
            </div>
            {
                loading
                    ? (
                        <>
                            <div className={'loading edit-profile'}>
                                <div className={'user-info'}>
                                    {
                                        Array(4).fill(null).map((e, index) => (
                                            <div key={index}>
                                                <div/>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className={'action'}>
                                    <div/>
                                </div>
                            </div>
                        </>
                    )
                    : (
                        <Form
                            onSubmit={updateProfileInformation}
                            initialValues={user}
                            className='row flex-grow-1'
                            validate={validate}
                            render={({submitting, values, initialValues}) => {
                                return (
                                    <>
                                        <Col {...colSpans} className='mt-4'>
                                            <FormInput
                                                placeholder={translations?.form?.firstName?.placeholder ?? ''}
                                                label={translations?.form?.firstName?.title ?? ''}
                                                name={formNames.firstName}
                                                required={required[formNames.firstName]}
                                            />
                                        </Col>
                                        <Col {...colSpans} className='mt-4'>
                                            <FormInput
                                                placeholder={translations?.form?.lastName?.placeholder ?? ''}
                                                label={translations?.form?.lastName?.title ?? ''}
                                                name={formNames.lastName}
                                                required={required[formNames.lastName]}
                                            />
                                        </Col>
                                        <Col {...colSpans} className='mt-4'>
                                            <FormInput
                                                placeholder={translations?.form?.phone?.placeholder ?? ''}
                                                label={translations?.form?.phone?.title ?? ''}
                                                name={formNames.phone}
                                                required={required[formNames.phone]}
                                            />
                                        </Col>
                                        <Col {...colSpans} className='mt-4'>
                                            <FormInput
                                                placeholder={translations?.form?.email?.placeholder ?? ''}
                                                label={translations?.form?.email?.title ?? ''}
                                                name={formNames.email}
                                                required={required[formNames.email]}
                                            />
                                        </Col>
                                        <Col xs={12} className='mt-4'>
                                            <div className='d-flex justify-content-end'>
                                                <button
                                                    type={'submit'}
                                                    className='button primary px-5'
                                                    disabled={deepEqual(initialValues, values) || submitting}
                                                >
                                                    {submitting
                                                        ? translations?.actions?.save?.validating ?? ''
                                                        : translations?.actions?.save?.text ?? ''}
                                                </button>
                                            </div>
                                        </Col>

                                    </>
                                )
                            }}
                        />
                    )
            }
            <ChangePasswordDialog
                open={changePasswordDialog.open}
                setOpen={toggleChangePasswordDialog}
            />
        </div>
    )
}

export default UserInfo;
