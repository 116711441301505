import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import classnames from "classnames";
import {DefaultInitialPaginationInfo, SpireItemScannedStatuses, TableCellAlignments, TableCellTypes} from "../../../../../../../../core/constants/enums";
import NoEntries from "../../../../../../../components/app-specific/no-entries";
import Table from "../../../../../../../containers/table";
import {ReactComponent as EditIcon} from "../../../../../../../../assets/images/add-note-icon.svg"
import {ReactComponent as ChevronIcon} from "../../../../../../../../assets/images/chevron-icon.svg";
import useRouter from "../../../../../../../hooks/use-router";
import {Checkbox} from "@material-ui/core";
import {Collapse} from "react-bootstrap";
import TableSpacer from "../../../../../../../containers/table/spacer";
import {useDispatch, useSelector} from "react-redux";
import {spireCountingSelectionActions} from "../../../../../../../../redux/entities/spire-recount-selection-slice";
import SingleSpireCountingSearchSection from "../../../../../../../components/search-sections/countings/spire-counting/single";
import AddOrEditNoteDialog from "../../../../../../../components/dialogs/counting/add-edit-note-dialog";
import {routeFunctions} from "../../../../../../../routes";
import {ReactComponent as BarcodeIcon} from "../../../../../../../../assets/images/barcode-icon.svg";
import {dialogActions} from "../../../../../../../../redux/entities/dialog-slice";
import useIsMounted from "../../../../../../../hooks/use-is-mounted";
import QueryManagementUtils from "../../../../../../../../core/services/query-management-utils";
import useSearchData from "../../../../../../../hooks/use-search-data";
import {PositionCountingItemsQueryParams, SpireCountingItemsQueryParams} from "../../../../../../../../core/constants/query-params";
import {SpireCountingTabValues} from "../../../../../../../../core/constants/tab-values";

// Main table cell keys
const tableCellKeys = {
    select: 'select',
    partNumber: 'partNumber',
    freezeCount: 'freezeCount',
    scannedCount: 'scannedCount',
    updatedDatetime: 'updatedDateTime',
    expand: 'expand'
}

// Inner table cell keys
const innerCellKeys = {
    edit: 'edit',
    serialNumber: 'serialNumber',
    operator: 'operator',
    scannedDateTime: 'scannedDateTime',
    status: 'status'
}

const initialPaginationInfo = {
    ...DefaultInitialPaginationInfo,
    pageSize: 10,
}

const SpireCountingItems = ({data}) => {
    const {query, location, history, stringifyUrl, params} = useRouter();
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [itemUnderOperation, setItemUnderOperation] = useState({});
    const [isAddingOrEditingNote, setIsAddingOrEditingNote] = useState(false);
    const {t} = useTranslation();
    const translations = t("views.panel.company.counting.spire.single", {returnObjects: true});
    const errorMessage = t('views.panel.errorMessages', {returnObjects: true})
    const [expandedSectionsIds, setExpandedSectionsIds] = useState([]);
    const isMounted = useIsMounted();

    //Redux
    const recountState = useSelector(state => state?.spireRecountSelection ?? {});
    const dispatch = useDispatch();

    const {type} = query;

    /**
     * With each change in the type of selected tab:
     * - if the items tab is selected, searches again for the items
     */
    useEffect(() => {
        if (type === SpireCountingTabValues.items)
            search().then();
    }, [type])

    /**
     * Searches the list of items in a spire counting request
     * @return {Promise<void>}
     */
    const search = async () => {
        const forApi = prepareRequestDS();
        const response = {
            resultFlag: true,
            data: {
                items: [
                    {
                        id: 1,
                        partNumber: "RADFR101A",
                        freezeCount: 12,
                        scannedCount: 10,
                        updatedDatetime: new Date(),
                        expanded: false,
                        selected: false,
                        innerItems: [
                            {
                                id: 11,
                                serialNumber: '13123-123123-123',
                                operator: "Testian",
                                scannedDataTime: new Date(),
                                status: {
                                    id: 1,
                                    name: "Scanned"
                                }
                            },
                            {
                                id: 12,
                                serialNumber: '13123-123123-123',
                                operator: "Testian",
                                scannedDataTime: new Date(),
                                status: {
                                    id: 2,
                                    name: "Not Scanned"
                                }
                            },
                            {
                                id: 13,
                                serialNumber: '13123-123123-123',
                                operator: "Testian",
                                scannedDataTime: new Date(),
                                status: {
                                    id: 3,
                                    name: "Incorrect"
                                }
                            }
                        ],
                    },
                    {
                        id: 2,
                        partNumber: "RADFR101A",
                        freezeCount: 12,
                        scannedCount: 10,
                        updatedDatetime: new Date(),
                        expanded: false,
                        selected: false,
                        innerItems: [
                            {
                                id: 21,
                                serialNumber: '13123-123123-123',
                                operator: "Testian",
                                scannedDataTime: new Date(),
                                status: {
                                    id: 1,
                                    name: "Scanned"
                                }
                            },
                            {
                                id: 22,
                                serialNumber: '13123-123123-123',
                                operator: "Testian",
                                scannedDataTime: new Date(),
                                status: {
                                    id: 2,
                                    name: "Not Scanned"
                                }
                            },
                            {
                                id: 23,
                                serialNumber: '13123-123123-123',
                                operator: "Testian",
                                scannedDataTime: new Date(),
                                status: {
                                    id: 3,
                                    name: "Incorrect"
                                }
                            }
                        ],
                    },
                    {
                        id: 3,
                        partNumber: "RADFR101A",
                        freezeCount: 12,
                        scannedCount: 10,
                        updatedDatetime: new Date(),
                        expanded: false,
                        selected: false,
                        innerItems: [
                            {
                                id: 31,
                                serialNumber: '13123-123123-123',
                                operator: "Testian",
                                scannedDataTime: new Date(),
                                status: {
                                    id: 1,
                                    name: "Scanned"
                                }
                            },
                            {
                                id: 32,
                                serialNumber: '13123-123123-123',
                                operator: "Testian",
                                scannedDataTime: new Date(),
                                status: {
                                    id: 2,
                                    name: "Not Scanned"
                                }
                            },
                            {
                                id: 33,
                                serialNumber: '13123-123123-123',
                                operator: "Testian",
                                scannedDataTime: new Date(),
                                status: {
                                    id: 3,
                                    name: "Incorrect"
                                }
                            }
                        ],
                    },
                    {
                        id: 4,
                        partNumber: "RADFR101A",
                        freezeCount: 12,
                        scannedCount: 10,
                        updatedDatetime: new Date(),
                        expanded: false,
                        selected: false,
                        innerItems: [
                            {
                                id: 41,
                                serialNumber: '13123-123123-123',
                                operator: "Testian",
                                scannedDataTime: new Date(),
                                status: {
                                    id: 1,
                                    name: "Scanned"
                                }
                            },
                            {
                                id: 42,
                                serialNumber: '13123-123123-123',
                                operator: "Testian",
                                scannedDataTime: new Date(),
                                status: {
                                    id: 2,
                                    name: "Not Scanned"
                                }
                            },
                            {
                                id: 43,
                                serialNumber: '13123-123123-123',
                                operator: "Testian",
                                scannedDataTime: new Date(),
                                status: {
                                    id: 3,
                                    name: "Incorrect"
                                }
                            }
                        ],
                    },
                ],
                paginationInfo: {
                    length: 4,
                }
            }
        }
        if (!isMounted())
            return;
        if (response?.resultFlag) {
            setItems(response.data.items ?? []);
            const newPaginationInfo = {
                ...paginationInfo,
                length: response?.data.paginationInfo.length ?? 0
            }
            QueryManagementUtils.setPaginationInfo(newPaginationInfo, query, history, location);
        }
        setLoading(false);
    }

    const {filters, paginationInfo, orderBy} = useSearchData(
        SpireCountingItemsQueryParams,
        search,
        {
            useFilters: true,
            initialFilters: {},
            filtersQueryParamMap: {
                keyword: PositionCountingItemsQueryParams.keyword,
                status: PositionCountingItemsQueryParams.status,
                filter: PositionCountingItemsQueryParams.filter,
            },

            usePaginationInfo: true,
            initialPaginationInfo: initialPaginationInfo,

            useOrderBy: true,
            initialOrderBy: undefined
        }
    );

    /**
     * Prepares the request body for the search api call
     * @return {{orderBy: unknown, paginationInfo: unknown, filters: {}}}
     */
    const prepareRequestDS = () => {
        return {
            paginationInfo: paginationInfo,
            filters: filters,
            orderBy: orderBy,
        }
    }

    /**
     * Clears the filters of this view to invoke a search without any filers.
     */
    const clearFilters = () => {
        history.push(stringifyUrl({
            url: location.pathname,
            query: {}
        }));
    }

    /**
     * Handles adding note for the inner products of the spire counting req:
     * - Sets the item under operation to the received object from table row
     * - Opens a dialog for this purpose by setting the [isAddingOrEditingNote] to true
     * @param {*} item
     */
    const onAddNoteClicked = (item) => {
        setItemUnderOperation(item);
        setIsAddingOrEditingNote(true);
    }

    /**
     * Handles adding note for the item instance in the inner table
     * - Calls the api regarding this action
     * - Closes the dialog
     * @param {Object} note
     */
    const onSubmittingNote = (note) => {
        //TODO: Call the api regarding this action
        console.log(note.note)
        setIsAddingOrEditingNote(false);
    }

    /**
     * Handles selection of the spire counting item:
     * - Dispatches the action with the help of redux for adding the selected counting req to the list
     * @param {*} selectedItem selected spire counting item
     */
    const onItemSelected = (selectedItem) => {
        dispatch(spireCountingSelectionActions.toggleCountingAdditionToList(selectedItem.id));
        console.log(recountState.selectedCountingIds.length);
    }

    /**
     * Handles expanding the table row for showing inner table
     * @param {MouseEvent<HTMLButtonElement>} event
     * @param {*} selectedItem
     */
    const onExpandClicked = (event, selectedItem) => {
        if (!selectedItem.expanded) {
            setExpandedSectionsIds(prevState => [...prevState, selectedItem.id]);
        }
        setItems(
            prevState => prevState.map(item => {
                if (item.id === selectedItem?.id) {
                    return {
                        ...item,
                        expanded: !selectedItem.expanded
                    }
                }
                return item;
            })
        );
    }

    /**
     * Handles click on the part number text button:
     * - Navigates user to the part single view
     * @param {number} partId
     */
    const onPartNumberClicked = (partId) => {
        history.push(routeFunctions.panel.company.items.single.product(params.company, partId))
    }

    /**
     * Renders the inner table products status of the table rows.
     * @param {any} item the item instances of a part in spire counting.
     * @return {JSX.Element}
     */
    const renderInnerTableItemsStatus = (item) => {
        return (
            <>
                <div className={'status-container'}>
                    <div className={classnames('status', {
                        'scanned': item.status?.id === SpireItemScannedStatuses.correct,
                        'not-scanned': item.status?.id === SpireItemScannedStatuses.notScanned,
                        'incorrect': item.status?.id === SpireItemScannedStatuses.incorrect,
                    })}/>
                    <p className={'text'}>
                        {item.status.name}
                    </p>
                </div>
            </>
        );
    }

    /**
     * @param {number} id the id of item in the table ro
     * sets the [isShowingExpandedSection] to false in the state
     */
    const hideExpandedSection = (id) => {
        setExpandedSectionsIds(prevState => prevState.filter(e => e !== id));
    }

    /**
     * Handles click on the barcode icon button:
     * - Opens dialog for showing the barcode
     * @param {string} partNumber for generating barcode from
     */
    const onBarcodeClicked = (partNumber) => {
        dispatch(dialogActions.configBarcodeDialog({
            open: true,
            onClose: () => dispatch(dialogActions.barcodeDialogToggle(false)),
            barcodeCode: partNumber,
        }));
    }

    /**
     * Memo version of table columns
     * @type {object[]}
     */
    const tableColumns = useMemo(() => [
        ...(recountState.isSelecting ? [{
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: tableCellKeys.select,
            size: 0.1
        },] : []),
        {
            alignment: TableCellAlignments.center,
            type: TableCellTypes.element,
            name: tableCellKeys.expand,
            size: 0,
        },
        {
            title: translations?.itemsTable?.partNumber ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: tableCellKeys.partNumber,
            sortable: true,
        },
        {
            title: translations?.itemsTable?.freezeCount ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.string,
            name: tableCellKeys.freezeCount,
            sortable: true,
        },
        {
            title: translations?.itemsTable?.scannedCount ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.string,
            name: tableCellKeys.scannedCount,
            sortable: true,
            size: 2
        },
        {
            title: translations?.itemsTable?.updateTimeDate ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.dateTime,
            name: tableCellKeys.updatedDatetime,
            sortable: true,
            size: 2
        },
    ], [recountState, translations]);

    /**
     * Memo version of inner table columns
     * @type {object[]}
     */
    const innerTableColumns = useMemo(() => [
        {
            alignment: TableCellAlignments.center,
            type: TableCellTypes.element,
            name: innerCellKeys.edit,
            size: 0
        },
        {
            title: translations?.itemsTable?.innerTable?.serialNumber ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: innerCellKeys.serialNumber,
            size: 1.5,
        },
        {
            title: translations?.itemsTable?.innerTable?.operator ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: innerCellKeys.operator,
            size: 1.5,

        },
        {
            title: translations?.itemsTable?.innerTable?.scannedDateTime ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.dateTime,
            name: innerCellKeys.scannedDateTime,
            size: 2

        },
        {
            title: translations?.itemsTable?.innerTable?.status ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: innerCellKeys.status,
        },
    ], [translations]);

    /**
     * Creates the data entries for spire counting item table
     * @return {TableData[]}
     */
    const tableRows = () => {
        return items
            ?.map((item) => ({
                key: item.id,
                [tableCellKeys.select]: () => {

                    return (
                        <Checkbox
                            className={classnames('item-selector', {'selected': recountState?.selectedCountingIds?.includes(item?.id)})}
                            onClick={(e) => onItemSelected(item)}
                            checked={recountState?.selectedCountingIds?.includes(item?.id)}
                        />
                    );
                },
                [tableCellKeys.partNumber]: () => (
                    <div className={'d-flex flex-row align-items-center'}>
                        {
                            item?.partNumber?.length > 0 &&
                            <div
                                onClick={() => onBarcodeClicked(item?.partNumber)}
                                className={'barcode-icon-button'}
                            >
                                <BarcodeIcon/>
                            </div>
                        }
                        <p className={'text-button'} onClick={() => onPartNumberClicked(item?.id)}>
                            {item?.partNumber}
                        </p>
                    </div>
                ),
                [tableCellKeys.freezeCount]: item?.freezeCount,
                [tableCellKeys.scannedCount]: item?.scannedCount,
                [tableCellKeys.updatedDatetime]: item?.updatedDatetime,
                [tableCellKeys.expand]: () => (
                    <div
                        className={classnames('expand-chevron', {'expanded': item?.expanded})}
                        onClick={(e) => onExpandClicked(e, item)}
                    >
                        <ChevronIcon/>
                    </div>

                ),
                expandedSection: () => (
                    //Make sure collapse is imported from react bootstrap
                    <>
                        <tr className={classnames({'visually-hidden': !expandedSectionsIds.includes(item?.id)})}>
                            <td colSpan={(expandedSectionsIds.length > 0) ? 6 : 0}>
                                <Collapse in={item?.expanded} onExited={() => hideExpandedSection(item.id)}>
                                    <div className={'inner-item-container'}>
                                        {
                                            item?.innerItems?.length >= 1
                                                ? <Table
                                                    className={'my-3 w-auto'}
                                                    color={'secondary'}
                                                    cells={innerTableColumns}
                                                    data={innerTableRows(item)}
                                                />
                                                : <NoEntries
                                                    text={errorMessage}
                                                    includeButton={false}
                                                />
                                        }
                                    </div>
                                </Collapse>
                            </td>
                        </tr>
                        {
                            item?.expanded &&
                            <TableSpacer length={tableColumns.length}/>
                        }
                    </>
                ),
            }))
    }

    /**
     * Creates the data entries for the spire counting products in the inner table
     * @param {*}  item
     * @return {TableData[]}
     */
    const innerTableRows = (item) => {
        return item?.innerItems?.map((innerItem) => (
            {
                key: innerItem.id,
                [innerCellKeys.edit]: () => (
                    <div onClick={(e) => onAddNoteClicked(innerItem)} className={'add-note'}>
                        <EditIcon/>
                    </div>
                ),
                [innerCellKeys.serialNumber]: innerItem?.serialNumber,
                [innerCellKeys.operator]: innerItem?.operator,
                [innerCellKeys.scannedDateTime]: innerItem?.scannedDataTime,
                [innerCellKeys.status]: () => renderInnerTableItemsStatus(innerItem),
            }
        ));
    }

    return (
        <>
            <div className={'spire-counting-single-items'}>
                <div className={'panel-card p-0 pt-4'}>
                    <SingleSpireCountingSearchSection filters={filters}/>
                    <div>
                        {
                            !!items?.length < 1 && !loading
                                ? (
                                    <NoEntries
                                        text={
                                            Object.entries(filters ?? {})?.length > 0
                                                ? translations?.empty?.withFilters
                                                : translations?.empty?.text
                                        }
                                        includeButton={Object.entries(filters ?? {})?.length > 0}
                                        buttonProps={{
                                            onClick: clearFilters,
                                            className: 'button primary px-3'
                                        }}/>
                                )
                                : (
                                    <Table
                                        loading={{state: loading, count: 8}}
                                        className={'my-3'}
                                        color={'primary'}
                                        cells={tableColumns}
                                        data={tableRows()}
                                        paginationInfo={paginationInfo}
                                        orderBy={orderBy}
                                        onSort={(orderBy) => QueryManagementUtils.setOrderBy(
                                            orderBy,
                                            query,
                                            history,
                                            location,
                                        )}
                                        onPageSizeChange={(p, i) => QueryManagementUtils.setPaginationInfo(
                                            {...p, pageSize: i},
                                            query,
                                            history,
                                            location
                                        )}
                                        onCurrentPageChange={(p, newP) => QueryManagementUtils.setPaginationInfo(
                                            {...p, currentPage: newP},
                                            query,
                                            history,
                                            location,
                                        )}
                                    />
                                )
                        }
                    </div>
                </div>
            </div>
            <AddOrEditNoteDialog
                setOpen={setIsAddingOrEditingNote}
                open={isAddingOrEditingNote}
                onSubmit={onSubmittingNote}
                initData={itemUnderOperation}
            />
        </>
    )
}

export default SpireCountingItems;

