import React from "react";
import {ReactComponent as BizPireLogo} from '../../../../assets/logos/logo-descriotion.svg'
import {Col, Container, Row} from "react-bootstrap";
import useRouter from "../../../hooks/use-router";
import routes from "../../../routes";
import {useTranslation} from "react-i18next";
import {Fade} from "@material-ui/core";

const PublicViewLayoutHeader = ({sections, onSectionClick}) => {
    const {match: isInRegisterRoute} = useRouter(routes.public.register);
    const {t} = useTranslation();

    /**
     * Navigates the user to the BizCore for authentication
     */
    const navigateToLogin = () => {
        if (window.bizpire?.navigateToCoreLogin) {
            window.bizpire?.navigateToCoreLogin();
        }
    }

    return (
        <>
            <nav className='header'>
                <Container>
                    <Row className={'justify-content-center justify-content-md-between'}>
                        <Col xs={"auto"} className={'d-flex flex-wrap'}>
                            <Col xs={12} sm={'auto'} className={'d-flex justify-content-center'}>
                                <BizPireLogo className={'logo'} onClick={() => onSectionClick(null)}/>
                            </Col>
                            <Col xs={12} sm={"auto"} className={'d-flex'}>
                                <div className={'nav-container justify-content-center w-100 w-sm-auto mb-3'}>
                                    {
                                        Object.values(sections).map((section) => (
                                            <div
                                                key={section.hash}
                                                className={'nav-item'}
                                                onClick={() => onSectionClick(section.hash)}
                                            >
                                                {section.text}
                                            </div>
                                        ))
                                    }
                                </div>
                            </Col>
                        </Col>
                        <Col xs={12} sm={12} md={'auto'}
                             className={'d-flex align-items-center justify-content-center justify-content-sm-end justify-content-md-center button-container'}>
                            <Fade in={!!isInRegisterRoute}>
                                <button className={'button secondary login'} onClick={navigateToLogin}>
                                    {t('views.public.landing.hero.actions.login')}
                                </button>
                            </Fade>
                        </Col>
                    </Row>
                </Container>
            </nav>
        </>

    )
}

export default PublicViewLayoutHeader;
