import React, {useMemo, useState} from 'react';
import {CircularProgress, Popover} from "@material-ui/core";
import {ReactComponent as CheckboxIcon} from '../../../../assets/images/views/location/warehouses/checkbox.svg'
import {ReactComponent as CheckboxActiveIcon} from '../../../../assets/images/views/location/warehouses/checkbox-active.svg';
import {ReactComponent as Star} from '../../../../assets/images/views/location/warehouses/star.svg';
import {ReactComponent as MoreInfo} from '../../../../assets/images/views/location/more.svg';
import classnames from "classnames";
import {CheckBox} from "../../base/checkbox";
import useRouter from "../../../hooks/use-router";
import {routeFunctions} from "../../../routes";
import {useTranslation} from "react-i18next";


const WarehouseCard = ({
                           editMode,
                           warehouse,
                           onSetDefault,
                           onSelect,
                           updating,
                           onEdit,
                           onEditSchema,
                           onRemove,
                       }) => {
    const {history, params} = useRouter();
    const [popover, setPopover] = useState();
    const {t} = useTranslation();
    const translations = t('components.locationCard', {returnObjects: true});


    /**
     * Navigates the user to the aisles view of this warehouse.
     */
    const navigateToAisles = () => {
        history.push(routeFunctions.panel.company.location.aisles(params.company, warehouse.id))
    }

    /**
     * Prevents the event to propagate upwards
     * @param {Event} e
     * @param {function} callback
     * @param {any} args
     */
    const preventEventPropagation = (e, callback, ...args) => {
        e.preventDefault();
        e.stopPropagation();
        if (callback) {
            callback(e, ...args)
        }
    }

    const showSelectionCheckbox = useMemo(() =>
            editMode && (!warehouse?.isDefault || (!warehouse.isSelected || (warehouse.isSelected && warehouse.hasChanged))),
        [editMode, warehouse?.hasChanged, warehouse?.isDefault, warehouse?.isSelected])
    
    return (
        <div
            onClick={() => !editMode && navigateToAisles()}
            className={classnames(`warehouse-card`, {
                'editMode': editMode,
                'not-imported': editMode && !warehouse.isSelected
            })}>
            <div className='d-flex justify-content-between align-items-start'>
                <div>
                    <p className='title'>
                        {warehouse?.title ?? ''}
                    </p>
                </div>
                {
                    showSelectionCheckbox &&
                    <CheckBox
                        disableRipple
                        disabled={updating}
                        className='p-0'
                        checked={warehouse?.isSelected ?? false}
                        checkedIcon={<CheckboxActiveIcon/>}
                        icon={<CheckboxIcon/>}
                        onChange={() => onSelect(warehouse)}
                    />
                }
                {
                    !showSelectionCheckbox && (!!onEdit || !!onEditSchema || !!onRemove) &&
                    <div className='more-info'>
                        <button
                            className='button primary text'
                            onClick={(e) => preventEventPropagation(e, (e) => setPopover(e.currentTarget))}
                        >
                            <MoreInfo/>
                        </button>
                        <Popover
                            open={!!popover}
                            anchorEl={popover}
                            onClose={() => setPopover(null)}
                            anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'right'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            classes={{paper: 'button-dropdown-popover location-actions-popover'}}
                        >
                            <div className={'content'}>
                                {
                                    onEdit &&
                                    <div
                                        className={classnames('option')}
                                        onClick={(e) => preventEventPropagation(e, () => setPopover(false) || onEdit(warehouse))}>
                                        {translations?.actions?.edit ?? ''}
                                    </div>
                                }
                                {
                                    !!onEditSchema &&
                                    <div
                                        className={classnames('option')}
                                        onClick={(e) => preventEventPropagation(e, () => setPopover(false) || onEditSchema(warehouse))}>
                                        {translations?.actions?.editSchema ?? ''}
                                    </div>
                                }
                                {
                                    !!onRemove &&
                                    <div
                                        className={classnames('option', 'red')}
                                        onClick={(e) => preventEventPropagation(e, () => setPopover(false) || onRemove(warehouse))}>
                                        {translations?.actions?.remove ?? ''}
                                    </div>
                                }

                            </div>
                        </Popover>
                    </div>
                }
            </div>
            <div className='d-flex justify-content-between align-items-end mt-3'>
                <div className='code'>
                    {warehouse?.code ?? ''}
                </div>
                <button
                    disabled={updating || editMode}
                    className={'button text primary p-0 d-flex align-items-center'}
                    onClick={(e) => preventEventPropagation(e, onSetDefault, warehouse)}
                >
                    {
                        updating
                            ? <CircularProgress size={18}/>
                            : <Star className={classnames('star', {'active': warehouse?.isDefault})}/>
                    }
                </button>
            </div>
        </div>
    );
}

export default WarehouseCard;
