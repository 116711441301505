import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {TableCellAlignments, TableCellTypes} from "../../../../../../../../core/constants/enums";
import {Col} from "react-bootstrap";
import {Select} from "../../../../../../../components/base/select/select";
import Table from "../../../../../../../containers/table";
import NoEntries from "../../../../../../../components/app-specific/no-entries";

// Keys for the reports' table cell keys
const reportsTableCellKeys = {
    reportTypes: 'reportTypes',
    export: 'export'
}

const PositionCountingReports = ({data, loading}) => {
    const [selectedReportType, setSelectedReportType] = useState(data?.reports?.reportTypes[0])
    const {t} = useTranslation();
    const translation = t('views.panel.company.counting.position.single', {returnObjects: true});
    const errorMessage = t('views.panel.errorMessages', {returnObjects: true})


    const reportsTableCells = [
        {
            title: translation?.reportTable?.reportTypes ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: reportsTableCellKeys.reportTypes,
            size: 1
        },
        {
            title: translation?.reportTable?.export ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.element,
            name: reportsTableCellKeys.export,
        },
    ];

    /**
     * Handles changes in the report type:
     * Sets the [selectedReportTypeId] in the state based on the selected report
     */
    const onReportTypeChanged = (e) => {
        console.log(e.target.value.id); //-> the id of the selected field
        setSelectedReportType(e?.target?.value);
        // setSelectedReportTypeId()
    }

    /**
     * Downloads the selected report
     * @param report
     */
    const onDownloadClicked = (report) => {

    }

    /**
     * Creates the data entries for the reports table
     * @return {TableData[]}
     */
    const createReportTable = () => {
        let reportsToShow = data.reports?.reportList?.filter(e => e.typeId === selectedReportType?.id)
        return reportsToShow.map((report) => ({
            key: report?.id,
            [reportsTableCellKeys.reportTypes]: report?.title,
            [reportsTableCellKeys.export]: () =>
                (<div className={'download-button'} onClick={(report) => onDownloadClicked}>
                    <p>{translation?.download}</p>
                </div>),
        }));
    }

    return (
        <>
            <div className={"spire-counting-single-reports"}>
                <Col xs={12} xl={7}>
                    <Select
                        name={'report-format'}
                        value={selectedReportType?.title}
                        placeholder={translation?.reportFormat ?? ''}
                        label={translation?.reportFormat ?? ''}
                        onChange={onReportTypeChanged}

                        data={data?.reports?.reportTypes?.map(e => ({
                            id: e.id,
                            value: e
                        }))}
                    />
                </Col>
                <Col xs={12} xl={7}>
                    {
                        data?.reports?.reportList?.length > 1
                            ? <Table
                                loading={{state: loading, count: 3}}
                                className={'my-3 w-75 '}
                                color={'primary'}
                                cells={reportsTableCells}
                                data={createReportTable()}
                            />
                            : <NoEntries
                                text={errorMessage}
                                includeButton={false}
                            />
                    }
                </Col>
            </div>
        </>
    );
}

export default PositionCountingReports;
