import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {landingViewScrollAnimationOptions} from "../../index";
import {useTranslation} from "react-i18next";
import ApplicationPricePlans from "../../../../../../containers/application-price-plans";
import {routeFunctions} from "../../../../../../routes";
import useRouter from "../../../../../../hooks/use-router";

const LandingViewPricePlansSection = ({sections}) => {
    const {history, params} = useRouter();
    const {t} = useTranslation();

    /**
     * Navigates the user to the registration view
     */
    const navigateToRegistration = () => {
        history.push(routeFunctions.public.register(params.language))
    }

    return (
        <>
            <div id={sections.pricing.hash}>
                <Container className={'plans-container'}>
                    <Row data-aos={landingViewScrollAnimationOptions.fadeUpAnimation}>
                        <Col xs={12}>
                            <h4 className={'title flex-row-center'}>
                                {t('views.public.landing.plans.title.black')}
                            </h4>
                        </Col>
                    </Row>
                    <ApplicationPricePlans
                        onPlanSelected={navigateToRegistration}
                        cardProps={{
                            'data-aos': landingViewScrollAnimationOptions.fadeUpAnimation,
                        }}
                    />
                </Container>
            </div>
        </>
    );
}
export default LandingViewPricePlansSection;
