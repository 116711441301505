import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    tabs: {},
    indicator: {
        backgroundColor: props => props.indicatorColor,
        height: props => props.indicatorHeight,
    },
    tab: {
        textTransform: 'uppercase',
        minWidth: props => props.minWidth,
        fontSize: props => props.fontSize,
        color: props => props.color,
        fontWeight: 600,
        marginRight: theme.spacing(4),
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
            color: props => props.hoverColor,
            opacity: 1,
        },
        '&$selected': {
            color: props => props.selectedColor,
            fontSize: props => props.selectedFontSize,
        },
        '&:focus': {
            color: props => props.focusColor,
        },
    },
    selected: {
        color: props => props.selectedColor,
        fontSize: props => props.selectedFontSize,
    },
}))


export default useStyles
