import {InventorySchemaVisualizerDepths} from "../../../core/models/constants/enums";
import React, {useCallback} from "react";
import {IconButton} from "@material-ui/core";
import {ZoomIn, ZoomOut} from "@material-ui/icons";
import InventorySchemaVisualizerDepthToggleButtons from "../toggle-buttons";

export type IInventorySchemaVisualizerControlPanelProps = {
    showDepth: boolean,
    depth: InventorySchemaVisualizerDepths,
    setDepth: (depth: InventorySchemaVisualizerDepths) => void,
    changeScale: (offset: number) => void,
}

const InventorySchemaVisualizerControlPanel = ({
                                                   showDepth,
                                                   depth,
                                                   setDepth,
                                                   changeScale,
                                               }: IInventorySchemaVisualizerControlPanelProps) => {

    /**
     * Increases the scale of the canvas
     * @type {(function(): void)}
     */
    const increaseScale = useCallback(() => {
        changeScale(0.1);
    }, [changeScale])

    /**
     * Decreases the scale of the canvas
     * @type {(function(): void)}
     */
    const decreaseScale = useCallback(() => {
        changeScale(-0.1);
    }, [changeScale])

    return (
        <>
            <div className={'inventory-schema-visualizer-control-panel'}>
                <div className={'inventory-schema-visualizer-control-panel-scale-container'}>
                    <IconButton
                        onClick={increaseScale}
                        color={'primary'}
                    >
                        <ZoomIn/>
                    </IconButton>
                    <IconButton
                        onClick={decreaseScale}
                        color={'primary'}
                        className={'mt-3'}
                    >
                        <ZoomOut/>
                    </IconButton>
                </div>
                {
                    showDepth &&
                    <InventorySchemaVisualizerDepthToggleButtons
                        value={depth}
                        setValue={setDepth}
                        orientation={'vertical'}
                    />
                }
            </div>
        </>
    );
}

export default InventorySchemaVisualizerControlPanel;
