import {useTranslation} from "react-i18next";
import {CreditCardTypes, MonerisCardTypes, TableCellAlignments, TableCellTypes} from "../../../../../../core/constants/enums";
import {ReactComponent as TrashIcon} from '../../../../../../assets/images/views/payment-methods/trash.svg';
import Table from "../../../../../containers/table";
import {CircularProgress} from "@material-ui/core";
import NoEntries from "../../../../../components/app-specific/no-entries";
import React from "react";

// the keys used for the table
const tableCellKeys = {
    expiry: 'expiry',
    number: 'number',
    type: 'type',
    setDefault: 'setDefault',
    remove: 'remove'
};


/**
 * Determines the type of the credit card used for the table.
 * @param type {string}
 */
const getCreditCardLogo = (type) => {
    switch (type) {
        case MonerisCardTypes.americanExpress:
            return <div className='card-type--amex card-type--center'/>

        case MonerisCardTypes.jcb:
            return <div className='card-type--jcb card-type--center'/>

        case MonerisCardTypes.masterCard:
            return <div className='card-type--mastercard card-type--center'/>

        case MonerisCardTypes.novusDiscover:
            return <div className='card-type--discover card-type--center'/>
        case MonerisCardTypes.visa:
            return <div className='card-type--visa card-type--center'/>
        default:
            return <p
                className='text-sm primary-color text-center font-weight-semi-bold'
                style={{width: 65}}
            >
                {CreditCardTypes[type] ?? 'Unknown'}
            </p>

    }
}

// TODO: the following are not ye implemented:
//  1. the functionality of adding a new card
//  2. the ui of adding a new card

const PaymentMethodsOtherCards = ({cardsList, addCard, setDefault, removeCard, loading}) => {
    const {t} = useTranslation();
    const translations = t('views.panel.paymentMethods.otherCards', {returnObjects: true});
    const tableCells = [
        {
            title: translations?.tableHeaders?.cardType ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: tableCellKeys.type,
            size: 0.5,
        },
        {
            title: translations?.tableHeaders?.cardNumber ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.element,
            name: tableCellKeys.number
        },
        {
            title: translations?.tableHeaders?.expiryDate ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.element,
            name: tableCellKeys.expiry,
            size: 0.8,
        },
        {
            alignment: TableCellAlignments.center,
            type: TableCellTypes.element,
            name: tableCellKeys.setDefault
        },
        {
            alignment: TableCellAlignments.center,
            type: TableCellTypes.element,
            name: tableCellKeys.remove,
            size: 0,
        }
    ];

    /**
     * Creates the data entries for the payment methods table
     * @return {TableCellData[]}
     */
    const createTableData = () => {
        return cardsList
            ?.filter((card) => !card.isDefault)
            ?.map((card) => ({
                key: card.id,
                [tableCellKeys.type]: () => (
                    <div>
                        {getCreditCardLogo(card.type)}
                    </div>
                ),
                [tableCellKeys.number]: () => (
                    <p>
                        <span className={'asterisks'}>**** **** **** </span>
                        {card?.number?.substring(card.number.length - 4)}
                    </p>
                ),
                [tableCellKeys.expiry]: () => (
                    <p>
                        {card?.expiry?.substring(0, 2)}
                        <span className={'mx-1'}>/</span>
                        <span className={'asterisks'}>**</span>
                    </p>

                ),
                [tableCellKeys.setDefault]: () => (
                    <button
                        className='button outlined primary'
                        onClick={() => setDefault(card)}>
                        {translations?.tableActions?.setDefault ?? ''}
                    </button>
                ),
                [tableCellKeys.remove]: () => (
                    <button
                        disabled={!!card?.loading}
                        className='button text'
                        onClick={() => removeCard(card)}>
                        {
                            loading
                                ? <CircularProgress color={'secondary'} size={18}/>
                                : <TrashIcon className={'remove-icon'}/>
                        }

                    </button>
                ),
            }))
    }

    /**
     * Adds the created card to the list of the user cards.
     * @param {any} card the newly created card
     */
    const onAddNewCard = (card) => {
        card = {
            id: cardsList.length + 1,
            type: 'V',
            lastFourDigits: "1234",
            month: '2',
        }
        // TODO: call api for adding a new card to the list of cards
        addCard(card)
    }


    return (
        <div className='other-cards'>
            <div className={'d-flex justify-content-between align-items-center'}>
                <h2 className='title'>
                    {translations?.title ?? ''}
                </h2>
                <button
                    className={'button primary px-5'}
                    onClick={onAddNewCard}
                >
                    {translations?.add ?? ''}
                </button>
            </div>
            <div>
                {
                    cardsList?.length < 1 && !loading
                        ? <NoEntries text={translations?.noCards ?? ''}/>
                        : <Table
                            loading={{state: loading, count: 4}}
                            className={'my-5'}
                            color={'secondary'}
                            cells={tableCells}
                            data={createTableData()}
                        />
                }
            </div>
        </div>
    )
}

export default PaymentMethodsOtherCards;
