import React from "react";
import * as Yup from 'yup';
import ValidateMessages from "../../../../../core/constants/texts/vallidate-messages";
import {makeRequired, makeValidate} from "mui-rff";
import {useTranslation} from "react-i18next";
import {DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import Dialog from "../../../base/dialog";
import Form from "../../../base/form";
import {FormInput} from "../../../base/input";
import {Col, Row} from "react-bootstrap";
import {FormKeyboardDatePicker} from "../../../base/date-picker";
import {FormSelect} from "../../../base/select/select";

/**
 * Form names keys
 * @type {{expiryDate: string, title: string, location: {self:string, id:string, title:string}}}
 */
const formNames = {
    title: "title",
    expiryDate: "expiryDate",
    location: 'location',
}

/**
 * Creates Schema for the form validation using Yup
 */
const schema = Yup.object().shape({
    [formNames.title]: Yup.string().nullable().required(ValidateMessages.required),
    //TODO: Check the form validation of the expiry date
    [formNames.expiryDate]: Yup.date().nullable().min(new Date()).required(ValidateMessages.required),
    // [formNames.location.self]: Yup.string().required(),
    // TODO: Check why self and id and title were being used and why object().shape() was being used?
    [formNames.location]: Yup.object().nullable().required(ValidateMessages.required),
});


const CreateSpireCountingDialog = ({setOpen, open, warehouses, createCountingReq}) => {
    const {t} = useTranslation();

    const validate = makeValidate(schema);
    const required = makeRequired(schema);
    const translation = t('dialogs.counting.spire.newCountingReq', {returnObjects: true});

    return (
        <>
            <Dialog
                max={'md'}
                fullWidth
                setOpen={setOpen}
                open={open}
            >
                <DialogTitle className={'title'} id={'scroll-dialog-title'}>
                    <div>
                        <h2>{translation?.title}</h2>
                    </div>
                </DialogTitle>
                <Form
                    onSubmit={createCountingReq}
                    validate={validate}
                    render={({submitting}) => {
                        return (
                            <>
                                <DialogContent id={'scroll-dialog-description'} className={'mb-4 pt-0'}>
                                    <Row>
                                        <Col xs={12} md={6} className={'mt-4'}>
                                            <FormInput
                                                name={formNames.title}
                                                required={required[formNames.title]}
                                                label={translation?.form?.title ?? ''}
                                                disabled={submitting}
                                            />
                                        </Col>
                                        <Col xs={12} md={6} className={'mt-4'}>
                                            <div className='date-wrapper edit-spire-counting-req'>
                                                <div className='picker-container'>
                                                    <FormKeyboardDatePicker
                                                        label={translation?.form?.expiryDate ?? ''}
                                                        name={formNames.expiryDate}
                                                        required={required[formNames.expiryDate]}
                                                        minDate={new Date()}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={6} className='mt-4'>
                                            <FormSelect
                                                required={required[formNames.location]}
                                                name={formNames.location}
                                                disabled={submitting}
                                                placeholder={translation?.form?.location ?? ''}
                                                label={translation?.form?.location ?? ''}
                                                data={warehouses?.map(e => ({
                                                    id: e.id,
                                                    value: e
                                                }))}
                                            />
                                        </Col>
                                    </Row>
                                </DialogContent>
                                <DialogActions>
                                    <button
                                        className='button primary outlined px-4'
                                        type='button'
                                        onClick={() => setOpen(false)}
                                        disabled={submitting}
                                    >
                                        {translation?.actions?.cancel ?? ''}
                                    </button>
                                    <button
                                        className='button primary px-5'
                                        disabled={submitting}
                                        type='submit'
                                    >
                                        {!submitting && (translation?.actions?.submit ?? '')}
                                        {submitting && (translation?.actions?.submitting ?? '')}
                                    </button>
                                </DialogActions>
                            </>
                        )
                    }}
                />
            </Dialog>

        </>
    );
}

export default CreateSpireCountingDialog;
