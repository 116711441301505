import * as Yup from "yup";
import ValidateMessages from "../../../../../../../../core/constants/texts/vallidate-messages";
import {makeRequired, makeValidate} from "mui-rff";
import Form from "../../../../../../../components/base/form";
import React, {useContext, useEffect, useRef, useState} from "react";
import {Col} from "react-bootstrap";
import {FormInput} from "../../../../../../../components/base/input";
import {useTranslation} from "react-i18next";
import {RegistrationContext} from "../../../../../../../contexts/registration";
import {registrationStepperStepIds} from "../../../../../../../../core/constants/ids";
import classnames from "classnames";
import {RegistrationReducerActions} from "../../../../../../../reducers/registration";
import {FormSelect} from "../../../../../../../components/base/select/select";
import {secondaryColor} from "../../../../../../../../assets/mui/colors";

const formNames = {
    type: 'type',
    name: 'name',
    phone: 'phone',
    address: 'address',
    postalCode: 'postalCode',
    province: 'province',
    country: 'country',
};

const schema = Yup.object().shape({
    [formNames.type]: Yup.mixed().nullable().required(ValidateMessages.required),
    [formNames.name]: Yup.string().nullable().required(ValidateMessages.required),
    [formNames.phone]: Yup.string().nullable().required(ValidateMessages.required),
    [formNames.address]: Yup.string().nullable().required(ValidateMessages.required),
    [formNames.postalCode]: Yup.string().nullable().required(ValidateMessages.required),
    [formNames.province]: Yup.mixed().nullable().required(ValidateMessages.required),
    [formNames.country]: Yup.mixed().nullable().required(ValidateMessages.required),
});

const FormSection = ({provinces, countries, companyTypes, tempAddress}) => {
    const [registrationState, registrationReducer] = useContext(RegistrationContext);
    const businessInfoState = registrationState[registrationStepperStepIds.businessInfo];
    const [initialValues, setInitialValues] = useState({});
    const validate = makeValidate(schema);
    const required = makeRequired(schema);
    const {t} = useTranslation();
    const formRef = useRef();

    const translations = t('views.public.registration.businessInfo.form', {returnObjects: true});

    /**
     * Listens to the changes in the temporary address of the businessInfo state and with each change:
     * Sets the address of the form
     */
    useEffect(() => {
        setTemporaryAddressForForm(tempAddress);
    }, [tempAddress])

    /**
     * Listens to the changes in businessInfoState and with each change:
     * Populates the initialValues object used in the form.
     */
    useEffect(() => {
        initializeInitialValues(businessInfoState);
    }, [businessInfoState])

    /**
     * Submits the values of this form.
     * @param {any} values
     */
    const onSubmit = (values) => {
        const forSubmission = {
            [formNames.type]: values[formNames.type],
            [formNames.name]: values[formNames.name],
            [formNames.phone]: values[formNames.phone],
            address: {
                [formNames.address]: values[formNames.address],
                [formNames.postalCode]: values[formNames.postalCode],
                [formNames.province]: values[formNames.province],
                [formNames.country]: values[formNames.country],
            },
        }
        registrationReducer({
            type: RegistrationReducerActions.submitStep,
            payload: {
                stepName: registrationStepperStepIds.businessInfo,
                values: forSubmission,
                submitted: true,
                valid: true,
            }
        });
        registrationReducer({type: RegistrationReducerActions.nextStep});
    }

    /**
     * Sets the temporary address of the business info into the form values.
     * @param {MapboxAddress & {[p: string]: string}} address
     */
    const setTemporaryAddressForForm = (address) => {
        if (!address) {
            return;
        }
        Object.entries(address).forEach(([key, value]) => {
            if (key === 'companyName') {
                formRef.current?.change(formNames.name, value);
                return;
            }
            formRef.current?.change(key, value);
        })
    }

    /**
     * Initializes the initialValues of the form.
     */
    const initializeInitialValues = () => {
        setInitialValues({
            [formNames.type]: businessInfoState[formNames.type],
            [formNames.name]: businessInfoState[formNames.name],
            [formNames.phone]: businessInfoState[formNames.phone],
            [formNames.address]: businessInfoState.address[formNames.address],
            [formNames.postalCode]: businessInfoState.address[formNames.postalCode],
            [formNames.province]: businessInfoState.address[formNames.province],
            [formNames.country]: businessInfoState.address[formNames.country],
        });
    }

    /**
     * Empties the selected value of the province if the country value has changed.
     * @param {any} form the form that the value is to change in
     */
    const onCountryChange = (form) => {
        form.change(formNames.province, undefined);
    }


    return (
        <>
            <div className={'registration-form-card'}>
                <Col xs={12} className={'mb-4 flex-row-center'}>
                    <p className={'title'}>
                        {t('views.public.registration.steps.businessInfo')}
                    </p>
                </Col>
                <Form
                    onSubmit={onSubmit}
                    className={'row'}
                    validate={validate}
                    initialValues={initialValues}
                    render={({form, values}) => {
                        formRef.current = form;
                        const colClassName = 'px-4 mb-4 mt-2';
                        return (
                            <>
                                <Col xs={12} className={classnames(colClassName)}>
                                    <FormSelect
                                        insidePanel={false}
                                        placeholder={translations.type.placeholder}
                                        label={translations.type.title}
                                        name={formNames.type}
                                        required={!!required[formNames.type]}
                                        iconColor={secondaryColor}
                                        data={companyTypes?.map((value) => ({
                                            id: value.id,
                                            value: value,
                                        })) ?? []}
                                    />
                                </Col>
                                <Col xs={12} sm={6} className={classnames(colClassName)}>
                                    <FormInput
                                        placeholder={translations.name.placeholder}
                                        label={translations.name.title}
                                        name={formNames.name}
                                        required={required[formNames.name]}
                                    />
                                </Col>
                                <Col xs={12} sm={6} className={classnames(colClassName)}>
                                    <FormInput
                                        placeholder={translations.phone.placeholder}
                                        label={translations.phone.title}
                                        name={formNames.phone}
                                        required={required[formNames.phone]}
                                    />
                                </Col>
                                <Col xs={12} sm={6} className={classnames(colClassName)}>
                                    <FormSelect
                                        insidePanel={false}
                                        placeholder={translations.address.country.placeholder}
                                        label={translations.address.country.title}
                                        name={formNames.country}
                                        required={!!required[formNames.country]}
                                        iconColor={secondaryColor}
                                        onChange={() => onCountryChange(form)}
                                        data={countries?.map((value) => ({
                                            id: value.id,
                                            value: value,
                                        })) ?? []}
                                    />
                                </Col>
                                <Col xs={12} sm={6} className={classnames(colClassName)}>
                                    <FormSelect
                                        insidePanel={false}
                                        placeholder={translations.address.province.placeholder}
                                        label={translations.address.province.title}
                                        name={formNames.province}
                                        required={!!required[formNames.province]}
                                        iconColor={secondaryColor}
                                        data={provinces
                                            ?.filter(e => e.countryId === values?.country?.id)
                                            ?.map((value) => ({
                                                id: value.id,
                                                value: value,
                                            })) ?? []}
                                    />
                                </Col>
                                <Col xs={8} className={classnames(colClassName, 'address')}>
                                    <FormInput
                                        placeholder={translations.address.address.placeholder}
                                        label={translations.address.address.title}
                                        name={formNames.address}
                                        required={required[formNames.address]}
                                    />
                                </Col>
                                <Col xs={4} className={classnames(colClassName, 'postalCode')}>
                                    <FormInput
                                        placeholder={translations.address.postalCode.placeholder}
                                        label={translations.address.postalCode.title}
                                        name={formNames.postalCode}
                                        required={required[formNames.postalCode]}
                                    />
                                </Col>
                                <Col xs={12} className={'flex-row-center mt-4 mb-2'}>
                                    <button
                                        className={'button secondary px-8 py-2'}
                                        type={'submit'}>
                                        {t('views.public.registration.userInfo.form.button.text')}
                                    </button>
                                </Col>
                            </>
                        );
                    }}
                />
            </div>

        </>
    );
}

export default FormSection;
