import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import classnames from "classnames";
import {Popover} from "@material-ui/core";
import {ReactComponent as Company} from "../../../../../assets/images/panel/top-bar/company.svg";
import {ReactComponent as Arrow} from "../../../../../assets/images/dropdown-icon.svg";
import {CompanyReducerActions} from "../../../../reducers/company";
import {matchPath} from "react-router";
import routes from "../../../../routes";
import useRouter from "../../../../hooks/use-router";
import Api from "../../../../../core/services/api_service";
import CacheService from "../../../../../core/services/cache_service";
import LocalStorageService from "../../../../../core/services/local_storage_service";
import useIsMounted from "../../../../hooks/use-is-mounted";
import {CompanyContext} from "../../../../contexts/company";


//TODO: the following are not done:
// 1. the ui / ux and api call for adding a company
// 2. the loading animation

const PanelTopbarCompanies = ({disabled}) => {
    const [selectedCompany, dispatchCompany] = useContext(CompanyContext);
    const {location, history} = useRouter();
    const [companies, setCompanies] = useState([]);
    const [loading, setLoading] = useState(false);
    const [popover, setPopover] = useState();
    const isMounted = useIsMounted();
    const {t} = useTranslation();

    const translations = t('containers.panel.topbar.company', {returnObjects: true});


    /**
     * Listens for the changes in the location of the url and with each change:
     *
     * if the current route is in the following, does nothing:
     * - login
     * - setup
     * otherwise fetches the list of available companies of the user as soon as the component mounts.
     */
    useEffect(() => {
        //TODO: add routes that the companies must not be fetched
        const disabledPaths = !!matchPath(location.pathname, {
            path: [
                routes.panel.auth.login,
                routes.panel.auth.logout,
                routes.panel.setup,
            ],
            exact: true
        });
        if (disabledPaths || companies?.length) return;
        getAvailableCompanies();
    }, [location?.pathname])


    /**
     * Fetches the list of available application of the current user by calling the server and if the result of the
     * api call is successful, sets the state. Also sets the current selected application depending on its existence.
     */
    const getAvailableCompanies = () => {
        setLoading(true);
        Api.getCompanies().then((response) => {
            if (!isMounted()) return;
            if (!response?.resultFlag) return;
            const companies = response.data ?? [];
            CacheService.syncUserCompanies(companies);
            setCompanies(companies);
            setLoading(false);
            if (!companies?.length) {
                history.replace(routes.error.accessDenied);
                return;
            }
            const selectedCompanyName = LocalStorageService.get(LocalStorageService.keys.selectedCompanyName);
            // the cached selected company otherwise the first one in the list
            const selectedCompany = companies?.find(e => e?.name === selectedCompanyName) ?? companies[0];
            if (!selectedCompany) return;
            dispatchCompany({
                type: CompanyReducerActions.select,
                payload: selectedCompany,
            });
            // if not default, make it so next time the default is in sync with the ui
            if (!selectedCompany?.isDefault) {
                setCompanyAsDefault(selectedCompany);
            }
        })
    }

    /**
     * Sets the selected company as the default company of the user.
     *
     * The result of the api call is particularly not important as the id of the company is from the cache and the
     * api is to also be in sync with it.
     *
     * @param {any} company the company to be set as the default company of the user
     */
    const setCompanyAsDefault = (company) => {
        Api.setDefaultCompany(company.id).then();
    }


    /**
     * Closes this popover and begins the process of adding a new company for the user.
     */
    const onAddCompanyClicked = () => {
        setPopover(null);
        // the ui / ux and api call for adding a company
    }

    /**
     * Selects the company as the current company of the user.
     * @param {{id: number, name:string}} company the company to be selected
     */
    const onCompanyClicked = (company) => {
        setPopover(null);
        if (company.id === selectedCompany?.id) return;
        dispatchCompany({type: CompanyReducerActions.select, payload: company});
    }

    return (
        <>
            <div
                className={classnames('topbar-icon companies',
                    {'disabled': disabled},
                    {'active': !!popover},
                )}
                onClick={(e) => setPopover(e.currentTarget)}>
                <Company/>
                <p>
                    {selectedCompany?.name ?? translations.placeholder}
                </p>
                <Arrow/>
            </div>
            <Popover
                id={!!popover ? 'companies' : undefined}
                elevation={2}
                classes={{paper: 'top-bar-popover companies'}}
                open={!!popover}
                onClose={() => setPopover(null)}
                anchorReference={'anchorEl'}
                anchorEl={popover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div className={'content'}>
                    <div className={'d-flex justify-content-between w-100 mx-3'}>
                        <div className={'title'}>
                            {translations.popover.title}
                        </div>
                    </div>
                    <div className={'d-flex flex-wrap mt-2'}>
                        {
                            companies?.map(company => (
                                <div
                                    key={company?.id}
                                    className={classnames('company',
                                        {'active': company.id === selectedCompany?.id})}
                                    onClick={() => onCompanyClicked(company)}
                                >
                                    {company?.name ?? '--'}
                                </div>
                            ))
                        }
                        <button onClick={onAddCompanyClicked}
                                className={'button primary text mx-3 p-0 my-3'}>
                            {translations.popover.actions.add}
                        </button>
                    </div>
                </div>
            </Popover>
        </>
    );
}

export default PanelTopbarCompanies;
