import React, {useEffect, useState} from "react";
import {DialogTitle} from "@material-ui/core";
import Dialog from "../../base/dialog";
import ConfirmationSection from "./sections/confirmation-section";
import SubmitTicketSection from "./sections/submit-ticket-section";


const SubmitTicketDialog = ({open, close, Data}) => {
    const [successData, setSuccessData] = useState(null);

    /**
     * With each change in open state of the dialog:
     * - if not open, then resets the success data of the dialog.
     */
    useEffect(() => {
        if (!open) {
            setSuccessData(null)
        }
    }, [open])


    return (
        <>
            <Dialog
                max={'sm'}
                fullWidth
                open={open}
                scroll={'paper'}
                setOpen={() => close(false)}
                classes={{
                    root: 'submit-ticket-dialog-root'
                }}
            >
                <DialogTitle className={'title'} id={'scroll-dialog-title'}>
                    <h2>
                        {
                            successData
                                ? "Ticket Submitted"
                                : "Submit a Ticket"
                        }
                    </h2>
                </DialogTitle>
                {
                    successData
                        ? <ConfirmationSection
                            data={successData}
                        />
                        : <SubmitTicketSection
                            data={Data}
                            onSuccess={setSuccessData}
                        />
                }
            </Dialog>
        </>
    );
}

export default SubmitTicketDialog;
