import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import {OrderListQueryNames} from "../../../../core/constants/query-names";
import {useTranslation} from "react-i18next";
import useRouter from "../../../hooks/use-router";
import {makeRequired, makeValidate} from "mui-rff";
import Form from "../../base/form";
import {Col, Row} from "react-bootstrap";
import {FormSelect} from "../../base/select/select";
import {FormKeyboardDatePicker} from "../../base/date-picker";
import {FormInput} from "../../base/input";
import moment from "moment";

// Form Keys
const formKeys = {
    keywords: 'keyword', status: 'status', fromDate: 'fromDate', toDate: 'toDate'
}

/**
 * Creates the schema of the order search section form.
 * @param {any} translation the translations for the search section
 * @param {any} validationTranslation the translations for the validation section
 * @return {import('yup').AnyObjectSchema}
 */
const makeSchema = (translation, validationTranslation) => {
    return Yup.object().shape({
        [formKeys.keywords]: Yup.string().when(formKeys.keywords, {
            is: val => !val?.length, then: Yup.string().nullable(), otherwise: Yup.string().nullable().required(validationTranslation?.required ?? '')
        }),
        [formKeys.status]: Yup.object().nullable(),
        [formKeys.fromDate]: Yup.date().when(formKeys.toDate, {
            is: val => !val,
            then: Yup.date().nullable().max(new Date(), translation?.validateMessages?.futureLookupError ?? '').typeError(validationTranslation?.incorrectType.concat(validationTranslation?.types.date)),
            otherwise: Yup.date().nullable()
                .max(Yup.ref(formKeys.toDate), validationTranslation?.dateSpecific?.maxLookupError ?? '')
                .max(new Date(), translation?.validateMessages?.futureLookupError ?? '').typeError(validationTranslation?.incorrectType.concat(validationTranslation?.types.date)),
        }),
        [formKeys.toDate]: Yup.date().when(formKeys.fromDate, {
            is: val => !val,
            then: Yup.date().nullable().max(new Date(), translation?.validateMessages?.futureLookupError ?? '').typeError(validationTranslation?.incorrectType.concat(validationTranslation?.types.date)),
            otherwise: Yup.date().nullable()
                .min(Yup.ref(formKeys.fromDate), validationTranslation?.dateSpecific?.minLookupError ?? '')
                .max(new Date(), translation?.validateMessages?.futureLookupError ?? '').typeError(validationTranslation?.incorrectType.concat(validationTranslation?.types.date)),
        }),
    }, [[formKeys.keywords, formKeys.keywords], [formKeys.fromDate, formKeys.toDate]]);
}

const OrderListSearchSection = ({filters, statuses}) => {
    const {t} = useTranslation();
    const {query, location, history, stringifyUrl} = useRouter();
    const [initialValues, setInitialValues] = useState({});
    const translation = t('views.panel.company.salesOrders.list.searchSections', {returnObjects: true});
    const validationTranslation = t('utils.formValidation', {returnObjects: true});
    const schema = makeSchema(translation, validationTranslation);
    const validate = makeValidate(schema);
    const required = makeRequired(schema);

    /**
     * Listens to the changes of filter and with each change, sets the [initialValue]
     */
    useEffect(() => {
        if (!!filters)
            setInitialValues({
                [formKeys.keywords]: filters[formKeys.keywords],
                [formKeys.toDate]: filters[formKeys.toDate],
                [formKeys.fromDate]: filters[formKeys.fromDate],
                [formKeys.status]: statuses?.find(e => e.title === filters[formKeys.status]) ?? undefined
            })
    }, [filters])

    /**
     * Handles searching for orders by adding the query params to the url
     * @param values
     */
    const onSearch = (values) => {
        const _fromDate = (values[formKeys.fromDate] ?? undefined) === undefined ? undefined : moment(values[formKeys.fromDate]).format('MM-DD-YYYY');
        const _toDate = (values[formKeys.toDate] ?? undefined) === undefined ? undefined : moment(values[formKeys.toDate]).format('MM-DD-YYYY');

        history.push(stringifyUrl({
            url: location.pathname, query: {
                ...query,
                [OrderListQueryNames.keyword]: values[formKeys.keywords] ?? undefined,
                [OrderListQueryNames.status]: values[formKeys.status]?.title ?? undefined,
                [OrderListQueryNames.fromDate]: _fromDate ?? undefined,
                [OrderListQueryNames.toDate]: _toDate ?? undefined,
            }
        }))
    }

    return (

        <Form
            onSubmit={onSearch}
            validate={validate}
            className={'orders-search-section'}
            initialValues={initialValues}
            render={({submitting, form, values}) => {
                return (
                    <>
                        <Row>
                            <Col xs={12} md={6} lg={4} className='mt-4'>
                                <FormInput
                                    name={formKeys.keywords}
                                    placeholder={translation?.keyword ?? ''}
                                    label={translation?.keyword ?? ''}
                                    value={values[formKeys.keywords]}
                                    onChange={(e) => form.change(e.target.name, e.target.value)}
                                    required={required[formKeys.keywords]}
                                />
                            </Col>
                            <Col xs={12} md={6} lg={4} className='mt-4'>
                                <FormSelect
                                    name={formKeys.status}
                                    placeholder={translation?.status ?? ''}
                                    label={translation?.status ?? ''}
                                    data={statuses?.map(e => ({
                                        id: e.id, value: e
                                    }))}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6} lg={4}
                                 className={'d-flex align-items-end mt-5'}
                            >
                                <p className={'title'}>
                                    {translation?.from ?? ''}
                                </p>
                                <FormKeyboardDatePicker
                                    maxDate={values[formKeys.toDate]}
                                    placeholder={translation?.date}
                                    name={formKeys.fromDate}
                                    required={required[formKeys.fromDate]}
                                />
                            </Col>
                            <Col xs={12} md={6} lg={4}
                                 className={'d-flex align-items-end mt-5'}
                            >
                                <p className={'title'}>
                                    {translation?.to ?? ''}
                                </p>
                                <FormKeyboardDatePicker
                                    minDate={values[formKeys.fromDate]}
                                    maxDate={new Date()}
                                    placeholder={translation?.date}
                                    name={formKeys.toDate}
                                    required={required[formKeys.toDate]}
                                />
                            </Col>
                            <Col xs={12} md={12} lg={4} className={'d-flex flex-grow-1 search-button-margin search-section-field'}>
                                <button
                                    disabled={submitting}
                                    type={'submit'}
                                    className={'button primary px-5 m-auto'}
                                >
                                    {translation?.search ?? ''}
                                </button>
                            </Col>
                        </Row>

                    </>
                );
            }}
        />
    );
}

export default OrderListSearchSection;
