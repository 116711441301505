import React, {useEffect, useState} from "react";
import useIsMounted from "../../../../hooks/use-is-mounted";
import {useTranslation} from "react-i18next";
import {TableCellAlignments, TableCellTypes} from "../../../../../core/constants/enums";
import Switch from "../../../../components/base/switch";
import Table from "../../../../containers/table";

// keys for the invoices table
const tableCellKeys = {
    name: 'name',
    description: "description",
    isAvailable: "isAvailable",
}

const CompanySettingsView = () => {
    const [loading, setLoading] = useState(true);
    const [companies, setCompanies] = useState([]);
    const isMounted = useIsMounted();
    const {t} = useTranslation()
    const translations = t("views.panel.settings.company", {returnObjects: true});
    const tableCells = [
        {
            title: translations?.tableHeaders?.name ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: tableCellKeys.name,
        },
        {
            title: translations?.tableHeaders?.description ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: tableCellKeys.description,
            size: 2.5,
        },
        {
            title: translations?.tableHeaders?.isAvailable ?? '',
            alignment: TableCellAlignments.center,
            type: TableCellTypes.element,
            name: tableCellKeys.isAvailable,
        },
    ]

    /**
     * As soon as the component mounts:
     * fetcehs the list of company settings from the server.
     */
    useEffect(() => {
        getCompaniesSetting();
    }, [])

    /**
     * Fetches the list of companies from the server and populates the state list.
     */
    const getCompaniesSetting = () => {
        setLoading(true);
        //TODO: call the api for fetching the company settings of the user.
        const response = {
            data: [
                {
                    id: 1,
                    name: 'CoolBC',
                    description: "lorem impum that says that this is a very long desc but to be honest its super cool.",
                    isAvailable: true,
                },
                {
                    id: 2,
                    name: 'CoolON',
                    description: "lorem impum that says that this is a very long desc but to be honest its super cool.",
                    isAvailable: true,
                },
                {
                    id: 3,
                    name: 'TestBC',
                    description: "lorem impum that says that this is a very long desc but to be honest its super cool.",
                    isAvailable: true,
                },
                {
                    id: 4,
                    name: 'Langley',
                    description: "lorem impum that says that this is a very long desc but to be honest its super cool.",
                    isAvailable: false,
                },
            ]
        }
        setCompanies(response?.data?.map(e => ({...e, loading: false})))
        setLoading(false);
    }

    /**
     * Toggles the availability of the company for the user.
     * @param {any} company the company to change its availability
     * @param {boolean} isAvailable the new availability
     */
    const toggleCompanyAvailability = (company, isAvailable) => {
        if (company.isAvailable === isAvailable) return
        setCompanies(prevState => prevState?.map(e => e.id === company.id ? ({...e, loading: true}) : e))
        //    TODO: call api for availability of the company.
        setCompanies(prevState => prevState?.map(e => e.id === company.id ? ({...e, isAvailable: isAvailable}) : e))
        setCompanies(prevState => prevState?.map(e => e.id === company.id ? ({...e, loading: false}) : e))
    }


    /**
     * Creates the data entries for invoices table
     * @return {TableCellData[]}
     */
    const createTableData = () => {
        const shouldDisable = companies.reduce((arr, current) => !current.loading && current.isAvailable, [])?.length <= 1;
        return companies?.map((company, index) => ({
            key: company.id,
            [tableCellKeys.name]: company?.name,
            [tableCellKeys.description]: company?.description,
            [tableCellKeys.isAvailable]: () => (
                <Switch
                    size={4}
                    checked={company?.isAvailable}
                    onChange={(checked) => toggleCompanyAvailability(company, checked)}
                    disabled={shouldDisable || company.loading}
                />
            ),
        }))
    }


    return (
        <>
            <div className={'company'}>
                <Table
                    loading={{state: loading, count: 5}}
                    className={'my-3'}
                    color={'primary'}
                    cells={tableCells}
                    data={createTableData()}
                />
            </div>
        </>
    )
}

export default CompanySettingsView;
