import React, {useContext, useEffect, useState} from 'react';
import {CircularProgress, Fade} from "@material-ui/core";
import {ReactComponent as ReportIcon} from "../../../../../../assets/images/report.svg";
import {CompanyContext} from "../../../../../contexts/company";
import WarehouseCard from "../../../../../components/app-specific/warehouse-card";
import Api from "../../../../../../core/services/api_service";
import useIsMounted from "../../../../../hooks/use-is-mounted";
import {useTranslation} from "react-i18next";
import {ReactComponent as Cross} from "../../../../../../assets/images/cross.svg";
import {ReactComponent as Tick} from "../../../../../../assets/images/tick.svg";
import {ReactComponent as Edit} from "../../../../../../assets/images/views/location/warehouses/edit.svg";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {numComparator} from "../../../../../../core/services/utils";
import NoEntries from "../../../../../components/app-specific/no-entries";
import {routeFunctions} from "../../../../../routes";
import {LocationTypes} from "../../../../../../core/constants/enums";
import useRouter from "../../../../../hooks/use-router";

/**
 * @typedef {{isDefault: boolean, code: string, isSelected: boolean, name: string, id: number}} warehouseType
 */

/**
 * Sorts the warehouse cards of this view based on their selected value and whether it has changed or not.
 *
 * if it has been selected and not changed, or was previously selected, then it comes first.
 * @param {any} a first warehouse
 * @param {any} b secnd warehouse
 * @return {0 | 1 | -1}
 */
const sortWarehouses = (a, b) => {
    return ((a.isSelected && !a.hasChanged)
        || (!a.isSelected && a.hasChanged))
    && ((b.isSelected && !b.hasChanged)
        || (!b.isSelected && b.hasChanged))
        ? numComparator(a.id, b.id)
        : (a.isSelected && !a.hasChanged)
        || (!a.isSelected && a.hasChanged) ? -1 : 1
}

const WarehousesViews = () => {
    const {history} = useRouter();
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(true);
    const [warehouses, setWarehouses] = useState([]);
    const [updating, setUpdating] = useState(false);
    const [generatingReport, setGeneratingReport] = useState(false);
    const [company] = useContext(CompanyContext);
    const isMounted = useIsMounted();
    const {t} = useTranslation();
    const translations = t('views.panel.company.location', {returnObjects: true});
    const errorMessage = t('views.panel.errorMessages', {returnObjects: true})
    const anyChanged = warehouses.some(e => e.hasChanged);
    const _warehouses = warehouses
        ?.filter(e => editMode ? e : e.isSelected)
        ?.sort(sortWarehouses);

    /**
     * Listens to the changes in selected company and with each change:
     * Reads the list of warehouses that belong to the new company
     */
    useEffect(() => {
        getListOfWarehouses();
    }, [company.id]);

    /**
     * Fetches the list of all warehouses belong to the selected company of the user from the server and populates
     * the inner state list.
     */
    const getListOfWarehouses = () => {
        setLoading(true);
        Api.getWarehouses().then((response) => {
            if (!isMounted()) return;
            if (response?.resultFlag) {
                setWarehouses(response?.data?.map(e => ({...e, hasChanged: false})) ?? []);
            } else {
                setWarehouses([])
            }
            setLoading(false);
        })
    }

    /**
     * Generates a csv report for all the parts in the selected company and the provided values as filters.
     * @param {any} values the selected filters accumulated from the dialog.
     */
    const generateReport = async (values) => {
        //TODO: REMEMBER THIS IS A ASYNC SINCE WE NEED ERRORS RESULT FOR THE DIALOG
        setGeneratingReport(true);
        const forApi = {...values}
        //TODO: call api for generating report for all the parts in a company
        setGeneratingReport(false);
    }

    /**
     * Submits the changes in edit mode if any warehouse has changed,
     * otherwise disables the edit mode
     */
    const submitWarehouseSelectionChanges = () => {
        if (!anyChanged) {
            setEditMode(false);
            return
        }
        const forApi = warehouses?.filter(e => e.isSelected)
        //TODO: call api for updating the selected warehouses of the user for their company
        const response = {
            resultFlag: true
        }
        if (response?.resultFlag) {
            setWarehouses(warehouses?.map(e => ({...e, hasChanged: false})));
            setEditMode(false);
        }
    }

    /**
     * Sets the provided warehouse as the default warehouse of the user.
     * @param {any} warehouse
     */
    const setDefaultWarehouse = (warehouse) => {
        if (warehouse?.id === warehouses?.find(e => e.isDefault)?.id) return
        setUpdating(true)
        Api.setDefaultWarehouse(warehouse?.id).then((response) => {
            if (!isMounted()) return;
            if (response?.resultFlag) {
                setWarehouses(prevState => prevState?.map(e => e.isDefault
                    ? ({...e, isDefault: false})
                    : e.id === warehouse?.id
                        ? ({...e, isDefault: true})
                        : e
                ))
            }
            setUpdating(false);
        })
    }

    /**
     * Sets the provided warehouse as the default warehouse of the user.
     * @param {any} warehouse
     */
    const removeWarehouse = (warehouse) => {
        if (warehouse?.id === warehouses?.find(e => e.isDefault)?.id)
            return
        setUpdating(true)
        Api.removeWarehouse(warehouse?.id).then((response) => {
            if (!isMounted()) return;
            if (response?.resultFlag) {
                setWarehouses(prevState => prevState?.filter(e => e.id !== warehouse.id))
            }
            setUpdating(false);
        })
    }

    /**
     * Updates the warehouse information using the provided Warehouse id and warehouse code.
     *
     * - if the result was successful, updates the warehouse in the list of warehouses
     * @param {number} warehouseId
     * @param {string} warehouseCode
     * @param {string} warehouseTitle
     */
    const updateWarehouse = async (warehouseId, warehouseCode, warehouseTitle) => {
        const foundWarehouse = warehouses.find(e => e.id === warehouseId);
        const forApi = {
            ...foundWarehouse,
            code: warehouseCode,
            title: warehouseTitle,
        }
        const response = await Api.updateWarehouse(forApi);
        if (!isMounted()) return;
        if (response?.resultFlag) {
            setWarehouses(prevState => prevState?.map(e => e.id === warehouseId
                ? {...e, ...forApi}
                : e
            ) ?? []);
        } else {
            // TODO: return errors based on the errors given in the api.
        }
    }

    /**
     * Opens the general report's dialog and binds the generation report callback for successful filtering.
     */
    const showGenerateReportDialog = () => {
        const generateLocationReportDialog = window.bizpire?.generateLocationReportDialog;
        if (!generateLocationReportDialog) {
            return;
        }
        generateLocationReportDialog(true, null, generateReport)
    }

    /**
     * Reverts the changes in the selected warehouses of the user and comes out of edit mode.
     */
    const revertWarehouseSelectionChanges = () => {
        setWarehouses(warehouses?.map(e => e.hasChanged
            ? {...e, hasChanged: false, isSelected: !e.isSelected}
            : e
        ));
        setEditMode(false);
    }

    /**
     * Toggles the selected state of a warehouse
     * @param {any} warehouse the selected warehouse
     */
    const toggleSelectedStateOfWarehouse = (warehouse) => {
        setWarehouses(prevState => prevState?.map(e => e.id === warehouse?.id
            ? {...e, isSelected: !e.isSelected, hasChanged: !e.hasChanged}
            : e
        ))
    }

    /**
     * Navigates the user to the edit-inventory-schema's view for the provided warheouse.
     * @param warehouse
     */
    const navigateToEditSchema = (warehouse) => {
        history.push(routeFunctions.panel.company.inventorySchema.edit(company.id, warehouse.id))
    }


    /**
     * Opens the Edit location dialog and binds the warehouse creation logic to the callback of this dialog.
     * @param {any} warehouse the warehouse to be updated.
     */
    const showEditWarehouseDialog = (warehouse) => {
        if (!warehouse)
            return;
        const upsertLocationDialog = window.bizpire?.upsertLocationDialog;
        if (!upsertLocationDialog)
            return
        upsertLocationDialog(
            true,
            LocationTypes.warehouse,
            {code: warehouse.code, title: warehouse.title},
            ({code, title}) => updateWarehouse(warehouse.id, code, title)
        )
    }

    return (
        <div className='panel-card warehouses-view'>
            <div className='d-flex align-items-center justify-content-between mb-3'>
                <div className={'d-flex align-items-center'}>
                    <p className='title'>
                        {
                            editMode
                                ? translations?.warehouses?.editTitle ?? ''
                                : translations?.warehouses?.title ?? ''
                        }
                    </p>
                    {
                        !editMode
                            ? (
                                <Fade unmountOnExit mountOnEnter in>
                                    <button
                                        className={'button text p-0'}
                                        disabled={updating}
                                        onClick={() => setEditMode(true)}>
                                        {
                                            updating
                                                ? <CircularProgress className={'ms-2'} size={20}/>
                                                : <Edit className={'ms-2 edit'}/>
                                        }
                                    </button>
                                </Fade>
                            )
                            : (
                                <Fade unmountOnExit mountOnEnter in>
                                    <div className={'d-flex'}>
                                        <button
                                            className={'button text icon-button'}
                                            disabled={updating}
                                            onClick={revertWarehouseSelectionChanges}>
                                            {
                                                updating
                                                    ? <CircularProgress size={18} color={'secondary'}/>
                                                    : <Cross/>

                                            }
                                        </button>
                                        <Fade in={!!anyChanged}>
                                            <button
                                                className={'button text icon-button'}
                                                disabled={updating || !anyChanged}
                                                onClick={submitWarehouseSelectionChanges}>
                                                {
                                                    updating
                                                        ? <CircularProgress size={18} color={'secondary'}/>
                                                        : <Tick/>
                                                }
                                            </button>
                                        </Fade>
                                    </div>
                                </Fade>
                            )
                    }
                </div>
                <Fade in={!editMode}>
                    <button
                        disabled={generatingReport}
                        onClick={showGenerateReportDialog}
                        className={'button primary outlined report'}>
                        <ReportIcon className={'me-1'}/>
                        <p>
                            {
                                generatingReport
                                    ? translations?.actions?.generateReport?.loading ?? ''
                                    : translations?.actions?.generateReport?.title ?? ''
                            }
                        </p>
                    </button>
                </Fade>
            </div>
            <div className='mt-4'>
                {
                    loading
                        ? (
                            <div className='d-flex flex-wrap'>
                                {
                                    Array(6).fill(null).map((e, index) => (
                                        <div key={index} className={'loading warehouse-card'}>
                                            <div/>
                                        </div>
                                    ))
                                }
                            </div>
                        )
                        : !!_warehouses?.length < 1
                            ? <NoEntries
                                text={editMode
                                    ? translations?.warehouses?.noImportedWarehouses
                                    : translations?.warehouses?.noSelectedWarehouses}
                                includeButton={!editMode}
                                buttonText={translations?.warehouses?.import}
                                buttonProps={{
                                    className: 'button primary px-4',
                                    disabled: updating || generatingReport,
                                    onClick: () => setEditMode(true)
                                }}
                            />
                            : <TransitionGroup className='d-flex flex-wrap'>
                                {
                                    !!_warehouses?.length >= 1
                                        ? _warehouses?.map(warehouse => (
                                                <CSSTransition
                                                    classNames={'fade'}
                                                    timeout={{
                                                        enter: 800,
                                                        exit: 300,
                                                    }}
                                                    key={warehouse.id}>
                                                    <WarehouseCard
                                                        editMode={editMode}
                                                        warehouse={warehouse}
                                                        updating={updating}
                                                        onSetDefault={setDefaultWarehouse}
                                                        onSelect={toggleSelectedStateOfWarehouse}
                                                        onEditSchema={navigateToEditSchema}
                                                        onRemove={removeWarehouse}
                                                        onEdit={showEditWarehouseDialog}
                                                    />
                                                </CSSTransition>
                                            )
                                        )
                                        : <NoEntries
                                            text={errorMessage}
                                            includeButton={false}
                                        />
                                }
                            </TransitionGroup>
                }
            </div>
        </div>
    )
}

export default WarehousesViews;
