import {DefaultPaginationInfo} from "../constants/enums";
import {deepEqual} from "./utils";
import {UrlQueryNames} from "../constants/query-names";
import {History, Location} from "history";
import {stringifyUrl} from "query-string";

/**
 * Determines if the order-by of the query has changed.
 *
 * If there is a change, sets the state to be in sync with the change
 * @param query the query of the view
 * @param orderBy the current orderBy value
 * @param setOrderBy the callback for setting the new order by
 * @return {TableOrderBy|null}the new orderBy if it has changed, null otherwise
 */
export const hasOrderByOfQueryChanged = (query: any, orderBy: any, setOrderBy: Function) => {
    let newOrderBy;
    const newOrderByName = query[UrlQueryNames.orderBy] ?? null;
    const isDescending = query[UrlQueryNames.orderByDescending] ?? null;
    if (newOrderByName !== null && isDescending !== null) {
        newOrderBy = {
            propertyName: newOrderByName,
            isDescending: isDescending === 'true',
        };
    }
    if (!deepEqual(newOrderBy, orderBy)) {
        setOrderBy(newOrderBy);
        return newOrderBy;
    }
    return null;
}

/**
 * Determines if the paginationInfo of the query has changed.
 *
 * If there is a change, sets the state to be in sync with the change
 * @param query the query of the view
 * @param paginationInfo the current paginationInfo value
 * @param setPaginationInfo the callback for setting the new paginationInfo
 * @return {PaginationInfo|null} the new paginationInfo if it has changed, null otherwise
 */
export const hasPaginationInfoChanged = (query: any, paginationInfo: any, setPaginationInfo: Function) => {
    let newPaginationInfo;
    const newPageSize = query[UrlQueryNames.pageSize] ?? null;
    const newCurrentPage = query[UrlQueryNames.currentPage] ?? null;
    const newTotal = query[UrlQueryNames.total] ?? null;
    newPaginationInfo = {
        pageSize: newPageSize ? parseInt(newPageSize) : DefaultPaginationInfo.pageSize,
        currentPage: newCurrentPage ? parseInt(newCurrentPage) : DefaultPaginationInfo.currentPage,
        length: newTotal ? parseInt(newTotal) : DefaultPaginationInfo.length,
    };
    if (!deepEqual(newPaginationInfo, paginationInfo)) {
        setPaginationInfo(newPaginationInfo);
        return newPaginationInfo;
    }
    return null;
}

/**
 * Constructs an object to overwrite the url query names for pagination and orderBy.
 *
 * use the obtained object to merge with the existing queries, to remove these fields if they exist.
 * @param {boolean} resetPaginationInfo whether to reset the pagination info, defaults to true
 * @param {boolean}  resetOrderBy whether to reset the orderBy info, defaults to true
 */
export const generatePaginationAndOrderByQueryResetter = (resetPaginationInfo: boolean = true, resetOrderBy: boolean = true) => {
    let result = {};
    if (resetPaginationInfo) {
        result = {
            ...result,
            [UrlQueryNames.pageSize]: undefined,
            [UrlQueryNames.currentPage]: undefined,
            [UrlQueryNames.total]: undefined,
        }
    }
    if (resetOrderBy) {
        result = {
            ...result,
            [UrlQueryNames.orderBy]: undefined,
            [UrlQueryNames.orderByDescending]: undefined,
        }
    }
    return result
}

/**
 * Changes the sorted column of the table in the url.
 *
 * * resets the current page back to one.
 * @param {TableOrderBy}    newOrderBy
 * @param {any}             query
 * @param {History}         history
 * @param {Location}        location
 */
export const onTableSort = (newOrderBy: any,
                            query: any,
                            history: History,
                            location: Location) => {
    history.push(stringifyUrl({
        url: location.pathname,
        query: {
            ...(query ?? {}),
            [UrlQueryNames.orderBy]: newOrderBy.propertyName,
            [UrlQueryNames.orderByDescending]: newOrderBy.isDescending,
            [UrlQueryNames.currentPage]: DefaultPaginationInfo.currentPage,
        }
    }));
}

/**
 * Changes the current page of the table pagination in the url
 * @param {PaginationInfo}  prevPaginationInfo
 * @param {number}          newCurrentPage
 * @param {any}             query
 * @param {History}         history
 * @param {Location}        location
 */
export const onTableCurrentPageChange = (prevPaginationInfo: any,
                                         newCurrentPage: number,
                                         query: any,
                                         history: History,
                                         location: Location) => {
    history.push(stringifyUrl({
        url: location.pathname,
        query: {
            ...query,
            [UrlQueryNames.currentPage]: newCurrentPage,
        }
    }));
}

/**
 * Changes the page size of the table pagination in the url
 * @param {PaginationInfo}  prevPaginationInfo
 * @param {number}          newPageSize
 * @param {any}             query
 * @param {History}         history
 * @param {Location}        location
 */
export const onTablePageSizeChange = (prevPaginationInfo: any,
                                      newPageSize: any,
                                      query: any,
                                      history: History,
                                      location: Location) => {
    history.push(stringifyUrl({
        url: location.pathname,
        query: {
            ...query,
            [UrlQueryNames.pageSize]: newPageSize,
        }
    }));
}
