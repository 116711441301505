import React from "react";
import routes from "../../routes";
import {Route, Switch} from "react-router";
import {MuiThemeProvider} from "@material-ui/core";
import theme from "../../../assets/mui/landing-theme";
import WithPublicViewLayoutViews from "./with-public-view-layout";

const PublicViews = () => {

    return (
        <MuiThemeProvider theme={theme}>
            <Switch>
                <Route path={[
                    routes.landing,
                    routes.public.landing,
                    routes.public.register,
                ]} exact>
                    <WithPublicViewLayoutViews/>
                </Route>
            </Switch>
        </MuiThemeProvider>
    );
}

export default PublicViews;
