import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import useRouter from "../../../../../hooks/use-router";
import * as Yup from "yup";
import {makeRequired, makeValidate} from "mui-rff";
import Form from "../../../../base/form";
import {Col, Row} from "react-bootstrap";
import {FormSelect} from "../../../../base/select/select";
import {SearchFormInput} from "../../../../base/input/search";
import classnames from "classnames";
import {PartInstanceListQueryNames} from "../../../../../../core/constants/query-names";
import {ReactComponent as CheckedBox} from "../../../../../../assets/images/multi-select-checked.svg";
import {ReactComponent as UnCheckedBox} from "../../../../../../assets/images/multi-select-blank.svg";

const formKeys = {
    keyword: 'keyword',
    status: 'statuses',
    warehouse: 'warehouses',
    aisle: 'aisles',
    section: 'sections',
    row: 'rows'
}

// Schema for the form validation of search section
const makeSchema = (validationTranslation) => {
    return Yup.object().shape({
        [formKeys.keyword]: Yup.string().when(formKeys.keyword, {
            is: val => !val?.length,
            then: Yup.string().nullable(),
            otherwise: Yup.string().nullable().required(validationTranslation?.required ?? '')
        }),
        [formKeys.status]: Yup.object().nullable(),
        [formKeys.warehouse]: Yup.object().nullable(),
        [formKeys.aisle]: Yup.object().nullable(),
        [formKeys.section]: Yup.object().nullable(),
        [formKeys.row]: Yup.object().nullable(),
    }, [[formKeys.keyword, formKeys.keyword]]);
}

const InstancesSearchSection = ({filters, statuses, warehouses, aisles, sections, rows}) => {
    const {t} = useTranslation();
    const {query, location, history, stringifyUrl} = useRouter();
    const [initialData, setInitialData] = useState({});
    const [aislesToSelectFrom, setAislesToSelectFrom] = useState(aisles);
    const [sectionToSelectFrom, setSectionsToSelectFrom] = useState(sections);
    const [rowsToSelectFrom, setRowsToSelectFrom] = useState(rows);

    const translations = t('views.panel.company.products.list.instances.searchSection', {returnObjects: true});
    const validationTranslation = t('utils.formValidation', {returnObjects: true});
    const schema = makeSchema(validationTranslation);
    const validate = makeValidate(schema);
    const required = makeRequired(schema);

    /**
     * With each change in the filters received in the props:
     * - Loads the initial data that is passed to the form
     * - Filters the available options for aisles, sections, and rows based on filter values
     */
    useEffect(() => {
        if (Object.keys(filters ?? {})?.length < 1)
            return;

        const _filters = Object.fromEntries(Object.entries(filters).map(([e, b]) => e === [PartInstanceListQueryNames.keyword] ? [e, b] : [e, JSON.parse(b)]));

        setInitialData(_filters);

        let warehousesId;
        let aislesId;
        let sectionsId;

        if (filters[PartInstanceListQueryNames.warehouse]?.length) {
            warehousesId = _filters[PartInstanceListQueryNames.warehouse].map(e => e.id);
            let _availableAisles = aisles.filter(e => warehousesId.includes(e.warehouse.id));
            let _availableSections = sections.filter(e => _availableAisles.map(f => f.id).includes(e.aisle.id));
            setAislesToSelectFrom(_availableAisles);
            setSectionsToSelectFrom(_availableSections);
            setRowsToSelectFrom(rows.filter(e => _availableSections.map(f => f.id).includes(e.section.id)));
        }
        if (filters[PartInstanceListQueryNames.aisle]?.length) {
            if (warehousesId?.length > 0) {
                aislesId = _filters[PartInstanceListQueryNames.aisle]?.filter(w => warehousesId.includes(w.warehouse.id))?.map(e => e.id);
            } else {
                aislesId = _filters[PartInstanceListQueryNames.aisle].map(e => e.id);
            }
            let _availableSections = sections.filter(e => aislesId.includes(e.aisle.id));
            let _availableRows = rows.filter(e => _availableSections.map(f => f.id).includes(e.section.id));
            setSectionsToSelectFrom(_availableSections);
            setRowsToSelectFrom(_availableRows);

        }
        if (filters[PartInstanceListQueryNames.section]?.length) {
            if (aislesId?.length > 0) {
                sectionsId = _filters[PartInstanceListQueryNames.section].filter(w => aislesId.includes(w.aisle.id)).map(e => e.id);
            } else {
                sectionsId = _filters[PartInstanceListQueryNames.section].map(e => e.id);
            }
            let _availableRows = rows.filter(e => sectionsId.includes(e.section.id));
            setRowsToSelectFrom(_availableRows);
        }

    }, [filters]);

    /**
     * Handles searching for part instances:
     * Add the search filter to the url search query
     * @param values
     */
    const onSearch = (values) => {
        history.push(stringifyUrl({
            url: location.pathname,
            query: {
                ...query,
                [PartInstanceListQueryNames.keyword]: values[formKeys.keyword] ?? undefined,
                [PartInstanceListQueryNames.status]: values[formKeys.status]?.length ? JSON.stringify(values[formKeys.status]) : undefined,
                [PartInstanceListQueryNames.warehouse]: values[formKeys.warehouse]?.length ? JSON.stringify(values[formKeys.warehouse]) : undefined,
                [PartInstanceListQueryNames.aisle]: values[formKeys.aisle]?.length ? JSON.stringify(values[formKeys.aisle]) : undefined,
                [PartInstanceListQueryNames.section]: values[formKeys.section]?.length ? JSON.stringify(values[formKeys.section]) : undefined,
                [PartInstanceListQueryNames.row]: values[formKeys.row]?.length ? JSON.stringify(values[formKeys.row]) : undefined,
            }
        }));
    }

    /**
     * - Renders a dummy <p> element in the body of the document with given value,
     * - Appends styles related to the mui-select field values
     * - calculates the width of the element and returns it
     *
     * @param value
     * @return {number}
     */
    const getSelectEntityWidth = (value) => {
        const entity = document.createElement('p');
        entity.classList.add('select-entity-value', 'ui-invisible');
        entity.innerHTML = value;
        document.body.appendChild(entity);
        const rect = entity.getBoundingClientRect();
        document.body.removeChild(entity);
        return rect.width;
    }

    /**
     * Renders the values inside the select field:
     * - Validates the selectedValues in the param
     * - Calculates the length of the field
     * - Sorts the values based on their title's length
     * - Checks how many of the items are able to fit the available space and renders the field values based on that
     *
     * @param selectedValues
     * @param selectElementId
     */
    const renderSelectBoxFieldValues = (selectedValues, selectElementId) => {
        // Checks if selected value is valid
        if (!Array.isArray(selectedValues) || selectedValues.length < 1)
            return;
        const element = document.getElementById(selectElementId);
        if (!element)
            return;
        const fieldWidth = element?.getBoundingClientRect()?.width - 25;
        selectedValues.sort((a, b) => a.title.length - b.title.length);
        let i = 0;
        let visibleValues = [];
        let remainedAvailableSpace = fieldWidth;
        while (i < selectedValues.length) {
            const singleEntityWidth = getSelectEntityWidth(selectedValues[i]?.title);
            remainedAvailableSpace -= singleEntityWidth;
            if (remainedAvailableSpace < 20)
                break;
            visibleValues.push(selectedValues[i]?.title);
            ++i;
        }
        switch (visibleValues.length) {
            case 0:
                if (selectedValues.length === 1)
                    return (<p>{selectedValues[0].title}</p>);
                if (selectedValues.length > 1)
                    return (
                        <div className={'select-box-values'}>
                            <p>{`${selectedValues[0].title.substring(0, 20)}...`}</p>
                            <div className={'select-box-indicator'}>
                                <p>{`+${selectedValues.length - 1}`}</p>
                            </div>
                        </div>
                    );
                break;
            case 1:
                if (selectedValues.length === 1)
                    return (<p>{selectedValues[0].title}</p>);
                if (selectedValues.length > 1)
                    return (
                        <div className={'select-box-values'}>
                            <p>{`${selectedValues[0].title}`}</p>
                            <div className={'select-box-indicator'}>
                                <p>{`+${selectedValues.length - 1}`}</p>
                            </div>
                        </div>
                    );
                break;
            default:
                return (
                    <div className={'select-box-values'}>
                        {
                            visibleValues.map((e, index, array) => (<p>{`${e}${index !== (array.length - 1) ? ',' : ''}`}</p>))
                        }
                        {
                            selectedValues.length !== visibleValues.length &&
                            <div className={'select-box-indicator'}>
                                <p>{`+${selectedValues.length - visibleValues.length}`}</p>
                            </div>
                        }
                    </div>
                );

        }

    }

    /**
     * Renders the values of the select box pop over and field:
     * - Checks type of [selectedValues] in the params and in case it's an array, calls the function for rendering the values
     * - Otherwise, renders the values shown in the popover
     * @param selectedValues
     * @param formKey
     * @return {JSX.Element|*}
     */
    const renderBoxValue = (selectedValues, formKey, values) => {
        if (Array.isArray(selectedValues)) {
            return renderSelectBoxFieldValues(selectedValues, formKey);
        }
        if (typeof selectedValues === 'object') {
            return (
                <div
                    className={'d-flex flex-row align-items-center justify-content-start w-100'}
                    key={selectedValues.id}
                >
                    {values[formKey]?.filter(e => e.id === selectedValues.id).length > 0 ? <CheckedBox/> :
                        <UnCheckedBox/>}
                    <p className={'m-1'}>{selectedValues?.title}</p>
                </div>
            );
        }
    }

    /**
     * With each change in the selected values for the warehouse, aisle, or section:
     * - Populates the [aislesToSelectFrom], [sectionsToSelectFrom], and [rowsToSelectFrom]
     * - Clears the selection field to show the new options
     * @param event
     * @param formKey
     */
    const onDataChanged = (event, formKey) => {
        if (formKey === formKeys.warehouse) {
            if (event?.target?.value?.length < 1) {
                setAislesToSelectFrom(aisles);
                setSectionsToSelectFrom(sections);
                setRowsToSelectFrom(rows);
                setInitialData(prevState => ({
                    ...prevState,
                    [formKeys.aisle]: [],
                    [formKeys.section]: [],
                    [formKeys.row]: [],
                }));
            } else {
                let _availableAisles = aisles.filter(e => event.target.value.map(f => f.id).includes(e.warehouse.id));
                let _availableSections = sections.filter(e => _availableAisles.map(f => f.id).includes(e.aisle.id));
                let _availableRows = rows.filter(e => _availableSections.map(f => f.id).includes(e.section.id));
                setAislesToSelectFrom(_availableAisles);
                setSectionsToSelectFrom(_availableSections);
                setRowsToSelectFrom(_availableRows)
                setInitialData(prevState => ({
                    ...prevState,
                    [formKeys.aisle]: [],
                    [formKeys.section]: [],
                    [formKeys.row]: []
                }));
            }
        }
        if (formKey === formKeys.aisle) {
            if (event?.target?.value?.length < 1) {
                setSectionsToSelectFrom(sections);
                setRowsToSelectFrom(rows);
                setInitialData(prevState => ({
                    ...prevState,
                    [formKeys.section]: [],
                    [formKeys.row]: []
                }));
            } else {
                let _availableSections = sections.filter(e => event.target.value.map(f => f.id).includes(e.aisle.id));
                let _availableRows = rows.filter(e => _availableSections.map(f => f.id).includes(e.section.id));
                setSectionsToSelectFrom(_availableSections);
                setRowsToSelectFrom(_availableRows);
                setInitialData(prevState => ({
                    ...prevState,
                    [formKeys.section]: [],
                    [formKeys.row]: []
                }));
            }
        }
        if (formKey === formKeys.section) {
            if (event?.target?.value?.length < 1) {
                setRowsToSelectFrom(rows);
                setInitialData(prevState => ({
                    ...prevState,
                    [formKeys.row]: []
                }));
            } else {
                let _availableRows = rows.filter(e => event.target.value.map(f => f.id).includes(e.section.id));
                setRowsToSelectFrom(_availableRows);
                setInitialData(prevState => ({
                    ...prevState,
                    [formKeys.row]: []
                }));
            }
        }
        setInitialData(prevState => (
            {
                ...prevState,
                [formKey]: event.target.value
            }
        ))
    }

    /**
     * Memo version of warehouses options used in the select box
     */
    const warehouseOptions = useMemo(() => warehouses?.map(e => ({id: e.id, value: e})), [warehouses]);

    /**
     * Memo version of aisles options used in the select box
     */
    const aisleOptions = useMemo(() => aislesToSelectFrom?.map(e => ({id: e.id, value: e})), [aislesToSelectFrom]);

    /**
     * Memo version of sections options used in the select box
     */
    const sectionOptions = useMemo(() => sectionToSelectFrom?.map(e => ({id: e.id, value: e})), [sectionToSelectFrom]);

    /**
     * Memo version of rows options used in the select box
     */
    const rowOptions = useMemo(() => rowsToSelectFrom?.map(e => ({id: e.id, value: e})), [rowsToSelectFrom]);

    return (
        <Form
            onSubmit={onSearch}
            validate={validate}
            className={'part-instances-search-section'}
            initialValues={initialData}
            render={({submitting, values}) => {

                const warehouseValues = values[formKeys.warehouse]
                        ?.map(e => warehouseOptions?.find(w => w.id === e.id)?.value)
                        ?.filter(e => !!e)
                    ?? []

                const aislesValues = values[formKeys.aisle]
                        ?.map(e => aisleOptions?.find(w => w.id === e.id)?.value)
                        ?.filter(e => !!e)
                    ?? []

                const sectionValues = values[formKeys.section]
                        ?.map(e => sectionOptions?.find(w => w.id === e.id)?.value)
                        ?.filter(e => !!e)
                    ?? []

                const rowValues = values[formKeys.row]
                        ?.map(e => rowOptions?.find(w => w.id === e.id)?.value)
                        ?.filter(e => !!e)
                    ?? []

                return (
                    <>
                        <Row>
                            <Col xs={12} lg={6} xl={4} className='mt-4 search-section-field'>
                                {/*Render value function must be double checked*/}
                                <FormSelect
                                    id={formKeys.status}
                                    multiple
                                    fullWidth
                                    name={formKeys.status}
                                    value={(values[formKeys.status]?.length ?? 0) === 0 ? [] : values[formKeys.status]}
                                    renderValue={(value) => renderBoxValue(value, [formKeys.status], values)}
                                    placeholder={translations?.statusPlaceHolder ?? ''}
                                    label={translations?.statusPlaceHolder ?? ''}
                                    onChange={(event) => onDataChanged(event, formKeys.status, values[formKeys.status])}
                                    data={statuses?.map(e => ({
                                        id: e.id,
                                        value: e
                                    }))}
                                />
                            </Col>
                            <Col xs={12} lg={6} xl={4} className='mt-4 search-section-field'>
                                <FormSelect
                                    id={formKeys.warehouse}
                                    multiple
                                    fullWidth
                                    name={formKeys.warehouse}
                                    value={warehouseValues}
                                    renderValue={(value) => renderBoxValue(value, [formKeys.warehouse], values)}
                                    placeholder={translations?.warehousePlaceHolder ?? ''}
                                    label={translations?.warehousePlaceHolder ?? ''}
                                    onChange={(event) => onDataChanged(event, formKeys.warehouse)}
                                    data={warehouseOptions}
                                />
                            </Col>
                            <Col xs={12} lg={6} xl={4} className='mt-4 search-section-field'>
                                <FormSelect
                                    id={formKeys.aisle}
                                    multiple
                                    fullWidth
                                    name={formKeys.aisle}
                                    value={aislesValues}
                                    renderValue={(value) => renderBoxValue(value, [formKeys.aisle], values)}
                                    placeholder={translations?.aislePlaceHolder ?? ''}
                                    label={translations?.aislePlaceHolder ?? ''}
                                    onChange={(event) => onDataChanged(event, formKeys.aisle)}
                                    data={aisleOptions}
                                />
                            </Col>
                            <Col xs={12} lg={6} xl={4} className='mt-4 search-section-field'>
                                <FormSelect
                                    id={formKeys.section}
                                    multiple
                                    fullWidth
                                    name={formKeys.section}
                                    value={sectionValues}
                                    renderValue={(value) => renderBoxValue(value, [formKeys.section], values)}
                                    placeholder={translations?.sectionPlaceHolder ?? ''}
                                    label={translations?.sectionPlaceHolder ?? ''}
                                    onChange={(event) => onDataChanged(event, formKeys.section)}
                                    data={sectionOptions}
                                />
                            </Col>
                            <Col xs={12} lg={6} xl={4} className='mt-4 search-section-field'>
                                <FormSelect
                                    id={formKeys.row}
                                    multiple
                                    fullWidth
                                    name={formKeys.row}
                                    value={rowValues}
                                    renderValue={(value) => renderBoxValue(value, [formKeys.row], values)}
                                    placeholder={translations?.rowPlaceHolder ?? ''}
                                    label={translations?.rowPlaceHolder ?? ''}
                                    onChange={(event) => onDataChanged(event, formKeys.row)}
                                    data={rowOptions}
                                />
                            </Col>
                        </Row>
                        <SearchFormInput
                            className={'search-field'}
                            placeholder={translations?.searchPlaceHolder ?? ''}
                            fullWidth
                            required={required[formKeys.keyword]}
                            name={formKeys.keyword}
                            buttonProps={{
                                className: classnames('button primary'),
                                children: translations?.search ?? '',
                                type: "submit",
                                disabled: submitting,
                            }}
                        />
                    </>
                )
            }}
        />
    );
}

export default InstancesSearchSection;
