import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {formatMoney} from "../../../core/services/utils";
import moment from "moment";
import classnames from "classnames";
import {DefaultPageSizes, DefaultTableRowAnimationProps, TableCellAlignments, TableCellTypes} from "../../../core/constants/enums";
import TablePagination from "./pagination";
import TableHeader from "./header";
import TableBody from "./body";
import {useTranslation} from "react-i18next";


/**
 * @typedef {{
 *     size:            number | -1,
 *     title:           string,
 *     name:            string,
 *     alignment:       'left' | 'right' | 'center',
 *     sortable:        boolean,
 *     type:            'date' | 'string' | 'money' | 'element',
 *     format:          function(any): any,
 *     last:            boolean,
 * }} TableCellData
 */

/**
 * @typedef {{
 *     propertyName: string,
 *     isDescending: boolean,
 * }} TableOrderBy
 */
/**
 * @typedef {{
 *     currentPage: int,
 *     pageSize:    int,
 *     length:      int
 * }} PaginationInfo
 */

//Added [expandedSection] on March 29th, 2022 regarding expandability of table row
/**
 * @typedef {{
 *     [p: string]:
 *     JSX.Element |
 *     number |
 *     string |
 *     boolean |
 *     function(boolean,function(boolean), function(any): any | null),
 *     child: null | any,
 *     key: string | number,
 *     expandedSection:  null | function(): JSX.Element,
 *     isSpecialRow: boolean | undefined
 * }} TableData
 */

/**
 * Creates the default formatter of the cell's cell based on its type
 * @param {string}  type
 * @return {function|null}
 */
const getDefaultFormat = (type) => {
    switch (type) {
        case TableCellTypes.money:
            return (data) => formatMoney(data);
        case TableCellTypes.date:
            return (data) => moment(data).format('DD/MM/YYYY')
        case TableCellTypes.dateTime:
            return (data) => moment(data).format(`DD/MM/YYYY [    ] hh:mm A`)
        case TableCellTypes.element:
        case TableCellTypes.string:
        default:
            return null;
    }
}

/**
 * Creates the default values of the cell properties and sets the state.
 */
const populateCells = (cellsProp) => {
    return cellsProp?.map((cell) => {
        const type = cell.type ?? TableCellTypes.string;
        return {
            ...cell,
            title: cell.title ?? '',
            alignment: cell.alignment ?? TableCellAlignments.left,
            type: type,
            size: cell.size == null ? 1 : cell.size,
            format: cell.format ?? getDefaultFormat(type),
        }
    }) ?? [];
}

/**
 * @param {TableCellData[]}                 cellsProp
 * @param {TableData[]}                     data
 * @param {PaginationInfo | boolean}        paginationInfo
 * @param {function(PaginationInfo, int)}   onPageSizeChange
 * @param {function(PaginationInfo, int)}   onCurrentPageChange
 * @param {TableOrderBy}                    orderBy
 * @param {function(TableOrderBy)}          onSort
 * @param {number[]}                        pageSizes
 * @param {'primary' | 'secondary'}         color
 * @param {string}                          className
 * @param {{count: number, state: boolean}}   loading
 * @param {{timeout: any, classNames: string}}  rowAnimationProps
 * @param {{timeout: any, classNames: string}}  loadingAnimationProps
 */
const Table = ({
                   cells: cellsProp,
                   data,
                   paginationInfo = false,
                   onPageSizeChange,
                   onCurrentPageChange,
                   pageSizes = DefaultPageSizes,
                   orderBy,
                   onSort,
                   color = 'primary',
                   className = '',
                   loading,
                   rowAnimationProps = DefaultTableRowAnimationProps,
                   loadingAnimationProps = rowAnimationProps,
               }) => {
    const {ready} = useTranslation();
    const [isTableScrolled, setIsTableScrolled] = useState(false);
    const [cells, setCells] = useState(() => populateCells(cellsProp));
    /**@type {React.MutableRefObject<HTMLElement>}*/
    const layoutRef = useRef();
    const [parentOffsetWidth, setParentOffsetWidth] = useState(0);
    /**@type {React.MutableRefObject<HTMLElement>}*/
    const tableRef = useRef();
    const [tableScrollable, setTableScrollable] = useState(false);


    /**
     * Listens to the changes in the layoutIdRef and as soon as it exists:
     * Sets the offsetWidth of its parents into the state.
     */
    useLayoutEffect(() => {
        if (layoutRef.current) {
            setParentOffsetWidth(layoutRef.current?.offsetWidth ?? 0)
        }
    }, [layoutRef.current]);

    /**
     * Listens to the changes in CellsProp and with each change:
     * Create the default values of the cell properties and sets the state.
     */
    useLayoutEffect(() => {
        setCells(populateCells(cellsProp))
    }, [cellsProp])

    /**
     * As soon as the component mounts:
     * Attaches the scrolling event listener for resizing effect of layout and table
     * As soon as the component un-mounts:
     * Removes the observer
     */
    useEffect(() => {
        if (!tableRef.current || !layoutRef.current || !ready) return;
        const observer = new ResizeObserver(determineIfTableIsToScroll)
        observer.observe(tableRef.current)
        observer.observe(layoutRef.current)
        return () => observer.disconnect()
    }, [tableRef.current, layoutRef.current, ready])

    /**
     * Determines whether the table element is to be scrolled.
     */
    const determineIfTableIsToScroll = () => {
        const table = tableRef.current;
        const layout = layoutRef.current;
        if (!table || !layout) return
        const scrollable = table.scrollWidth > layout.clientWidth;
        setTableScrollable(prevState => {
            if (scrollable && !prevState) {
                return true;
            }
            if (!scrollable && prevState) {
                return false;
            }
            return prevState;
        });
    }

    /**
     * Sets the leftSticky of th
     * @param {UIEvent} e
     */
    const onContainerScrolled = (e) => {
        const left = e.target?.scrollLeft ?? 0;
        if (left === 0 && isTableScrolled) {
            setIsTableScrolled(false);
            return;
        }
        if (left > 0 && !isTableScrolled) {
            setIsTableScrolled(true);
        }
    }


    return (
        <>
            <div className={classnames('table-layout', color, className)}
                 ref={layoutRef}
                 style={{
                     '--max-width': parentOffsetWidth,
                 }}>
                <div className={classnames('table-container', {'table-scrollable': !!tableScrollable})}
                     onScroll={onContainerScrolled}
                >
                    <table
                        className={classnames('app-table', {'scrolled': isTableScrolled})}
                        ref={tableRef}
                    >
                        <TableHeader
                            cells={cells}
                            orderBy={orderBy}
                            onSort={onSort}
                            isTableScrolled={isTableScrolled}
                        />
                        <TableBody
                            animationProps={{...DefaultTableRowAnimationProps, ...rowAnimationProps}}
                            loadingAnimationProps={{...DefaultTableRowAnimationProps, ...rowAnimationProps, ...loadingAnimationProps}}
                            loading={loading}
                            cells={cells}
                            data={data}
                        />
                    </table>
                </div>
                {
                    !!paginationInfo &&
                    <TablePagination
                        paginationInfo={paginationInfo}
                        onPageSizeChange={onPageSizeChange}
                        onCurrentPageChange={onCurrentPageChange}
                        pageSizes={pageSizes}
                    />
                }
            </div>

        </>
    )
}


export default Table;
