import React from "react";
import {useDispatch, useSelector} from "react-redux";
import Dialog from "../../base/dialog";
import {DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import {dialogActions} from "../../../../redux/entities/dialog-slice";

const ConfirmationDialog = () => {
    const state = useSelector(state => state?.dialog?.confirmation ?? {});
    const dispatch = useDispatch();


    /**
     * Call back function being passed to the dialog component for handling showing/closing dialog
     *
     * @param {boolean} toggleOpen
     */
    const setOpen = (toggleOpen) => dispatch(dialogActions.confirmationDialogToggle(toggleOpen));

    return (
        <>
            <Dialog
                max={'md'}
                fullWidth
                open={state.open}
                setOpen={setOpen}
                {...state.props}
            >
                <DialogTitle className={'title'} id={'scroll-dialog-title'}>
                    <div>
                        <h2>
                            {state?.title ?? ''}
                        </h2>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className={'remove-confirmation-description'}>
                        {state?.description ?? ''}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button
                        className='button primary outlined px-4'
                        type='button'
                        onClick={state.onClose}
                    >
                        {state?.closeButtonLabel ?? ''}
                    </button>
                    <button
                        className='button primary px-5'
                        type='submit'
                        onClick={state.onConfirm}
                    >
                        {state?.confirmButtonLabel ?? ''}
                    </button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ConfirmationDialog;
