/**
 * Manages the _animations between two elements in the DOM.
 */
class AnimationService {
    /**
     * Constructs an animation service with no _animations.
     */
    constructor() {
        this._animations = {}
    }

    /**
     * Adds an animation to the _animations of this service.
     * the added _animations are then used to be animated.
     * @param name
     * @param baseId
     * @param destinationId
     * @return AnimationEntity the animation entity that has been created
     */
    addAnimation({
                     name,
                     baseId,
                     destinationId,
                 }) {
        const elements = AnimationEntity.validateElementExistence(baseId, destinationId)
        if (!elements) return
        const animation = new AnimationEntity(name, baseId, destinationId)
        this._animations[name] = animation
        return animation;
    }

    /**
     * Removes an animation from this service.
     * @param animationName
     */
    removeAnimation(animationName) {
        delete this._animations[animationName]
    }

    /**
     * Fetches an animation from this service via its name while creating.
     * @param {string} animationName
     * @return {AnimationEntity | undefined}
     */
    getAnimation(animationName) {
        return this._animations[animationName]
    }
}


/**
 * Represents an animation entity that manages the animation performance between two elements
 */
class AnimationEntity {

    /**
     * Validates the existence of the two elements to be animated in the DOM.
     * @param {string} baseId the id of the base element
     * @param {string} destinationId the id of the destination element
     * @return {boolean|{baseElement: HTMLElement, destinationElement: HTMLElement}}
     */
    static validateElementExistence(baseId, destinationId) {
        const baseElement = document.getElementById(baseId)
        if (!baseElement) {
            console.warn(`The given id is not attached to an element. given id: ${baseId}`)
            return false
        }
        const destinationElement = document.getElementById(destinationId)
        if (!destinationElement) {
            console.warn(`The given id is not attached to an element. given id: ${destinationId}`)
            return false
        }
        return {baseElement, destinationElement}
    }

    /**
     * Constructs an animation service with no _animations.
     */
    constructor(name, baseId, destinationId) {
        this.name = name
        this.baseId = baseId
        this.destinationId = destinationId
        this.eventListener = null
    }

    /**
     * Plays this animation such that the transition will happened from the baseElement to the destinationElement
     *
     * for the opposite effect checkout the [reverse]{@link reverse} method
     * @param {number} transitionDuration the transition of duration
     * @param {{[p: string]:function}} callbacks a set of callbacks
     */
    play(transitionDuration = 1000, callbacks = {}) {
        this._setupAnimation(this.baseId, this.destinationId, transitionDuration, callbacks)
    }

    /**
     * Plays this animation such that the transition will happened from the destinationElement to the baseElement
     *
     * for the opposite effect checkout the [play]{@link play} method
     * @param {number} transitionDuration the transition of duration
     * @param {{[p: string]:function}} callbacks a set of callbacks
     */
    reverse(transitionDuration = 1000, callbacks = {}) {
        this._setupAnimation(this.destinationId, this.baseId, transitionDuration, callbacks)
    }

    /**
     * Toggles the visibility of the element associated with the id based on the provided value.
     * @param {string} id
     * @param {boolean} visible
     */
    toggleVisibility(id, visible) {
        if (![this.baseId, this.destinationId].includes(id)) return
        const element = document.getElementById(id)
        if (!element) return;
        this._toggleVisibility(element, visible)
    }

    /**
     * Prepares for the animation between the two elements.
     *
     * the process is as follows:
     * 1. validates the existence of the two elements
     * 2. identifies the properties and attributes that need to be animated
     * 3. fetches the position of the two elements
     * 4. creates a clone of the baseElement
     * 5. creates an overlay and adds the cloned element as a child of it. appends it to the end of the dom
     * 6. performs the animation
     * @param {string} baseId
     * @param {string} destinationId
     * @param {number} animationDuration the duration in which the cloned element will animate until.
     * @param {{[p: string]: function}} callbacks callback functions
     * @private
     */
    _setupAnimation(baseId, destinationId, animationDuration, callbacks) {
        const elements = AnimationEntity.validateElementExistence(baseId, destinationId)
        if (!elements) {
            return;
        }
        const {baseElement, destinationElement} = elements
        const animationProps = this._identifyAnimationProps(baseElement, destinationElement)
        const baseElementRect = baseElement.getBoundingClientRect()
        const destinationElementRect = destinationElement.getBoundingClientRect()
        const clonedBase = this._cloneBase(baseElement, baseElementRect, destinationElementRect, animationDuration)
        const {onCloneAdded} = (callbacks ?? {})
        if (onCloneAdded) onCloneAdded()
        this._performAnimation(baseElement,
            destinationElement,
            clonedBase,
            animationProps,
            baseElementRect,
            destinationElementRect,
            animationDuration,
            callbacks
        );
    }

    /**
     * Identifies the properties and attributes that need to be animated.
     * @param {Element & ElementCSSInlineStyle} baseElement
     * @param {Element & ElementCSSInlineStyle} destinationElement
     * @return {{}} an object of the differences between the two elements
     * @private
     */
    _identifyAnimationProps(baseElement, destinationElement) {
        const differences = {}
        for (const stylePropName in destinationElement.style) {
            if (baseElement.style[stylePropName] !== destinationElement.style[stylePropName]) {
                differences[stylePropName] = destinationElement.style[stylePropName]
            }
        }
        return differences;
    }

    /**
     * Creates a clone of the baseElement and applies the position and transition based on the given arguments.
     *
     * @param {Node} baseElement
     * @param {DOMRect} baseElementRect
     * @param {DOMRect} destinationElementRect
     * @param {number} animationDuration
     * @return {Node}
     * @private
     */
    _cloneBase(baseElement, baseElementRect, destinationElementRect, animationDuration) {
        const cloned = baseElement.cloneNode(true);
        cloned.setAttribute('id', `cloned__${cloned.getAttribute('id')}`)
        cloned.style.position = 'absolute'
        cloned.style.left = `${baseElementRect.left}px`
        cloned.style.top = `${baseElementRect.top}px`
        cloned.style.width = `${destinationElementRect.width}px`
        cloned.style.height = `${destinationElementRect.height}px`
        cloned.style.transition = `all ${animationDuration}ms ease-in-out`
        if (cloned.classList.contains('invisible')) {
            cloned.classList.remove('invisible')
        }
        if (cloned.classList.contains('d-none')) {
            cloned.classList.remove('d-none')
        }
        return cloned;
    }

    /**
     * Creates an overlay for the cloned element to sit in it.
     *
     * @param {Node[]} children
     * @return {HTMLDivElement}
     * @private
     */
    _createOverlay(children) {
        const overlay = document.createElement('div')
        overlay.style.position = 'fixed'
        overlay.style.width = `${window.innerWidth}px`
        overlay.style.height = `${window.innerHeight}px`
        overlay.style.zIndex = '1300'
        overlay.style.top = '0px';
        overlay.style.left = '0px';
        overlay.overflow = 'hidden'
        overlay.append(...children)
        return overlay
    }

    /**
     * Performs the animation between the two base and destination element.
     *
     * the idea is that we created an overlay for the cloned element that is exactly where the base element has been
     * in the dom. we will animate the cloned element from the baseElement's position to destination along with any
     * of their css attributes to perform a smooth change.
     * @param {Element & ElementCSSInlineStyle}  baseElement
     * @param {Element & ElementCSSInlineStyle}  destinationElement
     * @param {Element & ElementCSSInlineStyle & Node}  clonedBase
     * @param {any} animationProps
     * @param {DOMRect} baseElementRect
     * @param {DOMRect} destinationElementRect
     * @param {number} animationDuration
     * @param {{[p: string]: function}} callbacks
     * @private
     */
    _performAnimation(baseElement,
                      destinationElement,
                      clonedBase,
                      animationProps,
                      baseElementRect,
                      destinationElementRect,
                      animationDuration,
                      callbacks
    ) {

        const overlay = this._createOverlay([clonedBase])
        document.body.appendChild(overlay)
        const {onAnimationStart, onAnimationFinish} = (callbacks ?? {})
        this._toggleWindowScrollability(false)
        this._toggleVisibility(baseElement, false)
        this._toggleVisibility(destinationElement, false)

        const x = destinationElementRect.left - baseElementRect.left
        const y = destinationElementRect.top - baseElementRect.top

        animationProps['transform'] = `translate(${x}px, ${y}px)`

        window.requestAnimationFrame(() => {
            if (onAnimationStart) onAnimationStart()
            for (const animationProperty in animationProps) {
                clonedBase.style.setProperty(animationProperty, animationProps[animationProperty])
            }
            setTimeout(() => {
                this._finishAnimation(baseElement, destinationElement, overlay, clonedBase, onAnimationFinish)
            }, animationDuration)
        })
    }

    /**
     * Finishes the animation by removing the cloned element and its overlay, and showing the destination element.
     * @param {Element & ElementCSSInlineStyle} baseElement
     * @param {Element & ElementCSSInlineStyle} destinationElement
     * @param {HTMLDivElement} overlay
     * @param {Element & ElementCSSInlineStyle} clonedBase
     * @param {function} onAnimationFinish
     * @private
     */
    _finishAnimation(baseElement,
                     destinationElement,
                     overlay,
                     clonedBase,
                     onAnimationFinish) {
        if (onAnimationFinish) onAnimationFinish()
        this._toggleVisibility(baseElement, false)
        this._toggleVisibility(overlay, false)
        this._toggleVisibility(destinationElement, true)
        this._toggleWindowScrollability(true)
        window.requestAnimationFrame(() => {
            document.body.removeChild(overlay)

        })
    }

    /**
     * Toggles the visibility of the element based on the provided value.
     * @param {Element} element
     * @param {boolean} visible
     * @private
     */
    _toggleVisibility(element, visible) {
        if (!visible) {
            if (!element.classList.contains('invisible')) {
                element.classList.add('invisible')
            }
        } else {
            if (element.classList.contains('invisible')) {
                element.classList.remove('invisible')
            }
        }
    }

    /**
     * Makes the window scrollable or not scrollable depending on the given scroll boolean.
     * used during animation to prevent user from scrolling their screen.
     * @param {boolean} scroll
     * @private
     */
    _toggleWindowScrollability(scroll) {
        if (scroll) {
            document.body.classList.remove('stop-scroll')
        } else {
            if (!document.body.classList.contains('stop-scroll')) {
                document.body.classList.add('stop-scroll')
            }
        }
    }
}

export default AnimationService
