import React, {useContext, useEffect, useRef, useState} from 'react';
import LocationCard from "../../../../../components/app-specific/location-card";
import {CompanyContext} from "../../../../../contexts/company";
import Api from "../../../../../../core/services/api_service";
import useRouter from "../../../../../hooks/use-router";
import useIsMounted from "../../../../../hooks/use-is-mounted";
import {LocationTypes} from "../../../../../../core/constants/enums";
import {ReactComponent as BackIcon} from "../../../../../../assets/images/views/location/back.svg";
import {routeFunctions} from "../../../../../routes";
import {useTranslation} from "react-i18next";
import {ReactComponent as GenerateReportIcon} from "../../../../../../assets/images/report.svg";
import BreadCrumbs from "../../../../../components/app-specific/bread-crumbs";
import NoEntries from "../../../../../components/app-specific/no-entries";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {ReactComponent as PlusIcon} from "../../../../../../assets/images/views/location/plus.svg";


const SectionsView = () => {
    const {history, params: {warehouse, company: companyParam, aisle}} = useRouter();
    const [company] = useContext(CompanyContext);
    const [sections, setSections] = useState([]);
    const [locationInfo, setLocationInfo] = useState({});
    const [loading, setLoading] = useState(true);
    const [generatingReport, setGeneratingReport] = useState(false);
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const isMounted = useIsMounted();
    const {t} = useTranslation();
    const selectedCompany = useRef(company);
    const translations = t('views.panel.company.location', {
        returnObjects: true,
        code: locationInfo?.aisle?.code ?? ''
    });

    /**
     * With each change in the company id:
     * - navigates user to the list of warehouses related to the selected company
     */
    useEffect(() => {
        if (selectedCompany.current?.id === company?.id)
            return;
        selectedCompany.current = company;
        history.replace(routeFunctions.panel.company.location.warehouses(company.name))
    }, [company?.id])

    /**
     * Listens to the changes of the any of the previously selected location hierarchies, and if any of them changed:
     * Fetches the list of the rows for the provided warehouse and aisle.
     */
    useEffect(() => {
        getLocationInfo();
    }, [warehouse, aisle]);

    /**
     * Gets the location address information
     */
    const getLocationInfo = () => {
        setLoading(true);
        //TODO: call the Api to load the location address information
        const response = {
            resultFlag: true,
            data: {warehouse: {id: warehouse, code: '00'}, aisle: {id: aisle, code: "A5"}}
        };
        if (response?.resultFlag) {
            setLocationInfo(response?.data);
            if (window.bizpire?.makeLocationBreadcrumbs) {
                setBreadcrumbs(window.bizpire?.makeLocationBreadcrumbs(response?.data))
            }
            getSections()
        } else {
            setLoading(false)
        }
    }

    /**
     * Fetches the list of sections for the provided warehouse from the server and populates the inner state.
     */
    const getSections = () => {
        Api.getSections(aisle, warehouse).then((response) => {
            if (!isMounted()) return;
            if (response?.resultFlag) {
                setSections(response?.data ?? []);
            }
            setLoading(false);
        })
    }

    /**
     * Generates a csv report for all the parts in the selected company and the provided values as filters.
     * @param {any} values the selected filters accumulated from the dialog.
     */
    const generateReport = (values) => {
        setGeneratingReport(true);
        const forApi = {...values}
        //TODO: call api for generating report for all the parts in an aisle
        setGeneratingReport(false);
    }

    /**
     * Submits the new section to the server and appends the newly created section to the list of sections
     * @param {string} sectionCode
     */
    const createSection = async (sectionCode) => {
        const forApi = {
            code: sectionCode,
            aisleId: parseInt(aisle)
        }
        const response = await Api.createSection(forApi, parseInt(warehouse));
        if (!isMounted()) return;
        if (response?.resultFlag) {
            setSections(prevState => [...prevState, response.data]);
        } else {
            // TODO: return errors based on the errors given in the api.
        }
    }

    /**
     * Updates the section information using the provided section id and section code
     * If the result was successful, updates the section in the list of sections
     * @param {number} sectionId
     * @param {string} sectionCode
     */
    const updateSection = async (sectionId, sectionCode) => {
        const forApi = {
            id: sectionId,
            code: sectionCode,
            aisleId: parseInt(aisle)
        }
        const response = await Api.updateSection(forApi, forApi.warehouseId);
        if (!isMounted()) return;
        if (response?.resultFlag) {
            setSections(prevState => prevState?.map(e => e.section.id === sectionId
                ? {...e, section: {...e.section, code: sectionCode}}
                : e
            ) ?? []);
        } else {
            // TODO: return errors based on the errors given in the api.
        }
    }

    /**
     * Removes the given section from the list of sections after removing it from the server.
     * @param {any} section the id associated with the section to be removed
     */
    const removeSection = (section) => {
        setSections(prevState => prevState?.map(e => e.section.id === section?.id
            ? {...e, loading: true}
            : e
        ) ?? []);
        Api.removeSection(section?.id, parseInt(warehouse)).then((response) => {
            if (!isMounted()) return;
            if (response?.resultFlag) {
                setSections(prevState => prevState?.filter((e) => e.section.id !== section?.id) ?? []);
            }
            setSections(prevState => prevState?.map(e => e.section.id === section?.id
                ? {...e, loading: false}
                : e
            ) ?? []);
        })
    }


    /**
     * Navigates the user to the previous location page
     */
    const navigateBack = () => {
        history.push(routeFunctions.panel.company.location.aisles(companyParam, warehouse));
    }

    /**
     * Opens the general report's dialog and binds the generation report callback for successful filtering.
     */
    const showGenerateReportDialog = () => {
        const generateLocationReportDialog = window.bizpire?.generateLocationReportDialog;
        if (!generateLocationReportDialog) return;
        generateLocationReportDialog(true, locationInfo, generateReport)
    }

    /**
     * Opens the Add location dialog and binds the section creation logic to the callback of this dialog.
     */
    const showAddSectionDialog = () => {
        const upsertLocationDialog = window.bizpire?.upsertLocationDialog;
        if (!upsertLocationDialog) return
        const locationCode = locationInfo?.aisle?.code ?? '';
        upsertLocationDialog(
            true,
            LocationTypes.section,
            undefined,
            ({code}) => createSection(code + locationCode)
        )
    }

    /**
     * Opens the Add location dialog and binds the section creation logic to the callback of this dialog.
     * @param {any} section the section to be updated.
     */
    const showEditSectionDialog = (section) => {
        if (!section) return;
        const upsertLocationDialog = window.bizpire?.upsertLocationDialog;
        if (!upsertLocationDialog) return
        const locationCode = locationInfo?.aisle?.code ?? '';
        upsertLocationDialog(
            true,
            LocationTypes.section,
            {code: section.code?.replace(locationCode, "")},
            ({code}) => updateSection(section.id, locationCode + code)
        )
    }

    return (
        <div className={'panel-card location-view'}>
            <div className='d-flex justify-content-between align-items-center'>
                <div className={'d-flex align-items-center'}>
                    <button className='button primary icon-button back'
                            onClick={navigateBack}>
                        <BackIcon/>
                    </button>
                    <p className='title'>
                        {translations?.sections?.title ?? ''}
                    </p>
                </div>
                <div>
                    <button className='button outlined primary report'
                            onClick={showGenerateReportDialog}>
                        <GenerateReportIcon className='me-2'/>
                        {
                            generatingReport
                                ? translations?.actions?.generateReport?.loading ?? ''
                                : translations?.actions?.generateReport?.title ?? ''
                        }
                    </button>
                </div>
            </div>
            <div className={'mt-2'}>
                <BreadCrumbs data={breadcrumbs}/>
            </div>
            <div className='mt-4'>
                {
                    loading
                        ? (
                            <div className='d-flex flex-wrap'>
                                {
                                    Array(10).fill(null).map((e, index) => (
                                        <div key={index} className={'loading location-card'}>
                                            <div/>
                                        </div>
                                    ))
                                }
                            </div>
                        )
                        : sections?.length < 1
                            ? <NoEntries
                                text={translations?.sections?.noEntries ?? ''}
                                includeButton
                                buttonText={translations?.sections?.create}
                                buttonProps={{
                                    className: 'button primary px-4',
                                    disabled: generatingReport,
                                    onClick: showAddSectionDialog
                                }}
                            />
                            : <TransitionGroup className='d-flex flex-wrap'>
                                {
                                    sections?.map(location => (
                                            <CSSTransition
                                                classNames={'fade'}
                                                timeout={{enter: 800, exit: 300,}}
                                                key={location.section.id}>
                                                <LocationCard
                                                    location={location.section}
                                                    onEdit={showEditSectionDialog}
                                                    onRemove={removeSection}
                                                    type={LocationTypes.section}
                                                />
                                            </CSSTransition>
                                        )
                                    )
                                }
                                <CSSTransition
                                    key={'new-location-entry'}
                                    classNames={'fade'}
                                    timeout={{enter: 800, exit: 300,}}>
                                    <button
                                        className={'button primary add-location'}
                                        onClick={showAddSectionDialog}>
                                        <p>
                                            {translations?.actions?.addNew ?? ''}
                                        </p>
                                        <div className='mt-2 text-center'>
                                            <PlusIcon/>
                                        </div>
                                    </button>
                                </CSSTransition>
                            </TransitionGroup>
                }
            </div>
        </div>
    )
};

export default SectionsView;
