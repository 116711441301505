import React, {useContext} from "react";
import {PanelContext} from "../../../../contexts/panel";
import classnames from "classnames";
import sidebarMap from "../_map";
import SidebarMenuItem from "../menu-item";

const SidebarBody = () => {
    const [{collapsed}] = useContext(PanelContext);

    return (
        <div className={classnames('sidebar-body', {collapsed})}>
            {
                sidebarMap.body?.map(item => (
                    <SidebarMenuItem key={item.id} item={item} menu/>
                ))
            }
        </div>
    );
}

export default SidebarBody;
