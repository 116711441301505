import React, {useState} from "react";
import useIsMounted from "../../../../hooks/use-is-mounted";
import CacheService from "../../../../../core/services/cache_service";

const SidebarLogoutMenuItem = ({itemProps, MenuItem}) => {
    const [showLogout, setShowLogout] = useState(false);
    const isMounted = useIsMounted();

    /**
     * Logs the user out of the system and then navigates the user back to the landing page of the application.
     */
    const logout = () => {
        setShowLogout(true);
        setTimeout(() => {
            if (!isMounted()) return;
            window.location.replace(window.location.origin);
            CacheService.removeUserInformation();
        }, 300);
    }

    return (
        <>
            <div {...itemProps} onClick={logout}>
                {MenuItem}
            </div>
            <div className={'logout-container'}>
                {
                    showLogout &&
                    <iframe
                        title={'logout'}
                        src={`${window.bizpire?.getCoreUrls()?.logoutUrl}`}
                        width='0'
                        height='0'
                    />
                }
            </div>

        </>
    );
}

export default SidebarLogoutMenuItem;
