import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import moment from "moment";
import en from 'moment/locale/en-gb';
import fr from 'moment/locale/fr';
import {ApplicationLanguages} from "./core/constants/enums";


const fallbackLng = [ApplicationLanguages.english];
const availableLanguages = Object.values(ApplicationLanguages)
//TODO: add the moment language for adding a new language
const momentLanguages = {
    en,
    fr
}

/**
 * Changes the locale of the global moment object as the language of the app changes.
 * @param {string} language
 */
const onLanguageChange = (language) => {
    moment.locale(language, momentLanguages[language]);
}

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng,
        detection: {
            checkWhitelist: true
        },
        backend: {
            loadPath: `${process.env.PUBLIC_URL}/assets/locales/{{lng}}/{{ns}}.json?lng={{lng}}&{{ns}}`,
            addPath: `${process.env.PUBLIC_URL}/assets/locales/add/{{lng}}/{{ns}}`,
        },
        debug: false,
        whitelist: availableLanguages,
        interpolation: {
            escapeValue: false
        }
    });

i18n.on("languageChanged", (lang) => onLanguageChange(lang));

export default i18n;
