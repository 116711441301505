import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import youTubePlayer from 'yt-player';
import {ReactComponent as PlayIcon} from "../../../../assets/images/views/public-views/landing/play-button.svg";
import {Fade} from "@material-ui/core";


const VideoPlayer = ({id, videoId, title = 'Video Player'}) => {
    const [paused, setPaused] = useState(true);
    /**@type {React.MutableRefObject<YouTubePlayer & NodeJS.EventEmitter>}*/
    const player = useRef();
    const ref = useRef();

    /**
     * As soon as the component um-mounts destroys the current player.
     */
    useLayoutEffect(() => {
        return () => {
            player.current?.destroy();
            player.current = null;
        }
    }, [])

    /**
     * Listens for the changes in the ref and with each change:
     * if the ref exists, instantiates the current player
     */
    useEffect(() => {
        if (!ref.current || player.current) return;
        // noinspection JSValidateTypes
        player.current = new youTubePlayer(`#${id}`, {
            autoplay: false,
            captions: undefined,
            controls: true,
            keyboard: true,
            fullscreen: true,
            annotations: true,
            modestBranding: false,
            related: true,
            timeupdateFrequency: 1000,
            playsInline: true,
            start: 0
        });
    }, [ref.current]);

    /**
     * Listens to the changes in videoId and playerRef and with each change:
     * if they both exist and the player is instantiated, then loads the video with the current videoId
     */
    useEffect(() => {
        if (!videoId || !player.current) return;
        player.current.load(videoId);
    }, [videoId, player.current])

    /**
     * Plays the current video of the player and removes the paused icon
     */
    const play = () => {
        if (paused) {
            setPaused(false);
            player.current?.play();
        }
    }


    return (
        <>
            <div className={'video-player'}>
                <Fade in={paused}>
                    <div className={'play-button-container'}>
                        <button className={'play-button'} onClick={play}>
                            <PlayIcon/>
                        </button>
                    </div>
                </Fade>
                <div
                    ref={ref}
                    id={id}
                    title={title}
                />
            </div>

        </>
    );
}


export default VideoPlayer;
