import ApiExecutor from "./executor";
import {
    ApiInterceptors,
    ApiResponse,
    ChangeSerializedRowItemsLocationRequestDs,
    ChangeSerializedRowItemsLocationResponseDs,
    ChangeUnSerializedRowItemsLocationRequestDs,
    ChangeUnSerializedRowItemsLocationResponseDs,
    GetAllEntriesOfWarehouseResponseDs,
    GetInventorySchemaSavedState,
    GetRowItemsResponseDs,
    SaveInventorySchemaRequestDs,
} from "./type-declarations";
import {ApiMethods} from "../../models/constants/enums";
import InventorySchemaVisualizerApiEndpoints from "../../models/static/api-endpoints";

/**
 * The interface that houses all the available api calls related to this application.
 */
class InventorySchemaVisualizerApiService {

    /**
     * The executor of this api responsible for handling the api call themselves.
     */
    private static readonly executor = new ApiExecutor();

    /**
     * Injects api interceptors to the axios instance used in this interface.
     * @param interceptors
     */
    static injectInterceptors(interceptors: Partial<ApiInterceptors>): void {
        return this.executor.injectInterceptors(interceptors);
    }

    // ###################################       WAREHOUSE         ###################################

    /**
     * Fetches all the location entries of a specific warehouse provided its ID.
     * @param warehouseId
     */
    static async getAllEntriesOfWarehouse(warehouseId: number): ApiResponse<GetAllEntriesOfWarehouseResponseDs> {
        const response = await this.executor.execute({
            method: ApiMethods.get,
            url: InventorySchemaVisualizerApiEndpoints.warehouse.getAllEntries(warehouseId),
            headers: {
                WarehouseID: warehouseId,
            },
        });
        if (!response?.resultFlag)
            return response;
        return {
            ...response,
            data: {
                aisles: response.data ?? [],
            },
        };
    }


    // ###################################       ROW         ###################################

    /**
     * Fetches the items associated with the provided rowId from the server.
     *
     * @param warehouseId
     * @param rowId
     */
    static async getRowItems(warehouseId: number, rowId: number): ApiResponse<GetRowItemsResponseDs> {
        return await this.executor.execute({
            method: ApiMethods.get,
            url: InventorySchemaVisualizerApiEndpoints.row.getRowItems(rowId),
            headers: {
                WarehouseID: warehouseId,
            },
        });
    }

    // ###################################       LOCATION         ###################################

    /**
     * Changes the location of a serialized item in the server.
     * @param warehouseId
     * @param data
     */
    static async changeSerializedItemsLocation(warehouseId: number, data: ChangeSerializedRowItemsLocationRequestDs): ApiResponse<ChangeSerializedRowItemsLocationResponseDs> {
        return await this.executor.execute({
            method: ApiMethods.post,
            url: InventorySchemaVisualizerApiEndpoints.location.changeSerializedItemsLocation,
            data: data,
            headers: {
                WarehouseID: warehouseId,
            },
            showErrorToast: true,
            showSuccessToast: true,
        });
    }

    /**
     * Changes the location of an un-serialized item in the server.
     * @param warehouseId
     * @param data
     */
    static async changeUnSerializedItemsLocation(warehouseId: number, data: ChangeUnSerializedRowItemsLocationRequestDs): ApiResponse<ChangeUnSerializedRowItemsLocationResponseDs> {
        return await this.executor.execute({
            method: ApiMethods.post,
            url: InventorySchemaVisualizerApiEndpoints.location.changeUnSerializedItemsLocation,
            data: data,
            headers: {
                WarehouseID: warehouseId,
            },
            showErrorToast: true,
            showSuccessToast: true,
        });
    }

    // ###################################       INVENTORY-SCHEMA         ###################################

    /**
     * Fetches the saved state of the inventory schema for a specific warehouse from the server.
     * @param warehouseId
     */
    static async getInventorySchemaSavedState(warehouseId: number): ApiResponse<GetInventorySchemaSavedState> {
        const response = await this.executor.execute({
            method: ApiMethods.get,
            url: InventorySchemaVisualizerApiEndpoints.warehouse.getAll,
            headers: {
                WarehouseID: warehouseId,
            },
        });
        if (!response?.resultFlag)
            return response;
        response.data = response.data?.find((e: any) => e.id === warehouseId)?.schemaData;
        try {
            response.data = JSON.parse(response.data ?? '{}');
        } catch {
            response.data = '{}';
        }
        return response;
    }

    /**
     * Saves the state of the inventory-schema for a specific warehouse.
     * @param data
     */
    static async saveInventorySchemaState(data: SaveInventorySchemaRequestDs): ApiResponse<ChangeUnSerializedRowItemsLocationResponseDs> {
        return await this.executor.execute({
            method: ApiMethods.put,
            url: InventorySchemaVisualizerApiEndpoints.warehouse.update,
            data: data,
            headers: {
                WarehouseID: data.id,
            },
        });
    }
}


export * from './type-declarations';
export default InventorySchemaVisualizerApiService;
