import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import useRouter from "../../../../../hooks/use-router";
import {DefaultInitialPaginationInfo, DefaultPaginationInfo, TableCellAlignments, TableCellTypes} from "../../../../../../core/constants/enums";
import {
    generatePaginationAndOrderByQueryResetter,
    hasOrderByOfQueryChanged,
    hasPaginationInfoChanged,
    onTableCurrentPageChange,
    onTablePageSizeChange,
    onTableSort
} from "../../../../../../core/services/searching-utils";
import {SingleOrderQueryNames} from "../../../../../../core/constants/query-names";
import {deepEqual} from "../../../../../../core/services/utils";
import {routeFunctions} from "../../../../../routes";
import {ReactComponent as BarcodeIcon} from "../../../../../../assets/images/barcode-icon.svg";
import classnames from "classnames";
import {ReactComponent as ChevronIcon} from "../../../../../../assets/images/chevron-icon.svg";
import {ReactComponent as IsSerialized} from "../../../../../../assets/images/order-serializable/serialized.svg";
import {ReactComponent as NotSerialized} from "../../../../../../assets/images/order-serializable/not-serialized.svg";
import {Col, Collapse, Row} from "react-bootstrap";
import Table from "../../../../../containers/table";
import TableSpacer from "../../../../../containers/table/spacer";
import {ReactComponent as BackIconButton} from "../../../../../../assets/images/back-icon.svg";
import Barcode from "react-barcode";
import {lighterPrimaryColor, primaryTextColor} from "../../../../../../assets/mui/colors";
import SalesOrderPartsSearchSection from "../../../../../components/search-sections/sales-order";
import NoEntries from "../../../../../components/app-specific/no-entries";
import {useDispatch} from "react-redux";
import {dialogActions} from "../../../../../../redux/entities/dialog-slice";
import useIsMounted from "../../../../../hooks/use-is-mounted";

const infoTableCellKeys = {
    customerName: "customerName",
    orderDate: "orderDate",
    requiredDate: "requiredDate",
    totalProducts: "totalProducts",
    totalItems: "totalItems"
}

const mainTableCellKeys = {
    expand: "expand",
    partNumber: "partNumber",
    orderCount: "orderCount",
    committedCount: "committedCount",
    notScannedCount: "notScannedCount",
    isSerialized: "isSerialized",
    weight: "weight",
    height: "height",
    width: "width",
    depth: "depth",
    description: "description"
}

const innerTableCellKeys = {
    serialNumber: "serialNumber",
    scannedOn: "scannedOn",
    operator: "operator"
}

const PurchaseOrderSingleView = () => {
    const {t} = useTranslation();
    const {query, location, history, params, stringifyUrl} = useRouter();
    const [purchaseOrderFullInfo, setPurchaseOrderFullInfo] = useState({});
    const [parts, setParts] = useState([]);
    const [paginationInfo, setPaginationInfo] = useState(DefaultPaginationInfo);
    const [orderBy, setOrderBy] = useState(null);
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState({});
    const [expandedSectionsIds, setExpandedSectionsIds] = useState([]);
    const dispatch = useDispatch();
    const isMounted = useIsMounted();

    const translation = t("views.panel.company.purchaseOrder.single", {returnObjects: true});
    const errorMessage = t('views.panel.errorMessages', {returnObjects: true})


    const infoTableCells = [
        {
            title: translation?.infoTableHeader?.customerName ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: infoTableCellKeys.customerName,
            size: 0,
        },
        {
            title: translation?.infoTableHeader?.orderDate ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.date,
            name: infoTableCellKeys.orderDate,
            size: 0
        },
        {
            title: translation?.infoTableHeader?.requiredDate ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.date,
            name: infoTableCellKeys.requiredDate,
            size: 0
        },
        {
            title: translation?.infoTableHeader?.totalProducts ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.string,
            name: infoTableCellKeys.totalProducts,
            size: 0
        },
        {
            title: translation?.infoTableHeader?.totalItems ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.string,
            name: infoTableCellKeys.totalItems,
            size: 0
        },
    ];

    const mainTableCells = [
        {
            alignment: TableCellAlignments.center,
            type: TableCellTypes.element,
            name: mainTableCellKeys.expand,
            size: 0,
        },
        {
            title: translation?.partsTableHeaders?.partNumber ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: mainTableCellKeys.partNumber,
            sortable: true,
        },
        {
            title: translation?.partsTableHeaders?.orderCount ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.element,
            name: mainTableCellKeys.orderCount,
            sortable: true,
        },
        {
            title: translation?.partsTableHeaders?.committedCount ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.element,
            name: mainTableCellKeys.committedCount,
            sortable: true,
            size: 1.3
        },
        {
            title: translation?.partsTableHeaders?.notScannedCount ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.element,
            name: mainTableCellKeys.notScannedCount,
            sortable: true,
            size: 1.4
        },
        {
            title: translation?.partsTableHeaders?.isSerialized ?? '',
            alignment: TableCellAlignments.center,
            type: TableCellTypes.element,
            name: mainTableCellKeys.isSerialized,
            sortable: true,
        },
        {
            title: translation?.partsTableHeaders?.weight ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.string,
            name: mainTableCellKeys.weight,
            sortable: true,
        },
        {
            title: translation?.partsTableHeaders?.height ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.string,
            name: mainTableCellKeys.height,
            sortable: true,
        },
        {
            title: translation?.partsTableHeaders?.width ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.string,
            name: mainTableCellKeys.width,
            sortable: true,
        },
        {
            title: translation?.partsTableHeaders?.depth ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.string,
            name: mainTableCellKeys.depth,
            sortable: true,
        },
        {
            title: translation?.partsTableHeaders?.description ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: mainTableCellKeys.description,
            sortable: true,
            size: 3
        },
    ];

    const innerTableCells = [
        {
            title: translation?.partsInnerTableHeader?.serialNumber ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: innerTableCellKeys.serialNumber,
        },
        {
            title: translation?.partsInnerTableHeader?.scannedOn ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.dateTime,
            name: innerTableCellKeys.scannedOn,
        },
        {
            title: translation?.partsInnerTableHeader?.operator ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: innerTableCellKeys.operator,
        },
    ];

    /**
     * With each change in the url params:
     *
     * - Calls the api for getting the full information of the sales order
     */
    useEffect(() => {
        setPurchaseOrderFullInfo({
            id: 1,
            orderNumber: "Q123123-0",
            customerName: "Richardson",
            orderDate: new Date(),
            requiredDate: new Date(),
            totalProducts: 10,
            totalItems: 50,
        });
    }, [params]);

    /**
     * With each change in the query params of the url:
     *
     * - Checks if the filters, pagination info, or order-by have been changed, if so, calls the api for getting the parts in the purchase order with new filters
     */
    useEffect(() => {
        hasPaginationInfoChanged(query, paginationInfo, setPaginationInfo);
        const newFilter = hasFiltersChanged();
        const newOrderBy = hasOrderByOfQueryChanged(query, orderBy, setOrderBy);
        if (newFilter || newOrderBy)
            setPaginationInfo(DefaultInitialPaginationInfo)
    }, [query])

    useEffect(() => {
        if (paginationInfo.currentPage === DefaultInitialPaginationInfo.currentPage)
            return setPaginationInfo(DefaultPaginationInfo);
        searchParts();
    }, [paginationInfo.currentPage, paginationInfo.pageSize])

    /**
     * Checks if the search params has been changes and if so, sets the [filter] in the state to the new value
     * @return {{}}
     */
    const hasFiltersChanged = () => {
        let newFilters = {};
        const newKeyword = query[SingleOrderQueryNames.keyword];
        if (newKeyword?.length) {
            newFilters[SingleOrderQueryNames.keyword] = newKeyword;
        }
        if (!deepEqual(newFilters, filters)) {
            setFilters(newFilters);
            return newFilters;
        }
    }

    /**
     * Searches for parts within a purchase order:
     * - Calls the api regarding this action and sets the [parts] in the state
     */
    const searchParts = async () => {
        const forApi = {
            paginationInfo: paginationInfo,
            filters: filters,
            orderBy: orderBy
        }
        const response = await searchPartsApiCall(forApi);
        if (!isMounted())
            return;
        if (response?.resultFlag) {
            setParts(response?.data?.items);
            setPaginationInfo(prevState => ({...prevState, length: response?.data?.paginationInfo?.length}))
        }
        setLoading(false);
    }

    const searchPartsApiCall = async (forApi = null) => {
        return {
            resultFlag: true,
            data: {
                items: [
                    {
                        id: 1,
                        partNumber: "RADFR101A",
                        orderCount: 10,
                        committedCount: 14,
                        notScannedCount: 10,
                        isSerialized: true,
                        expanded: false,
                        weight: 12,
                        height: 12,
                        width: 12,
                        depth: 12,
                        description: "This is a sample description for checking how the description is being handled",
                        scannedSerials: [
                            {
                                id: 20,
                                serialNumber: '123-123-123',
                                row: 'R1',
                                section: 'S1',
                                aisle: 'A1',
                                warehouse: 'Warehouse1',
                                scannedOn: new Date(),
                                operator: "Testing User"
                            },
                            {
                                id: 21,
                                serialNumber: '123-123-123',
                                row: 'R1',
                                section: 'S1',
                                aisle: 'A1',
                                warehouse: 'Warehouse1',
                                scannedOn: new Date(),
                                operator: "Testing User"
                            },
                            {
                                id: 22,
                                serialNumber: '123-123-123',
                                row: 'R1',
                                section: 'S1',
                                aisle: 'A1',
                                warehouse: 'Warehouse1',
                                scannedOn: new Date(),
                                operator: "Testing User"
                            },
                            {
                                id: 23,
                                serialNumber: '123-123-123',
                                row: 'R1',
                                section: 'S1',
                                aisle: 'A1',
                                warehouse: 'Warehouse1',
                                scannedOn: new Date(),
                                operator: "Testing User"
                            },
                            {
                                id: 24,
                                serialNumber: '123-123-123',
                                row: 'R1',
                                section: 'S1',
                                aisle: 'A1',
                                warehouse: 'Warehouse1',
                                scannedOn: new Date(),
                                operator: "Testing User"
                            }
                        ],
                        suggestedSerials: [
                            {
                                id: 28,
                                serialNumber: '123-123-123',
                                row: 'R1',
                                section: 'S1',
                                aisle: 'A1',
                                warehouse: 'Warehouse1',
                            },
                            {
                                id: 29,
                                serialNumber: '123-123-123',
                                row: 'R1',
                                section: 'S1',
                                aisle: 'A1',
                                warehouse: 'Warehouse1',
                            }
                        ],
                    },
                    {
                        id: 2,
                        partNumber: "RADFR101A",
                        orderCount: 10,
                        committedCount: 14,
                        notScannedCount: 10,
                        isSerialized: false,
                        expanded: false,
                        weight: 12,
                        height: 12,
                        width: 12,
                        depth: 12,
                        description: "This is a sample description for checking how the description is being handled",
                    },
                    {
                        id: 3,
                        partNumber: "RADFR101A",
                        orderCount: 10,
                        committedCount: 14,
                        notScannedCount: 10,
                        isSerialized: true,
                        expanded: false,
                        weight: 12,
                        height: 12,
                        width: 12,
                        depth: 12,
                        description: "This is a sample description for checking how the description is being handled",
                        scannedSerials: [
                            {
                                id: 41,
                                serialNumber: '123-123-123',
                                row: 'R1',
                                section: 'S1',
                                aisle: 'A1',
                                warehouse: 'Warehouse1',
                                scannedOn: new Date(),
                                operator: "Testing User"
                            },
                            {
                                id: 42,
                                serialNumber: '123-123-123',
                                row: 'R1',
                                section: 'S1',
                                aisle: 'A1',
                                warehouse: 'Warehouse1',
                                scannedOn: new Date(),
                                operator: "Testing User"
                            },
                            {
                                id: 43,
                                serialNumber: '123-123-123',
                                row: 'R1',
                                section: 'S1',
                                aisle: 'A1',
                                warehouse: 'Warehouse1',
                                scannedOn: new Date(),
                                operator: "Testing User"
                            },
                            {
                                id: 44,
                                serialNumber: '123-123-123',
                                row: 'R1',
                                section: 'S1',
                                aisle: 'A1',
                                warehouse: 'Warehouse1',
                                scannedOn: new Date(),
                                operator: "Testing User"
                            },
                            {
                                id: 45,
                                serialNumber: '123-123-123',
                                row: 'R1',
                                section: 'S1',
                                aisle: 'A1',
                                warehouse: 'Warehouse1',
                                scannedOn: new Date(),
                                operator: "Testing User"
                            }
                        ],
                        suggestedSerials: [
                            {
                                id: 48,
                                serialNumber: '123-123-123',
                                row: 'R1',
                                section: 'S1',
                                aisle: 'A1',
                                warehouse: 'Warehouse1',
                            },
                            {
                                id: 49,
                                serialNumber: '123-123-123',
                                row: 'R1',
                                section: 'S1',
                                aisle: 'A1',
                                warehouse: 'Warehouse1',
                            }
                        ],
                    },
                    {
                        id: 4,
                        partNumber: "RADFR101A",
                        orderCount: 10,
                        committedCount: 14,
                        notScannedCount: 10,
                        isSerialized: false,
                        expanded: false,
                        weight: 12,
                        height: 12,
                        width: 12,
                        depth: 12,
                        description: "This is a sample description for checking how the description is being handled",
                    },
                ],
                paginationInfo: {
                    length: 4
                }
            }
        }
    }

    /**
     * Handles showing the barcode for the part number:
     * @param {string} partNumber
     */
    const onBarcodeClicked = (partNumber) => {
        dispatch(dialogActions.configBarcodeDialog({
            open: true,
            onClose: () => dispatch(dialogActions.barcodeDialogToggle(false)),
            barcodeCode: partNumber,
        }));
    }

    /**
     * Navigates user to the part single view:
     * @param {number} partId
     */
    const onPartNumberClicked = (partId) => {
        history.push(routeFunctions.panel.company.items.single.product(params.company, partId));
    }

    /**
     * Clears filters from url query params for triggering search without filters
     */
    const clearFilters = () => {
        history.push(stringifyUrl({
            url: location.pathname,
            query: {
                ...generatePaginationAndOrderByQueryResetter()
            }
        }));
    }

    /**
     * Navigates user back to sales order list view
     */
    const onNavigateBackClicked = () => {
        history.replace(routeFunctions.panel.company.purchaseOrders.list(params.company));
    }

    /**
     * Handles expanding the table row for showing inner table
     * @param part
     */
    const onExpandClicked = (part) => {
        if (!part.expanded) {
            setExpandedSectionsIds(prevState => [...prevState, part.id]);
        }
        setParts(
            prevState => prevState.map(item => {
                if (item.id === part?.id) {
                    return {
                        ...item,
                        expanded: !part.expanded
                    }
                }
                return item;
            })
        );
    }

    /**
     * @param {number} id the id of item in the table ro
     * sets the [isShowingExpandedSection] to false in the state
     */
    const hideExpandedSection = (id) => {
        setExpandedSectionsIds(prevState => prevState.filter(e => e !== id));
    }

    /**
     * Creates the data entries for the information of sales order table
     * @return {TableData[]}
     */
    const createInformationTableData = () => {
        return [{
            key: purchaseOrderFullInfo.id,
            [infoTableCellKeys.customerName]: purchaseOrderFullInfo.customerName,
            [infoTableCellKeys.orderDate]: purchaseOrderFullInfo.orderDate,
            [infoTableCellKeys.requiredDate]: purchaseOrderFullInfo.requiredDate,
            [infoTableCellKeys.totalProducts]: purchaseOrderFullInfo.totalProducts,
            [infoTableCellKeys.totalItems]: purchaseOrderFullInfo.totalItems,
        }];
    }

    /**
     * Creates the data entries for the instances of part in inner table
     * @param items
     * @return {TableData[]}
     */
    const createInnerTableData = (items) => {
        //TODO: Change the onClick functions for locations when DS is complete
        return items.map(serial => (
            {
                key: serial?.id,
                [innerTableCellKeys.serialNumber]: () => (
                    <div className={'d-flex flex-row align-items-center'}>
                        {
                            serial?.serialNumber?.length > 0 &&
                            <div
                                onClick={() => onBarcodeClicked(serial?.serialNumber)}
                                className={'barcode-icon-button'}
                            >
                                <BarcodeIcon/>
                            </div>
                        }
                        <p>
                            {serial?.serialNumber}
                        </p>
                    </div>
                ),
                [innerTableCellKeys.scannedOn]: serial.scannedOn,
                [innerTableCellKeys.operator]: serial.operator,
            }
        ));
    }

    /**
     * Creates the data entries for the information of sales order table
     * @return {TableData[]}
     */
    const createTableData = () => {
        return parts?.map(part => (
            {
                key: part?.id,
                [mainTableCellKeys.expand]: () => (
                    <div>
                        {part.isSerialized && <div
                            className={classnames('expand-chevron', {'expanded': part?.expanded})}
                            onClick={() => onExpandClicked(part)}
                        >
                            <ChevronIcon/>
                        </div>}
                    </div>
                ),
                [mainTableCellKeys.partNumber]: () => (
                    <div className={'d-flex flex-row align-items-center'}>
                        {
                            part?.partNumber?.length > 0 &&
                            <div
                                onClick={() => onBarcodeClicked(part?.partNumber)}
                                className={'barcode-icon-button'}
                            >
                                <BarcodeIcon/>
                            </div>
                        }
                        <p
                            className={'text-button'}
                            onClick={() => onPartNumberClicked(part.id)}
                        >
                            {part.partNumber}
                        </p>
                    </div>
                ),
                [mainTableCellKeys.orderCount]: () => (
                    <div className={'order-count'}>
                        <p>
                            {part.orderCount}
                        </p>
                    </div>
                ),
                [mainTableCellKeys.committedCount]: () => (
                    <div className={'committed-count'}>
                        <p>
                            {part.committedCount}
                        </p>
                    </div>
                ),
                [mainTableCellKeys.notScannedCount]: () => (
                    <div className={'not-scanned-count'}>
                        <p>
                            {part.notScannedCount}
                        </p>
                    </div>
                ),
                [mainTableCellKeys.isSerialized]: () => (
                    <div>
                        {part.isSerialized && <IsSerialized/>}
                        {!part.isSerialized && <NotSerialized/>}
                    </div>
                ),
                [mainTableCellKeys.weight]: part.weight ?? '',
                [mainTableCellKeys.height]: part.height ?? '',
                [mainTableCellKeys.width]: part.width ?? '',
                [mainTableCellKeys.depth]: part.depth ?? '',
                [mainTableCellKeys.description]: part.description ?? '',
                expandedSection: !part.isSerialized ? () => {
                } : () => (
                    //Make sure collapse is imported from react bootstrap
                    <>
                        <tr className={classnames({'visually-hidden': !expandedSectionsIds.includes(part?.id)})}>
                            <td colSpan={(expandedSectionsIds.length > 0) ? 12 : 0}>
                                <Collapse in={part?.expanded} onExited={() => hideExpandedSection(part.id)}>
                                    <div className={'inner-item-container scanned-instances-purchase-order'}>
                                        {
                                            part?.scannedSerials?.length > 0
                                                ? <Table
                                                    className={'my-3 w-auto'}
                                                    color={'secondary'}
                                                    cells={innerTableCells}
                                                    data={createInnerTableData(part?.scannedSerials)}
                                                />
                                                : <NoEntries
                                                    text={errorMessage}
                                                    includeButton={false}
                                                />
                                        }
                                    </div>
                                </Collapse>
                            </td>
                        </tr>
                        {
                            part?.expanded &&
                            <TableSpacer length={mainTableCells.length}/>
                        }
                    </>
                ),
            }
        ));

    }


    return (
        <>
            <div className={'single-sales-order'}>
                <div className={'panel-card p-3'}>
                    <div className={'d-flex align-items-start justify-content-start'}>
                        <div className={'back-icon-button'} onClick={onNavigateBackClicked}>
                            <BackIconButton/>
                        </div>
                        <div>
                            <p className={'title'}>
                                {purchaseOrderFullInfo?.orderNumber}
                            </p>
                            <p className={'sub-title'}>
                                {translation?.purchaseOrderInformation ?? ''}
                            </p>
                        </div>
                    </div>
                    <div className={'sales-order-info mb-5'}>
                        <Row>
                            <Col xs={12} xl={9}>
                                <div>
                                    <Table
                                        loading={{state: loading, count: 2}}
                                        className={'my-3'}
                                        color={'primary'}
                                        cells={infoTableCells}
                                        data={createInformationTableData()}
                                    />
                                </div>
                            </Col>
                            <Col xs={12} xl={3}>
                                <div className={'barcode-section d-flex  m-auto'}>
                                    <Barcode
                                        value={"RADFR101A"}
                                        text={"Scan by phone app"}
                                        font={"Montserrat"}
                                        fontSize={15}
                                        lineColor={primaryTextColor}
                                        width={2}
                                        height={150}
                                        background={lighterPrimaryColor}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <p className={'parts-title'}>
                            {translation?.parts}
                        </p>
                    </div>
                    <SalesOrderPartsSearchSection filters={filters}/>
                    <div>
                        {
                            parts?.length < 1 && !loading
                                ? <NoEntries
                                    text={
                                        Object.entries(filters ?? {})?.length > 0
                                            ? translation?.empty?.withFilters
                                            : translation?.empty?.text
                                    }
                                    includeButton={Object.entries(filters ?? {})?.length > 0}
                                    buttonProps={{
                                        onClick: clearFilters,
                                        className: 'button primary px-3'
                                    }}/>
                                : <Table
                                    loading={{state: loading, count: 8}}
                                    className={'my-3'}
                                    color={'primary'}
                                    cells={mainTableCells}
                                    data={createTableData()}
                                    paginationInfo={paginationInfo}
                                    orderBy={orderBy}
                                    onSort={(o) => onTableSort(o, query, history, location)}
                                    onPageSizeChange={(p, i) => onTablePageSizeChange(p, i, query, history, location)}
                                    onCurrentPageChange={(p, i) => onTableCurrentPageChange(p, i, query, history, location)}
                                />
                            
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default PurchaseOrderSingleView;
