import React from "react";
import useRouter from "../../../hooks/use-router";
import {useTranslation} from "react-i18next";
import {SettingsTabValues} from "../../../../core/constants/tab-values";
import {SettingsQueryNames} from "../../../../core/constants/query-names";
import TabsLayout from "../../../components/app-specific/tabs-layout";
import CompanySettingsView from "./company";
import GeneralSettingsView from "./general";
import SpireSettingsView from "./spire";

const SettingsView = () => {
    const {query, location, history, stringifyUrl} = useRouter()
    const {t} = useTranslation()
    const translations = t("views.panel.settings", {returnObjects: true});
    const tabs = [
        {
            label: translations?.tabs?.general,
            value: SettingsTabValues.general
        },
        {
            label: translations?.tabs?.spire,
            value: SettingsTabValues.spire
        },
        {
            label: translations?.tabs?.company,
            value: SettingsTabValues.company
        },
    ]

    /**
     * Determines the selected tab and updates the url of the view.
     * @param {string} tabValue
     */
    const onTabChanged = (tabValue) => {
        const result = {
            [SettingsQueryNames.type]: tabValue,
        }
        history.push(stringifyUrl({
            url: location.pathname,
            query: {...result}
        }))
    }


    return (
        <>
            <div className={'settings'}>
                <div className={'panel-card p-0 pt-4'}>
                    <TabsLayout
                        tabs={tabs}
                        swipeable
                        onChange={onTabChanged}
                        initialValue={query
                            ? query[SettingsQueryNames.type]
                            : undefined}
                    >
                        <GeneralSettingsView/>
                        <SpireSettingsView/>
                        <CompanySettingsView/>
                    </TabsLayout>
                </div>
            </div>
        </>
    )
}

export default SettingsView;
