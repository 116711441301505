import {configureStore as CStore} from '@reduxjs/toolkit';
import reducer from "./entities/reducer";
import dialogSlice from "./entities/dialog-slice";

/**
 * The singleton instance of the redux store.
 */
let store;

/**
 * Configures the redux store and makes it ready for injection in react.
 */
const configureStore = () => {
    store = CStore({
        reducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    dialogSlice.actions.configConfirmationDialog.type,
                    dialogSlice.actions.confirmationDialogToggle.type,
                    dialogSlice.actions.configBarcodeDialog.type,
                    dialogSlice.actions.barcodeDialogToggle.type,
                ],
                ignoredPaths: ['dialog']
            }
        }).concat([
            // extra middlewares for redux
        ]),
    });
}

if (!store) {
    configureStore();
}
export default store;
