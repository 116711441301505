import React, {useContext, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {PositionCountingTabValues} from "../../../../../../../core/constants/tab-values";
import useRouter from "../../../../../../hooks/use-router";
import {PositionCountingSingleQueryNames, SpireCountingSingleQueryNames} from "../../../../../../../core/constants/query-names";
import TabsLayout from "../../../../../../components/app-specific/tabs-layout";
import PositionCountingInformation from "./information";
import PositionCountingReports from "./reports";
import PositionCountingItems from "./items";
import {ReactComponent as BackIconButton} from "../../../../../../../assets/images/back-icon.svg";
import {routeFunctions} from "../../../../../../routes";
import {Select} from "../../../../../../components/base/select/select";
import {Fade, Row} from "react-bootstrap";
import RecountRequestDialog from "../../../../../../components/dialogs/counting/position/recount-request";
import {PositionCountingItemsQueryParams} from "../../../../../../../core/constants/query-params";
import {CompanyContext} from "../../../../../../contexts/company";

// Samples statuses being shown to be selected
const statuses = [
    {
        title: "Status 1",
        id: '1',
    },
    {
        title: "Status 2",
        id: '2'
    }
];

const locations = [
    {
        id: 1,
        title: 'location 1',
    },
    {
        id: 2,
        title: 'location 2',
    },
    {
        id: 3,
        title: 'location 3',
    },
    {
        id: 4,
        title: 'location 4',
    },
    {
        id: 5,
        title: 'location 5',
    },
    {
        id: 6,
        title: 'location 6',
    },
    {
        id: 7,
        title: 'location 7',
    },
    {
        id: 8,
        title: 'location 8',
    }, {
        id: 9,
        title: 'location 8',
    }, {
        id: 10,
        title: 'location 8',
    }, {
        id: 11,
        title: 'location 8',
    }, {
        id: 12,
        title: 'location 8',
    }, {
        id: 13,
        title: 'location 8',
    },
];

const PositionCountingSingleView = () => {
    const {query, stringifyUrl, history, location, params} = useRouter();
    const [positionCountingFullDetails, setPositionCountingFullDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isShowingRecount, setIsShowingRecount] = useState(true);
    const [isRequestingRecount, setIsRequestingRecount] = useState(false);
    const {t} = useTranslation();
    const translation = t('views.panel.company.counting.position.single', {returnObjects: true});
    const {type} = query;

    const [company] = useContext(CompanyContext);
    const selectedCompany = useRef(company);
    /**
     * With each change in the selected company:
     * - navigates user to the list of position countings
     */
    useEffect(() => {
        if (selectedCompany.current?.id === company?.id)
            return;
        selectedCompany.current = company;
        history.replace(routeFunctions.panel.company.counting.position(company.name));
    }, [company?.id])

    /**
     * Memo version of tabs of single position counting
     * @type {object[]}
     */
    const tabs = useMemo(() => [
        {
            label: translation?.tabTitles?.items,
            value: PositionCountingTabValues.items,
        },
        {
            label: translation?.tabTitles?.reports,
            value: PositionCountingTabValues.reports,
        },
        {
            label: translation?.tabTitles?.information,
            value: PositionCountingTabValues.information,
        },
    ], [])

    //TODO: remove after api call was added
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    /**
     * With each change in the url params:
     * - Calls the api for getting the full information of the position counting
     */
    useLayoutEffect(() => {
        delay(2000).then();
        //TODO: Calls the api for getting the counting request full information
        setPositionCountingFullDetails({
            id: 1,
            title: "Count Shelf",
            submitterAdmin: "Testing Testian",
            submitDate: new Date(),
            expiryDate: new Date(),
            totalCount: 100,
            scannedCount: 56,
            items: [],
            reports: {
                reportTypes: [
                    {
                        id: 1,
                        title: "Test 1",

                    },
                    {
                        id: 2,
                        title: "Test 2"
                    },
                    {
                        id: 3,
                        title: "Test 3"
                    }
                ],
                reportList: [
                    {
                        id: 1,
                        typeId: 1,
                        title: "report 1",

                    },
                    {
                        id: 2,
                        typeId: 1,
                        title: "report 2",
                    },
                    {
                        id: 3,
                        typeId: 2,
                        title: "report 3",
                    },
                    {
                        id: 4,
                        typeId: 2,
                        title: "report 4",
                    },
                    {
                        id: 5,
                        typeId: 3,
                        title: "report 5",

                    },
                    {
                        id: 6,
                        typeId: 3,
                        title: "report 6",

                    },
                ],
            },
            logs: [
                {
                    id: 1,
                    partNo: 'RADFR101A',
                    partId: 1,
                    serialNo: '12312-12123-12123',
                    count: 12,
                    dateTime: new Date(),
                    operator: "Sepehr Saebnia",
                    locationCode: "A12"
                },
                {
                    id: 2,
                    partNo: 'CACH101A',
                    partId: 2,
                    serialNo: '12312-12123-12123',
                    count: 1,
                    dateTime: new Date(),
                    operator: "Saul Saebnia",
                    locationCode: "A12"
                }
            ]
        })
        setLoading(false);

    }, [params])

    /**
     * With each change in the selected tab:
     * - Checks if status and recount request button should be visible or not
     */
    useEffect(() => {
        if (type === PositionCountingTabValues.items || type === undefined) {
            setIsShowingRecount(true);
            return;
        }
        setIsShowingRecount(false);
    }, [type]);

    /**
     * Navigates user to the previous route which is list of position countings
     */
    const onNavigateBackClicked = () => {
        //TODO: needs to be double checked
        history.replace(routeFunctions.panel.company.counting.position(params.company));
    }

    //TODO: implement getting the initial value for the status drop down box

    /**
     * Handles changes in the status select box:
     * - Sets the [selectedStatus] in the state to the received value
     * @param event
     */
    const onStatusChanged = (event) => {
        history.replace(stringifyUrl({
            url: location.pathname,
            query: {
                ...query,
                [PositionCountingItemsQueryParams.status]: event.target.value.title,
            }
        }));
    }

    /**
     * Handles clicking on the recount button:
     * - Opens a dialog regarding this action by setting the [isRequestingRecount] to [true] in the state
     */
    const onRecountClicked = () => {
        setIsRequestingRecount(true);
    }

    /**
     * Handles requesting for recount
     * @param formValue
     * @param selectedLocation
     */
    const onRecountRequested = (formValue, selectedLocation) => {
        console.log(formValue, selectedLocation);
        setIsRequestingRecount(false);
    }

    /**
     * Determines the selected tab and updates the url of the view
     * @param {string} tabValue
     */
    const onTabChanged = (tabValue) => {
        const result = {
            [PositionCountingSingleQueryNames.type]: tabValue,
        }
        history.push(stringifyUrl({
            url: location.pathname,
            query: {...result}
        }));
    }


    return (
        <>
            <div className={"spire-counting-single-view"}>
                <div className={'panel-card p-0 pt-4'}>
                    <div className={'px-4 d-flex align-items-center justify-content-between'}>
                        <div className={'back-icon-button'} onClick={onNavigateBackClicked}>
                            <BackIconButton/>
                        </div>
                        <Fade
                            in={isShowingRecount}
                            children={
                                <Row className={'position-counting-status-recount'}>
                                    {/*TODO: add the place holder for the drop box*/}
                                    <Select
                                        fullWidth={true}
                                        placeholder={"Status"}
                                        name={'search-status'}
                                        label={"select status"}
                                        value={
                                            !!query[PositionCountingItemsQueryParams.status]
                                                ? statuses.find(e => e.title === query[PositionCountingItemsQueryParams.status])
                                                : undefined
                                        }
                                        onChange={onStatusChanged}
                                        data={statuses.map(e => ({
                                            id: e.id,
                                            value: e
                                        }))}
                                    />
                                    <div style={{'width': "fit-content"}}>
                                        <button
                                            className={'button primary outlined'}
                                            onClick={onRecountClicked}
                                        >
                                            {translation?.requestRecount ?? ''}
                                        </button>
                                    </div>
                                </Row>
                            }
                        />
                    </div>
                    <TabsLayout
                        tabs={tabs}
                        swipeable
                        onChange={onTabChanged}
                        initialValue={query ? query[SpireCountingSingleQueryNames.type] : undefined}
                    >
                        <PositionCountingItems statuses={statuses}/>
                        <PositionCountingReports data={positionCountingFullDetails} loading={loading}/>
                        <PositionCountingInformation data={positionCountingFullDetails} loading={loading}/>
                    </TabsLayout>
                </div>
            </div>
            <RecountRequestDialog
                onSubmit={onRecountRequested}
                open={isRequestingRecount}
                setOpen={setIsRequestingRecount}
                locations={locations}
            />
        </>
    );
}

export default PositionCountingSingleView;
