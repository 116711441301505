import {useCallback, useMemo, useRef, useState} from "react";
import Konva from "konva";
import {InventorySchemaVisualizerModes} from "../../../core/models/constants/enums";
import Utils from "../../../core/services/utils";
import {IInventorySchemaVisualizerVisualConfig, IRemoveRectFunc, IRotateRectFunc} from "../../types";


export type IUseWarehouseRectProps = {
    allowInteractions: boolean,
    mode: InventorySchemaVisualizerModes,
    data: IInventorySchemaVisualizerVisualConfig['selectedEntityData'],
    selectedRectData?: IInventorySchemaVisualizerVisualConfig['selectedEntityData'],
    setSelectedRectData: Function,
    remove: IRemoveRectFunc,
    rotate: IRotateRectFunc,
}

/**
 * A wrapper hook for managing a canvas rect for a warehouse entity.
 */
const useWarehouseRect = ({
                              allowInteractions,
                              mode,
                              data,
                              setSelectedRectData,
                              selectedRectData,
                              remove,
                              rotate
                          }: IUseWarehouseRectProps) => {
    const [rect, setRect] = useState<Konva.Rect>();
    const groupRef = useRef<Konva.Group | null>();

    const draggable = useMemo(() => mode === InventorySchemaVisualizerModes.edit && allowInteractions, [allowInteractions, mode]);
    const selected = useMemo(() => mode === InventorySchemaVisualizerModes.edit && Utils.deepEqual(data, selectedRectData) && allowInteractions, [allowInteractions, data, mode, selectedRectData]);

    /**
     * Selects the entity by saving the canvas [rect]'s data attribute.
     */
    const selectEntity = useCallback<(e: Konva.KonvaEventObject<MouseEvent>) => void>((e) => {
        if (mode !== InventorySchemaVisualizerModes.edit || !allowInteractions)
            return;
        setSelectedRectData(e.target.getAttr('data'));
    }, [allowInteractions, mode, setSelectedRectData])

    /**
     * Removes the entity by calling the provided remove callback with the data as its arg.
     */
    const removeEntity = useCallback<() => void>(() => {
        remove(data as any);
    }, [data, remove]);

    /**
     * Rotates the entity by calling the provided rotate callback with the data as its arg.
     */
    const rotateEntity = useCallback(() => {
        rotate(data as any);
        if (!rect)
            return;
        // just to trigger the changeWidth listener of the text.
        rect.setAttrs({width: rect.width() + 1});
        rect.setAttrs({width: rect.width() - 1});
    }, [data, rotate, rect])

    const opacity = useMemo(() =>
        mode === InventorySchemaVisualizerModes.edit
            ? allowInteractions
                ? selected ? 0.3 : 0.8
                : 0.5
            : 0.8, [allowInteractions, mode, selected])

    const removable = useMemo(() => mode === InventorySchemaVisualizerModes.edit, [mode]);

    const rotatable = useMemo(() => mode === InventorySchemaVisualizerModes.edit && data?.length === 2, [data?.length, mode]);

    return {
        draggable,
        selected,
        selectEntity,
        rect,
        setRect,
        groupRef,
        opacity,
        removeEntity,
        removable,
        rotatable,
        rotateEntity
    };
}

export default useWarehouseRect;
