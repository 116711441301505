import React from "react";
import {Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import FeaturesSection from "./features";
import FormSection from "./form";


const RegistrationViewUserInfoSection = () => {
    const {t} = useTranslation();
    const translations = t('views.public.registration.userInfo.features', {returnObjects: true});

    return (
        <>
            <Row className={'user-info'}>
                {/*Admin Dashboard Features*/}
                <Col xs={12} md={5} className={'order-1 order-md-2 mb-0 mb-sm-3'}>
                    <FeaturesSection
                        title={translations.title}
                        body={translations.body}
                    />
                </Col>
                {/*User Info Form*/}
                <Col xs={12} md={7} className={'order-2 order-md-1 flex-row-center mb-5'}>
                    <FormSection/>
                </Col>
            </Row>

        </>
    )
}


export default RegistrationViewUserInfoSection;
