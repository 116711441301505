import React, {useRef, useState} from "react";
import * as Yup from "yup";
import {makeRequired, makeValidate} from "mui-rff";
import {DialogActions, DialogContent, Divider} from "@material-ui/core";
import {Col, Row} from "react-bootstrap";
import Form from "../../../base/form";
import {FormInput} from "../../../base/input";
import useIsMounted from "../../../../hooks/use-is-mounted";
import {screenshotUserBrowserWindow} from "../../../../../core/services/utils";


const schema = Yup.object().shape({
    description: Yup.string().nullable(),
    takeScreenshot: Yup.boolean().nullable().default(false),
});


const validate = makeValidate(schema);
const required = makeRequired(schema);


const SubmitTicketSection = ({data, onSuccess}) => {
    const [submitting, setSubmitting] = useState(false);
    const isMounted = useIsMounted();
    /**@type {React.MutableRefObject<formApi>}*/
    const formRef = useRef();

    /**
     * Submits a ticket for the error with the given values.
     * @param values
     * @return {Promise<void>}
     */
    const submitTicket = async (values) => {
        const forApi = {
            curl: data.curl,
            apiUrl: data.apiRequest?.url,
            resultCode: data.apiResponse?.code ?? data.apiResponse?.errorCode,
            resultMessage: data.apiResponse?.message,
            url: data.url,
            description: values.description,
            viewSnapshot: await takeScreenshot(values.takeScreenshot),
            screenshot: data.capturedScreen,
        }
        setSubmitting(true);
        const response = {};
        // TODO: Call the api for submitting a ticket
        // const response = await Api.submitErrorHandlingTicket(forApi);
        if (!isMounted()) return;
        setSubmitting(false);
        if (response?.resultFlag) {
            onSuccess({
                link: response.data.link,
                code: response.data.code,
                message: response.message,
            });
        }
    }

    /**
     * Removes the current dialog from the viewport then takes its screenshot and then brings back the dialog.
     * @param takeScreenshot
     * @return {Promise<string>}
     */
    const takeScreenshot = async (takeScreenshot) => {
        const dialogs = document.getElementsByClassName('submit-ticket-dialog-root');
        for (const dialog of dialogs) {
            dialog.classList.add('d-none');
        }
        const res = await screenshotUserBrowserWindow(takeScreenshot)
        for (const dialog of dialogs) {
            dialog.classList.remove('d-none');
        }
        return res;
    }

    /**
     * Sets the value for explicitly allowing for screenshots and then submits the form.
     * @param withScreenshot
     */
    const submitForm = (withScreenshot = false) => {
        formRef.current.change('takeScreenshot', withScreenshot);
        formRef.current.submit();
    }

    //TODO: Add translation for the submit ticket process

    return (
        <>
            <DialogContent className={'px-md-4 '}>
                <Col xs={12}>
                    <p className={'text-sm mt-4 mb-2'}>
                        We are deeply sorry for your inconvenience. We would encourage you to submit a ticket in case
                        of unexpected results. Thanks for your patience.
                    </p>
                </Col>
                <Form
                    className='d-flex flex-wrap w-100 align-items-center'
                    onSubmit={submitTicket}
                    validate={validate}
                    initialValues={{}}
                    render={({values, form}) => {
                        formRef.current = form;
                        return (
                            <>
                                <Col xs={12} className={'my-3'}>
                                    <FormInput
                                        fullWidth
                                        name={'description'}
                                        placeholder={'Context in which the error place, the aim of the interaction,etc...'}
                                        label={"Description (Optional)"}
                                        multiline
                                        rows={12}
                                        required={required.description}
                                        value={values.description ?? ''}
                                        onChange={(e) => form.change(e.target.name, e.target.value)}
                                    />
                                </Col>
                                {/*TODO: double check if needed in the form*/}
                                {/*<CheckBoxForm*/}
                                {/*    className={'d-none'}*/}
                                {/*    name={'takeScreenshot'}*/}
                                {/*    required={required.takeScreenshot}*/}
                                {/*    data={[{value: values.takeScreenshot ?? false}]}*/}
                                {/*/>*/}
                            </>
                        )
                    }}
                />
            </DialogContent>
            <DialogActions className={'p-0'}>
                <Row className={'w-100 no-gutters'}>
                    <Divider className='divider'/>
                    <Row className='no-gutters justify-content-between align-items-start w-100'>
                        <button
                            className='button primary outlined px-4'
                            type='button'
                            onClick={() => submitForm(false)}
                            disabled={submitting}
                        >
                            {
                                submitting
                                    ? "Submitting..."
                                    : "Submit Ticket"
                            }
                        </button>
                        <button
                            className='button primary px-5'
                            type='submit'
                            onClick={() => submitForm(true)}
                        >
                            {
                                submitting
                                    ? "Submitting..."
                                    : "Submit Ticket with screenshot"
                            }
                        </button>
                    </Row>
                </Row>
            </DialogActions>
        </>
    );
}

export default SubmitTicketSection;
