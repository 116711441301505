import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {dialogActions} from "../../../../redux/entities/dialog-slice";
import Dialog from "../../base/dialog";
import {DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {ReactComponent as PrintIcon} from "../../../../assets/images/print.svg";
import {primaryTextColor} from "../../../../assets/mui/colors";
import Barcode from "react-barcode";
import JsBarcode from 'jsbarcode';



const BarcodeDialog = () => {

    const state = useSelector(state => state?.dialog?.barcode ?? {});
    const dispatch = useDispatch();


    /**
     * Handles click on the print icon button:
     * Downloads the barcode file to the users system
     */
    const onPrintClicked = () => {
        const canvas = document.createElement("canvas");
        JsBarcode(canvas, state.barcodeCode, {format: "CODE39"});
        const link = document.createElement('a');
        link.href = canvas.toDataURL("image/png");
        link.download = `${state.barcodeCode}_barcode.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    /**
     * Call back function being passed to the dialog component for handling showing/closing dialog
     *
     * @param {boolean} toggleOpen
     */
    const setOpen = (toggleOpen) => dispatch(dialogActions.barcodeDialogToggle(toggleOpen));

    return (
        <>
            <Dialog
                max={'md'}
                fullWidth
                open={state.open}
                setOpen={setOpen}
                {...state.props}
            >
                <DialogTitle className={'title'} id={'scroll-dialog-title'}>
                    <div className={'d-flex flex-row justify-content-between align-items-center'}>
                        <h2>
                            {state?.title ?? ''}
                        </h2>
                        <div onClick={onPrintClicked} className={'barcode-print-icon'}>
                            <PrintIcon/>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className={'w-100 d-flex flex-column justify-content-center align-items-center barcode-svg'}>
                        <Barcode
                            value={state.barcodeCode ?? ''}
                            text={state?.barcodeCode ?? ''}
                            font={"Montserrat"}
                            fontSize={15}
                            lineColor={primaryTextColor}
                            width={2}
                            height={150}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <button
                        className='button primary outlined px-4'
                        type='button'
                        onClick={state.onClose}
                    >
                        {state?.closeButtonLabel ?? ''}
                    </button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default BarcodeDialog;


