import React, {useMemo} from 'react';
import * as Yup from 'yup';
import Dialog from "../../base/dialog";
import {useTranslation} from "react-i18next";
import {DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import Form from "../../base/form";
import {FormInput} from "../../base/input";
import {LocationTypes, LocationUpsertTypes} from "../../../../core/constants/enums";
import ValidateMessages from "../../../../core/constants/texts/vallidate-messages";
import {makeRequired, makeValidate} from "mui-rff";
import {deepEqual} from "../../../../core/services/utils";

/**
 * Determines the location type word of the dialog based on the given type.
 * @param {LocationTypes} locationType
 * @param {function} t
 * @return {string}
 */
const determineTranslationLocationType = (locationType, t) => {
    const translations = t("dialogs.upsertLocationDialog.locationTypes", {returnObjects: true});
    switch (locationType) {
        case LocationTypes.aisle:
            return translations?.aisle ?? '';
        case LocationTypes.section:
            return translations?.section ?? '';
        case LocationTypes.row:
            return translations?.row ?? '';
        default:
            return '';
    }
}

const formNames = {
    code: 'code',
    title: 'title',
    capacity: 'capacity',
}


const UpsertLocationDialog = ({
                                  open,
                                  setOpen,
                                  locationType,
                                  values = undefined,
                                  callback: submitResults
                              }) => {
    const {t} = useTranslation();
    const translations = t('dialogs.upsertLocationDialog', {
        returnObjects: true,
        code: values?.code,
        locationType: determineTranslationLocationType(locationType, t)
    });
    const validationTranslation = t('utils.formValidation', {returnObjects: true});

    const initialValues = useMemo(() => ({
        code: values?.code,
        title: values?.title,
        capacity: values?.capacity,
    }), [values?.code, values?.title, values?.capacity]);

    const schema = useMemo(() => {
        const obj = {
            [formNames.code]: Yup.string().nullable().required(ValidateMessages.required),
        };
        switch (locationType) {
            case LocationTypes.row:
                obj[formNames.capacity] = Yup.number()
                    .typeError(validationTranslation?.incorrectType.concat(validationTranslation?.types.number))
                    .nullable().min(0).required(ValidateMessages.required);
                break;
            case LocationTypes.warehouse:
                obj[formNames.title] = Yup.string().nullable().required(ValidateMessages.required);
                break;
            default:
                break;
        }
        return Yup.object().shape(obj);
    }, [locationType, validationTranslation?.incorrectType, validationTranslation?.types.number]);

    const validate = makeValidate(schema)
    const required = makeRequired(schema)

    const operationType = useMemo(() => !!values ? LocationUpsertTypes.edit : LocationUpsertTypes.add, [values])

    /**
     * Submits the validated code of the location by invoking the submitResults callback.
     * closes the dialog as well.
     *
     * @param {any} values the validated code.
     */
    const submitCode = async (values) => {
        const errors = await submitResults(values);
        if (!errors) {
            setOpen(false);
            return;
        }
        // TODO: based on the given error, highlight a field or do something.
        return {
            [formNames.code]: errors.code,
            [formNames.title]: errors.title,
            [formNames.capacity]: errors.capacity,
        }
    }

    return (
        <>
            <Dialog
                maxWidth={'sm'}
                setOpen={setOpen}
                open={open}
                fullWidth
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle className={'title'} id='scroll-dialog-title'>
                    {operationType === LocationUpsertTypes.add ? translations?.add?.title : translations?.edit?.title}
                </DialogTitle>
                <Form
                    initialValues={initialValues}
                    validate={validate}
                    onSubmit={submitCode}
                    render={({values, initialValues}) => {
                        const submitDisabled = deepEqual(values?.[formNames.code], initialValues?.[formNames.code])
                            && deepEqual(values?.[formNames.title], initialValues?.[formNames.title])
                            && deepEqual(values?.[formNames.capacity], initialValues?.[formNames.capacity]);

                        return (
                            <>
                                <DialogContent id="scroll-dialog-description">
                                    <div className={'d-flex flex-column'}>
                                        {
                                            locationType === LocationTypes.warehouse &&
                                            <div className={'w-75 mb-4'}>
                                                <FormInput
                                                    required={required[formNames.title]}
                                                    placeholder={translations?.form?.title?.placeholder}
                                                    label={translations?.form?.title?.title}
                                                    name={formNames.title}
                                                />
                                            </div>
                                        }
                                        <div className={'w-75 mb-4'}>
                                            <FormInput
                                                required={required[formNames.code]}
                                                placeholder={translations?.form?.code?.placeholder}
                                                label={translations?.form?.code?.title}
                                                name={formNames.code}
                                            />
                                        </div>
                                        {
                                            locationType === LocationTypes.row &&
                                            <div className={'w-50 mb-4'}>
                                                <FormInput
                                                    required={required[formNames.capacity]}
                                                    placeholder={translations?.form?.capacity?.placeholder}
                                                    label={translations?.form?.capacity?.title}
                                                    name={formNames.capacity}
                                                    type={'number'}
                                                    inputProps={{
                                                        min: 0,
                                                    }}
                                                />
                                            </div>
                                        }
                                    </div>
                                </DialogContent>
                                <DialogActions>
                                    <button
                                        disabled={submitDisabled}
                                        className='button primary px-5'
                                        type={'submit'}>
                                        {
                                            operationType === LocationUpsertTypes.add
                                                ? translations?.add?.action
                                                : translations?.edit?.action
                                        }
                                    </button>
                                </DialogActions>
                            </>
                        )
                    }}
                />
            </Dialog>
        </>
    )
};

export default UpsertLocationDialog;
