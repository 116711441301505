import React, {useEffect} from "react";
import useRouter from "../../../../hooks/use-router";
import {matchPath, Route, Switch} from "react-router";
import routes, {routeFunctions} from "../../../../routes";
import PurchaseOrderListView from "./list";
import PurchaseOrderSingleView from "./single";

const PurchaseOrderView = () => {
    const {location, history, params} = useRouter();


    useEffect(() => {
        if (!!matchPath(location.pathname, {path: routes.panel.company.purchaseOrders.base, exact: true})) {
            history.replace(routeFunctions.panel.company.purchaseOrders.list(params.company));
        }
    }, [location.pathname]);

    return (
        <>
            <Switch>
                <Route path={routes.panel.company.purchaseOrders.list.purchaseOrder}>
                    <PurchaseOrderListView/>
                </Route>
                <Route path={routes.panel.company.purchaseOrders.single.purchaseOrder}>
                    <PurchaseOrderSingleView/>
                </Route>
            </Switch>
        </>
    );
}

export default PurchaseOrderView;
