import React, {useContext, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import useRouter from '../../../../hooks/use-router';
import {matchPath, Route, Switch} from "react-router";
import {routeFunctions, routes} from "../../../../routes";
import WarehousesViews from "./warehouses";
import AislesViews from "./aisles";
import SectionsViews from "./sections";
import RowsViews from "./rows";
import {LocationFormDataContext} from "../../../../contexts/location-form-data";
import GenerateLocationReportDialog from "../../../../components/dialogs/generate-location-report";
import UpsertLocationDialog from "../../../../components/dialogs/upsert-location";
import {ReactComponent as CompanyIcon} from "../../../../../assets/images/views/location/company.svg";
import classnames from "classnames";
import {CompanyContext} from "../../../../contexts/company";
import RowItemsView from "./row-items";
import {useTranslation} from "react-i18next";

const LocationViews = () => {
    const {location, history} = useRouter();
    const [data, setData] = useState({});
    const [company] = useContext(CompanyContext);
    const selectedCompany = useRef(company);
    const {t} = useTranslation();
    const translations = t('views.panel.company.location', {returnObjects: true});
    const [generateReport, setGenerateReport] = useState({open: false, callback: undefined, locationAddress: null});
    const [upsertLocation, setUpsertLocation] = useState({
        open: false,
        callback: undefined,
        locationType: null,
        values: undefined
    });

    const params = useMemo(() => {
        const _routes = [
            routes.panel.company.location.warehouses,
            routes.panel.company.location.aisles,
            routes.panel.company.location.sections,
            routes.panel.company.location.rows,
            routes.panel.company.location.rowItems
        ]
        let match;
        let index = 0;
        while (!match) {
            match = matchPath(location.pathname, {
                path: _routes[index++],
                exact: true
            })
        }
        return match?.params ?? {};
    }, [location.pathname]);


    /**
     * As soon as the component mounts creates the following methods to be used in location views:
     * Adds the locationReport dialog toggle functionality
     * Adds the upsertLocation dialog toggle functionality
     */
    useLayoutEffect(() => {
        window.bizpire = {
            ...window.bizpire,
            generateLocationReportDialog: generateLocationReportDialog,
            upsertLocationDialog: upsertLocationDialog,
            makeLocationBreadcrumbs: (location) => injectBreadcrumbsColors(makeLocationBreadcrumbs(location)),
        };
        return () => {
            delete window.bizpire?.generateLocationReportDialog;
            delete window.bizpire?.upsertLocationDialog;
            delete window.bizpire?.makeLocationBreadcrumbs;
        }
    }, [])


    /**
     * As soon as the component mounts:
     * gets the location form data that is to be used for report generation.
     */
    useEffect(() => {
        getLocationFormData();
    }, [])


    /**
     * Listens for the changes in the location's pathname and with each change, checks if the pathname is
     * location.base route, and if that's the case, redirects the user to the warehouses route.
     */
    useEffect(() => {
        if (matchPath(location.pathname, {path: routes.panel.company.location.base, exact: true})) {
            history.replace(routeFunctions.panel.company.location.base(params.company));
        }
    }, [location?.pathname])


    /**
     * Listens to the changes in the selected company and with each change:
     * redirects the user back to the warehouses views if they were in any other pages.
     */
    useEffect(() => {
        if (selectedCompany.current?.id === company?.id) return;
        selectedCompany.current = company;
        history.replace(routeFunctions.panel.company.location.warehouses(company.name))
    }, [company?.id])

    /**
     * Fetches the form data needed for the report generation at different location hierarchies.
     */
    const getLocationFormData = () => {
        //    TODO: call api for getting report generation form data (types of products of and statues)
        setData({
            types: [
                {
                    id: 1,
                    title: "Serialized products",
                },
                {
                    id: 2,
                    title: "UnSerialized products",
                },
                {
                    id: 3,
                    title: "Both Types",
                },
            ],
            statuses: [
                {
                    id: 1,
                    title: "unavailable products",
                },
                {
                    id: 2,
                    title: "Available products",
                },
                {
                    id: 3,
                    title: "All products",
                },
            ],

        })
    }

    /**
     * Toggles the location generate report dialog of the application.
     * @param {boolean}             open            whether to open or close the modal
     * @param {any}                 locationAddress the current location information. used for breadcrumb creation.
     * @param {function(): void}    callback        a callback with the confirmed filter values.
     */
    const generateLocationReportDialog = (open = false, locationAddress = null, callback = undefined) => {
        if (open) {
            setGenerateReport({open, callback, locationAddress: locationAddress})
        } else {
            setGenerateReport({open, callback: undefined, locationAddress: null})
        }
    }


    /**
     * Toggles the location upsert dialog of the application.
     * @param {boolean}             open            whether to open or close the dialog
     * @param {string | null}       locationType    the location type used to determine which hierarchy we are in
     * @param {Record<string, any> | null}       values            the code of the current location in case of updating it.
     * @param {function}   callback        a callback with the new code. expects return value of errors
     */
    const upsertLocationDialog = (open = false, locationType = null, values = undefined, callback = undefined) => {
        if (open) {
            setUpsertLocation({open, callback, locationType, values})
        } else {
            setUpsertLocation({open, callback: undefined, locationType: null, values: undefined})
        }
    }

    /**
     * Navigates the user to the Warehouses view of the panel.
     */
    const onWarehouseClicked = () => {
        history.replace(routeFunctions.panel.company.location.warehouses(params.company));
    }

    /**
     * Navigates the user to the Aisles view of the panel.
     */
    const onAisleClicked = () => {
        history.replace(routeFunctions.panel.company.location.aisles(params.company, params.warehouse));
    }

    /**
     * Navigates the user to the Sections view of the panel.
     */
    const onSectionClicked = () => {
        history.replace(routeFunctions.panel.company.location.sections(params.company, params.warehouse, params.aisle));
    }

    /**
     * Navigates the user to the Rows view of the panel.
     */
    const onRowClicked = () => {
        history.replace(routeFunctions.panel.company.location.rows(params.company, params.warehouse, params.aisle, params.section));
    }


    /**
     * Creates the location bread crumbs for the given location address.
     * @param {any} locationAddress the address to create the breadcrumbs out of.
     * @return {any[]}
     */
    const makeLocationBreadcrumbs = (locationAddress) => {
        const res = [];
        res.push({
            key: 'company',
            child: <CompanyIcon/>,
        })
        if (!locationAddress?.warehouse) return res;
        res[res.length - 1]['onClick'] = onWarehouseClicked
        res.push({
            key: 'warehouse',
            child: locationAddress.warehouse?.code ?? '',
        })
        if (!locationAddress.aisle) return res;
        res[res.length - 1]['onClick'] = onAisleClicked
        res.push({
            key: 'aisle',
            child: locationAddress.aisle?.code ?? '',
        })
        if (!locationAddress.section) return res;
        res[res.length - 1]['onClick'] = onSectionClicked
        res.push({
            key: 'section',
            child: locationAddress.section?.code ?? '',
        })
        if (!locationAddress.row) return res;
        res[res.length - 1]['onClick'] = onRowClicked
        res.push({
            key: 'row',
            child: locationAddress.row?.code ?? '',
            onClick: onRowClicked
        })
        res.push({
            key: 'items',
            child: translations?.itemsBreadcrumb ?? '',
        })
        return res;
    }

    /**
     * Injects the colors of the breadcrumbs before passing the list to the caller.
     * @param {any[]} breadcrumbs
     * @return {*[]}
     */
    const injectBreadcrumbsColors = (breadcrumbs) => {
        if (!breadcrumbs) return [];
        breadcrumbs[breadcrumbs.length - 1]['className'] = classnames(breadcrumbs[breadcrumbs.length - 1]['className'], 'bg-first-breadcrumb-disabled-color')
        return breadcrumbs;
    }

    return (
        <>
            <LocationFormDataContext.Provider value={data}>
                <Switch>
                    <Route path={[
                        routes.panel.company.location.warehouses,
                        routes.panel.company.location.base
                    ]} exact>
                        <WarehousesViews/>
                    </Route>
                    <Route path={[routes.panel.company.location.aisles]} exact>
                        <AislesViews/>
                    </Route>
                    <Route path={[routes.panel.company.location.sections]} exact>
                        <SectionsViews/>
                    </Route>
                    <Route path={[routes.panel.company.location.rows]} exact>
                        <RowsViews/>
                    </Route>
                    <Route path={[routes.panel.company.location.rowItems]} exact>
                        <RowItemsView/>
                    </Route>
                </Switch>
                <GenerateLocationReportDialog
                    open={generateReport.open}
                    callback={generateReport.callback}
                    locationAddress={generateReport.locationAddress}
                    setOpen={generateLocationReportDialog}
                />
                <UpsertLocationDialog
                    open={upsertLocation.open}
                    callback={upsertLocation.callback}
                    locationType={upsertLocation.locationType}
                    values={upsertLocation.values}
                    setOpen={upsertLocationDialog}
                />
            </LocationFormDataContext.Provider>
        </>
    )
};

export default LocationViews;
