import * as Yup from 'yup';
import Dialog from "../../base/dialog";
import {DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Form from "../../base/form";
import {Col, Row} from "react-bootstrap";
import {FormSelect} from "../../base/select/select";
import React, {useContext, useLayoutEffect, useState} from "react";
import {makeRequired, makeValidate} from "mui-rff";
import {LocationFormDataContext} from "../../../contexts/location-form-data";
import {FormKeyboardDatePicker} from "../../base/date-picker";
import BreadCrumbs from "../../app-specific/bread-crumbs";
import classnames from "classnames";

const formNames = {
    status: {
        self: 'status',
        id: 'id',
        title: 'title',
    },
    type: {
        self: 'type',
        id: 'id',
        title: 'title',
    },
    enteredDateFrom: 'enterDateFrom',
    enteredDateTo: 'enteredDateTo',
    exitDateFrom: 'exitDateFrom',
    exitDateTo: 'exitDateTo'
};


/**
 * Creates the schema of the invoice form section.
 * @param {any} translation the translations for the search section
 * @param {any} validationTranslation the translations for the validation section
 * @return {import('yup').AnyObjectSchema}
 */
const makeSchema = (translation, validationTranslation) => {

    const enterFutureLookupError = translation?.form?.validateMessages?.enterFutureLookupError ?? '';
    const exitFutureLookupError = translation?.form?.validateMessages?.exitFutureLookupError ?? '';
    const maxLookupError = validationTranslation?.dateSpecific?.maxLookupError ?? ''
    const minLookupError = validationTranslation?.dateSpecific?.minLookupError ?? ''

    //TODO: determine the exact type of status and type
    return Yup.object().shape({
        [formNames.status.self]: Yup.object().nullable().shape({
            [formNames.status.id]: Yup.number().nullable(),
            [formNames.status.title]: Yup.string().nullable(),
        }),
        [formNames.type.self]: Yup.object().nullable().shape({
            [formNames.type.id]: Yup.number().nullable(),
            [formNames.type.title]: Yup.string().nullable(),
        }),
        [formNames.enteredDateFrom]: Yup.date().when(formNames.enteredDateTo, {
            is: val => !val,
            then: Yup.date().nullable().max(new Date(), enterFutureLookupError),
            otherwise: Yup.date().nullable()
                .max(Yup.ref(formNames.enteredDateTo), maxLookupError)
                .max(new Date(), enterFutureLookupError)
        }),
        [formNames.enteredDateTo]: Yup.date().when(formNames.enteredDateFrom, {
            is: val => !val,
            then: Yup.date().nullable().max(new Date(), enterFutureLookupError),
            otherwise: Yup.date().nullable()
                .min(Yup.ref(formNames.enteredDateFrom), minLookupError)
                .max(new Date(), enterFutureLookupError)
        }),
        [formNames.exitDateFrom]: Yup.date().when(formNames.exitDateTo, {
            is: val => !val,
            then: Yup.date().nullable().max(new Date(), exitFutureLookupError),
            otherwise: Yup.date().nullable()
                .max(Yup.ref(formNames.exitDateTo), maxLookupError)
                .max(new Date(), exitFutureLookupError)
        }),
        [formNames.exitDateTo]: Yup.date().when(formNames.exitDateFrom, {
            is: val => !val,
            then: Yup.date().nullable().max(new Date(), exitFutureLookupError),
            otherwise: Yup.date().nullable()
                .min(Yup.ref(formNames.exitDateFrom), minLookupError)
                .max(new Date(), exitFutureLookupError)
        }),
    }, [[formNames.enteredDateFrom, formNames.enteredDateTo], [formNames.exitDateFrom, formNames.exitDateTo]])
}

/**
 * Creates the location bread crumbs for the given location address.
 * @param {any} locationAddress the address to create the breadcrumbs out of.
 * @return {any[]}
 */
const makeLocationBreadcrumbs = (locationAddress) => {
    const res = [];
    if (!locationAddress?.warehouse) {
        res.push({
            key: 'warehouses',
            child: 'All warehouses',
        })
        return res;
    }
    res.push({
        key: 'warehouses',
        child: locationAddress.warehouse?.code,
    })
    if (!locationAddress.aisle) {
        res.push({
            key: 'aisles',
            child: `All Aisles`,
        })
        return res;
    }
    res.push({
        key: 'aisles',
        child: locationAddress.aisle?.code,
    })
    if (!locationAddress.section) {
        res.push({
            key: 'sections',
            child: `All Sections`,
        })
        return res;
    }
    res.push({
        key: 'sections',
        child: locationAddress.section?.code,
    })
    if (!locationAddress.row) {
        res.push({
            key: 'rows',
            child: `All Rows`,
        })
        return res;
    }
    res.push({
        key: 'rows',
        child: locationAddress.row?.code,
    })
    return res;
}

/**
 * Injects background colors to the given list of breadcrumbs based on their position.
 * @param {any[]} breadcrumbs
 * @return {any[]}
 */
const injectColorsIntoBreadcrumbs = (breadcrumbs) => {
    const colors = [
        'bg-first-breadcrumb-disabled-color',
        'bg-second-breadcrumb-disabled-color',
        'bg-third-breadcrumb-disabled-color',
        'bg-fourth-breadcrumb-disabled-color',

    ];
    return breadcrumbs?.map((e, index) => ({
        ...e,
        className: classnames(e.className, colors[breadcrumbs.length - 1 - index])
    })) ?? [];
}


const GenerateLocationReportDialog = ({
                                          open,
                                          setOpen,
                                          callback: submitFilters,
                                          locationAddress = null,
                                      }) => {
    const {statuses, types} = useContext(LocationFormDataContext);
    const {t} = useTranslation();
    const validationTranslations = t('utils.formValidation', {returnObjects: true})
    const translations = t('dialogs.generateLocationReportDialog', {returnObjects: true})
    const schema = makeSchema(translations, validationTranslations)
    const validate = makeValidate(schema);
    const required = makeRequired(schema);
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    /**
     * Listens for the changes in the location address and with each change:
     * creates a new breadcrumb list.
     */
    useLayoutEffect(() => {
        setBreadcrumbs(injectColorsIntoBreadcrumbs(makeLocationBreadcrumbs(locationAddress)));
    }, [locationAddress])

    /**
     * Submits the report filters by invoking its callback.
     * @param {any} values the filter values
     */
    const submitReport = async (values) => {
        const errors = await submitFilters(values)
        if (!errors) {
            setOpen(false);
            return;
        }
        // TODO: based on the given error, highlight a field or do something.
        return {
            [formNames.status]: "Error",
        }
    }

    return (
        <>
            <Dialog
                maxWidth={'md'}
                fullWidth
                setOpen={setOpen}
                open={open}
                className={'generate-location-report-dialog'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle className={'title'} id='scroll-dialog-title'>
                    <div className={'d-flex w-100 justify-content-between align-items-center'}>
                        <h2>{translations?.title ?? ''}</h2>
                        <BreadCrumbs data={breadcrumbs}/>
                    </div>
                </DialogTitle>
                <Form
                    onSubmit={submitReport}
                    validate={validate}
                    initialValues={{}}
                    render={({values}) => {
                        return (
                            <>
                                <DialogContent id="scroll-dialog-description" className={'mb-4 pt-0'}>
                                    <p className='sub-title'>
                                        {translations?.description ?? ''}
                                    </p>
                                    <Row>
                                        <Col xs={12} md={6} className='mt-4'>
                                            <FormSelect
                                                name={formNames.status.self}
                                                placeholder={translations?.form?.status?.placeholder ?? ''}
                                                label={translations?.form?.status?.title ?? ''}
                                                data={types?.map(e => ({
                                                    id: e.id,
                                                    value: e
                                                }))}
                                            />
                                        </Col>
                                        <Col xs={12} md={6} className='mt-4'>
                                            <FormSelect
                                                name={formNames.type.self}
                                                placeholder={translations?.form?.type?.placeholder ?? ''}
                                                label={translations?.form?.type?.title ?? ''}
                                                data={statuses?.map(e => ({
                                                    id: e.id,
                                                    value: e
                                                }))}
                                            />
                                        </Col>
                                        <Col xs={12} sm={6} className={'pe-sm-2 pe-0 mt-4'}>
                                            <div className='date-wrapper'>
                                                <span className='form-label'>
                                                    {translations?.form?.from ?? ''}
                                                </span>
                                                <div className='picker-container edit-spire-counting-req'>
                                                    <FormKeyboardDatePicker
                                                        placeholder={translations?.form?.enteredDateFrom?.placeholder ?? ''}
                                                        label={translations?.form?.enteredDateFrom?.title ?? ''}
                                                        name={formNames.enteredDateFrom}
                                                        required={required[formNames.enteredDateFrom]}
                                                        maxDate={values[formNames.enteredDateTo]
                                                            ? values[formNames.enteredDateTo]
                                                            : new Date()
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={6} className={'ps-sm-2 ps-0  mt-4'}>
                                            <div className='date-wrapper'>
                                               <span className='form-label'>
                                                        {translations?.form?.to ?? ''}
                                               </span>
                                                <div className='picker-container edit-spire-counting-req'>
                                                    <FormKeyboardDatePicker
                                                        placeholder={translations?.form?.enteredDateTo?.placeholder ?? ''}
                                                        label={translations?.form?.enteredDateTo?.title ?? ''}
                                                        name={formNames.enteredDateTo}
                                                        required={required[formNames.enteredDateTo]}
                                                        minDate={values[formNames.enteredDateFrom]}
                                                        maxDate={new Date()}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={6} className={'pe-sm-2 pe-0 mt-4'}>
                                            <div className='date-wrapper'>
                                                <span className='form-label'>
                                                        {translations?.form?.from ?? ''}
                                                </span>
                                                <div className='picker-container edit-spire-counting-req'>
                                                    <FormKeyboardDatePicker
                                                        placeholder={translations?.form?.exitDateFrom?.placeholder ?? ''}
                                                        label={translations?.form?.exitDateFrom?.title ?? ''}
                                                        name={formNames.exitDateFrom}
                                                        required={required[formNames.exitDateFrom]}
                                                        maxDate={values[formNames.exitDateTo]
                                                            ? values[formNames.exitDateTo]
                                                            : new Date()
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={6} className={'ps-sm-2 ps-0 mt-4'}>
                                            <div className='date-wrapper'>
                                                <span className='form-label'>
                                                        {translations?.form?.to ?? ''}
                                                </span>
                                                <div className='picker-container edit-spire-counting-req'>
                                                    <FormKeyboardDatePicker
                                                        placeholder={translations?.form?.exitDateTo?.placeholder ?? ''}
                                                        label={translations?.form?.exitDateTo?.title ?? ''}
                                                        name={formNames.exitDateTo}
                                                        required={required[formNames.exitDateTo]}
                                                        minDate={values[formNames.exitDateFrom]}
                                                        maxDate={new Date()}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </DialogContent>
                                <DialogActions>
                                    <button
                                        className='button primary outlined px-4'
                                        type='button'
                                        onClick={() => setOpen(false)}
                                    >
                                        {translations?.actions?.cancel ?? ''}
                                    </button>
                                    <button
                                        className='button primary px-5'
                                        type='submit'>
                                        {translations?.actions?.generate ?? ''}
                                    </button>
                                </DialogActions>
                            </>
                        )
                    }}
                />
            </Dialog>
        </>
    );
}

export default GenerateLocationReportDialog;
