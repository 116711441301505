import React, {forwardRef, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {default as SwipeableViews} from "react-swipeable-views";
import LazyImage from "../../base/lazy-image";
import ImageSlider from "../image-slider";
import useWindowViewportWidth from "../../../hooks/use-window";
import useIsMounted from "../../../hooks/use-is-mounted";
import classnames from "classnames";

const applicableSizesForSpacerHeightIncrease = ['xs', 'sm', 'md', 'lg'];


const _ImageSection = ({partImages, onRemoveImage, onAddImageClicked, translation, loading}, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [imageSectionMargin, setImageSectionMargin] = useState(0);
    const [images, setImages] = useState(partImages);
    /**@type {React.MutableRefObject<HTMLDivElement>}*/
    const imageContainerRef = useRef();
    const windowViewPortWidth = useWindowViewportWidth();
    /**@type {MutableRefObject<(string | null)>}* */
    const previousViewportWidth = useRef(null);
    const isMounted = useIsMounted();

    /**
     * With each change in the partImages, sets the images in the state with the received value
     */
    useEffect(() => {
        setImages(partImages);
    }, [partImages])
    

    /**
     * handles application of the style of the image section based on the changes in the viewport  and imageSectionContainer width
     * @type {{marginRight: number, marginLeft: number}}
     */
    const imageSectionStyle = useMemo(() => ({
        ...(
            applicableSizesForSpacerHeightIncrease.includes(windowViewPortWidth) ? {
                marginLeft: imageSectionMargin,
                marginRight: imageSectionMargin
            } : {}
        )
    }), [imageSectionMargin, windowViewPortWidth])

    useLayoutEffect(() => {
        const cleanup = () => {
            previousViewportWidth.current = windowViewPortWidth
        }

        if (!previousViewportWidth.current)
            return cleanup;

        if (previousViewportWidth.current === 'xl' && applicableSizesForSpacerHeightIncrease.includes(windowViewPortWidth)) {
            setImageSectionTransition().then();
        } else if (!applicableSizesForSpacerHeightIncrease.includes(windowViewPortWidth))
            ref.current.style.transition = "all 0.2s ease-in-out";

        return cleanup;
    }, [windowViewPortWidth])

    const setImageSectionTransition = async () => {
        if (!ref.current)
            return;
        ref.current.style.transition = "all 0.2s ease-in-out";
        await new Promise(r => setTimeout(r, 200));
        if (!isMounted())
            return;
        requestAnimationFrame(() => {
            if (!isMounted())
                return;
            ref.current.style.transition = "unset";
        })
    }

    /**
     * Handles click on the image in the bottom slide bar:
     * - Slides the top slide image section to show the selected image
     * @param {number} index
     */
    const onImageClicked = (index) => {
        setSelectedIndex(index);
    }

    /**
     * Adds a resize observer  to the imageSectionContainer
     */
    useLayoutEffect(() => {
        if (!imageContainerRef.current)
            return;
        const observer = new ResizeObserver(onImageSectionContainerResized);
        observer.observe(imageContainerRef.current);
        return () => {
            observer.disconnect();
        }
    }, [imageContainerRef])

    /**
     * With each change in the size of the image container, calculates the margin for the image section
     * @param {ResizeObserverEntry[]} entries
     */
    const onImageSectionContainerResized = (entries) => {
        if (!entries.length)
            return;
        const imageSectionWidth = ref.current?.getBoundingClientRect()?.width;
        const calculatedMargin = entries[0].contentRect.width - imageSectionWidth;
        setImageSectionMargin(calculatedMargin >= 0 ? (calculatedMargin / 2) : 0);
    }


    return (
        <div className={'part-info-image-section-container'} ref={imageContainerRef}>
            <div className={classnames('d-flex flex-column part-info-image-section', {'loading': loading})} style={imageSectionStyle} ref={ref}>
                {
                    loading
                        ? <div/>
                        : <>
                            {
                                (!!images?.length) < 1
                                    ? <div className={'no-image-found'}>
                                        <p>No Images found for this item</p>
                                    </div>
                                    : <>
                                        <div className={'upper-image-section'}>
                                            <SwipeableViews
                                                index={selectedIndex}
                                                animateTransitions={true}
                                                disableLazyLoading={false}
                                                children={images?.map((image) => (
                                                        <div key={image?.id}>
                                                            <LazyImage
                                                                src={image.src}
                                                            />
                                                        </div>
                                                    )
                                                )}
                                            />
                                        </div>
                                        <ImageSlider
                                            images={images}
                                            onImageRemoved={onRemoveImage}
                                            onImageClicked={onImageClicked}
                                        />
                                    </>
                            }
                            <button
                                className={'button primary outlined w-100'}
                                onClick={onAddImageClicked}
                            >
                                {translation.addImage}
                            </button>
                        </>
                }
            </div>
        </div>
    );

}


const ImageSection = forwardRef(_ImageSection);

export default ImageSection;
