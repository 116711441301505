import React, {useContext, useLayoutEffect, useRef, useState} from "react";
import {PanelContext} from "../../../../contexts/panel";
import useRouter from "../../../../hooks/use-router";
import {useTranslation} from "react-i18next";
import classnames from "classnames";
import {sidebarIds} from "../../../../../core/constants/ids";
import {ReactComponent as MenuArrow} from "../../../../../assets/images/panel/sidebar/arrow.svg";
import SidebarPopper from "../popper";
import {NavLink} from "react-router-dom";
import SidebarLogoutMenuItem from "./logout";
import LocalStorageService from "../../../../../core/services/local_storage_service";
import {ReactComponent as Dashboard} from "../../../../../assets/images/panel/sidebar/dashboard.svg";
import {ReactComponent as Users} from "../../../../../assets/images/panel/sidebar/users.svg";
import {ReactComponent as Counting} from "../../../../../assets/images/panel/sidebar/counting.svg";
import {ReactComponent as Products} from "../../../../../assets/images/panel/sidebar/products.svg";
import {ReactComponent as Warehouses} from "../../../../../assets/images/panel/sidebar/warehouses.svg";


const SidebarMenuItem = ({item, menu = false}) => {
    const [panelContext] = useContext(PanelContext);
    const {match, location} = useRouter(item.route);
    const [to, setTo] = useState(location.pathname);
    const {t} = useTranslation();
    const hasRoute = !!item.route;
    const popperReferenceElementRef = useRef();
    const props = {
        id: item.id,
        className: classnames('sidebar-item',
            {
                ...panelContext,
                'active': !!match && hasRoute,
                'menu': !!item?.children?.length || menu
            }),
    };

    /**
     * Listens to the changes in the item and with each change:
     *
     * * sets the To callback associated with the link component.
     */
    useLayoutEffect(() => {
        if (!item.route) return;
        const to = determineToRoute();
        setTo(to);
    }, [item.id])


    /**
     * Injects the necessary parameters for navigation with variable routes of the sidebar products.
     *
     * Note: only the routes are to clicked in the sidebar are included in this method and not all of the routes.
     * @return {null|string}
     */
    const determineToRoute = () => {
        if (!hasRoute) {
            return location.pathname;
        }
        if (!item.to) {
            if (typeof item.route.path === 'string') return item.route.path;
            return item.route.path[0];
        }
        switch (item.id) {
            case sidebarIds.body.dashboard:
            // case sidebarIds.body.userManagement.groups:
            // case sidebarIds.body.userManagement.list:
            case sidebarIds.body.userManagement.id:
            case sidebarIds.body.counting.spire:
            case sidebarIds.body.counting.position:
            case sidebarIds.body.products.list:
            case sidebarIds.body.products.instances:
            case sidebarIds.body.salesOrder:
            case sidebarIds.body.purchaseOrder:
            case sidebarIds.body.location:
                const params = match?.params ?? {};
                let company = params.company;
                if (!company) {
                    company = LocalStorageService.get(LocalStorageService.keys.selectedCompanyName);
                }
                if (!company?.length) {
                    return location.pathname;
                }
                return item.to(company);
            default:
                return location.pathname;
        }
    }

    /**
     * Picks the icon of menu component.
     * @return {null|*}
     */
    const pickIcon = () => {
        switch (item.id) {
            case sidebarIds.body.dashboard:
                return <Dashboard/>;
            case sidebarIds.body.userManagement.id:
                return <Users/>;
            case sidebarIds.body.counting.id:
                return <Counting/>;
            case sidebarIds.body.products.id:
                return <Products/>;
            case sidebarIds.body.location:
                return <Warehouses/>;
            default:
                return <div className={'dot-icon'}/>;
        }
    }

    /**
     * Renders the appropriate menu item component based on the their associated id.
     * @return {JSX.Element|null}
     */
    const renderMenuItemWithoutRoute = () => {
        switch (item.id) {
            case sidebarIds.header.logout:
                return <SidebarLogoutMenuItem
                    itemProps={props}
                    MenuItem={MenuItem}
                />
            default:
                return null;
        }
    }


    // const Icon = item?.icon;
    const MenuItem = (
        <>
            <div className="icon-wrapper">
                {item.icon ?? <div className={'dot-icon'}/>}
            </div>
            <span className="item-content">
                {item.name(t)}
            </span>
            {
                item.children?.length &&
                <div className={'icon-arrow-wrapper'}>
                    <MenuArrow/>
                </div>
            }
        </>
    );

    // not a routed menu item
    if (!hasRoute) {
        return (
            <>
                {renderMenuItemWithoutRoute()}
            </>
        );
    }

    // the menu item has children (another popper is needed)
    if (item?.children?.length) {
        return (
            <>
                <div {...props} ref={popperReferenceElementRef}>
                    {MenuItem}
                    <SidebarPopper referenceElement={popperReferenceElementRef}>
                        {
                            item?.children?.map(childItem => (
                                <SidebarMenuItem key={childItem.id} item={childItem}/>
                            ))
                        }
                    </SidebarPopper>
                </div>
            </>
        );
    }

    // no children and with route
    return (
        <>
            <NavLink to={to} {...props}>
                {MenuItem}
            </NavLink>
        </>
    );
}

export default SidebarMenuItem;
