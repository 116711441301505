import {
    ICanvasDataEntryDepth,
    IInternalCanvasDataEntry,
    IInventorySchemaVisualizerVisualConfig,
    IOnRowClickedFunc,
    IRemoveRectFunc,
    IRotateRectFunc
} from "../../../../types";
import Konva from "konva";
import {InventorySchemaVisualizerModes} from "../../../../../core/models/constants/enums";
import useWarehouseRect from "../../../../hooks/use-warehouse-rect";
import React, {useCallback} from "react";
import BoundRect from "../../bound-rect";
import {Group} from "react-konva";
import RowRect from "../row";

export type ISectionRectProps = {
    section: IInternalCanvasDataEntry<ICanvasDataEntryDepth.section>
    parent: Konva.Rect,
    selectedRectData?: IInventorySchemaVisualizerVisualConfig['selectedEntityData'],
    setSelectedRectData: (data: IInventorySchemaVisualizerVisualConfig['selectedEntityData'] | undefined) => void,
    mode: InventorySchemaVisualizerModes,
    remove: IRemoveRectFunc,
    rotate: IRotateRectFunc,
    onRowClicked: IOnRowClickedFunc,
}

const SectionRect = ({
                         parent,
                         section,
                         selectedRectData,
                         setSelectedRectData,
                         mode,
                         remove,
                         rotate,
                         onRowClicked,
                     }: ISectionRectProps) => {
    const {
        rect,
        setRect,
        groupRef,
        draggable,
        selected,
        selectEntity,
        opacity,
        removeEntity,
        rotateEntity,
        removable,
        rotatable
    } = useWarehouseRect({
        mode: mode,
        selectedRectData: selectedRectData,
        setSelectedRectData: setSelectedRectData,
        allowInteractions: section.allowInteractions,
        data: section.data,
        remove: remove,
        rotate: rotate,
    });

    /**
     * Changes the properties of the Group entity of the canvas with each change in the Rect's entity.
     */
    const onRectTransformed = useCallback<(evt: Konva.KonvaEventObject<Event>) => void>((e) => {
        if (!groupRef.current)
            return;
        const rect = e.target;
        const group = groupRef.current;
        const prevGroupAttrs = {
            x: group.x(),
            y: group.y(),
            rotation: group.rotation(),
            width: group.width(),
            height: group.height(),
        };
        const newGroupAttr = {
            x: rect.x(),
            y: rect.y(),
            rotation: rect.rotation(),
            width: rect.width(),
            height: rect.height(),
        }
        group.setAttrs(newGroupAttr);
        for (const rowRect of group.getChildren((node) => node.getClassName() === 'Rect')) {
            if (!section.rotated) {
                rowRect.setAttrs({
                    width: newGroupAttr.width,
                    height: newGroupAttr.height * (rowRect.height() / prevGroupAttrs.height),
                    x: 0,
                    y: newGroupAttr.height * (rowRect.y() / prevGroupAttrs.height),
                });
            } else {
                rowRect.setAttrs({
                    width: newGroupAttr.width * (rowRect.width() / prevGroupAttrs.width),
                    height: newGroupAttr.height,
                    x: newGroupAttr.width * (rowRect.x() / prevGroupAttrs.width),
                    y: 0,
                });
            }
            rowRect.getAttr('forceOnTransform')?.({target: rowRect});
        }
    }, [groupRef, section.rotated]);

    /**
     * Changes the position of the Group entity of the canvas with each change in the Rect entity's position.
     */
    const onRectDragged = useCallback<(evt: Konva.KonvaEventObject<DragEvent>) => void>((e) => {
        if (!groupRef.current)
            return;
        const rect = e.target;
        const group = groupRef.current;
        group.setAttrs({
            x: rect.x(),
            y: rect.y(),
        });
    }, [groupRef]);


    return (
        <>
            <BoundRect
                interactive={section.allowInteractions}
                allowRemoval={removable}
                allowRotation={rotatable}
                parent={parent}
                remove={removeEntity}
                rotate={rotateEntity}
                ref={(ref) => setRect(ref as Konva.Rect)}
                rectProps={{
                    x: section.x,
                    y: section.y,
                    width: section.width,
                    height: section.height,
                    fill: section.fill,
                    title: section.title,
                    data: section.data,
                    draggable: draggable,
                    name: section.name,
                    onDblClick: selectEntity,
                    onDblTap: selectEntity,
                    stroke: section.stroke,
                    strokeWidth: section.strokeWidth,
                    onDragMove: onRectDragged,
                    opacity: opacity,
                    cursor: section.cursor,
                    onTransform: onRectTransformed,
                    forceOnTransform: onRectTransformed,
                    rotated: section.rotated,
                    entity: section.entity,
                }}
                transformerProps={{
                    keepRatio: false,
                    borderStroke: section.stroke,
                    borderStrokeWidth: section.strokeWidth,
                    anchorCornerRadius: 2,
                    enabledAnchors: !section.rotated ? ['middle-right', 'middle-left'] : ['top-center', 'bottom-center'],
                    rotateEnabled: false,
                    visible: selected,
                }}
                minSize={{width: 10}}
            />
            <Group
                ref={ref => groupRef.current = ref}
                x={section.x}
                y={section.y}
                width={section.width}
                height={section.height}
                draggable={false}
            >
                {
                    section.children.map(row => (
                        <RowRect
                            key={row.key}
                            parent={rect as Konva.Rect}
                            row={row}
                            mode={mode}
                            selectedRectData={selectedRectData}
                            setSelectedRectData={setSelectedRectData}
                            remove={remove}
                            rotate={rotate}
                            onRowClicked={onRowClicked}
                        />
                    ))
                }
            </Group>
        </>
    );
}

export default SectionRect;
