import i18next from "i18next";

/**
 * The Static Text used in this application
 */
export default class ApiResponseMessages {
    static serverErrorTitle: string = i18next?.t('utils.apiResponseMessages.serverErrorTitle') ?? 'Error';
    static serverErrorMessage: string = i18next?.t('utils.apiResponseMessages.serverErrorMessage') ?? 'Can not communicate with the servers properly, please try again later.';
    static serverSuccessTitle: string = i18next?.t('utils.apiResponseMessages.serverSuccessTitle') ?? 'Success';
    static serverSuccessMessage: string = i18next?.t('utils.apiResponseMessages.serverSuccessMessage') ?? 'Your request completed successfully';
}

