import React, {useContext, useEffect, useState} from "react";
import LocalStorageService from "../../../../core/services/local_storage_service";
import useRouter from "../../../hooks/use-router";
import {matchPath} from "react-router";
import routes, {routesLists} from "../../../routes";
import classnames from "classnames";
import {Col} from "react-bootstrap";
import PanelTopbarSettings from "./settings";
import PanelTopbarNotifications from "./notifications";
import PanelTopbarApplications from "./apps";
import PanelTopbarSearchField from "./search-field";
import PanelTopbarCompanies from "./companies";
import {PanelContext} from "../../../contexts/panel";
import {CompanyContext} from "../../../contexts/company";
import {topbarId} from "../../../../core/constants/ids";
import PanelTopbarInventorySchema from "./inventory-schema";

const PanelTopbar = () => {
    const {location, history, query, stringifyUrl} = useRouter();
    const [company] = useContext(CompanyContext);
    const [{collapsed}] = useContext(PanelContext);
    const [disabled, setDisabled] = useState(false);


    /**
     * Listens for the changes in the location of the url and with each change:
     *
     * if the current route is in the following, disables the companies selection process:
     * - login
     * - setup
     */
    useEffect(() => {
        //TODO: add routes that the companies dropdown is disabled in
        const disabledPaths = !!matchPath(location.pathname, {
            path: [
                routes.panel.auth.login,
                routes.panel.auth.login,
                routes.panel.setup,
            ],
            exact: true
        });
        if (disabled !== disabledPaths) {
            setDisabled(disabledPaths);
        }
    }, [location?.pathname])


    /**
     * Listens for the changes in the selected company and with each change:
     * replaces the company of the current url to trigger any changes of the page the user is in.
     */
    useEffect(() => {
        if (!company) {
            return;
        }
        // sets the selected application name and emits a storage Event.
        LocalStorageService.set(LocalStorageService.keys.selectedCompanyName, company.name);
        window.dispatchEvent(new Event('storage'));

        const inDependentRoutes = !!matchPath(location.pathname, {
            path: [...routesLists.company.slice(1), routes.panel.base],
            exact: true,
        });

        if (!inDependentRoutes) return;

        // the url always starts with: /panel/:company
        const locationSegments = location.pathname.split('/');
        locationSegments[2] = company.name;
        history.push(stringifyUrl({
            url: locationSegments.join('/'),
            query: query,
        }))
    }, [company])


    return (
        <div className={'top-bar'} id={topbarId}>
            <div
                className={classnames('top-bar-inner order-1 justify-content-center justify-content-md-start',
                    {'collapsed': collapsed}
                )}>
                <PanelTopbarCompanies disabled={disabled}/>
                <Col className={'d-flex align-items-center justify-content-center justify-content-md-end flex-grow-1' +
                    ' order-3 order-sm-2 w-100'}>
                    <PanelTopbarSearchField disabled={disabled}/>
                </Col>
                <div className={'d-flex align-items-center order-2 order-sm-3'}>
                    <PanelTopbarInventorySchema disabled={disabled}/>
                    <PanelTopbarApplications disabled={disabled}/>
                    <PanelTopbarNotifications disabled={disabled}/>
                    <PanelTopbarSettings disabled={disabled}/>
                </div>
            </div>
        </div>
    );
}

export default PanelTopbar;
