import React, {useEffect, useReducer, useState} from "react";
import Stepper from "../../../../components/base/stepper";
import {ReactComponent as UserInfo} from "../../../../../assets/images/views/public-views/register/user-info.svg";
import {ReactComponent as BusinessInfo} from "../../../../../assets/images/views/public-views/register/business-info.svg";
import {ReactComponent as PaymentInfo} from "../../../../../assets/images/views/public-views/register/payment-info.svg";
import {ReactComponent as PlanSelection} from "../../../../../assets/images/views/public-views/register/plan-selection.svg";
import {Col, Container, Row} from "react-bootstrap";
import {registrationStepperId, registrationStepperStepIds} from "../../../../../core/constants/ids";
import {RegistrationStepperSteps} from "../../../../../core/constants/enums";
import {useTranslation} from "react-i18next";
import {RegistrationContext, RegistrationContextInitialValues} from "../../../../contexts/registration";
import RegistrationReducer, {RegistrationReducerActions} from "../../../../reducers/registration";
import {Check, Clear} from '@material-ui/icons';
import RegistrationViewBusinessInfoSection from "./sections/business-info";
import RegistrationViewPlanSelectionSection from "./sections/plan-selection";
import RegistrationViewPaymentInfoSection from "./sections/payment-info";
import RegistrationViewUserInfoSection from "./sections/user-info";
import RouteLeavingGuard from "../../../../components/base/route-leaving-guard";

const RegistrationView = () => {
    const {t} = useTranslation();
    const [registrationState, registrationReducer] = useReducer(RegistrationReducer, RegistrationContextInitialValues[0], undefined);
    const [blockNavigation, setBlockNavigation] = useState(false);
    const steps = [
        {
            index: RegistrationStepperSteps.userInfo,
            id: registrationStepperStepIds.userInfo,
            label: t('views.public.registration.steps.userInfo'),
            labelPrefix: (
                !registrationState[registrationStepperStepIds.userInfo].submitted
                    ? null
                    : !registrationState[registrationStepperStepIds.userInfo].valid
                        ? <Clear className={'invalid'}/>
                        : <Check className={'valid'}/>
            ),
            icon: (
                <>
                    <div className={'register-icon-wrapper'}>
                        <div className={'register-icon'}>
                            <UserInfo/>
                        </div>
                    </div>
                </>
            ),
        },
        {
            index: RegistrationStepperSteps.businessInfo,
            id: registrationStepperStepIds.businessInfo,
            label: t('views.public.registration.steps.businessInfo'),
            labelPrefix: (
                !registrationState[registrationStepperStepIds.businessInfo].submitted
                    ? null
                    : !registrationState[registrationStepperStepIds.businessInfo].valid
                        ? <Clear className={'invalid'}/>
                        : <Check className={'valid'}/>
            ),
            icon: (
                <>
                    <div className={'register-icon-wrapper'}>
                        <div className={'register-icon'}>
                            <BusinessInfo/>
                        </div>
                    </div>
                </>
            ),
        },
        {
            index: RegistrationStepperSteps.planSelection,
            id: registrationStepperStepIds.planSelection,
            label: t('views.public.registration.steps.planSelection'),
            labelPrefix: (
                !registrationState[registrationStepperStepIds.planSelection].submitted
                    ? null
                    : !registrationState[registrationStepperStepIds.planSelection].valid
                        ? <Clear className={'invalid'}/>
                        : <Check className={'valid'}/>
            ),
            icon: (
                <>
                    <div className={'register-icon-wrapper'}>
                        <div className={'register-icon'}>
                            <PlanSelection/>
                        </div>
                    </div>
                </>
            ),
        },
        {
            index: RegistrationStepperSteps.paymentInfo,
            id: registrationStepperStepIds.paymentInfo,
            label: t('views.public.registration.steps.paymentInfo'),
            labelPrefix: (
                !registrationState[registrationStepperStepIds.paymentInfo].submitted
                    ? null
                    : !registrationState[registrationStepperStepIds.paymentInfo].valid
                        ? <Clear className={'invalid'}/>
                        : <Check className={'valid'}/>
            ),
            icon: (
                <>
                    <div className={'register-icon-wrapper'}>
                        <div className={'register-icon'}>
                            <PaymentInfo/>
                        </div>
                    </div>
                </>
            ),
        }
    ];


    /**
     * As soon as the component mounts:
     * scrolls the window to the header of the view.
     */
    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"});
    }, [])


    /**
     * Listens to the changes in registrationState changes and with each change, determines if its needed to block
     * or un-block the navigation of the users.
     */
    useEffect(() => {
        guardNavigation();
    }, [registrationState])


    /**
     * Determines whether to block or un-block the navigation or the users from this page.
     *
     * if none of the sections are submitted            => un-block
     * if all of the sections are submitted and valid   => un-block
     * if partial submission or partial validation      => block
     */
    const guardNavigation = () => {
        const stateKeys = Object.values(registrationStepperStepIds);
        const stateValues = Object.entries(registrationState)?.filter(([key]) => stateKeys.includes(key)) ?? [];
        // if none submitted => then just let them pass
        const noneSubmitted = stateValues.every(e => !e.submitted);
        if (noneSubmitted) {
            if (blockNavigation) setBlockNavigation(false);
            return;
        }
        // if all submitted and all valid => then just let them pass
        const allSubmitted = stateValues.every(e => e.submitted);
        const allValid = stateValues.every(e => e.valid);
        if (allSubmitted && allValid) {
            if (blockNavigation) setBlockNavigation(false);
            return;
        }
        // if we have partial submission or partial validation, then block the navigation
        if (!blockNavigation) {
            setBlockNavigation(true);
        }
    }

    /**
     * Navigates to the selected step of the stepper as it is clicked.
     * @param step
     */
    const onStepClicked = (step) => {
        registrationReducer({
            type: RegistrationReducerActions.jumpToStep,
            payload: step.index,
        });
    }

    /**
     * Determines which one of the steps must be rendered based on the activeStep of the reducer
     * @return {JSX.Element}
     */
    const renderRegisterSections = () => {
        switch (registrationState.activeStep) {
            case RegistrationStepperSteps.userInfo:
                return <RegistrationViewUserInfoSection/>;
            case RegistrationStepperSteps.businessInfo:
                return <RegistrationViewBusinessInfoSection/>;
            case RegistrationStepperSteps.planSelection:
                return <RegistrationViewPlanSelectionSection/>;
            case RegistrationStepperSteps.paymentInfo:
                return <RegistrationViewPaymentInfoSection/>;
            default:
                return <></>;
        }
    }

    return (
        <>
            <RegistrationContext.Provider value={[registrationState, registrationReducer]}>
                <Container>
                    <Row className={'mt-5'}>
                        <Col xs={12} className={'p-0'}>
                            <Stepper
                                id={registrationStepperId}
                                steps={steps.map(e => ({...e, onClick: onStepClicked}))}
                                activeStepIndex={registrationState.activeStep}
                                className={'register-stepper'}
                            />
                        </Col>
                    </Row>
                    <Row className={'mt-5 registration-view'}>
                        {renderRegisterSections()}
                    </Row>
                </Container>
            </RegistrationContext.Provider>
            <RouteLeavingGuard
                when={blockNavigation}
                shouldBlockNavigation={() => blockNavigation}
            />
        </>
    )
}

export default RegistrationView;
