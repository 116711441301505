import Dialog from "../../base/dialog";
import * as Yup from 'yup';
import {DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Form from "../../base/form";
import ValidateMessages from "../../../../core/constants/texts/vallidate-messages";
import {makeRequired, makeValidate} from "mui-rff";
import {Col, Row} from "react-bootstrap";
import {PasswordFormInput} from "../../base/input/password";

const formNames = {
    currentPassword: 'currentPass',
    newPassword: 'newPass',
    confirmNewPassword: 'confirmPass'
};

const schema = Yup.object().shape({
    [formNames.currentPassword]: Yup.string().nullable().required(ValidateMessages.required),
    [formNames.newPassword]: Yup.string().nullable().required(ValidateMessages.required),
    [formNames.confirmNewPassword]: Yup.string().nullable().required(ValidateMessages.required)
})

const ChangePasswordDialog = ({open, setOpen}) => {
    const validate = makeValidate(schema);
    const required = makeRequired(schema);
    const {t} = useTranslation();
    const translations = t("dialogs.changePasswordDialog", {returnObjects: true});

    /**
     * Changes the password of the user by calling the api.
     *
     * closes the dialog if the result of the api is successful.
     * @param {any}         values          the form values
     * @param {formApi}     form            the form itself
     * @param {function}    submitErrors    callback for submitting errors for each field
     */
    const changeUserPassword = (values, form, submitErrors) => {
        //TODO: call api for changing the password of the user
        return null;
    }

    return (
        <>
            <Dialog
                maxWidth={'sm'}
                fullWidth
                setOpen={setOpen}
                open={open}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle className={'title'} id='scroll-dialog-title'>
                    {translations?.title ?? ""}
                </DialogTitle>
                <Form
                    onSubmit={changeUserPassword}
                    validate={validate}
                    render={({submitting}) => {
                        return (
                            <>
                                <DialogContent id="scroll-dialog-description" className={'pt-0'}>
                                    <Row>
                                        <Col xs={12} sm={7} className=''>
                                            <PasswordFormInput
                                                placeholder={translations?.form?.currentPassword?.placeholder ?? ''}
                                                label={translations?.form?.currentPassword?.title ?? ''}
                                                name={formNames.currentPassword}
                                                required={required[formNames.currentPassword]}
                                            />
                                        </Col>
                                        <Col xs={12} sm={7} className='mt-3'>
                                            <PasswordFormInput
                                                placeholder={translations?.form?.newPassword?.placeholder ?? ''}
                                                label={translations?.form?.newPassword?.title ?? ''}
                                                name={formNames.newPassword}
                                                required={required[formNames.newPassword]}
                                            />
                                        </Col>
                                        <Col xs={12} sm={7} className='py-3'>
                                            <PasswordFormInput
                                                placeholder={translations?.form?.confirmNewPassword?.placeholder ?? ''}
                                                label={translations?.form?.confirmNewPassword?.title ?? ''}
                                                name={formNames.confirmNewPassword}
                                                required={required[formNames.confirmNewPassword]}
                                            />
                                        </Col>
                                    </Row>
                                </DialogContent>
                                <DialogActions>
                                    <div className='d-flex justify-content-end'>
                                        <button
                                            className='button outlined primary mx-1 px-4 py-2'
                                            disabled={submitting}
                                            onClick={(e) => setOpen(false)}
                                            type={'button'}
                                        >
                                            {
                                                submitting
                                                    ? translations?.actions?.submitting ?? ''
                                                    : translations?.actions?.cancel ?? ''
                                            }
                                        </button>
                                        <button type='submit'
                                                disabled={submitting}
                                                className='button primary mx-1 me-3 px-5 py-2'>
                                            {
                                                submitting
                                                    ? translations?.actions?.submitting ?? ''
                                                    : translations?.actions?.submit ?? ''
                                            }
                                        </button>
                                    </div>

                                </DialogActions>
                            </>
                        )
                    }}
                />
            </Dialog>
        </>
    )
}

export default ChangePasswordDialog;
