import React, {useEffect, useState} from 'react';
import {Collapse, Container} from "react-bootstrap";
import {DefaultPaginationInfo, SpireItemScannedStatuses, TableCellAlignments, TableCellTypes} from "../../../../core/constants/enums";
import Table from "../../../containers/table";
import useRouter from "../../../hooks/use-router";
import {deepEqual} from "../../../../core/services/utils";
import TabsLayout from "../../../components/app-specific/tabs-layout";
import ButtonDropdown from "../../../components/app-specific/button-dropdown";
import {UrlQueryNames} from "../../../../core/constants/query-names";
import Form from "../../../components/base/form";
import {SearchFormInput} from "../../../components/base/input/search";
import {makeValidate} from "mui-rff";
import * as Yup from 'yup'
import {ReactComponent as Icon} from "../../../../assets/images/calendar-icon.svg";
import BreadCrumbs from "../../../components/app-specific/bread-crumbs";
import {useTranslation} from "react-i18next";
import {ReactComponent as EditIcon} from "../../../../assets/images/add-note-icon.svg";
import {
    generatePaginationAndOrderByQueryResetter,
    hasOrderByOfQueryChanged,
    hasPaginationInfoChanged, onTableCurrentPageChange,
    onTablePageSizeChange
} from "../../../../core/services/searching-utils";
import classnames from "classnames";
import {Checkbox} from "@material-ui/core";
import {ReactComponent as ChevronIcon} from "../../../../assets/images/chevron-icon.svg";
import NoEntries from "../../../components/app-specific/no-entries";

const TestView = () => {
    const {query, location, stringifyUrl, history} = useRouter();
    const tableCells = [
        {
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: 'select',
            size: 0,
        },
        {
            title: 'Part Number',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            sortable: true,
            name: 'partNumber',
        },
        {
            title: 'Freeze Count',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.string,
            sortable: true,
            name: 'freezeCount',
        },
        {
            title: 'Scanned Count',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.string,
            sortable: true,
            name: 'scannedCount',
            size: 1.2,
        },
        {
            title: 'Update Date & Time',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.dateTime,
            sortable: true,
            name: 'updateDateTime',
            size: 1.5,
        },
        {
            type: TableCellTypes.element,
            name: 'toggle',
            last: true,
        }
    ];
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [orderBy, setOrderBy] = useState(null);
    const [paginationInfo, setPaginationInfo] = useState(null);
    const tabs = [
        {
            label: "General Setting",
            wrapped: true,
            value: "tab1Value"
        },
        {
            label: "Spire Setting",
            value: "tab2Value"
        },
        {
            label: "company setting",
            value: "tab3Value"
        },
    ]
    const breadCrumbs = [
        {
            key: 1,
            className: 'test-first-crumb',
            child: (
                <Icon/>
            ),
            onClick: () => console.log("Hey")
        },
        {
            key: 2,
            className: 'test-second-crumb',
            child: (
                <p>
                    AAS2R322
                </p>
            ),
            onClick: () => console.log("Hey You")
        },
        {
            key: 2,
            className: 'bg-second-breadcrumb-disabled-color',
            child: (
                <p>
                    AAS2R322
                </p>
            ),
        },
        {
            key: 2,
            className: 'bg-first-breadcrumb-disabled-color',
            child: (
                <p>
                    AAS2R322
                </p>
            ),
        }
    ]

    /**
     * Listens to the changes in the url query parameters and with each change:
     * If the paginationInfo or orderBy of the url is not the same as the state, updates the state and
     */
    useEffect(() => {
        const newOrderBy = hasOrderByOfQueryChanged();
        const newPaginationInfo = hasPaginationInfoChanged();
        if (!newOrderBy && !newPaginationInfo) return;
        getFromApi(newOrderBy, newPaginationInfo);
    }, [query])


    /**
     * Determines if the paginationInfo of the query has changed.
     *
     * If there is a change, sets the state to be in sync with the change
     * @return {PaginationInfo|null}
     */
    const hasOrderByOfQueryChanged = () => {
        let newOrderBy = null;
        const newOrderByName = query[UrlQueryNames.orderBy] ?? null;
        const isDescending = query[UrlQueryNames.orderBy] ?? null;
        if (newOrderByName !== null && isDescending !== null) {
            newOrderBy = {
                propertyName: newOrderByName,
                isDescending: isDescending,
            };
        }
        if (!deepEqual(newOrderBy, orderBy)) {
            setOrderBy(newOrderBy);
            return newOrderBy;
        }
        return null;
    }

    /**
     * Determines if the paginationInfo of the query has changed.
     *
     * If there is a change, sets the state to be in sync with the change
     * @return {PaginationInfo|null}
     */
    const hasPaginationInfoChanged = () => {
        let newPaginationInfo;
        const newPageSize = query[UrlQueryNames.pageSize] ?? null;
        const newCurrentPage = query[UrlQueryNames.currentPage] ?? null;
        const newTotal = query[UrlQueryNames.total] ?? null;
        newPaginationInfo = {
            pageSize: newPageSize ?? DefaultPaginationInfo.pageSize,
            currentPage: newCurrentPage ?? DefaultPaginationInfo.currentPage,
            length: newTotal ?? DefaultPaginationInfo.length,
        };
        if (!deepEqual(newPaginationInfo, paginationInfo)) {
            setPaginationInfo(newPaginationInfo);
            return newPaginationInfo;
        }
        return null;
    }

    // FOR TEST PURPOSES
    const getFromApi = (orderBy, paginationInfo) => {
        setLoading(true);
        const result = {
            data: [],
            currentPage: 1,
            total: 100,
            pageSize: 10,
        };
        setLoading(false);
    }


    /**
     *
     * @return {TableData[]}
     */
    const createTableData = () => {
        return Array(20).fill(null).map((e, index) => ({
            key: index,
            select: () => {
                return (
                    <button className={'button primary text'}>
                        HAHA
                    </button>
                );
            },
            partNumber: `Part number ${index}`,
            freezeCount: index * 128,
            scannedCount: index * 28,
            updateDateTime: Date.now() - (index * 3600000),
            toggle: (open, setOpen, format) => {
                return (
                    <>
                        <button className={'button secondary'} onClick={() => setOpen(!open)}>
                            HAHA
                        </button>
                    </>
                );
            },
            child: (open, setOpen) => (
                <InnerTable open={open} setOpen={setOpen}/>
            )
        }));
    }

    /**
     * Changes the current page of the table pagination in the url
     * @param {PaginationInfo}  prevPaginationInfo
     * @param {number}          newCurrentPage
     */
    const onCurrentPageChange = (prevPaginationInfo, newCurrentPage) => {
        history.push(stringifyUrl({
            url: location.pathname,
            query: {
                ...query,
                [UrlQueryNames.currentPage]: newCurrentPage,
            }
        }));
    }

    /**
     * Changes the page size of the table pagination in the url
     * @param {PaginationInfo}  prevPaginationInfo
     * @param {number}          newPageSize
     */
    const onPageSizeChange = (prevPaginationInfo, newPageSize) => {
        history.push(stringifyUrl({
            url: location.pathname,
            query: {
                ...query,
                [UrlQueryNames.pageSize]: newPageSize,
            }
        }));
    }


    /**
     * Changes the sorted column of the table in the url.
     *
     * * resets the current page back to one.
     * @param {TableOrderBy}  newOrderBy
     */
    const onSort = (newOrderBy) => {
        history.push(stringifyUrl({
            url: location.pathname,
            query: {
                ...query,
                [UrlQueryNames.orderBy]: newOrderBy.propertyName,
                [UrlQueryNames.orderByDescending]: newOrderBy.isDescending,
                [UrlQueryNames.currentPage]: DefaultPaginationInfo.currentPage,
            }
        }));
    }

    const moreOptions = [
        {
            title: "Parts Report",
            id: "parts"
        },
        {
            title: "Serials Report",
            id: 'serials'
        },
        {
            title: "Regenerate this sync",
            id: 'manual'
        },
    ]

    return (
        <div className={'bg-lightest-primary-color'}>
            <Container>
                <div className={'mt-5'}/>
                <BreadCrumbs data={breadCrumbs}/>
                <div className={'mt-5'}/>
                <ButtonDropdown
                    title={'Test title'}
                    options={moreOptions}
                    variant={'outlined'}
                    onOptionSelected={(option) => console.log(option)}
                    buttonProps={{
                        className: 'primary',
                    }}
                />
                <div className={'mt-5'}/>
                <div className={'panel-card px-0'}>
                    <TabsLayout
                        tabs={tabs}
                        initialValue={tabs[0].value}
                        onChange={(val) => console.log(val)}
                        swipeable={false}
                    >
                        <TabInner/>
                        <TabInner/>
                        <TabInner/>
                    </TabsLayout>
                </div>

                <div className={'mt-5'}/>
                <Table
                    cells={tableCells}
                    data={createTableData()}
                    orderBy={orderBy}
                    paginationInfo={paginationInfo}
                    onCurrentPageChange={onCurrentPageChange}
                    onPageSizeChange={onPageSizeChange}
                    onSort={onSort}
                />
            </Container>

        </div>
    )
}


const TabInner = () => {

    const onSubmit = (e) => {
        console.log(e)
    }

    return (
        <>
            <div className={'m-5 p-4'}>
                <Form
                    onSubmit={onSubmit}
                    className={'d-flex flex-column w-100'}
                    validate={makeValidate(Yup.object().shape())}
                    render={() => {
                        return (
                            <>
                                <SearchFormInput
                                    buttonProps={{
                                        className: 'button primary',
                                        children: "Search",
                                        type: 'button',
                                        onClick: onSubmit
                                    }}
                                    name={'test-search'}
                                    fullWidth
                                    required
                                />
                            </>
                        );
                    }}
                />

            </div>
        </>
    );
}

export default TestView;



// Main table cell keys
const tableCellKeys = {
    select: 'select',
    partNumber: 'partNumber',
    freezeCount: 'freezeCount',
    scannedCount: 'scannedCount',
    updatedDatetime: 'updatedDateTime',
    expand: 'expand'
}

const InnerTable = ({open, setOpen}) => {
    const [loading, setLoading] = useState(true)
    const {t} = useTranslation();
    const translations = t("views.panel.company.counting.spire.single", {returnObjects: true});

    /**
     *
     * @type {TableCellData[]}
     */
    const outerTableCells = [
        {
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: tableCellKeys.select,
            size: 0.1
        },
        {
            title: translations?.itemsTable?.partNumber ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: tableCellKeys.partNumber,
            sortable: true,
        },
        {
            title: translations?.itemsTable?.freezeCount ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.string,
            name: tableCellKeys.freezeCount,
            sortable: true,
        },
        {
            title: translations?.itemsTable?.scannedCount ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.string,
            name: tableCellKeys.scannedCount,
            sortable: true,
            size:2
        },
        {
            title: translations?.itemsTable?.updateTimeDate ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.dateTime,
            name: tableCellKeys.updatedDatetime,
            sortable: true,
            size:2
        },
        {
            alignment: TableCellAlignments.center,
            type: TableCellTypes.element,
            name: tableCellKeys.expand,
            last: true,
        }
    ];



    const data = [
        {
            id: 1,
            partNumber: "RADFR101A",
            freezeCount: 12,
            scannedCount: 10,
            updatedDatetime: new Date(),
            expanded: false,
            selected: false,
            innerItems: [
                {
                    id: 11,
                    serialNumber: '13123-123123-123',
                    operator: "Testian",
                    scannedDataTime: new Date(),
                    status: {
                        id: 1,
                        name: "Scanned"
                    }
                },
                {
                    id: 12,
                    serialNumber: '13123-123123-123',
                    operator: "Testian",
                    scannedDataTime: new Date(),
                    status: {
                        id: 2,
                        name: "Not Scanned"
                    }
                },
                {
                    id: 13,
                    serialNumber: '13123-123123-123',
                    operator: "Testian",
                    scannedDataTime: new Date(),
                    status: {
                        id: 3,
                        name: "Incorrect"
                    }
                }
            ],
        },
        {
            id: 2,
            partNumber: "RADFR101A",
            freezeCount: 12,
            scannedCount: 10,
            updatedDatetime: new Date(),
            expanded: false,
            selected: false,
            innerItems: [
                {
                    id: 21,
                    serialNumber: '13123-123123-123',
                    operator: "Testian",
                    scannedDataTime: new Date(),
                    status: {
                        id: 1,
                        name: "Scanned"
                    }
                },
                {
                    id: 22,
                    serialNumber: '13123-123123-123',
                    operator: "Testian",
                    scannedDataTime: new Date(),
                    status: {
                        id: 2,
                        name: "Not Scanned"
                    }
                },
                {
                    id: 23,
                    serialNumber: '13123-123123-123',
                    operator: "Testian",
                    scannedDataTime: new Date(),
                    status: {
                        id: 3,
                        name: "Incorrect"
                    }
                }
            ],
        },
        {
            id: 3,
            partNumber: "RADFR101A",
            freezeCount: 12,
            scannedCount: 10,
            updatedDatetime: new Date(),
            expanded: false,
            selected: false,
            innerItems: [
                {
                    id: 31,
                    serialNumber: '13123-123123-123',
                    operator: "Testian",
                    scannedDataTime: new Date(),
                    status: {
                        id: 1,
                        name: "Scanned"
                    }
                },
                {
                    id: 32,
                    serialNumber: '13123-123123-123',
                    operator: "Testian",
                    scannedDataTime: new Date(),
                    status: {
                        id: 2,
                        name: "Not Scanned"
                    }
                },
                {
                    id: 33,
                    serialNumber: '13123-123123-123',
                    operator: "Testian",
                    scannedDataTime: new Date(),
                    status: {
                        id: 3,
                        name: "Incorrect"
                    }
                }
            ],
        },
        {
            id: 4,
            partNumber: "RADFR101A",
            freezeCount: 12,
            scannedCount: 10,
            updatedDatetime: new Date(),
            expanded: false,
            selected: false,
            innerItems: [
                {
                    id: 41,
                    serialNumber: '13123-123123-123',
                    operator: "Testian",
                    scannedDataTime: new Date(),
                    status: {
                        id: 1,
                        name: "Scanned"
                    }
                },
                {
                    id: 42,
                    serialNumber: '13123-123123-123',
                    operator: "Testian",
                    scannedDataTime: new Date(),
                    status: {
                        id: 2,
                        name: "Not Scanned"
                    }
                },
                {
                    id: 43,
                    serialNumber: '13123-123123-123',
                    operator: "Testian",
                    scannedDataTime: new Date(),
                    status: {
                        id: 3,
                        name: "Incorrect"
                    }
                }
            ],
        },
    ];


    /**
     * Creates the data entries for spire counting item table
     * @return {TableData[]}
     */
    const createTableData = () => {
        return data
            ?.map((item) => ({
                key: item.id,
                [tableCellKeys.select]: () => {
                    return (
                        <Checkbox className={classnames('item-selector', {
                            'selected': item?.selected,
                        })}
                                  onClick={(e) => false}
                        />
                    );
                },
                [tableCellKeys.partNumber]: item?.partNumber,
                [tableCellKeys.freezeCount]: item?.freezeCount,
                [tableCellKeys.scannedCount]: item?.scannedCount,
                [tableCellKeys.updatedDatetime]: item?.updatedDatetime,
                // [tableCellKeys.expand]: () => (
                //     <div
                //         className={classnames('expand-chevron', {'expanded': item?.expanded})}
                //         onClick={(e) => onExpandClicked(e, item)}
                //     >
                //         <ChevronIcon/>
                //     </div>
                //
                // ),

                // expandedSection: () => (
                //     //Make sure collapse is imported from react bootstrap
                //     <Collapse in={item?.expanded}>
                //         <div className={'inner-item-container'}>
                //             <Table
                //                 className={'my-3 w-auto'}
                //                 color={'secondary'}
                //                 cells={innerTableCells}
                //                 data={createInnerTableData(item)}
                //             />
                //         </div>
                //     </Collapse>
                // ),
            }))
    }


    return (
        <>
            <Table
                className={'my-3'}
                color={'primary'}
                cells={outerTableCells}
                data={createTableData()}
            />
        </>
    );
}
