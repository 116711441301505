import React from 'react';
import {FormControl, InputLabel, MenuItem, Select as MuiSelect,} from '@material-ui/core';
import {Field} from 'react-final-form';
import {ErrorMessage, showErrorOnChange, useFieldForErrors} from '../../../../core/services/muirff_utils';


const FieldSelect = ({
                         name,
                         label,
                         data,
                         children,
                         required,
                         multiple,
                         helperText,
                         fieldProps,
                         inputLabelProps,
                         formControlProps,
                         formHelperTextProps,
                         menuItemProps,
                         fullWidth,
                         onChange: onChangeProp,
                         showError = showErrorOnChange,
                         ...restSelectProps
                     }) => {

    if (!data && !children) {
        throw new Error('Please specify either children or data as an attribute.');
    }

    const {variant} = restSelectProps;
    const field = useFieldForErrors(name);
    const isError = showError(field);

    return (
        <Field
            name={name}
            render={({input: {name, value, onChange: onChangeFromField, ...restInput}}) => {
                // prevents an error that happens if you don't have initialValues defined in advance
                const finalValue = multiple && !value ? [] : value;
                const labelId = `select-input-${name}`;

                /**
                 * Changes the value of the input and calls the onChange callback if it exists
                 * @param {MouseEvent} e
                 * @param {any} child
                 */
                const onChange = (e, child) => {
                    onChangeFromField(e);
                    if (onChangeProp) {
                        onChangeProp(e, child);
                    }
                }

                return (
                    <FormControl
                        required={required}
                        error={isError}
                        fullWidth={fullWidth}
                        variant={variant}
                        {...formControlProps}
                    >
                        {!!label && (
                            <InputLabel id={labelId} {...inputLabelProps}>
                                {label}
                            </InputLabel>
                        )}
                        <MuiSelect
                            fullWidth={fullWidth}
                            name={name}
                            value={finalValue}
                            onChange={onChange}
                            multiple={multiple}
                            label={label}
                            labelId={labelId}
                            inputProps={{required, ...restInput}}
                            {...restSelectProps}
                        >
                            {data
                                ? data.map(item => (
                                    <MenuItem
                                        value={item.value}
                                        key={item.value}
                                        disabled={item.disabled}
                                        {...(menuItemProps)}
                                    >
                                        {item.label}
                                    </MenuItem>
                                ))
                                : children}
                        </MuiSelect>
                        <ErrorMessage
                            showError={isError}
                            meta={field.meta}
                            formHelperTextProps={formHelperTextProps}
                            helperText={helperText}
                        />
                    </FormControl>
                );
            }}
            {...fieldProps}
        />
    );
}

export default FieldSelect
