import {
    ICanvasDataEntryDepth,
    IInternalCanvasDataEntry,
    IInventorySchemaVisualizerVisualConfig,
    IOnRowClickedFunc,
    IRemoveRectFunc,
    IRotateRectFunc
} from "../../../../types";
import Konva from "konva";
import {InventorySchemaVisualizerModes} from "../../../../../core/models/constants/enums";
import useWarehouseRect from "../../../../hooks/use-warehouse-rect";
import BoundRect from "../../bound-rect";
import React, {useCallback} from "react";

export type IRowRectProps = {
    row: IInternalCanvasDataEntry<ICanvasDataEntryDepth.row>
    parent: Konva.Rect,
    selectedRectData?: IInventorySchemaVisualizerVisualConfig['selectedEntityData'],
    setSelectedRectData: (data: IInventorySchemaVisualizerVisualConfig['selectedEntityData'] | undefined) => void,
    mode: InventorySchemaVisualizerModes,
    remove: IRemoveRectFunc,
    rotate: IRotateRectFunc,
    onRowClicked: IOnRowClickedFunc,

}

const RowRect = ({
                     parent,
                     row,
                     selectedRectData,
                     setSelectedRectData,
                     mode,
                     remove,
                     rotate,
                     onRowClicked,
                 }: IRowRectProps) => {
    const {draggable, selected, selectEntity, opacity, removeEntity, rotatable, removable, rotateEntity} = useWarehouseRect({
        mode: mode,
        selectedRectData: selectedRectData,
        setSelectedRectData: setSelectedRectData,
        allowInteractions: row.allowInteractions,
        data: row.data,
        remove: remove,
        rotate: rotate,
    });

    /**
     * Invokes the [onRowClicked] callback of the component as soon as the rect is clicked or tapped.
     */
    const onClicked = useCallback(() => {
        onRowClicked?.([row.data[1], row.data[2], row.data[3]]);
    }, [onRowClicked, row.data]);

    return (
        <>
            <BoundRect
                interactive={row.allowInteractions}
                allowRemoval={removable}
                allowRotation={rotatable}
                parent={parent}
                remove={removeEntity}
                rotate={rotateEntity}
                rectProps={{
                    x: row.x,
                    y: row.y,
                    width: row.width,
                    height: row.height,
                    fill: row.fill,
                    title: row.title,
                    data: row.data,
                    name: row.name,
                    draggable: draggable,
                    onDblClick: selectEntity,
                    onDblTap: selectEntity,
                    stroke: row.stroke,
                    strokeWidth: row.strokeWidth,
                    opacity: opacity,
                    cursor: row.cursor,
                    rotated: row.rotated,
                    onTap: onClicked,
                    onClick: onClicked,
                    entity: row.entity,
                }}
                transformerProps={{
                    keepRatio: false,
                    borderStroke: row.stroke,
                    borderStrokeWidth: row.strokeWidth,
                    anchorCornerRadius: 2,
                    enabledAnchors: !row.rotated ? ['top-center', 'bottom-center'] : ['middle-right', 'middle-left'],
                    rotateEnabled: false,
                    visible: selected,
                }}
                minSize={{width: 10}}
            />
        </>
    );
}

export default RowRect;
