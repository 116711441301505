import React, {useLayoutEffect, useRef, useState} from "react";
import {Container} from "react-bootstrap";
import HowToSetupCard from "../../../../../../components/app-specific/how-to-setup-card";
import {landingViewScrollAnimationOptions} from "../../index";
import {ReactComponent as RegisterYourBusiness} from "../../../../../../../assets/images/views/public-views/landing/register-your-business.svg";
import {ReactComponent as DefineYourWarehouse} from "../../../../../../../assets/images/views/public-views/landing/define-your-warehouse-structure.svg";
import {ReactComponent as AddSpireInfo} from "../../../../../../../assets/images/views/public-views/landing/add-spire-inforamation.svg";
import {ReactComponent as InviteOperators} from "../../../../../../../assets/images/views/public-views/landing/invite-operators.svg";
import {useTranslation} from "react-i18next";


const LandingViewStepsSection = ({sections}) => {
    const {t} = useTranslation();
    const [howToSetupHeight, setHowToSetupHeight] = useState(0);
    /**@type {React.MutableRefObject<HTMLElement>}*/
    const howToSetupRef = useRef();

    const steps = [
        {
            ...t('views.public.landing.steps.data.0', {returnObjects: true}),
            icon: <RegisterYourBusiness/>,
            animationType: landingViewScrollAnimationOptions.slideLeftAnimation,
        },
        {
            ...t('views.public.landing.steps.data.1', {returnObjects: true}),
            icon: <DefineYourWarehouse/>,
            animationType: landingViewScrollAnimationOptions.slideRightAnimation,
        },
        {
            ...t('views.public.landing.steps.data.2', {returnObjects: true}),

            icon: <AddSpireInfo/>,
            animationType: landingViewScrollAnimationOptions.slideLeftAnimation,
        },
        {
            ...t('views.public.landing.steps.data.3', {returnObjects: true}),
            icon: <InviteOperators/>,
            animationType: landingViewScrollAnimationOptions.slideRightAnimation,
        },
    ];

    /**
     * Listens to the changes in the howToSetup text and with each change:
     * Sets the height of the background container to be in sync with the text
     */
    useLayoutEffect(() => {
        if (!howToSetupRef?.current) return;
        const newHeight = howToSetupRef.current.getBoundingClientRect().height;
        if (newHeight !== howToSetupHeight) {
            setHowToSetupHeight(newHeight);
        }
    }, [howToSetupRef.current])

    return (
        <>
            <div
                className={'landing-view-steps-container'}
                id={sections.steps.hash}
            >
                <div className={'second-floating-background-container'}
                     style={{minHeight: (howToSetupHeight ?? 0) + 50}}>
                </div>
                <Container>
                    <div style={{minHeight: (howToSetupHeight ?? 0) + 50}}
                         className={'d-flex flex-column justify-content-center'}>
                        <h4 className={'title'} ref={howToSetupRef}>
                            {t('views.public.landing.steps.title.black')}
                        </h4>
                    </div>
                    {
                        steps?.map((step, index) => (
                            <HowToSetupCard
                                key={index}
                                textFirst={index % 2 === 0}
                                step={step}
                                animationOptions={landingViewScrollAnimationOptions}
                            />
                        ))
                    }
                </Container>
            </div>
        </>
    );
}

export default LandingViewStepsSection;
