import React, {useEffect, useRef, useState} from 'react';
import mapBoxGl from 'mapbox-gl/dist/mapbox-gl';
import * as MapboxGlGeocoder from '@mapbox/mapbox-gl-geocoder';
import {ReactComponent as MapPinLocation} from '../../../../assets/images/views/public-views/register/map_pin_location.svg';
import Api from "../../../../core/services/api_service";
import useIsMounted from "../../../hooks/use-is-mounted";

/**
 * @typedef {{country: string, address: string, province: string, postalCode: string, companyName: string}} MapboxAddress
 */

// set the access token of mapboxGL
mapBoxGl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
// the default values used for the mapbox
const mapboxOptions = {
    style: 'mapbox://styles/mapbox/streets-v11',
    center: [
        45, 45,
    ],
    zoom: 1,
}

const MapBox = ({onAddressSelect, getStartingAddress}) => {
    const mapContainer = useRef(null);
    const [markers, setMarkers] = useState([]);
    /**@type {React.MutableRefObject<mapBoxGl.Map>}*/
    const map = useRef();
    const isMounted = useIsMounted();

    /**
     * As soon as the component mounts:
     * Creates the map and adds the search, zoomIn, zoomOut, and current functionalities
     */
    useEffect(() => {
        if (!mapContainer.current || map.current) return;
        map.current = createMapAndMapControls();
        navigateToStartingAddress(map.current);
    }, [mapContainer.current])


    /**
     * Creates a new MapboxGL and adds controls to it.
     * @return {mapBoxGl.Map}
     */
    const createMapAndMapControls = () => {
        const newMap = new mapBoxGl.Map({
            container: mapContainer.current,
            ...mapboxOptions
        });
        newMap.addControl(
            new mapBoxGl.NavigationControl(),
            'top-left'
        );
        newMap.addControl(
            new mapBoxGl.GeolocateControl({positionOptions: {enableHighAccuracy: true}, trackUserLocation: true}),
            'top-left'
        );
        newMap.addControl(
            new MapboxGlGeocoder({
                accessToken: mapBoxGl.accessToken,
                marker: false,
                mapboxgl: mapBoxGl
            })
        );
        return newMap;
    }

    /**
     * Navigates the center of the mapbox to the provided starting address if it exists.
     * @param {mapBoxGl.Map} map
     */
    const navigateToStartingAddress = (map) => {
        const address = getStartingAddress();
        if (!address.address?.length) return;
        Api.getMapboxCoordinates(address.address, mapBoxGl.accessToken, address.country).then((response) => {
            if (!isMounted()) return;
            if (response?.resultFlag) {
                if (!response.data?.features?.length) {
                    return;
                }
                const center = response.data.features[0].center;
                map.on('load', () => {
                    map.flyTo({
                        center,
                        zoom: 15,
                        bearing: 0,
                        essential: true,
                    });
                })
                createMarker(center, map);
            }
        })
    }


    /**
     * Fetches the address based on the given coordinates
     * @param {number} lng
     * @param {number} lat
     */
    const fetchAddressData = (lng, lat) => {
        Api.getMapboxMarkerAddress(lng, lat, mapBoxGl.accessToken).then((response) => {
            if (!isMounted()) return;
            if (response?.resultFlag) {
                const foundAddress = parseMapboxAddress(response.data);
                onAddressSelect(foundAddress);
            }
        })
    }

    /**
     * Parses the returned data of mapbox address api
     * @param {{features: {place_type: string[],properties: {address: string}, text: string, place_name: string,}[]}} data
     * @return {MapboxAddress}
     */
    const parseMapboxAddress = (data) => {
        const foundAddress = {
            address: '',
            province: '',
            country: '',
            postalCode: ''
        };
        data.features.forEach((item) => {
            switch (item.place_type[0]) {
                case 'address':
                    //TODO: make it work not only for english but for all the languages
                    let streetName = item.text;
                    const lengthOfStreetNumber = item.place_name.indexOf(item.text);
                    if (lengthOfStreetNumber !== -1) {
                        streetName = item.place_name.substring(0, lengthOfStreetNumber) + streetName;
                    }
                    foundAddress.address = streetName;
                    break;
                case 'postcode':
                    foundAddress.postalCode = item.text;
                    break;
                case 'region':
                    foundAddress.province = item.text;
                    break;
                case 'country':
                    foundAddress.country = item.text;
                    break;
                case 'poi':
                    foundAddress.address = item.properties.address;
                    foundAddress.companyName = item.text;
                    break;
                default:
                    break;
            }
        });
        return foundAddress;
    }

    /**
     * Retrieves the coordinates based on the location of the marker on the map and adds a new marker to the markers
     * of the mapbox
     *
     * If the onAddressSelect callback exists, fetches the address of the clicked marker and sends it to the callback
     */
    const onMarkerClick = () => {
        const {lng, lat} = map.current.getCenter();
        createMarker([lng, lat], map.current);
        if (onAddressSelect) {
            fetchAddressData(lng, lat);
        }
    };

    /**
     * Creates a marker with a specific center location in mapbox
     * @param {[number, number]} center
     * @param {mapBoxGl.Map} map
     */
    const createMarker = (center, map) => {
        const marker = new mapBoxGl.Marker()
            .setLngLat(center)
            .addTo(map);
        marker.getElement().classList.add('mapbox-marker')
        marker.getElement()?.addEventListener('click', () => {
            map.flyTo({
                center,
                zoom: 15,
                bearing: 0,
                essential: true,
            });
        })
        markers.forEach(e => e.remove());
        setMarkers([]);
        setMarkers([marker]);
    }


    return (
        <div className='mapbox'>
            <div ref={mapContainer} className='map-container'/>
            <MapPinLocation className="marker" onClick={onMarkerClick}/>
        </div>
    )
}

export default MapBox;
