import {InventorySchemaVisualizerDepths, InventorySchemaVisualizerModes} from "../../../core/models/constants/enums";
import {
    IAddRectFunc,
    ICanvasData,
    IInternalCanvasData,
    IInventorySchemaVisualizerVisualConfig,
    IOnRowClickedFunc,
    IRemoveRectFunc,
    IRotateRectFunc
} from "../../types";
import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import Konva from "konva";
import {Stage} from "react-konva";
import SnappyLayer from "./snappy-layer";
import AisleRect from "./warehouse-rects/aisle";
import TooltipLayer from "./tooltip-layer";
import Utils from "../../../core/services/utils";
import {debounce} from "lodash";

export type IInventorySchemaVisualizerCanvasProps = {
    mode: InventorySchemaVisualizerModes,
    depth: InventorySchemaVisualizerDepths,
    data: IInternalCanvasData,
    changeScale: (a: number) => void,
    setSelectedEntityData: (a: IInventorySchemaVisualizerVisualConfig['selectedEntityData'] | undefined) => void,
    visualConfig: IInventorySchemaVisualizerVisualConfig,
    setData: React.Dispatch<React.SetStateAction<ICanvasData>>,
    addRect: IAddRectFunc,
    removeRect: IRemoveRectFunc,
    rotateRect: IRotateRectFunc,
    onRowClicked: IOnRowClickedFunc,
}

const PADDING = 500;

const InventorySchemaVisualizerCanvas = forwardRef<Konva.Stage | undefined, IInventorySchemaVisualizerCanvasProps>
(({
      mode,
      depth,
      data,
      visualConfig,
      changeScale,
      setSelectedEntityData,
      setData,
      addRect,
      removeRect,
      rotateRect,
      onRowClicked,
  }, ref) => {
    const [stage, setStage] = useState<Konva.Stage | undefined>();
    const [availableSize, setAvailableSize] = useState({width: 0, height: 0});
    const canvasContainerRef = useRef<HTMLDivElement>(null);
    const gestureScaleRef = useRef(1);
    const SNAP_RECT_NAME = useMemo(() => 'snappy-rectangle', []);

    const canRenderCanvas = useMemo(() => Object.keys(availableSize).length > 0, [availableSize]);

    const totalEntitiesNumber = useMemo(() => {
        return data.aisles.reduce((num, aisle) => {
            return num + aisle.children.reduce((num, section) => {
                return num + section.children.length;
            }, aisle.children.length)
        }, data.aisles.length);

    }, [data])

    /**
     * Exposes the stage of the canvas to the parent component of this component.
     */
    useImperativeHandle(ref, () => stage, [stage]);

    /**
     * Groups the provided entities based on their position in the list and their className.
     *  * this method specifically groups the entities that exist as the children of a stage's layer.
     */
    const groupEntities = useCallback((list: Array<any>): Array<any> => {
        return list
                ?.reduce((aisles, currentNode) => {
                    switch (currentNode.className) {
                        case "Rect":
                            if (!!currentNode.attrs.data)
                                aisles.push({
                                    attrs: currentNode.attrs,
                                });
                            break;
                        case "Group":
                            if (!!aisles[aisles.length - 1]?.attrs)
                                aisles[aisles.length - 1].children = groupEntities(currentNode.children);
                            break;
                        default:
                            break;
                    }
                    return aisles;
                }, [])
            ?? [];
    }, []);

    /**
     * Saves the data-object of the canvas's stage locally.
     */
    const saveData = useCallback((stage: Konva.Stage) => {
        const canvasDataRaw = stage!.toObject();
        const nestedEntities = groupEntities(canvasDataRaw.children?.[0]?.children as Array<any>)
        setData(prevState => ({
            ...prevState,
            aisles: prevState.aisles.map(prevAisle => {
                const newAisle = nestedEntities.find(aisle => Utils.deepEqual(aisle.attrs.data, prevAisle.data));
                return ({
                    ...prevAisle,
                    ...(newAisle
                            ? {
                                height: newAisle.attrs.height,
                                rotated: newAisle.attrs.rotated,
                                width: newAisle.attrs.width,
                                x: newAisle.attrs.x ?? 0,
                                y: newAisle.attrs.y ?? 0,
                            }
                            : {}
                    ),
                    children: prevAisle.children.map(prevSection => {
                        const newSection = newAisle?.children?.find((section: any) => Utils.deepEqual(section.attrs.data, prevSection.data));
                        return ({
                            ...prevSection,
                            ...(newSection
                                    ? {
                                        height: newSection.attrs.height,
                                        rotated: newSection.attrs.rotated,
                                        width: newSection.attrs.width,
                                        x: newSection.attrs.x ?? 0,
                                        y: newSection.attrs.y ?? 0,
                                    }
                                    : {}
                            ),
                            children: prevSection.children.map(prevRow => {
                                const newRow = newSection?.children?.find((row: any) => Utils.deepEqual(row.attrs.data, prevRow.data));
                                return ({
                                    ...prevRow,
                                    ...(newRow
                                            ? {
                                                height: newRow.attrs.height,
                                                rotated: newRow.attrs.rotated,
                                                width: newRow.attrs.width,
                                                x: newRow.attrs.x ?? 0,
                                                y: newRow.attrs.y ?? 0,
                                            }
                                            : {}
                                    )
                                });
                            })
                        });
                    })
                });
            }),
        }));
    }, [groupEntities, setData])

    /**
     * With each change in the [totalEntitiesNumber] and [mode]:
     * - if the mode is not edit, does nothing
     * - for each of the warehouse rects, adds an event listener that would update the local state with each change in the canvas rects'
     * attributes.
     */
    useEffect(() => {
        if (!stage || mode === InventorySchemaVisualizerModes.view)
            return;
        const eventsList: Array<{ target: Konva.Node, name: string, handler: () => void }> = [];
        const rects = stage.find((e: Konva.Node) => e.className === 'Rect' && Array.isArray(e.getAttr('data')));
        const handler = debounce(() => saveData(stage), 200);
        for (const rect of rects) {
            eventsList.push({target: rect, name: 'transform', handler: handler});
            eventsList.push({target: rect, name: 'widthChange', handler: handler});
            eventsList.push({target: rect, name: 'heightChange', handler: handler});
            eventsList.push({target: rect, name: 'xChange', handler: handler});
            eventsList.push({target: rect, name: 'yChange', handler: handler});
        }
        for (const {target, name, handler} of eventsList) {
            target.on(name, handler);
        }
        return () => {
            for (const {target, name, handler} of eventsList) {
                target.off(name, handler);
            }
        }
    }, [stage, totalEntitiesNumber, mode, saveData])

    /**
     * Changes the page scale of the template with each mouse movement while the meta key is pressed down.
     */
    const changePageScaleOnWheel = useCallback((e: WheelEvent) => {
        if (!(e.metaKey || e.ctrlKey))
            return;
        e.preventDefault();
        e.stopPropagation();
        changeScale((Math.abs(e.deltaY) * (e.ctrlKey ? 0.1 : 0.1)) * (e.deltaY < 0 ? 1 : -1));
    }, [changeScale])

    /**
     * Changes the page scale of the template with each mouse movement while the meta key is pressed down.
     * @type {function(Event & {scale: number}): void}
     */
    const changePageScaleOnGesture = useCallback((e) => {
        e.preventDefault();
        const change = e.scale - gestureScaleRef.current;
        gestureScaleRef.current = e.scale;
        changeScale((Math.abs(change)) * (change < 0 ? -1 : 1) * 0.001);
    }, [changeScale])

    /**
     * Resets the gesture's scale value.
     * @type {(function(Event): void)}
     */
    const resetGestureScale = useCallback((e) => {
        e.preventDefault()
        gestureScaleRef.current = 1;
    }, []);

    /**
     * As soon as the component mounts:
     * - attaches a resize-observer for determining the size of the canvas.
     */
    useEffect(() => {
        const element = canvasContainerRef.current;
        if (!element)
            return;
        const contentRect = element.getBoundingClientRect();
        setAvailableSize({
            width: contentRect.width,
            height: contentRect.height,
        });
        const observer = new ResizeObserver((entries) => {
            setAvailableSize({
                width: entries[0].contentRect.width,
                height: entries[0].contentRect.height,
            })
        });
        observer.observe(element);
    }, [])

    /**
     * With each change in [canChangeScale] value:
     * - if canChangeScale, then attaches a mouse-wheel event listener that changes the scale of the canvas with wheel movement.
     */
    useEffect(() => {
        if (!canvasContainerRef.current)
            return;
        const container = canvasContainerRef.current;
        container.addEventListener('wheel', changePageScaleOnWheel, false)
        window.addEventListener('gesturestart', resetGestureScale)
        window.addEventListener('gesturechange', changePageScaleOnGesture)
        window.addEventListener('gestureend', resetGestureScale)
        return () => {
            container.removeEventListener('wheel', changePageScaleOnWheel, false)
            window.removeEventListener('gesturestart', resetGestureScale)
            window.removeEventListener('gesturechange', changePageScaleOnGesture)
            window.removeEventListener('gestureend', resetGestureScale)
        }
    }, [changePageScaleOnWheel, changePageScaleOnGesture, resetGestureScale])

    /**
     * De-selects the selected-entity when an empty area of the stage is clicked.
     */
    const removeSelectedEntityData = useCallback<(e: Konva.KonvaEventObject<MouseEvent>) => void>((e) => {
        if (e.target === e.target.getStage()) {
            setSelectedEntityData(undefined);
        }
    }, [setSelectedEntityData]);

    /**
     * Adds a new warehouse entity based on the target of the current event (the clicked rect) and the currently selected entry to be added.
     * * checks to see if the entry can be added or not.
     * * adjusts the position of the added entity to be bounded by its parent
     */
    const addNewWarehouseEntity = useCallback<(e: Konva.KonvaEventObject<MouseEvent>) => void>((e) => {
        if (!data.selectedEntryToBeAdded || !stage)
            return;
        const parent = e.target;
        const point = parent.getRelativePointerPosition();
        switch (data.selectedEntryToBeAdded.length) {
            case 1: {
                // adding an aisle
                if (parent.getClassName() !== 'Stage')
                    return;
                const width = 100;
                const height = 100;
                if (point.x - (width / 2) < 0) {
                    point.x = (width / 2)
                }
                if (point.y - (height / 2) < 0) {
                    point.y = (height / 2)
                }
                if (point.x + (width / 2) > parent.width()) {
                    point.x = parent.width() - (width / 2);
                }
                if (point.y + (height / 2) > parent.height()) {
                    point.y = parent.height() - (height / 2);
                }
                addRect(data.selectedEntryToBeAdded, {
                    x: point.x - (width / 2),
                    y: point.y - (height / 2),
                    width: width,
                    height: height,
                    rotated: false,
                }, parent.getAttr('data'));
                break;
            }
            case 2: {
                // adding a section
                if (!(parent.getClassName() === 'Rect' && parent.hasName(data.selectedEntryToBeAdded[0])))
                    return
                const width = 40;
                const height = parent.height();
                if (point.x - (width / 2) < 0) {
                    point.x = (width / 2)
                }
                if (point.x + (width / 2) > parent.width()) {
                    point.x = parent.width() - (width / 2);
                }
                addRect(data.selectedEntryToBeAdded, {
                    x: point.x - (width / 2),
                    y: 0,
                    width: width,
                    height: height,
                    rotated: false,
                }, parent.getAttr('data'));
                break
            }
            case 3: {
                // adding a row
                if (!(parent.getClassName() === 'Rect' && parent.hasName(data.selectedEntryToBeAdded[1])))
                    return
                const width = parent.width();
                const height = 40;
                if (point.y - (height / 2) < 0) {
                    point.y = (height / 2)
                }
                if (point.y + (height / 2) > parent.height()) {
                    point.y = parent.height() - (height / 2);
                }
                addRect(data.selectedEntryToBeAdded, {
                    x: 0,
                    y: point.y - (height / 2),
                    width: width,
                    height: height,
                    rotated: false,
                }, parent.getAttr('data'));
                break
            }
            default:
                return;
        }
    }, [addRect, data.selectedEntryToBeAdded, stage]);

    /**
     * Removes the selected entity-data and adds a new warehouse entity if possible.
     */
    const onStageClicked = useCallback<(e: Konva.KonvaEventObject<MouseEvent>) => void>((e) => {
        removeSelectedEntityData(e);
        addNewWarehouseEntity(e);
    }, [removeSelectedEntityData, addNewWarehouseEntity])

    /**
     * Repositions the current stage as the user scrolls the container div.
     */
    const repositionStage = useCallback(() => {
        if (!canvasContainerRef.current || !stage)
            return;
        const dx = canvasContainerRef.current.scrollLeft - PADDING;
        const dy = canvasContainerRef.current.scrollTop - PADDING;
        stage.container().style.transform =
            'translate(' + dx + 'px, ' + dy + 'px)';
        stage.x(-dx);
        stage.y(-dy);
    }, [stage])

    /**
     * As soon as the component mounts:
     * - repositions the stage based on the current scroll position of the container.
     */
    useEffect(() => {
        repositionStage();
    }, [repositionStage])


    /**
     * Changes the cursor of the stage's ui when the selectedEntryToBeAdded is an aisle.
     */
    useEffect(() => {
        if (!stage)
            return;
        if (data.selectedEntryToBeAdded?.length === 1) {
            stage.container().style.cursor = 'copy';
        } else {
            stage.container().style.cursor = 'default';
        }
    }, [data.selectedEntryToBeAdded, stage])

    const containerSize = useMemo(() => {
        const width = Math.max(3000, availableSize.width);
        const height = Math.max(3000, availableSize.height);
        return ({
            width: Math.max(width, width * visualConfig.scale),
            height: Math.max(height, height * visualConfig.scale),
        })
    }, [availableSize.height, availableSize.width, visualConfig.scale])

    const stageSize = useMemo(() => {
        return ({
            width: availableSize.width + PADDING * 2,
            height: availableSize.height + PADDING * 2,
        });
    }, [availableSize.height, availableSize.width])

    return (
        <>
            <div
                ref={canvasContainerRef}
                className={'inventory-schema-visualizer-canvas'}
                onScroll={repositionStage}
            >
                <div
                    className={'inventory-schema-visualizer-canvas-inner'}
                    style={containerSize}
                >
                    <Stage
                        width={stageSize.width}
                        height={stageSize.height}
                        scaleX={visualConfig.scale}
                        scaleY={visualConfig.scale}
                        ref={(stage) => setStage(stage as Konva.Stage)}
                        onClick={onStageClicked}
                        onTab={onStageClicked}
                    >
                        {
                            stage && canRenderCanvas &&
                            <SnappyLayer
                                padding={5}
                                stage={stage}
                                stageSize={containerSize}
                                draggableSelector={SNAP_RECT_NAME}
                            >
                                {
                                    data.aisles.map(aisle => (
                                        <AisleRect
                                            key={aisle.key}
                                            name={`${aisle.name} ${SNAP_RECT_NAME}`}
                                            aisle={aisle}
                                            mode={mode}
                                            stage={stage}
                                            selectedRectData={data.selectedRectData}
                                            setSelectedRectData={setSelectedEntityData}
                                            remove={removeRect}
                                            rotate={rotateRect}
                                            onRowClicked={onRowClicked}
                                        />
                                    ))
                                }
                                <TooltipLayer
                                    stage={stage}
                                    depth={depth}
                                    data={data.aisles}
                                />
                            </SnappyLayer>
                        }
                    </Stage>
                </div>
            </div>
        </>
    );
})


export default InventorySchemaVisualizerCanvas;
