import {Form as FormRff} from 'react-final-form';
import React from "react";

/**
 * @typedef {import("react-final-form/typescript/index").FormApi} formApi
 */

/**
 * Default Form Subscription
 * @type {{initialValues: boolean, submitting: boolean, values: boolean}}
 */
export const defaultFormSubscription = {submitting: true, values: true, initialValues: true, submitErrors: true};

/**
 *
 * @param {any} subscription the subscription of the FormRff
 * @param {string} className applied to the inner <form /> tag
 * @param autoFillValues applied to the inner <form /> tag
 * @param {import("react-final-form/typescript/index").FormProps} props
 * @param {function(import("react-final-form/typescript/index").RenderableProps)} render
 * @return {JSX.Element}
 */
const Form = ({
                  subscription = defaultFormSubscription,
                  render,
                  autoFillValues,
                  className,
                  ...props
              }) => {
    return (
        <FormRff
            subscription={subscription}
            key={subscription}
            {...props}
            render={({handleSubmit, ...props}) => {
                return <form
                    className={className}
                    onSubmit={handleSubmit}
                    noValidate
                    autoComplete={autoFillValues}
                >
                    {render(props)}
                </form>
            }}
        />

    );
}

export default Form;

