import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import classnames from "classnames";
import {Popover} from "@material-ui/core";
import {ReactComponent as Arrow} from "../../../../assets/images/dropdown-icon.svg";
import {useTranslation} from "react-i18next";
import useIsMounted from "../../../hooks/use-is-mounted";
import {createUUId, deepEqual} from "../../../../core/services/utils";

/**
 * @typedef {{title: string, id: any, disabled: boolean}} ButtonDropdownOption
 */


/**
 * @param {ButtonDropdownOption} options
 * @param onOptionSelected
 * @param buttonProps
 * @param popoverProps
 * @param {'outlined' | 'standard' | 'text'} variant
 * @param title
 * @param {'right'| 'left'} arrowPlacement
 * @return {JSX.Element}
 */
const ButtonDropdown = ({
                            options,
                            onOptionSelected,
                            title,
                            buttonProps,
                            popoverProps,
                            variant,
                            arrowPlacement = 'left'
                        }) => {
    const [popover, setPopover] = useState()
    const [_popover, _setPopover] = useState()
    const [width, setWidth] = useState()
    const {ready} = useTranslation()
    const isMounted = useIsMounted()
    const optionsRef = useRef({});
    const titleRef = useRef({});
    const buttonRef = useRef(createUUId())

    /**
     * Listens to the changes in popover and with each change:
     * sets the _popover state of this component with a little delay to wait for the animation of resizing
     */
    useEffect(() => {
        if (!popover) {
            _setPopover(popover)
        } else {
            setTimeout(() => {
                if (!isMounted()) return
                _setPopover(popover)
            }, 200)
        }
    }, [popover])

    /**
     * Listens to the changes in the options of the dropdown and with each change:
     * measures the length of its button to be at least the length of the biggest item in its menu
     */
    useLayoutEffect(() => {
        if (!ready) return
        if (shouldMeasureLength(!!popover)) {
            measureMinLengthOfButton(!!popover)
        }
    }, [options, ready, popover])

    /**
     * Determines whether the length of the button should be measured.
     * if not, sets the length to a saved width from previous measures.
     * @param {boolean} popoverOpen
     * @return {boolean}
     */
    const shouldMeasureLength = (popoverOpen) => {
        if (popoverOpen) {
            if (deepEqual(optionsRef.current?.comparable, options)) {
                setWidth(optionsRef.current.width)
                return false;
            }
            optionsRef.current = {
                ...optionsRef.current,
                comparable: options,
            }
        } else {
            if (!React.isValidElement(title) && deepEqual(titleRef.current.comparable, title)) {
                setWidth(titleRef.current.width)
                return false;
            }
            titleRef.current = {
                ...titleRef.current,
                comparable: title,
            }
        }
        return true
    }

    /**
     * Measures the minimum required length of this button based on the length of its options.
     * creates placeholder elements and measures them through their style properties. then removes the elements.
     */
    const measureMinLengthOfButton = (basedOnOptions = false) => {
        const _div = document.createElement('div');
        _div.style.width = 'fit-content'
        _div.style.position = 'absolute'
        _div.style.visibility = 'hidden'
        if (basedOnOptions) {
            for (const option of options) {
                _div.appendChild(createText(option.title ?? ''))
            }
        } else {
            _div.appendChild(createText(title))
        }
        document.body.appendChild(_div);
        const width = _div.getBoundingClientRect().width + 10;
        if (!width) return
        if (basedOnOptions) {
            optionsRef.current.width = width
        } else {
            titleRef.current.width = width
        }
        setWidth(width)
        document.body.removeChild(_div)
    }

    /**
     * Creates a text element with the given text.
     * used for measuring the length of the text.
     * @param {string} _text
     * @return {HTMLParagraphElement}
     */
    const createText = (_text) => {
        const text = document.createElement('p')
        text.style.fontSize = '15px';
        text.style.padding = '10px 1rem';
        text.style.fontWeight = '500';
        text.style.minHeight = '55px';
        text.innerHTML = _text ?? '';
        return text;
    }


    return (
        <>
            <button {...buttonProps}
                    style={{
                        ...(width ? {minWidth: width} : {}
                        )
                    }}
                    id={buttonRef.current}
                    onClick={(e) => setPopover(e.currentTarget)}
                    className={classnames('button-dropdown button', buttonProps?.className, {
                        'active': !!popover,
                        'outlined': (!popover) || (!!popover && variant === 'standard'),
                        'text': variant === 'text',
                        '': !!popover && variant === 'outlined',
                    })}>
                <div className={'d-flex align-items-center'}>
                    {arrowPlacement === 'left' && <Arrow className={'arrow'}/>}

                    <div className={'d-flex align-items-center justify-content-center flex-grow-1'}>
                        {title ?? ''}
                    </div>
                    {arrowPlacement === 'right' && <Arrow className={'arrow'}/>}
                </div>
            </button>
            <Popover
                id={!!_popover ? 'page-sizes' : undefined}
                classes={{paper: 'button-dropdown-popover'}}
                open={!!_popover}
                onClose={() => setPopover(null)}
                anchorReference={'anchorEl'}
                anchorEl={_popover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                {...popoverProps}
            >
                <div className={'content'}
                     style={{
                         minWidth: document.getElementById(buttonRef.current)?.clientWidth ?? 0
                     }}>
                    {
                        options?.map(option => (
                            <div
                                key={option?.id}
                                className={classnames('option')}
                                onClick={() => !options.disabled && onOptionSelected(option, setPopover)}
                            >
                                {option?.title ?? '--'}
                            </div>
                        ))
                    }
                </div>
            </Popover>
        </>
    )
}

export default ButtonDropdown;
