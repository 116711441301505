import {useTranslation} from "react-i18next";
import DropZone from "../../../../../components/base/drop-zone";
import React, {useContext, useState} from "react";
import {ReactComponent as DefaultUserImage} from "../../../../../../assets/images/views/edit-profile/default-image.svg";
import classnames from "classnames";
import {Fade} from "@material-ui/core";
import {InputFileAcceptMimes} from "../../../../../../core/constants/enums";
import {PanelContext} from "../../../../../contexts/panel";
import LazyImage from "../../../../../components/base/lazy-image";

const UserPicture = ({data, setData, loading}) => {
    const [{dragging}, setPanelContext] = useContext(PanelContext)
    const [tempImage, setTempImage] = useState([null, null]);
    const [submitting, setSubmitting] = useState(false);
    const [canSubmit, setCanSubmit] = useState(false);
    const {t} = useTranslation();
    const translation = t("views.panel.editProfile.userPicture", {returnObjects: true});

    /**
     * Sets the temporary image for the preview purposes.
     * @param {any[]} parsedImages
     */
    const setParsedImage = (parsedImages) => {
        setTempImage(prevState => [parsedImages[0], prevState[1]]);
    }

    /**
     * Sets the temporary image file for uploading porposes
     * @param {any[]} files
     */
    const setImageFile = (files) => {
        setCanSubmit(true);
        setTempImage(prevState => [prevState[0], files[0]]);
    };

    /**
     * Updates the profile image of the user form the server and sets the confirmed image as the current image in
     * the state
     */
    const updateProfileImageOfUser = () => {
        setSubmitting(true);
        // TODO: call the api to upload the new photo
        //TODO: use the tempImage[1] for accessing the file,
        setData(tempImage[0]);
        setTempImage([null, null]);
        setSubmitting(false);
        setCanSubmit(false);
    }

    return (
        <div className='user-picture panel-card'>
            <h2 className='title'>
                {translation?.title ?? ''}
            </h2>
            <div className='mt-5 d-flex align-items-center justify-content-center'>
                <DropZone
                    setFiles={setImageFile}
                    setParsedImages={setParsedImage}
                    loading={loading}
                    uploading={submitting}
                    openOnClick={true}
                    accept={[InputFileAcceptMimes.images]}
                    dropZoneClassName={classnames('user-picture-drop-zone', {'new-image': canSubmit})}
                    multiple={false}
                    id={'profile-picture'}
                    dragging={dragging}
                    notAcceptedDurationInMs={2000}
                    endDragging={() => setPanelContext(prevState => ({...prevState, dragging: false}))}
                >
                    {(_, highlight, notAccepted) => (
                        <>
                            <div className='d-flex justify-content-center'>
                                <div className={classnames('img-container')}>
                                    <div className={'background'}>
                                        <LazyImage
                                            src={tempImage[0] ?? data}
                                            placeholder={<DefaultUserImage/>}
                                            alt={'user profile'}
                                        />
                                    </div>
                                </div>
                            </div>
                            <Fade in={highlight || dragging || notAccepted}>
                                <div className='mt-2 call-to-action'>
                                    {notAccepted
                                        ? translation?.actions?.dnd?.notAccepted ?? ''
                                        : translation?.actions?.dnd?.text ?? ''}
                                </div>
                            </Fade>
                        </>
                    )}
                </DropZone>
            </div>
            <div className='d-flex justify-content-end align-items-end mt-4'>
                <button
                    className='button primary px-5'
                    onClick={updateProfileImageOfUser}
                    type={'button'}
                    disabled={loading || submitting || !canSubmit}
                >
                    {translation?.actions?.save ?? ''}
                </button>
            </div>
        </div>
    )
}

export default UserPicture;
