import React, {useEffect, useState} from "react";
import * as Yup from 'yup'
import ValidateMessages from "../../../../../core/constants/texts/vallidate-messages";
import {makeRequired, makeValidate} from "mui-rff";
import {useTranslation} from "react-i18next";
import Form from "../../../../components/base/form";
import {Col} from "react-bootstrap";
import {FormInput} from "../../../../components/base/input";
import {PasswordFormInput} from "../../../../components/base/input/password";
import {ReactComponent as Note} from "../../../../../assets/images/warning.svg";
import useIsMounted from "../../../../hooks/use-is-mounted";

const formNames = {
    serverIp: 'serverIp',
    username: "username",
    password: "password"
}

const schema = Yup.object().shape({
    [formNames.serverIp]: Yup.string().nullable().required(ValidateMessages.required),
    [formNames.username]: Yup.string().nullable().required(ValidateMessages.required),
    [formNames.password]: Yup.string().nullable().required(ValidateMessages.required)
})

const SpireSettingsView = () => {
    const [loading, setLoading] = useState(true);
    const [initialValues, setInitialValues] = useState({})
    const validate = makeValidate(schema)
    const required = makeRequired(schema)
    const isMounted = useIsMounted();
    const {t} = useTranslation()
    const translations = t("views.panel.settings.spire", {returnObjects: true});
    const colSpans = {
        xs: 12,
        sm: 9,
        md: 7,
        lg: 6,
    }

    /**
     * As soon as the component mounts, fetches the user's spire config settings.
     */
    useEffect(() => {
        getSpireSettings();
    }, [])

    /**
     * Fetches the spire configurations of the user from the server to populate their inputs
     */
    const getSpireSettings = () => {
        //  TODO: call the api for getting the spire config
        setLoading(true);
        const response = {
            data: {
                serverIp: 'ServerIP',
                username: "Abbase Bo Azar",
            },
        }
        setInitialValues(response.data)
        setLoading(false)
    }

    /**
     * Saves the spire configurations of the user in the server.
     * @param values
     * @param form
     * @param submitErrors
     */
    const saveSpireConfiguration = async (values, form, submitErrors) => {
        const forApi = {
            ...values,
        }
        //  TODO: call the api for saving the spire config
        setInitialValues(values)
        return false;
    }


    return (
        <>
            <div className={'spire'}>
                <p className={'title'}>
                    {translations?.title ?? ''}
                </p>
                <p className={'sub-title'}>
                    {translations?.subtitle ?? ''}
                </p>
                {
                    loading
                        ? <div className={'d-flex flex-column loading spire-settings'}>
                            {
                                Array(3).fill(null).map((e, index) => (
                                    <Col {...colSpans} key={index} className={'input'}>
                                        <div/>
                                    </Col>
                                ))
                            }
                            <Col xs={12} className={'mt-4'}>
                                <div className={'note'}>
                                    <p>
                                        <Note className={'me-1'}/>
                                        {translations?.note ?? ''}
                                    </p>
                                </div>
                            </Col>
                            <Col {...colSpans} className={'action d-flex justify-content-end'}>
                                <div/>
                            </Col>
                        </div>
                        : <Form
                            onSubmit={saveSpireConfiguration}
                            className={'d-flex flex-column'}
                            validate={validate}
                            initialValues={initialValues}
                            render={({initialValues, values}) => (
                                <>
                                    <Col {...colSpans} className='mt-4'>
                                        <FormInput
                                            placeholder={translations?.form?.serverIp?.placeholder ?? ''}
                                            label={translations?.form?.serverIp?.title ?? ''}
                                            name={formNames.serverIp}
                                            required={required[formNames.serverIp]}
                                        />
                                    </Col>
                                    <Col {...colSpans} className='mt-4'>
                                        <FormInput
                                            placeholder={translations?.form?.username?.placeholder ?? ''}
                                            label={translations?.form?.username?.title ?? ''}
                                            name={formNames.username}
                                            required={required[formNames.username]}
                                        />
                                    </Col>
                                    <Col {...colSpans} className='mt-4'>
                                        <PasswordFormInput
                                            placeholder={translations?.form?.password?.placeholder ?? ''}
                                            label={translations?.form?.password?.title ?? ''}
                                            name={formNames.password}
                                            required={required[formNames.password]}
                                        />
                                    </Col>
                                    <Col xs={12} className={'mt-4'}>
                                        <div className={'note'}>
                                            <p>
                                                <Note className={'me-1'}/>
                                                {translations?.note ?? ''}
                                            </p>
                                        </div>
                                    </Col>
                                    <Col {...colSpans} className='d-flex mt-5 justify-content-end'>
                                        <button
                                            type={'submit'}
                                            className={'py-2 px-5 button primary'}
                                        >
                                            {translations?.actions?.save ?? ''}
                                        </button>
                                    </Col>
                                </>
                            )}
                        />
                }
            </div>
        </>
    )
}

export default SpireSettingsView;
