import React, {useContext} from "react";
import classnames from "classnames";
import {PanelContext} from "../../../contexts/panel";
import SidebarFooter from "./footer";
import SidebarHeader from "./header";
import SidebarBody from "./body";

const PanelSidebar = ({toggleCollapsed, toggleProfileExpanded}) => {
    const [{collapsed}] = useContext(PanelContext);

    return (
        <>
            <div className={classnames('sidebar', {collapsed})}>
                <SidebarHeader toggleCollapsed={toggleCollapsed} toggleProfileExpanded={toggleProfileExpanded}/>
                <SidebarBody/>
                <SidebarFooter/>
            </div>
        </>
    )
}

export default PanelSidebar;
