import {Text} from "react-konva";
import React, {useCallback, useEffect, useRef} from "react";
import Konva from "konva";
import {InventorySchemaVisualizerDepths} from "../../../../core/models/constants/enums";


export type ITooltipLayerProps = {
    stage: Konva.Stage,
    depth: InventorySchemaVisualizerDepths,
    data: any,
}

type IEventSubscriptions = Array<{ target: Konva.Node, name: string, handler: Function }>;

const TooltipLayer = ({stage, depth, data}: ITooltipLayerProps) => {
    const textRef = useRef<Konva.Text | null>();

    /**
     * Updates the position of the tooltip based on the pointer of the stage.
     */
    const updateTooltipPosition = useCallback<Konva.KonvaEventListener<Konva.Rect, MouseEvent>>((e) => {
        const stage = e.target.getStage();
        if (!stage)
            return;
        const absPosition = stage.getRelativePointerPosition();
        if (absPosition)
            textRef.current?.setAttrs({
                x: absPosition.x + 10,
                y: absPosition.y + 10,
            });
    }, [])

    /**
     * Hides the tooltip
     */
    const hideTooltip = useCallback<Konva.KonvaEventListener<Konva.Rect, MouseEvent>>(() => {
        textRef.current?.visible(false);
        textRef.current?.text('');
    }, []);

    /**
     * Shows the tooltip with its text.
     */
    const onNodeHovered = useCallback<Konva.KonvaEventListener<Konva.Node, MouseEvent>>((e) => {
        const targetTitle = e.target.getAttr('title');
        if (targetTitle) {
            textRef.current?.text(targetTitle);
            textRef.current?.visible(true);
        }
    }, []);

    /**
     * Detaches the event listeners from the rectangles that had the movement subscriptions.
     */
    const detachMovementListenerToRects = useCallback<(a: IEventSubscriptions) => void>((eventListeners: IEventSubscriptions) => {
        for (const {name, target, handler} of eventListeners) {
            target.off(name, handler);
        }
        eventListeners.splice(0, eventListeners.length);
    }, [])

    /**
     * Attaches the event listeners from the rectangles that had the movement subscriptions.
     */
    const attachMovementListenerToRects = useCallback<(a: IEventSubscriptions) => void>((eventListeners: IEventSubscriptions) => {
        detachMovementListenerToRects(eventListeners);
        const rects = stage.find((node: Konva.Node) => node.getClassName() === 'Rect' && !!node.getAttr('title') && node.name().includes(depth));
        for (const _rect of rects) {
            const rect = (_rect as Konva.Rect);
            eventListeners.push(...[
                {target: rect, name: 'mouseenter', handler: onNodeHovered},
                {target: rect, name: 'mousemove', handler: updateTooltipPosition},
                {target: rect, name: 'dragmove', handler: hideTooltip},
                {target: rect, name: 'dragend', handler: onNodeHovered},
                {target: rect, name: 'dragend', handler: updateTooltipPosition},
                {target: rect, name: 'mouseleave', handler: hideTooltip},
            ]);
        }
        for (const {name, target, handler} of eventListeners) {
            target.on(name, handler as any);
        }
    }, [depth, detachMovementListenerToRects, hideTooltip, onNodeHovered, stage, updateTooltipPosition])

    /**
     * With each change in the [depth], and [stage] props:
     * - attaches movement event listeners to the rects that are in the same depth as the [depth] value so the tooltip can be shown.
     */
    useEffect(() => {
        const eventListeners: Array<{ target: Konva.Node, name: string, handler: Function }> = [];
        attachMovementListenerToRects(eventListeners);
        return () => {
            detachMovementListenerToRects(eventListeners);
        }
    }, [attachMovementListenerToRects, detachMovementListenerToRects, stage, data]);

    return (
        <>
            <Text
                perfectDrawEnabled={false}
                ref={ref => textRef.current = ref}
                fontSize={12}
                fill={'black'}
                visible={false}
            />
        </>
    );
}

export default TooltipLayer;
