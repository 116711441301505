import React from "react";
import {toast} from 'react-toastify';
import ToastContent from "../../ui/components/app-specific/toast-content";

/**
 * @typedef {{
 *     title : string,
 *     message : string,
 *     buttons : any[],
 *     hideTicketSubmission : boolean,
 *     props : Partial<import('react-toastify').ToastOptions>,
 * }} ToastServiceProps
 */

/**
 * The interface for showing toasts in the application.
 */
class ToastService {

    /**
     * Shows an error toast for the user with the given props.
     *
     * @param {Partial<ToastServiceProps>} data
     * @return {import('react-toastify').Id}
     */
    static error(data) {
        const {
            title = '',
            message = '',
            hideTicketSubmission = true,
            buttons = [],
            props = {},
        } = data;

        return toast.error((props) =>
                <ToastContent
                    title={title}
                    message={message}
                    buttons={buttons}
                    toastProps={props}
                    hideTicketSubmission={hideTicketSubmission}
                />,
            {
                autoClose: hideTicketSubmission ? 5000 : false,
                closeOnClick: hideTicketSubmission,
                ...props
            },
        );
    }

    /**
     * Shows an error toast for the user with the given props.
     *
     * @param {Partial<ToastServiceProps>} data
     * @return {import('react-toastify').Id}
     */
    static success(data) {
        const {
            title = '',
            message = '',
            buttons = [],
            hideTicketSubmission = true,
            props = {},
        } = data;

        return toast.success((props) =>
                <ToastContent
                    title={title}
                    message={message}
                    buttons={buttons}
                    toastProps={props}
                    hideTicketSubmission={hideTicketSubmission}
                />,
            {
                ...props
            },
        );
    }
}

export default ToastService;




