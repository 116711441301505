import {IInventorySchemaVisualizerSidebarTreeViewDataEntry} from "../../../types";
import React, {useCallback, useMemo, useState} from "react";
import Utils from "../../../../core/services/utils";
import classnames from "classnames";
import {ArrowDropDown} from "@material-ui/icons";
import {Collapse} from "@material-ui/core";

export type IInventorySchemaVisualizerSidebarTreeViewProps = {
    selectedValue?: Array<string>,
    list: Array<IInventorySchemaVisualizerSidebarTreeViewDataEntry>
}

const InventorySchemaVisualizerSidebarTreeView = ({selectedValue, list}: IInventorySchemaVisualizerSidebarTreeViewProps) => {

    return (
        <div className={'inventory-schema-visualizer-sidebar-tree-view'}>
            {
                list.map(entry => (
                    <InventorySchemaVisualizerSidebarTreeEntry
                        key={entry.value.join('-')}
                        entry={entry}
                        depth={0}
                        selectedValue={selectedValue}
                    />
                ))
            }
        </div>
    );
}


export type IInventorySchemaVisualizerSidebarTreeEntryProps = {
    selectedValue?: Array<string>,
    depth: number,
    entry: IInventorySchemaVisualizerSidebarTreeViewDataEntry,
}

const InventorySchemaVisualizerSidebarTreeEntry = ({entry, depth, selectedValue}: IInventorySchemaVisualizerSidebarTreeEntryProps) => {
    const [childrenVisible, setChildrenVisible] = useState(false);
    const selected = useMemo(() => Utils.deepEqual(selectedValue, entry.value), [entry.value, selectedValue])

    /**
     * Toggles the visibility of this entry's children.
     * @type {function(): void}
     */
    const toggleChildrenVisible = useCallback(() => setChildrenVisible(prevState => !prevState), [])

    return (
        <>
            <div
                className={classnames('inventory-schema-visualizer-sidebar-tree-entry', {'selected': selected})}
                // @ts-ignore
                style={{'--depth': depth}}
            >
                <div className={'inventory-schema-visualizer-sidebar-tree-entry-content'}>
                    <div
                        className={'inventory-schema-visualizer-sidebar-tree-entry-content-collapse-icon'}
                        onClick={toggleChildrenVisible}
                    >
                        {
                            (!!entry.children?.length) &&
                            <ArrowDropDown className={classnames({'open': childrenVisible})}/>
                        }
                    </div>
                    <div
                        onClick={entry.onSelect}
                        className={'inventory-schema-visualizer-sidebar-tree-entry-content-inner'}
                    >
                        <div className={'inventory-schema-visualizer-sidebar-tree-entry-content-inner-icon'}>
                            {entry.icon}
                        </div>
                        <div className={'inventory-schema-visualizer-sidebar-tree-entry-content-inner-text'}>
                            {entry.title}
                        </div>
                    </div>
                </div>
                {
                    (!!entry.children?.length) &&
                    <Collapse in={childrenVisible} className={''}>
                        {
                            entry.children.map(entry => (
                                <InventorySchemaVisualizerSidebarTreeEntry
                                    key={entry.value.join('-')}
                                    entry={entry}
                                    depth={depth + 1}
                                    selectedValue={selectedValue}
                                />
                            ))
                        }
                    </Collapse>
                }
            </div>
        </>
    );
}

export default InventorySchemaVisualizerSidebarTreeView;
