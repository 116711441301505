import React, {useEffect, useState} from "react";
import useRouter from "../../../hooks/use-router";
import useIsMounted from "../../../hooks/use-is-mounted";
import {useTranslation} from "react-i18next";
import {InvoiceStatuses, TableCellAlignments, TableCellTypes} from "../../../../core/constants/enums";
import ButtonDropdown from "../../../components/app-specific/button-dropdown";
import {ReactComponent as ReportIcon} from "../../../../assets/images/report.svg";
import {ReactComponent as PrintIcon} from "../../../../assets/images/print.svg";
import classnames from "classnames";
import Table from "../../../containers/table";
import NoEntries from "../../../components/app-specific/no-entries";
import {
    generatePaginationAndOrderByQueryResetter,
    hasPaginationInfoChanged,
    onTableCurrentPageChange,
    onTablePageSizeChange
} from "../../../../core/services/searching-utils";
import {InvoiceSearchQueryNames} from "../../../../core/constants/query-names";
import {deepEqual} from "../../../../core/services/utils";
import moment from "moment";
import InvoicesSearchSection from "../../../components/search-sections/invoices";

// keys for the invoices table
const tableCellKeys = {
    invoiceNumber: 'invoiceNumber',
    submittedDate: "submittedDate",
    amount: "amount",
    status: "status",
    print: 'print',
}

// keys for generating report options dropdown
const reportOptionKeys = {
    pdf: "pdf",
    csv: 'csv',
}

const InvoicesView = () => {
    const {query, location, history, stringifyUrl} = useRouter()
    const [invoices, setInvoices] = useState([])
    const [loading, setLoading] = useState(true)
    const [generatingReport, setGeneratingReport] = useState(false)
    const [filters, setFilters] = useState({});
    const [paginationInfo, setPaginationInfo] = useState(null);
    const isMounted = useIsMounted()
    const {t} = useTranslation()
    const translations = t("views.panel.invoices", {returnObjects: true});
    const tableCells = [
        {
            title: translations?.tableHeaders?.invoiceNumber ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: tableCellKeys.invoiceNumber,
        },
        {
            title: translations?.tableHeaders?.submittedDate ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.dateTime,
            name: tableCellKeys.submittedDate,
            size: 1,
        },
        {
            title: translations?.tableHeaders?.amount ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.money,
            name: tableCellKeys.amount,
            size: 0.5,
        },
        {
            title: translations?.tableHeaders?.status ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: tableCellKeys.status,
        },
        {
            alignment: TableCellAlignments.center,
            type: TableCellTypes.element,
            name: tableCellKeys.print,
            size: 0,
        }
    ]
    const reportOptions = [
        {
            id: reportOptionKeys.pdf,
            title: generatingReport
                ? translations?.reports?.types?.loading ?? ''
                : translations?.reports?.types?.pdf ?? '',
            disabled: generatingReport,
        },
        {
            id: reportOptionKeys.csv,
            title: generatingReport
                ? translations?.reports?.types?.loading ?? ''
                : translations?.reports?.types?.csv ?? '',
            disabled: generatingReport,
        },
    ]

    /**
     * Listens to the changes in the url query parameters and with each change:
     * If the paginationInfo of query is not the same as the state, updates the state
     * if the filters of the query is not the same as the state, updates the state
     *
     * if there are any changes from the above scenarios, searches the invoices with the new state values
     */
    useEffect(() => {
        const newPaginationInfo = hasPaginationInfoChanged(query, paginationInfo, setPaginationInfo);
        const newFilters = hasFiltersChanged();
        if (!newFilters && !newPaginationInfo) return;
        searchInvoices(prepareForSearchApi(newFilters ?? filters, newPaginationInfo ?? paginationInfo));
    }, [query])

    /**
     * Determines if the filters of the query parameters have changed or not.
     * @return {any} the newFilters if they have changed, otherwise null
     */
    const hasFiltersChanged = () => {
        let newFilters = {};
        const newKeywords = query[InvoiceSearchQueryNames.keywords]
        if (newKeywords?.length) {
            newFilters[InvoiceSearchQueryNames.keywords] = newKeywords
        }
        const newStartDate = query[InvoiceSearchQueryNames.fromDate]
        if (newStartDate?.length) {
            newFilters[InvoiceSearchQueryNames.fromDate] = newStartDate
        }
        const newEndDate = query[InvoiceSearchQueryNames.toDate]
        if (newEndDate?.length) {
            newFilters[InvoiceSearchQueryNames.toDate] = newStartDate
        }
        const newDateFilter = query[InvoiceSearchQueryNames.dateFilter]
        if (newDateFilter?.length) {
            newFilters[InvoiceSearchQueryNames.dateFilter] = newDateFilter
        }
        if (!deepEqual(newFilters, filters)) {
            setFilters(newFilters);
            return newFilters;
        }
    }

    /**
     * Prepares the data for searching.
     * @param   {any}                       filters
     * @param   {PaginationInfo | null}     paginationInfo
     * @return  {{paginationInfo, filters}}
     */
    const prepareForSearchApi = (filters, paginationInfo) => {
        return {
            paginationInfo: paginationInfo,
            filters: filters,
        }
    }

    /**
     * Searches the invoices of the system with the provided api data.
     * @param {any} forApiData the filters and possible pagination info for searching
     */
    const searchInvoices = (forApiData) => {
        //TODO: call api for searching for invoices.
        setInvoices([
            {
                id: 1,
                invoiceNo: '1231431002',
                submittedDateTime: moment.now(),
                status: {
                    id: InvoiceStatuses.paid
                },
                total: 25.33,
            },
            {
                id: 2,
                invoiceNo: '1231431002',
                submittedDateTime: moment.now(),
                status: {
                    id: InvoiceStatuses.notPaid
                },
                total: 125.332,
            },
            {
                id: 3,
                invoiceNo: '1231431002',
                submittedDateTime: moment.now(),
                status: {
                    id: InvoiceStatuses.pending
                },
                total: 12445.2342,
            },
            {
                id: 4,
                invoiceNo: '1231431002',
                submittedDateTime: moment.now(),
                status: {
                    id: 4
                },
                total: -123,
            }
        ])
        setPaginationInfo(prevState => ({...prevState, length: 4}))
        setLoading(false)
    }

    /**
     * Fetches the list of invoices for csv generation from the server and outputs a pdf file to the os of the user.
     */
    const generatePdfReport = () => {
        setGeneratingReport(true);
        // TODO: implement pdf generation feature
        setGeneratingReport(false);
    }

    /**
     * Fetches the list of invoices for csv generation from the server and outputs a csv file to the os of the user.
     */
    const generateCsvReport = () => {
        setGeneratingReport(true)
        //TODO: call api for getting invoices for report generation
        setGeneratingReport(false)
    }

    /**
     * Determines which of the report types to generate based on the selected option of the user.
     * @param {{id: string}} option the selected option
     * @param {function(boolean): void} setPopover the callback to close the popover
     */
    const onReportTypeSelected = (option, setPopover) => {
        switch (option.id) {
            case reportOptionKeys.pdf:
                generatePdfReport()
                break;
            case reportOptionKeys.csv:
            default:
                generateCsvReport()
                break;
        }
        if (setPopover) setPopover(false)
    }

    /**
     * Clears the filters of this view to invoke a search without any filers.
     */
    const clearFilters = () => {
        //TODO: implement clearing filters
        history.push(stringifyUrl({
            url: location.pathname,
            query: {
                [InvoiceSearchQueryNames.dateFilter]: query[InvoiceSearchQueryNames.dateFilter] ?? undefined,
                ...generatePaginationAndOrderByQueryResetter()
            }
        }));
    }

    /**
     * Renders the invoice status of the table rows.
     * @param {any} invoice the invoice.
     * @return {JSX.Element}
     */
    const renderInvoiceStatus = (invoice) => {
        return (
            <>
                <div className={'status-container'}>
                    <div className={classnames('status', {
                        'paid': invoice.status?.id === InvoiceStatuses.paid,
                        'pending': invoice.status?.id === InvoiceStatuses.pending,
                        'not-paid': invoice.status?.id === InvoiceStatuses.notPaid,
                    })}/>
                    <p className={'text'}>
                        {
                            invoice.status?.id === InvoiceStatuses.paid
                                ? translations?.statuses?.paid ?? ''
                                : invoice.status?.id === InvoiceStatuses.pending
                                    ? translations?.statuses?.pending ?? ''
                                    : invoice.status?.id === InvoiceStatuses.notPaid
                                        ? translations?.statuses?.notPaid ?? ''
                                        : translations?.statuses?.unknown ?? ''
                        }
                    </p>
                </div>
            </>
        );
    }

    /**
     * Prints the invoice using the BizPrint system
     * @param {any} invoice the given invoice to be printed
     */
    const onPrintInvoiceClicked = (invoice) => {
        //TODO: implement printing mechanism
    }

    /**
     * Creates the data entries for invoices table
     * @return {TableCellData[]}
     */
    const createTableData = () => {
        return invoices
            ?.map((invoice) => ({
                key: invoice.id,
                [tableCellKeys.invoiceNumber]: invoice?.invoiceNo,
                [tableCellKeys.submittedDate]: invoice?.submittedDateTime,
                [tableCellKeys.amount]: invoice?.total,
                [tableCellKeys.status]: () => renderInvoiceStatus(invoice),
                [tableCellKeys.print]: () => (
                    <button className={'button text'} onClick={() => onPrintInvoiceClicked(invoice)}>
                        <PrintIcon className={'print-icon'}/>
                    </button>
                ),
            }))
    }


    return (
        <>
            <div className={'invoices'}>
                <div className={'panel-card p-0 pt-4'}>
                    <div className={'px-4 d-flex align-items-center justify-content-between'}>
                        <p className={'title'}>
                            {translations?.title ?? ''}
                        </p>
                        <ButtonDropdown
                            variant={'outlined'}
                            arrowPlacement={'right'}
                            options={reportOptions}
                            onOptionSelected={onReportTypeSelected}
                            title={(
                                <>
                                    <div className={'d-flex align-items-center'}>
                                        <ReportIcon className={'me-1'}/>
                                        {translations?.reports?.text ?? ''}
                                    </div>
                                </>
                            )}
                            buttonProps={{
                                className: 'primary'
                            }}
                        />
                    </div>
                    <InvoicesSearchSection filters={filters}>
                        <div>
                            {
                                invoices?.length < 1 && !loading
                                    ? <NoEntries
                                        text={
                                            Object.entries(filters ?? {})?.length > 0
                                                ? translations?.empty?.withFilters
                                                : translations?.empty?.text
                                        }
                                        includeButton={Object.entries(filters ?? {})?.length > 0}
                                        buttonProps={{
                                            onClick: clearFilters,
                                            className: 'button primary px-3'
                                        }}/>
                                    : <Table
                                        loading={{state: loading, count: 8}}
                                        className={'my-3'}
                                        color={'primary'}
                                        cells={tableCells}
                                        data={createTableData()}
                                        paginationInfo={paginationInfo}
                                        onPageSizeChange={(p, i) => onTablePageSizeChange(p, i, query, history, location)}
                                        onCurrentPageChange={(p, i) => onTableCurrentPageChange(p, i, query, history, location)}
                                    />
                            }
                        </div>
                    </InvoicesSearchSection>
                </div>
            </div>
        </>
    )
}

export default InvoicesView;
