import React from "react";
import {DialogContent} from "@material-ui/core";
import {ReactComponent as SuccessIcon} from "../../../../../assets/images/art-like/ticket-submission-success.svg";
import {Col} from "react-bootstrap";
import CopyToClipboard from "../../../app-specific/copy-to-clipboard";
import {ReactComponent as Copy} from "../../../../../assets/images/copy.svg";

const ConfirmationSection = ({data}) => {

    return (
        <>
            <DialogContent className={'px-md-4'}>
                <Col xs={12} className={'mb-4 d-flex'}>
                    <SuccessIcon className={'ticket-submission-success'}/>
                </Col>
                <Col xs={12}>
                    <p className={'text-sm mt-4 mb-3'}>
                        {data?.message ?? ''}
                    </p>
                </Col>
                <Col xs={12} className={`mb-5 px-4`}>
                    <div className={'bordered w-100'}>
                        <p className='text-center text-md font-weight-semi-bold'>
                            {data?.code ?? '--'}
                        </p>
                    </div>
                </Col>
                <Col xs={12} className='mb-5 px-4'>
                    <p className='text-sm text-color-1 mb-2'>
                        Ticket Link:
                    </p>
                    <div
                        className={`background-secondary-color-3 rounded-xl d-flex align-items-center justify-content-between px-3 py-2`}>
                        <p className='text-color-1 text-sm w-75'>
                            {data.link ?? ""}
                        </p>
                        <CopyToClipboard>
                            {({copy}) =>
                                <div onClick={() => copy(data.link ?? "")}>
                                    <Copy/>
                                </div>
                            }
                        </CopyToClipboard>
                    </div>
                </Col>
            </DialogContent>
        </>
    );
}


export default ConfirmationSection;
