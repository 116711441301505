export const apiUrl = process.env.REACT_APP_API_URL;

// ########################################     Auth    ########################################

const _authBaseUrl = apiUrl + 'Auth/';
/**
 * Auth Apis
 */
export const authApis = {
    baseUrl: _authBaseUrl,
    getConfiguration: _authBaseUrl + 'GetAllFrontConfigurations',
    login: _authBaseUrl + 'Login',
};

// ########################################     Company    ########################################

const _companyBaseUrl = apiUrl + 'Company/';
/**
 * Company Apis
 */
export const companyApis = {
    getList: _companyBaseUrl + 'list',
    setDefault: (companyId: number) => _companyBaseUrl + `SetDefault/${companyId}`,
}

// ########################################     Warehouse    ########################################

const _warehouseBaseUrl = apiUrl + 'Warehouse/';
/**
 * Warehouse Apis
 */
export const warehouseApis = {
    baseUrl: _warehouseBaseUrl,
    getList: _warehouseBaseUrl + 'list',
    setDefault: (warehouseId: number) => _warehouseBaseUrl + `SetDefault/${warehouseId}`,
    create: _warehouseBaseUrl,
    update: _warehouseBaseUrl,
    remove: (warehouseId: number) => _warehouseBaseUrl + `${warehouseId}`,
}

// ########################################     Aisle    ########################################

const _aisleBaseUrl = apiUrl + 'Aisle/';
/**
 * Aisle Apis
 */
export const aisleApis = {
    baseUrl: _aisleBaseUrl,
    getList: _aisleBaseUrl,
    create: _aisleBaseUrl,
    update: _aisleBaseUrl,
    remove: (aisleId: number) => _aisleBaseUrl + `${aisleId}`,
    loadInfo: (code: string) => _aisleBaseUrl + `LoadInfo/${code}`,
}

// ########################################     Section    ########################################

const _sectionBaseUrl = apiUrl + 'Section/';
/**
 * Section Apis
 */
export const sectionApis = {
    baseUrl: _sectionBaseUrl,
    getList: (aisleId: number) => _sectionBaseUrl + `${aisleId}`,
    create: _sectionBaseUrl,
    update: _sectionBaseUrl,
    remove: (sectionId: number) => _sectionBaseUrl + `${sectionId}`,
    loadInfo: (code: string) => _sectionBaseUrl + `LoadInfo/${code}`,
}

// ########################################     Row    ########################################

const _rowBaseUrl = apiUrl + 'Row/';
/**
 * Section Apis
 */
export const rowApis = {
    baseUrl: _rowBaseUrl,
    getList: (sectionId: number) => _rowBaseUrl + `${sectionId}`,
    create: _rowBaseUrl,
    update: _rowBaseUrl,
    remove: (rowId: number) => _rowBaseUrl + `${rowId}`,
    loadInfo: (rowId: number) => _rowBaseUrl + `LoadInfo/${rowId}`,
}

// ########################################     WarehouseSynchronize    ########################################

const _warehouseSyncBaseUrl = apiUrl + 'WarehouseSyncernace/';
export const warehouseSyncApis = {
    baseUrl: _warehouseSyncBaseUrl,
    getList: _warehouseSyncBaseUrl + 'list',
    createManual: _warehouseSyncBaseUrl + `CreateManual`,
    apply: (syncId: number) => _warehouseSyncBaseUrl + `Apply/${syncId}`,
    getPartReport: (syncId: number) => _warehouseSyncBaseUrl + `GetPartReport/${syncId}`,
    getSerialReport: (syncId: number) => _warehouseSyncBaseUrl + `GetSerialReport/${syncId}`,
}

// ########################################     SpireCounting    ########################################

const _spireCountingBaseUrl = apiUrl + 'SpireCounting/';
/**
 * Spire Counting Apis
 */
export const spireCountingApis = {
    baseUrl: _spireCountingBaseUrl,
    getList: _spireCountingBaseUrl + 'list',
    getById: (requestId: number) => _spireCountingBaseUrl + `GetById/${requestId}`,
    create: _spireCountingBaseUrl + 'SubmitRequest',
    createRecount: _spireCountingBaseUrl + 'SubmitRequestByParts',
    update: _spireCountingBaseUrl,
    remove: (requestId: number) => _spireCountingBaseUrl + `Remove/${requestId}`,
    getParticipants: (requestId: number) => _spireCountingBaseUrl + `ParticipantsList/${requestId}`,
    getScannedItems: _spireCountingBaseUrl + 'ScannedItemList',
    getScannedSerials: _spireCountingBaseUrl + 'ScannedSerialsList',
    updateFrozenNote: _spireCountingBaseUrl + 'UpdateFreezedNote',
    updateScannedNote: _spireCountingBaseUrl + 'UpdateScannedNote',
    updateUnSerializedNote: _spireCountingBaseUrl + 'UpdateUnSerializedNote',
}

// ########################################     SpireCountingReport    ########################################

const _spireCountingReportsBaseUrl = apiUrl + 'SpireCountingReport/';
/**
 * Spire Counting Report Apis
 */
export const spireCountingReportApis = {
    baseUrl: _spireCountingReportsBaseUrl,
    //TODO: Add encode Uri component to fix special characters
    getFrozenReport: (requestId: number) => _spireCountingReportsBaseUrl + `FreezedReport/${encodeURIComponent(requestId)}`,
    getScannedReport: (requestId: number) => _spireCountingReportsBaseUrl + `ScannedReport/${requestId}`,
    getGeneralReport: _spireCountingReportsBaseUrl + `GeneralReport`,
}
