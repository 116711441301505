import React, {useEffect, useState} from "react";
import * as Yup from 'yup';
import ValidateMessages from "../../../../../core/constants/texts/vallidate-messages";
import {makeRequired, makeValidate} from "mui-rff";
import {useTranslation} from "react-i18next";
import {DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import Dialog from "../../../base/dialog";
import Form from "../../../base/form";
import {FormInput} from "../../../base/input";
import {Col} from "react-bootstrap";
import {FormKeyboardDatePicker} from "../../../base/date-picker";
import moment from "moment";

/**
 * Form names keys
 * @type {{expiryDate: string, title: string}}
 */
const formNames = {
    title : "title",
    expiryDate: "expiryDate"
}

/**
 * Creates Schema for the form validation using Yup
 */
const schema = Yup.object().shape({
    [formNames.title]: Yup.string().nullable().required(ValidateMessages.required),
    //TODO: Double check the expiry valid message
    [formNames.expiryDate]: Yup.date().nullable().min(new Date().setTime(0)).required(ValidateMessages.required)
});


const EditSpireCountingDialog = ({setOpen, open, initData, onSubmit}) => {
    const [initialValue, setInitialValue] = useState({title: initData?.title, expiryDate: initData?.expiryDate});
    const {t} = useTranslation();
    const validate = makeValidate(schema);
    const required = makeRequired(schema);
    const translation = t('dialogs.counting.spire.editCountingReq',{returnObjects: true});

    /**
     * With each change in the [initData] that is being passed as props:
     * - Sets the [initialValue] in the state to the received object
     */
    useEffect(()=>{
        setInitialValue({title: initData?.title, expiryDate: initData?.expiryDate});
    },[initData])


    return (
        <>
            <Dialog
                max={'md'}
                fullWidth
                setOpen={setOpen}
                open={open}
            >
                <DialogTitle className={'title'} id={'scroll-dialog-title'}>
                    <div>
                        <h2>{translation?.title}</h2>
                    </div>
                </DialogTitle>
                <Form
                    onSubmit={(val) => onSubmit(val, initData,formNames)}
                    validate={validate}
                    initialValues={initialValue}
                    render={({submitting,values,form}) =>{
                        return (
                            <>
                                <DialogContent id={'scroll-dialog-description'} className={'mb-4 pt-0'}>
                                    <Col>
                                        <Col xs={12} md={6} className={'mt-3'}>
                                            <FormInput
                                                value={values[formNames.title]}
                                                onChange={(e) => form.change(e.target.name, e.target.value)}
                                                name={formNames.title}
                                                required={required[formNames.title]}
                                                label={translation?.form?.title ?? ''}
                                                disabled={submitting}
                                            />
                                        </Col>
                                        <Col xs={12} md={6} className={'mt-4'}>
                                            <div className='date-wrapper edit-spire-counting-req'>
                                                <div className='picker-container'>
                                                    {/*TODO:Fix Bug: in rare cases results in error regarding the value being undefined*/}
                                                    <FormKeyboardDatePicker
                                                        value={values[formNames.expiryDate]}
                                                        onChange={(e) => form.change(formNames.expiryDate,  moment(e))}
                                                        placeholder={'dd-mm-yyyy'}
                                                        label={translation?.form?.expiryDate ?? ''}
                                                        name={formNames.expiryDate}
                                                        required={required[formNames.expiryDate]}
                                                        minDate={new Date()}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Col>
                                </DialogContent>
                                <DialogActions>
                                    <button
                                        className='button primary outlined px-4'
                                        type='button'
                                        onClick={() => setOpen(false)}
                                        disabled={submitting}
                                    >
                                        {translation?.actions?.cancel ?? ''}
                                    </button>
                                    <button
                                        className='button primary px-5'
                                        disabled={submitting || !open || values[formNames.title] === initData?.title && values[formNames.expiryDate] === initData?.expiryDate}
                                        type='submit'>
                                        {!submitting && (translation?.actions?.submit ?? '')}
                                        {submitting && (translation?.actions?.submitting ?? '')}
                                    </button>
                                </DialogActions>
                            </>
                        )
                    }}
                />

            </Dialog>

        </>
    );
}

export default EditSpireCountingDialog;
