import React, {useEffect, useLayoutEffect, useState} from 'react';
import {Cancel} from '@material-ui/icons';
import classnames from "classnames";
import {abbreviateNumber, createPriceForPlansCard} from "../../../../../../core/services/utils";
import {useTranslation} from "react-i18next";
import {ReactComponent as DollarSign} from "../../../../../../assets/images/views/public-views/landing/dollar-sign.svg";
import {ReactComponent as DropdownIcon} from "../../../../../../assets/images/dropdown-icon.svg";
import moment from "moment";
import {Tooltip} from "@material-ui/core";
import {ReactComponent as PricingInformation} from "../../../../../../assets/images/views/public-views/landing/pricing-information.svg";

const ActivePlan = ({plan, loading, updatingStatus, onCancel, activatePlan}) => {
    const [fullDetailMode, setFullDetailMode] = useState(false);
    const [[priceInt, priceDecimal], setPrice] = useState([]);
    const [width, setWidth] = useState();
    const {t} = useTranslation();
    const translation = t('views.panel.subscription.currentPlan',
        {
            returnObjects: true,
            date: plan?.nextBill
                ? moment(plan?.nextBill).format('MMM DD YYYY')
                : ''
        }
    );


    /**
     * As soon as the component mounts:
     * * Attaches a resize observer to the width of the plan container
     */
    useEffect(() => {
        return observeWidth();
    }, [])


    /**
     * Attaches a resize observer to the container of the plan width
     *const observer = new ResizeObserver(entries => {
            const entry = entries.length ? entries[0] : null;
            if (entry) {
                setWidth(entry.contentRect.width ?? 0);
            }
        });
     for (let elementsByClassNameElement of document.getElementsByClassName("info-box")) {
            observer.observe(elementsByClassNameElement);
        }
     return observer.disconnect;
     * @return {() => void} the disconnect method of observer to be used after the component unmounts
     */
    const observeWidth = () => {

    }


    /**
     * Listens for the changes in plan and with each change:
     * if data has a price, then separates the price into its int and decimal parts
     */
    useLayoutEffect(() => {
        setPrice(createPriceForPlansCard(plan?.price));
    }, [plan])

    /**
     * Toggles the view mode of the active plan between expanded and minimised
     */
    const toggleDetailsMode = () => {
        setFullDetailMode((prevState => !prevState));
    }

    return (
        <div className={`w-100`}>
            {
                loading
                    ? (
                        <div className={'loading current-plan'}>
                            <div className={'title'}>
                                <div/>
                            </div>
                            <div className={'box'}>
                                <div/>
                            </div>
                            <div className={'action'}>
                                <div/>
                            </div>
                        </div>
                    )
                    : (
                        <div className={`active-plan`}>
                            <div className='d-flex'>
                                <div className={classnames('inner-container', {'collapsed': !fullDetailMode})}>
                                    <div className={classnames('title-container', {'collapsed': !fullDetailMode})}>
                                        <h3 className={classnames(`title`, {'flex-grow-1': !fullDetailMode})}>
                                            {translation?.title ?? ''}
                                        </h3>
                                        {
                                            !plan?.isActive &&
                                            <p className={'deactivation-date'}>
                                                {translation?.deactivationDate ?? ''}
                                            </p>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={`d-flex`}>
                                <div className={classnames(`transition`, {'flex-grow-1': !fullDetailMode})}>
                                    <div className='box-container'>
                                        <div className={`info-box`}>
                                            <div className='package-title text-center w-100 px-5'>
                                                {plan?.name ?? ''}
                                            </div>
                                            <div className='d-flex align-items-start justify-content-center w-100 px-5'>
                                    <span className='package-price dollar'>
                                        <DollarSign className={''}/>
                                    </span>
                                                <span className='package-price center mt-2'>
                                        {priceInt ?? ''}
                                    </span>
                                                <span className='package-price'>
                                        {priceDecimal ?? ''}
                                    </span>
                                            </div>
                                            {
                                                plan?.isActive &&
                                                <div
                                                    className='monthly-bill d-flex justify-content-around align-items-center w-100'>
                                        <span className='bill-info bold'>
                                            {translation?.nextBill?.title ?? ''}
                                        </span>
                                                    <span className='bill-info'>
                                            {translation?.nextBill?.date ?? ''}
                                        </span>
                                                </div>
                                            }

                                            <div className='mt-2 d-flex justify-content-end'>
                                                <button
                                                    className={classnames(`info-btn button text primary`,
                                                        {'active': fullDetailMode})}
                                                    onClick={toggleDetailsMode}>
                                                    {translation?.actions?.information ?? ''}
                                                    <DropdownIcon className={'icon'}/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    fullDetailMode &&
                                    <div className='detail-box'>
                                        <div className='details-inner'>
                                            <div className={'properties-layout'}>
                                                <table>
                                                    <tbody>
                                                    {
                                                        plan?.properties?.map((prop) => (
                                                            <tr key={`${prop.title} + ${prop.value}`}>
                                                                <td>
                                                                    {
                                                                        prop.description?.length > 0 &&
                                                                        <Tooltip
                                                                            interactive
                                                                            placement={'top-start'}
                                                                            classes={{
                                                                                tooltip: 'active-plan-description'
                                                                            }}
                                                                            title={
                                                                                <>
                                                                                    {prop.description}
                                                                                </>
                                                                            }
                                                                        >
                                                                            <PricingInformation className={'info'}/>
                                                                        </Tooltip>
                                                                    }
                                                                    <span>
                                                        {abbreviateNumber(prop.value)}
                                                    </span>
                                                                </td>
                                                                <td>
                                                                    {prop.title}
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div
                                className={classnames('action-button-container',)}>
                                <div className={classnames('inner-container', {'collapsed': !fullDetailMode})}>
                                    {
                                        plan?.isActive
                                            ? <button
                                                className={`cancel-button button text secondary`}
                                                style={{width: width}}
                                                disabled={loading || updatingStatus}
                                                onClick={onCancel}
                                            >
                                                <div className={'d-flex justify-content-center align-items-center'}>
                                                    <Cancel className={'me-2'}/>
                                                    {translation?.actions?.cancel ?? ''}
                                                </div>
                                            </button>
                                            : <button
                                                className={`button primary mt-2`}
                                                style={{width: width}}
                                                disabled={loading || updatingStatus}
                                                onClick={() => activatePlan(plan)}
                                            >
                                                {translation?.actions?.reactivate ?? ""}
                                            </button>
                                        
                                    }

                                </div>
                            </div>
                        </div>
                    )
            }

        </div>
    )
}

export default ActivePlan;
