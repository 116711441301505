/**
 * This file contains the query names used in the entirety of the application.
 */


/**
 * The default query names used and shared through the application
 */
export const UrlQueryNames = {
    pageSize: 'size',
    currentPage: 'page',
    total: 'total',
    orderBy: 'orderBy',
    orderByDescending: 'orderByDescending',
}

/**
 * The invoice searching query names
 */
export const InvoiceSearchQueryNames = {
    keywords: 'keyword',
    fromDate: 'fromDate',
    toDate: 'toDate',
    dateFilter: 'dateFilter',
}

/**
 * The settings searching query names
 */
export const SettingsQueryNames = {
    type: 'type',
}

/**
 * The row products searching query names
 */
export const RowItemsQueryNames = {
    keywords: 'keyword',
}

/**
 * The users searching query names
 */
export const UsersSearchQueryNames = {
    keywords: 'keyword'
}

/**
 * The users searching query names
 */
export const SpireCountingsSearchQueryNames = {
    keyword: 'keyword'
}

/**
 * the products list query section names (2 sections of products and instances)
 */
export const ItemsListQuerySectionNames = {
    products: "products",
    instances: "instances",
}

/**
 * The products list searching query names
 */
export const ItemsListQueryNames = {
    [ItemsListQuerySectionNames.products]: {
        keywords: 'keyword',
        weight: 'weight',
        height: 'height',
        width: 'width',
        depth: 'depth',
    },
    [ItemsListQuerySectionNames.products]: {
        keywords: 'keyword',
        status: 'status',
        warehouses: 'warehouses',
        aisles: 'aisles',
        sections: 'sections',
        rows: 'rows',
    },
}

/**
 * The single spire counting query name
 */
export const SpireCountingSingleQueryNames = {
    type: 'type'
}


/**
 * The single position counting query name
 */
export const PositionCountingSingleQueryNames = {
    type: 'type'
}

/**
 * The list of position countings search section query names
 */
export const PositionCountingListQueryNames = {
    keyword: 'keyword',
    warehouse: 'warehouse'
}

/**
 * The list of part instances search section query names
 */
export const PartInstanceListQueryNames = {
    keyword: 'keyword',
    status: 'statuses',
    warehouse: 'warehouses',
    aisle: 'aisles',
    section: 'sections',
    row: 'rows'

}

/**
 * The list of part list search section query names
 */
export const PartListQueryNames = {
    keyword: 'keyword',
    weight: 'weight',
    height: 'height',
    width: 'width',
    depth: 'depth'
}

/**
 * The list of orders list search section query names
 */
export const OrderListQueryNames = {
    keyword: 'keyword',
    status: 'status',
    fromDate: 'fromDate',
    toDate: 'toDate',
}


/**
 * The list of single order search section query names
 */
export const SingleOrderQueryNames = {
    keyword: 'keyword',
}




