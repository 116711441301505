import React, {useCallback, useMemo} from "react";
import {InventorySchemaVisualizerDepths} from "../../../core/models/constants/enums";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";

export type IInventorySchemaVisualizerDepthToggleButtonsProps<T = string> = {
    value: T,
    setValue: (value: T) => void,
    orientation: 'horizontal' | 'vertical',
}

const InventorySchemaVisualizerDepthToggleButtons = <T, >({
                                                              value,
                                                              setValue,
                                                              orientation
                                                          }: IInventorySchemaVisualizerDepthToggleButtonsProps<T>) => {

    const options = useMemo(() => {
        const depths = Object.values(InventorySchemaVisualizerDepths);
        return depths.map((val) => ({
            value: val,
            name: val,
        }))
    }, [])

    /**
     * Sets the value of this component by invoking the [setValue] props callback.
     */
    const onValueChanged = useCallback((e, value) => {
        value && setValue(value);
    }, [setValue])

    return (
        <>
            <ToggleButtonGroup
                exclusive
                value={value}
                onChange={onValueChanged}
                orientation={orientation}
                className={'inventory-schema-visualizer-toggle-buttons'}
            >
                {
                    options.map(option => (
                        <ToggleButton
                            value={option.value}
                            key={option.value}
                            color={'primary'}
                            size={'small'}
                        >
                            {option.name}
                        </ToggleButton>
                    ))
                }
            </ToggleButtonGroup>
        </>
    );
}

export default InventorySchemaVisualizerDepthToggleButtons;
