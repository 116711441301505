import {Col} from "react-bootstrap";
import React from "react";

const LandingStatisticsCard = ({data}) => {
    return (
        <Col xs={12} sm={6} md={4} className={'flex-row-center'}>
            <div className={'stat-card'}>
                {data.icon}
                <p className={'stat'}>
                    {data.stat}
                </p>
                <p className={'text'}>
                    {data.text}
                </p>
            </div>
        </Col>

    );
}
export default LandingStatisticsCard;
