import React, {useMemo} from "react";
import {IWarehouseData} from "../../types";
import {InventorySchemaVisualizerDepths} from "../../../core/models/constants/enums";
import InventorySchemaVisualizerSidebarTreeView from "./tree-view";
import InventorySchemaVisualizerDepthToggleButtons from "../toggle-buttons";
import {Button} from "@material-ui/core";
import {dialogActions} from "../../../../../redux/entities/dialog-slice";
import {useDispatch} from "react-redux";

export type IInventorySchemaVisualizerSidebarProps = {
    warehouseData: IWarehouseData,
    depth: InventorySchemaVisualizerDepths,
    setDepth: (depth: InventorySchemaVisualizerDepths) => void,
    selectedEntry?: Array<string>,
    onEntrySelected: (value: Array<string>) => void,
    autoGenerateSchema: Function,
    canAutoGenerate: boolean,
}

const InventorySchemaVisualizerSidebar = ({
                                              warehouseData,
                                              depth,
                                              setDepth,
                                              selectedEntry,
                                              onEntrySelected,
                                              autoGenerateSchema,
                                              canAutoGenerate,
                                          }: IInventorySchemaVisualizerSidebarProps) => {
    const dispatch = useDispatch();

    const treeList = useMemo(() => {
        return warehouseData.aisles?.map(aisle => ({
            title: 'Aisle '.concat(aisle.code),
            value: [aisle.code],
            icon: "A",
            onSelect: () => onEntrySelected([aisle.code]),
            children: aisle.sections?.map(section => ({
                title: 'Section '.concat(section.code),
                value: [aisle.code, section.code],
                icon: "S",
                onSelect: () => onEntrySelected([aisle.code, section.code]),
                children: section.rows?.map((row) => ({
                    title: 'Row '.concat(row.code),
                    value: [aisle.code, section.code, row.code],
                    icon: "R",
                    onSelect: () => onEntrySelected([aisle.code, section.code, row.code]),
                    children: [],
                })) ?? [],
            })) ?? [],
        })) ?? [];
    }, [warehouseData.aisles, onEntrySelected])

    /**
     * Opens the confirmation dialog.
     */
    const onAutoGenerateSchemaClicked = () => {
        dispatch(dialogActions.configConfirmationDialog({
            open: true,
            onClose: () => dispatch(dialogActions.confirmationDialogToggle(false)),
            onConfirm: () => autoGenerateSchema(),
            title: 'Confirmation',
            description: 'Auto-generating the schema will remove your current schema',
        }))
    }
    return (
        <>
            <div className={'inventory-schema-visualizer-sidebar'}>
                {
                    canAutoGenerate &&
                    <div className={'inventory-schema-visualizer-sidebar-section'}>
                        <Button
                            variant={'outlined'}
                            className={'inventory-schema-visualizer-sidebar-button'}
                            color={'primary'}
                            onClick={onAutoGenerateSchemaClicked}
                        >
                            Auto-Generate Schema
                        </Button>
                    </div>
                }
                <div className={'inventory-schema-visualizer-sidebar-section'}>
                    <p className={'inventory-schema-visualizer-sidebar-section-title'}>
                        Depth Shown
                    </p>
                    <InventorySchemaVisualizerDepthToggleButtons
                        value={depth}
                        setValue={setDepth}
                        orientation={'horizontal'}
                    />
                </div>
                <div className={'inventory-schema-visualizer-sidebar-section'}>
                    <p className={'inventory-schema-visualizer-sidebar-section-title'}>
                        Warehouse Entities
                    </p>
                    <InventorySchemaVisualizerSidebarTreeView
                        list={treeList}
                        selectedValue={selectedEntry}
                    />
                </div>
            </div>
        </>
    );
}

export default InventorySchemaVisualizerSidebar;
