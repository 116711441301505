import React, {useLayoutEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ApplicationLanguages, ApplicationSettingKeys, GeneralSettingsTypes} from "../../../../../core/constants/enums";
import CacheService from "../../../../../core/services/cache_service";
import GeneralSettingDynamicEntry from "../dynamic";

const GeneralSettingLanguagesEntry = ({values, initialValues, ...formProps}) => {
    const [setting, setSetting] = useState({});
    const {t, i18n} = useTranslation();
    const languageTranslations = t("languages", {returnObjects: true});
    const translations = t("views.panel.settings.general.languages", {returnObjects: true})
    const languages = Object.values(ApplicationLanguages).map(e => ({id: e, title: languageTranslations[e]})) ?? [];

    /**
     * As soon as the component mounts:
     * Creates the setting object associated with the languages setting.
     */
    useLayoutEffect(() => {
        const settings = CacheService.getSettings() ?? {}
        const selectedLanguage = languages.find(e => e.id === settings[ApplicationSettingKeys.language])
            ?? {title: "", id: 'no-language-selected'}
        setSetting({
            title: translations?.title ?? '',
            defaultValues: languages,
            value: selectedLanguage,
            type: GeneralSettingsTypes.list,
            id: ApplicationSettingKeys.language,
        })
    }, [])

    /**
     * Changes the selected language both in the cache service and using the i18n package.
     * @param {any} setting the language setting
     * @param {{title: string, id: string}} newLanguage the newly selected language
     */
    const changeSelectedLanguage = (setting, newLanguage) => {
        if (setting.value?.id === newLanguage?.id || !newLanguage?.id) {
            return
        }
        const languageKey = newLanguage.id
        CacheService.setSettings(ApplicationSettingKeys.language, languageKey);
        i18n.changeLanguage(languageKey).then();
        setSetting(prevState => ({...prevState, value: newLanguage}))
    }

    /**
     * Reverts the selected language to the cached value.
     * @param {any} setting the language setting
     * @param {formApi} form the form api for changing the value of the
     */
    const revertSelectedLanguage = (setting, form) => {
        form.change(ApplicationSettingKeys.language, initialValues[ApplicationSettingKeys.language])
    }

    return (
        <>
            <GeneralSettingDynamicEntry
                setting={setting}
                values={values}
                initialValues={initialValues}
                onSave={changeSelectedLanguage}
                onRevert={revertSelectedLanguage}
                name={ApplicationSettingKeys.language}
                {...formProps}
            />
        </>
    );
}


export default GeneralSettingLanguagesEntry;
