import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import useRouter from "../../../../../hooks/use-router";
import useIsMounted from "../../../../../hooks/use-is-mounted";
import {useTranslation} from "react-i18next";
import {DefaultInitialPaginationInfo, TableCellAlignments, TableCellTypes} from "../../../../../../core/constants/enums";
import {generatePaginationAndOrderByQueryResetter} from "../../../../../../core/services/searching-utils";
import {RowItemsQueryNames} from "../../../../../../core/constants/query-names";
import {createUUId} from "../../../../../../core/services/utils";
import moment from "moment";
import {routeFunctions} from "../../../../../routes";
import {ReactComponent as BackIcon} from "../../../../../../assets/images/views/location/back.svg";
import {ReactComponent as GenerateReportIcon} from "../../../../../../assets/images/report.svg";
import BreadCrumbs from "../../../../../components/app-specific/bread-crumbs";
import NoEntries from "../../../../../components/app-specific/no-entries";
import Table from "../../../../../containers/table";
import RowItemsSearchSection from "../../../../../components/search-sections/row-items";
import {ReactComponent as BarcodeIcon} from "../../../../../../assets/images/barcode-icon.svg";
import {dialogActions} from "../../../../../../redux/entities/dialog-slice";
import {useDispatch} from "react-redux";
import QueryManagementUtils from "../../../../../../core/services/query-management-utils";
import {CompanyContext} from "../../../../../contexts/company";
import useSearchData from "../../../../../hooks/use-search-data";


// keys for the invoices table
const tableCellKeys = {
    partNumber: 'partNumber',
    serialNumber: "serialNumber",
    submitDate: "submitDate",
    expiryDate: "expiryDate",
    count: 'count',
}


const initialPaginationInfo = {
    ...DefaultInitialPaginationInfo,
    pageSize: 10,
}


const RowItemsView = () => {
    const {
        query, location, history, stringifyUrl, params: {
            company: companyParam,
            warehouse,
            row,
            section,
            aisle
        }
    } = useRouter();
    const [items, setItems] = useState([])
    const [loading, setLoading] = useState(true)
    const [generatingReport, setGeneratingReport] = useState(false)
    const [locationInfo, setLocationInfo] = useState({});
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const isMounted = useIsMounted();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const translations = t("views.panel.company.location", {
        returnObjects: true,
        code: locationInfo?.row?.code ?? ''
    });

    const [company] = useContext(CompanyContext);
    const selectedCompany = useRef(company);
    /**
     * With each change in the company id:
     * - navigates user to the list of warehouses related to the selected company
     */
    useEffect(() => {
        if (selectedCompany.current?.id === company?.id)
            return;
        selectedCompany.current = company;
        history.replace(routeFunctions.panel.company.location.warehouses(company.name))
    }, [company?.id])

    /**
     * With each change in the selected company:
     * - navigates user to the list of spire countings
     */
    useEffect(() => {
        getLocationInfo();
    }, [warehouse, aisle, section, row]);

    /**
     * Gets the location address information
     */
    const getLocationInfo = () => {
        setLoading(true);
        //TODO: call the Api to load the location address information
        const response = {
            resultFlag: true,
            data: {
                warehouse: {id: warehouse, code: '00'},
                aisle: {id: aisle, code: "A5"},
                section: {id: section, code: "A5A2"},
                row: {id: row, code: "A5A2R3"},
            }
        };
        if (response?.resultFlag) {
            setLocationInfo(response?.data);
            if (window.bizpire?.makeLocationBreadcrumbs) {
                setBreadcrumbs(window.bizpire?.makeLocationBreadcrumbs(response?.data))
            }
        }
    }

    /**
     * Gets the list of row items from the server
     * @return {Promise<void>}
     */
    const search = async () => {
        // TODO: call api for searching for products in a row.
        const forApi = prepareForSearchApi();
        const response = {
            resultFlag: true,
            data: {
                resultList: Array(10).fill(null).map((e, index) => ({
                    partId: index + 1,
                    instanceId: (index * 100) + 1,
                    partNumber: `Part ${index}`,
                    serialNumber: index % 3 === 0 ? null : createUUId(),
                    expiryDate: new Date(),
                    submitDate: moment().add(10, 'month').toDate(),
                    count: index % 3 === 0 ? index * 100 : 1,
                    isSerialized: index % 3 !== 0,
                })),
                paginationInfo: {
                    length: 10,
                }
            }
        }
        if (!isMounted())
            return;
        if (response?.resultFlag) {
            setItems(response?.data?.resultList ?? []);
            const newPaginationInfo = {
                ...paginationInfo,
                length: response?.data.paginationInfo.length ?? 0
            }
            QueryManagementUtils.setPaginationInfo(newPaginationInfo, query, history, location);
        }
        setLoading(false)
    };

    const {filters, paginationInfo, orderBy} = useSearchData(
        RowItemsQueryNames,
        search,
        {
            useFilters: true,
            initialFilters: {},
            filtersQueryParamMap: {
                keyword: RowItemsQueryNames.keywords,
            },

            usePaginationInfo: true,
            initialPaginationInfo: initialPaginationInfo,

            useOrderBy: true,
            initialOrderBy: undefined
        }
    );


    /**
     * Prepares api call request body:
     * @return {{orderBy, paginationInfo, filters}}
     */
    const prepareForSearchApi = () => {
        return {
            paginationInfo: paginationInfo,
            filters: filters,
            orderBy: orderBy,
        }
    }

    /**
     * Generates a csv report for all the parts in the selected company and the provided values as filters.
     * @param {any} values the selected filters accumulated from the dialog.
     */
    const generateReport = (values) => {
        setGeneratingReport(true);
        const forApi = {...values}
        //TODO: call api for generating report for all the parts in an aisle
        setGeneratingReport(false);
    }

    /**
     * Opens the general report's dialog and binds the generation report callback for successful filtering.
     */
    const showGenerateReportDialog = () => {
        const generateLocationReportDialog = window.bizpire?.generateLocationReportDialog;
        if (!generateLocationReportDialog) return;
        generateLocationReportDialog(true, locationInfo, generateReport)
    }

    /**
     * Clears the filters of this view to invoke a search without any filers.
     */
    const clearFilters = () => {
        history.push(stringifyUrl({
            url: location.pathname,
            query: {
                [RowItemsQueryNames.keywords]: undefined,
                ...generatePaginationAndOrderByQueryResetter()
            }
        }));
    }

    /**
     * Navigates the user to the product view
     * @param product
     */
    const navigateToProduct = (product) => {
        history.push(routeFunctions.panel.company.products.single.product(companyParam, product.partId))
    }

    /**
     * Navigates the user to the the instance of the given product.
     * @param product
     */
    const navigateToInstance = (product) => {
        history.push(routeFunctions.panel.company.products.single.instance(companyParam, product.instanceId))
    }

    /**
     * Navigates the user to the previous location page
     */
    const navigateBack = () => {
        history.push(routeFunctions.panel.company.location.sections(companyParam, warehouse, aisle));
    }

    /**
     * Handles click on the barcode icon button:
     * - Opens dialog for showing the barcode
     * @param {string} partNumber for generating barcode from
     */
    const onBarcodeClicked = (partNumber) => {
        dispatch(dialogActions.configBarcodeDialog({
            open: true,
            onClose: () => dispatch(dialogActions.barcodeDialogToggle(false)),
            barcodeCode: partNumber,
        }));
    }

    /**
     * Memo version of table columns
     * @type {object[]}
     */
    const tableColumns = useMemo(() => [
        {
            title: translations?.rowItems?.tableHeaders?.partNumber ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: tableCellKeys.partNumber,
            sortable: true
        },
        {
            title: translations?.rowItems?.tableHeaders?.serialNumber ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: tableCellKeys.serialNumber,
            size: 3,
            sortable: true
        },
        {
            title: translations?.rowItems?.tableHeaders?.submitDate ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.date,
            name: tableCellKeys.submitDate,
            size: 1,
            sortable: true
        },
        {
            title: translations?.rowItems?.tableHeaders?.expiryDate ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.date,
            name: tableCellKeys.expiryDate,
            size: 1,
            sortable: true
        },
        {
            title: translations?.rowItems?.tableHeaders?.count ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.string,
            name: tableCellKeys.count,
            size: 0.5,
            sortable: true
        }
    ], [translations])


    /**
     * Memo version of table rows
     * @type {object[]}
     */
    const tableRows = useMemo(() => {
        return items
            ?.map((item) => ({
                key: `${item.partId}-${item.instanceId}`,
                [tableCellKeys.partNumber]: () => (
                    <div className={'d-flex flex-row align-items-center'}>
                        {
                            item?.partNumber?.length > 0 &&
                            <div
                                onClick={() => onBarcodeClicked(item?.partNumber ?? '')}
                                className={'barcode-icon-button'}
                            >
                                <BarcodeIcon/>
                            </div>
                        }
                        <div onClick={() => navigateToProduct(item)}>
                            <p className={'text-button'}>{item?.partNumber ?? ''}</p>
                        </div>
                    </div>
                ),
                [tableCellKeys.serialNumber]: () => (
                    <div className={'d-flex flex-row align-items-center'}>
                        {
                            item?.serialNumber?.length > 0 &&
                            <div
                                onClick={() => onBarcodeClicked(item?.serialNumber ?? '')}
                                className={'barcode-icon-button'}
                            >
                                <BarcodeIcon/>
                            </div>
                        }
                        <div onClick={() => navigateToInstance(item)}>
                            <p className={'text-button'}>{item?.serialNumber ?? ''}</p>
                        </div>
                    </div>
                ),
                [tableCellKeys.submitDate]: item?.submitDate,
                [tableCellKeys.expiryDate]: item?.expiryDate,
                [tableCellKeys.count]: item?.count,
            }))
    }, [items, loading])


    return (
        <>
            <div className={'panel-card location-view row-items'}>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className={'d-flex align-items-center'}>
                        <button className='button primary icon-button back'
                                onClick={navigateBack}>
                            <BackIcon/>
                        </button>
                        <p className='title'>
                            {translations?.rowItems?.title ?? ''}
                        </p>
                    </div>
                    <div>
                        <button className='button outlined primary report'
                                onClick={showGenerateReportDialog}>
                            <GenerateReportIcon className='me-2'/>
                            {
                                generatingReport
                                    ? translations?.actions?.generateReport?.loading ?? ''
                                    : translations?.actions?.generateReport?.title ?? ''
                            }
                        </button>
                    </div>
                </div>
                <div className={'mt-2'}>
                    <BreadCrumbs data={breadcrumbs}/>
                </div>
                <div className={'mt-3'}>
                    <RowItemsSearchSection filters={filters}/>
                </div>
                <div className='mt-2'>
                    {
                        !!items?.length < 1 && !loading
                            ? (
                                <NoEntries
                                    text={
                                        Object.entries(filters ?? {})?.length > 0
                                            ? translations?.rowItems?.empty?.withFilters
                                            : translations?.rowItems?.empty?.text
                                    }
                                    includeButton={Object.entries(filters ?? {})?.length > 0}
                                    buttonProps={{
                                        onClick: clearFilters,
                                        className: 'button primary px-3'
                                    }}/>
                            )
                            : (
                                <Table
                                    loading={{state: loading, count: 8}}
                                    className={'my-3'}
                                    color={'primary'}
                                    cells={tableColumns}
                                    data={tableRows}
                                    paginationInfo={paginationInfo}
                                    orderBy={orderBy}
                                    onPageSizeChange={(p, i) => QueryManagementUtils.setPaginationInfo(
                                        {...p, pageSize: i},
                                        query,
                                        history,
                                        location
                                    )}
                                    onCurrentPageChange={(p, newP) => QueryManagementUtils.setPaginationInfo(
                                        {...p, currentPage: newP},
                                        query,
                                        history,
                                        location,
                                    )}
                                    onSort={(orderBy) => QueryManagementUtils.setOrderBy(
                                        orderBy,
                                        query,
                                        history,
                                        location,
                                    )}
                                />
                            )
                    }
                </div>
            </div>
        </>
    )
}

export default RowItemsView;
