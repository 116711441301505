import React, {useEffect, useLayoutEffect, useReducer, useState} from "react";
import useRouter from "../../hooks/use-router";
import useWindowViewportWidth from "../../hooks/use-window";
import {matchPath} from "react-router";
import routes from "../../routes";
import {PanelContext, PanelContextInitialValues} from "../../contexts/panel";
import classnames from "classnames";
import PanelTopbar from "./topbar";
import {CompanyContext, CompanyContextInitialValues} from "../../contexts/company";
import CompanyReducer from "../../reducers/company";
import PanelSidebar from "./sidebar";
import {useDispatch} from "react-redux";
import CacheService from "../../../core/services/cache_service";
import {setUserInfoRedux} from "../../../redux/entities/user-info/actions";
import {topbarId} from "../../../core/constants/ids";
import * as $ from 'jquery';


const Panel = ({children}) => {
    const {location, match} = useRouter({path: routes.panel.base, exact: true});
    const windowViewportWidth = useWindowViewportWidth();
    const [company, dispatchCompany] = useReducer(CompanyReducer, CompanyContextInitialValues, undefined);
    const [state, setState] = useState(PanelContextInitialValues[0]);
    const topbar = $(`#${topbarId}`);
    const [topbarHeight, setTopbarHeight] = useState(topbar.outerHeight());
    const dispatch = useDispatch();

    /**
     * Depending on the viewport Width of the window, sets the collapsed state.
     */
    useLayoutEffect(() => {
        switch (windowViewportWidth) {
            case 'xs':
                setState(prevState => ({...prevState, collapsed: true, canCollapse: false}));
                break;
            case 'sm':
                setState(prevState => ({...prevState, collapsed: true, canCollapse: false}));
                break;
            case 'md':
                setState(prevState => ({...prevState, collapsed: true, canCollapse: true}));
                break;
            case  'lg':
            case  'xl':
            case  'xxl':
            default:
                setState(prevState => ({...prevState, collapsed: false, canCollapse: true}));
                break;
        }
    }, [windowViewportWidth])

    /**
     * As soon as the component mounts:
     * * Populates the user-info redux state from the local-storage
     * * Attaches a resize observer to the topbar of the panel
     * * attaches the preventDrop callback to the onmouseleave event of document
     */
    useEffect(() => {
        const userInfo = CacheService.getUserInfo();
        dispatch(setUserInfoRedux(userInfo));
        const disconnect = observeTopbarHeight();
        document.addEventListener('mouseleave', preventDroppingFiles)
        return () => {
            document.removeEventListener('mouseleave', preventDroppingFiles)
            disconnect();
        }
    }, [])


    /**
     * Attaches a resize observer to the topbar of the panel that will update the state with each change in the height
     * of the topbar.
     *
     * @return {() => void} the disconnect method of observer to be used after the component unmounts
     */
    const observeTopbarHeight = () => {
        const observer = new ResizeObserver(entries => {
            const entry = entries.length ? entries[0] : null;
            if (entry) {
                setTopbarHeight(entry.contentRect.height ?? 0);
            }
        });
        observer.observe(document.getElementById(topbarId));
        return () => observer.disconnect();
    }


    /**
     * Listens for the changes in location and with each change, checks if the sidebar is disabled as it is loading
     * the applications or not.
     */
    useEffect(() => {
        //TODO: add routes that the sidebar is disabled in
        const disabled = !!matchPath(location.pathname, {
            path: [],
            exact: true
        });
        //TODO: add routes with no padding
        const noPadding = !!matchPath(location.pathname, {
            path: [],
            exact: true
        });
        setState(prevState => ({...prevState, disabled: disabled, noPadding: noPadding}))
    }, [location?.pathname]);

    /**
     * Toggles the collapsed state of the sidebar
     */
    const toggleCollapsed = () => {
        setState(prevState => ({
            ...prevState,
            collapsed: !prevState.collapsed,
            profileExpanded: (!prevState.collapsed) ? false : prevState.profileExpanded
        }));
    }

    /**
     * Toggles the profile expanded state of the sidebar
     */
    const toggleProfileExpanded = () => {
        setState(prevState => ({
            ...prevState,
            profileExpanded: !prevState.profileExpanded
        }));
    }

    /**
     * Toggles the dragging state of the panel
     * @param {DragEvent}   e
     * @param {boolean}     dragging
     */
    const toggleDragging = (e, dragging) => {
        if (!matchPath(location.pathname, {
            path: routes.panel.editProfile,
            exact: true,
        }))
            return;
        e.preventDefault();
        e.stopPropagation();
        // if the dragging starts,
        if (dragging) {
            e.dataTransfer.dropEffect = 'none';
        }
        setState(prevState => ({
            ...prevState,
            dragging: dragging
        }))
    }

    /**
     * Prevents the dropping of products into the panel.
     *
     * sets the dragging to false too
     * @param {DragEvent} e
     */
    const preventDroppingFiles = (e) => {
        e.preventDefault();
        e.stopPropagation();
        toggleDragging(e, false);
    }

    return (
        <PanelContext.Provider value={[state, setState]}>
            <CompanyContext.Provider value={[company, dispatchCompany]}>
                <div className={classnames('panel', {"dragging": state?.dragging})}
                     onDragEnter={(e) => toggleDragging(e, true)}
                     onDrop={(e) => preventDroppingFiles(e)}
                     onDragOver={e => e.preventDefault()}
                >
                    <PanelSidebar toggleCollapsed={toggleCollapsed} toggleProfileExpanded={toggleProfileExpanded}/>
                    <PanelTopbar/>
                    <main
                        className={classnames('main-section', {
                            'collapsed': state.collapsed,
                            'no-padding': state.noPadding
                        })}
                    >
                        <div style={{marginTop: topbarHeight}}>
                            {children(!company || match)}
                        </div>
                    </main>
                    <div className={'bottom-bar'}/>
                </div>
            </CompanyContext.Provider>
        </PanelContext.Provider>
    );
}

export default Panel;
