import {TextField} from "mui-rff";
import {TextField as MuiTextField} from "@material-ui/core";
import classnames from "classnames";
import InputMask from "react-input-mask";


export const Input = ({
                          placeholder = 'type...',
                          ariaLabel = 'input',
                          type = 'text',
                          required = false,
                          fullWidth = true,
                          className = '',
                          label,
                          disabled,
                          multiline,
                          rows,
                          value,
                          ...props
                      }) => {

    return (
        <MuiTextField
            rows={rows}
            aria-multiline={multiline}
            multiline={multiline}
            disabled={disabled}
            fullWidth={fullWidth}
            required={required}
            type={type}
            variant={'standard'}
            label={label}
            className={classnames(className, 'app-input')}
            placeholder={placeholder}
            inputProps={{'aria-label': ariaLabel}}
            value={value}
            {...props}
        />

    );
}

export const FormInput = ({
                              placeholder = 'type...',
                              ariaLabel = 'input',
                              type = 'text',
                              required = false,
                              fullWidth = true,
                              className = '',
                              label,
                              helperText,
                              name,
                              disabled,
                              multiline,
                              rows,
                              onInput,
                              variant,
                              ...props
                          }) => {
    return (
        <TextField
            rows={rows}
            aria-multiline={multiline}
            multiline={multiline}
            disabled={disabled}
            helperText={helperText}
            fullWidth={fullWidth}
            required={required}
            type={type}
            variant={variant ?? 'standard'}
            label={label}
            name={name}
            className={classnames(className, 'app-input')}
            placeholder={placeholder}
            inputProps={{'aria-label': ariaLabel, onInput: onInput}}
            {...props}
        />
    );
}


export const PhoneNumberInput = ({
                                     name,
                                     required,
                                     label,
                                     disabled,
                                     value,
                                     onChange,
                                 }) => {
    return (

        <InputMask mask={"(999)999-9999"} disabled={disabled} value={value} onChange={onChange} maskChar={''}>
            {(inputProps) => <TextField
                required={required}
                label={label}
                name={name}
                className={'app-input'}
                {...inputProps}
            />}
        </InputMask>

    );
}
