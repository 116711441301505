import React, {useLayoutEffect, useState} from "react";
import {ReactComponent as AdminFeatureCheckbox} from "../../../../../../../../assets/images/views/public-views/register/admin-feature-checkbox.svg";


const FeaturesSection = ({title, body}) => {
    const [[blackTitle, blueTitle], setTitle] = useState(['', '']);

    /**
     * Listens for the changes in the title of the step and with each change:
     * Splits the title into black and blue sections
     */
    useLayoutEffect(() => {
        splitTitle(title)
    }, [title])

    /**
     * Splits the title to black and blue sections
     * The last word of the title is blue, the rest is black
     */
    const splitTitle = () => {
        const words = title?.split(' ') ?? [];
        if (!words?.length) {
            return;
        }
        if (words.length === 1) {
            setTitle(['', words[0]]);
        }
        setTitle([
            words.filter((_, index) => index !== words.length - 1).join(' '),
            words[words.length - 1],
        ]);
    }

    return (
        <>
            <div
                className='d-flex justify-content-center align-items-center align-items-md-start flex-column text-break features'>
                <h4 className='title text-center text-md-start'>
                    {blackTitle}
                    <span className='primary-color mx-1'>{blueTitle}</span>
                </h4>
                <table className={'border-0'}>
                    <tbody>
                    {
                        body?.filter(e => e?.length)?.map((text, index) => (
                            <tr className={'body'} key={index}>
                                <td className={'pt-2 pb-1'}>
                                    <AdminFeatureCheckbox className={'me-2'}/>
                                </td>
                                <td>
                                    <p>
                                        {text}
                                    </p>
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default FeaturesSection;
