import * as Yup from "yup";
import ValidateMessages from "../../../../../../../../core/constants/texts/vallidate-messages";
import {makeRequired, makeValidate} from "mui-rff";
import Form from "../../../../../../../components/base/form";
import React, {useContext, useState} from "react";
import {Col} from "react-bootstrap";
import {FormInput} from "../../../../../../../components/base/input";
import RegexValidations from "../../../../../../../../core/services/regex_validations";
import {useTranslation} from "react-i18next";
import {CheckBoxForm} from "../../../../../../../components/base/checkbox";
import {ReactComponent as TermsIcon} from "../../../../../../../../assets/images/views/public-views/register/checkbox.svg";
import {ReactComponent as TermsIconChecked} from "../../../../../../../../assets/images/views/public-views/register/checkbox-checked.svg";
import {RegistrationContext} from "../../../../../../../contexts/registration";
import {registrationStepperStepIds} from "../../../../../../../../core/constants/ids";
import classnames from "classnames";
import {RegistrationReducerActions} from "../../../../../../../reducers/registration";

const formNames = {
    firstName: 'firstName',
    lastName: 'lastName',
    email: 'email',
    phone: 'phone',
    terms: 'terms',
};

const schema = Yup.object().shape({
    [formNames.firstName]: Yup.string().nullable().required(ValidateMessages.required),
    [formNames.lastName]: Yup.string().nullable().required(ValidateMessages.required),
    [formNames.email]: Yup.string().nullable().email().required(ValidateMessages.required),
    [formNames.phone]: Yup.string().nullable().required(ValidateMessages.required),
    [formNames.terms]: Yup.boolean().nullable().required(ValidateMessages.required),
});

const FormSection = () => {
    const [emailValidation, setEmailValidation] = useState({
        validating: false,
        message: null,
        error: false,
        email: null
    })
    const [registrationState, registrationReducer] = useContext(RegistrationContext);
    const validate = makeValidate(schema);
    const required = makeRequired(schema);
    const {t} = useTranslation();

    const translations = t('views.public.registration.userInfo.form', {returnObjects: true});

    /**
     * Submits the values of this form.
     *
     * Before the submission of the values in the reducer the following are checked:
     *
     * 1. whether the terms checkbox is checked, and if not, shows the appropriate error
     * 2. whether the email address is valid and if not, shows the appropriate error
     *
     * if any of the checks failed, the state of the reducer is submitted with an invalid state
     * @param {any} values
     * @param {any} form
     * @param {function(errors:any): void} errorCallback
     */
    const onSubmit = async (values, form, errorCallback) => {
        let termsError = false;
        if (!values[formNames.terms]) {
            errorCallback({
                [formNames.terms]: ValidateMessages.required,
            })
            termsError = true;
        }
        const emailError = !await validateEmail(values[formNames.email]);
        if (emailError) {
            errorCallback({
                [formNames.email]: emailValidation.message,
            })
        }
        const hasError = emailError || termsError;
        registrationReducer({
            type: RegistrationReducerActions.submitStep,
            payload: {
                stepName: registrationStepperStepIds.userInfo,
                values,
                submitted: true,
                valid: !hasError,
            }
        });
        if (!hasError) {
            registrationReducer({type: RegistrationReducerActions.nextStep});
        }
    }


    /**
     * Validates the email of the user by checking its length, its validity through regex and then calling api for
     * duplication check
     * @param email {string}
     * @return {Promise<boolean>} whether the email is valid or not.
     */
    const validateEmail = async (email) => {
        if (email !== emailValidation.email) {
            setEmailValidation(prevState => ({...prevState, email: email}));
        } else {
            return !emailValidation.error;
        }
        if (!email?.length) {
            setEmailValidation(prevState => ({...prevState, error: true, message: ValidateMessages.required}));
            return false;
        }
        if (!RegexValidations.email.test(email?.toLowerCase())) {
            setEmailValidation(prevState => ({
                ...prevState,
                error: true,
                message: ValidateMessages.incorrectType(t('utils.formValidation.types.email')),

            }));
            return false;
        }
        setEmailValidation(prevState => ({...prevState, validating: true}));
        //TODO: call api for validation email
        // const validateRes = await Api.validateRegistrationEmail(email);
        const validateRes = {
            resultFlag: true,
            message: 'Email is wrong'
        };
        if (!validateRes.resultFlag) {
            setEmailValidation(prevState => ({
                ...prevState,
                validating: false,
                error: true,
                message: validateRes.message
            }));
            return false;
        }
        setEmailValidation(prevState => ({...prevState, validating: false, error: false, message: null}));
        return true;
    }

    /**
     * Validates the Email input of the form for being non empty, an email, and not a duplicate.
     * @param value {string}
     * @return {Promise<string>}
     */
    const validateEmailFormat = async (value) => {
        if (!value?.length) {
            return ValidateMessages.required;
        }
        if (!RegexValidations.email.test(value?.toLowerCase())) {
            return ValidateMessages.incorrectType(t('utils.formValidation.types.email'));
        }
        return null;
    }

    /**
     * Navigates the user to the BizCore for authentication
     */
    const navigateToLogin = () => {
        if (window.bizpire?.navigateToCoreLogin) {
            window.bizpire?.navigateToCoreLogin();
        }
    }

    /**
     * Depending on the state of the checkbox:
     *
     * if checkbox is checked  => unchecks the checkbox
     * if the checkbox is not checked => opens the terms and conditions dialog and awaits its response
     * @param {any} form the user-info form
     * @param {any} values the current values of the form
     */
    const ToggleTermsAndConditionsCheckbox = (form, values) => {
        if (values[formNames.terms]) {
            form.change(formNames.terms, false);
            return;
        }
        if (window.bizpire?.termsAndConditionsDialog) {
            window.bizpire?.termsAndConditionsDialog(true, false, (accepted) => {
                if (accepted && !values[formNames.terms]) {
                    form.change(formNames.terms, true);
                }
                if (!accepted && values[formNames.terms]) {
                    form.change(formNames.terms, false);
                }
            });
        }
    }


    return (
        <>
            <div className={'registration-form-card'}>
                <Col xs={12} className={'mb-4 flex-row-center'}>
                    <p className={'title'}>
                        {t('views.public.registration.steps.userInfo')}
                    </p>
                </Col>
                <Form
                    onSubmit={onSubmit}
                    className={'row'}
                    validate={validate}
                    initialValues={registrationState[registrationStepperStepIds.userInfo]}
                    render={({form, values}) => {
                        const colClassName = 'px-4 mb-4 mt-2';
                        return (
                            <>
                                <Col xs={12} sm={6} md={6} className={classnames(colClassName)}>
                                    <FormInput
                                        placeholder={translations.firstName.placeholder}
                                        label={translations.firstName.title}
                                        name={formNames.firstName}
                                        required={required[formNames.firstName]}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={6} className={classnames(colClassName)}>
                                    <FormInput
                                        placeholder={translations.lastName.placeholder}
                                        label={translations.lastName.title}
                                        name={formNames.lastName}
                                        required={required[formNames.lastName]}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={6} className={classnames(colClassName)}>
                                    <FormInput
                                        placeholder={translations.email.placeholder}
                                        label={translations.email.title}
                                        name={formNames.email}
                                        required={required[formNames.email]}
                                        error={!!emailValidation.error}
                                        helperText={!!emailValidation.error ? emailValidation.message : null}
                                        onBlur={(e) => validateEmail(e.target.value)}
                                        fieldProps={{
                                            validate: validateEmailFormat
                                        }}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={6} className={classnames(colClassName)}>
                                    <FormInput
                                        placeholder={translations.phone.placeholder}
                                        label={translations.phone.title}
                                        name={formNames.phone}
                                        required={required[formNames.phone]}
                                    />
                                </Col>
                                <Col xs={12} className={'flex-row-center mt-3'}>
                                    <CheckBoxForm
                                        name={formNames.terms}
                                        required={required[formNames.terms]}
                                        className={'registration-checkbox'}
                                        data={{
                                            label: translations.terms,
                                            value: values[formNames.terms],
                                        }}
                                        onChange={() => ToggleTermsAndConditionsCheckbox(form, values)}
                                        checkedIcon={<TermsIconChecked/>}
                                        icon={<TermsIcon/>}
                                    />
                                </Col>
                                <Col xs={12} className={'flex-row-center mt-4 mb-2'}>
                                    <button
                                        className={'button secondary px-8 py-2'}
                                        disabled={emailValidation.validating}
                                        type={'submit'}>
                                        {
                                            emailValidation.validating
                                                ? t('views.public.registration.userInfo.form.button.validating')
                                                : t('views.public.registration.userInfo.form.button.text')
                                        }
                                    </button>
                                </Col>
                                <Col xs={12} className={'mt-4 flex-row-center'}>
                                    <p className={'login'}>
                                        {t('views.public.registration.userInfo.form.loginText')}
                                    </p>
                                    <button className={'ml-2 button secondary text'}
                                            onClick={navigateToLogin}>
                                        {t('views.public.registration.userInfo.form.loginButton')}
                                    </button>
                                </Col>
                            </>
                        );
                    }}
                />
            </div>
        </>
    );
}

export default FormSection;
