import React, {useContext, useLayoutEffect, useRef, useState} from "react";
import * as Yup from "yup";
import {AnimationServiceContext} from "../../../../../contexts/animation-service";
import {makeRequired, makeValidate} from "mui-rff";
import {AnimationContextConstants} from "../../../../../../core/constants/enums";
import {SearchFormInput} from "../../../../base/input/search";
import classnames from "classnames";
import {Col, Collapse, Row} from "react-bootstrap";
import Form from "../../../../base/form";
import {useTranslation} from "react-i18next";
import {FormInput} from "../../../../base/input";
import {ReactComponent as ArrowIcon} from "../../../../../../assets/images/small-arrow-icon.svg";
import useRouter from "../../../../../hooks/use-router";
import {PartListQueryNames} from "../../../../../../core/constants/query-names";

// Form names for the
const formNames = {
    keyword: 'keyword',
    weight: 'weight',
    height: 'height',
    width: 'width',
    depth: 'depth'
}

// Creates schema for form validation
const makeSchema = (validationTranslation) => {
    return Yup.object().shape({
        [formNames.keyword]: Yup.string().when(formNames.keyword, {
            is: val => !val?.length,
            then: Yup.string().nullable(),
            otherwise: Yup.string().nullable().required(validationTranslation?.required ?? '')
        }),
        [formNames.weight]: Yup.number().nullable().typeError(validationTranslation?.incorrectType.concat(validationTranslation?.types.number)),
        [formNames.height]: Yup.number().nullable().typeError(validationTranslation?.incorrectType.concat(validationTranslation?.types.number)),
        [formNames.width]: Yup.number().nullable().typeError(validationTranslation?.incorrectType.concat(validationTranslation?.types.number)),
        [formNames.depth]: Yup.number().nullable().typeError(validationTranslation?.incorrectType.concat(validationTranslation?.types.number)),
    }, [[formNames.keyword, formNames.keyword]]);
}

const PartsSearchSection = ({filters}) => {
    const animationService = useContext(AnimationServiceContext);
    const {query, location, history, stringifyUrl} = useRouter();
    const {t} = useTranslation();
    const translation = t('views.panel.company.products.list.parts.searchSection', {returnObjects: true});
    const validationTranslation = t('utils.formValidation', {returnObjects: true});
    const [isShowingCollapsedForm, setIsShowingCollapsedForm] = useState(false);
    const schema = makeSchema(validationTranslation);
    const validate = makeValidate(schema);
    const required = makeRequired(schema);
    const isInitialRender = useRef(true);


    /**
     * As soon as the component layout is done:
     * Creates the animation for search button between tabs.
     */
    useLayoutEffect(() => {
        animationService.removeAnimation(AnimationContextConstants.searchParts.name)
        animationService.addAnimation(AnimationContextConstants.searchParts)
    }, []);

    /**
     * Listens to the changes in showCollapsedForm and with each change:
     * Determines how to animate the search button based on collapsed for being open or not
     */
    useLayoutEffect(() => {
        if (isInitialRender?.current) {
            isInitialRender.current = false;
            return;
        }
        const animation = animationService.getAnimation(AnimationContextConstants.searchParts.name)
        let animationFrame;
        if (isShowingCollapsedForm) animationFrame = window.requestAnimationFrame(() => animation.play());
        else animationFrame = window.requestAnimationFrame(() => animation.reverse());

        return () => {
            if (!animationFrame) return
            cancelAnimationFrame(animationFrame)
            animationFrame = null
        }
    }, [isShowingCollapsedForm]);

    /**
     * Handles searching for parts:
     * Adds the search filter to the url search query
     */
    const onSearch = (newQuery) => {
        history.push(stringifyUrl({
            url: location.pathname,
            query: {
                ...query,
                [PartListQueryNames.keyword]: newQuery[formNames.keyword] ?? undefined,
                [PartListQueryNames.weight]: !isShowingCollapsedForm ? undefined : (newQuery[formNames.weight] ?? undefined),
                [PartListQueryNames.height]: !isShowingCollapsedForm ? undefined : (newQuery[formNames.height] ?? undefined),
                [PartListQueryNames.width]: !isShowingCollapsedForm ? undefined : (newQuery[formNames.width] ?? undefined),
                [PartListQueryNames.depth]: !isShowingCollapsedForm ? undefined : (newQuery[formNames.depth] ?? undefined),
            }
        }));
    }

    /**
     * Toggles the visibility of the advanced search:
     * - Sets the value of [isShowingCollapsedForm] to its counter value
     */
    const showAdvanceSearchOptions = () => {
        setIsShowingCollapsedForm(prevState => !prevState)
    }


    return (
        <Form
            onSubmit={onSearch}
            validate={validate}
            className={'d-flex flex-column mt-4'}
            initialValues={filters}
            render={({submitting}) => {
                return (
                    <>
                        <SearchFormInput
                            placeholder={translation?.searchPlaceHolder ?? ''}
                            fullWidth
                            required={required[formNames.keyword]}
                            name={formNames.keyword}
                            buttonProps={{
                                className: classnames('button primary'),
                                children: translation?.search ?? '',
                                type: "submit",
                                id: AnimationContextConstants.searchParts.baseId,
                                disabled: submitting
                            }}
                        />
                        <div
                            className={classnames('d-flex w-100 flex-row align-items-center justify-content-end advance-search', {'showing': isShowingCollapsedForm})}
                            onClick={showAdvanceSearchOptions}
                        >
                            <ArrowIcon/>
                            <p>{translation?.advanceSearch}</p>
                        </div>

                        <Collapse in={isShowingCollapsedForm}>
                            <div>
                                <div className={'advanced-search-section'}>
                                    <Row>
                                        <Col xs={12} md={6} lg={2} className={'search-section-field'}>
                                            <FormInput
                                                placeholder={translation?.weightPlaceHolder}
                                                name={formNames.weight}
                                                required={required[formNames.weight]}
                                                label={translation?.weightPlaceHolder}
                                            />
                                        </Col>
                                        <Col xs={12} md={6} lg={2} className={'search-section-field'}>
                                            <FormInput
                                                placeholder={translation?.heightPlaceHolder}
                                                name={formNames.height}
                                                required={required[formNames.height]}
                                                label={translation?.heightPlaceHolder}
                                            />
                                        </Col>
                                        <Col xs={12} md={6} lg={2} className={'search-section-field'}>
                                            <FormInput
                                                placeholder={translation?.widthPlaceHolder}
                                                name={formNames.width}
                                                required={required[formNames.width]}
                                                label={translation?.widthPlaceHolder}
                                            />
                                        </Col>
                                        <Col xs={12} md={6} lg={2} className={'search-section-field'}>
                                            <FormInput
                                                placeholder={translation?.depthPlaceHolder}
                                                name={formNames.depth}
                                                required={required[formNames.depth]}
                                                label={translation?.depthPlaceHolder}
                                            />
                                        </Col>
                                        <Col xs={12} lg={2} className={'d-flex justify-content-center'}>
                                            <button
                                                disabled={submitting}
                                                id={AnimationContextConstants.searchParts.destinationId}
                                                type={'submit'}
                                                className={'button primary invisible mt-3 animated-part-search-button'}
                                            >
                                                {`    ${translation?.search ?? ''}    `}
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Collapse>
                    </>
                );
            }}
        />
    );
}

export default PartsSearchSection;
