import {createContext} from "react";

/**
 * @typedef {{
 *      collapsed: boolean,
 *      disabled: boolean,
 *      noPadding: boolean,
 *      canCollapse: boolean,
 *      profileExpanded: boolean,
 *      dragging: boolean,
 * }} PanelContextState
 */

/**
 * The initial Values used for the panel
 * @type {[PanelContextState & ((prevState: PanelContextState) => PanelContextState)]}
 */
export const PanelContextInitialValues = [
    {
        collapsed: false,
        disabled: false,
        noPadding: false,
        canCollapse: true,
        profileExpanded: false,
        dragging: false,
    },
    ({...prevState}) => {
    }
]
/**
 * Context used to store the panel state.
 */
export const PanelContext = createContext(PanelContextInitialValues);
