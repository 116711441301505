import {ApiInterceptors, ApiRequestDs, ApiResponseDs} from "./type-declarations";
import ApiResponseCodes from "../../models/static/api-response-codes";
import axios, {AxiosError, AxiosResponse} from "axios";
import Texts from "../../models/static/texts";
import {toast} from "react-toastify";

/**
 * The Api executor of this application.
 */
class ApiExecutor {

    /**
     * The effects that will be run after the execution.
     */
    private readonly effects: Array<Function> = [
        ApiExecutor.showToast,
    ];

    /**
     * The instance with which all the api calls are handled.
     */
    private readonly instance = axios.create();

    /**
     * The list of interceptor ids that the current instance uses.
     */
    private readonly instanceInterceptorIds: { request: Array<number>, response: Array<number> } = {
        request: [],
        response: []
    };

    /**
     * Injects api interceptors to the axios instance used in this interface.
     * @param interceptors
     */
    public injectInterceptors(interceptors: Partial<ApiInterceptors>): void {
        for (const id of this.instanceInterceptorIds.request)
            this.instance.interceptors.request.eject(id);
        for (const id of this.instanceInterceptorIds.response)
            this.instance.interceptors.response.eject(id);
        for (const [onFulfilled, onRejected] of interceptors.request ?? [])
            this.instanceInterceptorIds.request.push(this.instance.interceptors.request.use(onFulfilled, onRejected));
        for (const [onFulfilled, onRejected] of interceptors.response ?? [])
            this.instanceInterceptorIds.response.push(this.instance.interceptors.response.use(onFulfilled, onRejected));
    }

    /**
     * Executes the api call with the given args and then parses the response.
     * @param request
     */
    async execute(request: ApiRequestDs): Promise<ApiResponseDs> {
        let response: ApiResponseDs;
        try {
            response = ApiExecutor.onResponseReceived(await this.instance.request(request));
        } catch (e: any) {
            response = ApiExecutor.onErrorReceived(e);
        }
        this.runExecutionEffects(request, response);
        return response;
    }

    /**
     * Runs the effects registered in this executor after the execution of the api call.
     * @param request
     * @param response
     */
    runExecutionEffects(request: ApiRequestDs, response: ApiResponseDs): void {
        for (const effect of this.effects ?? []) {
            effect(request, response);
        }
    }


    /**
     * Creates a placeholder response data-structure.
     *
     * * this method is primarily used for creating the response object when an error happens in the api call.
     * @param statusCode
     */
    private static createPlaceholderResponseDs(statusCode: number): ApiResponseDs {
        return {
            code: statusCode,
            resultFlag: false,
            data: null,
            message: undefined,
            aborted: statusCode === ApiResponseCodes.aborted,
        };
    }

    /**
     * Executes the logic for when an error is thrown while the api call is being made or after the response of the
     * api call is received.
     * @param error
     */
    private static onErrorReceived(error: AxiosError & {}): ApiResponseDs {
        if (error.response) {
            // Request made and server responded
            return ApiExecutor.createPlaceholderResponseDs(
                error.response?.status ?? ApiResponseCodes.serverNotResponded
            );
        }
        if (error.request) {
            if (axios.isCancel(error)) {
                // cancelled / aborted
                return ApiExecutor.createPlaceholderResponseDs(ApiResponseCodes.aborted);
            }
            // The request was made but no response was received
            return ApiExecutor.createPlaceholderResponseDs(ApiResponseCodes.serverNotResponded);
        }

        // Something happened in setting up the request that triggered an Error
        return ApiExecutor.createPlaceholderResponseDs(ApiResponseCodes.requestFailed);
    }

    /**
     * Creates a response data-structure from the given response object and the statusCode.
     *
     * @param response
     * @param statusCode
     */
    private static createResponseDs(response: ApiResponseDs, statusCode: number): ApiResponseDs {
        const code = response.code ?? statusCode;
        return {
            ...response,
            resultFlag: response.resultFlag ?? false,
            data: response?.data,
            code: code,
            aborted: code === ApiResponseCodes.aborted,
        };
    }

    /**
     * Executes the logic for when the response of the api call is received without any axios-specific or http
     * specific errors.
     *
     * @param response
     */
    private static onResponseReceived(response: AxiosResponse): ApiResponseDs {
        const internalResponse: ApiResponseDs = response.data;
        return ApiExecutor.createResponseDs(
            internalResponse,
            internalResponse.code ?? response.status,
        );
    }

    // ################################         EFFECTS         ################################

    /**
     * Shows toasts associated with the status of the api response if the permission was granted in the request.
     *
     * @param request
     * @param response
     */
    private static showToast(request: ApiRequestDs, response: ApiResponseDs): void {
        if (response?.resultFlag) {
            const message = response?.message ?? '';
            if (!!message?.length && request.showSuccessToast) {
                toast.success(message);
            }
            return;
        }
        if (!response?.aborted && request.showErrorToast) {
            // only show the toast for non-aborted responses.
            let message = response?.message;
            if (!message) {
                const messageFromResponseCode = ApiResponseCodes.getMessageFromCode(response?.code)
                message = messageFromResponseCode ?? Texts.serverError;
            }
            toast.error(message);
        }
    }
}


export default ApiExecutor;
