import React from "react";
import {makeRequired, makeValidate} from "mui-rff";
import * as Yup from "yup";
import {SearchFormInput} from "../../../base/input/search";
import classnames from "classnames";
import Form from "../../../base/form";
import {useTranslation} from "react-i18next";
import useRouter from "../../../../hooks/use-router";

// Form keys
const formKeys = {
    keywords: 'keyword'
}

/**
 * Creates the schema of the users form section
 */
const makeSchema = (validationTranslation) => {
    return Yup.object().shape({
        [formKeys.keywords]: Yup.string().when(formKeys.keywords, {
            is: val => !val?.length,
            then: Yup.string().nullable(),
            otherwise: Yup.string().nullable().required(validationTranslation?.required ?? '')
        }),
    }, [[formKeys.keywords, formKeys.keywords]]);
}

const UsersSearchSection = ({filters}) => {
    const {t} = useTranslation();
    const {query, location, history, stringifyUrl} = useRouter()
    const translation = t("views.panel.company.usersManagement", {returnObjects: true});
    const validationTranslation = t('utils.formValidation', {returnObjects: true})
    const schema = makeSchema(validationTranslation)
    const validate = makeValidate(schema);
    const required = makeRequired(schema);

    /**
     * Sets the query for performing search over list of users
     * @param values
     */
    const onFormSearched = (values) => {
        // if user searches with empty keyword, removes keyword from query params
        if (!Object.keys(values).length) {
            history.push(stringifyUrl({
                url: location.pathname,
                query: {}
            }))
            return;
        }
        history.push(stringifyUrl({
            url: location.pathname,
            query: {
                ...query,
                ...values,
            }
        }))

    }

    return (
        <>
            <Form
                onSubmit={onFormSearched}
                validate={validate}
                className={'d-flex flex-column users-search-section'}
                initialValues={filters}
                render={({submitting}) => {
                    return (
                        <>
                            <SearchFormInput
                                placeholder={translation?.form?.keywords?.placeHolder ?? ''}
                                fullWidth
                                required={required[formKeys.keywords]}
                                name={formKeys.keywords}
                                buttonProps={{
                                    className: classnames('button primary'),
                                    children: translation?.actions?.submit ?? '',
                                    type: "submit",
                                    disabled: submitting
                                }}
                            />
                        </>
                    );
                }}
            />
        </>
    );
}

export default UsersSearchSection;
