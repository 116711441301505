import React, {useContext, useState} from "react";
import {Input} from "../../../../components/base/input";
import {CircularProgress} from "@material-ui/core";
import {Search} from "@material-ui/icons";
import {useTranslation} from "react-i18next";
import classnames from "classnames";
import {CompanyContext} from "../../../../contexts/company";

//TODO: the following are not done:
// 1. the api call for magic search and handling its result
// 2. the ux ui for previewing the result of magic search (my idea)


const PanelTopbarSearchField = ({disabled}) => {
    const [company] = useContext(CompanyContext);
    const [value, setValue] = useState('');
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();
    const translations = t('containers.panel.topbar.searchField', {returnObjects: true});


    /**
     * Listens for the pressed keys and with each of them:
     *
     * * if enter is pressed, invokes the magic search
     * * if esc is pressed, clears the input
     * @param {KeyboardEvent} e
     */
    const onKeyDown = (e) => {
        switch (e.key) {
            case 'Enter':
                search(value);
                break;
            case 'Escape':
                setValue('');
                break;
            default:
                break;

        }
    }

    /**
     * Searches for the given code in the entire system and returns the given result based on its findings.
     *
     *
     * @param {string} code the code to search for
     */
    const search = (code) => {
        setLoading(true);
        //TODO: call magic search api.
        setLoading(false);
    }

    return (
        <>
            <Input
                disabled={disabled}
                placeholder={translations?.placeholder}
                onKeyDown={onKeyDown}
                value={value}
                className={classnames('search-field', {'value': !!value?.length})}
                onChange={e => setValue(e.target.value)}
                InputProps={{
                    endAdornment: !loading
                        ? <Search color={'primary'}/>
                        : <CircularProgress color={'primary'} size={20}/>
                }}

            />
        </>
    )
}

export default PanelTopbarSearchField;
