import React, {useState} from 'react';
import {ReactComponent as MoreInfo} from '../../../../assets/images/views/location/more.svg';
import {Popover} from "@material-ui/core";
import useRouter from "../../../hooks/use-router";
import {routeFunctions} from "../../../routes";
import {LocationTypes} from "../../../../core/constants/enums";
import classnames from "classnames";
import {useTranslation} from "react-i18next";

const LocationCard = ({location, onEdit, onRemove, type}) => {
    const {history, params: {company, warehouse, aisle, section}} = useRouter();
    const [popover, setPopover] = useState()
    const {t} = useTranslation();
    const translations = t('components.locationCard', {returnObjects: true});

    /**
     * Prevents the event to propagate upwards
     * @param {Event} e
     * @param {function} callback
     * @param {any} args
     */
    const preventEventPropagation = (e, callback, ...args) => {
        e.preventDefault();
        e.stopPropagation();
        if (callback) {
            callback(e, ...args)
        }
    }

    /**
     * Navigates the user using the provided location type:
     * If the location type is aisle => navigates the user to the list of sections
     * If the location type is section => navigates the user to the list of rows
     * If the location type is row => navigates the user to the row info page (single)
     * by default: does nothing
     */
    const onLocationClick = () => {
        if (!!popover) return
        switch (type) {
            case LocationTypes.aisle:
                history.push(routeFunctions.panel.company.location.sections(company, warehouse, location.id));
                break
            case LocationTypes.section:
                history.push(routeFunctions.panel.company.location.rows(company, warehouse, aisle, location.id));
                break
            case LocationTypes.row:
                history.push(routeFunctions.panel.company.location.rowItems(company, warehouse, aisle, section, location.id));
                break
            default:
                break;
        }
    }

    return (
        <div className='location-card' onClick={onLocationClick}>
            <div className='more-info'>
                <button
                    className='button primary text'
                    onClick={(e) => preventEventPropagation(e, (e) => setPopover(e.currentTarget))}
                >
                    <MoreInfo/>
                </button>
                <Popover
                    open={!!popover}
                    anchorEl={popover}
                    onClose={() => setPopover(null)}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    classes={{paper: 'button-dropdown-popover location-actions-popover'}}
                >
                    <div className={'content'}>
                        <div
                            className={classnames('option')}
                            onClick={(e) => preventEventPropagation(e, () => setPopover(false) || onEdit(location))}>
                            {translations?.actions?.edit ?? ''}
                        </div>
                        <div
                            className={classnames('option', 'red')}
                            onClick={(e) => preventEventPropagation(e, () => setPopover(false) || onRemove(location))}>
                            {translations?.actions?.remove ?? ''}
                        </div>
                    </div>
                </Popover>
            </div>
            <div className='code'>
                {location?.code ?? ''}
            </div>
        </div>
    )
};

export default LocationCard;
