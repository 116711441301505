import {createContext} from "react";

/**
 * @typedef {{types: any[], statuses: any[]}} LocationFormDataContextState
 */

/**
 * The initial Values used for the location form data context
 * @type {LocationFormDataContextState}
 */
export const LocationFormDataContextInitialValues = {
    types: [],
    statuses: [],
};

/**
 * Context used to store the form data of location views. this data is then used for report generation.
 */
export const LocationFormDataContext = createContext(LocationFormDataContextInitialValues);
