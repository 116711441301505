// **************** APPLICATION COLORS ****************

export const primaryColor = '#009AD8';
export const primaryColorAlternate = '#33AEE0';
export const lightPrimaryColor = '#EBF7FC';
export const lighterPrimaryColor = '#F5FBFD';
export const lightestPrimaryColor = '#F6FDFF';

export const secondaryColor = '#FF7612';
export const lightSecondaryColor = '#FF9D57';
export const lighterSecondaryColor = 'rgba(255, 118, 18, 0.04)';

export const white = 'white';

export const green = '#40B92C';

export const red = 'rgba(255, 18, 18, 0.87)';
export const redLight = '#FFE7E7';

export const grey = `rgba(180, 174, 173, 1)`;
export const greyLight = `rgba(242, 242, 242, 1)`;

export const primaryTextColor = '#000000';
export const secondaryTextColor = 'rgba(0, 0, 0, 0.86)';
export const disabledTextColor = 'rgba(0, 0, 0, 0.4)';
export const hintTextColor = 'rgba(0, 0, 0, 0.54)';

// **************** Dashboard Page COLORS ****************

export const barChartFirstColumnColor = `#33E0BC`;
export const barChartSecondColumnColor = `#ADF489`;
export const barChartThirdColumnColor = `#d28838`;
