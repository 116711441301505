import React, {useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {CompanyContext} from "../../../../../contexts/company";
import useIsMounted from "../../../../../hooks/use-is-mounted";
import {useTranslation} from "react-i18next";
import Api from "../../../../../../core/services/api_service";
import InventorySchemaVisualizer from "../../../../../../packages/inventory-schema-visualizer/ui";
import {InventorySchemaVisualizerModes} from "../../../../../../packages/inventory-schema-visualizer/core/models/constants/enums";
import useRouter from "../../../../../hooks/use-router";
import {toast} from "react-toastify";
import {routeFunctions} from "../../../../../routes";
import InventorySchemaVisualizerApiService from "../../../../../../packages/inventory-schema-visualizer/core/services/api";
import TabsLayout from "../../../../../components/app-specific/tabs-layout";
import {CircularProgress} from "@material-ui/core";

const initialRowItemsDialogState = {open: false, selectedRowId: null};

const ViewInventorySchemaView = () => {
    const {history} = useRouter();
    const [company] = useContext(CompanyContext);
    const [rowItemForRelocation, setRowItemForRelocation] = useState(null);
    const [rowItemsDialogState, setRowItemsDialogState] = useState(initialRowItemsDialogState);
    const [relocatingItem, setRelocatingItem] = useState(false);
    const [warehouses, setWarehouses] = useState([]);
    const [loading, setLoading] = useState(true);
    const isMounted = useIsMounted();
    const initialRender = useRef(true);
    /**@type {React.MutableRefObject<IInventorySchemaVisualizerRef>}*/
    const visualizerRef = useRef();

    const tabs = useMemo(() => warehouses.map(warehouse => ({
        label: warehouse.title,
        value: warehouse.id,
    })), [warehouses])

    const highlightedRowsData = useMemo(() => {
        if (!rowItemForRelocation)
            return undefined;
        const locationAddress = rowItemForRelocation?.locationAddress;
        return [locationAddress.aisle.id, locationAddress.section.id, locationAddress.row.id];
    }, [rowItemForRelocation])

    const {t} = useTranslation();
    const translations = t("views.panel.company.inventorySchema.view");

    /**
     * Fetches the available warehouses of the selected company of the operator from the server.
     * @type {(function(): Promise<void>)|*}
     */
    const getCompanyWarehouses = useCallback(async () => {
        setLoading(true);
        const response = await Api.getWarehouses();
        if (!isMounted())
            return;
        if (response?.resultFlag)
            setWarehouses(response.data ?? []);
        setLoading(false);
    }, [isMounted])

    /**
     * With each change in the selected company of the panel:
     * - fetches the available warehouses of the selected company of the operator from the server.
     */
    useEffect(() => {
        getCompanyWarehouses().then();
    }, [getCompanyWarehouses, company?.id])

    /**
     * As soon as the [componentId] changes, resets the initialRender's ref value.
     */
    useLayoutEffect(() => {
        initialRender.current = true;
    }, [company?.id])

    /**
     * With each change in the [companyId] and [warehouses] selector value:
     * - if the warehouses list is empty navigates the user out of the view.
     */
    useEffect(() => {
        if (initialRender.current)
            return (initialRender.current = false) || void 0;
        if (warehouses.length || loading)
            return;
        toast.warn(translations.noWarehousesWarning);
        history.replace(routeFunctions.panel.company.location.warehouses(company.id));
    }, [company.id, history, warehouses.length, loading, translations.noWarehousesWarning]);

    /**
     * Relocates the provided [item] from the provided [locationAddress] to the row associated with the provided [rowId].
     * @type {(function({locationAddress: LocationAddressDs, item: LocationPartInstanceDs, count: number}, Array<number>): Promise<*>)}
     */
    const relocateItem = useCallback(async ({item, count, locationAddress}, [_, __, rowId]) => {
        let response;
        setRelocatingItem(true);
        if (item.isSerialized) {
            response = await InventorySchemaVisualizerApiService.changeSerializedItemsLocation(locationAddress.warehouse.id, {
                RowID: rowId,
                Instances: [item.instanceID],
            });
        } else {
            response = await InventorySchemaVisualizerApiService.changeUnSerializedItemsLocation(locationAddress.warehouse.id, {
                RowID: rowId,
                Code: item.partNumber,
                UnserializedList: [
                    {RowID: locationAddress.row.id, Count: count},
                ],
            })
        }
        if (!isMounted())
            return;
        if (response?.resultFlag) {
            // remove the relocation item:
            setRowItemForRelocation(null);
            visualizerRef.current.reFetchWarehouseData?.();
        }
        setRelocatingItem(false);
    }, [visualizerRef, isMounted]);

    /**
     * Depending on the value of the [rowItemForRelocation] and [rowItemsDialogState]:
     *
     * - if [rowItemForRelocation] does not exist, then opens the rowsItems dialog for the newly selected row.
     * - if [rowItemForRelocation] exists, then relocates the item to the newly selected row.
     * @type {function(Array<number>): any}
     */
    const onRowClicked = useCallback((data) => {
        if (relocatingItem)
            return;
        if (!rowItemsDialogState.open && !rowItemForRelocation) {
            // opening the dialog for items of a row.
            return setRowItemsDialogState({open: true, selectedRowId: data[data.length - 1]});
        }
        if (!rowItemsDialogState.open && !!rowItemForRelocation) {
            if (rowItemForRelocation.locationAddress.row.id === data[data.length - 1]) {
                // the same row is selected for relocation.
                return toast.warn('This item already belongs to the selected row, please choose a different row');
            }
            // a new row is selected after an item is set to be relocated.
            return relocateItem(rowItemForRelocation, data).then();
        }
    }, [relocatingItem, rowItemsDialogState.open, rowItemForRelocation, relocateItem]);

    /**
     * Removes the item that will be relocated.
     * @type {(function(): void)}
     */
    const removeItemForRelocation = useCallback(() => {
        if (relocatingItem)
            return;
        setRowItemForRelocation(null);
    }, [relocatingItem])

    /**
     * Sets the activeIndex of the tabs and also removes the [rowItemForRelocation] state value.
     * @param index
     */
    const onTabChanged = useCallback((warehouseId) => {
        if (!warehouseId)
            return;
        setRowItemForRelocation(null);
    }, [])

    return (
        <div className={'panel-card view-inventory-schema-view'}>
            <TabsLayout
                tabs={tabs}
                initialValue={warehouses?.[0]?.id}
                onChange={onTabChanged}
            >
                {(selectedWarehouseId) => {
                    const warehouse = warehouses.find(e => e.id === selectedWarehouseId);
                    if (!warehouse)
                        return null;
                    return (
                        <>
                            {
                                !!rowItemForRelocation &&
                                <div className={'my-4 mx-4 d-flex align-items-center'}>
                                    <p className={'text-sm mr-3'}>

                                        NOTE: You are relocating <span className={'font-weight-semi-bold'}>
                                {rowItemForRelocation.item.isSerialized ? rowItemForRelocation.item.serialNo : rowItemForRelocation.count}
                            </span> of <span className={'font-weight-semi-bold'}>{rowItemForRelocation.item.partNumber}</span> from
                                        Row: <span
                                        className={'font-weight-semi-bold'}>{rowItemForRelocation.locationAddress.row.code}</span>

                                    </p>
                                    <button
                                        className={'button text icon-button'}
                                        onClick={removeItemForRelocation}>
                                        <CircularProgress size={18} color={'secondary'}/>
                                    </button>
                                </div>
                            }
                            <InventorySchemaVisualizer
                                mode={InventorySchemaVisualizerModes.view}
                                warehouse={warehouse}
                                onRowClicked={onRowClicked}
                                highlightsRowsData={highlightedRowsData}
                            />
                        </>
                    )
                }}
            </TabsLayout>
        </div>
    )
}

export default ViewInventorySchemaView;
