import React from "react";
import {TableCellAlignments, TableCellTypes} from "../../../../../../../../core/constants/enums";
import {useTranslation} from "react-i18next";
import Table from "../../../../../../../containers/table";
import {dialogActions} from "../../../../../../../../redux/entities/dialog-slice";
import {useDispatch} from "react-redux";
import {ReactComponent as BarcodeIcon} from "../../../../../../../../assets/images/barcode-icon.svg";
import NoEntries from "../../../../../../../components/app-specific/no-entries";
import useRouter from "../../../../../../../hooks/use-router";
import {routeFunctions} from "../../../../../../../routes";

//Keys for the upper table
const upperTableCellKeys = {
    title: 'title',
    submitterAdmin: 'submitterAdmin',
    submitDate: 'submitDate',
    expiryDate: 'expiryDate',
    totalCount: 'totalCount',
    scannedCount: 'scannedCount'
}

//Keys for the recent logs table
const logsTableKeys = {
    partNumber: 'partNumber',
    serialNumber: 'serialNumber',
    count: 'count',
    dateTime: 'dateTime',
    operator: 'operator',
    location: 'location'
}

const PositionCountingInformation = ({data, loading}) => {
    const {history, params: {company}} = useRouter();
    const {t} = useTranslation();
    const errorMessage = t('views.panel.errorMessages', {returnObjects: true})
    const translation = t('views.panel.company.counting.position.single', {returnObjects: true});
    const dispatch = useDispatch();

    // list of information table cell structures
    const upperTableCells = [
        {
            title: translation?.informationTab?.upperTable?.title ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: upperTableCellKeys.title,
        },
        {
            title: translation?.informationTab?.upperTable?.submitterAdmin ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: upperTableCellKeys.submitterAdmin,
        },
        {
            title: translation?.informationTab?.upperTable?.submitDate ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.dateTime,
            name: upperTableCellKeys.submitDate,
            size: 2,
        },
        {
            title: translation?.informationTab?.upperTable?.expiryDate ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.dateTime,
            name: upperTableCellKeys.expiryDate,
            size: 2,
        },
        {
            title: translation?.informationTab?.upperTable?.totalCount ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.string,
            name: upperTableCellKeys.totalCount,
        },
        {
            title: translation?.informationTab?.upperTable?.scannedCount ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.string,
            name: upperTableCellKeys.scannedCount,
        },
    ];

    // list of logs table cell structures
    const logsTableCells = [
        {
            title: translation?.informationTab?.logsTable?.partNumber ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: logsTableKeys.partNumber,
        },
        {
            title: translation?.informationTab?.logsTable?.serialNumber ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: logsTableKeys.serialNumber,
            size: 2,
        },
        {
            title: translation?.informationTab?.logsTable?.count ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: logsTableKeys.count,
        },
        {
            title: translation?.informationTab?.logsTable?.datetime ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.dateTime,
            name: logsTableKeys.dateTime,
            size: 2
        },
        {
            title: translation?.informationTab?.logsTable?.operator ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: logsTableKeys.operator,
        },
        {
            title: translation?.informationTab?.logsTable?.location ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: logsTableKeys.location,
        },
    ];

    /**
     * Handles click on the barcode icon button:
     * - Opens dialog for showing the barcode
     * @param {string} partNumber for generating barcode from
     */
    const onBarcodeClicked = (partNumber) => {
        dispatch(dialogActions.configBarcodeDialog({
            open: true,
            onClose: () => dispatch(dialogActions.barcodeDialogToggle(false)),
            barcodeCode: partNumber,
        }));
    }

    /**
     * Handles click on the part number text button:
     * - Navigates user to part information single view
     * @param {number} partId
     */
    const onPartNumberClicked = (partId) => {
        // TODO: the whole process for showing part /instance full information could be changed
        //  double check all the dependant functionality for the whole application when apis are ready
        history.push(routeFunctions.panel.company.items.single.product(company, partId))
    }

    /**
     * Handles click on the serial number text button:
     * - Navigates user to the instance single view
     * @param {string} serialNumber
     */
    const onSerialNumberClicked = (serialNumber) => {
        // TODO: Implement this
    }


    /**
     * Handles click on the location code in the logs's table item
     * - Navigates user the location view
     * @param {string} locationCode
     */
    const onSelectedLocationClicked = (locationCode) => {

    }

    /**
     * Creates the data entries for upper information table
     * @return {TableData[]}
     */
    const createUpperInformativeTableDate = () => {
        return [
            {
                key: data?.id,
                [upperTableCellKeys.title]: data?.title,
                [upperTableCellKeys.submitterAdmin]: data?.submitterAdmin,
                [upperTableCellKeys.submitDate]: data?.submitDate,
                [upperTableCellKeys.expiryDate]: data?.expiryDate,
                [upperTableCellKeys.totalCount]: data?.totalCount,
                [upperTableCellKeys.scannedCount]: data?.scannedCount,
            }
        ];
    }

    /**
     * Creates the data entries for the recent logs table
     * @return {TableData[]}
     */
    const createRecentLogsTableData = () => {
        return data?.logs?.map((log) => ({
            key: log?.id,
            [logsTableKeys.partNumber]: () => (
                <div className={'d-flex flex-row align-items-center'}>
                    {
                        log?.partNo?.length > 0 &&
                        <div
                            onClick={() => onBarcodeClicked(log?.partNo)}
                            className={'barcode-icon-button'}
                        >
                            <BarcodeIcon/>
                        </div>
                    }
                    <p className={'text-button'} onClick={() => onPartNumberClicked(log?.partId)}>
                        {log?.partNo}
                    </p>
                </div>
            ),
            [logsTableKeys.serialNumber]: () => (
                <div className={'d-flex flex-row align-items-center'}>
                    {
                        log?.serialNo?.length > 0 &&
                        <div
                            onClick={() => onBarcodeClicked(log?.serialNo)}
                            className={'barcode-icon-button'}
                        >
                            <BarcodeIcon/>
                        </div>
                    }
                    <p className={'text-button'} onClick={() => onSerialNumberClicked(log?.serialNo)}>
                        {log?.serialNo}
                    </p>
                </div>
            ),
            [logsTableKeys.count]: log?.count,
            [logsTableKeys.dateTime]: log?.dateTime,
            [logsTableKeys.operator]: log?.operator,
            [logsTableKeys.location]: () => (
                <p
                    onClick={() => onSelectedLocationClicked(log?.locationCode)}
                    className={'text-button'}
                >
                    {log?.locationCode}
                </p>
            ),
        }));
    }


    return (
        <>
            <div className={'spire-counting-single-information'}>
                <Table
                    loading={{state: loading, count: 1}}
                    className={'my-3'}
                    color={'primary'}
                    cells={upperTableCells}
                    data={createUpperInformativeTableDate()}
                />
                <p className={'title mt-5'}>
                    {translation?.logs ?? ''}
                </p>
                {
                    data?.logs?.length < 1
                        ? <NoEntries
                            text={errorMessage}
                            includeButton={false}
                        />
                        : <Table
                            loading={{state: loading, count: 1}}
                            className={'my-3'}
                            color={'primary'}
                            cells={logsTableCells}
                            data={createRecentLogsTableData()}
                        />
                }
            </div>
        </>
    );
}

export default PositionCountingInformation;
