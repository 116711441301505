import React, {useEffect, useState} from 'react';
import ActivePlan from "./sections/active-plan";
import ApplicationPricePlans from "../../../containers/application-price-plans";
import classnames from "classnames";
import {useTranslation} from "react-i18next";
import moment from "moment";
import CancelCurrentPlanConfirmationDialog from "../../../components/dialogs/cancel-current-plan-confirmation";

const SubscriptionView = () => {
    const [activePlan, setActivePlan] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [updatingPlanStatus, setUpdatingPlanStatus] = useState(false);
    const [confirmation, setConfirmation] = useState({open: false, plan: activePlan});
    const {t} = useTranslation();
    const translations = t('views.panel.subscription',
        {
            returnObjects: true,
            date: activePlan?.nextBill
                ? moment(activePlan?.nextBill).format('MMM DD YYYY')
                : ''
        });

    /**
     * Changes the plan of the confirmation state as soon as it changes.
     */
    useEffect(() => {
        setConfirmation({open: false, plan: activePlan})
    }, [activePlan])

    /**
     * Activates the selected plan for the customer
     * @param {any} selectedPlan
     */
    const activatePlan = (selectedPlan) => {
        //    TODO: activate selected plan
        setUpdatingPlanStatus(true);
        setActivePlan({...selectedPlan, isActive: true, nextBill: moment().add(1, 'months').toISOString()});
        setUpdatingPlanStatus(false);
    }

    /**
     * Cancels the currently active plan of the user.
     */
    const cancelActivePlan = () => {
        setUpdatingPlanStatus(true);
        setActivePlan(prevState => ({...prevState, isActive: false}));
        setUpdatingPlanStatus(false);
    }


    const toggleConfirmationDialog = () => {
        setConfirmation(prevState => ({...prevState, open: !prevState.open}))
    }

    return (
        <div className='subscription'>
            <section className={classnames('panel-card pe-0')}>
                <div className='plan mb-5'>
                    <h2 className='title mb-4'>
                        {translations?.title ?? ''}
                    </h2>
                    <ActivePlan
                        loading={loading}
                        updatingStatus={updatingPlanStatus}
                        plan={activePlan}
                        activatePlan={activatePlan}
                        onCancel={toggleConfirmationDialog}
                    />
                </div>
            </section>
            <section className={'panel-card mt-4 mb-5'}>
                <div className='plan others px-3 py-2 my-2 mb-5'>
                    <h2 className='title'>
                        {translations?.upgrades ?? ''}
                    </h2>
                    <ApplicationPricePlans
                        setLoading={setLoading}
                        setActivePlan={setActivePlan}
                        showUpgradesOnly
                        onPlanSelected={activatePlan}
                        buttonText={translations?.plans?.button ?? ''}
                    />
                </div>
            </section>
            <CancelCurrentPlanConfirmationDialog
                {...confirmation}
                setOpen={toggleConfirmationDialog}
                onConfirm={cancelActivePlan}
            />
        </div>
    )
}

export default SubscriptionView;
