import React from "react";
import useRouter from "../../../hooks/use-router";
import {useTranslation} from "react-i18next";
import {RowItemsQueryNames} from "../../../../core/constants/query-names";
import * as Yup from "yup";
import {makeRequired, makeValidate} from "mui-rff";
import {SearchFormInput} from "../../base/input/search";
import classnames from "classnames";
import Form from "../../base/form";


const schema = Yup.object().shape({
    [RowItemsQueryNames.keywords]: Yup.string().nullable(),
})

const RowItemsSearchSection = ({filters}) => {
    const {query, history, location, stringifyUrl} = useRouter();
    const {t} = useTranslation()
    const translations = t("views.panel.company.location.rowItems", {returnObjects: true});
    const validate = makeValidate(schema);
    const required = makeRequired(schema);

    /**
     * Merges the newQuery with the existing query of the view.
     * used to apply the changes in the form section while preserving the existing queries
     * @param {any} values the query object to merge with the previous one
     */
    const mergeQueriesAndApply = (values) => {
        history.push(stringifyUrl({
            url: location.pathname,
            query: {
                ...query,
                ...values,
            }
        }))
    }

    return (
        <>
            <Form
                onSubmit={mergeQueriesAndApply}
                validate={validate}
                className={'d-flex flex-column mt-5'}
                initialValues={filters}
                render={({submitting}) => {
                    return (
                        <>
                            <SearchFormInput
                                placeholder={translations?.form?.keywords?.placeholder ?? ''}
                                fullWidth
                                required={required[RowItemsQueryNames.keywords]}
                                name={RowItemsQueryNames.keywords}
                                buttonProps={{
                                    className: classnames('button primary'),
                                    children: translations?.actions?.search ?? '',
                                    type: "submit",
                                    disabled: submitting
                                }}
                            />
                        </>
                    );
                }}
            />
        </>
    )
}

export default RowItemsSearchSection;
