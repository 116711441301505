import React from "react";
import {ReactComponent as ProductsCount} from "../../../../../../../assets/images/views/public-views/landing/product-count.svg";
import {ReactComponent as LocationTransaction} from "../../../../../../../assets/images/views/public-views/landing/location-transaction.svg";
import {ReactComponent as PurchaseCount} from "../../../../../../../assets/images/views/public-views/landing/purchase-count.svg";
import {useTranslation} from "react-i18next";
import {Container, Row} from "react-bootstrap";
import LandingStatisticsCard from "../../../../../../components/app-specific/landing-statistics-card";
import {landingViewScrollAnimationOptions} from "../../index";

const LandingViewStatsSection = () => {
    const {t} = useTranslation();

    //TODO: stats must be received from api

    const stats = [
        {
            icon: <ProductsCount/>,
            stat: 2010,
            text: t('views.public.landing.stats.productCount'),
        },
        {
            icon: <LocationTransaction/>,
            stat: '20k',
            text: t('views.public.landing.stats.transactions'),
        },
        {
            icon: <PurchaseCount/>,
            stat: 2010,
            text: t('views.public.landing.stats.purchaseCount'),
        },

    ];

    return (
        <>
            <Container className={'stats-container'} data-aos={landingViewScrollAnimationOptions.fadeUpAnimation}>
                <Row className={'flex-row-center'}>
                    <div className={'stats-row'}>
                        {stats?.map(stat => (
                            <LandingStatisticsCard key={stat.text} data={stat}/>
                        ))}
                    </div>
                </Row>
            </Container>
        </>
    )
}

export default LandingViewStatsSection;
