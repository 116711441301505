import React, {useContext, useEffect, useRef, useState} from 'react';
import {CompanyContext} from "../../../../../../contexts/company";
import DoughnutChart from "../../../../../../components/app-specific/doughnut-chart";
import moment from "moment";
import {useTranslation} from "react-i18next";
import classnames from "classnames";
import useRouter from "../../../../../../hooks/use-router";
import {deepEqual} from "../../../../../../../core/services/utils";

// the date filters for the company stats
const dateFilters = [
    {
        id: 1,
        startDate: moment().subtract(7, 'days').toISOString(),
        endDate: moment().toISOString(),
        title: (dates) => dates.week,
        query: 'thisWeek'
    },
    {
        id: 2,
        startDate: moment().subtract(1, 'months').toISOString(),
        endDate: moment().toISOString(),
        title: (dates) => dates.month,
        query: 'thisMonth'
    },
    {
        id: 3,
        startDate: moment().subtract(2, 'months').toISOString(),
        endDate: moment().subtract(1, 'months').toISOString(),
        title: (dates) => dates.lastMonth,
        query: 'lastMonth'
    },
    {
        id: 4,
        startDate: moment().subtract(1, 'years').toISOString(),
        endDate: moment().toISOString(),
        title: (dates) => dates.year,
        query: 'thisYear'
    },
]

const queryNames = {
    date: 'date',
}

const DashboardViewCompanyStatsSection = () => {
    const {location, query, history, stringifyUrl} = useRouter();
    const [company] = useContext(CompanyContext);
    const [stats, setStats] = useState([]);
    const [filters, setFilters] = useState({});
    const [statsLoading, setStatsLoading] = useState(true);
    const fetchedCompanyId = useRef(company?.id);
    const {t} = useTranslation();
    const translations = t('views.panel.company.dashboard.companyStats', {returnObjects: true})


    /**
     * Listens to the changes in company and filters and with each change:
     * Retrieves the features info based on the new provided information
     */
    useEffect(() => {
        const newDate = hasDateOfQueryChanged();
        if (!newDate && company?.id === fetchedCompanyId) return;
        getCompanyStats();
    }, [query, company?.id]);


    /**
     * Determines if the date of the query has changed.
     *
     * If there is a change, sets the state to be in sync with the change
     * @return {any}
     */
    const hasDateOfQueryChanged = () => {
        const queryDate = query[queryNames.date] ?? null;
        const newDate = dateFilters.find((e) => e.query === queryDate);
        if (!deepEqual(filters?.date, newDate)) {
            setFilters(prevState => ({...prevState, date: newDate}));
            return newDate;
        }
        return null;
    }


    /**
     * Fetches the statistics of the selected company from the server.
     */
    const getCompanyStats = () => {
        setStatsLoading(true);
        const response = {
            data: {
                sales: {
                    open: 59332,
                    close: 23422,
                },
                purchase: {
                    open: 100,
                    close: 300,
                },
                spireCounting: {
                    open: 900,
                    close: 300,
                },
                locationCounting: {
                    open: 300,
                    close: 300,
                }
            }
        };
        setStats(createStatData(response.data));
        setStatsLoading(false)
    }

    /**
     * Creates the statistics data for the the donut charts
     * @param data
     * @return {[{title, close: {total, title}, open: {total, title}}, {title, close: {total, title}, open: {total, title}}, {title, close: {total, title}, open: {total, title}}, {title, close: {total, title}, open: {total, title}}]}
     */
    const createStatData = (data) => {
        return [
            {
                title: translations.stats.sales.title,
                open: {
                    total: data.sales.open,
                    title: translations.stats.sales.open
                },
                close: {
                    total: data.sales.close,
                    title: translations.stats.sales.close
                }
            },
            {
                title: translations.stats.purchase.title,
                open: {
                    total: data.purchase.open,
                    title: translations.stats.purchase.open
                },
                close: {
                    total: data.purchase.close,
                    title: translations.stats.purchase.close
                }
            },
            {
                title: translations.stats.spireCounting.title,
                open: {
                    total: data.spireCounting.open,
                    title: translations.stats.spireCounting.open
                },
                close: {
                    total: data.spireCounting.close,
                    title: translations.stats.spireCounting.close
                }
            },
            {
                title: translations.stats.locationCounting.title,
                open: {
                    total: data.locationCounting.open,
                    title: translations.stats.locationCounting.open
                },
                close: {
                    total: data.locationCounting.close,
                    title: translations.stats.locationCounting.close
                }
            },
        ]
    }

    /**
     * Changes the selected date of the company stats in the url query params.
     *
     * This will invoke the search functionality again.
     * @param {string} date
     */
    const changeSelectedDateRange = (date) => {
        history.push(stringifyUrl({
            url: location.pathname,
            query: {
                ...query,
                [queryNames.date]: date,
            }
        }));
    }

    return (
        <>
            <section className='mt-5'>
                <div className='company-stats'>
                    <h3 className='title'>
                        {translations.title}
                    </h3>
                    <div className='d-flex justify-content-start flex-wrap mt-5 mb-3'>
                        {
                            dateFilters.map((dateRange) =>
                                <button
                                    key={dateRange.id}
                                    onClick={() => changeSelectedDateRange(dateRange.query)}
                                    className={classnames('dashboard-date-button button primary grouped',
                                        {active: filters?.date?.id === dateRange.id}
                                    )}
                                >
                                    {dateRange.title(translations.filters.date)}
                                </button>
                            )
                        }
                    </div>
                    <div className='d-flex justify-content-around flex-wrap mt-4 mb-4'>
                        {
                            stats?.map((feature) => (
                                <div key={feature.title} className='mt-3'>
                                    <DoughnutChart loading={statsLoading} data={feature}/>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default DashboardViewCompanyStatsSection;
