import {createSlice} from "@reduxjs/toolkit";

// Initial state of slice for handling the selection of spire counting
const initialState = {
    selectedCountingIds: [],
    isSelecting: false,
}

/**
 *
 * Redux slice for handling selection of spire counting
 */
const spireCountingSelectionSlice = createSlice({
    name: 'spireRecountSelection',
    initialState: initialState,
    reducers: {
        setIsSelecting(state, action){
            state.isSelecting = action?.payload;
            if(action?.payload === false){
                state.selectedCountingIds = initialState.selectedCountingIds;
            }
        },
        toggleCountingAdditionToList(state, action){
            if(state.selectedCountingIds.filter(e => e === action.payload).length === 0){
               state.selectedCountingIds.push(action.payload);
                console.log(state.selectedCountingIds);
               return;

            }
            state.selectedCountingIds = state.selectedCountingIds.filter(e => e !== action.payload);
            console.log(state.selectedCountingIds);
        }
    }
});

export const spireCountingSelectionActions = spireCountingSelectionSlice.actions;

export default spireCountingSelectionSlice;
