import React, {useEffect} from "react";
import useRouter from "../../../hooks/use-router";
import routes from "../../../routes";
import Api from "../../../../core/services/api_service";
import useIsMounted from "../../../hooks/use-is-mounted";
import CacheService from "../../../../core/services/cache_service";
import {useDispatch} from "react-redux";
import {setUserInfoRedux} from "../../../../redux/entities/user-info/actions";
import Loader from "../../../components/app-specific/loader";

const Auth = () => {
    const {params, history} = useRouter();
    const dispatch = useDispatch();
    const isMounted = useIsMounted();

    /**
     * As soon as the component mounts:
     *
     * Calls the api for system login and if successful, sets the operator information in local storage if there
     * existed any cached url in local storage, removes it and redirects the user to that route else redirects to
     * look up base route
     */
    useEffect(() => {
        if (!params.token || !params.time) {
            history.replace(routes.error.accessDenied);
        }
        Api.login(params.token).then((response) => {
            if (!isMounted()) return;
            const {
                token,
                tokenLifeSpanMinutes,
                firstName,
                lastName,
                email,
                //TODO: set the correct name for profileImageUrl
                profileImageUrl,
            } = response.data;
            CacheService.setLoginInfo(token, tokenLifeSpanMinutes, firstName, lastName, email, profileImageUrl);
            dispatch(setUserInfoRedux({
                firstName: firstName,
                lastName: lastName,
                email: email,
                profileImageUrl: profileImageUrl,
            }));
            //TODO: set the configurations when its available
            const cachedUrl = CacheService.getCachedUrl();
            if (cachedUrl) {
                history.replace(cachedUrl);
            } else {
                history.replace(routes.panel.base);
            }
        });
    }, []);

    return (
        <>
            <Loader withOverlay/>
        </>
    );
}

export default Auth;
