import React from "react";

const TableSpacer = ({length}) => {

    return (
        <>
            <tr className={'row-spacer'}>
                {
                    Array(length + 1).fill(null).map((e, index) => (
                        <td key={index}/>
                    ))
                }
            </tr>
        </>
    )
}

export default TableSpacer;
