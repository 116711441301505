import React, {useContext, useRef, useState} from "react";
import {PanelContext} from "../../../../contexts/panel";
import {useSelector} from "react-redux";
import classnames from "classnames";
import {ReactComponent as Menu} from "../../../../../assets/images/panel/sidebar/menu.svg";
import {ReactComponent as Arrow} from "../../../../../assets/images/dropdown-icon.svg";
import SidebarPopper from "../popper";
import sidebarMap from "../_map";
import {Collapse} from "react-bootstrap";
import SidebarMenuItem from "../menu-item";
import LazyImage from "../../../../components/base/lazy-image";

const SidebarHeader = ({toggleCollapsed, toggleProfileExpanded}) => {
    const [{collapsed, canCollapse}] = useContext(PanelContext);
    const [actionsVisible, setActionsVisible] = useState(false);
    const popperReferenceElementRef = useRef();
    const userInfo = useSelector(store => store.userInfo?.data ?? {});


    /**
     * Toggles the visibility of the actions of this component if its not collapsed.
     */
    const toggleActionsVisibility = () => {
        if (collapsed) return;
        setActionsVisible(prevState => !prevState);
        toggleProfileExpanded();
    }

    return (
        <div className={classnames('sidebar-header', {collapsed})}>
            <div className={'header-inner'}>
                <Menu className={'menu-icon'} onClick={() => canCollapse && toggleCollapsed()}/>
                <div
                    className={classnames('user-info', {'active': !!actionsVisible})}
                    ref={popperReferenceElementRef}
                    onClick={toggleActionsVisibility}
                >
                    <div className={'profile'}>
                        <LazyImage src={userInfo.profileImageUrl}
                                   alt="profile"
                                   placeholder={(
                                       <p>
                                           {
                                               userInfo.firstName?.length
                                                   ? userInfo.firstName[0]
                                                   : ''
                                           }
                                           {
                                               userInfo.lastName?.length
                                                   ? userInfo.lastName[0]
                                                   : ''
                                           }
                                       </p>
                                   )}
                        />
                    </div>
                    <div className={'name'}>
                        <p>
                            {`${userInfo.firstName ?? ''} ${userInfo.lastName ?? ''}`}
                        </p>
                    </div>
                    <div className={'arrow'}>
                        <Arrow/>
                    </div>
                    {
                        collapsed &&
                        <SidebarPopper referenceElement={popperReferenceElementRef}>
                            {
                                sidebarMap.header?.map((item) => (
                                    <SidebarMenuItem key={item.id} item={item}/>
                                ))
                            }
                        </SidebarPopper>
                    }
                </div>
            </div>
            {
                !collapsed &&
                <Collapse in={actionsVisible}>
                    <div className={'actions'}>
                        {
                            sidebarMap.header?.map((item) => (
                                <SidebarMenuItem key={item.id} item={item}/>
                            ))
                        }
                    </div>
                </Collapse>
            }

        </div>
    );
}

export default SidebarHeader;
