import React from "react";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import useRouter from "../../../../../hooks/use-router";
import {makeRequired, makeValidate} from "mui-rff";
import {SearchFormInput} from "../../../../base/input/search";
import classnames from "classnames";
import Form from "../../../../base/form";

// Form key
const formKeys = {
    keyword: 'keyword'
}

/**
 * Creates the schema of the spire counting form section
 */
const makeSchema = (validationTranslation) => {
    return Yup.object().shape({
        [formKeys.keyword]: Yup.string().when(formKeys.keyword,{
            is: val => !val?.length,
            then: Yup.string().nullable(),
            otherwise: Yup.string().nullable().required(validationTranslation?.required ?? '')
        }),
    },[[formKeys.keyword,formKeys.keyword]])
}

const SpireCountingSearchSection = ({filters}) => {
    const {t} = useTranslation();
    const {query, location, history, stringifyUrl} = useRouter()

    //TODO: change translation
    const translation = t("views.panel.company.usersManagement", {returnObjects: true});
    const validationTranslation = t('utils.formValidation', {returnObjects: true})
    const schema = makeSchema(validationTranslation)
    const validate = makeValidate(schema);
    const required = makeRequired(schema);

    /**
     * Handles click on search button and modifies the query with the changes in the form values
     * @param newQuery
     */
    const onFormSearched = (newQuery) => {
        // if user searches with empty keyword, removes keyword from query params
        if(!Object.keys(newQuery).length){
            history.push(stringifyUrl({
                url: location.pathname,
                query: {}
            }))
            return;
        }
        history.push(stringifyUrl({
            url: location.pathname,
            query: {
                ...query,
                ...newQuery,
            }
        }))

    }

    return (
        <>
            <Form
                onSubmit={onFormSearched}
                validate={validate}
                className={'d-flex flex-column spire-counting-search-section'}
                initialValues={filters}
                render={({submitting}) => {
                    return (
                        <>
                            <SearchFormInput
                                placeholder={translation?.form?.keywords?.placeHolder ?? ''}
                                fullWidth
                                required={required[formKeys.keyword]}
                                name={formKeys.keyword}
                                buttonProps={{
                                    className: classnames('button primary'),
                                    children: translation?.actions?.submit ?? '',
                                    type: "submit",
                                    disabled: submitting
                                }}
                            />
                        </>
                    );
                }}
            />
        </>
    );
}

export default SpireCountingSearchSection;
