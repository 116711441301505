import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState} from "react";
import {InventorySchemaVisualizerDepths, InventorySchemaVisualizerModes} from "../core/models/constants/enums";
import useIsMounted from "./hooks/use-is-mounted";
import Utils from "../core/services/utils";
import {
    IAddRectFunc,
    ICanvasData,
    ICanvasDataEntry,
    ICanvasDataEntryDepth,
    IInternalCanvasData,
    IInternalCanvasDataEntry,
    IInventorySchemaVisualizerVisualConfig,
    IOnRowClickedFunc,
    IRemoveRectFunc,
    IWarehouseAisleData,
    IWarehouseData,
    IWarehouseRowData,
    IWarehouseSectionData
} from "./types";
import InventorySchemaVisualizerControlPanel from "./components/control-panel";
import InventorySchemaVisualizerCanvas from "./components/canvas";
import InventorySchemaVisualizerSidebar from "./components/sidebar";
import Gradient from "javascript-color-gradient";
import Konva from "konva";
import {CircularProgress} from "@material-ui/core";
import InventorySchemaVisualizerApiService, {LocationWarehouseDs} from "../core/services/api";
import {debounce} from "lodash";

export type IInventorySchemaVisualizerProps = {
    mode: InventorySchemaVisualizerModes,
    warehouse: LocationWarehouseDs,
    onRowClicked: IOnRowClickedFunc,
    highlightsRowsData?: Array<number>,
}

export type IInventorySchemaVisualizerRef = {
    reFetchWarehouseData: Function,
}

const initCanvasData: ICanvasData = {
    aisles: [],
};
const initWarehouseData: IWarehouseData = {
    aisles: [],
};
const initSelectedEntryToBeAdded = undefined;
const initVisualConfig: IInventorySchemaVisualizerVisualConfig = {
    depth: InventorySchemaVisualizerDepths.rows,
    scale: 1.0,
    selectedEntityData: undefined,
};
const initLoading = true;

const InventorySchemaVisualizer = forwardRef<IInventorySchemaVisualizerRef, IInventorySchemaVisualizerProps>
(({
      mode: unsafeMode,
      warehouse,
      onRowClicked,
      highlightsRowsData,
  }, ref) => {
    const [canvasData, setCanvasData] = useState<ICanvasData>(initCanvasData);
    const [warehouseData, setWarehouseData] = useState<IWarehouseData>(initWarehouseData);
    const [loading, setLoading] = useState(initLoading);
    const [selectedEntryToBeAdded, setSelectedEntryToBeAdded] = useState<Array<string> | undefined>(initSelectedEntryToBeAdded);
    const [visualConfig, setVisualConfig] = useState<IInventorySchemaVisualizerVisualConfig>(initVisualConfig);
    const [, setSavingState] = useState(false);
    const isMounted = useIsMounted();
    const stageRef = useRef<Konva.Stage | undefined>();
    const prevSavedData = useRef<ICanvasData | null>(null);
    const canSaveData = useRef(false);
    const forceSaveData = useRef(false);
    const warehouseId = useMemo(() => warehouse?.id, [warehouse?.id]);

    const mode = useMemo(() => unsafeMode ?? InventorySchemaVisualizerModes.view, [unsafeMode]);
    const gradientArray = useMemo(() => new Gradient().setColorGradient("00FF00", "#F0FF00", "#FF0000").setMidpoint(100).getColors(), []);

    const internalCanvasData = useMemo<IInternalCanvasData>(() => {
        const parentOfAddedRectName = !!selectedEntryToBeAdded?.length && selectedEntryToBeAdded.length > 1
            ? selectedEntryToBeAdded?.[selectedEntryToBeAdded.length - 2]
            : undefined;
        const droppableFill = '#D5EDF3FF';
        const interactiveStroke = '#009FB8';
        const disabledStroke = 'grey';
        return ({
            selectedRectData: visualConfig.selectedEntityData,
            selectedEntryToBeAdded: selectedEntryToBeAdded,
            aisles: canvasData.aisles.map<IInternalCanvasDataEntry<ICanvasDataEntryDepth.aisle>>(aisle => {
                let sectionsCount = 0;
                let sectionsCapacity = 0;
                const sections = aisle.children
                        ?.map<IInternalCanvasDataEntry<ICanvasDataEntryDepth.section>>(section => {
                            let rowsCount = 0;
                            let rowsCapacity = 0;
                            const rows = section.children
                                    ?.map<IInternalCanvasDataEntry<ICanvasDataEntryDepth.row>>(row => {
                                        const highlighted = !Utils.deepEqual(highlightsRowsData, row.data.slice(1));
                                        const rowFillColor = gradientArray[Math.floor(Math.min(((row.entity.productCount ?? 0) / (row.entity.capacity ?? 1)) * 100, 99))];
                                        rowsCount += (row.entity.productCount ?? 0);
                                        rowsCapacity += (row.entity.capacity ?? 0);
                                        return {
                                            key: row.data.join('-'),
                                            x: row.x,
                                            y: row.y,
                                            width: row.width,
                                            height: row.height,
                                            data: row.data,
                                            title: row.entity.code,
                                            name: `${InventorySchemaVisualizerDepths.rows} ${row.entity.code}`,
                                            children: undefined,
                                            rotated: row.rotated,
                                            allowInteractions: visualConfig.depth === InventorySchemaVisualizerDepths.rows || parentOfAddedRectName === row.entity.code,
                                            cursor: visualConfig.depth === InventorySchemaVisualizerDepths.rows && mode === InventorySchemaVisualizerModes.view
                                                ? highlightsRowsData
                                                    ? highlighted
                                                        ? 'pointer'
                                                        : 'default'
                                                    : 'pointer'
                                                : parentOfAddedRectName === row.entity.code
                                                    ? 'copy'
                                                    : 'default',
                                            stroke: visualConfig.depth === InventorySchemaVisualizerDepths.rows
                                                ? (!!highlightsRowsData && highlighted) || (!highlightsRowsData)
                                                    ? interactiveStroke
                                                    : disabledStroke
                                                : 'transparent',
                                            strokeWidth: 1,
                                            fill: parentOfAddedRectName === row.entity.code
                                                ? droppableFill
                                                : !!highlightsRowsData
                                                    ? highlighted
                                                        ? droppableFill
                                                        : 'transparent'
                                                    : visualConfig.depth === InventorySchemaVisualizerDepths.rows
                                                        ? rowFillColor
                                                        : 'transparent',
                                        } as IInternalCanvasDataEntry<ICanvasDataEntryDepth.row>;
                                    })
                                ?? []
                            const sectionFill = gradientArray[Math.floor(Math.min((rowsCount / Math.max(rowsCapacity, 1)) * 100, 99))];
                            sectionsCount += rowsCount;
                            sectionsCapacity += rowsCapacity;
                            return {
                                key: section.data.join('-'),
                                x: section.x,
                                y: section.y,
                                width: section.width,
                                height: section.height,
                                data: section.data,
                                title: section.entity.code,
                                rotated: section.rotated,
                                name: `${InventorySchemaVisualizerDepths.sections} ${section.entity.code}`,
                                children: rows,
                                allowInteractions: visualConfig.depth === InventorySchemaVisualizerDepths.sections || parentOfAddedRectName === section.entity.code,
                                cursor: parentOfAddedRectName === section.entity.code ? 'copy' : 'default',
                                stroke: visualConfig.depth === InventorySchemaVisualizerDepths.sections
                                    ? interactiveStroke
                                    : visualConfig.depth === InventorySchemaVisualizerDepths.aisles
                                        ? 'transparent'
                                        : disabledStroke,
                                strokeWidth: 1,
                                fill: parentOfAddedRectName === section.entity.code
                                    ? droppableFill
                                    : visualConfig.depth === InventorySchemaVisualizerDepths.sections
                                        ? sectionFill
                                        : 'transparent',
                            } as IInternalCanvasDataEntry<ICanvasDataEntryDepth.section>;
                        })
                    ?? [];
                const aisleFill = gradientArray[Math.floor(Math.min((sectionsCount / Math.max(sectionsCapacity, 1)) * 100, 99))];
                return {
                    key: aisle.data.join('-'),
                    x: aisle.x,
                    y: aisle.y,
                    width: aisle.width,
                    height: aisle.height,
                    data: aisle.data,
                    title: aisle.entity.code,
                    rotated: aisle.rotated,
                    name: `${InventorySchemaVisualizerDepths.aisles} ${aisle.entity.code}`,
                    children: sections,
                    allowInteractions: visualConfig.depth === InventorySchemaVisualizerDepths.aisles || parentOfAddedRectName === aisle.entity.code,
                    cursor: parentOfAddedRectName === aisle.entity.code ? 'copy' : 'default',
                    stroke: visualConfig.depth === InventorySchemaVisualizerDepths.aisles
                        ? 'transparent'
                        : disabledStroke,
                    strokeWidth: 1,
                    fill: parentOfAddedRectName === aisle.entity.code
                        ? droppableFill
                        : visualConfig.depth === InventorySchemaVisualizerDepths.aisles
                            ? aisleFill
                            : 'transparent',
                    ...(visualConfig.depth === InventorySchemaVisualizerDepths.aisles && {
                        shadowColor: 'rgba(188, 193, 200, 0.8)',
                        shadowOffsetX: 28,
                        shadowOffsetY: 20,
                        shadowBlur: 49,
                        shadowEnabled: true,
                    })
                } as IInternalCanvasDataEntry<ICanvasDataEntryDepth.aisle>;
            })
        })
    }, [selectedEntryToBeAdded, visualConfig.selectedEntityData, visualConfig.depth, canvasData.aisles, gradientArray, mode, highlightsRowsData])

    /**
     * With each change in the [selectedEntityData] value of the [visualConfig] state:
     * - moves the selectedEntity's z-index to the top until the selection changes.
     */
    useEffect(() => {
        const selectedData = visualConfig.selectedEntityData;
        if (!selectedData)
            return;
        setCanvasData(prevState => {
            const [, aisleId, sectionId, rowId] = selectedData;
            if (!aisleId)
                return prevState;
            const copy = Utils.deepCopy(prevState);
            // manipulating aisles
            for (const aisle of copy.aisles.filter(e => e.data[e.data.length - 1] === aisleId)) {
                const aisleIndex = copy.aisles.findIndex(e => Utils.deepEqual(e.data, aisle.data));
                if (aisleIndex === -1) // impossible to happen
                    continue;
                if (!sectionId) {
                    if (!Utils.deepEqual(aisle.data, selectedData)) {
                        continue;
                    }
                    copy.aisles.splice(aisleIndex, 1)
                    copy.aisles.push(aisle);
                    return copy;
                }
                // manipulating sections
                for (const section of aisle.children.filter(e => e.data[e.data.length - 1] === sectionId)) {
                    const sectionIndex = aisle.children.findIndex(e => Utils.deepEqual(e.data, section.data));
                    if (sectionIndex === -1) // impossible to happen
                        continue;
                    if (!rowId) {
                        if (!Utils.deepEqual(section.data, selectedData)) {
                            continue;
                        }
                        copy.aisles.splice(aisleIndex, 1)
                        copy.aisles.push(aisle);
                        aisle.children.splice(sectionIndex, 1);
                        aisle.children.push(section);
                        return copy;
                    }
                    // manipulating rows
                    for (const row of section.children.filter(e => e.data[e.data.length - 1] === rowId)) {
                        const rowIndex = section.children.findIndex(e => Utils.deepEqual(e.data, row.data));
                        if (rowIndex === -1) // impossible to happen
                            continue;
                        if (!Utils.deepEqual(row.data, selectedData)) {
                            continue;
                        }
                        copy.aisles.splice(aisleIndex, 1)
                        copy.aisles.push(aisle);
                        aisle.children.splice(sectionIndex, 1);
                        aisle.children.push(section);
                        section.children.splice(rowIndex, 1);
                        section.children.push(row);
                        return copy;
                    }
                }
            }
            return prevState;
        })
    }, [visualConfig.selectedEntityData])

    /**
     * Merges the data of the saved-state (canvasData) and the warehouse entries data (warehouseData) such that the visualizer's state
     * always contains the most updated data.
     */
    const ensureCanvasDataContainsWarehouseData = useCallback((canvasData: ICanvasData, warehouseData: IWarehouseData) => {
        const nonNullPredicate = (e: any): e is ICanvasDataEntry<any> => !!e;
        return {
            aisles: canvasData.aisles
                    ?.map(aisleCanvasData => {
                        const aisle = warehouseData.aisles?.find(e => e.id === aisleCanvasData.data[1]) ?? undefined;
                        if (!aisle)
                            return null;
                        return {
                            ...aisleCanvasData,
                            entity: aisle,
                            children: aisleCanvasData.children
                                    ?.map(sectionCanvasData => {
                                        const section = aisle.sections?.find(e => e.id === sectionCanvasData.data[2]) ?? undefined;
                                        if (!section)
                                            return null;
                                        return {
                                            ...sectionCanvasData,
                                            entity: section,
                                            children: sectionCanvasData.children
                                                    ?.map(rowCanvasData => {
                                                        const row = section.rows?.find(e => e.id === rowCanvasData.data[3]) ?? undefined;
                                                        if (!row)
                                                            return null;
                                                        return {
                                                            ...rowCanvasData,
                                                            entity: row,
                                                        } as ICanvasDataEntry<ICanvasDataEntryDepth.row>;
                                                    })
                                                    ?.filter(nonNullPredicate)
                                                ?? [],
                                        } as ICanvasDataEntry<ICanvasDataEntryDepth.section>;
                                    })
                                    ?.filter(nonNullPredicate)
                                ?? [],
                        } as ICanvasDataEntry<ICanvasDataEntryDepth.aisle>;
                    })
                    ?.filter(nonNullPredicate)
                ?? [],
        }
    }, []);

    /**
     * Fetches the warehouse entries associated with the provided warehouseId.
     */
    const getWarehouseEntries = useCallback(async () => {
        const response = await InventorySchemaVisualizerApiService.getAllEntriesOfWarehouse(warehouseId);
        if (!isMounted())
            return;
        if (response?.resultFlag) {
            setWarehouseData(response?.data ?? {});
        }
    }, [isMounted, warehouseId])

    /**
     * Exposes the logic for re-fetching warehouse data to the parent component through the provided ref object.
     */
    useImperativeHandle(ref, () => ({
        reFetchWarehouseData: getWarehouseEntries,
    }), [getWarehouseEntries])

    /**
     * Fetches the saved canvas data and warehouse entries needed for the visualizer from the sever.
     */
    const getInventoryData = useCallback(async () => {
        setLoading(true);
        const [canvasDataResponse, warehouseResponse] = await Promise.all([
            InventorySchemaVisualizerApiService.getInventorySchemaSavedState(warehouseId),
            InventorySchemaVisualizerApiService.getAllEntriesOfWarehouse(warehouseId),
        ]);
        if (!isMounted())
            return;
        if (warehouseResponse?.resultFlag) {
            setWarehouseData(warehouseResponse.data ?? {});
        }
        if (canvasDataResponse?.resultFlag) {
            setCanvasData(ensureCanvasDataContainsWarehouseData(canvasDataResponse?.data as ICanvasData, warehouseResponse?.data ?? {}));
            canSaveData.current = true;
        }
        setLoading(false);
    }, [warehouseId, isMounted, ensureCanvasDataContainsWarehouseData])

    /**
     * With each change in the [warehouseId]'s prop value:
     * - Fetches the warehouse entries needed for the visualizer from the sever.
     */
    useEffect(() => {
        getInventoryData().then();
    }, [getInventoryData]);

    /**
     * Saves the canvas state of this visualizer for the current [warehouseId] in the server.
     */
    const saveCanvasState = useMemo<Function>(() => debounce((state: ICanvasData) => {
        const forApi = {
            ...warehouse,
            schemaData: JSON.stringify(state),
        };
        if (forceSaveData.current) {
            return InventorySchemaVisualizerApiService.saveInventorySchemaState(forApi).then();
        }
        setSavingState((prevSavingState) => {
            if (prevSavingState)
                return true;
            InventorySchemaVisualizerApiService
                .saveInventorySchemaState(forApi)
                .then(() => {
                    if (!isMounted())
                        return;
                    setSavingState(false);
                })
            return true;
        });
    }, 5000), [isMounted, warehouse]);

    /**
     * With each change in the [warehouseId]'s prop value:
     * - Resets the canvasData, rehouseData, selectedEntryToBeAdded and visualConfig state values.
     */
    useLayoutEffect(() => {
        forceSaveData.current = false;
        canSaveData.current = false
        setLoading(true);
        prevSavedData.current = null;
        setCanvasData(initCanvasData);
        setWarehouseData(initWarehouseData);
        setSelectedEntryToBeAdded(initSelectedEntryToBeAdded);
        setVisualConfig(initVisualConfig);
        return () => {
            if (mode === InventorySchemaVisualizerModes.view)
                return;
            forceSaveData.current = true;
            // @ts-ignore
            saveCanvasState?.flush();
        }
    }, [warehouseId, mode])

    /**
     * The comparator of the canvas data entries.
     *
     * - used when sorting the canvasData for saving the state in the server.
     * - first compares based on genericId then aisleId then sectionId then rowId.
     */
    const canvasDataComparator = useCallback((a: ICanvasDataEntry<any>, b: ICanvasDataEntry<any>) => {
        const comparedById = Utils.stringComparator(a.data[0], b.data[0]);
        if (comparedById || !a.data[1] || !b.data[1]) {
            return comparedById;
        }
        const comparedByAisleId = Utils.numComparator(a.data[1], b.data[1]);
        if (comparedByAisleId || !a.data[2] || !b.data[2]) {
            return comparedByAisleId
        }
        const comparedBySectionId = Utils.numComparator(a.data[2], b.data[2]);
        if (comparedBySectionId || !a.data[3] || !b.data[3]) {
            return comparedBySectionId;
        }
        return Utils.numComparator(a.data[3], b.data[3]);
    }, []);

    /**
     * With each change in the [canvasData] and [loading] state values:
     * - determines if there is a need for saving and calls the [saveState] callback.
     */
    useEffect(() => {
        if (loading || !canSaveData.current)
            return;
        const savedData: ICanvasData = {
            aisles: canvasData.aisles.map((aisle) => ({
                data: aisle.data,
                height: aisle.height,
                rotated: aisle.rotated,
                width: aisle.width,
                x: aisle.x,
                y: aisle.y,
                entity: undefined as unknown as IWarehouseAisleData,
                children: aisle.children.map((section) => ({
                    data: section.data,
                    height: section.height,
                    rotated: section.rotated,
                    width: section.width,
                    x: section.x,
                    y: section.y,
                    entity: undefined as unknown as IWarehouseSectionData,
                    children: section.children.map((row) => ({
                        data: row.data,
                        height: row.height,
                        rotated: row.rotated,
                        width: row.width,
                        x: row.x,
                        y: row.y,
                        entity: undefined as unknown as IWarehouseRowData,
                        children: undefined,
                    })).sort(canvasDataComparator),
                })).sort(canvasDataComparator),
            })).sort(canvasDataComparator)
        }
        if (!prevSavedData.current) {
            prevSavedData.current = savedData;
            return;
        }
        if (!Utils.deepEqual(savedData, prevSavedData.current)) {
            prevSavedData.current = savedData;
            saveCanvasState(savedData);
        }
    }, [canvasData, canvasDataComparator, loading, saveCanvasState])


    /**
     * Sets the depth value of the visual config state.
     * - if [removeNewEntrySelection] flag is set, removes the [selectedEntryToBeAdded]'s state value.
     */
    const setDepth = useCallback((depth, removeNewEntrySelection = true) => {
        setVisualConfig(prevState => ({
            ...prevState,
            depth: depth,
        }))
        if (removeNewEntrySelection) {
            setSelectedEntryToBeAdded(undefined);
        }
    }, [])

    /**
     * Changes the scale value of the visual config state with the provided [offset] value.
     */
    const changeScale = useCallback((offset) => {
        setVisualConfig(prevState => ({
            ...prevState,
            scale: Math.max(Math.min(prevState.scale + offset, 6), 0.5),
        }))
    }, [])

    /**
     * Sets the [selectedEntityData]'s state value or resets it if the same value is given.
     */
    const setSelectedEntityData = useCallback((entityData: IInventorySchemaVisualizerVisualConfig['selectedEntityData'] | undefined) => {
        setVisualConfig(prevState => ({
            ...prevState,
            selectedEntityData: Utils.deepEqual(prevState.selectedEntityData, entityData) ? undefined : entityData,
        }))
    }, [])

    /**
     * Sets the [selectedEntryToBeAdded]'s state value or resets it if the same value is given.
     */
    const selectTreeEntry = useCallback((value) => {
        setSelectedEntryToBeAdded(prevState => {
            let res = value;
            if (Utils.deepEqual(prevState, value)) {
                res = undefined;
            }
            return res;
        })
    }, [])

    /**
     * Adds a [rect]'s data to the canvas data based on the provided values.
     * - removes the [selectedEntryToBeAdded]'s state value as well.
     */
    const addRect = useCallback<IAddRectFunc>(([aisleCode, sectionCode, rowCode], options, parentData) => {
        setCanvasData(prevState => {
            switch (visualConfig.depth) {
                case InventorySchemaVisualizerDepths.aisles: {
                    const aisleToBeAdded = warehouseData.aisles?.find(e => e.code === aisleCode) as IWarehouseAisleData;
                    return {
                        ...prevState,
                        aisles: [
                            ...prevState.aisles,
                            {
                                data: [Utils.createUUId(), aisleToBeAdded?.id],
                                entity: aisleToBeAdded,
                                children: [],
                                ...options,
                            }
                        ]
                    }
                }
                case InventorySchemaVisualizerDepths.sections: {
                    const aisleToBeAdded = warehouseData.aisles?.find(e => e.code === aisleCode) as IWarehouseAisleData;
                    const sectionToBeAdded = aisleToBeAdded?.sections?.find(e => e.code === sectionCode) as IWarehouseSectionData;
                    return {
                        ...prevState,
                        aisles: prevState.aisles.map(aisle =>
                            aisleCode === aisle.entity.code && parentData === aisle.data
                                ? {
                                    ...aisle,
                                    children: [
                                        ...aisle.children,
                                        {
                                            data: [Utils.createUUId(), aisleToBeAdded?.id, sectionToBeAdded?.id],
                                            entity: sectionToBeAdded,
                                            children: [],
                                            ...options,
                                        }
                                    ]
                                }
                                : aisle
                        )
                    }
                }
                case InventorySchemaVisualizerDepths.rows: {
                    const aisleToBeAdded = warehouseData.aisles?.find(e => e.code === aisleCode) as IWarehouseAisleData;
                    const sectionToBeAdded = aisleToBeAdded?.sections?.find(e => e.code === sectionCode) as IWarehouseSectionData;
                    const rowToBeAdded = sectionToBeAdded?.rows?.find(e => e.code === rowCode) as IWarehouseRowData;
                    return {
                        ...prevState,
                        aisles: prevState.aisles.map(aisle =>
                            aisleCode === aisle.entity.code
                                ? {
                                    ...aisle, children: aisle.children.map(section =>
                                        sectionCode === section.entity.code && parentData === section.data
                                            ? {
                                                ...section,
                                                children: [
                                                    ...section.children,
                                                    {
                                                        data: [Utils.createUUId(), aisleToBeAdded?.id, sectionToBeAdded?.id, rowToBeAdded?.id],
                                                        entity: rowToBeAdded,
                                                        ...options,
                                                    } as ICanvasDataEntry<ICanvasDataEntryDepth.row>
                                                ]
                                            }
                                            : section
                                    )
                                }
                                : aisle
                        )
                    }
                }
                default:
                    return prevState;
            }
        })
        setSelectedEntryToBeAdded(undefined);
    }, [visualConfig.depth, warehouseData.aisles]);

    /**
     * Removes a [rect] from the canvas data based on the provided data.
     */
    const removeRect = useCallback<IRemoveRectFunc>((data) => {
        setCanvasData(prevState => ({
            ...prevState,
            aisles: prevState.aisles.filter(aisle => !Utils.deepEqual(aisle.data, data)).map(e => ({
                ...e,
                children: e.children.filter(section => !Utils.deepEqual(section.data, data)).map(e => ({
                    ...e, children: e.children.filter(row => !Utils.deepEqual(row.data, data))
                }))
            }))
        }))
    }, [])

    /**
     * Rotates a [rect]'s dimensions and all of its children depending on the hierarchy that it exists on. (for now only aisles are
     * rotatable)
     */
    const rotateRect = useCallback<IRemoveRectFunc>((data) => {
        setCanvasData(prevState => ({
            ...prevState,
            aisles: prevState.aisles.map(aisle => {
                if (!Utils.deepEqual(aisle.data, data))
                    return aisle;
                return {
                    ...aisle,
                    rotated: !aisle.rotated,
                    width: aisle.height,
                    height: aisle.width,
                    children: aisle.children.map(section => ({
                        ...section,
                        width: section.height,
                        height: section.width,
                        x: section.y,
                        y: section.x,
                        rotated: !aisle.rotated,
                        children: section.children.map(row => ({
                            ...row,
                            width: row.height,
                            height: row.width,
                            x: row.y,
                            y: row.x,
                            rotated: !aisle.rotated,
                        }))
                    }))
                }
            })
        }))
    }, [])

    /**
     * With eah change in the [selectedEntryToBeAdded]:
     * - if [selectedEntryToBeAdded] has a value: removes the [selectedEntityData] and syncs the [depth].
     */
    useLayoutEffect(() => {
        if (!selectedEntryToBeAdded || !stageRef.current)
            return;
        setSelectedEntityData(undefined);
        setDepth([InventorySchemaVisualizerDepths.aisles, InventorySchemaVisualizerDepths.sections, InventorySchemaVisualizerDepths.rows][selectedEntryToBeAdded.length - 1], false);
    }, [selectedEntryToBeAdded, setDepth, setSelectedEntityData])

    const autoGenerateSchema = useCallback(() => {
        if (!stageRef.current)
            return;
        const stageSize = stageRef.current.container().parentElement?.getBoundingClientRect();
        if (!stageSize)
            return;

        const ROW_HEIGHT = 30;
        const SECTION_WIDTH = 80;

        const X_PADDING = 10;
        const Y_PADDING = 10;

        let aisleX = 0;
        let aisleY = 0;
        let maxAisleHeightInLine = 0;

        setCanvasData(() => {
            const aisles = warehouseData.aisles?.map((aisle) => {
                let aisleHeight = Math.max(...(aisle.sections?.map(section => (section.rows?.length ?? 0)) ?? []), 5) * ROW_HEIGHT;
                let aisleWidth = Math.max(aisle.sections?.length ?? 0, 5) * SECTION_WIDTH;
                const sections = aisle.sections?.map((section, sectionIndex) => {
                        const rows = section.rows?.map((row, rowIndex) => {
                                return {
                                    x: 0,
                                    y: rowIndex * ROW_HEIGHT,
                                    width: SECTION_WIDTH,
                                    height: ROW_HEIGHT,
                                    data: [Utils.createUUId(), aisle.id, section.id, row.id],
                                    rotated: false,
                                    entity: row,
                                } as ICanvasDataEntry<ICanvasDataEntryDepth.row>;
                            })
                            ?? []
                        return {
                            x: sectionIndex * SECTION_WIDTH,
                            y: 0,
                            width: SECTION_WIDTH,
                            height: aisleHeight,
                            data: [Utils.createUUId(), aisle.id, section.id],
                            rotated: false,
                            entity: section,
                            children: rows,
                        } as ICanvasDataEntry<ICanvasDataEntryDepth.section>;
                    })
                    ?? [];
                return {
                    width: aisleWidth,
                    height: aisleHeight,
                    data: [Utils.createUUId(), aisle.id],
                    rotated: false,
                    entity: aisle,
                    children: sections,
                } as ICanvasDataEntry<ICanvasDataEntryDepth.aisle>;
            }) ?? [];
            for (const aisle of aisles) {
                if (aisleX >= stageSize.width) {
                    aisleX = 0;
                    aisleY += maxAisleHeightInLine + Y_PADDING;
                }
                if (aisleY >= stageSize.height) {
                    aisleY = 0;
                    aisleX = 0;
                    maxAisleHeightInLine = 0;
                }
                aisle.x = aisleX;
                aisle.y = aisleY;
                aisleX += aisle.width + X_PADDING;
                maxAisleHeightInLine = Math.max(aisle.height, maxAisleHeightInLine);
            }
            return ({
                aisles: aisles,
            })
        })
    }, [warehouseData, stageRef]);


    return (
        <>
            <div className={'inventory-schema-visualizer'}>
                {
                    loading &&
                    <div className={'d-flex align-items-center justify-content-center w-100 h-100'}>
                        <CircularProgress
                            size={20}
                        />
                    </div>
                }
                {
                    !loading &&
                    <>
                        {
                            mode === InventorySchemaVisualizerModes.edit &&
                            <InventorySchemaVisualizerSidebar
                                canAutoGenerate={!!warehouseData.aisles?.length}
                                depth={visualConfig.depth}
                                warehouseData={warehouseData}
                                onEntrySelected={selectTreeEntry}
                                selectedEntry={selectedEntryToBeAdded}
                                setDepth={setDepth}
                                autoGenerateSchema={autoGenerateSchema}
                            />
                        }
                        <InventorySchemaVisualizerCanvas
                            ref={stageRef}
                            mode={mode}
                            depth={visualConfig.depth}
                            data={internalCanvasData}
                            visualConfig={visualConfig}
                            changeScale={changeScale}
                            setSelectedEntityData={setSelectedEntityData}
                            setData={setCanvasData}
                            addRect={addRect}
                            removeRect={removeRect}
                            rotateRect={rotateRect}
                            onRowClicked={onRowClicked}
                        />
                        <InventorySchemaVisualizerControlPanel
                            showDepth={mode === InventorySchemaVisualizerModes.view}
                            depth={visualConfig.depth}
                            changeScale={changeScale}
                            setDepth={setDepth}
                        />
                    </>
                }
            </div>
        </>
    )
})

export default InventorySchemaVisualizer;
