import React, {useEffect} from "react";
import useRouter from "../../../hooks/use-router";
import routes from "../../../routes";
import {useTranslation} from "react-i18next";
import {ReactComponent as ServerErrorIcon} from "../../../../assets/images/views/error/_server-error.svg";
import {Col, Container, Row} from "react-bootstrap";

const ServerError = () => {
    const {history, location} = useRouter();
    const {t} = useTranslation();

    /**
     * checks the pathname of the url based on serverErrorForSection route,
     * no prefix => change the url to serverErrorForSection
     */
    useEffect(() => {
        if (location.pathname === routes.error.serverErrorForSection) {
            history.replace(routes.error.serverError);
            history.goBack();
        }
    }, [location.pathname])


    return (
        <>
            <div className={'svg-container'}>
                <ServerErrorIcon/>
            </div>
            <Container>
                <Row>
                    <Col xs={12} className={'flex-row-center'}>
                        <p className={'message'}>
                            {t('views.errors.serverError.message')}
                        </p>
                    </Col>
                    <Col xs={12} className={'flex-row-center mt-3'}>
                        <button
                            className={'button primary px-5'}
                            onClick={history.goBack}>
                            {t('views.errors.serverError.actionButton')}
                        </button>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ServerError;
