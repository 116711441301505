import React from "react";
import useRouter from "../../../../hooks/use-router";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import {makeRequired, makeValidate} from "mui-rff";
import Form from "../../../base/form";
import classnames from "classnames";
import {SearchFormInput} from "../../../base/input/search";
import {FormSelect} from "../../../base/select/select";
import {Col, Row} from "react-bootstrap";
import {PositionCountingListQueryNames} from "../../../../../core/constants/query-names";

// Form keys
const formKeys = {
    keywords: 'keyword',
    warehouse: 'warehouse'
}

// Schema for the form validation of search section
const makeSchema = (validationTranslation) => {
    return Yup.object().shape({
        [formKeys.keywords]: Yup.string().when(formKeys.keywords, {
            is: val => !val?.length,
            then: Yup.string().nullable(),
            otherwise: Yup.string().nullable().required(validationTranslation?.required ?? '')
        }),
        [formKeys.warehouse]: Yup.object().nullable(),
    }, [[formKeys.keywords, formKeys.keywords]]);
}

const PositionCountingSearchSection = ({filters, warehouses}) => {
    const {t} = useTranslation();
    const {query, location, history, stringifyUrl} = useRouter();

    const translations = t('views.panel.company.counting.position.list', {returnObjects: true});
    const validationTranslation = t('utils.formValidation', {returnObjects: true});
    const schema = makeSchema(validationTranslation);
    const validate = makeValidate(schema);
    const required = makeRequired(schema);

    /**
     * Sets the query of the url based on the value in the form
     * @param values
     */
    const onSearch = (values) => {
        console.log(values);
        history.push(stringifyUrl({
            url: location.pathname,
            query:
                {
                    ...query,
                    [PositionCountingListQueryNames.keyword]: values[formKeys.keywords] ?? undefined,
                    [PositionCountingListQueryNames.warehouse]: values[formKeys.warehouse]?.title ?? undefined,
                }
        }))
    }


    return (
        <>
            <Form
                onSubmit={onSearch}
                validate={validate}
                className={'position-counting-search-section'}
                initialValues={filters}
                render={({submitting}) => {
                    return (
                        <>
                            <Row>
                                <Col xs={12} md={4} className='mt-5'>
                                    <FormSelect
                                        name={formKeys.warehouse}
                                        placeholder={translations?.form?.warehouse?.placeholder ?? ''}
                                        label={translations?.form?.warehouse?.title ?? ''}
                                        data={warehouses?.map(e => ({
                                            id: e.id,
                                            value: e
                                        }))}
                                    />
                                </Col>
                            </Row>
                            <SearchFormInput
                                className={'search-field'}
                                placeholder={translations?.searchSection?.searchFieldPlaceHolder ?? ''}
                                fullWidth
                                required={required[formKeys.keywords]}
                                name={formKeys.keywords}
                                buttonProps={{
                                    className: classnames('button primary'),
                                    children: translations?.searchSection?.action ?? '',
                                    type: "submit",
                                    disabled: submitting
                                }}
                            />
                        </>
                    )
                }}
            />
        </>
    );
}

export default PositionCountingSearchSection;
