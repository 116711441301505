import {sidebarIds} from "../../../../core/constants/ids";
import routes, {routeFunctions} from "../../../routes";
import {ReactComponent as Dashboard} from "../../../../assets/images/panel/sidebar/dashboard.svg";
import {ReactComponent as Users} from "../../../../assets/images/panel/sidebar/users.svg";
import {ReactComponent as Counting} from "../../../../assets/images/panel/sidebar/counting.svg";
import {ReactComponent as Products} from "../../../../assets/images/panel/sidebar/products.svg";
import {ReactComponent as Warehouses} from "../../../../assets/images/panel/sidebar/warehouses.svg";
import {ReactComponent as Payments} from "../../../../assets/images/panel/sidebar/payments.svg";
import {ReactComponent as SalesOrder} from "../../../../assets/images/panel/sidebar/sales-order.svg";
import {ReactComponent as PurchaseOrder} from "../../../../assets/images/panel/sidebar/purchase-order.svg";


/**
 * The map of the sidebar structure for its menu and menu products and their information.
 */
const sidebarMap = {
    header: [
        {
            id: sidebarIds.header.editProfile,
            name: (t) => t('containers.panel.sidebar.header.editProfile.title'),
            route: {
                path: routes.panel.editProfile,
                exact: true,
            },
        },
        {
            id: sidebarIds.header.logout,
            name: (t) => t('containers.panel.sidebar.header.logout.title'),
        },
    ],
    body: [
        {
            id: sidebarIds.body.dashboard,
            icon: <Dashboard/>,
            name: (t) => t('containers.panel.sidebar.body.dashboard.title'),
            to: routeFunctions.panel.company.dashboard,
            route: {
                path: routes.panel.company.dashboard,
                exact: true,
            },
        },
        {
            id: sidebarIds.body.products.id,
            icon: <Products/>,
            name: (t) => t('containers.panel.sidebar.body.products.title'),
            route: {
                path: routes.panel.company.items.base,
                exact: false,
            },
            children: [
                {
                    id: sidebarIds.body.products.list,
                    name: (t) => t('containers.panel.sidebar.body.products.children.list.title'),
                    to: routeFunctions.panel.company.items.list.products,
                    route: {
                        path: [
                            routes.panel.company.items.list.products,
                            routes.panel.company.items.single.product,
                        ],
                        exact: true,
                    },
                },
                {
                    id: sidebarIds.body.products.instances,
                    name: (t) => t('containers.panel.sidebar.body.products.children.instances.title'),
                    to: routeFunctions.panel.company.items.list.instances,
                    route: {
                        path: [
                            routes.panel.company.items.list.instances,
                            routes.panel.company.items.single.instance,
                        ],
                        exact: true,
                    },
                },
            ],
        },
        {
            id: sidebarIds.body.salesOrder,
            icon: <SalesOrder/>,
            name: (t) => t('containers.panel.sidebar.body.salesOrder.title'),
            to: routeFunctions.panel.company.salesOrders.list,
            route: {
                path: routes.panel.company.salesOrders.base,
                exact: false,
            },
        },
        {
            id: sidebarIds.body.purchaseOrder,
            icon: <PurchaseOrder/>,
            name: (t) => t('containers.panel.sidebar.body.purchaseOrder.title'),
            to: routeFunctions.panel.company.purchaseOrders.list,
            route: {
                path: routes.panel.company.purchaseOrders.base,
                exact: false,
            },
        },
        {
            id: sidebarIds.body.location,
            icon: <Warehouses/>,
            name: (t) => t('containers.panel.sidebar.body.location.title'),
            to: routeFunctions.panel.company.location.warehouses,
            route: {
                path: routes.panel.company.location.base,
                exact: false,
            },
        },
        {
            id: sidebarIds.body.counting.id,
            icon: <Counting/>,
            name: (t) => t('containers.panel.sidebar.body.counting.title'),
            route: {
                path: routes.panel.company.counting.base,
                exact: false,
            },
            children: [
                {
                    id: sidebarIds.body.counting.spire,
                    name: (t) => t('containers.panel.sidebar.body.counting.children.spire.title'),
                    to: routeFunctions.panel.company.counting.spire,
                    route: {
                        path: [
                            routes.panel.company.counting.list.spireCountings,
                            routes.panel.company.counting.single.spireCounting
                        ],
                        exact: true,
                    },
                },
                {
                    id: sidebarIds.body.counting.position,
                    name: (t) => t('containers.panel.sidebar.body.counting.children.position.title'),
                    to: routeFunctions.panel.company.counting.position,
                    route: {
                        path: [
                            routes.panel.company.counting.list.positionCountings,
                            routes.panel.company.counting.single.positionCounting,
                        ],
                        exact: true,
                    },
                },
            ],
        },
        {
            id: sidebarIds.body.userManagement.id,
            icon: <Users/>,
            name: (t) => t('containers.panel.sidebar.body.users.title'),
            to: routeFunctions.panel.company.users.list,
            route: {
                path: routes.panel.company.users.base,
                exact: false,
            },
            // children: [
            //     {
            //         id: sidebarIds.body.userManagement.list,
            //         name: (t) => t('containers.panel.sidebar.body.users.children.list.title'),
            //         to: routeFunctions.panel.company.users.list,
            //         route: {
            //             path: routes.panel.company.users.list,
            //             exact: true,
            //         },
            //     },
            //     {
            //         id: sidebarIds.body.userManagement.groups,
            //         name: (t) => t('containers.panel.sidebar.body.users.children.groups.title'),
            //         to: routeFunctions.panel.company.users.groups,
            //         route: {
            //             path: routes.panel.company.users.groups,
            //             exact: true,
            //         },
            //     },
            // ],
        },
        {
            id: sidebarIds.body.payments.id,
            icon: <Payments/>,
            name: (t) => t('containers.panel.sidebar.body.payments.title'),
            route: {
                path: [
                    routes.panel.subscription,
                    routes.panel.paymentMethods,
                    routes.panel.invoices,
                ],
                exact: false,
            },
            children: [
                {
                    id: sidebarIds.body.payments.subscription,
                    name: (t) => t('containers.panel.sidebar.body.payments.children.subscription.title'),
                    route: {
                        path: routes.panel.subscription,
                        exact: true,
                    },
                },
                {
                    id: sidebarIds.body.payments.methods,
                    name: (t) => t('containers.panel.sidebar.body.payments.children.methods.title'),
                    route: {
                        path: routes.panel.paymentMethods,
                        exact: true,
                    },
                },
                {
                    id: sidebarIds.body.payments.invoices,
                    name: (t) => t('containers.panel.sidebar.body.payments.children.invoices.title'),
                    route: {
                        path: routes.panel.invoices,
                        exact: true,
                    },
                },
            ],
        },
    ],

}

export default sidebarMap;
