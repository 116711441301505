import {createContext} from "react";

/**
 * @typedef {null | {id: number, name: string}} CompanyContextState
 */

/**
 * The initial Values used for the company context
 * @type {CompanyContextState}
 */
export const CompanyContextInitialValues = null;

/**
 * Context used to store the selected company of the user.
 */
export const CompanyContext = createContext(CompanyContextInitialValues);
