import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import PricePlanCard from "../../components/app-specific/price-package-card";
import NoEntries from "../../components/app-specific/no-entries";


const ApplicationPricePlans = ({
                                   onPlanSelected,
                                   showUpgradesOnly = false,
                                   setActivePlan,
                                   setLoading: setLoadingProp,
                                   cardProps,
                                   buttonText = undefined,
                               }) => {
    const [plans, setPlans] = useState([]);
    const [loading, setLoading] = useState(true);
    const {t, ready, i18n} = useTranslation();
    const translations = t("views.panel.errorMessages", {returnObjects: true});

    /**
     * Listens to the changes in translationReady and language and showUpgradeOnly with each change:
     *
     * fetches the list of application price plans
     */
    useEffect(() => {
        if (!ready) return;
        setLoading(true);
        if (setLoadingProp) setLoadingProp(true);
        getApplicationPricePlans(showUpgradesOnly);
    }, [ready, i18n.language, showUpgradesOnly]);

    /**
     * Gets the list of available price plans of the applications
     *
     * Different api calls are made based on the upgradesOnly argument,
     * @param {boolean} upgradesOnly whether to call the applicationPricePlans for a specific user or not
     */
    const getApplicationPricePlans = (upgradesOnly) => {
        //TODO: call api for fetching the list of price plans (2 apis, upgradesOnly condition)
        const plans = [
            {
                name: "Basic Package",
                id: 1,
                price: 99.99,
                properties: [
                    {
                        title: "companies",
                        value: 2,
                    },
                    {
                        title: "warehouses",
                        value: 2,
                    },
                    {
                        title: "operators",
                        value: 2,
                    },
                    {
                        title: "transactions",
                        value: 10000,
                        description: "This is the description of the trnasaction that is supposed to be quote long" +
                            " to be host but you know, its like you've been living a dream and somehow when it gets" +
                            " time to wake up, you almost dont want to accept the harsh reality"
                    },
                ],
            },
            {
                name: "Silver Package",
                id: 2,
                price: 129.99,
                properties: [
                    {
                        title: "companies",
                        value: 10,
                    },
                    {
                        title: "warehouses",
                        value: 23,
                    },
                    {
                        title: "operators",
                        value: 8,
                    },
                    {
                        title: "transactions",
                        value: 20000,
                        description: "This is the description of the trnasaction that is supposed to be quote long" +
                            " to be host but you know, its like you've been living a dream and somehow when it gets" +
                            " time to wake up, you almost dont want to accept the harsh reality"
                    },
                ],
            },
            {
                name: "Golden Package",
                id: 3,
                price: 199.99,
                properties: [
                    {
                        title: "companies",
                        value: 130,
                    },
                    {
                        title: "warehouses",
                        value: 200,
                    },
                    {
                        title: "operators",
                        value: 100,
                    },
                    {
                        title: "transactions",
                        value: 25000,
                        description: "This is the description of the trnasaction that is supposed to be quote long" +
                            " to be host but you know, its like you've been living a dream and somehow when it gets" +
                            " time to wake up, you almost dont want to accept the harsh reality"
                    },
                ],
            },
            t('containers.applicationPricePlans.noPricePlan', {returnObjects: true}),
        ];
        const currentPlan = {
            nextBill: '2021-09-20',
            isActive: true,
            name: "Golden Package",
            id: 3,
            price: 199.99,
            properties: [
                {
                    title: "companies",
                    value: 130,
                },
                {
                    title: "warehouses",
                    value: 200,
                },
                {
                    title: "operators",
                    value: 100,
                },
                {
                    title: "transactions",
                    value: 25000,
                    description: "This is the description of the trnasaction that is supposed to be quote long" +
                        " to be host but you know, its like you've been living a dream and somehow when it gets" +
                        " time to wake up, you almost dont want to accept the harsh reality"
                },
            ],
        };
        setPlans([...plans.map(e => ({
            ...e,
            button: e.price === null
                ? t('containers.applicationPricePlans.common.contactUsButton')
                : t(buttonText ?? 'containers.applicationPricePlans.common.registerButton'),
            onClick: () => onPlanSelected(e),
        }))])
        if (setActivePlan) {
            setActivePlan(currentPlan);
        }
        setLoading(false);
        if (setLoadingProp) setLoadingProp(false);
    }


    return (
        <>
            <Row className={'price-plans'}>
                {
                    loading
                        ? (
                            Array(4).fill(null).map((e, index) => (
                                <Col
                                    key={index}
                                    xs={12} sm={6} md={4} lg={3}
                                    className={'d-flex justify-content-center'}
                                    {...cardProps}
                                >
                                    <div className={'loading price-plan'}>
                                        <div/>
                                    </div>
                                </Col>
                            ))
                        )
                        : plans?.length > 0
                            ? plans?.map((plan) => (
                                <Col
                                    key={plan.id}
                                    xs={12} sm={6} md={4} lg={3}
                                    className={'d-flex justify-content-center'}
                                    {...cardProps}
                                >
                                    <PricePlanCard data={plan}/>
                                </Col>
                            ))
                            : <NoEntries
                                text={translations}
                                includeButton={false}
                            />

                }
            </Row>
        </>
    )
}


export default ApplicationPricePlans;
