import React, {useContext, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {DefaultInitialPaginationInfo, TableCellAlignments, TableCellTypes} from "../../../../../../../core/constants/enums";
import {PartInstanceListQueryNames} from "../../../../../../../core/constants/query-names";
import {exportCSVFile} from "../../../../../../../core/services/utils";
import useRouter from "../../../../../../hooks/use-router";
import {ReactComponent as ConvertToExcel} from "../../../../../../../assets/images/convert-to-excel.svg";
import InstancesSearchSection from "../../../../../../components/search-sections/items/list/instances";
import NoEntries from "../../../../../../components/app-specific/no-entries";
import Table from "../../../../../../containers/table";
import {routeFunctions} from "../../../../../../routes";
import {ReactComponent as BarcodeIcon} from "../../../../../../../assets/images/barcode-icon.svg";
import {dialogActions} from "../../../../../../../redux/entities/dialog-slice";
import {useDispatch} from "react-redux";
import {Col, Row} from "react-bootstrap";
import {ReactComponent as EditIcon} from "../../../../../../../assets/images/edit-icon.svg";
import Barcode from "react-barcode";
import {lighterPrimaryColor, primaryTextColor} from "../../../../../../../assets/mui/colors";
import ImageSection from "../../../../../../components/app-specific/product-image-section";
import useWindowViewportWidth from "../../../../../../hooks/use-window";
import useIsMounted from "../../../../../../hooks/use-is-mounted";
import moment from "moment";
import {CompanyContext} from "../../../../../../contexts/company";
import useSearchData from "../../../../../../hooks/use-search-data";
import QueryManagementUtils from "../../../../../../../core/services/query-management-utils";


const initialPaginationInfo = {
    ...DefaultInitialPaginationInfo,
    pageSize: 10,
}

// Object indicating the table cell keys
const tableCellKeys = {
    serialNumber: 'serialNumber',
    warehouse: 'warehouse',
    aisle: 'aisle',
    section: 'section',
    row: 'row',
    enterDate: 'enterDate',
    exitDate: 'exitDate',
    count: 'count'
}

// Samples statuses
const statuses = [
    {
        id: 1,
        title: "Active"
    },
    {
        id: 2,
        title: "Testing"
    }
]

// Sample warehouses
const warehouses = [
    {
        id: 1,
        title: "00"
    },
    {
        id: 2,
        title: "Test"
    }
]

// Sample Aisles
const aisles = [
    {
        id: 1,
        title: 'A1',
        warehouse: {
            id: 1
        },
    },
    {
        id: 2,
        title: 'A2',
        warehouse: {
            id: 1
        },
    },
    {
        id: 3,
        title: 'A3',
        warehouse: {
            id: 2
        },
    },
    {
        id: 4,
        title: 'A4',
        warehouse: {
            id: 2
        },
    },
]

// Sample sections
const sections = [
    {
        id: 1,
        title: 'S1',
        aisle: {
            id: 1
        }
    },
    {
        id: 2,
        title: 'S2',
        aisle: {
            id: 1
        }
    },
    {
        id: 3,
        title: 'S3',
        aisle: {
            id: 3
        }
    },
    {
        id: 4,
        title: 'S4',
        aisle: {
            id: 3
        }
    },
]

// Sample rows
const rows = [
    {
        id: 1,
        title: 'R1',
        section: {
            id: 1
        },
    },
    {
        id: 2,
        title: 'R2',
        section: {
            id: 1
        },
    },
    {
        id: 3,
        title: 'R3',
        section: {
            id: 2
        },
    },
    {
        id: 4,
        title: 'R4',
        section: {
            id: 2
        },
    },
]

const applicableSizesForWidthDecrease = ['xs', 'sm', 'md', 'lg'];

const ProductView = () => {
    const {t} = useTranslation();
    const {query, location, history, params, stringifyUrl} = useRouter()
    const [partFullInfo, setPartFullInfo] = useState({});
    const [parts, setParts] = useState([]);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    /**@type {React.MutableRefObject<HTMLDivElement>}*/
    const imageSectionRef = useRef();
    const [imageSectionDimensions, setImageSectionDimensions] = useState();
    const viewportWidth = useWindowViewportWidth();
    const isMounted = useIsMounted();
    const [isGeneratingExcelReport, setIsGeneratingExcelReport] = useState(false);

    const translation = t("views.panel.company.products.single.part", {returnObjects: true});

    //Panel company
    const [company] = useContext(CompanyContext);
    const selectedCompany = useRef(company);

    /**
     * With each change in the selected company:
     */
    useEffect(() => {
        if (selectedCompany.current?.id === company?.id)
            return;
        selectedCompany.current = company;
        //   TODO: implement the action related to this which is navigate back
    }, [company?.id]);

    const informationSectionStyle = useMemo(() => ({
        ...(
            applicableSizesForWidthDecrease.includes(viewportWidth)
                ? {}
                : {height: (imageSectionDimensions?.height) ?? 0}
        )
    }), [viewportWidth, imageSectionDimensions]);

    const spacerStyles = useMemo(() => ({
        // width: imageSectionDimensions?.width ?? 0,
        ...(
            applicableSizesForWidthDecrease.includes(viewportWidth)
                ? {
                    width: 0
                }
                : {width: (imageSectionDimensions?.width + 200) ?? 0}
        )
    }), [viewportWidth, imageSectionDimensions])

    /**
     * with each change in the imageSectionRef current:
     * - attaches a new resize observer to the image section
     */
    useLayoutEffect(() => {
        if (!imageSectionRef.current) return;
        const observer = new ResizeObserver(onImageSectionResized);
        observer.observe(imageSectionRef.current);
        onImageSectionResized([imageSectionRef.current]);
        return () => {
            observer.disconnect();
        }
    }, [imageSectionRef]);

    /**
     * With each change in the instance id in the url param changes the part full information
     */
    useEffect(() => {
        setPartFullInfo({
            partNumber: 'RADFR153A',
            upc: '123123123',
            isSerialized: true,
            height: 20,
            weight: 60,
            width: 60,
            depth: 25,
            totalAvailability: 2012,
            spireAvailability: 200,
            images: [
                {
                    id: 1,
                    src: 'https://admin.chtp.com/files/4c146b533ab249c39528d71daf7ea7ba.jpg'
                },
                {
                    id: 2,
                    src: 'https://admin.chtp.com/files/9a2422ac784b48f2af1064f8897e87df.jpg'
                },
                {
                    id: 3,
                    src: 'https://admin.chtp.com/files/b92922e008a64a578dfff8995f57ea31.jpg'
                },
                {
                    id: 4,
                    src: 'https://admin.chtp.com/files/4c146b533ab249c39528d71daf7ea7ba.jpg'
                }
            ],
        });
    }, [params.id])

    /**
     * Handles the changes of the
     * //TODO: Ask why we made this call back being called on the first time as well
     * @param {( HTMLDivElement | ResizeObserverEntry)[] } entries
     */
    const onImageSectionResized = (entries) => {
        if (!entries.length)
            return;
        const element = entries[0];
        const clientRect = element.contentRect ?? element.getBoundingClientRect();
        setImageSectionDimensions({
            width: clientRect.width,
            height: clientRect.height,
        });
    }

    /**
     * Searches for part instances:
     * - Calls teh api regarding this action with the given pagination info, orderBy, and search filters
     * @param forApiData
     */
    const search = async () => {
        const forApi = prepareRequestBody();
        const response = {
            resultFlag: true,
            data: {
                items: [
                    {
                        id: 1,
                        partNumber: 'RADFR153A',
                        serializable: true,
                        serialNumber: '123-123-123',
                        warehouse: {
                            id: 1,
                            title: "00"
                        },
                        aisle: {
                            id: 1,
                            title: 'A1'
                        },
                        section: {
                            id: 1,
                            title: 'S1'
                        },
                        row: {
                            id: 1,
                            title: 'R1'
                        },
                        enterDate: new Date(),
                        exitDate: new Date(),
                        count: 1,
                    },
                    {
                        id: 2,
                        partNumber: 'CACDO102',
                        serializable: false,
                        serialNumber: null,
                        warehouse: {
                            id: 1,
                            title: "00"
                        },
                        aisle: {
                            id: 1,
                            title: 'A1'
                        },
                        section: {
                            id: 1,
                            title: 'S1'
                        },
                        row: {
                            id: 1,
                            title: 'R1'
                        },
                        enterDate: new Date(),
                        exitDate: new Date(),
                        count: 20,
                    },
                    {
                        id: 3,
                        partNumber: 'RADFR153A',
                        serializable: true,
                        serialNumber: '123-123-123',
                        warehouse: {
                            id: 1,
                            title: "00"
                        },
                        aisle: {
                            id: 1,
                            title: 'A1'
                        },
                        section: {
                            id: 1,
                            title: 'S1'
                        },
                        row: {
                            id: 1,
                            title: 'R1'
                        },
                        enterDate: new Date(),
                        exitDate: new Date(),
                        count: 1,
                    },
                    {
                        id: 4,
                        partNumber: 'CONVO108',
                        serializable: false,
                        serialNumber: null,
                        warehouse: {
                            id: 1,
                            title: "00"
                        },
                        aisle: {
                            id: 1,
                            title: 'A1'
                        },
                        section: {
                            id: 1,
                            title: 'S1'
                        },
                        row: {
                            id: 1,
                            title: 'R1'
                        },
                        enterDate: new Date(),
                        exitDate: new Date(),
                        count: 10,
                    },
                    {
                        id: 5,
                        partNumber: 'RADFR153A',
                        serializable: true,
                        serialNumber: '123-123-123',
                        warehouse: {
                            id: 1,
                            title: "00"
                        },
                        aisle: {
                            id: 1,
                            title: 'A1'
                        },
                        section: {
                            id: 1,
                            title: 'S1'
                        },
                        row: {
                            id: 1,
                            title: 'R1'
                        },
                        enterDate: new Date(),
                        exitDate: new Date(),
                        count: 1,
                    },
                ],
                paginationInfo: {
                    length: 5
                }
            }
        };
        if (!isMounted())
            return;
        if (response?.resultFlag) {
            setParts(response?.data?.items);
            const newPaginationInfo = {
                ...paginationInfo,
                length: response?.data.paginationInfo.length ?? 0
            }
            QueryManagementUtils.setPaginationInfo(newPaginationInfo, query, history, location);
        }
        setLoading(false);
    }

    const {filters, paginationInfo, orderBy} = useSearchData(
        PartInstanceListQueryNames,
        search,
        {
            useFilters: true,
            initialFilters: {},
            filtersQueryParamMap: {
                keyword: PartInstanceListQueryNames.keyword,
                rows: PartInstanceListQueryNames.row,
                aisles: PartInstanceListQueryNames.aisle,
                sections: PartInstanceListQueryNames.section,
                warehouses: PartInstanceListQueryNames.warehouse,
                statuses: PartInstanceListQueryNames.status,
            },

            usePaginationInfo: true,
            initialPaginationInfo: initialPaginationInfo,

            useOrderBy: true,
            initialOrderBy: undefined,
        }
    );

    /**
     * Prepares the request ds for the search api
     */
    const prepareRequestBody = () => {
        return {
            orderBy: orderBy,
            paginationInfo: paginationInfo,
            filters: filters
        }
    }

    /**
     * Clears the search filters
     */
    const clearFilters = () => {
        history.push(stringifyUrl({
            url: location.pathname,
            query: {}
        }));
    }

    /**
     * Handles click on the serial number text button:
     * - Navigates user to the part instance single view
     * @param part
     */
    const onSerialNumberClicked = (part) => {
        history.push(routeFunctions.panel.company.items.single.instance(params.company, part.id))
    }

    /**
     * Handles click on the warehouse code text button:
     * - Navigates user to the warehouse view
     * @param warehouseId
     */
    const onWarehouseClicked = (warehouseId) => {
        //TODO: to be implemented
    }

    /**
     * Handles click on the aisle code text button:
     * - Navigates user to the aisle view
     * @param aisleId
     */
    const onAisleClicked = (aisleId) => {
        //TODO: to be implemented
    }

    /**
     * Handles click on the section code text button:
     * - Navigates user to the section view
     * @param sectionId
     */
    const onSectionClicked = (sectionId) => {
        //TODO: to be implemented
    }

    /**
     * Handles click on the row code text button:
     * - Navigates user to the row  view
     * @param rowId
     */
    const onRowClicked = (rowId) => {
        //TODO: to be implemented
    }

    /**
     * Handles click on the barcode icon button:
     * - Opens dialog for showing the barcode
     * @param {string} partNumber for generating barcode from
     */
    const onBarcodeClicked = (partNumber) => {
        dispatch(dialogActions.configBarcodeDialog({
            open: true,
            onClose: () => dispatch(dialogActions.barcodeDialogToggle(false)),
            barcodeCode: partNumber,
        }));
    }

    /**
     * Generates excel report for the part instances
     */
    const generateExcelReport = async () => {
        setIsGeneratingExcelReport(true);
        //TODO: change the functionality when api call is ready
        const response = await search();
        if (!isMounted())
            return;
        setIsGeneratingExcelReport(false);
        if (response?.resultFlag) {
            const title = translation?.information?.excelTitle;
            const content = response.data.items?.map(e => ({
                [translation?.tableHeaders.serialNumber]: e?.serialNumber ?? 'N/A',
                [translation?.tableHeaders.warehouse]: `${e.warehouse.title}`,
                [translation?.tableHeaders.aisle]: `${e.aisle.title}`,
                [translation?.tableHeaders.section]: `${e.section.title}`,
                [translation?.tableHeaders.row]: `${e.row.title}`,
                [translation?.tableHeaders.enterDate]: `${moment(e?.enterDate).format('DD-MM-YYYY HH:mm:ss')}`,
                [translation?.tableHeaders.exitDate]: `${moment(e?.exitDate).format('DD-MM-YYYY HH:mm:ss')}`,

            }))
            const headers = Object.keys(content[0]);
            exportCSVFile(headers, content, title);
        }
    }

    /**
     * Handles adding a new image to the list of images of the part:
     */
    const onAddImageClicked = () => {
    }

    /**
     * Handles click on the cross icon button on images in the bottom slide bar:
     * - Opens confirmation dialog for confirming the remove action
     * - After confirmation, calls the api regarding this action
     * @param index
     */
    const onRemoveImage = (index) => {
    }

    /**
     * Memo version of table columns
     * @type {object[]}
     */
    const tableColumns = useMemo(() => [
        // If item is serialized shows serial number list
        ...(partFullInfo.isSerialized ? [{
            title: translation?.tableHeaders.serialNumber,
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: tableCellKeys.serialNumber,
            sortable: true,
            size: 1.5
        },] : []),

        // If item is not serialized shows count list
        ...(!partFullInfo.isSerialized ? [
            {
                title: translation?.tableHeaders.count,
                alignment: TableCellAlignments.left,
                type: TableCellTypes.string,
                name: tableCellKeys.count,
                sortable: true
            },
        ] : []),

        {
            title: translation?.tableHeaders.warehouse,
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: tableCellKeys.warehouse,
            sortable: true
        },
        {
            title: translation?.tableHeaders.aisle,
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: tableCellKeys.aisle,
            sortable: true
        },
        {
            title: translation?.tableHeaders.section,
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: tableCellKeys.section,
            sortable: true
        },
        {
            title: translation?.tableHeaders.row,
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: tableCellKeys.row,
            sortable: true
        },
        {
            title: translation?.tableHeaders.enterDate,
            alignment: TableCellAlignments.left,
            type: TableCellTypes.dateTime,
            name: tableCellKeys.enterDate,
            sortable: true,
            size: 1.5
        },
        {
            title: translation?.tableHeaders.exitDate,
            alignment: TableCellAlignments.left,
            type: TableCellTypes.dateTime,
            name: tableCellKeys.exitDate,
            sortable: true
        },

    ], [partFullInfo, translation]);

    /**
     * Memo version of table rows
     * @type {object[]}
     */
    const tableRows = useMemo(() => {
        return parts?.map(part => (
            {
                key: part?.id,
                [tableCellKeys.serialNumber]: () => (
                    <div className={'d-flex flex-row align-items-center'}>
                        {
                            part?.serialNumber?.length > 0 &&
                            <div
                                onClick={() => onBarcodeClicked(part?.serialNumber)}
                                className={'barcode-icon-button'}
                            >
                                <BarcodeIcon/>
                            </div>
                        }
                        <div onClick={() => onSerialNumberClicked(part)}>
                            <p className={'text-button'}>{part?.serialNumber}</p>
                        </div>
                    </div>
                ),
                [tableCellKeys.warehouse]: () => (
                    <div onClick={() => onWarehouseClicked(part.warehouse.id)}>
                        <p className={'text-button'}>{part?.warehouse?.title}</p>
                    </div>
                ),
                [tableCellKeys.aisle]: () => (
                    <div onClick={() => onAisleClicked(part.aisle.id)}>
                        <p className={'text-button'}>{part?.aisle?.title}</p>
                    </div>
                ),
                [tableCellKeys.section]: () => (
                    <div onClick={() => onSectionClicked(part.section.id)}>
                        <p className={'text-button'}>{part?.section?.title}</p>
                    </div>
                ),
                [tableCellKeys.row]: () => (
                    <div onClick={() => onRowClicked(part.row.id)}>
                        <p className={'text-button'}>{part?.row?.title}</p>
                    </div>
                ),
                [tableCellKeys.enterDate]: part?.enterDate,
                [tableCellKeys.exitDate]: part?.exitDate,
                [tableCellKeys.count]: part.count,
            }
        ));
    }, [parts])

    return (
        <>
            <div className={'panel-card  single-product-view'}>
                <div className={'information-section'} style={informationSectionStyle}>
                    <ImageSection
                        loading={loading}
                        ref={imageSectionRef}
                        onRemoveImage={onRemoveImage}
                        partImages={partFullInfo.images}
                        onAddImageClicked={onAddImageClicked}
                        translation={translation}
                    />
                    <div className={'d-flex  flex-grow-1 w-100 information-section-details'}>
                        <div className={'information-section-spacer'}
                             style={spacerStyles}
                        />
                        <Row className={'w-100'}>
                            <Col xs={12} md={6}>
                                <div className={'mt-4 m-xl-0'}>
                                    <div className={'d-flex flex-row  align-items-center justify-content-center justify-content-md-start'}>
                                        <p className={'part-number'}>{partFullInfo.partNumber}</p>
                                        <EditIcon className={'edit-icon-button'}/>
                                    </div>
                                    <div className={'d-flex flex-row  align-items-center justify-content-center justify-content-md-start mb-4'}>
                                        <p className={'info-title'}>{`${translation.information?.upc ?? ''}:`}</p>
                                        <p className={'info-value'}>{partFullInfo.upc}</p>
                                    </div>
                                    <div className={'d-flex flex-row  align-items-center justify-content-center justify-content-md-start mb-2'}>
                                        <p className={'info-title'}>{`${translation.information?.height ?? ''}:`}</p>
                                        <p className={'info-value'}>{`${partFullInfo.height ?? 0} m`}</p>
                                    </div>
                                    <div className={'d-flex flex-row  align-items-center justify-content-center justify-content-md-start mb-2'}>
                                        <p className={'info-title'}>{`${translation.information?.weight ?? ''}:`}</p>
                                        <p className={'info-value'}>{`${partFullInfo.weight ?? 0} m`}</p>
                                    </div>
                                    <div className={'d-flex flex-row  align-items-center justify-content-center justify-content-md-start mb-2'}>
                                        <p className={'info-title'}>{`${translation.information?.width ?? ''}:`}</p>
                                        <p className={'info-value'}>{`${partFullInfo.width ?? 0} m`}</p>
                                    </div>
                                    <div className={'d-flex flex-row  align-items-center justify-content-center justify-content-md-start mb-2'}>
                                        <p className={'info-title'}>{`${translation.information?.depth ?? ''}:`}</p>
                                        <p className={'info-value'}>{`${partFullInfo.depth ?? 0} m`}</p>
                                    </div>
                                </div>

                            </Col>
                            <Col xs={12} md={6}>
                                <div className={'d-flex flex-column align-items-center justify-content-center mt-4 m-xl-0'}>
                                    <div className={'d-flex flex-row  align-items-center justify-content-start'}>
                                        <p className={'availability-title'}>{`${translation.information?.totalAvailability ?? ''}:`}</p>
                                        <p className={'availability-value'}>{partFullInfo.totalAvailability?.toLocaleString()}</p>
                                    </div>
                                    <div className={'d-flex flex-row  align-items-center justify-content-start'}>
                                        <p className={'availability-title'}>{`${translation.information?.spireAvailability ?? ''}:`}</p>
                                        <p className={'availability-value'}>{partFullInfo.spireAvailability?.toLocaleString()}</p>
                                    </div>
                                    <div className={'d-flex flex-column barcode-info mt-3'}>
                                        <Barcode
                                            value={partFullInfo.partNumber ?? ''}
                                            displayValue={false}
                                            font={"Montserrat"}
                                            fontSize={15}
                                            background={lighterPrimaryColor}
                                            lineColor={primaryTextColor}
                                            width={2}
                                            height={150}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className={'d-flex align-items-center justify-content-between  px-4'}>
                    <p className={'title'}>
                        {translation?.title ?? ''}
                    </p>
                    <div className={'d-flex align-items-center justify-content-center'}>
                        <p className={'me-3 total-instance-count'}>
                            {translation?.information?.total?.concat(paginationInfo?.length)}
                        </p>
                        <button
                            onClick={generateExcelReport}
                            className={'button primary outlined report'}>
                            <ConvertToExcel className={'me-1'}/>
                            <p>
                                {isGeneratingExcelReport ? (translation.generatingReport ?? '') : (translation?.information?.convertToExcel ?? '')}
                            </p>
                        </button>
                    </div>
                </div>
                <div className={'p-4'}>
                    <InstancesSearchSection
                        filters={filters}
                        warehouses={warehouses}
                        aisles={aisles}
                        rows={rows}
                        sections={sections}
                        statuses={statuses}
                    />
                </div>
                <div className={'p-4'}>
                    {
                        !!parts?.length < 1 && !loading
                            ? <NoEntries
                                text={
                                    Object.entries(filters ?? {})?.length > 0
                                        ? translation?.empty?.withFilters
                                        : translation?.empty?.text
                                }
                                includeButton={Object.entries(filters ?? {})?.length > 0}
                                buttonProps={{
                                    onClick: clearFilters,
                                    className: 'button primary px-3'
                                }}/>
                            : <Table
                                loading={{state: loading, count: 8}}
                                className={'my-3'}
                                color={'primary'}
                                cells={tableColumns}
                                data={tableRows}
                                paginationInfo={paginationInfo}
                                orderBy={orderBy}
                                onSort={(orderBy) => QueryManagementUtils.setOrderBy(
                                    orderBy,
                                    query,
                                    history,
                                    location,
                                )}
                                onPageSizeChange={(p, i) => QueryManagementUtils.setPaginationInfo(
                                    {...p, pageSize: i},
                                    query,
                                    history,
                                    location
                                )}
                                onCurrentPageChange={(p, newP) => QueryManagementUtils.setPaginationInfo(
                                    {...p, currentPage: newP},
                                    query,
                                    history,
                                    location,
                                )}
                            />
                    }
                </div>
            </div>
        </>
    )
}

export default ProductView;
