import {createTheme} from "@material-ui/core";
import {
    disabledTextColor,
    green,
    hintTextColor,
    lighterSecondaryColor,
    primaryColor,
    primaryColorAlternate,
    primaryTextColor,
    red,
    secondaryColor,
    secondaryTextColor,
} from "./colors";
import {lg, md, sm, xl, xs, xxl} from "./sizes";


const theme = createTheme({
    breakpoints: {
        values: {
            xs: xs,
            sm: sm,
            md: md,
            lg: lg,
            xl: xl,
            xxl: xxl,
        },
    },
    palette: {
        primary: {
            main: secondaryColor,
        },
        secondary: {
            main: primaryColor,
        },
        success: {
            main: green,
        },
        error: {
            main: red,
        },
        text: {
            primary: primaryTextColor,
            secondary: secondaryTextColor,
            disabled: disabledTextColor,
            hint: hintTextColor,
        }
    },
    typography: {
        fontFamily: 'Montserrat',
        fontSize: 13,
    },
    shape: {
        borderRadius: 7,
    },
    overrides: {
        MuiInputBase: {
            root: {
                fontSize: '0.8rem',
                '&.Mui-disabled': {
                    cursor: 'not-allowed',
                },
            },
        },
        MuiInput: {
            input: {
                borderColor: primaryColorAlternate,
                '&:hover': {
                    borderColor: primaryColor,
                }
            },
            multiline: {
                padding: '4.5px 0',
            }
        },
        MuiFormControl: {
            root: {
                '&.Mui-disabled': {
                    cursor: 'not-allowed',
                },
            },
        },
        MuiSelect: {
            root: {
                padding: '16px 16px'
            },
            select: {
                '&.Mui-disabled': {
                    cursor: 'not-allowed',
                    color: disabledTextColor,
                },
                '&:focus': {
                    backgroundColor: 'transparent',
                },
            },
            outlined: {
                paddingRight: '15px',
                paddingLeft: 15,
            }
        },
        MuiPopover: {
            paper: {
                elevation: 4,
            },
            root: {
                elevation: 4,
            }
        },
        MuiMenu: {
            paper: {
                marginTop: 5,
                borderRadius: 3,
            }
        },
        MuiMenuItem: {
            root: {
                transition: 'all 0.2s ease-in-out',
                color: "black",
                margin: '5px 5px',
                borderRadius: 3,
                paddingBottom: 11,
                paddingTop: 11,
                backgroundColor: 'white',
                '&:hover': {
                    backgroundColor: lighterSecondaryColor,
                },
                "&$selected": {
                    transition: 'all 0.2s ease-in-out',
                    color: secondaryColor,
                    backgroundColor: lighterSecondaryColor,
                    '&:hover': {
                        backgroundColor: lighterSecondaryColor,
                    },
                }
            },
        },
        MuiListItem: {
            root: {
                '&$selected': {
                    backgroundColor: `${lighterSecondaryColor} !important`,
                    color: secondaryColor,
                    fontWeight: 600,
                },
                '&:hover': {
                    backgroundColor: `${lighterSecondaryColor} !important`,
                },
            },
        },
        MuiFormHelperText: {
            root: {
                '&$error': {
                    fontWeight: 500,
                    fontSize: '12px !important',
                    margin: '8px 0',
                }
            }
        },
        MuiTooltip: {
            tooltip: {
                borderRadius: 5,
                padding: 10,
                fontWeight: 600,
                fontSize: 12,
            }
        },
    },

});


export default theme;
