import React, {useEffect, useState} from "react";
import Dialog from "../../base/dialog";
import {DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import {useTranslation} from "react-i18next";


const TermsAndConditionsDialog = ({open, preview = false, setOpen: setOpenProp, callback,}) => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [termsAndConditions, setTermsAndConditions] = useState(null);

    /**
     * Listens to the open state of this dialog and with each change:
     * * if open: fetches the terms and conditions of the server to show them
     * * if not open: sets the loading state to false
     */
    useEffect(() => {
        if (!open) {
            setLoading(false);
        }
        if (open) {
            getTermsAndConditions();
        }
    }, [open]);


    /**
     * Fetches the company's terms and conditions from the server.
     */
    const getTermsAndConditions = () => {
        setLoading(true);
        //TODO: call api for getting termsAndConditions
        setTermsAndConditions('<p>Terms and Conditions</p>');
        setLoading(false);
    }


    /**
     * Toggles the open state of this privacy policy dialog
     *
     * * if this function is called as a result of requestClose of muiDialog so the use must not have accepted
     * he privacy
     * @param {boolean} open
     * @param {boolean} accept whether the user has accepted the privacy policy
     */
    const setOpen = (open, accept = false) => {
        setOpenProp(open);
        if (callback) {
            callback(accept);
        }
    }

    /**
     * Stops the default behaviour of click and then closes the dialog based on the provided accept argument.
     * @param {MouseEvent} e
     * @param {boolean} accept
     */
    const onButtonClick = (e, accept = false) => {
        e.preventDefault();
        e.stopPropagation();
        setOpen(false, accept);
    }

    return (
        <>
            <Dialog
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                maxWidth={'md'}
                className={'terms-and-conditions-dialog'}
                fullWidth
                setOpen={setOpen}
                open={open}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle className={'title'} id="scroll-dialog-title">
                    {t('dialogs.termsAndConditionsDialog.title')}
                </DialogTitle>
                <DialogContent dividers>
                    {
                        loading
                            ? (
                                <></>
                            )
                            : (
                                <DialogContentText
                                    id="scroll-dialog-description"
                                    className={'body'}
                                    tabIndex={-1}
                                    dangerouslySetInnerHTML={{__html: termsAndConditions ?? '<p></p>'}}>
                                </DialogContentText>
                            )
                    }
                </DialogContent>
                <DialogActions className={'actions'}>
                    {
                        preview
                            ? <>
                                <button className={'button secondary outlined px-7 py-2'}
                                        onClickCapture={onButtonClick}>
                                    {t('dialogs.termsAndConditionsDialog.actions.close')}
                                </button>
                            </>
                            : <>
                                <button className={'button secondary outlined px-7 py-2'}
                                        type={'button'}
                                        onClickCapture={onButtonClick}>
                                    {t('dialogs.termsAndConditionsDialog.actions.cancel')}
                                </button>
                                <button className={'button secondary outlined px-7 py-2'}
                                        type={'button'}
                                        onClickCapture={(e) => onButtonClick(e, true)}
                                >
                                    {t('dialogs.termsAndConditionsDialog.actions.accept')}
                                </button>
                            </>
                    }
                </DialogActions>
            </Dialog>
        </>
    )
}

export default TermsAndConditionsDialog;
