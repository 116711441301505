import LocalStorageService from "./local_storage_service";
import moment from "moment";
import {matchPath} from "react-router";
import routes from "../../ui/routes";
import {Location} from 'history';
import {ApplicationLanguages, ApplicationSettingKeys} from "../constants/enums";

/**
 * Service that communicates with the local storage of the window
 */
export default class CacheService {
    static keys = LocalStorageService.keys;
    static setLocalStorage = LocalStorageService.set;
    static getLocalStorage = LocalStorageService.get;
    static localStorageRemove = LocalStorageService.remove;
    static localStorageRemoveAll = LocalStorageService.removeAll;

    // ########################################     Auth  ########################################

    /**
     * Sets the login information to the local storage of the window
     * @param {string}  token                   the authentiacation token of the user
     * @param {number}  tokenLifeSpanMinutes    the number of minutes until the token expires
     * @param {string}  firstName               (OPTIONAL) the first name of the user
     * @param {string}  lastName                (OPTIONAL) the last name of the user
     * @param {string}  email                   (OPTIONAL) the email of the user
     * @param {string}  profileImageUrl         (OPTIONAL) the profile file url of the user
     */
    static setLoginInfo(token: string, tokenLifeSpanMinutes: number, firstName?: string, lastName?: string, email?: string, profileImageUrl?: string) {
        this.setLocalStorage(this.keys.token, token);
        this.setLocalStorage(this.keys.tokenExpiration, new Date().setMinutes(new Date().getMinutes() + tokenLifeSpanMinutes).toString());
        const userInfo: any = {};
        if (firstName) userInfo['firstName'] = firstName;
        if (lastName) userInfo['lastName'] = lastName;
        if (email) userInfo['email'] = email;
        if (profileImageUrl) userInfo['profileImageUrl'] = profileImageUrl;
        if (!Object.keys(userInfo)?.length) {
            this.localStorageRemove(this.keys.userInfo);
            return;
        }
        this.setLocalStorage(this.keys.userInfo, JSON.stringify(userInfo));
    }

    /**
     * Fetches the user information from the local storage of the user.
     */
    static getUserInfo(): any {
        const result = LocalStorageService.get(LocalStorageService.keys.userInfo);
        if (!result) {
            return undefined;
        }
        return JSON.parse(result);
    }


    /**
     * Checks to see if the user has a token and their expiry time has not passed
     */
    static isLoggedIn(): boolean {
        return !!this._retrieveTokenAndExpiration();
    }

    /**
     * Retrieves the token and expiry time in minutes form the local storage.
     * @private
     */
    static _retrieveTokenAndExpiration() {
        let token = this.getLocalStorage(this.keys.token);
        let expDateString = this.getLocalStorage(this.keys.tokenExpiration);
        if (isNaN(parseInt(expDateString))) {
            return undefined;
        }
        let expDate = expDateString && moment(parseInt(expDateString));
        let currentDate = moment();
        if (!token || !expDate || currentDate >= expDate) {
            return undefined;
        }
        return {token, tokenExpiration: moment.duration(expDate.diff(currentDate)).asMinutes()};
    };

    /**
     * Logs the user out the system by removing the user from the local storage
     */
    static removeUserInformation() {
        //TODO: remove the local storage products in case of logout
        this.localStorageRemove(this.keys.token);
        this.localStorageRemove(this.keys.tokenExpiration);
        this.localStorageRemove(this.keys.userInfo);
        this.localStorageRemove(this.keys.userCompanies);
        this.localStorageRemove(this.keys.selectedCompanyName);
        window.dispatchEvent(new Event('storage'));
    }

    // ########################################     Core URLs   ########################################

    /**
     * Gets the login and logout, and base url of the core from local storage
     */
    static getCoreUrls(): { loginUrl: string | undefined, logoutUrl: string | undefined, baseUrl: string | undefined } {
        const coreUrls = this.getLocalStorage(this.keys.coreUrls);
        if (!coreUrls) {
            return {
                logoutUrl: undefined,
                baseUrl: undefined,
                loginUrl: undefined,
            }
        }
        return JSON.parse(coreUrls);
    }

    /**
     * Sets the core urls for login / logout / base in local storage of the window
     * @param loginUrl {string}
     * @param logoutUrl {string}
     * @param baseUrl {string}
     * @return {{loginUrl: string | undefined, logoutUrl: string | undefined, baseUrl: string | undefined }} the
     * modified core urls
     */
    static setCoreUrls(loginUrl: string, logoutUrl: string, baseUrl: string): { loginUrl: string | undefined, logoutUrl: string | undefined, baseUrl: string | undefined } {
        const replaceUrl = "https://core.bizkeytech.com";
        const replacement = "https://bizcore.bizkeytech.com";
        if (loginUrl.startsWith(replaceUrl)) {
            loginUrl = loginUrl.replace(replaceUrl, replacement);
        }
        if (logoutUrl.startsWith(replaceUrl)) {
            logoutUrl = logoutUrl.replace(replaceUrl, replacement);
        }
        if (baseUrl.startsWith(replaceUrl)) {
            baseUrl = baseUrl.replace(replaceUrl, replacement);
        }
        const result = {
            loginUrl,
            logoutUrl,
            baseUrl,
        }
        this.setLocalStorage(this.keys.coreUrls, JSON.stringify(result));
        return result;
    }

    // ########################################     URL Caching   ########################################

    /**
     * Caches the location to the local storage only anf only if the route is not of error, or auth routes
     *
     * @param location {string}
     */
    static cacheUrl(location?: Location | null) {
        if (!location) {
            return;
        }
        if (location.pathname.includes(routes.error.base)) {
            return;
        }
        const unCacheableRoutes = [
            routes.error.base,
            routes.panel.auth.login,
            routes.panel.auth.loginWithoutParams,
        ];
        if (matchPath(location.pathname, {path: unCacheableRoutes, exact: false})) {
            return;
        }
        this.setLocalStorage(this.keys.cachedUrl, JSON.stringify(location));
    }

    /**
     * Gets the cached url from the local storage and removes the cached route
     */
    static getCachedUrl(): Location | null {
        const cachedUrl = this.getLocalStorage(this.keys.cachedUrl);
        if (cachedUrl) {
            this.localStorageRemove(this.keys.cachedUrl);
            return JSON.parse(cachedUrl);
        }
        return null;
    }

    // ########################################     Settings   ########################################

    /**
     * Fetches the Settings of the application form the local storage.
     *
     * if settings did not exist, uses the default settings, and if that did not exist, creates and returns the
     * default settings
     */
    static getSettings(): any {
        const settings = this.getLocalStorage(this.keys.settings);
        if (!settings) {
            let defaultSettings = this.getLocalStorage(this.keys.defaultSettings);
            if (!defaultSettings) {
                return CacheService._restoreDefaultSettings();
            }
            return JSON.parse(defaultSettings);
        }
        return JSON.parse(settings);
    }

    /**
     * Sets teh default settings of the application to the local storage and returns the object associated with it.
     * @private
     */
    static _restoreDefaultSettings(): any {
        //TODO: create the default settings here.
        const defaultSettings = {
            [ApplicationSettingKeys.language]: ApplicationLanguages.english, // default language is english.
        };
        this.setLocalStorage(this.keys.defaultSettings, JSON.stringify(defaultSettings));
        this.setLocalStorage(this.keys.settings, JSON.stringify(defaultSettings));
        return defaultSettings;
    }

    /**
     * Sets one of the keys of the settings to the proposed value.
     *
     * @param {string} key the key of the settings to change
     * @param {any} value the value to change the key to.
     */
    static setSettings(key: string, value: any): void {
        const settings = CacheService.getSettings();
        settings[key] = value;
        LocalStorageService.set(LocalStorageService.keys.settings, JSON.stringify(settings));
    }

    // ########################################     User Companies   ########################################

    /**
     * Syncs the user companies with the values saved in the local storage if the list of companies are not empty.
     * Otherwise remove the list of companies.
     *
     * @param companies
     */
    static syncUserCompanies(companies: any[]): void {
        if (!companies?.length) {
            this.setLocalStorage(this.keys.userCompanies, JSON.stringify([]));
            return;
        }
        this.setLocalStorage(this.keys.userCompanies, JSON.stringify(companies));
    }

    /**
     * Fetches the company id of the selected company name.
     *
     * * if no company name is provided, fetches the company od of the current company name.
     * @param companyName
     * @return {number | undefined}
     */
    static getCompanyId(companyName?: string): number | undefined {
        const savedApplications = this.getLocalStorage(this.keys.userCompanies);
        if (!savedApplications) {
            return undefined;
        }
        const parsedApplications = JSON.parse(savedApplications);
        if (!companyName) {
            companyName = this.getLocalStorage(this.keys.selectedCompanyName);
            if (!companyName) {
                return undefined;
            }
        }
        return parsedApplications.find((e: any) => e.name === companyName)?.id;
    }

    /**
     * Fetches the list of applications of the user.
     */
    static getCompaniesOfUser(): any[] {
        const savedApplications = this.getLocalStorage(this.keys.userCompanies);
        if (!savedApplications) {
            return [];
        }
        return JSON.parse(savedApplications) ?? [];
    }
}
