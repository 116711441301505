import React, {useState} from "react";
import {FormInput, Input} from "./index";
import {InputAdornment} from "@material-ui/core";
import {ReactComponent as EyeIcon} from "../../../../assets/images/components/change-password/eye.svg";
import {ReactComponent as EyeOffIcon} from "../../../../assets/images/components/change-password/eye-off.svg";
import classnames from "classnames";

export const PasswordInput = ({type = 'text', ...props}) => {
    const [visible, setVisible] = useState(false)

    /**
     * Toggles the visibility of this input.
     */
    const toggleVisibility = () => {
        setVisible(prevState => !prevState)
    }

    return (
        <>
            <Input
                {...props}
                type={visible
                    ? type ?? 'text'
                    : "password"
                }
                InputProps={{
                    endAdornment: (
                        <InputAdornment position={'end'}>
                            <button className='text'
                                    onClick={toggleVisibility}>
                                {
                                    !visible
                                        ? <EyeIcon/>
                                        : <EyeOffIcon/>
                                }
                            </button>
                        </InputAdornment>
                    )
                }}
            />
        </>
    )
}

export const PasswordFormInput = ({type = 'text', className = '', ...props}) => {
    const [visible, setVisible] = useState(false)

    /**
     * Toggles the visibility of this input.
     */
    const toggleVisibility = () => {
        setVisible(prevState => !prevState)
    }

    return (
        <>
            <FormInput
                {...props}
                type={visible
                    ? type ?? 'text'
                    : "password"
                }
                className={classnames(className, 'password-input', {'visible': visible})}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position={'end'}>
                            <button
                                type={'button'}
                                className='button text'
                                onClick={toggleVisibility}>
                                {
                                    !visible
                                        ? <EyeIcon/>
                                        : <EyeOffIcon/>
                                }
                            </button>
                        </InputAdornment>
                    )
                }}
            />
        </>
    );
}
