import {v4 as UUIDv4} from 'uuid';
import moment from "moment/moment";

/**
 * The utility methods used in this package.
 */
class Utils {

    /**
     * Determines if two objects are equal.
     *
     * @param object1 {any}
     * @param object2 {any}
     * @return {boolean}
     */
    static deepEqual(object1: any, object2: any): boolean {
        // check if the first one is an array
        if (Array.isArray(object1)) {
            if (!Array.isArray(object2) || object1.length !== object2.length) return false;
            for (let i = 0; i < object1.length; i++) {
                if (!this.deepEqual(object1[i], object2[i])) return false;
            }
            return true;
        }
        // check if the first one is an object
        if (typeof object1 === 'object' && object1 !== null && object2 !== null) {
            if (!(typeof object2 === 'object')) return false;
            const keys = Object.keys(object1);
            if (keys.length !== Object.keys(object2).length) return false;
            for (const key in object1) {
                if (!this.deepEqual(object1[key], object2[key])) return false;
            }
            return true;
        }
        // not array and not object, therefore must be primitive
        return object1 === object2;
    }

    /**
     * Deep copy an acyclic *basic* Javascript object.  T
     *
     * * this method only handles basic scalars (strings, numbers, booleans) and arbitrarily deep arrays and objects
     * containing these.
     * * This method does *not* handle instances of other classes.
     * @param obj {any}
     */
    static deepCopy<T = any>(obj: T): T {
        let ret: any, key;
        let marker = '__deepCopy';

        // @ts-ignore
        if (obj && obj[marker])
            throw (new Error('attempted deep copy of cyclic object'));

        if (obj && obj.constructor === Object) {
            ret = {};
            // @ts-ignore
            obj[marker] = true;

            for (key in obj) {
                if (key === marker)
                    continue;

                // @ts-ignore
                ret[key] = this.deepCopy(obj[key]);
            }

            // @ts-ignore
            delete (obj[marker]);
            return (ret);
        }

        // @ts-ignore
        if (obj && obj.constructor === Array) {
            ret = [];
            // @ts-ignore
            obj[marker] = true;

            // @ts-ignore
            for (key = 0; key < obj.length; key++)
                ret.push(this.deepCopy(obj[key]));

            // @ts-ignore
            delete (obj[marker]);
            return (ret);
        }
        // It must be a primitive type -- just return it.
        return (obj);
    }

    /**
     * Creates a Unique Identifier in form of a string
     * @return {string}
     */
    static createUUId(): string {
        const uuid = UUIDv4();
        return `ID-${uuid}`
    }

    /**
     * Compares two numbers
     * @param a {number}
     * @param b {number}
     */
    public static numComparator(a: number, b: number): number {
        if (a === b) return 0;
        if (a < b) return -1;
        return 1
    }

    /**
     * Compares two dates by converting them to moment objects and then comparing them
     * @param a {Date}
     * @param b {Date}
     * @param format {moment.MomentFormatSpecification}
     */
    public static dateComparator(a: Date, b: Date, format?: moment.MomentFormatSpecification): number {
        const _momentComparator = (a: moment.Moment, b: moment.Moment) => {
            if (a.isSame(b, 'ms')) return 0;
            if (a.isAfter(b, 'ms')) return 1;
            return -1;
        }
        return _momentComparator(moment(a, format), moment(b, format));
    }

    /**
     * Compares two strings.
     * @param a {string}
     * @param b {string}
     */
    public static stringComparator(a: string, b: string): number {
        return a?.localeCompare(b);
    }

    /**
     * Compares two Booleans
     * @param a {boolean}
     * @param b {boolean}
     */
    public static booleanComparator(a: boolean, b: boolean): number {
        if (a === b) return 0;
        if (a < b) return -1;
        return 1;
    }

}

export default Utils;
