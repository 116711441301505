import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import FeatureCard from "../../../../../../components/app-specific/features-card";
import {landingViewScrollAnimationOptions} from "../../index";
import {useTranslation} from "react-i18next";
import {ReactComponent as ProductLocation} from "../../../../../../../assets/images/views/public-views/landing/product-location.svg";
import {ReactComponent as PurchaseReceiving} from "../../../../../../../assets/images/views/public-views/landing/purchase-receiving.svg";
import {ReactComponent as SalesOrder} from "../../../../../../../assets/images/views/public-views/landing/sales-order.svg";
import {ReactComponent as CountingProducts} from "../../../../../../../assets/images/views/public-views/landing/counting-products.svg";


const LandingViewFeaturesSection = ({sections}) => {
    const {t} = useTranslation();

    const features = [
        {
            ...t('views.public.landing.features.data.0', {returnObjects: true}),
            icon: <ProductLocation/>,
            animationType: landingViewScrollAnimationOptions.fadeRightAnimation,
        },
        {
            ...t('views.public.landing.features.data.1', {returnObjects: true}),
            icon: <PurchaseReceiving/>,
            animationType: landingViewScrollAnimationOptions.fadeLeftAnimation,
        },
        {
            ...t('views.public.landing.features.data.2', {returnObjects: true}),
            icon: <SalesOrder/>,
            animationType: landingViewScrollAnimationOptions.fadeUpAnimation,
        },
        {
            ...t('views.public.landing.features.data.3', {returnObjects: true}),
            icon: <CountingProducts/>,
            animationType: landingViewScrollAnimationOptions.fadeUpAnimation,
        },
    ];

    return (
        <>
            <Container className='features-container' data-aos={landingViewScrollAnimationOptions.fadeUpAnimation}>
                <div id={sections.features.hash}>
                    <h3 className='title'>
                        {t('views.public.landing.features.title.black')}
                        <span className='primary-color mx-2'>
                            {t('views.public.landing.features.title.blue')}
                            </span>
                    </h3>
                    <Row className={'mt-5'}>
                        {
                            features?.map((feature, index) => (
                                <Col xs={12} md={6} key={index}>
                                    <FeatureCard
                                        feature={feature}
                                        index={index}
                                        delay={landingViewScrollAnimationOptions.featuresBaseDelay * index}
                                    >
                                    </FeatureCard>
                                </Col>
                            ))
                        }
                    </Row>
                </div>
            </Container>
        </>
    );
}

export default LandingViewFeaturesSection;
