import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {DefaultInitialPaginationInfo, PositionItemScannedStatuses, TableCellAlignments, TableCellTypes} from "../../../../../../../../core/constants/enums";
import classnames from "classnames";
import {ReactComponent as CheckedIcon} from "../../../../../../../../assets/images/scanned-item-status-action.svg";
import {ReactComponent as RemoveIcon} from "../../../../../../../../assets/images/delete-garbage-icon.svg";
import {ReactComponent as NotApplicableInSpire} from "../../../../../../../../assets/images/not-applicable-in-spire.svg";
import {ReactComponent as ChevronIcon} from "../../../../../../../../assets/images/chevron-icon.svg";
import {Collapse} from "react-bootstrap";
import Table from "../../../../../../../containers/table";
import TableSpacer from "../../../../../../../containers/table/spacer";
import {ReactComponent as EditIcon} from "../../../../../../../../assets/images/add-note-icon.svg";
import NoEntries from "../../../../../../../components/app-specific/no-entries";
import useRouter from "../../../../../../../hooks/use-router";
import SingleSpireCountingSearchSection from "../../../../../../../components/search-sections/countings/spire-counting/single";
import {useDispatch, useSelector} from "react-redux";
import {dialogActions} from "../../../../../../../../redux/entities/dialog-slice";
import ReviewAndAdjustDialog from "../../../../../../../components/dialogs/counting/position/review-and-adjust-dialog";
import AddOrEditNoteDialog from "../../../../../../../components/dialogs/counting/add-edit-note-dialog";
import QueryManagementUtils from "../../../../../../../../core/services/query-management-utils";
import useSearchData from "../../../../../../../hooks/use-search-data";
import {PositionCountingItemsQueryParams} from "../../../../../../../../core/constants/query-params";
import useIsMounted from "../../../../../../../hooks/use-is-mounted";
import {SpireCountingTabValues} from "../../../../../../../../core/constants/tab-values";

// Main table cell keys
const mainTableCellKeys = {
    addNote: 'addNote',
    partNumber: 'partNumber',
    freezeCount: 'freezeCount',
    scannedCount: 'scannedCount',
    locationCode: 'locationCode',
    updatedDatetime: 'updatedDateTime',
    checkAndAdjust: 'checkAndAdjust',
    expand: 'expand',

}

// Inner table cell keys
const innerTableCellKeys = {
    note: 'note',
    serialNumber: 'serialNumber',
    operator: 'operator',
    scannedDateTime: 'scannedDateTime',
    locationCode: 'locationCode',
    status: 'status',
    statusAction: 'statusAction'
}

const initialPaginationInfo = {
    ...DefaultInitialPaginationInfo,
    pageSize: 10,
}

const PositionCountingItems = ({statuses}) => {
    const {query, location, history, stringifyUrl} = useRouter();
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isShowingReviewAndAdjust, setIsShowingReviewAndAdjust] = useState(false);
    const [isAddingOrEditingNote, setIsAddingOrEditingNote] = useState(false);
    const [itemUnderOperation, setItemUnderOperation] = useState({});
    const {t} = useTranslation();
    const translations = t("views.panel.company.counting.position.single", {returnObjects: true});
    const [expandedSectionsIds, setExpandedSectionsIds] = useState([]);
    const errorMessage = t('views.panel.errorMessages', {returnObjects: true});
    const isMounted = useIsMounted();

    // Redux
    const dialogState = useSelector(state => state?.dialog ?? {});
    const dispatch = useDispatch();

    const {type} = query;

    /**
     * With each change in the type of selected tab:
     * - if the items tab is selected, searches again for the items
     */
    useEffect(() => {
        if (type === SpireCountingTabValues.items)
            search().then();
    }, [type])


    /**
     * Memo version of available statuses
     * @type {object[]}
     *
     */
        //TODO: use when api is ready for filtering the selected status
    const availableStatuses = useMemo(() => statuses, [statuses])

    /**
     * Searches for the items in a position counting and updates the pagination information
     * @return {Promise<void>}
     */
    const search = async () => {
        const forApi = prepareRequestDS();
        const response = {
            resultFlag: true,
            data: {
                list: [
                    {
                        id: 1,
                        partNumber: "RADFR101A",
                        freezeCount: 12,
                        scannedCount: 10,
                        locationCode: "A120",
                        updatedDatetime: '2021-12-02',
                        needsCheckAndAdjust: false,
                        expanded: false,
                        note: "Testing",
                        innerItems: [
                            {
                                id: 11,
                                serialNumber: '13123-123123-123',
                                operator: "Testian",
                                scannedDataTime: new Date(),
                                locationCode: "R20",
                                note: "Testing",
                                status: {
                                    id: 1,
                                    name: "Scanned"
                                }
                            },
                            {
                                id: 12,
                                serialNumber: '13123-123123-123',
                                operator: "Testian",
                                scannedDataTime: new Date(),
                                locationCode: "R20",
                                status: {
                                    id: 2,
                                    name: "Not Scanned"
                                }
                            },
                            {
                                id: 13,
                                serialNumber: '13123-123123-123',
                                operator: "Testian",
                                scannedDataTime: new Date(),
                                locationCode: "R20",
                                status: {
                                    id: 3,
                                    name: "N/A in SPIRE"
                                }
                            },
                            {
                                id: 14,
                                serialNumber: '13123-123123-123',
                                operator: "Testian",
                                scannedDataTime: new Date(),
                                locationCode: "R20",
                                note: "Testing",
                                status: {
                                    id: 4,
                                    name: "Incorrect Location"
                                }
                            },
                            {
                                id: 15,
                                serialNumber: '13123-123123-123',
                                operator: "Testian",
                                scannedDataTime: new Date(),
                                locationCode: "R20",
                                status: {
                                    id: 5,
                                    name: "Not Assigned"
                                }
                            },
                        ],
                    },
                    {
                        id: 2,
                        partNumber: "RADFR101A",
                        freezeCount: 12,
                        scannedCount: 10,
                        locationCode: "A120",
                        updatedDatetime: new Date(),
                        needsCheckAndAdjust: true,
                        expanded: false,
                        innerItems: [
                            {
                                id: 21,
                                serialNumber: '13123-123123-123',
                                operator: "Testian",
                                scannedDataTime: new Date(),
                                locationCode: "R20",
                                note: "Testing",
                                status: {
                                    id: 1,
                                    name: "Scanned"
                                }
                            },
                            {
                                id: 22,
                                serialNumber: '13123-123123-123',
                                operator: "Testian",
                                scannedDataTime: new Date(),
                                locationCode: "R20",
                                status: {
                                    id: 2,
                                    name: "Not Scanned"
                                }
                            },
                            {
                                id: 23,
                                serialNumber: '13123-123123-123',
                                operator: "Testian",
                                scannedDataTime: new Date(),
                                locationCode: "R20",
                                note: "Testing",
                                status: {
                                    id: 3,
                                    name: "N/A in SPIRE"
                                }
                            },
                            {
                                id: 23,
                                serialNumber: '13123-123123-123',
                                operator: "Testian",
                                scannedDataTime: new Date(),
                                locationCode: "R20",
                                status: {
                                    id: 4,
                                    name: "Incorrect Location"
                                }
                            },
                            {
                                id: 25,
                                serialNumber: '13123-123123-123',
                                operator: "Testian",
                                scannedDataTime: new Date(),
                                locationCode: "R20",
                                status: {
                                    id: 5,
                                    name: "Not Assigned"
                                }
                            },
                        ],
                    },
                    {
                        id: 3,
                        partNumber: "RADFR101A",
                        freezeCount: 12,
                        scannedCount: 10,
                        locationCode: "A120",
                        updatedDatetime: new Date(),
                        needsCheckAndAdjust: false,
                        expanded: false,
                        note: "Testing",
                        innerItems: [
                            {
                                id: 31,
                                serialNumber: '13123-123123-123',
                                operator: "Testian",
                                scannedDataTime: new Date(),
                                locationCode: "R20",
                                note: "Testing",
                                status: {
                                    id: 1,
                                    name: "Scanned"
                                }
                            },
                            {
                                id: 32,
                                serialNumber: '13123-123123-123',
                                operator: "Testian",
                                scannedDataTime: new Date(),
                                locationCode: "R20",
                                status: {
                                    id: 2,
                                    name: "Not Scanned"
                                }
                            },
                            {
                                id: 33,
                                serialNumber: '13123-123123-123',
                                operator: "Testian",
                                scannedDataTime: new Date(),
                                locationCode: "R20",
                                status: {
                                    id: 3,
                                    name: "N/A in SPIRE"
                                }
                            },
                            {
                                id: 34,
                                serialNumber: '13123-123123-123',
                                operator: "Testian",
                                scannedDataTime: new Date(),
                                locationCode: "R20",
                                note: "Testing",
                                status: {
                                    id: 4,
                                    name: "Incorrect Location"
                                }
                            },
                            {
                                id: 35,
                                serialNumber: '13123-123123-123',
                                operator: "Testian",
                                scannedDataTime: new Date(),
                                locationCode: "R20",
                                status: {
                                    id: 5,
                                    name: "Not Assigned"
                                }
                            },
                        ],
                    },
                    {
                        id: 4,
                        partNumber: "RADFR101A",
                        freezeCount: 12,
                        scannedCount: 10,
                        locationCode: "A120",
                        updatedDatetime: new Date(),
                        needsCheckAndAdjust: true,
                        expanded: false,
                        innerItems: [
                            {
                                id: 41,
                                serialNumber: '13123-123123-123',
                                operator: "Testian",
                                scannedDataTime: new Date(),
                                locationCode: "R20",
                                note: "Testing",
                                status: {
                                    id: 1,
                                    name: "Scanned"
                                }
                            },
                            {
                                id: 42,
                                serialNumber: '13123-123123-123',
                                operator: "Testian",
                                scannedDataTime: new Date(),
                                locationCode: "R20",
                                status: {
                                    id: 2,
                                    name: "Not Scanned"
                                }
                            },
                            {
                                id: 43,
                                serialNumber: '13123-123123-123',
                                operator: "Testian",
                                scannedDataTime: new Date(),
                                locationCode: "R20",
                                status: {
                                    id: 3,
                                    name: "N/A in SPIRE"
                                }
                            },
                            {
                                id: 44,
                                serialNumber: '13123-123123-123',
                                operator: "Testian",
                                scannedDataTime: new Date(),
                                locationCode: "R20",
                                note: "Testing",
                                status: {
                                    id: 4,
                                    name: "Incorrect Location"
                                }
                            },
                            {
                                id: 45,
                                serialNumber: '13123-123123-123',
                                operator: "Testian",
                                scannedDataTime: new Date(),
                                locationCode: "R20",
                                status: {
                                    id: 5,
                                    name: "Not Assigned"
                                }
                            },
                        ],
                    },
                ],
                paginationInfo: {
                    length: 4,
                }
            },
        };
        if (!isMounted())
            return;
        if (response?.resultFlag) {
            setItems(response.data?.list.map(e => ({...e, loading: false})))
            const newPaginationInfo = {
                ...paginationInfo,
                length: response?.data.paginationInfo.length ?? 0
            }
            QueryManagementUtils.setPaginationInfo(newPaginationInfo, query, history, location);
        }
        setLoading(false);
    }

    const {filters, paginationInfo, orderBy} = useSearchData(
        PositionCountingItemsQueryParams,
        search,
        {
            useFilters: true,
            initialFilters: {},
            filtersQueryParamMap: {
                keyword: PositionCountingItemsQueryParams.keyword,
                status: PositionCountingItemsQueryParams.status,
                filter: PositionCountingItemsQueryParams.filter,
            },

            usePaginationInfo: true,
            initialPaginationInfo: initialPaginationInfo,

            useOrderBy: true,
            initialOrderBy: undefined
        }
    );

    /**
     * Clears the filters of this view to invoke a search without any filers.
     */
    const clearFilters = () => {
        history.replace(stringifyUrl({
            url: location.pathname,
            query: {}
        }));
    }

    /**
     * Prepares api call request body
     * @return {{orderBy, paginationInfo, filters}}
     */
    const prepareRequestDS = () => {
        //TODO: add the statuses filteration
        return {
            paginationInfo: paginationInfo,
            filters: filters,
            orderBy: orderBy,
        }
    }

    /**
     * Handles navigating user to location view
     * @param item
     */
    const onLocationCodeClicked = (item) => {

    }

    /**
     * Handles adding note to position counting item:
     * - Sets the [itemUnderOperation] to the desired value
     * - Opens a dialog regarding this action
     * @param {Object} item item in the position counting or instance of it
     */
    const onAddNoteClicked = (item) => {
        setItemUnderOperation(item);
        setIsAddingOrEditingNote(true);
    }

    /**
     * Handles adding or editing a note for an item on instance of it:
     * - Calls the api regarding this action
     * - Closes the dialog
     *
     */
    const onSubmittingNote = (note) => {
        //for sake of testing the process, checks if the item under operation is the position counting item or its instances
        if (itemUnderOperation?.partNumber) {
            setItems(prevState => prevState.map(e => {
                if (e.id === itemUnderOperation.id) return {...e, ['note']: note?.note};
                return e;
            }));
        }
        //TODO: Call the api for adding note to the part
        console.log(note.note);
        setIsAddingOrEditingNote(false);
    }

    /**
     * Handles checking and adjusting the item in the position counting
     * @param item
     */
    const onCheckAndAdjustItem = (item) => {

    }

    /**
     * Handles expanding the table row for showing inner table
     * @param {MouseEvent<HTMLButtonElement>} event
     * @param {*} selectedItem
     */
    const onExpandClicked = (event, selectedItem) => {
        if (!selectedItem.expanded)
            setExpandedSectionsIds(prevState => [...prevState, selectedItem.id]);
        setItems(
            prevState => prevState.map(item => {
                if (item.id === selectedItem?.id) {
                    return {
                        ...item,
                        expanded: !selectedItem.expanded
                    }
                }
                return item;
            })
        );
    }

    /**
     * Handles removing an item from counting req products list:
     * - Shows a confirmation dialog regarding this action
     * - After the action was confirmed, calls the api regarding this action
     * - Removes the item from the [products] in the state
     * @param {Object} item
     */
    const onCountingItemRemovePressed = (item) => {
        dispatch(dialogActions.configConfirmationDialog({
            open: true,
            onConfirm: () => _onRemoveConfirmed(item),
            onClose: () => _onDialogClosed(),
            title: translations.confirmationDialogTitle ?? '',
            description: translations?.confirmationDialogDesc ?? '',
        }));
    }

    /**
     * Call back function being passed to the confirmation dialog:
     *
     * - Closes the dialog
     * - Calls the api regarding this action
     * - Updates the list of products
     *
     * @param item
     * @private
     */
    const _onRemoveConfirmed = (item) => {
        //TODO: Calls the api regarding this action
        dispatch(dialogActions.confirmationDialogToggle(false));
    }

    /**
     * Call back function being passed to the confirmation dialog:
     *
     * - Closes the dialog
     * @private
     */
    const _onDialogClosed = () => {
        dispatch(dialogActions.confirmationDialogToggle(false));
    }

    /**
     * Handles Reviewing and adjusting the status of a part instance in the products of spire counting:
     *
     * - Shows a dialog for moving item to current location
     * - Sets the [itemUnderOperation] to the received item in the params
     *
     * @param {Object} item
     */
    const onReviewAndAdjust = (item) => {
        setItemUnderOperation(item);
        setIsShowingReviewAndAdjust(true);
    }

    /**
     * Calls the api regarding moving the part to the current location
     * uses the [itemUnderOperation] in the state
     */
    const onReviewAndAdjustMove = () => {
        setIsShowingReviewAndAdjust(false);
    }

    /**
     * Handles moving the part instance in the counting request position changing:
     * - Shows a dialog for ...
     * @param item
     */
    const onMoveToCurrentLocation = (item) => {
        //TODO: Calls the api regarding this action
    }

    /**
     * @param {number} id the id of the item in a table row
     * sets the [isShowingExpandedSection] to false in the state
     */
    const hideExpandedSection = (id) => {
        setExpandedSectionsIds(prevState => prevState.filter(e => e !== id));
    }

    /**
     * updates the item status in spire by calling the related api
     * @param {object} item the serial number of the part in position counting
     * @param {object} countingReq the counting request that includes the item
     */
    const updateSpireStatus = (item, countingReq) => {
        setItems(prevState => prevState.map(e => e.id === countingReq.id ? {...e, loading: true} : e));
        // TODO: implement the api call for updating the the status for the api
        setTimeout(() => {
            setItems(prevState => prevState.map(e => e.id === countingReq.id ? {...e, loading: false} : e));
        }, 2000)
    }

    /**
     * Renders the inner table products status of the table rows.
     * @param {any} item the item instances of a part in spire counting.
     * @param {object} countingReq the loading of items in the counting
     *
     * @return {JSX.Element}
     */
    const renderInnerTableItemsStatus = (item, countingReq) => {
        return (
            <div className={'status-container'}>
                {
                    item.status.id === PositionItemScannedStatuses.naInSpire ?
                        <button className={'not-styled-button'} onClick={() => updateSpireStatus(item, countingReq)}>
                            <NotApplicableInSpire className={'not-applicable-in-spire'}/>
                        </button> :
                        <div className={classnames('status', {
                            'scanned': item.status?.id === PositionItemScannedStatuses.scanned,
                            'not-scanned': item.status?.id === PositionItemScannedStatuses.notScanned,
                            'incorrect': item.status?.id === PositionItemScannedStatuses.incorrect,
                            'not-assigned': item.status?.id === PositionItemScannedStatuses.notAssigned
                        })}/>
                }

                <p className={'text'}>
                    {item?.status?.name ?? ''}
                </p>
            </div>
        );
    }

    /**
     * Renders the status actions used in the inner table of position counting item:
     * - Based on the status id of the products returns different components
     *
     * @param {any} item the item instances of a part in spire counting
     * @return {JSX.Element}
     */
    const renderInnerTableItemStatusAction = (item) => {
        switch (item.status.id) {
            // Scanned
            case 1:
                return (
                    <div>
                        <CheckedIcon className={'cursor-default-hover'}/>
                    </div>
                );
            // Not Scanned
            case 2:
                return (
                    <div/>
                );
            // N/A in spire
            case 3:
                return (
                    <div
                        onClick={(e) => onCountingItemRemovePressed(item)}
                    >
                        <RemoveIcon className={'remove-icon'}/>
                    </div>
                );
            // Incorrect Location
            case 4:
                return (
                    <p
                        onClick={(e) => onReviewAndAdjust(item)}
                        className={'text-button'}
                    >
                        {translations?.itemsTab?.innerTable?.statusOptions?.reviewAndAdjust ?? ''}
                    </p>
                );
            // Not Assigned
            case 5:
                return (
                    <p
                        onClick={(e) => onMoveToCurrentLocation(item)}
                        className={'text-button'}
                    >
                        {translations?.itemsTab?.innerTable?.statusOptions?.moveToCurrentLocation ?? ''}
                    </p>
                );
        }
    }

    /**
     * Memo version of inner table columns
     * @type {object[]}
     */
    const innerTableColumns = useMemo(() => [
        {
            alignment: TableCellAlignments.center,
            type: TableCellTypes.element,
            name: innerTableCellKeys.note,
            size: 0
        },
        {
            title: translations?.itemsTab?.innerTable?.serialNumber ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: innerTableCellKeys.serialNumber,
        },
        {
            title: translations?.itemsTab?.innerTable?.operator ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: innerTableCellKeys.operator,
        },
        {
            title: translations?.itemsTab?.innerTable?.scannedDateTime ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.dateTime,
            name: innerTableCellKeys.scannedDateTime,
        },
        {
            title: translations?.itemsTab?.innerTable?.locationCode ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: innerTableCellKeys.locationCode,
        },
        {
            title: translations?.itemsTab?.innerTable?.status ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: innerTableCellKeys.status,
            size: 1.5
        },
        {
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: innerTableCellKeys.statusAction,
            size: 2
        },
    ], [translations]);

    /**
     * Creates the rows of the inner table
     * @param {any[]} data
     * @return {TableData[]}
     */
    const innerTableRows = (data) => {
        return data?.innerItems?.map(item => ({
            key: item.id,
            [innerTableCellKeys.note]: () => (
                <div onClick={(e) => onAddNoteClicked(item)} className={'add-note'}>
                    <EditIcon/>
                </div>
            ),
            [innerTableCellKeys.serialNumber]: item?.serialNumber,
            [innerTableCellKeys.operator]: item?.operator,
            [innerTableCellKeys.scannedDateTime]: item?.scannedDataTime,
            [innerTableCellKeys.locationCode]: () => (
                <p
                    className={'text-button'}
                    onClick={(e) => onLocationCodeClicked(item)}
                >
                    {item?.locationCode}
                </p>
            ),
            [innerTableCellKeys.status]: () => renderInnerTableItemsStatus(item, data),
            [innerTableCellKeys.statusAction]: () => renderInnerTableItemStatusAction(item)
        }));
    }

    /**
     * Memo version of table columns
     * @type {object[]}
     */
    const tableColumns = useMemo(() => [
        {
            alignment: TableCellAlignments.center,
            type: TableCellTypes.element,
            name: mainTableCellKeys.expand,
            size: 0,
        },
        {
            alignment: TableCellAlignments.center,
            type: TableCellTypes.element,
            name: mainTableCellKeys.addNote,
            size: 0
        },
        {
            title: translations?.itemsTab?.mainTable?.partNumber ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: mainTableCellKeys.partNumber,
            sortable: true,
        },
        {
            title: translations?.itemsTab?.mainTable?.freezeCount ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.string,
            name: mainTableCellKeys.freezeCount,
            sortable: true,
        },
        {
            title: translations?.itemsTab?.mainTable?.scannedCount ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.string,
            name: mainTableCellKeys.scannedCount,
            sortable: true,
            size: 1.2
        },
        {
            title: translations?.itemsTab?.mainTable?.locationCode ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: mainTableCellKeys.locationCode,
        },
        {
            title: translations?.itemsTab?.mainTable?.updateDateTime ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.dateTime,
            name: mainTableCellKeys.updatedDatetime,
            sortable: true,
            size: 1.5
        },
        {
            alignment: TableCellAlignments.center,
            type: TableCellTypes.element,
            name: mainTableCellKeys.checkAndAdjust,
            size: 1.1
        },

    ], [translations]);

    /**
     * Memo version of table rows
     * @type {object[]}
     */
    const tableRows = useMemo(() => {
        return items?.map((item) => ({
            key: item.id,
            [mainTableCellKeys.addNote]: () => (
                <div onClick={(e) => onAddNoteClicked(item)} className={'add-note'}>
                    <EditIcon/>
                </div>
            ),
            [mainTableCellKeys.partNumber]: item?.partNumber,
            [mainTableCellKeys.freezeCount]: item?.freezeCount,
            [mainTableCellKeys.scannedCount]: item?.scannedCount,
            [mainTableCellKeys.locationCode]: () => (
                <p
                    className={'text-button'}
                    onClick={(e) => onLocationCodeClicked(item)}
                >
                    {item?.locationCode ?? ''
                    }</p>
            ),
            [mainTableCellKeys.updatedDatetime]: item?.updatedDatetime,
            [mainTableCellKeys.checkAndAdjust]: item?.needsCheckAndAdjust
                ? () => {
                }
                : () => (
                    <p
                        onClick={(e) => onCheckAndAdjustItem(item)}
                        className={'text-button'}
                    >
                        {translations?.itemsTab?.mainTable?.checkAndAdjust ?? ''}
                    </p>
                ),
            [mainTableCellKeys.expand]: () =>
                (
                    <div
                        className={classnames('expand-chevron', {'expanded': item?.expanded})}
                        onClick={(e) => onExpandClicked(e, item)}
                    >
                        <ChevronIcon/>
                    </div>

                ),
            expandedSection: () =>
                (
                    //Make sure collapse is imported from react bootstrap
                    <>
                        <tr className={classnames({'visually-hidden': !expandedSectionsIds.includes(item?.id)})}>
                            <td colSpan={(expandedSectionsIds.length > 0) ? 9 : 0}>
                                <Collapse in={item?.expanded} onExited={() => hideExpandedSection(item?.id)}>
                                    <div className={'inner-item-container'}>
                                        {
                                            item?.innerItems?.length >= 1
                                                ? <Table
                                                    loading={{count: 4, state: item.loading}}
                                                    className={'my-3 w-auto'}
                                                    color={'secondary'}
                                                    cells={innerTableColumns}
                                                    data={innerTableRows(item)}
                                                />
                                                : <NoEntries
                                                    text={errorMessage}
                                                    includeButton={false}
                                                />
                                        }
                                    </div>
                                </Collapse>
                            </td>
                        </tr>
                        {
                            item?.expanded &&
                            <TableSpacer length={tableColumns.length}/>
                        }
                    </>
                ),
        }))
    }, [items, expandedSectionsIds])

    return (
        <>
            <div className={'position-counting-single-item'}>
                <div className={'panel-card p-0 pt-4'}>
                    <SingleSpireCountingSearchSection filters={filters}/>
                    <div>
                        {
                            !!items?.length < 1 && !loading
                                ? (
                                    <NoEntries
                                        text={
                                            Object.entries(filters ?? {})?.length > 0
                                                ? translations?.empty?.withFilters
                                                : translations?.empty?.text
                                        }
                                        includeButton={Object.entries(filters ?? {})?.length > 0}
                                        buttonProps={{
                                            onClick: clearFilters,
                                            className: 'button primary px-3'
                                        }}/>
                                )
                                : (
                                    <Table
                                        loading={{state: loading, count: 8}}
                                        className={'my-3'}
                                        color={'primary'}
                                        cells={tableColumns}
                                        data={tableRows}
                                        paginationInfo={paginationInfo}
                                        orderBy={orderBy}
                                        onSort={(orderBy) => QueryManagementUtils.setOrderBy(
                                            orderBy,
                                            query,
                                            history,
                                            location,
                                        )}
                                        onPageSizeChange={(p, i) => QueryManagementUtils.setPaginationInfo(
                                            {...p, pageSize: i},
                                            query,
                                            history,
                                            location
                                        )}
                                        onCurrentPageChange={(p, newP) => QueryManagementUtils.setPaginationInfo(
                                            {...p, currentPage: newP},
                                            query,
                                            history,
                                            location,
                                        )}
                                    />
                                )
                        }
                    </div>
                </div>
            </div>
            {/*TODO: Change the move to location to current location*/}
            <ReviewAndAdjustDialog
                setOpen={setIsShowingReviewAndAdjust}
                open={isShowingReviewAndAdjust}
                currentLocationCode={itemUnderOperation?.locationCode ?? ''}
                onMove={onReviewAndAdjustMove}
                moveToLocationCode={'A11R120'}
            />
            <AddOrEditNoteDialog
                setOpen={setIsAddingOrEditingNote}
                open={isAddingOrEditingNote}
                onSubmit={onSubmittingNote}
                initData={itemUnderOperation}
            />
        </>
    );
}

export default PositionCountingItems;
