import React from "react";
import {FormInput} from "./index";
import classnames from "classnames";
import {InputAdornment} from "@material-ui/core";
import {ReactComponent as SearchIcon} from "../../../../assets/images/search-input-icon.svg";

export const SearchFormInput = ({
                                    buttonProps,
                                    placeholder = 'Search',
                                    className = '',
                                    ...props
                                }) => {

    return (
        <>
            <FormInput
                {...props}
                placeholder={placeholder}
                className={classnames(className, 'search-input')}
                InputProps={{
                    startAdornment: (
                        <InputAdornment className={'start-adornment'} position={'end'}>
                            <SearchIcon/>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position={'end'}>
                            <button{...buttonProps}>
                                {buttonProps.children ?? ''}
                            </button>
                        </InputAdornment>
                    )
                }}
            />
        </>
    );
}


