import {createContext} from "react";
import {registrationStepperStepIds} from "../../../core/constants/ids";
import {RegistrationStepperSteps} from "../../../core/constants/enums";

/**
 * @typedef {[
 *  {[p: string]: string
 *      | {firstName: *, lastName: *, terms: boolean, email: *, submitted: boolean, valid: boolean}
 *      | {address: {country: *, address: *, province: *, postalCode: *}, phone: *, name: *, type: *, submitted: boolean, valid: boolean}
 *      | {id: number,submitted: boolean, valid: boolean}
 *      | {holderName: *, expiryMonth: *, expiryYear: *, cardNumber: *,submitted: boolean, valid: boolean}, activeStep: number
 *  }
 * ]} RegistrationState
 */

/**
 * The initial Values used for the registration state.
 * @type {[RegistrationState & dispatchAction]}
 */
export const RegistrationContextInitialValues = [
    {
        activeStep: RegistrationStepperSteps.userInfo,
        [registrationStepperStepIds.userInfo]: {
            firstName: undefined,
            lastName: undefined,
            email: undefined,
            terms: false,
            submitted: false,
            valid: true,
        },
        [registrationStepperStepIds.businessInfo]: {
            type: undefined,
            name: undefined,
            phone: undefined,
            address: {
                country: undefined,
                province: undefined,
                address: undefined,
                postalCode: undefined,
            },
            submitted: false,
            valid: true,
        },
        [registrationStepperStepIds.planSelection]: {
            id: -1,
            submitted: false,
            valid: true,
        },
        [registrationStepperStepIds.paymentInfo]: {
            holderName: undefined,
            cardNumber: undefined,
            expiryMonth: undefined,
            expiryYear: undefined,
            submitted: false,
            valid: true,
        },
    },
    ({type, payload}) => {
    }
]

/**
 * Context used to store and inject the registration reducer and its state to the registration sections.
 */
export const RegistrationContext = createContext(RegistrationContextInitialValues);
