import React, {useContext, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import useRouter from "../../../../../../hooks/use-router";
import {useTranslation} from "react-i18next";
import {ReactComponent as BackIconButton} from "../../../../../../../assets/images/back-icon.svg";
import TabsLayout from "../../../../../../components/app-specific/tabs-layout";
import {SpireCountingSingleQueryNames} from "../../../../../../../core/constants/query-names";
import {SpireCountingTabValues} from "../../../../../../../core/constants/tab-values";
import SpireCountingInformation from "./information";
import SpireCountingItems from "./items";
import SpireCountingParticipants from "./participant";
import SpireCountingReports from "./reports";
import {routeFunctions} from "../../../../../../routes";
import {useDispatch, useSelector} from "react-redux";
import {spireCountingSelectionActions} from "../../../../../../../redux/entities/spire-recount-selection-slice";
import {Fade} from "react-bootstrap";
import {CompanyContext} from "../../../../../../contexts/company";

const SpireCountingSingleView = () => {
    const {query, stringifyUrl, history, location, params} = useRouter();
    const [countingReqFullDetails, setCountingReqFullDetails] = useState(null);
    const [isShowingRecount, setIsShowingRecount] = useState(false);
    const [loading, setLoading] = useState(true);
    const {t} = useTranslation();
    const recountState = useSelector(state => state?.spireRecountSelection ?? {});
    const dispatch = useDispatch();
    const {type} = query;
    const translation = t('views.panel.company.counting.spire.single', {returnObjects: true});

    const [company] = useContext(CompanyContext);
    const selectedCompany = useRef(company);
    /**
     * With each change in the selected company:
     * - navigates user to the list of spire countings
     */
    useEffect(() => {
        if (selectedCompany.current?.id === company?.id)
            return;
        selectedCompany.current = company;
        history.replace(routeFunctions.panel.company.counting.spire(company.name));
    }, [company?.id])

    /**
     * Memo version of tabs of single spire counting
     * @type {object[]}
     */
    const tabs = useMemo(() => [
        {
            label: translation?.tabsTitles?.information,
            value: SpireCountingTabValues.information,
        },
        {
            label: translation?.tabsTitles?.items,
            value: SpireCountingTabValues.items,
        },
        {
            label: translation?.tabsTitles?.participant,
            value: SpireCountingTabValues.participant,
        },
        {
            label: translation?.tabsTitles?.reports,
            value: SpireCountingTabValues.reports,
        }
    ], [])

    //TODO: remove after api call was added
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    /**
     * With each change in the url params:
     * - Calls the api for getting the full information of the counting req
     */
    useLayoutEffect(() => {
        delay(2000).then();
        //TODO: Calls the api for getting the counting request full information
        setCountingReqFullDetails({
            id: 1,
            title: "Count Shelf",
            submittedBy: "Testing Testian",
            submitDate: new Date(),
            expiryDate: new Date(),
            totalCount: 100,
            scannedCount: 56,
            participants: [
                {
                    id: 1,
                    name: 'Testing Testian',
                    joinDate: new Date(),
                    lastSave: new Date()
                },
                {
                    id: 2,
                    name: 'Testing Testian',
                    joinDate: new Date(),
                    lastSave: new Date(),
                },
                {
                    id: 3,
                    name: 'Testing Testian',
                    joinDate: new Date(),
                    lastSave: new Date(),
                },
                {
                    id: 4,
                    name: 'Testing Testian',
                    joinDate: new Date(),
                    lastSave: new Date(),
                },
            ],
            items: [
                {
                    id: 1,
                    partNo: "RADFR101A",
                    freezeCount: 12,
                    scannedCount: 1,
                    updateDateTime: new Date(),
                    partInstances: [
                        {
                            id: 1,
                            serialNo: "123-12312-12312",
                            operator: "Test Testian",
                            scannedDateTime: new Date(),
                            Status: {
                                id: 1,
                                name: "Not Scanned",
                            }
                        },
                        {
                            id: 2,
                            serialNo: "123-12312-12312",
                            operator: "Test Testian",
                            scannedDateTime: new Date(),
                            Status: {
                                id: 2,
                                name: "Incorrect",
                            }
                        },
                        {
                            id: 3,
                            serialNo: "123-12312-12312",
                            operator: "Test Testian",
                            scannedDateTime: new Date(),
                            Status: {
                                id: 3,
                                name: "Correct",
                            }
                        },
                        {
                            id: 4,
                            serialNo: "123-12312-12312",
                            operator: "Test Testian",
                            scannedDateTime: new Date(),
                            Status: {
                                id: 1,
                                name: "Not Scanned",
                            }
                        },
                        {
                            id: 5,
                            serialNo: "123-12312-12312",
                            operator: "Test Testian",
                            scannedDateTime: new Date(),
                            Status: {
                                id: 2,
                                name: "Incorrect",
                            }
                        },
                        {
                            id: 6,
                            serialNo: "123-12312-12312",
                            operator: "Test Testian",
                            scannedDateTime: new Date(),
                            Status: {
                                id: 3,
                                name: "Correct",
                            }
                        }

                    ],
                },
                {
                    id: 2,
                    partNo: "RADFR101A",
                    freezeCount: 12,
                    scannedCount: 1,
                    updateDateTime: new Date(),
                    partInstances: [
                        {
                            id: 1,
                            serialNo: "123-12312-12312",
                            operator: "Test Testian",
                            scannedDateTime: new Date(),
                            Status: {
                                id: 1,
                                name: "Not Scanned",
                            }
                        },
                        {
                            id: 2,
                            serialNo: "123-12312-12312",
                            operator: "Test Testian",
                            scannedDateTime: new Date(),
                            Status: {
                                id: 2,
                                name: "Incorrect",
                            }
                        },
                        {
                            id: 3,
                            serialNo: "123-12312-12312",
                            operator: "Test Testian",
                            scannedDateTime: new Date(),
                            Status: {
                                id: 3,
                                name: "Correct",
                            }
                        },
                        {
                            id: 4,
                            serialNo: "123-12312-12312",
                            operator: "Test Testian",
                            scannedDateTime: new Date(),
                            Status: {
                                id: 1,
                                name: "Not Scanned",
                            }
                        },
                        {
                            id: 5,
                            serialNo: "123-12312-12312",
                            operator: "Test Testian",
                            scannedDateTime: new Date(),
                            Status: {
                                id: 2,
                                name: "Incorrect",
                            }
                        },
                        {
                            id: 6,
                            serialNo: "123-12312-12312",
                            operator: "Test Testian",
                            scannedDateTime: new Date(),
                            Status: {
                                id: 3,
                                name: "Correct",
                            }
                        }

                    ],
                },
                {
                    id: 3,
                    partNo: "RADFR101A",
                    freezeCount: 12,
                    scannedCount: 1,
                    updateDateTime: new Date(),
                    partInstances: [
                        {
                            id: 1,
                            serialNo: "123-12312-12312",
                            operator: "Test Testian",
                            scannedDateTime: new Date(),
                            Status: {
                                id: 1,
                                name: "Not Scanned",
                            }
                        },
                        {
                            id: 2,
                            serialNo: "123-12312-12312",
                            operator: "Test Testian",
                            scannedDateTime: new Date(),
                            Status: {
                                id: 2,
                                name: "Incorrect",
                            }
                        },
                        {
                            id: 3,
                            serialNo: "123-12312-12312",
                            operator: "Test Testian",
                            scannedDateTime: new Date(),
                            Status: {
                                id: 3,
                                name: "Correct",
                            }
                        },
                        {
                            id: 4,
                            serialNo: "123-12312-12312",
                            operator: "Test Testian",
                            scannedDateTime: new Date(),
                            Status: {
                                id: 1,
                                name: "Not Scanned",
                            }
                        },
                        {
                            id: 5,
                            serialNo: "123-12312-12312",
                            operator: "Test Testian",
                            scannedDateTime: new Date(),
                            Status: {
                                id: 2,
                                name: "Incorrect",
                            }
                        },
                        {
                            id: 6,
                            serialNo: "123-12312-12312",
                            operator: "Test Testian",
                            scannedDateTime: new Date(),
                            Status: {
                                id: 3,
                                name: "Correct",
                            }
                        }

                    ],
                },
                {
                    id: 4,
                    partNo: "RADFR101A",
                    freezeCount: 12,
                    scannedCount: 1,
                    updateDateTime: new Date(),
                    partInstances: [
                        {
                            id: 10,
                            serialNo: "123-12312-12312",
                            operator: "Test Testian",
                            scannedDateTime: new Date(),
                            Status: {
                                id: 1,
                                name: "Not Scanned",
                            }
                        },
                        {
                            id: 20,
                            serialNo: "123-12312-12312",
                            operator: "Test Testian",
                            scannedDateTime: new Date(),
                            Status: {
                                id: 2,
                                name: "Incorrect",
                            }
                        },
                        {
                            id: 30,
                            serialNo: "123-12312-12312",
                            operator: "Test Testian",
                            scannedDateTime: new Date(),
                            Status: {
                                id: 3,
                                name: "Correct",
                            }
                        },
                        {
                            id: 40,
                            serialNo: "123-12312-12312",
                            operator: "Test Testian",
                            scannedDateTime: new Date(),
                            Status: {
                                id: 1,
                                name: "Not Scanned",
                            }
                        },
                        {
                            id: 50,
                            serialNo: "123-12312-12312",
                            operator: "Test Testian",
                            scannedDateTime: new Date(),
                            Status: {
                                id: 2,
                                name: "Incorrect",
                            }
                        },
                        {
                            id: 60,
                            serialNo: "123-12312-12312",
                            operator: "Test Testian",
                            scannedDateTime: new Date(),
                            Status: {
                                id: 3,
                                name: "Correct",
                            }
                        }

                    ],
                },
            ],
            reports: {
                reportTypes: [
                    {
                        id: 1,
                        title: "Test 1",

                    },
                    {
                        id: 2,
                        title: "Test 2"
                    },
                    {
                        id: 3,
                        title: "Test 3"
                    }
                ],
                reportList: [
                    {
                        id: 1,
                        typeId: 1,
                        title: "report 1",

                    },
                    {
                        id: 2,
                        typeId: 1,
                        title: "report 2",
                    },
                    {
                        id: 3,
                        typeId: 2,
                        title: "report 3",
                    },
                    {
                        id: 4,
                        typeId: 2,
                        title: "report 4",
                    },
                    {
                        id: 5,
                        typeId: 3,
                        title: "report 5",

                    },
                    {
                        id: 6,
                        typeId: 3,
                        title: "report 6",

                    },
                ],
            },
            recentLogs: [
                {
                    id: 1,
                    partNo: 'RADFR101A',
                    serialNo: '12312-12123-12123',
                    count: 12,
                    dateTime: new Date(),
                    operator: "Sepehr Saebnia"
                },
                {
                    id: 2,
                    partNo: 'CACH101A',
                    serialNo: '12312-12123-12123',
                    count: 1,
                    dateTime: new Date(),
                    operator: "Saul Saebnia"
                }
            ]
        })
        setLoading(false);

    }, [params])

    /**
     * With each change in the type of tab from query params of the url:
     * Checks if the selected tab is the products for showing the recount section
     */
    useEffect(() => {
        if (type === SpireCountingTabValues.items) {
            setIsShowingRecount(true);
            return;
        }
        setIsShowingRecount(false);
    }, [type]);

    /**
     * Handles recounting the counting request with help of redux:
     * dispatches actions regarding the status of recounting
     */
    const onRecountClicked = () => {
        dispatch(spireCountingSelectionActions.setIsSelecting(true));
        //TODO: Add api call or other steps for submitting recount request
    }

    /**
     * Handles canceling the recount request
     * dispatches redux recount action regarding this process
     */
    const onRecountCanceled = () => {
        dispatch(spireCountingSelectionActions.setIsSelecting(false));
    }

    /**
     * Determines the selected tab and updates the url of the view
     * @param {string} tabValue
     */
    const onTabChanged = (tabValue) => {
        const result = {
            [SpireCountingSingleQueryNames.type]: tabValue,
        }
        history.push(stringifyUrl({
            url: location.pathname,
            query: {...result}
        }))
    }

    /**
     * Handles navigating user to the previous visited route which is list of spire counting
     */
    const onNavigateBackClicked = () => {
        //TODO: needs to be double checked
        history.replace(routeFunctions.panel.company.counting.spire(params.company));
    }


    return (
        <>
            <div className={"spire-counting-single-view"}>
                <div className={'panel-card p-0 pt-4'}>
                    <div className={'px-4 d-flex align-items-center justify-content-between'}>
                        <div className={'back-icon-button'} onClick={onNavigateBackClicked}>
                            <BackIconButton/>
                        </div>
                        <Fade
                            in={isShowingRecount}
                            children={
                                <div>
                                    {recountState.isSelecting &&
                                        <button
                                            className={'button primary  outlined px-2 mx-2'}
                                            onClick={onRecountCanceled}
                                        >
                                            {translation?.cancelRecount ?? ''}
                                        </button>
                                    }
                                    <button
                                        className={'button primary  px-2'}
                                        onClick={onRecountClicked}
                                        disabled={recountState.isSelecting && !!!recountState.selectedCountingIds.length}
                                    >
                                        {recountState.isSelecting && (translation?.proceed ?? '').concat(` (${recountState.selectedCountingIds.length})`)}
                                        {!recountState.isSelecting && (translation?.recountRequest ?? '')}
                                    </button>
                                </div>
                            }
                        />
                    </div>

                    <TabsLayout
                        tabs={tabs}
                        swipeable
                        onChange={onTabChanged}
                        initialValue={query ? query[SpireCountingSingleQueryNames.type] : undefined}
                    >
                        <SpireCountingInformation data={countingReqFullDetails} loading={loading}/>
                        <SpireCountingItems data={countingReqFullDetails}/>
                        <SpireCountingParticipants data={countingReqFullDetails} loading={loading}/>
                        <SpireCountingReports data={countingReqFullDetails}/>

                    </TabsLayout>

                </div>
            </div>
        </>
    );
}

export default SpireCountingSingleView;
