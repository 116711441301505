import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {DefaultInitialPaginationInfo, TableCellAlignments, TableCellTypes} from "../../../../../../core/constants/enums";
import Switch from "../../../../../components/base/switch";
import NoEntries from "../../../../../components/app-specific/no-entries";
import Table from "../../../../../containers/table";
import useRouter from "../../../../../hooks/use-router";
import {unMaskPhoneNumber} from "../../../../../../core/services/utils";
import UsersSearchSection from "../../../../../components/search-sections/users/list";
import CreateNewUserDialog from "../../../../../components/dialogs/create-new-user";
import classnames from "classnames";
import useIsMounted from "../../../../../hooks/use-is-mounted";
import {CompanyContext} from "../../../../../contexts/company";
import QueryManagementUtils from "../../../../../../core/services/query-management-utils";
import useSearchData from "../../../../../hooks/use-search-data";
import {UsersListQueryParams} from "../../../../../../core/constants/query-params";

// Keys for the users table
const tableCellKeys = {
    firstName: 'firstName',
    lastName: 'lastName',
    email: 'email',
    phoneNumber: 'phoneNumber',
    isAdmin: "isAdmin",
    isActive: "isActive",
}

const initialPaginationInfo = {
    ...DefaultInitialPaginationInfo,
    pageSize: 10,
}

const UsersListView = () => {
    const {query, location, history, stringifyUrl} = useRouter()
    const [isAddingNewUser, setIsAddingNewUser] = useState(false);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const {t} = useTranslation();
    const isMounted = useIsMounted();

    const translation = t("views.panel.company.usersManagement", {returnObjects: true});

    const [company] = useContext(CompanyContext);
    const selectedCompany = useRef(company);

    /**
     * With each change in the selected company:
     */
    useEffect(() => {
        if (selectedCompany.current?.id === company?.id)
            return;
        selectedCompany.current = company;
        //   TODO: implement the action related to this
    }, [company?.id])


    // TODO: Remove when called the api
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    /**
     * Loads the list of users:
     * Sets the [loading] in the state as true
     * Calls the api regarding this action and handles error cases
     * sets the [users], [paginationInfo], [orderBy] by calling the setState function for each
     */
    const search = async () => {
        await delay(2000);
        const forApi = prepareRequestDs();
        const response = {
            resultFlag: true,
            data: {
                users: [
                    {
                        id: 1,
                        firstName: 'Testing',
                        lastName: 'User 1',
                        email: 'testing@gmail.com',
                        phoneNumber: '1231237878',
                        isAdmin: false,
                        isActive: true,

                    },
                    {
                        id: 2,
                        firstName: 'Tes2ting',
                        lastName: 'User 2',
                        email: 'testing@gmail.com',
                        phoneNumber: '1231237878',
                        isAdmin: false,
                        isActive: true,

                    },
                    {
                        id: 3,
                        firstName: 'Tes3ting',
                        lastName: 'User 3',
                        email: 'testing@gmail.com',
                        phoneNumber: '1231237878',
                        isAdmin: false,
                        isActive: true,

                    },
                    {
                        id: 4,
                        firstName: 'Tes4ting',
                        lastName: 'User 4',
                        email: 'testing@gmail.com',
                        phoneNumber: '1231237878',
                        isAdmin: true,
                        isActive: false,

                    },
                    {
                        id: 5,
                        firstName: 'Tes5ting',
                        lastName: 'User 5',
                        email: 'testing@gmail.com',
                        phoneNumber: '1231237878',
                        isAdmin: false,
                        isActive: true,

                    },
                    {
                        id: 6,
                        firstName: 'Tes6ting',
                        lastName: 'User 6',
                        email: 'testing@gmail.com',
                        phoneNumber: '1231237878',
                        isAdmin: false,
                        isActive: true,

                    },
                    {
                        id: 7,
                        firstName: 'Tes7ting',
                        lastName: 'User 7',
                        email: 'testing@gmail.com',
                        phoneNumber: '1231237878',
                        isAdmin: false,
                        isActive: true,

                    },
                    {
                        id: 8,
                        firstName: 'Tes8ting',
                        lastName: 'User 8',
                        email: 'testing@gmail.com',
                        phoneNumber: '1231237878',
                        isAdmin: true,
                        isActive: false,

                    },
                    {
                        id: 9,
                        firstName: 'Tes9ting',
                        lastName: 'User 9',
                        email: 'testing@gmail.com',
                        phoneNumber: '1231237878',
                        isAdmin: true,
                        isActive: true,

                    },
                    {
                        id: 10,
                        firstName: 'Tes10ting',
                        lastName: 'User 10',
                        email: 'testing@gmail.com',
                        phoneNumber: '1231237878',
                        isAdmin: false,
                        isActive: false,

                    },

                ],
                paginationInfo: {
                    length: 10,
                }
            }
        }
        if (!isMounted())
            return;
        if (response.resultFlag) {
            setUsers(response?.data?.users ?? []);
            const newPaginationInfo = {
                ...paginationInfo,
                length: response?.data.paginationInfo.length ?? 0
            }
            QueryManagementUtils.setPaginationInfo(newPaginationInfo, query, history, location);
        }
        setLoading(false);
    }

    const {filters, paginationInfo, orderBy} = useSearchData(
        UsersListQueryParams,
        search,
        {
            useFilters: true,
            initialFilters: {},
            filtersQueryParamMap: {
                keyword: UsersListQueryParams.keywords,
            },

            usePaginationInfo: true,
            initialPaginationInfo: initialPaginationInfo,

            useOrderBy: true,
            initialOrderBy: undefined,

        }
    )

    const prepareRequestDs = () => {
        return {
            paginationInfo: paginationInfo,
            filters: filters,
            orderBy: orderBy
        }
    }

    /**
     * Changes the user being admin in the system by clicking on the switch
     * @param {boolean} isAdmin
     * @param {Object} user
     */
    const onIsAdminToggle = (isAdmin, user) => {

    }

    /**
     * Changes the user being active in the system by clicking on the switch
     * @param {boolean} isActive
     * @param {Object} user
     */
    const onIsActiveToggle = (isActive, user) => {

    }

    /**
     * Opens the dialog for adding a new user to the system
     * @param {boolean} shouldOpen
     */
    const onAddNewUserClicked = (shouldOpen) => {
        setIsAddingNewUser(shouldOpen);
    }

    /**
     * Creates a new user:
     * - Calls the api regarding this action,
     * - if the api call was successful, adds the user to the list of users
     *
     *  removes the first user in the list and adds the added user to the [users] in the state
     *
     * @param {Object} value form values from the dialog
     * @param {string} phoneNumberFormKey name of the phone number form field
     */
    const createNewUser = (value, phoneNumberFormKey) => {
        let phoneNumberUnMasked = unMaskPhoneNumber(value[phoneNumberFormKey]);
        //TODO:call the api for adding the new user to the list
        setIsAddingNewUser(false);
        // setUsers([]);

        setUsers(prevState => {
            if (prevState.length === paginationInfo.pageSize) {
                prevState.splice(0, 1, {
                    id: 1000,
                    firstName: value.firstName,
                    lastName: value.lastName,
                    email: value.email,
                    phoneNumber: phoneNumberUnMasked,
                    isAdmin: false,
                    isActive: false
                });
                return prevState;
            } else {
                return [{
                    id: 1000,
                    firstName: value.firstName,
                    lastName: value.lastName,
                    email: value.email,
                    phoneNumber: phoneNumberUnMasked,
                    isAdmin: false,
                    isActive: false
                }, ...prevState]
            }
        })
    }

    /**
     * Clears the form values
     */
    const clearFilters = () => {
        history.push(stringifyUrl({
            url: location.pathname,
            query: {}
        }));
    }

    /**
     * Memo version of table columns
     * @type {object[]}
     */
    const tableColumns = useMemo(() => [
        {
            title: translation?.tableHeaders?.firstName ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: tableCellKeys.firstName,
            sortable: true
        },
        {
            title: translation?.tableHeaders?.lastName ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: tableCellKeys.lastName,
            sortable: true,
        },
        {
            title: translation?.tableHeaders?.emailAddress ?? '',
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: tableCellKeys.email,
            sortable: true,
        },
        {
            title: translation?.tableHeaders?.phoneNumber ?? '',
            alignment: TableCellAlignments.right,
            type: TableCellTypes.string,
            name: tableCellKeys.phoneNumber,
            sortable: true,
            size: 2
        },
        {
            title: translation?.tableHeaders?.isAdmin ?? '',
            alignment: TableCellAlignments.center,
            type: TableCellTypes.element,
            name: tableCellKeys.isAdmin,
            size: 0,
        },
        {
            title: translation?.tableHeaders?.isActive ?? '',
            alignment: TableCellAlignments.center,
            type: TableCellTypes.element,
            name: tableCellKeys.isActive,
            size: 0,
        }
    ], [translation])

    /**
     * Memo version of table rows
     * @type {object[]}
     */
    const tableRows = useMemo(() => {
        return users?.map((user) => ({
            key: user.id,
            [tableCellKeys.firstName]: user?.firstName,
            [tableCellKeys.lastName]: user?.lastName,
            [tableCellKeys.email]: user?.email,
            [tableCellKeys.phoneNumber]: user?.phoneNumber,
            [tableCellKeys.isAdmin]: () => (
                <Switch onChange={(checked) => onIsAdminToggle(checked, user)} checked={user?.isAdmin} size={5}/>
            ),
            [tableCellKeys.isActive]: () => (
                <Switch onChange={(checked) => onIsActiveToggle(checked, user)} checked={user?.isActive} size={5}/>
            ),
        }))
    }, [users])


    return (
        <>
            <div className={"user-management"}>
                <div className={'panel-card p-3 pt-4'}>
                    <div className={'px-4 d-flex align-items-center justify-content-between'}>
                        <p className={'title'}>
                            {translation?.title ?? ''}
                        </p>
                        <button
                            className={'button primary outlined px-4'}
                            onClick={() => onAddNewUserClicked(true)}
                        >
                            {translation?.newUserButtonLabel ?? ''}
                        </button>
                    </div>
                    <UsersSearchSection filters={filters}/>
                    <div>
                        {
                            (!!users?.length < 1) && !loading
                                ? <NoEntries
                                    className={'pb-5'}
                                    text={
                                        Object.entries(filters ?? {})?.length > 0
                                            ? translation?.empty?.withFilters
                                            : translation?.empty?.text
                                    }
                                    includeButton={Object.entries(filters ?? {})?.length > 0}
                                    buttonProps={{
                                        onClick: clearFilters,
                                        className: 'button primary px-3'
                                    }}
                                />
                                : <Table
                                    loading={{state: loading, count: 8}}
                                    className={classnames('my-3 users-table')}
                                    orderBy={orderBy}
                                    color={'primary'}
                                    onSort={(orderBy) => QueryManagementUtils.setOrderBy(
                                        orderBy,
                                        query,
                                        history,
                                        location,
                                    )}
                                    cells={tableColumns}
                                    data={tableRows}
                                    paginationInfo={paginationInfo}
                                    onPageSizeChange={(p, i) => QueryManagementUtils.setPaginationInfo(
                                        {...p, pageSize: i},
                                        query,
                                        history,
                                        location
                                    )}
                                    onCurrentPageChange={(p, newP) => QueryManagementUtils.setPaginationInfo(
                                        {...p, currentPage: newP},
                                        query,
                                        history,
                                        location,
                                    )}
                                />

                        }
                    </div>

                </div>
            </div>
            <CreateNewUserDialog
                open={isAddingNewUser}
                setOpen={onAddNewUserClicked}
                createNewUser={createNewUser}
            />
        </>
    );


}

export default UsersListView;
