import {combineReducers} from 'redux';
import UserInfoReducer from './user-info/reducer';
import dialogSlice from "./dialog-slice";
import spireRecountSelectionSlice from "./spire-recount-selection-slice";

/**
 * Combines all the different redux slices into a single reducer for the store.
 */
export default combineReducers(
    {
        userInfo: UserInfoReducer,
        dialog: dialogSlice.reducer,
        spireRecountSelection: spireRecountSelectionSlice.reducer,
    }
);
