import React, {Suspense, useEffect, useLayoutEffect, useRef, useState} from "react";
import useRouter from "../hooks/use-router";
import CacheService from "../../core/services/cache_service";
import routes, {routeFunctions, routesLists,} from "../routes";
import {matchPath, Redirect, Route, Switch} from "react-router";
import Api from "../../core/services/api_service";
import PublicViews from "./public";
import ErrorViews from "./error";
import Loader from "../components/app-specific/loader";
import useIsMounted from "../hooks/use-is-mounted";
import PanelViews from "./panel";
import TestView from "./public/test";
import AnimationService from "../../core/services/animation-service";
import {AnimationServiceContext} from "../contexts/animation-service";
import ConfirmationDialog from "../components/dialogs/confirmation-dialog";
import BarcodeDialog from "../components/dialogs/barcode-dialog";
import LocalStorageService from "../../core/services/local_storage_service";
import InventorySchemaVisualizerApiService from "../../packages/inventory-schema-visualizer/core/services/api";
import {ApiHeaderNames} from "../../core/constants/enums";

const Views = () => {
    const {history, location} = useRouter();
    const [coreUrls, setCoreUrls] = useState(CacheService.getCoreUrls());
    const isMounted = useIsMounted();
    const animationService = useRef(new AnimationService())
    const [authToken, setAuthToken] = useState(LocalStorageService.get(LocalStorageService.keys.token) ?? '');
    const [companyId, setCompanyId] = useState(LocalStorageService.get(LocalStorageService.keys.companyId) ?? undefined);

    /**
     * Attaches an event listeners for the storage and with each dispatch determines what to do with the new data.
     */
    useEffect(() => {
        window.addEventListener('storage', determineStorageEvent);
        return () => window.removeEventListener('storage', determineStorageEvent);
    }, [])

    /**
     * Determines what callback should be called based on the event key.
     * @param {StorageEvent} event
     */
    const determineStorageEvent = (event) => {
        switch (event.key) {
            case LocalStorageService.keys.token:
                setAuthToken(event.newValue);
                break;
            case LocalStorageService.keys.companyId:
                setCompanyId(event.newValue);
                break;
            default:
                break;
        }
    }

    /**
     * With each change in the [auth-token] value of the props:
     * - creates the injectors for the apis such that the auth header is set with the [token] value
     */
    useLayoutEffect(() => {
        InventorySchemaVisualizerApiService.injectInterceptors({
            request: [[
                (config) => {
                    config.headers = {
                        [ApiHeaderNames.authorization]: LocalStorageService.get(LocalStorageService.keys.token),
                        [ApiHeaderNames.companyId]: CacheService.getCompanyId(),
                        ...(config.headers ?? {}),
                    }
                    return config;
                }
            ]],
        })

    }, [authToken, companyId])

    /**
     * As soon as the component mounts:
     * * Adds the login to core functionality to the global bizpire object.
     * * Adds the navigation to core functionality to the global bizpire object.
     * * Adds the getter of core urls to the global bizpire object.
     */
    useLayoutEffect(() => {
        window.bizpire = {
            ...window.bizpire,
            navigateToCoreLogin: navigateToCoreLogin,
            navigateToCore: navigateToCore,
            getCoreUrls: () => coreUrls,
        };
        return () => {
            delete window.bizpire?.navigateToCoreLogin;
            delete window.bizpire?.getCoreUrls;
            delete window.bizpire?.navigateToCore;
        }
    }, [])

    /**
     * Sets the history object for api interface.
     * Sets the location object for api interface
     * Listens for the changes in the location object and sets the updated location for api interface
     */
    useEffect(() => {
        Api.setHistory(history);
        Api.setLocation(location);
        return history.listen((location) => {
            Api.setLocation(location);
        });
    }, [location])

    /**
     * checks if loginUrl and logoutUrl exists, if not fetches them from api as soon as the component mounts
     */
    useEffect(() => {
        getCoreUrls().then();
    }, [coreUrls])

    /**
     * Listens for the changes in pathname of the url and with each change, determines if there is a
     * need that the user must be redirected to login page.
     */
    useEffect(() => {
        determineIfShouldRedirectToLoginOrPanel();
    }, [location.pathname])

    /**
     * Attaches an event listeners for the storage and with each dispatch, determines if the user should be
     * redirected to the login page.
     */
    useEffect(() => {
        window.addEventListener('storage', determineIfShouldRedirectToLoginOrPanel);
        return () => window.removeEventListener('storage', determineIfShouldRedirectToLoginOrPanel);
    }, [])

    /**
     * Fetches the core urls form the server that are used for the authentication purposes.
     */
    const getCoreUrls = async () => {
        if (!Object.values(coreUrls ?? {})?.filter(e => !e?.length)?.length) return;
        const response = await Api.getApplicationConfigurations();
        if (!isMounted()) return;
        if (!response?.data) return;
        // TODO: change the name of baseUrl when api is ready
        const data = CacheService.setCoreUrls(response.data.loginUrl, response.data.logoutUrl, response.data.baseUrl);
        setCoreUrls(data);
        return data;
    }

    /**
     * Depending on the current route:
     * if route is landing => if login, redirect to panel, otherwise redirect to public.landing
     * if route is public or not private => do nothing
     * if route is private => check if the user is logged in and if not in logout or error pages, navigate to login
     * page.
     */
    const determineIfShouldRedirectToLoginOrPanel = () => {
        if (isUserInLandingView()) return;
        if (isUserInNonRestrictedViews()) return;
        // if not logged in, save current url and go to login page
        if (!CacheService.isLoggedIn()) {
            redirectToUrl(routeFunctions.public.landing());
        }
    }

    /**
     * Navigates the user to an appropriate view depending on their auth state.
     *
     * - if the user is not in the landing view then do nothing
     * - otherwise:
     *      * if they are not login then navigate them to public.landing to register or login
     *      * otherwise navigate them to their cached url and then remove their cached url
     * @return {boolean} to indicate if the user was in the landing view or not
     */
    const isUserInLandingView = () => {
        const isInLandingView = !!matchPath(window.location.pathname, {path: [routes.landing], exact: true});
        if (!isInLandingView) {
            return false;
        }
        if (!CacheService.isLoggedIn()) {
            history.replace(routeFunctions.public.landing());
            return true;
        }
        const cachedUrl = CacheService.getCachedUrl();
        if (cachedUrl) {
            history.replace(cachedUrl);
        } else {
            history.replace(routes.panel.base);
        }
        return true;
    }

    /**
     * Determines if the user is in a non-restricted (requiring authentication) view or not.
     * @return {boolean}
     */
    const isUserInNonRestrictedViews = () => {
        const isInPublicRoutes = !!matchPath(window.location.pathname, {
            path: [
                routes.landing,
                ...routesLists.public,
                ...routesLists.error,
                routes.panel.auth.login,
                routes.panel.auth.loginWithoutParams,
            ],
            exact: true
        });
        const isInPrivateRoutes = !!matchPath(window.location.pathname, {
            //TODO: add private pages
            path: [
                ...routesLists.panel.filter(e => ![routes.panel.auth.login].includes(e)),
            ],
            exact: true
        });
        return isInPublicRoutes || !isInPrivateRoutes;
    }

    /**
     * Finds the lookup url of the coreUrls and invokes the navigation function with the lookup url
     *
     * in case of errors, onError can be invoked if exists
     * @param {string}                  lookupUrl
     * @param {function(string): void}  navigate
     * @param {function(): void}        onError
     * @return {Promise<void>}
     */
    const withCoreUrls = async (lookupUrl, navigate, onError) => {
        if (!coreUrls) {
            if (onError) onError();
        }
        if (coreUrls[lookupUrl]?.length) {
            navigate(coreUrls[lookupUrl]);
        }
        const _coreUrls = await getCoreUrls();
        if (_coreUrls) {
            navigate(_coreUrls[lookupUrl])
        } else {
            if (onError) onError();
        }
    }

    /**
     * Caches the current url of the application and then navigates the user to login page
     * if the loginUrl is not yet available, does nothing.
     */
    const navigateToCoreLogin = () => {
        const _navigateToCoreLogin = (loginUrl) => {
            const url = `${loginUrl}/${encodeURIComponent(window.location.origin + routes.panel.auth.loginWithoutParams)}`;
            redirectToUrl(url);
        }
        const _onError = () => {
            console.error('The login url is not yet available')
            history.replace(routes.public.landing)
        }
        withCoreUrls('loginUrl', _navigateToCoreLogin, _onError).then();
    };

    /**
     * Navigates the user to login page
     * if the baseurl is not yet available, does nothing.
     */
    const navigateToCore = () => {
        const _navigateToCore = (baseUrl) => {
            redirectToUrl(baseUrl)
        }
        const _onError = () => {
            console.error('The base url is not yet available')
        }
        withCoreUrls('baseUrl', _navigateToCore, _onError).then();
    };

    /**
     * Caches the current route of the application if in restricted views and the redirects the user to the url.
     *
     * @param {string}  url
     * @param {boolean} cacheRoute
     */
    const redirectToUrl = (url, cacheRoute = true) => {
        if (cacheRoute && !isUserInNonRestrictedViews()) {
            CacheService.cacheUrl(location);
        }
        window.location.replace(url);
    }

    return (
        <>
            <AnimationServiceContext.Provider value={animationService.current}>
                <Suspense fallback={<Loader withOverlay/>}>
                    {
                        !coreUrls?.loginUrl?.length
                            ? <Loader withOverlay/>
                            : (
                                <>
                                    <Switch>
                                        <Route
                                            path={[
                                                routes.landing,
                                                ...routesLists.public
                                            ]} exact>
                                            <PublicViews/>
                                        </Route>
                                        <Route path={routesLists.panel} exact>
                                            <PanelViews/>
                                        </Route>
                                        {/*TODO: Remove Test in production*/}
                                        <Route path={'/test'}>
                                            <TestView/>
                                        </Route>
                                        <Route
                                            path={[
                                                ...routesLists.error,
                                                ...routesLists.allWithAccessDeniedSection,
                                                ...routesLists.allWithServerErrorSection
                                            ]}>
                                            <ErrorViews/>
                                        </Route>
                                        <Redirect to={routes.error.notFound}/>
                                    </Switch>
                                    <ConfirmationDialog/>
                                    <BarcodeDialog/>

                                </>

                            )
                    }
                </Suspense>
            </AnimationServiceContext.Provider>
        </>
    );
}

export default Views;
