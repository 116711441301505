import {createSlice} from "@reduxjs/toolkit";

const slice = createSlice({
    name: 'user-info',
    initialState: {
        data: null,
    },
    reducers: {
        /**
         * Sets the information of the user.
         * @param state
         * @param action
         */
        set: (state, action) => {
            const newRes = {...state.data};
            Object.entries(action.payload ?? {}).forEach(([key, value]) => {
                if (newRes[key]) {
                    switch (typeof newRes[key]) {
                        case 'object':
                            newRes[key] = {...newRes[key], ...value};
                            break;
                        default:
                            newRes[key] = value;
                    }
                    return;
                }
                newRes[key] = value;
            })
            state.data = newRes;
        },
    }
});

/**
 * User info Actions
 */
export const {
    set,
} = slice.actions;

/**
 * The User info Reducer
 */
export default slice.reducer;
