// ###########################       General Query Params           ###########################

/**
 * The default query parameters specific for pagination data used and shared through the application
 */
export const PaginationQueryParams = {
    pageSize: 'size',
    currentPage: 'page',
    length: 'total',
}

/**
 * Sort by query parameters specific for sorting purposes used in the views that need these properties.
 */
export const SortByQueryParams = {
    property: "sort-by-property-name",
    descending: "sort-by-is-descending",
}


/**
 * Common query param names in the application
 */
export const CommonQueryParams = {
    tab: 'tab',
}

// #############################        Application Search Query Params      ###########################

/**
 * Query params keys used in sales order list view
 */
export const SalesOrderListQueryParams = {
    keyword: 'keyword',
    status: 'status',
    fromDate: 'fromDate',
    toDate: 'toDate',
    pageSize: PaginationQueryParams.pageSize,
    currentPage: PaginationQueryParams.currentPage,
    length: PaginationQueryParams.length,

}

/**
 * Query params keys used in users list view
 */
export const UsersListQueryParams = {
    keywords: 'keyword',
}


/**
 * Query param keys used in spire counting list view
 */
export const SpireCountingListQueryParams = {
    keywords: 'keyword',
}

/**
 * Query param keys used in the items list of a position counting
 */
export const PositionCountingItemsQueryParams = {
    keyword: 'keyword',
    status: 'status',
    filter: 'filter'
}


/**
 * Query param keys used in the items list of a spire counting
 */
export const SpireCountingItemsQueryParams = {
    keyword: 'keyword',
    filter: 'filter'
}


