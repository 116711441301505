import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import useRouter from "../../../../../../hooks/use-router";
import {useTranslation} from "react-i18next";
import {DefaultInitialPaginationInfo, TableCellAlignments, TableCellTypes} from "../../../../../../../core/constants/enums";
import {generatePaginationAndOrderByQueryResetter} from "../../../../../../../core/services/searching-utils";
import NoEntries from "../../../../../../components/app-specific/no-entries";
import Table from "../../../../../../containers/table";
import PartsSearchSection from "../../../../../../components/search-sections/items/list/parts";
import {ReactComponent as ConvertToExcel} from "../../../../../../../assets/images/convert-to-excel.svg";
import {ReactComponent as CheckMarkIcon} from "../../../../../../../assets/images/order-serializable/serialized.svg";
import {ReactComponent as CrossIcon} from "../../../../../../../assets/images/order-serializable/not-serialized.svg";
import {exportCSVFile} from "../../../../../../../core/services/utils";
import {routeFunctions, routes} from "../../../../../../routes";
import {ReactComponent as BarcodeIcon} from "../../../../../../../assets/images/barcode-icon.svg";
import {dialogActions} from "../../../../../../../redux/entities/dialog-slice";
import {useDispatch} from "react-redux";
import useIsMounted from "../../../../../../hooks/use-is-mounted";
import moment from "moment";
import useSearchData from "../../../../../../hooks/use-search-data";
import QueryManagementUtils from "../../../../../../../core/services/query-management-utils";
import {CompanyContext} from "../../../../../../contexts/company";
import {PartListQueryNames} from "../../../../../../../core/constants/query-names";
import {matchPath} from "react-router";

// Object indicating the table cell keys
const tableCellKeys = {
    partNumber: "partNumber",
    weight: "weight",
    height: "height",
    width: "width",
    depth: "depth",
    submitDateTime: "submitDateTime",
    isSerialized: "isSerialized",
    description: 'description'
}

const initialPaginationInfo = {
    ...DefaultInitialPaginationInfo,
    pageSize: 10,
}

const PartsTabView = () => {
    const {query, location, history, stringifyUrl, params} = useRouter();
    const [parts, setParts] = useState([]);
    const [loading, setLoading] = useState(true);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const isMounted = useIsMounted();
    const [isGeneratingExcelReport, setIsGeneratingExcelReport] = useState(false);

    const translation = t("views.panel.company.products.list.parts", {returnObjects: true});

    //Panel company
    const [company] = useContext(CompanyContext);
    const selectedCompany = useRef(company);

    /**
     * With each change in the selected company:
     */
    useEffect(() => {
        if (selectedCompany.current?.id === company?.id)
            return;
        selectedCompany.current = company;
        //   TODO: implement the action related to this
    }, [company?.id])

    /**
     * With each change in the path name of the location, checks if query is empty so triggers searching again
     */
    useEffect(() => {
        if (!!matchPath(location.pathname, {
            path: routes.panel.company.items.list.products,
            exact: true
        }) && !!Object.keys(query)?.length < 1)
            search().then();
    }, [location?.pathname])

    /**
     * Searches for part instances:
     * - Calls teh api regarding this action
     */
    const search = async () => {
        const forApi = prepareSearchRequestDs();
        const response = {
            resultFlag: true,
            data: {
                items: [
                    {
                        id: 1,
                        partNumber: 'RADFR153A',
                        weight: 120,
                        height: 100,
                        width: 10,
                        depth: 20,
                        submitDateTime: new Date(),
                        isSerialized: true,
                        description: "this is a test"
                    },
                    {
                        id: 2,
                        partNumber: 'RADFR153A',
                        weight: 120,
                        height: 100,
                        width: 10,
                        depth: 20,
                        submitDateTime: new Date(),
                        isSerialized: false,
                        description: "this is a test"
                    },
                    {
                        id: 3,
                        partNumber: 'RADFR153A',
                        weight: 120,
                        height: 100,
                        width: 10,
                        depth: 20,
                        submitDateTime: new Date(),
                        isSerialized: true,
                        description: "this is a test"
                    },
                    {
                        id: 4,
                        partNumber: 'RADFR153A',
                        weight: 120,
                        height: 100,
                        width: 10,
                        depth: 20,
                        submitDateTime: new Date(),
                        isSerialized: false,
                        description: "this is a test"
                    },
                ],
                paginationInfo: {
                    length: 4,
                }
            }
        }
        if (!isMounted())
            return;
        if (response?.resultFlag) {
            setParts(response?.data?.items);
            const newPaginationInfo = {
                ...paginationInfo,
                length: response?.data.paginationInfo.length ?? 0
            }
            QueryManagementUtils.setPaginationInfo(newPaginationInfo, query, history, location);
        }
        setLoading(false);
    }

    const {filters, paginationInfo, orderBy} = useSearchData(
        PartListQueryNames,
        search,
        {
            useFilters: true,
            initialFilters: {},
            filtersQueryParamMap: {
                keyword: PartListQueryNames.keyword,
                depth: PartListQueryNames.depth,
                height: PartListQueryNames.height,
                width: PartListQueryNames.width,
                weight: PartListQueryNames.weight,
            },

            usePaginationInfo: true,
            initialPaginationInfo: initialPaginationInfo,

            useOrderBy: true,
            initialOrderBy: undefined,
        }
    );

    /**
     * Prepares the api call request body based on filters, pagination, and orderBy:
     */
    const prepareSearchRequestDs = () => {
        return {
            filters: filters,
            orderBy: orderBy,
            paginationInfo: paginationInfo,
        }
    }

    /**
     * Clears the search filters of this view to invoke a search without any filters
     */
    const clearFilters = () => {
        history.push(stringifyUrl({
            url: location.pathname,
            query: {
                ...generatePaginationAndOrderByQueryResetter()
            }
        }));
    }

    /**
     * Generates excel report for the list of parts of the system:
     */
    const generateExcelReport = async () => {
        setIsGeneratingExcelReport(true);
        const response = await search();
        if (!isMounted())
            return;
        setIsGeneratingExcelReport(false);
        if (response?.resultFlag) {
            const title = translation?.excelTitle;
            const content = response.data.items?.map(e => ({
                [translation?.tableHeaders.partNumber]: e?.partNumber,
                [translation?.tableHeaders.weight]: `${e.weight}`,
                [translation?.tableHeaders.height]: `${e.height}`,
                [translation?.tableHeaders.width]: `${e.width}`,
                [translation?.tableHeaders.depth]: `${e.depth}`,
                [translation?.tableHeaders.submitDateTime]: `${moment(e?.submitDateTime).format('DD-MM-YYYY HH:mm:ss')}`,
                [translation?.tableHeaders.isSerialized]: `${e.isSerialized ? 'True' : 'False'}`,
                [translation?.tableHeaders.description]: `${e.description}`,
            }))
            const headers = Object.keys(content[0]);
            exportCSVFile(headers, content, title);
        }
    }

    /**
     * Navigates user to part view:
     * - Change the url path and adds the part id as the url param
     * @param {Object} part
     */
    const onPartNumberClicked = (part) => {
        history.push(routeFunctions.panel.company.items.single.product(params.company, part.id))
    }

    /**
     * Handles click on the barcode icon button:
     * - Opens dialog for showing the barcode
     * @param {string} partNumber for generating barcode from
     */
    const onBarcodeClicked = (partNumber) => {
        dispatch(dialogActions.configBarcodeDialog({
            open: true,
            onClose: () => dispatch(dialogActions.barcodeDialogToggle(false)),
            barcodeCode: partNumber,
        }));
    }

    /**
     * Memo version of table columns
     * @type {object[]}
     */
    const tableColumns = useMemo(() => [
        {
            title: translation?.tableHeaders?.partNumber,
            alignment: TableCellAlignments.left,
            type: TableCellTypes.element,
            name: tableCellKeys.partNumber,
            sortable: true
        },
        {
            title: translation?.tableHeaders.weight,
            alignment: TableCellAlignments.right,
            type: TableCellTypes.string,
            name: tableCellKeys.weight,
            sortable: true
        },
        {
            title: translation?.tableHeaders.height,
            alignment: TableCellAlignments.right,
            type: TableCellTypes.string,
            name: tableCellKeys.height,
            sortable: true
        },
        {
            title: translation?.tableHeaders.width,
            alignment: TableCellAlignments.right,
            type: TableCellTypes.string,
            name: tableCellKeys.width,
            sortable: true
        },
        {
            title: translation?.tableHeaders.depth,
            alignment: TableCellAlignments.right,
            type: TableCellTypes.string,
            name: tableCellKeys.depth,
            sortable: true
        },
        {
            title: translation?.tableHeaders.submitDateTime,
            alignment: TableCellAlignments.right,
            type: TableCellTypes.dateTime,
            name: tableCellKeys.submitDateTime,
            sortable: true,
            size: 1.7
        },
        {
            title: translation?.tableHeaders.isSerialized,
            alignment: TableCellAlignments.center,
            type: TableCellTypes.element,
            name: tableCellKeys.isSerialized,
            sortable: true
        },
        {
            title: translation?.tableHeaders.description,
            alignment: TableCellAlignments.left,
            type: TableCellTypes.string,
            name: tableCellKeys.description,
            sortable: true
        },
    ], [translation]);

    /**
     * Memo version of table rows records
     * @return {object[]}
     */
    const tableRows = useMemo(() => {
        return parts?.map(part => (
            {
                key: part?.id,
                [tableCellKeys.partNumber]: () => (
                    <div className={'d-flex flex-row align-items-center'}>
                        {
                            part?.partNumber?.length > 0 &&
                            <div
                                onClick={() => onBarcodeClicked(part?.partNumber)}
                                className={'barcode-icon-button'}
                            >
                                <BarcodeIcon/>
                            </div>
                        }
                        <div onClick={() => onPartNumberClicked(part)}>
                            <p className={'text-button'}>{part?.partNumber}</p>
                        </div>
                    </div>

                ),
                [tableCellKeys.weight]: part?.weight,
                [tableCellKeys.height]: part?.height,
                [tableCellKeys.width]: part?.width,
                [tableCellKeys.depth]: part?.depth,
                [tableCellKeys.submitDateTime]: part?.submitDateTime,
                [tableCellKeys.isSerialized]: () => (
                    <div>
                        {part?.isSerialized && <CheckMarkIcon/>}
                        {!part?.isSerialized && <CrossIcon/>}
                    </div>
                ),
                [tableCellKeys.description]: part.description,
            }
        ));

    }, [parts])

    return (
        <>
            <div className={'parts-list'}>
                <div className={'panel-card p-3 pt-4'}>
                    <div className={'d-flex align-items-center justify-content-between mb-5 '}>
                        <div/>
                        <div className={'d-flex align-items-center justify-content-center'}>
                            <p className={'me-3 total-instance-count'}>
                                {translation?.total?.concat(paginationInfo?.length ?? 0)}
                            </p>
                            <button
                                onClick={generateExcelReport}
                                className={'button primary outlined report'}
                                disabled={isGeneratingExcelReport}
                            >
                                <ConvertToExcel className={'me-1'}/>
                                <p>
                                    {isGeneratingExcelReport ? (translation?.generatingReport ?? '') : (translation?.convertToExcel ?? '')}
                                </p>
                            </button>
                        </div>
                    </div>
                    <PartsSearchSection filters={filters}/>
                    <div>
                        {
                            parts?.length < 1 && !loading
                                ? <NoEntries
                                    text={
                                        Object.entries(filters ?? {})?.length > 0
                                            ? translation?.empty?.withFilters
                                            : translation?.empty?.text
                                    }
                                    includeButton={Object.entries(filters ?? {})?.length > 0}
                                    buttonProps={{
                                        onClick: clearFilters,
                                        className: 'button primary px-3'
                                    }}/>

                                : <Table
                                    loading={{state: loading, count: 8}}
                                    className={'my-3'}
                                    color={'primary'}
                                    cells={tableColumns}
                                    data={tableRows}
                                    paginationInfo={paginationInfo}
                                    orderBy={orderBy}
                                    onSort={(orderBy) => QueryManagementUtils.setOrderBy(
                                        orderBy,
                                        query,
                                        history,
                                        location,
                                    )}
                                    onPageSizeChange={(p, i) => QueryManagementUtils.setPaginationInfo(
                                        {...p, pageSize: i},
                                        query,
                                        history,
                                        location
                                    )}
                                    onCurrentPageChange={(p, newP) => QueryManagementUtils.setPaginationInfo(
                                        {...p, currentPage: newP},
                                        query,
                                        history,
                                        location,
                                    )}
                                />

                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default PartsTabView;
