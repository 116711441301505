import React, {useEffect, useLayoutEffect, useState} from "react";
import {default as SwipeableViews} from 'react-swipeable-views'
import {Tab, Tabs} from "../../base/tabs";


const TabsLayout = ({
                        tabs,
                        onChange,
                        initialValue = false,
                        children,
                        tabsProps,
                        tabsDefaultValue = 0,
                        swipeable = false,
                        animateTransitions = true,
                        ...props
                    }) => {
    const [selectedIndex, setSelectedIndex] = useState(tabsDefaultValue)

    /**
     * As soon as the component mounts:
     * Dispatches a resize event from the window to tell the Tabbar indicator to show their selected tab.
     *
     * * NOTE: this has  been a bug on Mui side, and this is a temporary fix.
     */
    useEffect(() => {
        window.dispatchEvent(new Event('resize'))
    }, [])

    /**
     * Listens to the changes in initial value and with each change:
     * sets the value of the selectedIndex from the given tab value
     */
    useLayoutEffect(() => {
        selectIndexFromValue(initialValue)
    }, [initialValue])


    /**
     * Selects the index of the tab from the given tab value.
     *
     * @param {any} value the value of a tab.
     */
    const selectIndexFromValue = (value) => {
        const tab = tabs?.find(e => e.value === value);
        const index = tabs.indexOf(tab)
        if (index === -1) {
            console.warn("Tab with the given value does not exist. Unexpected behaviour may occur")
            return;
        }
        if (index === selectedIndex) return
        setSelectedIndex(index)
    }

    /**
     * Fetches the value of a tab from the selected index.
     *
     * @param {number} index the given index
     * @return {null|*} if the index is out of bounds of the tabs, returns null, otherwise returns the value of the tab.
     */
    const getTabValueFromIndex = (index) => {
        if (index > (tabs?.length ?? 0) - 1) {
            console.warn("The selected index is out of tabs bounds. Please check if you have enough tabs for the views")
            return null
        }
        return tabs[index].value
    }

    /**
     * Sets the inner state of the component as a new tab is selected.
     *
     * @param {MouseEvent | Event} e
     * @param {number} index the selected tab's index
     */
    const onTabSelected = (e, index) => {
        if (index === selectedIndex) return
        //TODO: implement the dialog confirmation logic if applicable
        setSelectedIndex(index)
        if (onChange) {
            onChange(getTabValueFromIndex(index))
        }
    }

    /**
     * Sets the current index of the inner state when the user swiped the swipable view.
     *
     * @param {number} currentIndex
     * @param {number} previousIndex
     * @param {{reason: "swipe" | "focus"}} meta
     */
    const onViewSwiped = (currentIndex, previousIndex, meta) => {
        onTabSelected(new Event(meta), currentIndex)
    }

    /**
     * Renders the selected child of this tab layout
     * @return {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal}
     */
    const renderSelectedChild = () => {
        const _children = React.Children.toArray(children)
        let _selectedIndex = selectedIndex;
        if ((children.length - 1) < _selectedIndex) {
            console.warn("There is not enough valid children to show for all the tabs in tabs layout")
            _selectedIndex = children.length - 1
        }
        return _children[_selectedIndex]
    }

    return (
        <>
            <div className={'tabs-layout'} {...props}>
                <Tabs
                    scrollButtons={'on'}
                    variant={'scrollable'}
                    className={'tabs-container'}
                    defaultValue={tabsDefaultValue}
                    value={selectedIndex}
                    onChange={onTabSelected}
                    {...(tabsProps ?? {})}
                >
                    {
                        tabs?.map((tab, index) => (
                            <Tab {...tab} key={index} value={index}/>
                        ))
                    }
                </Tabs>
                <div className={'views-container'}>
                    {
                        swipeable
                            ? (
                                <SwipeableViews
                                    index={selectedIndex}
                                    onChangeIndex={onViewSwiped}
                                    animateTransitions={animateTransitions}
                                    disableLazyLoading={false}
                                >
                                    {
                                        React.Children.map(children, child => (
                                            <div key={child.key} className={'view'}>
                                                {child}
                                            </div>
                                        ))
                                    }
                                </SwipeableViews>
                            )
                            : (
                                <div className={'view'}>
                                    {
                                        typeof children === 'function'
                                            ? children(getTabValueFromIndex(selectedIndex))
                                            : renderSelectedChild()
                                    }
                                </div>
                            )
                    }

                </div>
            </div>
        </>
    )
}

export default TabsLayout;
