import React, {useContext, useEffect, useState} from "react";
import {CompanyContext} from "../../../../../../contexts/company";
import BarChart from "../../../../../../components/app-specific/bar-chart";
import {useTranslation} from "react-i18next";
import {barChartFirstColumnColor, barChartSecondColumnColor} from "../../../../../../../assets/mui/colors";

const DashboardViewWarehousesInfo = () => {
    const [company] = useContext(CompanyContext) ?? {};
    const [loadingWarehouseInfo, setLoadingWarehouseInfo] = useState(true);
    const [warehouseInfo, setWarehouseInfo] = useState({});
    const {t} = useTranslation();
    const translations = t('views.panel.company.dashboard.barChart', {returnObjects: true})

    /**
     * Listens to the changes of the companyId
     * Once the companyId is changed, retrieves the warehouse information
     * associated with the new company
     */
    useEffect(() => {
        if (!company.id) return;
        getWarehousesInfo();
    }, [company.id]);

    /**
     * Fetches the information of the warehouses associated with the provided companyId form the server
     */
    const getWarehousesInfo = () => {
        setLoadingWarehouseInfo(true);
        const response = {
            data: [
                {
                    warehouseId: 1,
                    warehouseCode: "00",
                    partsCount: 100,
                    instanceCount: 500,
                },
                {
                    warehouseId: 2,
                    warehouseCode: "CA",
                    partsCount: 400,
                    instanceCount: 2000,
                },
                {
                    warehouseId: 3,
                    warehouseCode: "LM",
                    partsCount: 400,
                    instanceCount: 700,
                },

            ],
        }
        //TODO: Call the API to get the warehouse statistics
        setWarehouseInfo(createBarChartData(response.data));
        setLoadingWarehouseInfo(false);
    }

    /**
     * Creates the bar chart data based on the given data from the server.
     * @param data
     * @return {{legends: [{color: string, id: number, title: *}, {color: string, id: number, title: (string|string|Array<T>|*)}], xAxisLabel: *, dataset: [{code: string, data: [{legendId: number, total: number}, {legendId: number, total: number}, {legendId: number, total: number}], id: number}, {code: string, data: [{legendId: number, total: number}, {legendId: number, total: number}, {legendId: number, total: number}], id: number}, {code: string, data: [{legendId: number, total: number}, {legendId: number, total: number}, {legendId: number, total: number}], id: number}, {code: string, data: [{legendId: number, total: number}, {legendId: number, total: number}, {legendId: number, total: number}], id: number}]}}
     */
    const createBarChartData = (data) => {
        return {
            xAxisLabel: translations.xAxisLabel,
            legends: [
                {
                    id: 1,
                    title: translations.legends.parts,
                    color: barChartFirstColumnColor
                },
                {
                    id: 2,
                    title: translations.legends.instances,
                    color: barChartSecondColumnColor
                },
            ],
            dataset: data.map(e => ({
                id: e.warehouseId,
                code: e.warehouseCode,
                data: [
                    {
                        legendId: 1,
                        total: e.partsCount
                    },
                    {
                        legendId: 2,
                        total: e.instanceCount,
                    },
                ],
            })),
        };
    }

    return (
        <>
            <BarChart loading={loadingWarehouseInfo} data={warehouseInfo} title={translations.title}/>
        </>
    )
}

export default DashboardViewWarehousesInfo;
