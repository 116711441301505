/**
 * apiCallback methods
 */
export const ApiMethods = {
    get: 'GET' as const,
    post: 'POST' as const,
    put: 'PUT' as const,
    delete: 'DELETE' as const,
    patch: 'PATCH' as const,
};

/**
 * The languages that this application is able to support
 */
export const ApplicationLanguages = {
    //TODO: add the language key for adding a new language, also add it in the translations.json
    english: 'en' as const,
    french: 'fr' as const
}

/**
 * Upload states of the images in the system
 */
export const UploadStates = {
    error: 'error' as const,
    pending: 'pending' as const,
    done: 'done' as const,
}

export const ReadingFileAs = {
    binaryString: 'binaryString' as const,
    dataUrl: 'dataUrl' as const,
    text: 'text' as const,
    arrayBuffer: 'arrayBuffer' as const,
}

export const ChatMessageOperationTypes = {
    add: 'add' as const,
    remove: 'remove' as const,
}

/**
 * The application Setting keys used to get or set specific portions of the application setting
 */
export const ApplicationSettingKeys = {
    language: 'language-static' as const,
}

/**
 * Different types of file types the file-fetcher of browser accepts
 */
export const InputFileAcceptTypes = {
    png: '.png' as const,
    jpg: '.jpg' as const,
    jpeg: '.jpeg' as const,
    mp4: '.mp4' as const,
    mov: '.mov' as const,
    webm: '.webm' as const,
    flv: '.flv' as const,
    wmv: '.wmv' as const,
    pdf: '.pdf' as const,
}

/**
 * Different File Mimes used while choosing the documents in user system
 */
export const InputFileAcceptMimes = {
    images: 'image/*' as const,
    videos: 'videos/*' as const,
    audios: 'audio/*' as const,
}

/**
 * The header names used in the api calls of the application
 */
export const ApiHeaderNames = {
    companyId: 'CompanyId' as const,
    warehouseId: 'WarehouseId' as const,
    authorization: 'UserToken' as const,
};

/**
 * The indexes for the registration view stepper steps.
 */
export const RegistrationStepperSteps = {
    userInfo: 0 as const,
    businessInfo: 1 as const,
    planSelection: 2 as const,
    paymentInfo: 3 as const,
}

/**
 * The Alignments of the table cells
 */
export const TableCellAlignments = {
    left: 'left' as const,
    right: 'right' as const,
    center: 'center' as const,
}

/**
 * The different Types of the table cells
 */
export const TableCellTypes = {
    date: 'date' as const,
    dateTime: 'dateTime' as const,
    string: 'string' as const,
    money: 'money' as const,
    element: 'element' as const,
}

/**
 * The hash used to recognize the privacy policy view
 */
export const TermsAndConditionsLocationHash = 'terms-&-conditions';

/**
 * The different types of location types
 */
export const LocationTypes = {
    warehouse: 'warehouse' as const,
    aisle: 'aisle' as const,
    section: 'section' as const,
    row: 'row' as const,
}

/**
 * The different types of features for managing the locations in the warehouse
 */
export const LocationUpsertTypes = {
    add: 'add' as const,
    edit: 'edit' as const,
}

/**
 * The default page sizes of the table pagination
 */
export const DefaultPageSizes = [
    10 as const,
    20 as const,
    50 as const,
    100 as const,
    500 as const,
] as const;

/**
 * The default animation properties of the table row products
 */
export const DefaultTableRowAnimationProps = {
    timeout: 500 as const,
    classNames: 'shrink' as const,
}

/**
 * The default pagination info of the application
 */
export const DefaultPaginationInfo = {
    pageSize: DefaultPageSizes[0],
    length: 0 as const,
    currentPage: 1 as const,
}

/**
 * The default pagination info of the application
 */
export const DefaultInitialPaginationInfo = {
    ...DefaultPaginationInfo,
    currentPage: -1 as const,
}

/**
 * Different types for handling the pagination
 */
export const HandlePaginationType = {
    tablePagination: 'tablePagination',
    scrollPagination: 'scrollPagination',
}

/**
 * The credit cards supported in moneris
 */
export const MonerisCardTypes = {
    masterCard: 'M' as const,
    visa: 'V' as const,
    americanExpress: 'AX' as const,
    novusDiscover: 'NO' as const,
    debit: 'D' as const,
    jcb: 'C1' as const,
    sears: 'SE' as const,
}

/**
 * The credit card names with their moneris key names
 */
export const CreditCardTypes = {
    M: 'masterCard' as const,
    V: 'visa' as const,
    AX: 'amex' as const,
    NO: 'discover' as const,
    D: 'debit' as const,
    C1: 'jcb' as const,
    SE: 'sears' as const,
}

/**
 * The system's invoice statuses. used to determine the paid status of an invoice.
 * targets the status.id property.
 */
export const InvoiceStatuses = {
    paid: 1 as const,
    notPaid: 2 as const,
    pending: 3 as const,
}

export const GeneralSettingsTypes = {
    text: 'Text' as const,
    boolean: "Bit" as const,
    number: "Float" as const,
    list: 'List as const' as const,
}

/**
 * The Available operating systems.
 */
export const OperatingSystems = {
    mac: 'Mac' as const,
    ios: 'IOS' as const,
    windows: 'Windows' as const,
    android: 'Android' as const,
    linux: 'Linux' as const,
}

/**
 * The default options to parse or stringify query strings.
 */
export const QueryStringDefaultParseOptions = {
    arrayFormat: 'bracket-separator' as const,
    arrayFormatSeparator: '|' as const,
}

/**
 * The Animation Context Ids used for the elements of animations.
 */
export const AnimationContextConstants = {
    searchInvoices: {
        name: 'invoices-search-button' as const,
        baseId: 'invoices-search-button-base' as const,
        destinationId: 'invoices-search-button-destination' as const,
    },
    searchParts: {
        name: 'parts-search-button' as const,
        baseId: 'parts-search-button-base' as const,
        destinationId: 'part-search-button-destination' as const,
    }
}

/**
 * The system's spire counting item statuses. used to determine the scanned status of an instance of a part
 * targets the status.id property.
 */
export const SpireItemScannedStatuses = {
    correct: 1 as const,
    notScanned: 2 as const,
    incorrect: 3 as const,
}

/**
 * Different options for loading spire counting products in a single spire counting
 */
export const SpireItemSearchOptions = {
    all: 'all' as const,
    correctParts: 'correctParts' as const,
    incorrectParts: 'inCorrectParts' as const
}


/**
 * The system's position counting item statuses. used to determine the scanned status of an instance of a part
 * targets the [status.id] property.
 */
export const PositionItemScannedStatuses = {
    scanned: 1 as const,
    notScanned: 2 as const,
    naInSpire: 3 as const,
    incorrect: 4 as const,
    notAssigned: 5 as const
}
