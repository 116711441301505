import Konva from "konva";
import {Text} from "react-konva";
import {useCallback, useEffect, useRef} from "react";


export type IBoundTextProps = Konva.TextConfig & {
    rect: Konva.Rect,
    defaultFontSize: number,
};

const BoundText = ({rect, defaultFontSize, ...props}: IBoundTextProps) => {
    const textRef = useRef<Konva.Text | null>();

    /**
     * Adjusts the properties of the text entity such that the text is center-aligned.
     */
    const adjustTextProperties = useCallback((text: Konva.Text, rect: Konva.Rect) => {
        text.setAttrs({
            x: rect.x(),
            y: rect.y(),
            width: rect.width(),
            height: rect.height(),
        });
    }, [])

    /**
     * Adjusts the text properties when the [rect]'s properties have been transformed.
     */
    const onRectTransformed = useCallback<Konva.KonvaEventListener<Konva.Rect, any>>((e) => {
        const text = textRef.current;
        if (!text)
            return;
        adjustTextProperties(text, e.target as Konva.Rect);
    }, [adjustTextProperties])

    /**
     * Adjusts the text properties when the [rect]'s position has been changed.
     */
    const onRectDragged = useCallback((e) => {
        const text = textRef.current;
        if (!text)
            return;
        adjustTextProperties(text, e.currentTarget as Konva.Rect);
    }, [adjustTextProperties])

    /**
     * Adjusts the text properties when the [rect]'s scale has been changed.
     */
    const onScaleChanged = useCallback(() => {
        const text = textRef.current;
        if (!text)
            return;
        adjustTextProperties(text, rect);
    }, [adjustTextProperties, rect])

    /**
     * With each change in the [rect]'s prop value:
     * - attaches event listeners for [rect]'s changes so that the text can be adjusted.
     */
    useEffect(() => {
        const text = textRef.current;
        if (text)
            adjustTextProperties(text, rect);

        const stage = rect.getStage();
        rect.on('transform', onRectTransformed);
        rect.on('widthChange', onRectDragged);
        rect.on('heightChange', onRectDragged);
        rect.on('xChange', onRectDragged);
        rect.on('yChange', onRectDragged);
        stage?.on('scaleXChange', onScaleChanged)
        return () => {
            rect.off('transform', onRectTransformed);
            rect.off('widthChange', onRectDragged);
            rect.off('heightChange', onRectDragged);
            rect.off('xChange', onRectDragged);
            rect.off('yChange', onRectDragged);
            stage?.off('scaleXChange', onScaleChanged);
        }
    }, [adjustTextProperties, onRectDragged, onRectTransformed, onScaleChanged, rect]);

    return (
        <>
            <Text
                perfectDrawEnabled={false}
                ref={ref => textRef.current = ref}
                fontSize={defaultFontSize}
                align={'center'}
                verticalAlign={'middle'}
                {...props}
            />
        </>
    );
}

export default BoundText;
