import React, {useLayoutEffect, useState} from "react";
import {abbreviateNumber, createPriceForPlansCard} from "../../../../core/services/utils";
import {ReactComponent as DollarSign} from "../../../../assets/images/views/public-views/landing/dollar-sign.svg";
import {ReactComponent as PricingInformation} from "../../../../assets/images/views/public-views/landing/pricing-information.svg";
import {Tooltip} from "@material-ui/core";

const PricePlanCard = ({data}) => {
    const [[priceInt, priceDecimal], setPrice] = useState([]);

    /**
     * Listens for the changes in data and with each change:
     * if data has a price, then separates the price into its int and decimal parts
     */
    useLayoutEffect(() => {
        setPrice(createPriceForPlansCard(data?.price));
    }, [data])

    /**
     * Determines the behaviour of this card's button.
     *
     * if data has not price => calls the company for manual registration
     * if data has an onclick set => calls the onClick
     * else => navigates to the registration view
     */
    const onButtonClick = () => {
        if (!data?.price) {
            contactUs();
            return;
        }
        if (data?.onClick) {
            data.onClick();
        }
    }

    /**
     * Calls the available phone number for registration of platinum plan
     */
    const contactUs = () => {
        const a = document.createElement('a');
        a.href = 'tel:1-671-354-5156';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }


    return (
        <>
            <div className={'text-center price-plan-card px-2 py-3 pb-4'}>
                <div className={'price-section'}>
                    <p className='title'>
                        {data.name ?? ''}
                    </p>
                    {
                        // for the packages that their price is defined
                        data?.price
                            ? (
                                <>
                                    <div className='price-text d-flex justify-content-center'>
                                    <span className={'dollar'}>
                                        <DollarSign className={'dollar'}/>
                                    </span>
                                        <span className='main'>
                                        {priceInt}
                                    </span>
                                        <span>
                                        {priceDecimal}
                                    </span>
                                    </div>
                                </>
                            )
                            : (
                                <>
                                    <p className='define-price mt-3'>
                                        {data.priceMessage}
                                    </p>
                                </>
                            )
                    }
                </div>
                <div className='info-section'>
                    {
                        data?.price
                            ? (
                                <table>
                                    <tbody>
                                    {
                                        data?.properties?.map((prop) => (
                                            <tr key={`${prop.title} + ${prop.value}`}>
                                                <td>
                                                     <span>
                                                        {abbreviateNumber(prop.value)}
                                                    </span>
                                                </td>
                                                <td>
                                                    {prop.title}
                                                    {
                                                        prop.description?.length > 0 &&
                                                        <Tooltip
                                                            interactive
                                                            placement={'top'}
                                                            classes={{
                                                                tooltip: 'price-card-description'
                                                            }}
                                                            title={
                                                                <>
                                                                    {prop.description}
                                                                </>
                                                            }
                                                        >
                                                            <PricingInformation className={'info'}/>
                                                        </Tooltip>
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </table>
                            )
                            : (
                                <>
                                    <p className='contact-us'>
                                        {data.text}
                                    </p>
                                </>
                            )
                    }
                </div>
                <div className={'register-section'}>
                    <button className={'button secondary outlined'}
                            onClick={onButtonClick}>
                        {data.button}
                    </button>
                </div>
            </div>
        </>
    );
}


export default PricePlanCard;
